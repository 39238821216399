import { Col, List, Row } from 'antd';
import React from 'react';
import { CylinderSettlement, DistributionCenter } from 'types/type';
import { CylinderSettlementList } from '../redux/reducers';
import Text from 'antd/lib/typography/Text';
import { initDistributionCenter } from 'commons/initTypes';
import { toNumber } from 'commons/numbersFormat';
import moment from 'moment';

/* This function return the cylinder serttlements displayed as a list */
export const responsiveList = (
  sales: CylinderSettlementList,
  distributionCenters: DistributionCenter[],
): JSX.Element => {
  return (
    <List
      dataSource={sales.content}
      renderItem={(item: CylinderSettlement): JSX.Element => {
        //Find distribution center in the list or set an initialized if not found
        const distrCenter: DistributionCenter =
          distributionCenters.find(
            (center: DistributionCenter): boolean =>
              center.id === item.distributionCenterId,
          ) || initDistributionCenter;
        return (
          <List.Item key={item.id}>
            <List.Item.Meta
              description={
                <Row
                  style={{
                    background: '#FFFFFF',
                    padding: '10px',
                    margin: '-7px',
                  }}
                >
                  <Col xs={24} sm={12} lg={8} xl={8} xxl={8}>
                    <Text strong>{'Fecha: '}</Text>
                    <Text>
                      {moment(item.movementDate).format('YYYY-MM-DD HH:mm:ss')}
                    </Text>
                  </Col>
                  <Col xs={24} sm={12} lg={8} xl={8} xxl={8}>
                    <Text strong>{'Ruta: '}</Text>
                    <Text>{item.routeTag}</Text>
                  </Col>
                  <Col xs={24} sm={12} lg={8} xl={8} xxl={8}>
                    <Text strong>{'Volumen total (Kgs): '}</Text>
                    <Text>{toNumber(item.totalVolume)}</Text>
                  </Col>
                  <Col xs={24} sm={12} lg={8} xl={8} xxl={8}>
                    <Text strong>{'Total: '}</Text>
                    <Text>{'$' + toNumber(item.total)}</Text>
                  </Col>
                  <Col xs={24} sm={12} lg={8} xl={8} xxl={8}>
                    <Text strong>{'Centro de distribución: '}</Text>
                    <Text>{distrCenter.name}</Text>
                  </Col>
                </Row>
              }
            ></List.Item.Meta>
          </List.Item>
        );
      }}
    />
  );
};
