/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Hiram Padilla <hiram@ssf.com.mx>, February 2021
 *
 * This file contains the redux actions for Cylinder Settlements & Sales
 */

import { notification } from 'antd';
import { Popups } from 'commons/components/popups/popups';
import { parseApiError } from 'commons/error';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import CompanyService from 'services/company';
import CylindersService from 'services/cylinders';
import PricesService from 'services/prices';
import { RootState } from 'store/configureStore';
import {
  CylinderEquipment,
  CylinderFilters,
  CylinderSale,
  Pageable,
  Price,
} from 'types/type';
import { CylinderModel } from '..';
import { getIsFetching } from './reducers';
import {
  BEGIN_FETCH,
  CylinderSettlementAction,
  FETCH_COMPLETED,
  RESET_CYLINDER_SALES,
  SET_CYLINDER_EQUIPMENTS,
  SET_CYLINDER_FILTERS,
  SET_CYLINDER_SALES,
  SET_CYLINDER_SETTLEMENTS,
  SET_DISTRIBUTION_CENTERS,
  SET_PRICES,
} from './types';

type ITA = ThunkAction<Promise<void>, RootState, {}, CylinderSettlementAction>;
type IDA = ThunkDispatch<{}, {}, CylinderSettlementAction>;

const cylindersService = new CylindersService();
const companyService = new CompanyService();
const pricesService = new PricesService();

const startFetch = (): CylinderSettlementAction => {
  return { type: BEGIN_FETCH };
};

const endFetch = (): CylinderSettlementAction => {
  return { type: FETCH_COMPLETED };
};

/* Action to get all cylinder settlements paginated */
export const getSettlementsPage = (filters: CylinderFilters): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) return Promise.resolve();

  try {
    dispatch(startFetch());
    dispatch({ type: SET_CYLINDER_FILTERS, cylinderFilters: filters });
    filters.currentPage = filters.currentPage - 1;
    const settlementsList: Pageable = await cylindersService.getSettlementsPaginated(
      filters,
    );
    dispatch({ type: SET_CYLINDER_SETTLEMENTS, settlements: settlementsList });
    dispatch(endFetch());
  } catch (error) {
    dispatch(endFetch());
    notification.error(parseApiError(error));
  }
};

/* Action to get cylinder sales by cylinder settlement id */
export const getCylinderSales = (settlementId: string): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) return Promise.resolve();

  try {
    dispatch(startFetch());
    dispatch({ type: RESET_CYLINDER_SALES });
    const response = await cylindersService.getSalesBySettlementID(
      settlementId,
    );
    dispatch({ type: SET_CYLINDER_SALES, sales: response });
    dispatch(endFetch());
  } catch (error) {
    dispatch(endFetch());
    notification.error(parseApiError(error));
  }
};

/* Action to save a Cylinder Settlement with its Sales */
export const save = (model: CylinderModel): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) return Promise.resolve();

  try {
    dispatch(startFetch());
    await cylindersService.saveCylinderSettlement(model);
    Popups.notifySuccess('Corte y ventas guardados');
    dispatch(endFetch());
  } catch (error) {
    dispatch(endFetch());
    notification.error(parseApiError(error));
  }
};

/* Action to get Distribution Centers */
export const getDistributionCenters = (): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) return Promise.resolve();

  try {
    dispatch(startFetch());
    const response = await companyService.getDistrubutionCenter();
    dispatch({ type: SET_DISTRIBUTION_CENTERS, distributionCenters: response });
    dispatch(endFetch());
  } catch (error) {
    dispatch(endFetch());
    notification.error(parseApiError(error));
  }
};

/* Action to get the cylinder equipments */
export const getCylinderEquipments = (): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) return Promise.resolve();

  try {
    dispatch(startFetch());
    const response: CylinderEquipment[] = await cylindersService.getCylinderEquipments();
    dispatch({ type: SET_CYLINDER_EQUIPMENTS, equipments: response });
    dispatch(endFetch());
  } catch (error) {
    dispatch(endFetch());
    notification.error(parseApiError(error));
  }
};

/* Action to get the prices */
export const getPrices = (): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) Promise.resolve();

  try {
    dispatch(startFetch());
    const response: Price[] = await pricesService.getAll();
    dispatch({ type: SET_PRICES, prices: response });
    dispatch(endFetch());
  } catch (error) {
    dispatch(endFetch());
    notification.error(parseApiError(error));
  }
};

/* Action to save sales and relate them to a settlement */
export const saveSales = (
  sales: CylinderSale[],
  settlementId: string,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) return Promise.resolve();

  try {
    dispatch(startFetch());
    await cylindersService.saveSalesAtSettlement(sales, settlementId);
    Popups.notifySuccess('Ventas guardadas');
    dispatch(endFetch());
  } catch (error) {
    dispatch(endFetch());
    notification.error(parseApiError(error));
  }
};
