import logoCentral from 'assets/LogoCentralGas.png';
import logoSsf from 'assets/logo.png';
const enviromentSelect = (enviroment: string | undefined): string => {
  /* tmp no kub */
  if('local') return `https://erp.centraldegas.info`;
  //if(1) return `https://erp.centraldegas.info`;
  /* tmp no kub */
  switch (enviroment) {
    case 'staging':
      return 'https://centraldegas.org';
    case 'production':
      return 'https://centraldegas.info';
    case 'develop':
      return 'http://localhost:8765';
    default:
      //return 'http://localhost:8765';
      //return 'https://centraldegas.info';
      //return 'https://centraldegas.org';
      return 'https://erp.centraldegas.info';
  }
};
export const enterprice = (): string => {
  const enviroment = process.env.REACT_APP_STAGE;
  switch (enviroment) {
    case 'staging':
      return logoSsf;
    case 'production':
      return logoCentral;
    default:
      return logoSsf;
  }
};
export const enterpriceLogo: string = enterprice();
export const GATEWAY_API_URL: string | undefined = enviromentSelect(
  process.env.REACT_APP_STAGE,
);
export const WS_G4S_URL: string | undefined = 'http://108.161.131.197:8900';
export const CORE_SERVICE_KEY = 'api';
export const IDLE_TIMEOUT_IN_MINUTES = 20;
