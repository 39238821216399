import { AnyAction } from 'redux';
import UserService from '../../../services/user';
import { saveToken, saveTokenResponse } from '../../../security/';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { TokenResponse } from '../../../types/type';

const userService = new UserService();
// Action Definition
export interface SetAction {
  type: 'SET';
  accessToken: string;
}
// Union Action Types
export type Action = SetAction;
// Action Creators
export const set = (accessToken: string): SetAction => {
  return { type: 'SET', accessToken };
};
export const login = (
  username: string,
  password: string,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    const response: TokenResponse = await userService.authenticateUser({
      username,
      password,
    });
    await saveTokenResponse(response);
    dispatch(set(response.accessToken));
  };
};

export const loginCustomer = (
  username: string,
  password: string,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    const response: string = await userService.authenticateCustomer({
      username,
      password,
    });
    await saveToken(response);
    dispatch(set(response));
  };
};
