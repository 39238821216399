/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Hiram Padilla <hiram@ssf.com.mx>, August 2020
 *
 * This file contains the redux actions for inventory component
 */
import { getIsFetching } from './reducers';
import { ThunkDispatch, ThunkAction } from 'redux-thunk';
import { RootState } from 'store/configureStore';
import {
  InventoryAction,
  BEGIN_FETCH,
  FETCH_COMPLETED,
  SET_INVENTORY,
  SET_EQUIPMENTS,
  SET_CONTAINER_FILTERS,
  SET_EQUIVALENCES,
  RESET_EQUIVALENCES,
  SET_TOTALS_INDICATORS,
} from './types';
import ContainerService from '../../../services/containers';
import {
  Container,
  Pageable,
  Equipment,
  ContainerFilters,
  Equivalence,
  LiterTotals,
} from 'types/type';
import { Popups } from 'commons/components/popups/popups';
import { notification } from 'antd';
import { parseApiError } from 'commons/error';
import SettlementService from 'services/settlement';
import EquivalenceService from 'services/equivalence';

type ITA = ThunkAction<Promise<void>, RootState, {}, InventoryAction>;
type IDA = ThunkDispatch<{}, {}, InventoryAction>;

const containerService = new ContainerService();
const settlementService = new SettlementService();
const equivalenceService = new EquivalenceService();

const startFetch = (): InventoryAction => {
  return { type: BEGIN_FETCH };
};

const endFetch = (): InventoryAction => {
  return { type: FETCH_COMPLETED };
};

/** Action to get all data from inventory by pages */
export const getPage = (containerFilters: ContainerFilters): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    const response: Pageable = await containerService.getAll(containerFilters);
    dispatch({ type: SET_CONTAINER_FILTERS, containerFilters });
    dispatch({ type: SET_INVENTORY, inventory: response });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};

/** Action to save a Container */
export const save = (container: Container): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  dispatch(startFetch());
  await containerService.save(container);
  Popups.notifySuccess('Contenedor guardado');
  dispatch(endFetch());
};

/** Action to delete a Container with the subcontainers*/
export const deleteContainer = (id: string): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    await containerService.deleteContainer(id);
    Popups.notifySuccess('Contenedor eliminado');
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};

/** Action to get equipments for Containers */
export const getEquipments = (distributionCenterId: string): ITA => async (
  dispatch: IDA,
): Promise<void> => {
  try {
    const response: Equipment[] = await settlementService.settlementEquipmentList(
      distributionCenterId,
    );
    dispatch({ type: SET_EQUIPMENTS, equipments: response });
  } catch (err) {
    notification.error(parseApiError(err));
  }
};

/** Action to save Equivalences */
export const saveEquivalences = (equivalences: Equivalence[]): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    await equivalenceService.save(equivalences);
    Popups.notifySuccess('Cubicaje(s) guardado(s)');
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};

/** Action to get the Equivalences from a Container */
export const getEquivalences = (id: string): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    const response = await equivalenceService.findByContainerId(id);
    dispatch({ type: SET_EQUIVALENCES, equivalences: response });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};

/** Action to reset the current Equivalences in props */
export const resetEquivalences = (): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch({ type: RESET_EQUIVALENCES, equivalences: [] });
  } catch (err) {
    notification.error(parseApiError(err));
  }
};

/** Action to get totals data for containers indicators */
export const getTotalIndicators = (
  containerFilters: ContainerFilters,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    const response: LiterTotals = await containerService.getTotalIndicators(
      containerFilters,
    );
    dispatch({ type: SET_TOTALS_INDICATORS, totalIndicators: response });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};

/** Action to set end fetching when the exception is managed out of redux actions */
export const setEndFetch = (): ITA => async (dispatch: IDA): Promise<void> => {
  dispatch(endFetch());
};
