/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by David Canul <david@ssf.com.mx>, Jul 2019
 *
 *
 */
import PaymentService from '../../../services/payment';
import { parseApiError } from '../../../commons/error';
import { notification } from 'antd';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {
  PaymentAction,
  BEGIN_FETCH,
  FETCH_COMPLETED,
  SET_PAYMENT,
  SET_PAYMENT_FILTERS,
  SET_PAYMENT_COMPANY_LIST,
} from './types';
import { getIsFetching } from './reducers';
import { RootState } from 'store/configureStore';
import { Popups } from 'commons/components/popups/popups';
import { PaymentFilters } from 'types/type';
import { SET_DISTRIBUTION_CENTER } from 'routes/company/redux/types';
import CompanyService from 'services/company';
import RoutesService from 'services/routes';

type TAPUA = ThunkAction<Promise<void>, {}, {}, PaymentAction>;
type TAU = ThunkDispatch<{}, {}, PaymentAction>;

const paymentService = new PaymentService();
const companyService = new CompanyService();
const routesService = new RoutesService();

const startFetch = (): PaymentAction => {
  return { type: BEGIN_FETCH };
};
type ITA = ThunkAction<Promise<void>, RootState, {}, PaymentAction>;
type IDA = ThunkDispatch<{}, {}, PaymentAction>;
/**
 * This action creator is not public beca1use it should be used
 * automatically by other action creators that need fetch data from
 * backend.
 */
const endFetch = (): PaymentAction => {
  return { type: FETCH_COMPLETED };
};
export const applyPayment = (
  paymentId: string,
  idUserApply: string,
  paymentFilters: PaymentFilters,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    await paymentService.applyPayment(paymentId, idUserApply);
    let responsepage = await paymentService.paymentListGrouped(paymentFilters);
    dispatch({ type: SET_PAYMENT, paymentList: responsepage });

    Popups.notifySuccess('Pago aplicado correctamente');
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};

export const getPayments = (paymentFilters: PaymentFilters): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    let response = await paymentService.paymentListGrouped(paymentFilters);

    dispatch({ type: SET_PAYMENT_FILTERS, paymentFilters });
    dispatch({ type: SET_PAYMENT, paymentList: response });
    const responseDistribution = await companyService.getDistrubutionCenter();
    dispatch({
      type: SET_DISTRIBUTION_CENTER,
      distributionCenter: responseDistribution,
    });
    const responseCompany = await companyService.getAll();
    dispatch({
      type: SET_PAYMENT_COMPANY_LIST,
      companyId: responseCompany,
    });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};

export const handleBankChange = (
  paymentId: string,
  bank: string,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    await paymentService.changeBank({ paymentId, bank });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};

export const refusePayment = (
  paymentId: string,
  paymentFilters: PaymentFilters,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    await paymentService.refusePayment(paymentId);
    let responsepage = await paymentService.paymentListGrouped(paymentFilters);
    dispatch({ type: SET_PAYMENT, paymentList: responsepage });

    Popups.notifySuccess('Pago rechazado correctamente');
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};

export const savePaymentBalance = (
  customerCode: string,
  amount: number,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    Popups.notifySuccess('La cuenta del cliente ha sido actualizada');
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};

export const page = (filter: PaymentFilters): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    // dispatch(editFilters(filter));
    dispatch(startFetch());
    const responsepage = await paymentService.paymentListGrouped(filter);

    dispatch({ type: SET_PAYMENT, paymentList: responsepage });
    const responseDistribution = await companyService.getDistrubutionCenter();
    dispatch({
      type: SET_DISTRIBUTION_CENTER,
      distributionCenter: responseDistribution,
    });
    const responseCompany = await companyService.getAll();
    dispatch({
      type: SET_PAYMENT_COMPANY_LIST,
      companyId: responseCompany,
    });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};

export const getDistributionCenter = (): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    const responseDistribution = await companyService.getDistrubutionCenter();
    dispatch({
      type: SET_DISTRIBUTION_CENTER,
      distributionCenter: responseDistribution,
    });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};

export const getCompany = (): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    const responseCompany = await companyService.getAll();
    dispatch({
      type: SET_PAYMENT_COMPANY_LIST,
      companyId: responseCompany,
    });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};

export const downloadPaymentsReport = (
  paymentFilters: PaymentFilters,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) return Promise.resolve();
  dispatch(startFetch());
  try {
    await paymentService.getPaymentsReport(paymentFilters);
  } catch (error) {
    notification.error(parseApiError(error));
  }
  dispatch(endFetch());
};

export const downloadDetailedPaymentsReport = (
  paymentFilters: PaymentFilters,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) return Promise.resolve();
  dispatch(startFetch());
  try {
    await paymentService.getDetailedPaymentsReport(paymentFilters);
  } catch (error) {
    notification.error(parseApiError(error));
  }
  dispatch(endFetch());
};

export const downloadTaskitEvidence = (taskitId: string): ITA => async (
  dispatch: IDA,
  getState: Function,
): Promise<void> => {
  if (getIsFetching(getState())) return Promise.resolve();

  dispatch(startFetch());
  try {
    routesService.downloadEvidence(taskitId);
  } catch (error) {
    notification.error(parseApiError(error));
  }
  dispatch(endFetch());
};

export const downloadZip = (filters: PaymentFilters): ITA => async (
  dispatch: IDA,
  getState: Function,
): Promise<void> => {
  if (getIsFetching(getState())) return Promise.resolve();

  dispatch(startFetch());
  try {
    await paymentService.getZipFile(filters);
  } catch (error) {
    notification.error(parseApiError(error));
  }
  dispatch(endFetch());
};

export const changeFilters = (filters: PaymentFilters): ITA => async (
  dispatch: IDA,
  getState: Function,
): Promise<void> => {
  if (getIsFetching(getState())) return Promise.resolve();

  dispatch(startFetch());
  try {
    dispatch({ type: SET_PAYMENT_FILTERS, paymentFilters: filters });
  } catch (err) {
    notification.error(parseApiError(err));
  }
  dispatch(endFetch());
};
