/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by David Canul <david@ssf.com.mx>, Jun 2019
 *
 * This component lists all the sales crawled from g4s
 */
import React, { Component, useCallback, useEffect, useRef, useState } from 'react';
import JwtDecode from 'jwt-decode';
import {
  Row,
  List,
  Pagination,
  Col,
  Icon,
  DatePicker,
  Table,
  Divider,
  Button,
  Badge,
  Select,
  Tag,
  Tooltip,
  Popover,
  Card,
  Modal,
  Spin,
  Comment,
  Avatar,
  Input,
  Radio,
  InputNumber,
  AutoComplete,
} from 'antd';
import { omit } from 'lodash';
import {
  BankDeposit,
  FilterBankDeposit,
  ModelBankTotals,
  DistributionCenter,
  Settlement,
  Sale,
  SettlementDepositModal,
  Clarification,
  
  InvoicingPortalFiscalInfo,
} from 'types/type';
import { connect } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { BankDepositList } from './redux/reducers';
import {
  page,
  downloadFile,
  downloadTicket,
  totalCalculator,
  apply,
  getSettlementList,
  save,
  resetModal,
  getSettlementListByBankDeposit,
  downloadDeposit,
  getClarificationsHistory,
  saveClarification,
} from './redux/actions';
import moment, { Moment } from 'moment';
import Text from 'antd/lib/typography/Text';
import { RadioChangeEvent } from 'antd/lib/radio';
import { Permissions as P } from 'commons/permissions';
import { isAllowed, Token, getToken, getIncognitMode } from 'security/index';
import { Redirect } from 'react-router';
import { toNumber } from 'commons/numbersFormat';
import {
  initDistributionCenter,
  initBankDeposit,
  initSettlement,
} from 'commons/initTypes';
import { Strings } from 'commons/strings';
import Search from 'antd/lib/input/Search';
import { banks } from 'commons/bankCatalog';
import CollectionCreateDepositStatement from './components/createDepositStatement';
import Form, { FormComponentProps } from 'antd/lib/form';
import { Popups } from 'commons/components/popups/popups';
import { Link } from 'react-router-dom';
import { ColumnProps } from 'antd/lib/table';
import ValidateDepositModel from './components/validatorDepositModal';
import { isDateWrong } from 'routes/functions';
import ClarificationForm from './components/clarificationFormModal';
import ClarificationHistory from './components/clarificationHistoryModal';
import { DATE_FORMAT, getEndTime, getInitialTime } from 'commons/services';
import { ApiRoute, ApiTypes, ApiVarStorage, callApi, callApiGetFile, callApiWithFiles, getCurrentTokenInfo } from 'commons/services/api';
import { DbOldDetails, DbOldTypes, DbOldValues } from 'database';
import { AppTypes, Globals } from 'commons/globals';
import { DataSourceItemObject } from 'antd/lib/auto-complete';
import InvoiceService from 'services/invoice';

const token =
  getToken() !== null ? JwtDecode<Token>(getToken() as string) : null;

interface DispatchProps {
  page: (filterBankDeposit: FilterBankDeposit) => void;
  downloadFile: (id: string, name: string, saleFolio: string) => void;
  downloadTicket: (id: string, saleFolio: string, name: string) => void;
  totalCalculator: (filterBankDeposit: FilterBankDeposit) => void;
  apply: (
    id: string,
    status: string,
    description: string,
    amountDifferences: number,
  ) => void;
  getSettlementList: (distId: string) => void;
  getSettlementListByBankDeposit: (distId: string, depositType: string) => void;
  save: (statement: FormData) => void;
  resetModal: () => void;
  downloadDeposit: (id: string, folio: string) => void;
  getClarificationsHistory: (id: string) => void;
  saveClarification: (clarification: FormData) => void;
}
interface StateProps {
  bankDepoistPage: BankDepositList;
  bankDepositToApply: BankDeposit;
  modelBankTotals: ModelBankTotals;
  distributionCenter: DistributionCenter[];
  isFetching: boolean;
  settlementList: Settlement[];
  settlementModalList: SettlementDepositModal;
  salesList: Sale[];
  bankDepositSave: BankDeposit;
  bankDepositFilters: FilterBankDeposit;
  clarifications: Clarification[];
}

type Props = StateProps & FormComponentProps & DispatchProps;

const DATE_PICKER_START_NAME = 'startValueDate';
const DATE_PICKER_END_NAME = 'endValueDate';

class SalesListComponent extends Component<Props> {
  public state = {
    pagePosition: 1,
    invoiceStatus: 'ALL',
    unitType: 'L',
    startValueDate: moment(),
    endValueDate: moment(),
    endOpen: false,
    all: false,
    distributionCenter: initDistributionCenter,
    fromAmount: 0,
    toAmount: 10000000,
    filterFolio: '',
    filterAcount: '',
    bank: '',
    visible: false,
    visibleApply: false,
    stagingArea: [],
    distributionCenterId: '',
    bankDepositToValidate: initBankDeposit,
    validar: true,
    expanded: [],
    description: 'NA',
    pageSize: 10,
    currentPage: 1,
    isClarificationVisible: false,
    isClarHistoryVisible: false,
    selectedBankDeposit: initBankDeposit,
    amountDifferences: 0,
    
    depositDifferencesStatus: DbOldValues.DepositDifferenceStatus.CLOSED,
    userHasLevel: false,
    userLevel: DbOldValues.UserAuditLevel.None,
    allowedAuditInvoice: false,
  };

  public setDescription = (desc: string): void => {
    this.setState({ description: desc });
  };

  public setAmountDifferences = (amount: number | undefined): void => {
    this.setState({ amountDifferences: amount || 0 });
  };

  // eslint-disable-next-line
  public buildEntity = (values: any, stagingArea: Settlement[]): FormData => {
    let formData = new FormData();
    let totaldebt = 0;
    const newValues = {
      id: values.id,
      settlementsId: stagingArea.map((element: Settlement): string => {
        totaldebt += element.totalCash ? element.totalCash : 0;
        return element.id;
      }),
      createdAt: values.createdAt,
      updatedAt: values.updatedAt,
      status: 'A validar',
      balance: values.balance,
      debt: totaldebt + Number(values.debt) - values.amount,
      distributionCenterId: values.distributionCenterId,
      paymentMethod: values.paymentForm,
      amount: values.amount,
      balancesp: values.balancesp,
      bank: values.bank,
      acountRecipt: values.acountRecipt,
      depositDate: values.depositDate,
      idUserCreate: token ? token.id : '',
      idUserUpdate: token ? (token.customerCode === '' ? token.id : '') : '',
      folioDeposit: values.folioDeposit,
      refuceDescription: values.refuceDescription,
      comments: values.comments,
    };

    const auxEstate = omit(newValues, ['images']);
    const BankDeposit = Object.assign(
      {},
      {
        ...auxEstate,
        fileType: values.voucher[0].originFileObj.type,
        fileExt: values.voucher[0].originFileObj.name.substring(
          values.voucher[0].originFileObj.name.lastIndexOf('.') + 1,
        ),
      },
    );

    // eslint-disable-next-line
    const newEstate: any = Object.assign(
      {},
      { BankDeposit },
      { images: values.voucher[0].originFileObj || [] },
    );

    Object.keys(newEstate).forEach((key: string): void => {
      if (key === 'images') {
        formData.append(key, newEstate[key]);
      } else {
        const model = newEstate[key];
        formData.append(key, JSON.stringify(model));
      }
    });
    return formData;
  };

  public showModalApply = (
    validar: boolean,
    bankDepositToValidate: BankDeposit,
  ): void => {
    const asyncAction = async (): Promise<void> => {
      try {
        this.props.getSettlementListByBankDeposit(
          bankDepositToValidate.id,
          bankDepositToValidate.depositType,
        );
        this.setState({
          visibleApply: true,
          validar,
          bankDepositToValidate,
          amountDifferences: 0,
        });
      } catch {}
    };
    asyncAction();
  };

  public showModal = async (): Promise<void> => {

    const allowedResult = await callApi(ApiRoute.VerifyAllowedOperations, { operation: 'registerDeposit' });
    if(!allowedResult || !allowedResult.success){
      alert((allowedResult && allowedResult.msg) || 'Error verificando operación.');
      return;
    }

    this.props.resetModal();
    if (!isAllowed([P.CAN_VIEW_ALL_DISTRIBUTION_CENTERS])) {
      this.props.getSettlementList(
        JwtDecode<Token>(getToken() as string).distributionCenterId,
      );
    }
    this.setState({ visible: true });
  };
  public handleCancel = (): void => {
    this.props.resetModal();
    this.setState({ visible: false });
  };
  public handleCancelApply = (): void => {
    this.setState({ visibleApply: false, description: 'NA' });
  };
  private statusBankDepopsitSalePerm = (): string[] => {
    let seeBankDeposit: string[] = [];

    if (
      isAllowed([P.CAN_VIEW_DEPOSIT_IUS]) ||
      isAllowed([P.CAN_APPLY_DEPOSIT_IUS])
    ) {
      seeBankDeposit.push('A validar');
    }
    if (isAllowed([P.CAN_VIEW_DEPOSIT_IUS])) {
      seeBankDeposit.push('Rechazado');
    }
    if (isAllowed([P.CAN_VIEW_APPLICATED_DEPOSIT_UIS])) {
      seeBankDeposit.push('Aplicado');
    }
    return seeBankDeposit;
  };
  private statusBankDepopsitSettlementPerm = (): string[] => {
    let seeBankDeposit: string[] = [];

    if (
      isAllowed([P.CAN_APPLY_BANK_DEPOSITS]) ||
      isAllowed([P.CAN_VIEW_BANK_DEPOSITS])
    ) {
      seeBankDeposit.push('A validar');
    }
    if (isAllowed([P.CAN_VIEW_BANK_DEPOSITS])) {
      seeBankDeposit.push('Rechazado');
    }
    if (isAllowed([P.CAN_APPLY_BANK_DEPOSITS])) {
      seeBankDeposit.push('Aplicado');
    }
    return seeBankDeposit;
  };
  private typeBankDepopsitPerm = (): string[] => {
    let seeBankDeposit: string[] = [];
    if (
      isAllowed([P.CAN_APPLY_DEPOSIT_IUS]) ||
      isAllowed([P.CAN_VIEW_BANK_DEPOSITS])
    ) {
      seeBankDeposit.push('Corte');
    }
    if (isAllowed([P.CAN_APPLY_BANK_DEPOSITS])) {
      seeBankDeposit.push('Venta');
    }
    return seeBankDeposit;
  };
  public handleOkApply = (): void => {
    if (
      (!this.state.validar && this.state.description === 'NA') ||
      this.state.description === ''
    ) {
      Popups.notifyError('Introduzca el motivo de rechazo');
      return;
    }

    const asyncAction = async (): Promise<void> => {
      try {
        await this.setState({ visibleApply: false });
        await this.props.apply(
          this.state.bankDepositToValidate.id,
          this.state.validar ? 'Aplicado' : 'Rechazado',
          this.state.description,
          this.state.amountDifferences,
        );
        await this.props.page(this.props.bankDepositFilters);
        await this.props.totalCalculator(this.props.bankDepositFilters);
      } catch (e) {
        Popups.notifyException(e);
      }
    };
    asyncAction();
  };

  public handleCreate = (stagingArea: Settlement[]): void => {
    console.log(`Entered handle.`);
    const form = this.props.form;
    if (stagingArea.length === 0) {
      Popups.notifyError('Slecciona un corte a depositar');
      return;
    }
    const asyncAction = async (): Promise<void> => {
      try {
        await this.props.page(this.props.bankDepositFilters);
        await this.props.totalCalculator(this.props.bankDepositFilters);
      } catch (e) {
        Popups.notifyException(e);
      }
    };
    form.validateFields(
      async (err, values): Promise<void> => {
        if (!err) {
          try {
            console.log(values);
            let { amountsNotMatch: amount = 0, depositDifferences: formDepositDifferences = [] } = values as {
              amountsNotMatch: number,
              depositDifferences: (ApiTypes.NewDepositDifference & { file: File | null })[],
            };
            let depositDifferenceTotalAmount = formDepositDifferences.reduce((acc, cur) => acc + cur.amount, amount);
            if(!Globals.Utils.Number.equalsWithPermissiveLimit(depositDifferenceTotalAmount, 0, .2)){
              Popups.notifyError(`El monto de diferencias debe quedar en ceros.`);
              return;
            }

            //Commented this to avoid generating deposit while testing differences
            this.setState({
              visible: false,
              stagingArea: [],
              selectedRowKeys: {},
            });
            
            await this.props.save(this.buildEntity(values, stagingArea));
            
            let bankDepositId = ApiVarStorage.consume('bankDepositId');
            if(bankDepositId){
              console.log(`Entered block.`);
              let depositDifferences: ApiTypes.NewDepositDifference[] = amount ? [{ bankDepositId, amount, paymentWay: 0, otherPaymentWay: '', fileId: '' }] : [];
              let files: ApiTypes.ParamFile[] = [];
              for(let {file, ...formDepositDifference} of formDepositDifferences){
                depositDifferences.push({...formDepositDifference, bankDepositId});
                if(file) files.push({ id: formDepositDifference.fileId, file });
              }
              let depositDifferenceResponse = await callApiWithFiles(ApiRoute.AddDepositDifferences, { depositDifferences }, files);
              console.log(depositDifferenceResponse);
              if(!depositDifferenceResponse || !depositDifferenceResponse.success) Popups.notifyError('Error añadiendo diferencias.');
            }else{
              Popups.notifyError('Error añadiendo diferencias (Falta id del depósito). ');
            }
            //if(1) return;
            
            form.resetFields();
            asyncAction();
            Popups.notifySuccess('Guardado Exitoso');
          } catch (e) {
            console.log(`Error.`, e);
            Popups.notifyException(e as any);
          }
        }else{
          console.log(`Err.`, err)
        }
      },
    );
  };
  private handleChange = (): void => {};
  private onSearch = (): void => {};
  /**
   * This function execute when the component is mounting and get the first page from
   * sales
   */
  public componentDidMount(): void {
    this.setState({
      distributionCenterId: isAllowed([P.CAN_VIEW_ALL_DISTRIBUTION_CENTERS])
        ? ''
        : JwtDecode<Token>(getToken() as string).distributionCenterId,
      startValueDate: getInitialTime(),
      endValueDate: getEndTime(),
      all: getIncognitMode() === '1',
    });
    const asyncAction = async (): Promise<void> => {
      try {
        await this.props.page(this.props.bankDepositFilters);
        await this.props.totalCalculator(this.props.bankDepositFilters);
      } catch (e) {
        Popups.notifyException(e);
      }
    };
    asyncAction();
    this.reloadDepositDifferencesStatus();
    this.reloadUserHasLevel();
  }

  public handlePaginationChangeNew = (currentPage: number): void => {
    const asyncAction = async (): Promise<void> => {
      try {
        await this.props.page({
          ...this.props.bankDepositFilters,
          pagePosition: currentPage,
        });
        this.setState({ currentPage: currentPage });
      } catch (e) {
        Popups.notifyException(e);
      }
    };
    asyncAction();
  };

  /**
   * This function change page and replaces current position in the pagination
   */
  public handlePaginationChange = (
    currentPage: number,
    pageSize?: number,
  ): void => {
    this.setState({ currentPage: currentPage, pageSize });
    const asyncAction = async (): Promise<void> => {
      try {
        await this.props.page({
          ...this.props.bankDepositFilters,
          pagePosition: currentPage,
          pageSize: pageSize || 0,
        });
      } catch (e) {
        Popups.notifyException(e);
      }
    };
    asyncAction();
  };

  /**
   * This function called when a date picker (start or end) changes.
   * TODO : change any to Moment when yarn has be updated
   */
  public onDatePickerChange = (
    startDateValue: Moment,
    endDateValue: Moment,
  ): void => {
    const asyncAction = async (): Promise<void> => {
      //when clic on cancel date, it is null, so set the today's date
      if (startDateValue === null) {
        startDateValue = getInitialTime();
      }
      if (endDateValue === null) {
        endDateValue = getEndTime();
      }

      try {
        await this.props.page({
          ...this.props.bankDepositFilters,
          initDate: startDateValue,
          endDate: endDateValue,
          pageSize: 25,
          pagePosition: 1,
        });
        await this.props.totalCalculator({
          ...this.props.bankDepositFilters,
          initDate: startDateValue,
          endDate: endDateValue,
          pageSize: 25,
          pagePosition: 1,
        });
      } catch (e) {
        Popups.notifyException(e);
      }
    };
    asyncAction();
    if (startDateValue !== null && endDateValue !== null) {
      this.setState({
        [DATE_PICKER_START_NAME]: startDateValue,
        [DATE_PICKER_END_NAME]: endDateValue,
      });
    }
  };

  /**this function change radio element  and find status on the db from state element*/
  public onChangeRadio = (e: RadioChangeEvent): void => {
    this.setState({
      invoiceStatus: e.target.value,
    });
  };

  public onChangeAmountfrom = (value: number | undefined): void => {
    if (this.state.fromAmount > (value ? value : 0)) {
      const asyncAction = async (): Promise<void> => {
        try {
          await this.props.page({
            ...this.props.bankDepositFilters,
            initAmount: value ? value : 0,
            pageSize: 25,
            pagePosition: 1,
          });
          await this.props.totalCalculator({
            ...this.props.bankDepositFilters,
            initAmount: value ? value : 0,
            pageSize: 25,
            pagePosition: 1,
          });
        } catch (e) {
          Popups.notifyException(e);
        }
      };
      asyncAction();

      this.setState({ fromAmount: value });
    }
  };

  public onChangeAmountto = (value: number | undefined): void => {
    if (this.state.fromAmount < (value ? value : 10000000)) {
      const asyncAction = async (): Promise<void> => {
        try {
          await this.props.page({
            ...this.props.bankDepositFilters,
            endAmount: value ? value : 0,
            pageSize: 25,
            pagePosition: 1,
          });
          await this.props.totalCalculator({
            ...this.props.bankDepositFilters,
            endAmount: value ? value : 0,
            pageSize: 25,
            pagePosition: 1,
          });
        } catch (e) {
          Popups.notifyException(e);
        }
      };
      asyncAction();

      this.setState({ toAmount: value });
    }
  };

  public changeDistributionCenterModal = (value: string): void => {
    this.props.getSettlementList(value);
  };

  public handleChangeDistributionCenter = (
    distributionCenter: string,
  ): void => {
    const asyncAction = async (): Promise<void> => {
      try {
        await this.props.page({
          ...this.props.bankDepositFilters,
          distributionCenterId: distributionCenter,
          pageSize: 25,
          pagePosition: 1,
        });
        await this.props.totalCalculator({
          ...this.props.bankDepositFilters,
          distributionCenterId: distributionCenter,
          pageSize: 25,
          pagePosition: 1,
        });
      } catch (e) {
        Popups.notifyException(e);
      }
    };
    asyncAction();
    this.setState({ distributionCenterId: distributionCenter });
  };

  public handleChangeBank = (bank: string): void => {
    const asyncAction = async (): Promise<void> => {
      try {
        await this.props.page({
          ...this.props.bankDepositFilters,
          bank: bank,
          pageSize: 25,
          pagePosition: 1,
        });
        this.setState({ bank: bank });
        await this.props.totalCalculator({
          ...this.props.bankDepositFilters,
          bank: bank,
          pageSize: 25,
          pagePosition: 1,
        });
        this.setState({ bank: bank });
      } catch (e) {
        Popups.notifyException(e);
      }
    };
    asyncAction();
  };

  public handleFindFolio = (filterFolio: string): void => {
    if (filterFolio === Strings.EMPTY_STRING || filterFolio) {
      const asyncAction = async (): Promise<void> => {
        try {
          await this.props.page({
            ...this.props.bankDepositFilters,
            folioDeposit: filterFolio,
            pageSize: 25,
            pagePosition: 1,
          });
          await this.props.totalCalculator({
            ...this.props.bankDepositFilters,
            folioDeposit: filterFolio,
            pageSize: 25,
            pagePosition: 1,
          });
        } catch (e) {
          Popups.notifyException(e);
        }
      };
      asyncAction();
    }

    this.setState({ filterFolio: filterFolio });
  };

  public handleFindAcount = (filterAcount: string): void => {
    if (filterAcount === Strings.EMPTY_STRING || filterAcount) {
      const asyncAction = async (): Promise<void> => {
        try {
          await this.props.page({
            ...this.props.bankDepositFilters,
            acountRecipt: filterAcount,
            pageSize: 25,
            pagePosition: 1,
          });
          await this.props.totalCalculator({
            ...this.props.bankDepositFilters,
            acountRecipt: filterAcount,
            pageSize: 25,
            pagePosition: 1,
          });
        } catch (e) {
          Popups.notifyException(e);
        }
      };
      asyncAction();
      this.reloadDepositDifferencesStatus();
    }
    this.setState({ filterAcount: filterAcount });
  };

  private async reloadDepositDifferencesStatus(depositDifferences?: DbOldTypes.DepositDifference[]){
    if(!isAllowed([ P.DEPOSIT_DIFFERENCE_VIEW ])) return;
    console.log(`Reloading depositDifferencesStatus from ${this.state.depositDifferencesStatus}.`);
    let statusKeys: DbOldValues._DepositDifferenceStatus[] = [ DbOldValues.DepositDifferenceStatus.CREATED, DbOldValues.DepositDifferenceStatus.RESOLVED ];
    if(!depositDifferences){
      let result = await callApi(ApiRoute.GetDepositDifferences, { filters: { statusKeys } });
      if(!result || !result.success){
        Popups.notifyError('No se pudo obtener el estatus de las diferencias.');
        return;
      }
      depositDifferences = result.depositDifferences;
    }else{
      depositDifferences = depositDifferences.filter(dd => statusKeys.includes(dd.status_key));
    }
    if(depositDifferences.length){
      let depositDifferencesStatus = DbOldValues.DepositDifferenceStatus.RESOLVED;
      
      for(let depositDifference of depositDifferences){
        if(depositDifference.status_key === DbOldValues.DepositDifferenceStatus.CREATED){
          depositDifferencesStatus = depositDifference.status_key;
          
        }
      }
      this.setState({ depositDifferencesStatus });
    console.log(`Now is ${depositDifferencesStatus}.`);
    }else{
      this.setState({ depositDifferencesStatus: DbOldValues.DepositDifferenceStatus.CLOSED });
    }
  }
  private async reloadUserHasLevel(){
    console.log(`RUHL`);
    let result = await callApi(ApiRoute.GetUserAuditLevel, {});
    console.log(result);
    if(result && result.success && 'levelKey' in result){
      this.state.userHasLevel = result.levelKey <= DbOldValues.UserAuditLevel.Level2;
      this.state.userLevel = result.levelKey;
      this.state.allowedAuditInvoice = result.allowedAuditInvoice;
    }
  }

  /**
   * This function render elements from the filters
   */
  private renderFilters = (): JSX.Element => {
    return (
      <Row className={'content-backgroud'}>
        <Row>
          <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
            {this.renderStartDatePicker()}
            {this.renderEndDatePicker()}
          </Col>
          <Col xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>
            <Row>
              <Text strong style={{ color: '#113C71' }}>
                Centro de Distribución
              </Text>
            </Row>
            <Row>
              {!isAllowed([P.CAN_VIEW_ALL_DISTRIBUTION_CENTERS]) && (
                <Select
                  defaultValue={
                    this.props.bankDepositFilters.distributionCenterId
                  }
                  size="large"
                  style={{ width: '100%' }}
                >
                  <Select.Option
                    value={this.props.bankDepositFilters.distributionCenterId}
                  >
                    {
                      (
                        this.props.distributionCenter.find(
                          (d: DistributionCenter): boolean =>
                            d.id ===
                            JwtDecode<Token>(getToken() as string)
                              .distributionCenterId,
                        ) || initDistributionCenter
                      ).name
                    }
                  </Select.Option>
                </Select>
              )}

              {isAllowed([P.CAN_VIEW_ALL_DISTRIBUTION_CENTERS]) && (
                <Select
                  defaultValue={
                    this.props.bankDepositFilters.distributionCenterId
                  }
                  size="large"
                  style={{ width: '100%' }}
                  onChange={(value: string): void =>
                    this.handleChangeDistributionCenter(value)
                  }
                >
                  <Select.Option value="">Todos</Select.Option>
                  {this.props.distributionCenter.map(
                    (item, index): React.ReactNode => (
                      <Select.Option key={item.id} value={item.id}>
                        <div id={'dist_' + index.toString()}> {item.name}</div>
                      </Select.Option>
                    ),
                  )}
                </Select>
              )}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
            <Row>
              <Text strong style={{ color: '#113C71' }}>
                Folio de Depósito
              </Text>
            </Row>
            <Row>
              <Search
                size="large"
                placeholder={
                  this.props.bankDepositFilters.folioDeposit !== ''
                    ? this.props.bankDepositFilters.folioDeposit
                    : 'Folio'
                }
                onSearch={(value: string): void => this.handleFindFolio(value)}
                enterButton
              />
            </Row>
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4} offset={1}>
            <Row>
              <Text strong style={{ color: '#113C71' }}>
                Cuenta Destino
              </Text>
            </Row>
            <Row>
              <Search
                size="large"
                placeholder={
                  this.props.bankDepositFilters.acountRecipt !== ''
                    ? this.props.bankDepositFilters.acountRecipt
                    : 'Número de cuenta'
                }
                onSearch={(value: string): void => this.handleFindAcount(value)}
                enterButton
              />
            </Row>
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4} offset={1}>
            <Row>
              <Text strong style={{ color: '#113C71' }}>
                Banco
              </Text>
            </Row>
            <Row>
              <Select
                showSearch
                defaultValue={this.props.bankDepositFilters.bank}
                size="large"
                onChange={this.handleChangeBank}
                style={{ width: '100%' }}
              >
                <Select.Option value="">{'Todos'}</Select.Option>
                {banks.map(
                  (item, index: number): React.ReactNode => (
                    <Select.Option key={item.name} value={item.name}>
                      <div id={'banco_' + index.toString()}>{item.name}</div>
                    </Select.Option>
                  ),
                )}
              </Select>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={3} xl={3} xxl={3} offset={1}>
            <Row>
              {isAllowed([P.CAN_CREATE_BANK_DEPOSITS]) && (
                <Button
                  onClick={(): Promise<void> => this.showModal()}
                  type="primary"
                  className="button-add-user button-size"
                  size="large"
                  id="user_new"
                >
                  Registrar depósito
                </Button>
              )}
              {this.state.visible && (
                <CollectionCreateDepositStatement
                  visible={this.state.visible}
                  onCancel={(): Function => this.handleCancel}
                  onCreate={this.handleCreate}
                  handleChange={(): Function => this.handleChange}
                  onSearch={(): Function => this.onSearch}
                  form={this.props.form}
                  stagingArea={this.state.stagingArea}
                  settlementSearchList={this.props.settlementModalList}
                  customerName={token ? token.customerCode : ''}
                  isFetching={this.props.isFetching}
                  handleChangeDistributionCenter={
                    this.changeDistributionCenterModal
                  }
                  distributionCenter={this.props.distributionCenter}
                />
              )}
            </Row>
          </Col>
        </Row>
      </Row>
    );
  };

  private renderStartDatePicker = (): JSX.Element => {
    const { endValueDate } = this.state;
    return (
      <Row>
        <label>{'De:\u00A0\u00A0\u00A0\u00A0\u00A0'}</label>
        <Divider type="vertical" />
        <DatePicker
          disabledDate={(date: moment.Moment | undefined): boolean =>
            isDateWrong(date, endValueDate)
          }
          showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
          format={DATE_FORMAT}
          placeholder={getInitialTime()
            .format('YYYY-MM-DD HH:mm:SS')
            .toString()}
          onChange={
            /*eslint-disable-line*/ (startDate: any): void =>
              this.onDatePickerChange(startDate, endValueDate)
          }
          onOpenChange={(open: boolean): void => {
            if (!open) this.setState({ endOpen: true });
          }}
        />
      </Row>
    );
  };

  private renderEndDatePicker = (): JSX.Element => {
    const { startValueDate, endOpen } = this.state;
    return (
      <Row>
        <label>{'Hasta:'}</label>
        <Divider type="vertical" />
        <DatePicker
          disabledDate={(date: moment.Moment | undefined): boolean =>
            isDateWrong(startValueDate, date)
          }
          showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }}
          format={DATE_FORMAT}
          placeholder={getEndTime()
            .format('YYYY-MM-DD HH:mm:SS')
            .toString()}
          onChange={
            /*eslint-disable-line*/ (endDate: any): void =>
              this.onDatePickerChange(startValueDate, endDate)
          }
          open={endOpen}
          onOpenChange={(status: boolean): void =>
            this.setState({ endOpen: status })
          }
        />
      </Row>
    );
  };

  private renderDetails = (item: BankDeposit): JSX.Element => {
    const distributionC: DistributionCenter =
      this.props.distributionCenter.find(
        (d: DistributionCenter): boolean => d.id === item.distributionCenterId,
      ) || initDistributionCenter;
    return (
      <Row>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Estado: '}</Text>
          <Text>{item.status}</Text>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Fecha de registro: '}</Text>
          <Text>{moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}</Text>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Fecha de depósito: '}</Text>
          <Text>{moment(item.depositDate).format('YYYY-MM-DD HH:mm:ss')}</Text>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Centro distribución: '}</Text>
          <Text>{distributionC.name}</Text>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Monto: '}</Text>
          <Text>{' $' + toNumber(item.amount)}</Text>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Banco: '}</Text>
          <Text>{item.bank}</Text>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Cuenta destino: '}</Text>
          <Text>{item.acountRecipt}</Text>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Folio: '}</Text>
          <Text>{item.folioDeposit}</Text>
        </Col>
      </Row>
    );
  };

  private showClarificationHistoryModal = (deposit: BankDeposit): void => {
    const asyncFunction = async (): Promise<void> => {
      try {
        await this.props.getClarificationsHistory(deposit.id);
        await this.setState({
          isClarHistoryVisible: true,
        });
      } catch {}
    };
    asyncFunction();
  };

  private handleCancelClarification = (): void => {
    this.setState({ isClarificationVisible: false });
  };

  private handleCreateClarification = (): void => {
    //Validate form data and save it
    const form = this.props.form;
    form.validateFields(
      async (err, values): Promise<void> => {
        if (!err) {
          let formData = new FormData();
          //Build an object with the values to save
          const clarification = {
            comment: values.comment,
            bankDepositId: this.state.selectedBankDeposit.id,
            fileType: values.image[0].originFileObj.type,
            fileExt: values.image[0].originFileObj.name.substring(
              values.image[0].name.lastIndexOf('.') + 1,
            ),
            date: moment(),
            userEmail: token ? token.email : '',
          };

          //Separate the image from the object and add each one to the formData variable
          const objectWithoutImage = omit(clarification, ['images']);
          formData.append('clarification', JSON.stringify(objectWithoutImage));
          formData.append('image', values.image[0].originFileObj || []);
          //Send the form data to save it
          await this.props.saveClarification(formData);
          form.resetFields();
          this.setState({
            isClarificationVisible: false,
          });
        }
      },
    );
  };

  /** Modal to add clarifications */
  public renderClarificationForm = (): JSX.Element => {
    const { isFetching, form } = this.props;
    return (
      <Row>
        <ClarificationForm
          isFetching={isFetching}
          isVisible={this.state.isClarificationVisible}
          bankDeposit={this.state.selectedBankDeposit}
          onCancel={(): Function => this.handleCancelClarification}
          onCreate={(): Function => this.handleCreateClarification}
          form={form}
        />
      </Row>
    );
  };

  private handleCancelHistory = (): void => {
    this.setState({ isClarHistoryVisible: false });
  };

  /** Modal to see the clarifications history */
  public renderClarificationHistory = (): JSX.Element => {
    const { isFetching, clarifications } = this.props;
    return (
      <Row>
        <ClarificationHistory
          isFetching={isFetching}
          isVisible={this.state.isClarHistoryVisible}
          clarifications={clarifications}
          onCancel={(): Function => this.handleCancelHistory}
        />
      </Row>
    );
  };

  public renderActions = (item: BankDeposit): JSX.Element => {
    const { isFetching, downloadDeposit } = this.props;
    return (
      <Row>
        <Col span={24}>
          {item.status !== 'A validar' ? (
            <Col>
              {item.status === 'Aplicado' ? (
                <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={4}>
                  <Icon
                    type="check-circle"
                    theme="twoTone"
                    twoToneColor="#52c41a"
                    style={{ fontSize: '12px' }}
                  />
                </Col>
              ) : (
                <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <Icon
                      type="close-circle"
                      theme="twoTone"
                      twoToneColor="#D71F49"
                      style={{ fontSize: '12px' }}
                    />
                    <Divider type={'vertical'} />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <Popover
                      content={item.refuceDescription}
                      title="Motivo de Rechazo"
                      overlayStyle={{ width: '50rem' }}
                      placement="topRight"
                      arrowPointAtCenter
                    >
                      <Icon
                        type="message"
                        theme="twoTone"
                        style={{ fontSize: '16px' }}
                      />
                    </Popover>
                    <Divider type={'vertical'} />
                  </Col>
                </Col>
              )}
            </Col>
          ) : isAllowed([P.CAN_APPLY_BANK_DEPOSITS]) &&
            item.depositType === 'Corte' ? (
            <Col span={24}>
              <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={4}>
                <Link
                  key="validar"
                  to={{}}
                  onClick={(): void => this.showModalApply(true, item)}
                >
                  <Tooltip title="Validar">
                    <Icon
                      type="like"
                      theme="twoTone"
                      style={{ fontSize: '16px' }}
                    />
                  </Tooltip>
                </Link>
                <Divider type={'vertical'} />
              </Col>
              <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={4}>
                <Link
                  key="rechazar"
                  to={{}}
                  onClick={(): void => this.showModalApply(false, item)}
                >
                  <Tooltip title="Rechazar">
                    <Icon
                      type="dislike"
                      theme="twoTone"
                      style={{ fontSize: '16px' }}
                    />
                  </Tooltip>
                </Link>
                <Divider type={'vertical'} />
              </Col>
              <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={4}>
                <Button
                  type="link"
                  className="ticket-button ant-button"
                  disabled={isFetching}
                  onClick={(): void => {
                    downloadDeposit(item.id, item.folioDeposit);
                  }}
                  style={{ padding: '0px 5px' }}
                >
                  PDF
                </Button>
              </Col>
            </Col>
          ) : isAllowed([P.CAN_APPLY_DEPOSIT_IUS]) &&
            item.depositType === 'Venta' ? (
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={4}>
              <Link
                key="2"
                to={{}}
                onClick={(): void => this.showModalApply(true, item)}
              >
                <Tooltip title="Validar">
                  <Icon
                    type="like"
                    theme="twoTone"
                    style={{ fontSize: '16px' }}
                  />
                </Tooltip>
              </Link>
            </Col>
          ) : (
            <></>
          )}
          {item.depositType === 'Corte' && (
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={4}>
              <Col span={24}>
                <a
                  key="20"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://storage.googleapis.com/almacen_facturas_central/bankDeposit/${item.id}.${item.fileExt}`}
                >
                  <Tooltip title="Descargar comprobante">
                    <Icon
                      type="down-circle"
                      theme="twoTone"
                      style={{ fontSize: '16px' }}
                    />
                  </Tooltip>
                </a>
              </Col>
            </Col>
          )}
          {isAllowed([P.CAN_ADD_DEPOSIT_CLARIFICATIONS]) && (
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={4}>
              <Link
                key="3"
                to={{}}
                onClick={(): void =>
                  this.setState({
                    selectedBankDeposit: item,
                    isClarificationVisible: true,
                  })
                }
              >
                <Tooltip title="Agregar aclaración">
                  <Icon
                    type="file-add"
                    theme="twoTone"
                    style={{ fontSize: '16px' }}
                  />
                </Tooltip>
              </Link>
            </Col>
          )}
          <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={4}>
            <Link
              key="4"
              to={{}}
              onClick={(): void => this.showClarificationHistoryModal(item)}
            >
              <Tooltip title="Ver aclaraciones">
                <Icon
                  type="clock-circle"
                  theme="twoTone"
                  style={{ fontSize: '16px' }}
                />
              </Tooltip>
            </Link>
          </Col>
        </Col>
      </Row>
    );
  };

  public bankDepositDataSource = (
    record: BankDeposit | undefined,
  ): Settlement[] | Sale[] | undefined => {
    const id = record ? record.id : '';
    if (record && record.depositType === 'Corte') {
      let totalCash = 0;
      let creditCard = 0;
      let debitCard = 0;
      let check = 0;
      let transfer = 0;

      this.props.settlementList.forEach((settlement: Settlement): void => {
        totalCash += settlement.totalCash ? settlement.totalCash : 0;
        creditCard += settlement.totalCredit ? settlement.totalCredit : 0;
        debitCard += settlement.totalDebit ? settlement.totalDebit : 0;
        check += settlement.totalCheque ? settlement.totalCheque : 0;
        transfer += settlement.totalTransfer ? settlement.totalTransfer : 0;
      });
      const settlemntTotalizer: Settlement[] = [
        {
          ...initSettlement,
          folio: 'Totales',
          totalCash: totalCash,
          totalCredit: creditCard,
          totalDebit: debitCard,
          totalCheque: check,
          totalTransfer: transfer,
          isTotal: true,
        },
      ];

      return this.props.settlementList[0].bankDepositId === id
        ? [...this.props.settlementList, ...settlemntTotalizer]
        : [];
    } else {
      return this.props.salesList[0].bankDepositId === id
        ? this.props.salesList
        : [];
    }
  };

  public setSalestoApply = (
    isExpanded: boolean,
    bankDeposit: BankDeposit,
  ): void => {
    if (isExpanded) {
      this.setState({ expanded: [bankDeposit.id] });
      this.props.getSettlementListByBankDeposit(
        bankDeposit.id,
        bankDeposit.depositType,
      );
    } else {
      this.setState({ expanded: [] });
    }
  };
  public settlementListContent = (): ColumnProps<Settlement | Sale>[] => [
    {
      title: 'Fecha y hora',
      dataIndex: 'movementDate',
      key: 'movementDate',
      render: (movementDate, item: Settlement | Sale): React.ReactNode => {
        return item.isTotal ? (
          <div />
        ) : (
          <div>
            {movementDate ? <Badge color={'cyan'} /> : <div />}
            {moment(movementDate).format(DATE_FORMAT)}
          </div>
        );
      },
    },
    {
      title: 'Folio Corte',
      dataIndex: 'folio',
      key: 'folio',
      render: (folio: number, item: Settlement | Sale): React.ReactNode => {
        return item.isTotal ? (
          <Row>
            {' '}
            <Text strong style={{ color: '#113C71' }}>
              {'Totales:'}
            </Text>
          </Row>
        ) : (
          <Row>{folio}</Row>
        );
      },
    },
    {
      title: 'Tipo de servicio',
      dataIndex: 'serviceType',
      key: 'serviceType',
    },
    {
      title: 'Unidad',
      dataIndex: 'unit',
      key: 'unit',
    },
    {
      title: 'Monto en efectivo',
      dataIndex: 'totalCash',
      key: 'postAmountCash',
      render: (postAmountCash, item: Settlement | Sale): React.ReactNode => {
        return item.isTotal ? (
          <div>
            <Text strong style={{ color: '#113C71' }}>
              {'Efectivo:  '}
            </Text>
            <Row>{'$' + toNumber(postAmountCash)}</Row>
          </div>
        ) : (
          <div>{'$' + toNumber(postAmountCash)}</div>
        );
      },
    },
    {
      title: 'Tarjeta crédito',
      dataIndex: 'totalCredit',
      key: 'totalCredit',
      render: (totalCredit, item: Settlement | Sale): React.ReactNode => {
        return item.isTotal ? (
          <div>
            <Text strong style={{ color: '#113C71' }}>
              {'Crédito:  '}
            </Text>
            <Row>{'$' + toNumber(totalCredit)}</Row>
          </div>
        ) : (
          <div>{'$' + toNumber(totalCredit)}</div>
        );
      },
    },
    {
      title: 'Tarjeta débito',
      dataIndex: 'totalDebit',
      key: 'totalDebit',
      render: (totalDebit, item: Settlement | Sale): React.ReactNode => {
        return item.isTotal ? (
          <div>
            <Text strong style={{ color: '#113C71' }}>
              {'Débito:  '}
            </Text>
            <Row>{'$' + toNumber(totalDebit)}</Row>
          </div>
        ) : (
          <div>{'$' + toNumber(totalDebit)}</div>
        );
      },
    },
    {
      title: 'Cheque',
      dataIndex: 'totalCheque',
      key: 'totalCheque',
      render: (totalCheque, item: Settlement | Sale): React.ReactNode => {
        return item.isTotal ? (
          <div>
            {' '}
            <Text strong style={{ color: '#113C71' }}>
              {'Cheque:  '}
            </Text>
            <Row>{'$' + toNumber(totalCheque)}</Row>
          </div>
        ) : (
          <div>{'$' + toNumber(totalCheque)}</div>
        );
      },
    },
    {
      title: 'Transferencia',
      dataIndex: 'totalTransfer',
      key: 'totalTransfer',
      render: (totalTransfer, item: Settlement | Sale): React.ReactNode => {
        return item.isTotal ? (
          <div>
            <Text strong style={{ color: '#113C71' }}>
              {'Transferencia:  '}
            </Text>
            <Row>{'$' + toNumber(totalTransfer)}</Row>
          </div>
        ) : (
          <div>{'$' + toNumber(totalTransfer)}</div>
        );
      },
    },
  ];
  public saleListContent = (): ColumnProps<Settlement | Sale>[] => [
    {
      title: 'Fecha y hora',
      dataIndex: 'endDateSave',
      key: 'endDateSave',
      render: (endDateSave): React.ReactNode => {
        return (
          <div>
            {endDateSave !== undefined ? <Badge color={'cyan'} /> : <div />}
            {moment(endDateSave).format(DATE_FORMAT)}
          </div>
        );
      },
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: 'Total Volumen',
      dataIndex: 'totalVolume',
      key: 'totalVolume',
    },
    {
      title: 'Folio',
      dataIndex: 'salesFolio',
      key: 'salesFolio',
    },
    {
      title: 'IUS',
      dataIndex: 'IUSCode',
      key: 'IUSCode',
    },
  ];
  public render = (): JSX.Element => {
    const { isFetching } = this.props;
    if (!localStorage.getItem('token')) return <Redirect to="/login" />;
    return (
      <div>
        {this.state.visibleApply && (
          <ValidateDepositModel
            bankDepositToValidate={this.state.bankDepositToValidate}
            onCancel={(): Function => this.handleCancelApply}
            onCreate={(): Function => this.handleOkApply}
            validar={this.state.validar}
            onCangeText={this.setDescription}
            settlementList={this.props.settlementList}
            distributionCenter={this.props.distributionCenter}
            isFetching={this.props.isFetching}
            title={
              this.state.validar ? 'Validar depósito' : 'Rechazar depósito'
            }
            visible={this.state.visibleApply}
            form={this.props.form}
            onChangeDifferences={this.setAmountDifferences}
          />
        )}
        <Row style={ { textAlign: 'right' } } >
          <span style={ { marginLeft: 18 } } >
            { this.state.userHasLevel && ( <DailyAuditStatusModal /> ) }
          </span>
          <span style={ { marginLeft: 18 } } >
            { this.state.allowedAuditInvoice && ( <AuditBillingModal  mode='cp' /> ) }
          </span>
        </Row>
        {this.renderFilters()}
        <Row>
          <Row>
            <p />
          </Row>
          <Row>
            <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={4}>
              <Card className="card-statement">
                <Text>Total Aplicado</Text>
                <Text>
                  <h4>
                    {'$ ' + toNumber(this.props.modelBankTotals.totalApply)}
                  </h4>
                </Text>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={4}>
              <Card className="card-statement">
                <Text>En espera de Verificación</Text>
                <Text>
                  <h4>
                    {'$ ' + toNumber(this.props.modelBankTotals.totalPending)}
                  </h4>
                </Text>
              </Card>
            </Col>
            {isAllowed([P.CAN_APPLY_DEPOSIT_IUS]) && (
              <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={4}>
                <Card className="card-statement">
                  <Text>En espera de Verificación IUS</Text>
                  <Text>
                    <h4>
                      {'$ ' +
                        toNumber(this.props.modelBankTotals.totalIusPending)}
                    </h4>
                  </Text>
                </Card>
              </Col>
            )}

            <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={4}>
              <Card className="card-statement">
                <Text>Pendiente a depositar</Text>
                <Text>
                  <h4>
                    {'$ ' +
                      toNumber(this.props.modelBankTotals.totalPendingDeposit)}
                  </h4>
                </Text>
              </Card>
            </Col>
            {isAllowed([P.CAN_APPLY_DEPOSIT_IUS]) && (
              <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={4}>
                <Card className="card-statement">
                  <Text>Pendiente a depositar IUS</Text>
                  <Text>
                    <h4>
                      {'$ ' +
                        toNumber(
                          this.props.modelBankTotals.totalIusPendingDeposit,
                        )}
                    </h4>
                  </Text>
                </Card>
              </Col>
            )}
            <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={4}>
              <Card className="card-statement">
                <Text>Diferencias</Text>
                {/* <Text>
                  <h4>
                    {'$ ' +
                      toNumber(
                        this.props.modelBankTotals.totalAmountDifferences,
                      )}
                  </h4>
                </Text> */}
                { isAllowed([ P.DEPOSIT_DIFFERENCE_VIEW ]) && ( <DifferenceModal
                  depositDifferencesStatusKey={ this.state.depositDifferencesStatus }
                  distCenterId={ this.state.distributionCenterId as DbOldValues._DistCenterId }
                  onLoad={ async depositDifferences => { console.log(1, this.state.distributionCenterId, this.props.bankDepositFilters.distributionCenterId); await this.reloadDepositDifferencesStatus(depositDifferences) } }
                /> ) }
              </Card>
            </Col>
          </Row>
          {this.state.isClarificationVisible && this.renderClarificationForm()}
          {this.state.isClarHistoryVisible && this.renderClarificationHistory()}
          <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
            <List
              dataSource={this.props.bankDepoistPage.content}
              renderItem={(item: BankDeposit, index: number): JSX.Element => (
                <List.Item>
                  <List.Item.Meta
                    key={index}
                    description={
                      <Row
                        style={{
                          background: 'white',
                          padding: '10px',
                          margin: '-7px',
                        }}
                      >
                        <Col span={20}>{this.renderDetails(item)}</Col>
                        <Col span={4}>{this.renderActions(item)}</Col>
                      </Row>
                    }
                  />
                </List.Item>
              )}
            />
          </Col>
          <p />
          <Col
            xs={0}
            sm={0}
            md={0}
            lg={24}
            xl={24}
            xxl={24}
            style={{ backgroundColor: 'white' }}
          >
            <Table
              dataSource={this.props.bankDepoistPage.content}
              onExpand={(expand: boolean, record: BankDeposit): void =>
                this.setSalestoApply(expand, record)
              }
              size='small'
              style={{  fontSize: '12px' }}
              rowKey={(record): string => record.id}
              pagination={false}
              loading={isFetching}
              expandedRowKeys={this.state.expanded}
              expandedRowRender={(record): JSX.Element =>
                (this.bankDepositDataSource(record) || []).length > 0 ? (
                  <Table
                    size='small'
                    className={'custom-table'}
                    dataSource={this.bankDepositDataSource(record)}
                    pagination={false}
                    columns={
                      record.depositType === 'Corte'
                        ? this.settlementListContent()
                        : this.saleListContent()
                    }
                  />
                ) : (
                  <div />
                )
              }
            >
              <Table.Column
                title="Estatus"
                dataIndex="status"
                render={(
                  text: string,
                  record: BankDeposit,
                ): React.ReactNode => {
                  return record.status === 'A validar' ? (
                    <Tag
                      color="#EDFAFF"
                      style={{ color: '#248BEC', borderColor: '#248BEC', fontSize: '10px' }}
                    >
                      {record.status}
                    </Tag>
                  ) : record.status === 'Aplicado' ? (
                    <Tag
                      color="#F0FFE9"
                      style={{ color: '#08A20E', borderColor: '#74DE62', fontSize: '10px' }}
                    >
                      {record.status}
                    </Tag>
                  ) : (
                    <Tag
                      color="#FFF4F4"
                      style={{ color: '#CC3B5C', borderColor: '#FC9C9E', fontSize: '10px' }}
                    >
                      {record.status}
                    </Tag>
                  );
                }}
              />
              <Table.Column
                title="Fecha registro"
                dataIndex="createdAt"
                render={(
                  text: string,
                  record: BankDeposit,
                ): JSX.Element => {
                  return <Text style={{  fontSize: '13px' }}> {moment(record.createdAt).format('YYYY-MM-DD HH:mm:ss')}</Text>;
                }}
              />
              <Table.Column
                title="Fecha depósito"
                dataIndex="depositDate"
                render={(
                  text: string,
                  record: BankDeposit,
                ): JSX.Element => {
                  return <Text style={{  fontSize: '13px' }}> {moment(record.depositDate).format('YYYY-MM-DD HH:mm:ss')}</Text>;
                }}
              />
              <Table.Column
                title="CDD"
                dataIndex="distributionCenterId"
                render={(text: string): JSX.Element => {
                  const x: DistributionCenter =
                    this.props.distributionCenter.find(
                      (d: DistributionCenter): boolean => d.id === text,
                    ) || initDistributionCenter;
                  return <Text style={{  fontSize: '13px' }}>{x.name}</Text>;
                }}
              />
              <Table.Column
                title="Monto"
                dataIndex="amount"
                render={(postAmountCash: number): React.ReactNode => {
                  return <Text style={{  fontSize: '13px' }}>{'$' + toNumber(postAmountCash)}</Text>;
                }}
              />
              { isAllowed([ P.CAN_SEE_ALL_PERM_DEPOSIT ]) && (
                 <Table.Column
                 title="sp"
                 dataIndex="amountsp"
                 render={(amountsp: number): React.ReactNode => {
                   return <Text style={{  fontSize: '13px' }}>{'$' + toNumber(amountsp)}</Text>;
                 }}
               />
              )}
              <Table.Column
                title="Diferencia"
                dataIndex="amountDifferences"
                render={(amountDifference: number): React.ReactNode => {
                  return <Text style={{  fontSize: '13px' }}>{'$' + toNumber(amountDifference || 0)}</Text>;
                }}
              />
              <Table.Column 
              title="Banco" 
              dataIndex="bank"
              render={(bank: number): React.ReactNode => {
                return <Text style={{  fontSize: '13px' }}>{bank}</Text>;
              }}
               />
              <Table.Column
                title="Cuenta de destino"
                dataIndex="acountRecipt"
                render={(acountRecipt: number): React.ReactNode => {
                  return <Text style={{  fontSize: '13px' }}>{acountRecipt}</Text>;
                }}
              />
              <Table.Column
                title="Folio depósito"
                dataIndex="folioDeposit"
                render={(folioDeposit: number): React.ReactNode => {
                  return <Text style={{  fontSize: '13px' }}>{folioDeposit}</Text>;
                }}
              />
              <Table.Column
                title="Acciones"
                dataIndex="invoice"
                render={(
                  text: string,
                  record: BankDeposit,
                ): React.ReactNode => {
                  return this.renderActions(record);
                }}
              />
            </Table>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <Pagination
              showSizeChanger
              pageSizeOptions={['25', '50', '75', '100']}
              defaultPageSize={25}
              disabled={this.props.isFetching}
              current={(this.props.bankDepoistPage.number || 0) + 1}
              total={this.props.bankDepoistPage.totalElements}
              onShowSizeChange={this.handlePaginationChange}
              onChange={this.handlePaginationChangeNew}
            />
          </Col>
        </Row>
      </div>
    );
  };
}

const mapStateToProps = (states: RootState): StateProps => {
  return {
    bankDepoistPage: states.bankDepositState.bankDepositList,
    bankDepositToApply: states.bankDepositState.bankDepositToApply,
    modelBankTotals: states.bankDepositState.dashboardValue,
    distributionCenter:
      states.bankDepositState.distributionCenter.distributionCenter,
    isFetching: states.bankDepositState.isFetching,
    salesList: Object.values(states.bankDepositState.salesList),
    settlementList: Object.values(states.bankDepositState.settlementList),
    settlementModalList: states.bankDepositState.settlementModalList,
    bankDepositSave: states.bankDepositState.bankDepositSave,
    bankDepositFilters: states.bankDepositState.bankDepositFilters,
    clarifications: Object.values(states.bankDepositState.clarifications),
  };
};

const mapDispatchToProps: DispatchProps = {
  resetModal,
  save,
  getSettlementList,
  getSettlementListByBankDeposit,
  page,
  downloadFile,
  downloadTicket,
  totalCalculator,
  apply,
  downloadDeposit,
  getClarificationsHistory,
  saveClarification,
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create()(SalesListComponent));


/* let formatCurrency = (value: number, { unvalidReturn = 'Monto inválido' } = {}): string => {
  if(isNaN(value)) return unvalidReturn;
  return `$ ${(+value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
}; */
/* 
{
  author: 'Han Solo',
  avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
  content: <p>{this.state.value}</p>,
  datetime: moment().fromNow(),
}
*/

type DifferenceModalProps = {
  depositDifferencesStatusKey: DbOldValues._DepositDifferenceStatus,
  distCenterId: DbOldValues._DistCenterId,
  onLoad: (depositDifferences: DbOldTypes.DepositDifference[]) => Promise<void>,
};
const DifferenceModal = (props: DifferenceModalProps) => {
  let { formatCurrency } = Globals.Utils.Number;
  let { openBucketFileInNewTab } = Globals.Utils.File;
  let { FileInput } = Globals.Components;
  let { CREATED, RESOLVED, CLOSED } = DbOldValues.DepositDifferenceStatus;
  let { distCenterId } = props;

  const tokenInfo = getCurrentTokenInfo();

  let [ visible, setVisible ] = useState(false);
  let [ isLoading, setIsLoading ] = useState(true);
  let [ isError, setIsError ] = useState(false);
  let [ depositDifferences, setDepositDifferences ] = useState([] as DbOldTypes.DepositDifference[]);
  let [ depositDifferencesDatas, setDepositDifferencesDatas ] = useState([ CREATED, RESOLVED, CLOSED ].map(key => ({
    key, label: DbOldDetails.depositDifferenceStatus[key].pluralName, values: [0, 0, 0] as [number, number, number],
  })));

  let [ expandedRowKeys, setExpandedRowComments ] = useState([] as string[]);
  let [ isLoadingComments, setIsLoadingComments ] = useState(false);
  let [ comments, setComments ] = useState([] as (ApiTypes.ExtendedDepositDifferenceComment & { editing?: boolean })[]);
  let [ commentContent, setCommentContent ] = useState('');
  let [ commentFile, setCommentFile ] = useState(null as null | File);
  let [ commentFileId, setCommentFileId ] = useState('');
  let [ editCommentFileAction, setEditCommentFileAction ] = useState(0);

  let loadDepositDifferences = useCallback(async () => {
    console.log(0, `Getting deposit differences.`, distCenterId);
    let result = await callApi(ApiRoute.GetDepositDifferences, { filters: { distCenterId } }) || null as never;
    console.log(result);
    if(!result || !result.success || !('depositDifferences' in result)){
      setIsError(true);
      return;
    }
    let { depositDifferences } = result;
    await props.onLoad(result.depositDifferences);
    setDepositDifferences(result.depositDifferences);
    setDepositDifferencesDatas(previous => {
      let newDatas: typeof previous = previous.map(({ key, label }) => {
        let keyDifferences = depositDifferences.filter(depositDifference => depositDifference.status_key === key);
        let values: [number, number, number] = [keyDifferences.length, 0, 0];
        for(let keyDifference of keyDifferences) values[keyDifference.amount > 0 ? 1 : 2] += keyDifference.amount;
        return { key, label, values };
      });
      return newDatas;
    });
  }, [ distCenterId ]);

  let editDepositDifferenceStatus = useCallback(async (id: string, statusKey: DbOldValues._DepositDifferenceStatus) => {
    let result = await callApi(ApiRoute.EditDepositDifferenceStatus, { depositDifferenceId: id, statusKey });
    console.log(result);
    if(!result || !result.success){
      alert((result && result.msg) || `Error actualizando la diferencia.`);
      return;
    }
    await loadDepositDifferences();
  }, [ loadDepositDifferences ]);

  let loadDepositDifferenceComments = useCallback(async (depositDifferenceId: string) => {
    console.log(`Getting deposit difference comments of '${depositDifferenceId}'.`);
    setIsLoadingComments(true);
    let result = await callApi(ApiRoute.GetDepositDifferenceComments, { filters: { depositDifferenceId } }) || null as never;
    console.log(result);
    if(!result || !result.success){
      Popups.notifyError((result && result.msg) || 'Error obteniendo los comentarios.');
      return;
    }
    console.log(result.depositDifferenceComments);
    setComments(result.depositDifferenceComments);
    setIsLoadingComments(false);
  }, []);

  let touchDepositDifferenceComment = useCallback(async (depositDifferenceId: string, depositDifferenceCommentId?: string) => {
    console.log(commentContent, commentFile, commentFileId);
    if(!commentContent){
      Popups.notifyError(`El comentario debe incluír texto.`);
      return;
    }
    let result;
    if(!depositDifferenceCommentId){
      result = await callApiWithFiles(ApiRoute.AddDepositDifferenceComment, {
        depositDifferenceId,
        content: commentContent,
        fileId: commentFileId,
      }, commentFile ? [ { id: commentFileId, file: commentFile } ] : []);
    }else{
      result = await callApiWithFiles(ApiRoute.EditDepositDifferenceComment, {
        depositDifferenceCommentId,
        keepFile: editCommentFileAction === 0,
        content: commentContent,
        fileId: editCommentFileAction === 2 ? commentFileId : undefined,
      }, commentFile && editCommentFileAction === 2 ? [ { id: commentFileId, file: commentFile } ] : []);
    }
    if(!result || !result.success){
      Popups.notifyError((result && result.msg) || 'Error procesando el comentario.');
      return;
    }
    setCommentContent('');
    setEditCommentFileAction(0);
    setCommentFile(null);
    setCommentFileId('');
    await loadDepositDifferenceComments(depositDifferenceId);
  }, [ commentContent, commentFile, commentFileId, editCommentFileAction, loadDepositDifferenceComments ]);
  let removeDepositDifferenceComment = useCallback(async (depositDifferenceId: string, depositDifferenceCommentId: string) => {
    let result = await callApi(ApiRoute.DeleteDepositDifferenceComment, { depositDifferenceCommentId });
    if(!result || !result.success){
      Popups.notifyError((result && result.msg) || 'Error eliminando el comentario.');
      return;
    }
    await loadDepositDifferenceComments(depositDifferenceId);
  }, [ loadDepositDifferenceComments ]);
  let toggleEditComment = useCallback((commentId: string) => {
    setEditCommentFileAction(0);
    setCommentFile(null);
    setCommentFileId('');
    setComments(previousComments => {
      let newComments: typeof previousComments = [];
      for(let comment of previousComments){
        if(comment.id === commentId){
          comment.editing = !comment.editing;
          setCommentContent(comment.editing ? comment.content : '');
          if(comment.editing) setEditCommentFileAction(comment.file ? 0 : 2);
        }else{
          comment.editing = false;
        }
        newComments.push(comment);
      }
      return newComments;
    });
  }, []);
  
  useEffect(() => {
    if(visible){
      setIsError(false);
      setIsLoading(true);
      loadDepositDifferences().then(() => setIsLoading(false));
    }
  }, [ visible, loadDepositDifferences ]);

  const commentTextArea = (
    <Form.Item htmlFor="comments">
      <Input.TextArea value={ commentContent } onChange={ event => setCommentContent(event.target.value.slice(0, 500)) } />
    </Form.Item>
  );
  const commentFileInput = (
    <FileInput id={ 'commentFileInput' } file={ commentFile } onChange={ file => {
      console.log(`Changed file status.`, file);
      setCommentFile(file);
      setCommentFileId(file ? `file_${+new Date()}` : '');
      //setTimeout(() => setDepositDifferences([...depositDifferences]), 400);
    } } />
  );

  let modalContent: JSX.Element;
  if(isError || !tokenInfo) modalContent = (
    <div style={{ marginTop: 20, textAlign: 'center' }} >
      <span> Error obteniendo las diferencias. </span>
    </div>
  );
  else if(isLoading) modalContent = (
    <div style={{ marginTop: 20, textAlign: 'center' }} >
      <Spin size="large" />
    </div>
  );
  else modalContent = (
    <div>
      <Row>
        { depositDifferencesDatas.map((data, index) => {
          let dataItemId = `dataItem_${index}`;
          return (
            <Col key={ dataItemId } span={ 8 } >
              <div className="ant-row ant-form-item" style={{ marginBottom: 0, paddingBottom: 0, paddingRight: 32 }} >
                <label style={{ fontSize: 18 }} className="" > { data.label }: </label>
              </div>
              { [ 'Cantidad', 'A favor', 'En contra' ].map((label, index) => (
                <div key={ `label_${index}` } className="ant-row ant-form-item" style={{ marginBottom: 0, paddingBottom: 0, paddingRight: 32 }} >
                  <label style={{ fontSize: 14 }} className="" > { label } </label>
                  <span style={{ float: 'right' }} > { !index ? data.values[index] : formatCurrency(data.values[index]) } </span>
                </div>
              )) }
            </Col>
          );
        }) }
      </Row>
      <Divider type="horizontal" style={{ marginTop: 12, marginBottom: 12 }} />
      <Row>
        <Table
          dataSource={ depositDifferences }
          rowKey={ row => row.id }
          pagination={{ pageSize: 10 }}
          size="small"
          onExpand={ (expanded, depositDifference) => {
            if(expanded){
              loadDepositDifferenceComments(depositDifference.id).then(() => {
                setExpandedRowComments([ depositDifference.id ]);
              });
            }else{
              setExpandedRowComments([]);
            }
          } }
          expandedRowKeys={ expandedRowKeys }
          expandedRowRender={ depositDifference => {
            if(isLoadingComments) return (
              <div style={{ marginTop: 20, textAlign: 'center' }} >
                <Spin size="large" />
              </div>
            );
            return (
              <Row>
                <div className="ant-row ant-form-item" style={{ marginBottom: 0, paddingBottom: 0, paddingRight: 32 }} >
                  <label style={{ fontSize: 18 }} className="" > Comentarios </label>
                </div>
                { comments.map(({ file, ...comment }, index) => {
                  let actions: React.ReactNode[] = [];
                  if(tokenInfo && tokenInfo.email === comment.created_by_user_email) actions.push(
                    <span className='commentAction' onClick={ () => { toggleEditComment(comment.id) } } > { comment.editing ? 'Cancelar' : 'Editar' } </span>,
                    <span className='commentAction' onClick={ () => { removeDepositDifferenceComment(depositDifference.id, comment.id) } } > Eliminar </span>,
                  );
                  if(file) actions.push( <span className='commentAction' onClick={ () => { openBucketFileInNewTab(file) } } > Ver archivo </span> );
                  let content: React.ReactNode = <p style={ { marginBottom: 0 } } > {comment.content} </p>;
                  if(comment.editing) content = (
                    <Row>
                      <Col span={ 14 } >
                        { commentTextArea }
                      </Col>
                      <Col span={ 8 } style={ { paddingLeft: 12 } } >
                        { !file ? commentFileInput : (
                          <Radio.Group value={ editCommentFileAction } onChange={ event => setEditCommentFileAction(event.target.value) } >
                            <Radio value={ 0 } style={ { display: 'block' } } > Mantener archivo </Radio>
                            <Radio value={ 1 } style={ { display: 'block' } } > Remover archivo </Radio>
                            <Radio value={ 2 } style={ { display: 'block' } } >
                              Cambiar archivo
                              { editCommentFileAction === 2 ? commentFileInput : null }
                            </Radio>
                          </Radio.Group>
                        ) }
                      </Col>
                      <Col span={ 2 } style={ { textAlign: 'right' } } >
                        <Button type={ 'primary' } onClick={ () => touchDepositDifferenceComment(depositDifference.id, comment.id) } > Editar </Button>
                      </Col>
                    </Row>
                  );
                  return (
                    <Comment
                      key={ comment.id }
                      content={ content  }
                      author={ <span> { comment.createdByUserFullName } </span> }
                      avatar={ <Avatar src={ 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png' } /> }
                      actions={ actions }
                    />
                  );
                }) }
                {
                  comments.some(comment => comment.editing) ? null : (
                    <div>
                      <Row>
                        { commentTextArea }
                      </Row>
                      <Row>
                        <Col span={ 20 } >
                          { commentFileInput }
                        </Col>
                        <Col span={ 4 } style={ { textAlign: 'right' } } >
                          <Button type={ 'primary' } onClick={ () => touchDepositDifferenceComment(depositDifference.id) } > Añadir comentario </Button>
                        </Col>
                      </Row>
                    </div>
                  )
                }
              </Row>
            );
          } }
          columns={ [
            {
              title: 'Fecha',
              dataIndex: 'created_at',
              key: 'created_at',
              render: (_, row) => row.created_at.replace('T', ', '),
            },
            {
              title: 'Usuario',
              dataIndex: 'created_by_user_email',
              key: 'created_by_user_email',
              render: (_, row) => row.created_by_user_email,
            },
            {
              title: 'Método de pago',
              dataIndex: 'payment_way',
              key: 'payment_way',
              filters: [
                { text: DbOldDetails.salePaymentWay[0].name, value: `0` },
                { text: DbOldDetails.salePaymentWay[1].name, value: `1` },
                { text: DbOldDetails.salePaymentWay[2].name, value: `2` },
                { text: DbOldDetails.salePaymentWay[3].name, value: `3` },
                { text: DbOldDetails.salePaymentWay[4].name, value: `4` },
              ],
              onFilter: (value, row) => `${row.payment_way}` === value,
              render: (_, row) => DbOldDetails.salePaymentWay[row.payment_way].name
            },
            {
              title: 'Monto',
              dataIndex: 'amount',
              key: 'amount',
              render: (_, row) => formatCurrency(row.amount)
            },
            {
              title: 'Estatus',
              dataIndex: 'status_key',
              key: 'status_key',
              filters: [
                { text: DbOldDetails.depositDifferenceStatus[CREATED].name, value: CREATED },
                { text: DbOldDetails.depositDifferenceStatus[RESOLVED].name, value: RESOLVED },
                { text: DbOldDetails.depositDifferenceStatus[CLOSED].name, value: CLOSED },
              ],
              onFilter: (value, row) => row.status_key === value,
              render: (_, row) => {
                let statusDetail = DbOldDetails.depositDifferenceStatus[row.status_key];
                return (
                  <Tag color={ statusDetail.tagColor } >
                    { statusDetail.name }
                  </Tag>
                )
              },
            },
            {
              title: 'Acciones',
              key: 'actions',
              align: 'right',
              render: (_, row) => {
                let buttons = row.file ? [
                  <Button key={ `file_${row.id}` } onClick={ () => { openBucketFileInNewTab(row.file as DbOldTypes.BucketFile) } } > Archivo </Button>
                ] : [];
                switch(row.status_key){
                  case CREATED: buttons.push(
                    <Button key={ `resolve_${row.id}` } onClick={ () => {
                      Modal.confirm({
                        title: `¿Marcar diferencia como resuelta?`,
                        content: `Esta acción se puede deshacer.`,
                        onOk: () => editDepositDifferenceStatus(row.id, RESOLVED),
                        onCancel: () => {},
                      });
                    } } > Resolver </Button>
                  ); break;
                  case RESOLVED: buttons.push(
                    <Button key={ `close_${row.id}` } onClick={ () => {
                      Modal.confirm({
                        title: `¿Cerrar diferencia?`,
                        content: `Esta acción se puede deshacer.`,
                        onOk: () => editDepositDifferenceStatus(row.id, CLOSED),
                        onCancel: () => {},
                      });
                    } } > Cerrar </Button>
                  ); break;
                  case CLOSED: buttons.push(
                    <Button key={ `open_${row.id}` } onClick={ () => {
                      Modal.confirm({
                        title: `¿Abrir diferencia?`,
                        content: `La diferencia quedará como no resuelta.`,
                        onOk: () => editDepositDifferenceStatus(row.id, CREATED),
                        onCancel: () => {},
                      });
                    } } > Abrir </Button>
                  ); break;
                }
                return buttons;
              }
            },
          ] }
        />
      </Row>
    </div>
  );

  let color = DbOldDetails.depositDifferenceStatus[props.depositDifferencesStatusKey].color;

  return (
    <div>
      <Button type="primary" style={{ backgroundColor: color, borderColor: color, color: 'white', fontWeight: 'bold' }} onClick={ () => setVisible(true) } >
        Gestionar diferencias
      </Button>
      <Modal
        width={ 1024 }
        title="Gestionar diferencias"
        centered
        visible={ visible }
        footer={ null }
        onCancel={ () => setVisible(false) }
      >
        { modalContent }
      </Modal>
    </div>
  );
};

type AuditDepositModalProps = {};
const DailyAuditStatusModal = (props: AuditDepositModalProps) => {
  const BTN_COLOR = 'darkblue';
  //let { formatCurrency } = Globals.Utils.Number;

  let [ visible, setVisible ] = useState(false);
  let [ isLoading, setIsLoading ] = useState(true);
  let [ isError, setIsError ] = useState(false);
  let [ dass, setDass ] = useState([] as DbOldTypes.DailyAuditStatus[]);
  let [ airAmountByDas, setAirAmountByDas ] = useState<{ [date in string]?: number }>({});

  let [ expandedDasIds, setExpandedDasIds ] = useState([] as string[]);
  let [ expandedCutIds, setExpandedCutIds ] = useState([] as string[]);
  let [ isLoadingCuts, setIsLoadingCuts ] = useState(false);
  let [ cuts, setCuts ] = useState([] as ApiTypes.DasCut[]);
  let [ sales, setSales ] = useState([] as ApiTypes.DasSale[]);
  let [ cutSales, setCutSales ] = useState([] as typeof sales);
  //let [ isLoadingSales, setIsLoadingSales ] = useState(false);

  function valueOfCutReviewSnapshot<K extends keyof DbOldTypes.SettlementAuditReviewSnapshot>(cut: ApiTypes.DasCut, key: K): null | DbOldTypes.SettlementAuditReviewSnapshot[K] {
    let { [DbOldValues.AuditStatus.Reviewed]: reviewSnapshot = null } = cut.audit_snapshot || {};
    if(!reviewSnapshot || !reviewSnapshot.apply) return null;
    return reviewSnapshot[key] || null;
  }
  function valueOfSaleArrangeSnapshot<K extends keyof DbOldTypes.SaleAuditArrangeSnapshot>(sale: ApiTypes.DasSale, key: K, timeline: 'before' | 'after'): null | DbOldTypes.SaleAuditArrangeSnapshot[K] {
    let { [DbOldValues.AuditStatus.Arranged]: arrangeSnapshot = null, [DbOldValues.AuditStatus.Received]: beforeArrangeSnapshot = null } = sale.audit_snapshot || {};
    if(timeline === 'before'){
      if(!beforeArrangeSnapshot) return null;
      return beforeArrangeSnapshot[key] || null;
    }
    if(!arrangeSnapshot) return null;
    return arrangeSnapshot[key] || null;
  }
  function valueOfSaleReviewSnapshot<K extends keyof DbOldTypes.SaleAuditReviewSnapshot>(sale: ApiTypes.DasSale, key: K): null | DbOldTypes.SaleAuditReviewSnapshot[K] {
    let { [DbOldValues.AuditStatus.Reviewed]: reviewSnapshot = null } = sale.audit_snapshot || {};
    if(!reviewSnapshot || !reviewSnapshot.apply) return null;
    return reviewSnapshot[key];
  }
  function saleWasArranged(sale: ApiTypes.DasSale): boolean {
    return (sale.audit_role && (sale.audit_role.typeKey !== DbOldValues.SaleAuditRoleType.Untouched)) || false;
  }
  function saleSpToCpDescription(sale: ApiTypes.DasSale): string {
    let { audit_role: ar = null } = sale;
    if(ar){
      let saleMapping = {
        before: { tag: valueOfSaleArrangeSnapshot(sale, 'tag', 'before'), amount: valueOfSaleArrangeSnapshot(sale, 'total', 'before'), volume: valueOfSaleArrangeSnapshot(sale, 'total_volume', 'before') },
        after: { tag: valueOfSaleArrangeSnapshot(sale, 'tag', 'after'), amount: valueOfSaleArrangeSnapshot(sale, 'total', 'after'), volume: valueOfSaleArrangeSnapshot(sale, 'total_volume', 'after') },
      } as const;
      switch(ar.typeKey){
        case DbOldValues.SaleAuditRoleType.Untouched: return `-`;
        case DbOldValues.SaleAuditRoleType.Rejected: return `---`
        case DbOldValues.SaleAuditRoleType.BecameSp:
        case DbOldValues.SaleAuditRoleType.BecameCp:
        case DbOldValues.SaleAuditRoleType.Fallback:
        case DbOldValues.SaleAuditRoleType.DonorToFallback:
          return `${saleMapping.before.tag || ''}(${saleMapping.before.amount || ''}, ${saleMapping.before.volume}L)\n  -> ${saleMapping.after.tag || ''}(${saleMapping.after.amount || ''}, ${saleMapping.after.volume}L)`;
      }
    }
    return `-`
  }

  let loadDass = useCallback(async () => {
    console.log(`Getting all das.`);
    setIsLoading(true);
    setIsError(false);
    let dateMinus80Days = Globals.Utils.Date.dateToStringMinusDays(new Date(), 80);
    let result = await callApi(ApiRoute.GetDailyAuditStatuss, { filters: { date: dateMinus80Days } });
    console.log(result);
    if(!result || !result.success){
      setIsError(true);
      return;
    }
    setDass(result.dailyAuditStatuss);
    setAirAmountByDas(result.airAmountByDas);
    setIsLoading(false);
    /* setDass([
      { id_date: '2023-04-12', review_transition_status: DbOldValues.DasReviewTransitionStatus.Pending, review_snapshot: { amount: 2345 } } as any as DbOldTypes.DailyAuditStatus,
      { id_date: '2023-04-13', review_transition_status: DbOldValues.DasReviewTransitionStatus.ReceivedByLevel2, review_snapshot: { amount: 2345 } } as any as DbOldTypes.DailyAuditStatus,
      { id_date: '2023-04-14', review_transition_status: DbOldValues.DasReviewTransitionStatus.GivenByLevel2, review_snapshot: { amount: 2345 } } as any as DbOldTypes.DailyAuditStatus,
      { id_date: '2023-04-15', review_transition_status: DbOldValues.DasReviewTransitionStatus.ReceivedByLevel1, review_snapshot: { amount: 2345 } } as any as DbOldTypes.DailyAuditStatus,
      { id_date: '2023-04-16', review_transition_status: DbOldValues.DasReviewTransitionStatus.GivenByLevel1, review_snapshot: { amount: 2345 } } as any as DbOldTypes.DailyAuditStatus,
      { id_date: '2023-04-17', review_transition_status: DbOldValues.DasReviewTransitionStatus.ClosedByLevel0, review_snapshot: { amount: 2345 } } as any as DbOldTypes.DailyAuditStatus,
    ]); */
  }, []);
  let loadDasCuts = useCallback(async (dasId: string): Promise<boolean> => {
    setIsLoadingCuts(true);
    let result = await callApi(ApiRoute.GetDasCutsAndSales, { dasId });
    if(!result || !result.success || !('cuts' in result)){
      return false;
    }
    setCuts( result.cuts.filter(cut => cut.audit_role_type_key !== DbOldValues.SettlementAuditRoleType.Untouched || valueOfCutReviewSnapshot(cut, 'apply')) );
    setSales( result.sales.filter(sale => saleWasArranged(sale) || valueOfSaleReviewSnapshot(sale, 'apply') ) );
    setCutSales([]);
    setIsLoadingCuts(false);
    return true;
  }, []);

  let editDasReviewTransition = useCallback(async (id: string, statusKey: DbOldValues._DasReviewTransitionStatus) => {
    let result = await callApi(ApiRoute.EditDailyAuditStatusReviewTransition, { id, statusKey, transition: {
      datetime: `${Globals.Utils.Date.dateToString()}T${Globals.Utils.Date.timeToString()}`,
      user_email: '',
      amount: 0,
      notes: '',
    } });
    console.log(result);
    if(!result || !result.success){
      alert((result && result.msg) || `Error actualizando la auditoría.`);
      return;
    }
    await loadDass();
  }, [ loadDass ]);
  
  useEffect(() => {
    if(visible){
      //setIsError(false);
      //setIsLoading(true);
      loadDass();// .then(() => setIsLoading(false));
    }
  }, [ visible, loadDass ]);

  let modalContent: JSX.Element;
  if(isError) modalContent = (
    <div style={{ marginTop: 20, textAlign: 'center' }} >
      <span> Error obteniendo las diferencias. </span>
    </div>
  );
  else if(isLoading) modalContent = (
    <div style={{ marginTop: 20, textAlign: 'center' }} >
      <Spin size="large" />
    </div>
  );
  else modalContent = (
    <div style={ { height: '88vh', overflowY: 'auto' } } >
      <Row>
        <Table
          dataSource={ dass }
          rowKey={ row => row.id }
          pagination={ { pageSize: 20 } }
          size="small"
          onExpand={ (expanded, das) => {
            if(expanded){
              loadDasCuts(das.id).then((success) => {
                setExpandedDasIds(success ? [ das.id ] : []);
              });
            }else{
              setExpandedCutIds([]);
              setExpandedDasIds([]);
            }
          } }
          expandedRowKeys={ expandedDasIds }
          expandedRowRender={ das => {
            if(isLoadingCuts) return (
              <div style={ { marginTop: 20, textAlign: 'center' } } >
                <Spin size="large" />
              </div>
            );
            return (
              <div style={ { width: '90vw', margin: '0 auto' } } >
                <Row>
                  <Table
                    dataSource={ cuts }
                    rowKey={ row => row.id }
                    pagination={ { pageSize: 14 } }
                    size="small"
                    onExpand={ (expanded, cut) => {
                      if(expanded){
                        setCutSales(sales.filter(sale => sale.cut_id === cut.id));
                        setExpandedCutIds([ cut.id ]);
                      }else{
                        setExpandedCutIds([]);
                      }
                    } }
                    expandedRowKeys={ expandedCutIds }
                    expandedRowRender={ cut => {
                      return (
                        <div style={ { width: '80vw', margin: '0 auto' } } >
                          <Row>
                            <Table
                              dataSource={ cutSales }
                              rowKey={ row => row.id }
                              pagination={ { pageSize: 12 } }
                              size="small"
                              columns={ [
                                { title: 'Folio Venta', key: 'folio', render: (_, row) => row.sales_folio },
                                { title: 'Folio G4S', key: 'folioBefore', render: (_, row) => valueOfSaleArrangeSnapshot(row, 'sales_folio', 'before') },
                                { title: 'Auditada', key: 'apply', render: (_, row) => valueOfSaleReviewSnapshot(row, 'apply') ? 'Sí' : 'No' },
                                { title: 'Monto O', key: 'amount', render: (_, row) => valueOfSaleReviewSnapshot(row, 'amount') || '-' },
                                { title: 'Monto F', key: 'finalAmount', render: (_, row) => valueOfSaleReviewSnapshot(row, 'finalAmount') || '-' },
                                { title: 'Monto', key: 'reviewAmount', render: (_, row) => valueOfSaleReviewSnapshot(row, 'reviewAmount') || '-' },
                                { title: 'Volumen O', key: 'volume', render: (_, row) => valueOfSaleReviewSnapshot(row, 'volume') || '-' },
                                { title: 'Volumen F', key: 'finalVolume', render: (_, row) => valueOfSaleReviewSnapshot(row, 'finalVolume') || '-' },
                                { title: 'Volumen', key: 'reviewVolume', render: (_, row) => valueOfSaleReviewSnapshot(row, 'reviewVolume') || '-' },
                                { title: `Cambio`, key: 'sptocp', render: (_, row) => saleSpToCpDescription(row) },
                              ] }
                            />
                          </Row>
                        </div>
                      );
                    } }
                    columns={ [
                      { title: 'Folio Corte', key: 'folio', render: (_, row) => row.folio },
                      { title: 'Auditado', key: 'apply', render: (_, row) => valueOfCutReviewSnapshot(row, 'apply') ? 'Sí' : 'No' },
                      { title: 'Monto O', key: 'amount', render: (_, row) => valueOfCutReviewSnapshot(row, 'amount') || '-' },
                      { title: 'Monto F', key: 'finalAmount', render: (_, row) => valueOfCutReviewSnapshot(row, 'finalAmount') || '-' },
                      { title: 'Contado O', key: 'amountCash', render: (_, row) => valueOfCutReviewSnapshot(row, 'amountCash') || '-' },
                      { title: 'Contado F', key: 'finalAmountCash', render: (_, row) => valueOfCutReviewSnapshot(row, 'finalAmountCash') || '-' },
                      { title: 'Monto', key: 'reviewAmount', render: (_, row) => valueOfCutReviewSnapshot(row, 'reviewAmount') || '-' },
                      { title: 'Volumen O', key: 'volume', render: (_, row) => valueOfCutReviewSnapshot(row, 'volume') || '-' },
                      { title: 'Volumen F', key: 'finalVolume', render: (_, row) => valueOfCutReviewSnapshot(row, 'finalVolume') || '-' },
                      { title: 'Vol Contado O', key: 'volumeCash', render: (_, row) => valueOfCutReviewSnapshot(row, 'volumeCash') || '-' },
                      { title: 'Vol Contado F', key: 'finalVolumeCash', render: (_, row) => valueOfCutReviewSnapshot(row, 'finalVolumeCash') || '-' },
                      { title: 'Volumen', key: 'reviewVolume', render: (_, row) => valueOfCutReviewSnapshot(row, 'reviewVolume') || '-' },
                    ] }
                  />
                </Row>
              </div>
            );
          } }
          columns={ [
            { title: 'Fecha', key: 'id_date', render: (_, row) => row.date },
            { title: 'Gerencia', key: 'dist_center_id', render: (_, row) => DbOldDetails.distCenterId[row.dist_center_id].name },
            { title: 'Monto', dataIndex: 'amount', key: 'amount', render: (_, row) => ((row.review_snapshot && row.review_snapshot.amount) || 0).toFixed(0) },
            { title: 'Factura', key: 'airsAmount', render: (_, row) => (airAmountByDas[row.id] || 0).toFixed(0) },
            {
              title: 'Estatus',
              dataIndex: 'status_key',
              key: 'status_key',
              /* filters: [
                { text: DbOldDetails.depositDifferenceStatus[CREATED].name, value: CREATED },
                { text: DbOldDetails.depositDifferenceStatus[RESOLVED].name, value: RESOLVED },
                { text: DbOldDetails.depositDifferenceStatus[CLOSED].name, value: CLOSED },
              ],
              onFilter: (value, row) => row.status_key === value, */
              render: (_, row) => {
                //console.log(row, row.review_transition_status, DbOldDetails.dasReviewTransitionStatus);
                let statusDetail = DbOldDetails.dasReviewTransitionStatus[row.review_transition_status_key];
                return (
                  <Tag color={ (statusDetail && statusDetail.tagColor) || 'volcano' } >
                    { (statusDetail && statusDetail.name) || 'Sin estatus' }
                  </Tag>
                )
              },
            },
            {
              title: 'Acciones',
              key: 'actions',
              align: 'right',
              render: (_, row) => {
                let buttons = [
                  <Button key={ `download_${row.id}` } onClick={ async () => {
                    let result = await callApiGetFile(ApiRoute.GetDasCutCsv, { dasId: row.id, mode: 'FULL' });
                    if(result) Globals.Utils.File.downloadBlob(result, `Cortes_${row.date}_${DbOldDetails.distCenterId[row.dist_center_id].fileName}.csv`);
                    else alert(`Error obteniendo archivo.`);
                  } } > Descargar </Button>
                ];
                switch(row.review_transition_status_key){
                  case DbOldValues.DasReviewTransitionStatus.Pending: buttons.push(
                    <Button key={ `action_${row.id}` } onClick={ () => {
                      Modal.confirm({
                        title: `¿Confirmmar recibido?`,
                        content: ``,
                        onOk: () => editDasReviewTransition(row.id, DbOldValues.DasReviewTransitionStatus.ReceivedByLevel2),
                        onCancel: () => {},
                      });
                    } } > Confirmar recibido </Button>
                  ); break;
                  case DbOldValues.DasReviewTransitionStatus.ReceivedByLevel2: buttons.push(
                    <Button key={ `action_${row.id}` } onClick={ () => {
                      Modal.confirm({
                        title: `¿Confirmmar entregado?`,
                        content: ``,
                        onOk: () => editDasReviewTransition(row.id, DbOldValues.DasReviewTransitionStatus.GivenByLevel2),
                        onCancel: () => {},
                      });
                    } } > Confirmar entregado </Button>
                  ); break;
                  case DbOldValues.DasReviewTransitionStatus.GivenByLevel2: buttons.push(
                    <Button key={ `action_${row.id}` } onClick={ () => {
                      Modal.confirm({
                        title: `¿Confirmmar recibido?`,
                        content: ``,
                        onOk: () => editDasReviewTransition(row.id, DbOldValues.DasReviewTransitionStatus.ReceivedByLevel1),
                        onCancel: () => {},
                      });
                    } } > Confirmar recibido </Button>
                  ); break;
                  case DbOldValues.DasReviewTransitionStatus.ReceivedByLevel1: buttons.push(
                    <Button key={ `action_${row.id}` } onClick={ () => {
                      Modal.confirm({
                        title: `¿Confirmmar entregado?`,
                        content: ``,
                        onOk: () => editDasReviewTransition(row.id, DbOldValues.DasReviewTransitionStatus.GivenByLevel1),
                        onCancel: () => {},
                      });
                    } } > Confirmar entregado </Button>
                  ); break;
                  case DbOldValues.DasReviewTransitionStatus.GivenByLevel1: buttons.push(
                    <Button key={ `action_${row.id}` } onClick={ () => {
                      Modal.confirm({
                        title: `¿Confirmmar recibido?`,
                        content: ``,
                        onOk: () => editDasReviewTransition(row.id, DbOldValues.DasReviewTransitionStatus.ClosedByLevel0),
                        onCancel: () => {},
                      });
                    } } > Confirmar recibido </Button>
                  ); break;
                }
                return buttons;
              }
            },
          ] }
        />
      </Row>

    </div>
  );

  return (
    <span>
      <Button type="primary" style={{ backgroundColor: BTN_COLOR, borderColor: BTN_COLOR, color: 'white', fontWeight: 'bold' }} onClick={ () => setVisible(true) } >
        Auditoría
      </Button>
      <Modal
        width={ '100vw' }
        style={ { position: 'fixed', top: 0, left: 0 } }
        title="Auditoría"
        centered
        visible={ visible }
        footer={ null }
        onCancel={ () => setVisible(false) }
      >
        { modalContent }
      </Modal>
    </span>
  );
};

type ApiCut = ApiTypes.AuditBilling.Cut;
type ApiSale = ApiTypes.AuditBilling.Sale;
type ApiCustomer = ApiTypes.AuditBilling.Customer;
//type ApiEquipment = ApiTypes.AuditBilling.Equipment;
type DayGroup = {
  date: string,
  distCenterId: DbOldValues._DistCenterId,
  distCenter: string,
  completedCuts: ApiCut[],
  pendingCuts: ApiCut[],
};
type SaleGroup = {
  sales: ApiSale[],
  totalAmount: number,
  totalVolume: number,
};
type AuditBillingModalProps = { mode: 'sp' | 'cp' };
const AuditBillingModal = (props: AuditBillingModalProps) => {
  const BTN_COLOR = 'gray';

  const { formatCurrency } = Globals.Utils.Number;

  let saleGroupSelectRef = useRef(null as null | Select<number>);

  let [ visible, setVisible ] = useState(false);
  let [ isLoading, setIsLoading ] = useState(true);
  let [ isError, setIsError ] = useState(false);
  let [ isOccupied, setIsOcuppied ] = useState(false);

  let [ apiCuts, setApiCuts ] = useState([] as ApiCut[]);
  //let [ apiCpSales, setApiCpSales ] = useState([] as ApiSale[]);
  let [ apiCustomers, setApiCustomers ] = useState([] as ApiCustomer[]);
  //let [ apiEquipments, setApiEquipments ] = useState([] as ApiEquipment[]);

  let [ dayGroups, setDayGroups ] = useState([] as DayGroup[]);
  let [ stampPendingFrontendSales, setStampPendingFrontendSales ] = useState<Sale[]>([]);
  //let [ expandedSpDayGroupDate, setExpandedSpDayGroupDate ] = useState([] as string[]);
  //let [ auditedInvoices, setAuditedInvoices ] = useState([] as Invoice[]);

  let [ selectedDayGroup, setSelectedDayGroup ] = useState(null as null | DayGroup);
  let [ displaySaleGroups, setDisplaySaleGroups ] = useState([] as SaleGroup[]);
  let [ displayCustomers, setDisplayCustomers ] = useState([] as DataSourceItemObject[]);
  let [ displayCuts, setDisplayCuts ] = useState([] as DataSourceItemObject[]);
  let [ formCustomerCode, setFormCustomerCode ] = useState('');
  let [ formSalePaymentWay ] = useState(DbOldValues.SalePaymentWay.TRANSFERENCIA);
  let [ formCutId, setFormCutId ] = useState('' as '' | ApiCut['id']);
  let [ formWishedAmount, setFormWishedAmount ] = useState(0);
  let [ formSaleGroupIndex, setFormSaleGroupIndex ] = useState(-1);

  const displaySalePaymentWays: AppTypes.SelectOption<DbOldValues._SalePaymentWay>[] = Globals.Utils.Object.entries(DbOldDetails.salePaymentWay).map(spw => ({
    value: +spw.key,
    name: spw.value.name,
  }));

  const getSalesOfCuts = (cuts: ApiCut[]): ApiSale[] => {
    return cuts.reduce<ApiSale[]>((acc, cur) => [ ...acc, ...cur.sales ], []);
  };
  const createCustomerOptions = useCallback(( customers: ApiCustomer[], filter: string ) => {
    console.log(`Creating customer options for: '${filter}'.`);
    setDisplayCustomers(
      customers.map(customer => ({
        value: customer.code,
        text: `${customer.code}, ${customer.rfc}, ${customer.business_name}`,
      })).filter(opt => !filter || opt.text.toLowerCase().includes(filter.toLowerCase())),
    );
  }, []);
  const createCutOptions = useCallback(( dayGroup: DayGroup, filter: string ) => {
    console.log(`Creating cut options for: '${filter}'.`, dayGroup);
    setFormCutId('');
    setDisplayCuts(
      dayGroup.pendingCuts/* .filter(cut => cut.type == DbOldValues.EquipmentType.Pipa) */.map(cut => ({
        value: cut.id,
        text: `Corte: ${cut.folio}, Equipo: ${cut.unit} ( ${cut.equipment_number} )`,
      }))/* .filter(opt => !filter || opt.text.toLowerCase().includes(filter.toLowerCase())) */,
    );
  }, []);
  const promptForNewWishedAmount = useCallback(async (dayGroup: DayGroup) => {
    let initial = getSalesOfCuts(dayGroup.pendingCuts).reduce((acc, cur) => acc + cur.total_amount_c, 0).toFixed(2);
    let wishedAmount = await Globals.Utils.Prompt.number(`Ingrese monto deseado.`, { initial });
    if(wishedAmount === null) return;
    setSelectedDayGroup(dayGroup);
    setFormWishedAmount(wishedAmount);
    createSaleGroupsForWishedAmount(dayGroup, wishedAmount);
    //setFormSaleGroupIndex(displaySaleGroups.length ? 0 : -1);
    /* if(wishedAmount !== null){
      await Globals.Utils.Time.sleep(400);
      saleGroupSelectRef.current && saleGroupSelectRef.current.focus();
    } */
  }, []);
  const createSaleGroupsForWishedAmount = useCallback((dayGroup: DayGroup, wishedAmount: number) => {
    console.log(`Creating sale groups for wished amount: '${wishedAmount}'.`);
    if(!dayGroup){
      setDisplaySaleGroups([]);
      return;
    }
    const createGroupFromSales = (sales: ApiSale[]): SaleGroup => {
      let group: SaleGroup = { sales: sales, totalAmount: 0, totalVolume: 0 };
      for(let sale of sales){
        group.totalAmount += sale.total_amount_c;
        group.totalVolume += sale.total_volume_c;
      }
      return group;
    };

    const cutById = dayGroup.pendingCuts.reduce<{ [id: string]: ApiCut }>((acc, cur) => ({ ...acc, [cur.id]: cur }), {});
    
    let [ firstUsableSale, ...usableSales ] = Globals.Utils.Array.sort(
      getSalesOfCuts(dayGroup.pendingCuts).filter(sale => sale.total_amount_c < wishedAmount),
      [
        { direction: 'desc', valueFn: sale => cutById[sale.cut_id].sales.length },
        { direction: 'desc', valueFn: sale => sale.total_amount_c },
      ],
    );
    let salesForUse: ApiSale[] = [ firstUsableSale ];
    let saleGroups: SaleGroup[] = [ createGroupFromSales(salesForUse) ];
    for(let usableSale of usableSales){
    
      let nextGroup: SaleGroup = createGroupFromSales([ ...salesForUse, usableSale ]);

      if(+nextGroup.totalAmount.toFixed(2) < wishedAmount){
        saleGroups[0] = nextGroup;
        salesForUse.push(usableSale);
      }else{
        saleGroups.push(nextGroup);
        break;
      }
    }
    setDisplaySaleGroups(saleGroups);
    console.log(saleGroups);
    setFormSaleGroupIndex(saleGroups.length - 1);
  }, []);

  let loadSales = useCallback(async () => {
    console.log(`Getting cuts.`);
    setIsLoading(true);
    setIsError(false);
    let result = await callApi(ApiRoute.GetAuditBillingInfo, { mode: props.mode });
    console.log(result);
    if(!result || !result.success || !('cpCuts' in result) || !('spCuts' in result)){
      setIsError(true);
      return;
    }

    let modeCuts = props.mode === 'cp' ? result.cpCuts : result.spCuts;

    setApiCuts(modeCuts);
    //setApiCpSales(result.cpSales);
    setApiCustomers(result.customers);
    setStampPendingFrontendSales(result.stampPendingFrontendSales || []);

    //setApiEquipments(result.equipments);

    let dayGroupsByDasId: { [K in string]: DayGroup } = {};
    for(let cut of modeCuts){
      let [ date, distCenterId ] = cut.daily_audit_status_id.split('_') as [ string, DbOldValues._DistCenterId ];
      let dayGroup: DayGroup = dayGroupsByDasId[cut.daily_audit_status_id];
      if(!dayGroup) dayGroup = { date, distCenterId, distCenter: DbOldDetails.getDistCenterId(distCenterId).name, pendingCuts: [], completedCuts: [] };
      if(cut.audit_invoice_record_id) dayGroup.completedCuts.push(cut);
      else dayGroup.pendingCuts.push(cut);
      dayGroupsByDasId[cut.daily_audit_status_id] = dayGroup;
    }
    setDayGroups(Globals.Utils.Object.entries(dayGroupsByDasId).map(sdg => sdg.value));
    setSelectedDayGroup(null);
    console.log(dayGroupsByDasId);

    setIsLoading(false);
    setIsOcuppied(false);
  }, []);


  let createAuditInvoice = useCallback(async () => {
    let saleGroup: SaleGroup = displaySaleGroups[formSaleGroupIndex];
    if(!saleGroup) return alert(`Seleccione un monto.`);
    let customer: undefined | ApiCustomer = apiCustomers.find(customer => customer.code === formCustomerCode);
    if(!customer) return alert(`Seleccione un cliente.`);
    let cut: undefined | ApiCut = apiCuts.find(cut => cut.id === formCutId);
    if(!cut) return alert(`Seleccione un corte.`);
    let salePaymentWayName = DbOldDetails.getSalePaymentWay(formSalePaymentWay).name;
    if(!salePaymentWayName) return alert(`Seleccione una forma de pago.`);


    let confirmMessage = (
      `Monto: ${formatCurrency(saleGroup.totalAmount)}\n` +
      `Volumen: ${saleGroup.totalVolume.toFixed(2)}L\n` +
      `Cliente: ${customer.code} (${customer.business_name})\n` +
      `RFC: ${customer.rfc}\n` +
      `Forma pago: ${salePaymentWayName}\n` +
      `¿Confirmar operación?\n`
    );
    if(!Globals.Utils.Prompt.confirm(confirmMessage)) return;

    setIsOcuppied(true);

    let invoiceService = new InvoiceService();

    let fiscalInfo: InvoicingPortalFiscalInfo;
    try{
      fiscalInfo = await invoiceService.getFiscalInformationByRfc(customer.rfc);
      console.log(fiscalInfo);
      //if(!Globals.Utils.Prompt.confirm(`Datos fiscales:\n${JSON.stringify(fiscalInfo, null, '  ')}`)) return;
    }catch(error){
      console.log(error);
      alert(`Error obteniendo datos para el RFC ${customer.rfc}.`);
      setIsOcuppied(false);
      return;
    }

    const saleIds = saleGroup.sales.map(sale => sale.id);
    let groupResult = await callApi(ApiRoute.GroupForAuditBilling, { mode: props.mode, saleIds, customerCode: formCustomerCode, cutId: formCutId, paymentWay: formSalePaymentWay });
    console.log(groupResult);
    if(!groupResult || !groupResult.success || !('frontendSale' in groupResult)){
      alert(`Error agrupando ventas.`);
      setIsOcuppied(false);
      return;
    }

    alert(`Se agruparon las ventas correctamente. Proceder a timbrar desde estado de cuenta.`);
    //await stampGroupedSale(groupResult.frontendSale, fiscalInfo);
    /* let doStamp = Globals.Utils.Prompt.confirm(`Se agruparon las ventas correctamente. ¿Proceder con el timbrado?`);
    if(doStamp){
    }else{
      await loadCpSales();
    } */

    await loadSales();
    setIsOcuppied(false);

  }, [ displaySaleGroups, formSaleGroupIndex, apiCustomers, formCustomerCode, apiCuts, formCutId, displaySalePaymentWays, formSalePaymentWay, loadSales ]);

  const closeGroupedSale = useCallback(async (frontendSale: Sale, fiscalInfo?: InvoicingPortalFiscalInfo) => {
    setIsOcuppied(true);
    /* let invoiceService = new InvoiceService();
    if(!fiscalInfo){
      try{
        fiscalInfo = await invoiceService.getFiscalInformationByRfc(frontendSale.customerRfc);
        console.log(`Fiscal info:`, fiscalInfo);
        //if(!Globals.Utils.Prompt.confirm(`Datos fiscales:\n${JSON.stringify(fiscalInfo, null, '  ')}`)) return;
      }catch(error){
        console.log(error);
        alert(`Error obteniendo datos para el RFC ${frontendSale.customerRfc}.`);
        return;
      }
    } */

    /* frontendSale.invoiceDescription = 'Venta';
    let invoiceParam: Invoice = {
      id: '',
      idG4s: '',
      name: fiscalInfo.businessName,
      rfc: fiscalInfo.rfc,
      usoCfdi: fiscalInfo.cfdiUse,
      customerFiscalRegime: fiscalInfo.fiscalRegime,
      formaPago: fiscalInfo.paymentWay,
      street: fiscalInfo.street,
      externalNumber: fiscalInfo.externalNumber,
      internalNumber: fiscalInfo.internalNumber,
      zipCode: fiscalInfo.zipCode,
      colony: fiscalInfo.colony,
      city: fiscalInfo.city,
      municipality: fiscalInfo.municipality,
      state: fiscalInfo.state,
      status: 'PENDING',
      email: (getCurrentTokenInfo() || { email: '' }).email,
      sales: [ frontendSale ],
      fiscalRegime: '',
    };
    console.log(`Invoice param:`, invoiceParam); */
    //if(1) return;

    try{
      /* let invoiceResult = await invoiceService.saveInvoice(invoiceParam, fiscalInfo);
      console.log(`Invoice result:`, invoiceResult);
      if(!invoiceResult || !invoiceResult.id) throw [ `No invoice id.`, invoiceResult ];
      alert(`Timbrado existoso.`); */
      let setResult = await callApi(ApiRoute.SetAuditBillingStampInfo, { saleId: frontendSale.id/* , invoiceId: invoiceResult.id */ });
      console.log(setResult);
      if(!setResult || !setResult.success){
        alert(`Error actualizando datos.\n${(setResult && setResult.msg) || 'Error desconocido.'}`);
      }
    }catch(error){
      alert(`Error con el cerrado.`);
      console.log(error);
    }

    await loadSales();
    setIsOcuppied(false);
  }, []);
  
  useEffect(() => {
    if(visible){
      loadSales();
    }
  }, [ visible, loadSales ]);

  let modalContent: JSX.Element;
  if(isError) modalContent = (
    <div style={{ marginTop: 20, textAlign: 'center' }} >
      <span> Error obteniendo ventas. </span>
    </div>
  );
  else if(isLoading) modalContent = (
    <div style={{ marginTop: 20, textAlign: 'center' }} >
      <Spin size="large" />
    </div>
  );
  else modalContent = (
    <div style={ { height: '88vh', overflowY: 'auto' } } >
      <Row>
        <Col span={ 12 } style={ { padding: '0 16px' } } >
          <h2> Ventas { props.mode === 'cp' ? 'CP' : 'SP' } Agrupadas por día </h2>
          <Table
            dataSource={ dayGroups }
            rowKey={ row => `${row.date}_${row.distCenterId}` }
            pagination={{ pageSize: 20 }}
            size="small"
            columns={ [
              {
                title: 'Fecha',
                dataIndex: 'date',
                key: 'date',
                render: (_, row) => row.date,
              },
              {
                title: 'Gerencia',
                dataIndex: 'distCenter',
                key: 'distCenter',
                render: (_, row) => row.distCenter,
              },
              {
                title: 'Ventas disponibles',
                dataIndex: 'vec',
                key: 'vec',
                render: (_, row) => getSalesOfCuts(row.pendingCuts).length,
              },
              {
                title: 'Volumen',
                dataIndex: 'voc',
                key: 'voc',
                render: (_, row) => `${getSalesOfCuts(row.pendingCuts).reduce((acc, cur) => acc + cur.total_volume_c, 0).toFixed(2)}L`,
              },
              {
                title: 'Monto disponible',
                dataIndex: 'mp',
                key: 'mp',
                render: (_, row) => formatCurrency(getSalesOfCuts(row.pendingCuts).reduce((acc, cur) => acc + cur.total_amount_c, 0)),
              },
              {
                title: 'Acciones',
                key: 'actions',
                align: 'right',
                render: (_, row) => {
                  let buttons: JSX.Element[] = [
                    <Button key={ `select_${row.date}` } disabled={ isOccupied } onClick={ () => { 
                      //setSelectedCpDayGroup(row);
                      promptForNewWishedAmount(row);
                      createCutOptions(row, '');
                    } } > Seleccionar </Button>
                  ];
                  return buttons;
                }
              },
            ] }
          />
          <h2> Ventas SP o CP pendientes for resolver </h2>
          <Table
            dataSource={ stampPendingFrontendSales }
            rowKey={ row => row.id }
            pagination={ { pageSize: 10 } }
            size="small"
            columns={ [
              {
                title: 'Folio',
                dataIndex: 'folio',
                key: 'folio',
                render: (_, row) => row.salesFolio,
              },
              {
                title: 'Fecha',
                dataIndex: 'date',
                key: 'date',
                render: (_, row) => `${row.endDateSave}`.slice(0, 10),
              },
              {
                title: 'Cliente',
                dataIndex: 'customer',
                key: 'customer',
                render: (_, row) => row.customerName,
              },
              {
                title: 'Código',
                dataIndex: 'customerCode',
                key: 'customerCode',
                render: (_, row) => row.customerCode,
              },
              {
                title: 'Rfc',
                dataIndex: 'customerRfc',
                key: 'customerRfc',
                render: (_, row) => row.customerRfc,
              },
              {
                title: 'Monto',
                dataIndex: 'total',
                key: 'total',
                render: (_, row) => row.total,
              },
              {
                title: 'Volumen',
                dataIndex: 'totalVolume',
                key: 'totalVolume',
                render: (_, row) => row.totalVolume,
              },
              {
                title: 'Acciones',
                key: 'actions',
                align: 'right',
                render: (_, row) => {
                  let buttons: JSX.Element[] = [
                    <Button key={ `stamp_${row.id}` } disabled={ isOccupied } onClick={ () => { 
                      closeGroupedSale(row);
                    } } > Resolver </Button>
                  ];
                  return buttons;
                }
              },
            ] }
          />
        </Col>
        <Col span={ 12 } style={ { padding: '0 16px' } } >
          { selectedDayGroup && (
            <Row>
              <h2> Seleccionado: { selectedDayGroup.distCenter } { selectedDayGroup.date } </h2>
              <Row>
                <Col span={ 12 } >
                  <label style={ { fontWeight: 'bold' } } > Monto deseado: </label>
                  <InputNumber id={ `formAmount` } value={ formWishedAmount } style={ { width: '100%' } } readOnly={ true }
                    onClick={ () => {
                      promptForNewWishedAmount(selectedDayGroup as DayGroup);
                    } }
                  />
                </Col>
                <Col span={ 12 } >
                  <label style={ { fontWeight: 'bold' } } > Monto seleccionado: </label>
                  <Select id="formSaleGroupIndex" ref={ saleGroupSelectRef } value={ formSaleGroupIndex } onChange={ value => setFormSaleGroupIndex(value) } style={ { width: '100%' } } >
                    <Select.Option key={ `fsgi_${ -1 }` } value={ -1 } > Sin seleccionar </Select.Option>
                    { displaySaleGroups.map((fsgi, fsgiIndex) => (
                      <Select.Option key={ `fsgi_${ fsgi.totalAmount }` } value={ fsgiIndex } > { fsgi.totalAmount.toFixed(2) } ( { fsgi.sales.length } ventas ) </Select.Option>
                    )) }
                  </Select>
                </Col>
                <Col span={ 24 } >
                  <label style={ { fontWeight: 'bold' } } > Código cliente: </label>
                  <AutoComplete
                    dataSource={ displayCustomers }
                    onSearch={ text => {
                      setFormCustomerCode(text);
                      createCustomerOptions(apiCustomers, text);
                    } }
                    onBlur={ () => {
                      let customer = apiCustomers.find(customer => customer.code === formCustomerCode);
                      if(!customer){
                        setFormCustomerCode('');
                        createCustomerOptions(apiCustomers, '');
                      }
                    } }
                    value={ formCustomerCode }
                    //onChange={ value => console.log(`Changed customer `, value) }
                    onSelect={ value => setFormCustomerCode(`${value}`) }
                    style={ { width: '100%' } }
                  />
                </Col>
                <Col span={ 12 } >
                  <label style={ { fontWeight: 'bold' } } > Corte y pipa que realiza la venta: </label>
                  <Select id="formCutId" value={ formCutId } onChange={ value => setFormCutId(value) } style={ { width: '100%' } } >
                    <Select.Option key={ `fci_${ '' }` } value={ '' } > Sin seleccionar </Select.Option>
                    { displayCuts.map((dc) => (
                      <Select.Option key={ `fci_${dc.value}` } value={ dc.value } > { dc.text } </Select.Option>
                    )) }
                  </Select>
                </Col>
                <Col span={ 12 } >
                  <label style={ { fontWeight: 'bold' } } > Forma de pago: Transferencia </label>
                  {/* <Select id="formSalePaymentWay" value={ formSalePaymentWay } onChange={ value => setFormSalePaymentWay(value) } style={ { width: '100%' } } >
                    { displaySalePaymentWays.map((spw) => (
                      <Select.Option key={ `spw_${spw.value}` } value={ spw.value } > { spw.name } </Select.Option>
                    )) }
                  </Select> */}
                </Col>
              </Row>
              <Row style={ { textAlign: 'right' } } >
                <Button
                  type="primary"
                  style={ { backgroundColor: 'green', borderColor: 'green', color: 'white' } }
                  disabled={ isOccupied }
                  onClick={ () => createAuditInvoice() }
                > Facturar </Button>
              </Row>
            </Row>
          ) }
          { /* <Row>
            <pre>{ JSON.stringify({ formSaleGroupIndex, formCustomerCode, formCutId, formSalePaymentWay }) }</pre>
          </Row> */ }
        </Col>
      </Row>
    </div>
  );


  return (
    <span>
      <Button type="primary" style={{ backgroundColor: BTN_COLOR, borderColor: BTN_COLOR, color: 'white', fontWeight: 'bold' }} onClick={ () => setVisible(true) } >
        Facturación { props.mode === 'cp' ? 'CP' : 'SP' }
      </Button>
      <Modal
        width={ '100vw' }
        style={ { position: 'fixed', top: 0, left: 0 } }
        title="Facturación"
        centered
        visible={ visible }
        footer={ null }
        onCancel={ () => setVisible(false) }
      >
        { modalContent }
      </Modal>
    </span>
  );
};