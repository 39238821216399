import {
  Form,
  Col,
  Input,
  Row,
  Select,
  Button,
  Divider,
  Icon,
  Card,
} from 'antd';
import React, { FormEvent } from 'react';
import {
  FormComponentProps,
  WrappedFormUtils,
  ValidationRule,
} from 'antd/lib/form/Form';
import { InvoiceRules as r } from './rulesFiscalData';
import {
  usoCFDI,
  formaPago,
  regimenFiscal,
} from 'commons/components/regimenFiscal';
import { InvoicingPortalFiscalInfo } from 'types/type';

interface CounterProps extends FormComponentProps {
  onFiscalDataProvidedPrevius: (
    e: FormEvent,
    fiscalInfo: InvoicingPortalFiscalInfo,
  ) => void;
  onFiscalDataProvidedNext: (e: FormEvent, form: WrappedFormUtils) => void;
  renderCancelButton: () => JSX.Element;
  fiscalData: InvoicingPortalFiscalInfo;
  isFetching: boolean;
  getFiscalInformation: Function;
}

type Props = CounterProps & FormComponentProps;

class FiscalDataForm extends React.Component<Props> {
  private formImputItemRender = (
    invoiceItem: string,
    label: string,
    name: string,
    placeholder: string,
    rule: ValidationRule[],
  ): JSX.Element => {
    return (
      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
        <Form.Item hasFeedback label={label}>
          {this.props.form.getFieldDecorator(name, {
            initialValue: invoiceItem,
            rules: rule,
          })(<Input size="large" placeholder={placeholder} />)}
        </Form.Item>
      </Col>
    );
  };
  private formImputItemRenderSmall = (
    invoiceItem: string,
    label: string,
    name: string,
    placeholder: string,
    rule: ValidationRule[],
  ): JSX.Element => {
    return (
      <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
        <Form.Item hasFeedback label={label}>
          {this.props.form.getFieldDecorator(name, {
            initialValue: invoiceItem,
            rules: rule,
          })(<Input size="large" placeholder={placeholder} />)}
        </Form.Item>
      </Col>
    );
  };
  public render = (): JSX.Element => {
    const {
      onFiscalDataProvidedPrevius,
      onFiscalDataProvidedNext,
      renderCancelButton,
      fiscalData,
      form: { getFieldDecorator },
    } = this.props;
    return (
      <Row gutter={16}>
        <h1>Datos Fiscales</h1>
        Los campos marcados con * son obligatorios
        <Form className="login-form">
          <Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Card style={{ marginTop: 16, backgroundColor: '#FAFAFA' }}>
                {this.formImputItemRender(
                  fiscalData.businessName,
                  'Razón Social',
                  'businessName',
                  'Razón Social',
                  r.nameRule,
                )}
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Form.Item hasFeedback label={'RFC'}>
                    {this.props.form.getFieldDecorator('rfc', {
                      initialValue: fiscalData.rfc,
                      rules: r.rfcRule,
                    })(
                      <Input
                        size="large"
                        placeholder={'Ingresa un RFC'}
                        onChange={(e): void => {
                          if (
                            e.target.value.length === 12 ||
                            e.target.value.length === 13
                          ) {
                            this.props.getFiscalInformation(e.target.value);
                          }
                        }}
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Form.Item hasFeedback label="Uso de CFDI">
                    {getFieldDecorator('cfdiUse', {
                      initialValue: fiscalData.cfdiUse,
                      rules: r.usoCfdiRule,
                    })(
                      <Select showSearch size="large">
                        {usoCFDI.map(
                          (item, index): React.ReactNode => (
                            <Select.Option key={item.key} value={item.key}>
                              <div id={'usoCFDI_' + index.toString()}>
                                {item.name}
                              </div>
                            </Select.Option>
                          ),
                        )}
                      </Select>,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Form.Item hasFeedback label="Regimen Fiscal">
                    {getFieldDecorator('fiscalRegime', {
                      initialValue: fiscalData.fiscalRegime,
                      rules: r.fiscalRegimeRule,
                    })(
                      <Select showSearch size="large">
                        {regimenFiscal.map(
                          (item, index): React.ReactNode => (
                            <Select.Option key={item.key} value={item.key}>
                              <div id={'regimenFiscal_' + index.toString()}>
                                {item.name}
                              </div>
                            </Select.Option>
                          ),
                        )}
                      </Select>,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Form.Item hasFeedback label="Forma de pago">
                    {getFieldDecorator('paymentWay', {
                      initialValue: fiscalData.paymentWay,
                      rules: r.usoCfdiRule,
                    })(
                      <Select showSearch size="large">
                        {formaPago.map(
                          (item, index): React.ReactNode => (
                            <Select.Option key={item.key} value={item.key}>
                              <div id={'formaPago_' + index.toString()}>
                                {item.name}
                              </div>
                            </Select.Option>
                          ),
                        )}
                      </Select>,
                    )}
                  </Form.Item>
                </Col>
              </Card>
            </Col>

            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Card style={{ marginTop: 16, backgroundColor: '#FAFAFA' }}>
                {this.formImputItemRender(
                  fiscalData.street,
                  'Calle',
                  'street',
                  'Ingresa una Calle',
                  r.streetRule,
                )}
                {this.formImputItemRenderSmall(
                  fiscalData.externalNumber,
                  'Número exterior',
                  'externalNumber',
                  'Ingresa un Número exterior',
                  r.externalNumberRule,
                )}
                {this.formImputItemRenderSmall(
                  fiscalData.internalNumber,
                  'Número interior',
                  'internalNumber',
                  'Ingresa un Número interior',
                  r.internalNumberRule,
                )}
                {this.formImputItemRenderSmall(
                  fiscalData.zipCode,
                  'Código postal',
                  'zipCode',
                  'Ingresa un Código postal',
                  r.zipCodeRule,
                )}
                {this.formImputItemRender(
                  fiscalData.colony,
                  'Colonia',
                  'colony',
                  'Ingresa una Colonia',
                  r.colonyRule,
                )}
                {this.formImputItemRenderSmall(
                  fiscalData.city,
                  'Ciudad',
                  'city',
                  'Ingresa una Ciudad',
                  r.cityRule,
                )}
                {this.formImputItemRenderSmall(
                  fiscalData.municipality,
                  'Municipio',
                  'municipality',
                  'Ingresa un Municipio',
                  r.municipalityRule,
                )}
                {this.formImputItemRenderSmall(
                  fiscalData.state,
                  'Estado',
                  'state',
                  'Ingresa un Estado',
                  r.stateRule,
                )}
              </Card>
            </Col>
          </Col>
          <p />
          <Col />
        </Form>
        <Col span={24} className="align-center-col">
          <Divider />
          <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
            <Button
              type="primary"
              id="previus_fiscal_data_button"
              onClick={(e): void =>
                onFiscalDataProvidedPrevius(e, {
                  ...fiscalData,
                  ...this.props.form.getFieldsValue(),
                })
              }
            >
              {' '}
              <Icon type="left" />
              Anterior{' '}
            </Button>
          </Col>
          <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
            <Button
              type="primary"
              id="next_fiscal_data_button"
              onClick={(e): void =>
                onFiscalDataProvidedNext(e, this.props.form)
              }
            >
              {' '}
              Siguiente <Icon type="right" />
            </Button>
          </Col>
          <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
            {renderCancelButton()}
          </Col>
        </Col>
      </Row>
    );
  };
}
export default Form.create<CounterProps>()(FiscalDataForm);
