import {
  User,
  DistributionCenter,
  Permissions,
  Marketstall,
  DebtCollector,
  PortfolioManager,
} from 'types/type';

export enum UserActionTypes {
  GET = 'GET',
  SET = 'SET',
  SAVE = 'SAVE',
  UPDATE = 'UPDATE',
  GET_DISTRIBUTION_CENTER = 'GET_DISTRIBUTION_CENTER',
  GET_MARKETSTALL = 'GET_MARKETSTALL',
  GET_PERMISSIONS = 'GET_PERMISSIONS',
  CLEAN_USER = 'CLEAN_USER',
  FETCHING = 'FETCHING',
  GET_DEBT_COLLECTOR = 'GET_DEBT_COLLECTOR',
  GET_PORTFOLIO_MANAGER = 'GET_PORTFOLIO_MANAGER',
}

interface SetAction {
  type: UserActionTypes.SET;
  payload: {};
}
interface SaveAction {
  type: UserActionTypes.SAVE;
  payload: User;
}
interface UpdateAction {
  type: UserActionTypes.UPDATE;
  payload: {};
}

interface GetAction {
  type: UserActionTypes.GET;
  payload: User;
}

interface GetDistributionCenter {
  type: UserActionTypes.GET_DISTRIBUTION_CENTER;
  payload: DistributionCenter[];
}

interface GetPermissions {
  type: UserActionTypes.GET_PERMISSIONS;
  payload: Permissions[];
}

interface CleanUserAction {
  type: UserActionTypes.CLEAN_USER;
  payload: User;
}

interface GetMarketstall {
  type: UserActionTypes.GET_MARKETSTALL;
  payload: Marketstall[];
}
interface Fetching {
  type: UserActionTypes.FETCHING;
  payload: boolean;
}
interface GetDebtCollector {
  type: UserActionTypes.GET_DEBT_COLLECTOR;
  payload: DebtCollector[];
}
interface GetPortfolioManager {
  type: UserActionTypes.GET_PORTFOLIO_MANAGER;
  payload: PortfolioManager[];
}
export type UserActions =
  | SetAction
  | SaveAction
  | UpdateAction
  | GetAction
  | GetDistributionCenter
  | GetMarketstall
  | GetPermissions
  | Fetching
  | CleanUserAction
  | GetDebtCollector
  | GetPortfolioManager;
