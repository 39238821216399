import React, { Component, useCallback, useEffect, useState } from 'react';
import { Row, DatePicker, Col, Divider, Card, Spin, Button, Icon, Input, Select, Modal, InputNumber, Tag } from 'antd';
import { removeToken, isAllowed, getIncognitMode } from '../../security';
import { Redirect } from 'react-router-dom';
import { Typography } from 'antd';
import { connect } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { totalCalculator } from '../sales/redux/actions';
import './style.css';
import { DistributionCenter } from 'types/type';
import { RouteComponentProps } from 'react-router';
import { Permissions, Permissions as permissions } from '../../commons/permissions';
import moment, { Moment } from 'moment';
import locale from 'antd/es/date-picker/locale/es_ES';
import { isDateWrong } from 'routes/functions';
import { DATE_FORMAT, getEndTime, getInitialTime } from 'commons/services';
import { ApiRoute, callApi } from 'commons/services/api';
import { DbOldDetails, DbOldTypes, DbOldValues } from 'database';
import { Globals } from 'commons/globals';

const { Text } = Typography;

interface DistributionCenters {
  name: string;
  price: number;
  percent: number;
}

interface TotalsByDIst {
  realSale: number;
  totalSale: number;
  totalSaleExpected: number;
  saleCheck: number;
  totalAlternativeSale: number;
  totalAlternativeSaleExpected: number;
  totalAlternativeSaleCheck: number;
}

interface DispatchProps {
  totalCalculator: (initDate: Moment, endDate: Moment) => void;
}

interface StateProps {
  total: DistributionCenter[];
}

type Props = StateProps & DispatchProps & RouteComponentProps;

const DATE_PICKER_START_NAME = 'startValueDate';
const DATE_PICKER_END_NAME = 'endValueDate';
class Dashboard extends Component<Props> {
  public state = {
    mode: ['month', 'month'],
    value: [],
    redirect: false,
    endOpen: false,
    startValueDate: moment(),
    endValueDate: moment(),
  };
  public UNSAFE_componentWillMount(): void {
    const startAsync = async (): Promise<void> => {
      try {
        await this.validatePermission();
        await this.props.totalCalculator(getInitialTime(), getEndTime());
        await this.setState({
          startValueDate: getInitialTime(),
          endValueDate: getEndTime(),
        });
      } catch {}
    };
    startAsync();
  }

  private validatePermission(): void {
    if (!isAllowed([permissions.DEVELOP_MODE])) {
      this.props.history.push('/home');
    }
  }

  public clicklogout = (): void => {
    removeToken();
    this.setState({
      redirect: true,
    });
  };
  private renderStartDatePicker = (): JSX.Element => {
    const { endValueDate } = this.state;
    return (
      <Row>
        <label>{'\u00A0\u00A0De:\u00A0\u00A0\u00A0\u00A0\u00A0'}</label>
        <Divider type="vertical" />
        <DatePicker
          showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
          locale={locale}
          disabledDate={(date: Moment | undefined): boolean =>
            isDateWrong(date, endValueDate)
          }
          format={DATE_FORMAT}
          placeholder={this.state.startValueDate.format('YYYY-MM-DD HH:mm:SS')}
          onOk={(startDate: Moment): void =>
            this.onDatePickerChange(startDate, endValueDate)
          }
        />
      </Row>
    );
  };
  private renderEndDatePicker = (): JSX.Element => {
    const { startValueDate } = this.state;
    return (
      <Row>
        <label>{'\u00A0\u00A0Hasta:'}</label>
        <Divider type="vertical" />
        <DatePicker
          showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }}
          locale={locale}
          disabledDate={(date: Moment | undefined): boolean =>
            isDateWrong(startValueDate, date)
          }
          format={DATE_FORMAT}
          placeholder={this.state.endValueDate.format('YYYY-MM-DD HH:mm:SS')}
          onOk={(endDate: Moment): void =>
            this.onDatePickerChange(startValueDate, endDate)
          }
        />
      </Row>
    );
  };

  /**metodo para buscar cambiando los parametros de las fechas */
  public onDatePickerChange = (
    startDateValue: Moment,
    endDateValue: Moment,
  ): void => {
    const startAsync = async (): Promise<void> => {
      try {
        await this.setState({
          [DATE_PICKER_START_NAME]: startDateValue,
          [DATE_PICKER_END_NAME]: endDateValue,
        });

        await this.props.totalCalculator(startDateValue, endDateValue);
      } catch {}
    };
    startAsync();
  };

  public showTotals = (): TotalsByDIst => {
    let totals: TotalsByDIst = {
      realSale: 0,
      totalSale: 0,
      totalSaleExpected: 0,
      saleCheck: 0,
      totalAlternativeSale: 0,
      totalAlternativeSaleExpected: 0,
      totalAlternativeSaleCheck: 0,
    };

    this.props.total.forEach((item: DistributionCenter): void => {
      totals.realSale += item.realSale;
      totals.totalSale += item.totalSale;
      totals.totalSaleExpected += item.totalSaleExpected;
      totals.saleCheck += item.saleCheck;
      totals.totalAlternativeSale += item.totalAlternativeSale;
      totals.totalAlternativeSaleExpected += item.totalAlternativeSaleExpected;
      totals.totalAlternativeSaleCheck += item.totalAlternativeSaleCheck;
    });
    return totals;
  };
  public renderCustomCards = (
    title: string,
    label: string,
    total: number,
    distributionCenters: DistributionCenters[],
  ): JSX.Element => {
    return (
      <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
        <Row className="header-custom-card">
          <Text className="card-title">&nbsp;{title}</Text>
          <Text className="card-title">&nbsp;{label}</Text>
          <Text className="card-total">{'$' + total}</Text>
        </Row>
        <Row>
          {distributionCenters.map(
            (item, index): React.ReactNode => (
              <Row key={index}>
                <Row className={index % 2 === 0 ? 'style-one' : 'style-two'}>
                  <Text>&nbsp;{item.name}</Text>
                  <Text strong className="one-color table-number-value">
                    {item.percent + '%'}
                  </Text>
                  <Text className="table-number-value">{'$' + item.price}</Text>
                </Row>
              </Row>
            ),
          )}
        </Row>
      </Col>
    );
  };

  public renderList = (
    dividerType: 'vertical' | 'horizontal' | undefined,
  ): React.ReactNode => {
    console.log(dividerType);
    return (
      <Row>
        <Col
          xs={0}
          sm={0}
          md={0}
          lg={0}
          xl={0}
          xxl={getIncognitMode() === '1' ? 24 : 12}
        >
          <Card style={{ backgroundColor: '#f0f2f5', borderColor: '#f0f2f5' }}>
            <Col xxl={getIncognitMode() === '1' ? 3 : 4}>
              <Text style={{ color: '#113C71' }} strong>
                Sucursal
              </Text>
            </Col>
            <Col xxl={getIncognitMode() === '1' ? 3 : 4}>
              <Text style={{ color: '#113C71' }} strong>
                Venta Real
              </Text>
            </Col>
            <Col xxl={getIncognitMode() === '1' ? 3 : 4}>
              <Text style={{ color: '#113C71' }} strong>
                Venta Total
              </Text>
            </Col>
            <Col xxl={getIncognitMode() === '1' ? 3 : 4}>
              <Text style={{ color: '#113C71' }} strong>
                Venta en Disputa
              </Text>
            </Col>
            <Col xxl={getIncognitMode() === '1' ? 3 : 4}>
              <Text style={{ color: '#113C71' }} strong>
                Venta Cobrada
              </Text>
            </Col>
            {getIncognitMode() === '1' && (
              <Col xxl={3}>
                <Text style={{ color: '#113C71' }} strong>
                  C.
                </Text>
              </Col>
            )}
            {getIncognitMode() === '1' && (
              <Col xxl={3}>
                <Text style={{ color: '#113C71' }} strong>
                  C. en Disputa
                </Text>
              </Col>
            )}
            {getIncognitMode() === '1' && (
              <Col xxl={3}>
                <Text style={{ color: '#113C71' }} strong>
                  C. Cobrado
                </Text>
              </Col>
            )}
          </Card>
          <p />
          {this.props.total.map(
            (item: DistributionCenter, index: number): React.ReactNode => (
              <Col key={index}>
                <Card
                  style={{
                    boxShadow: 'px 3px 4px rgba(0, 0, 0, 0.12)',
                    border: '1px solid #74989d',
                    boxSizing: 'border-box',
                    backgroundColor: '#FFFFF',
                    borderRadius: '5px',
                    height: '5rem',
                  }}
                >
                  <Col xxl={getIncognitMode() === '1' ? 3 : 4}>{item.name}</Col>
                  <Col xxl={getIncognitMode() === '1' ? 3 : 4}>
                    {'$'}
                    {getIncognitMode() === '1'
                      ? item.realSale
                      : item.realSale - item.totalAlternativeSale}
                  </Col>
                  <Col xxl={getIncognitMode() === '1' ? 3 : 4}>
                    {'$'}
                    {item.totalSale}
                  </Col>
                  <Col xxl={getIncognitMode() === '1' ? 3 : 4}>
                    {'$'}
                    {item.totalSaleExpected}
                  </Col>
                  <Col xxl={getIncognitMode() === '1' ? 3 : 4}>
                    {'$'}
                    {item.saleCheck}
                  </Col>
                  {getIncognitMode() === '1' && (
                    <Col xxl={3}>
                      {'$'}
                      {item.totalAlternativeSale}
                    </Col>
                  )}
                  {getIncognitMode() === '1' && (
                    <Col xxl={3}>
                      {'$'}
                      {item.totalAlternativeSaleExpected}
                    </Col>
                  )}
                  {getIncognitMode() === '1' && (
                    <Col xxl={3}>
                      {'$'}
                      {item.totalAlternativeSaleCheck}
                    </Col>
                  )}
                </Card>
                <p />
              </Col>
            ),
          )}
          <Col>
            <Card
              style={{ backgroundColor: '#f0f2f5', borderColor: '#f0f2f5' }}
            >
              <Col xxl={getIncognitMode() === '1' ? 3 : 4}>
                <Text style={{ color: '#113C71' }}>{'Totales'}</Text>
              </Col>
              <Col xxl={getIncognitMode() === '1' ? 3 : 4}>
                <Text style={{ color: '#000000' }} strong>
                  {'$'}
                  {getIncognitMode() === '1'
                    ? this.showTotals().realSale
                    : this.showTotals().realSale -
                      this.showTotals().totalAlternativeSale}
                </Text>
              </Col>
              <Col xxl={getIncognitMode() === '1' ? 3 : 4}>
                <Text style={{ color: '#000000' }} strong>
                  {'$'}
                  {this.showTotals().totalSale}
                </Text>
              </Col>
              <Col xxl={getIncognitMode() === '1' ? 3 : 4}>
                <Text style={{ color: '#000000' }} strong>
                  {'$'}
                  {this.showTotals().totalSaleExpected}
                </Text>
              </Col>
              <Col xxl={getIncognitMode() === '1' ? 3 : 4}>
                <Text style={{ color: '#000000' }} strong>
                  {'$'}
                  {this.showTotals().saleCheck}
                </Text>
              </Col>
              {getIncognitMode() === '1' && (
                <Col xxl={3}>
                  <Text style={{ color: '#000000' }} strong>
                    {'$'}
                    {this.showTotals().totalAlternativeSale}
                  </Text>
                </Col>
              )}
              {getIncognitMode() === '1' && (
                <Col xxl={3}>
                  <Text style={{ color: '#000000' }} strong>
                    {'$'}
                    {this.showTotals().totalAlternativeSaleExpected}
                  </Text>
                </Col>
              )}
              {getIncognitMode() === '1' && (
                <Col xxl={3}>
                  <Text style={{ color: '#000000' }} strong>
                    {'$'}
                    {this.showTotals().totalAlternativeSaleCheck}
                  </Text>
                </Col>
              )}
            </Card>
            <p />
          </Col>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={{ span: 6 }} xxl={0}>
          <Card
            title={'Total'}
            style={{
              boxShadow: 'px 3px 4px rgba(0, 0, 0, 0.12)',
              border: '1px solid #74989d',
              boxSizing: 'border-box',
              backgroundColor: '#FFFFF',
              borderRadius: '5px',
            }}
          >
            <Col span={15}>
              <Text style={{ color: '#113C71' }}>Venta Real</Text>
            </Col>
            <Col span={9}>
              {'$'}
              {getIncognitMode() === '1'
                ? this.showTotals().realSale
                : this.showTotals().realSale -
                  this.showTotals().totalAlternativeSale}
            </Col>
            <Col span={15}>
              <Text style={{ color: '#113C71' }}>Venta Total</Text>
            </Col>
            <Col span={9}>
              {'$'}
              {this.showTotals().totalSale}
            </Col>
            <Col span={15}>
              <Text style={{ color: '#113C71' }}>Venta en Disputa</Text>
            </Col>
            <Col span={9}>
              {'$'}
              {this.showTotals().totalSaleExpected}
            </Col>
            <Col span={15}>
              <Text style={{ color: '#113C71' }}>Venta Cobrada</Text>
            </Col>
            <Col span={9}>
              {'$'}
              {this.showTotals().saleCheck}
            </Col>
            {getIncognitMode() === '1' && (
              <div>
                <Col span={15}>
                  <Text style={{ color: '#113C71' }}>C.</Text>
                </Col>
                <Col span={9}>
                  {'$'}
                  {this.showTotals().totalAlternativeSale}
                </Col>
              </div>
            )}
            {getIncognitMode() === '1' && (
              <div>
                <Col span={15}>
                  <Text style={{ color: '#113C71' }}>C. en Disputa</Text>
                </Col>
                <Col span={9}>
                  {'$'}
                  {this.showTotals().totalAlternativeSaleExpected}
                </Col>
              </div>
            )}
            {getIncognitMode() === '1' && (
              <div>
                <Col span={15}>
                  <Text style={{ color: '#113C71' }}>C. Cobrado</Text>
                </Col>
                <Col span={9}>
                  {'$'}
                  {this.showTotals().totalAlternativeSaleCheck}
                </Col>
              </div>
            )}
          </Card>
        </Col>
        {this.props.total.map(
          (item: DistributionCenter, index: number): React.ReactNode => (
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={{ span: 6 }}
              xxl={0}
              key={index}
            >
              <Card
                title={item.name}
                style={{
                  boxShadow: 'px 3px 4px rgba(0, 0, 0, 0.12)',
                  border: '1px solid #74989d',
                  boxSizing: 'border-box',
                  backgroundColor: '#FFFFF',
                  borderRadius: '5px',
                }}
              >
                <Col span={15}>
                  <Text style={{ color: '#113C71' }}>Venta Real</Text>
                </Col>
                <Col span={9}>
                  {'$'}
                  {getIncognitMode() === '1'
                    ? item.realSale
                    : item.realSale - item.totalAlternativeSale}
                </Col>
                <Col span={15}>
                  <Text style={{ color: '#113C71' }}>Venta Total</Text>
                </Col>
                <Col span={9}>
                  {'$'}
                  {item.totalSale}
                </Col>
                <Col span={15}>
                  <Text style={{ color: '#113C71' }}>Venta en Disputa</Text>
                </Col>
                <Col span={9}>
                  {'$'}
                  {item.totalSaleExpected}
                </Col>
                <Col span={15}>
                  <Text style={{ color: '#113C71' }}>Venta Cobrada</Text>
                </Col>
                <Col span={9}>
                  {'$'}
                  {item.saleCheck}
                </Col>
                {getIncognitMode() === '1' && (
                  <div>
                    <Col span={15}>
                      <Text style={{ color: '#113C71' }}>C.</Text>
                    </Col>
                    <Col span={9}>
                      {'$'}
                      {item.totalAlternativeSale}
                    </Col>
                  </div>
                )}
                {getIncognitMode() === '1' && (
                  <div>
                    <Col span={15}>
                      <Text style={{ color: '#113C71' }}>C. en Disputa</Text>
                    </Col>{' '}
                    <Col span={9}>
                      {'$'}
                      {item.totalAlternativeSaleExpected}
                    </Col>
                  </div>
                )}
                {getIncognitMode() === '1' && (
                  <div>
                    <Col span={15}>
                      <Text style={{ color: '#113C71' }}>C. Cobrado</Text>
                    </Col>
                    <Col span={9}>
                      {'$'}
                      {item.totalAlternativeSaleCheck}
                    </Col>
                  </div>
                )}
              </Card>
            </Col>
          ),
        )}
      </Row>
    );
  };
  public render = (): React.ReactNode => {
    if (getIncognitMode() !== '1') return <Redirect to="/" />;
    if (this.state.redirect === true) return <Redirect to="/login" />;
    if (!localStorage.getItem('token')) return <Redirect to="/login" />;
    return (
      <div>
        <Row>
          { isAllowed([Permissions.DEVELOP_MODE]) && (<AuditConfigModal />) }
        </Row>
        {/* <Row style={{ backgroundColor: '#F1F7FF' }}>
          <Col
            className="dashboard-filters-elements"
            xs={24}
            sm={12}
            md={12}
            lg={8}
            xl={8}
            xxl={8}
          >
            <Row></Row>
            {this.renderStartDatePicker()}
            {this.renderEndDatePicker()}
            <p />
          </Col>
        </Row>

        <Row>
          <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
            {this.renderList('horizontal')}
          </Col>
          <Col xs={0} sm={0} md={0} lg={24} xl={24} xxl={24}>
            {this.renderList('vertical')}
          </Col>
        </Row>

        <Row>
          <p />
        </Row> */}
      </div>
    );
  };
}
const mapStateToProps = (states: RootState): StateProps => {
  return {
    total: states.saleState.dashboardValue.distributionCentersTotals,
  };
};

const mapDispatchToProps = {
  totalCalculator,
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard);


type AuditConfigModalProps = {};
const AuditConfigModal = (props: AuditConfigModalProps) => {
  const BTN_COLOR = 'gray';

  let [ visible, setVisible ] = useState(false);
  let [ isLoading, setIsLoading ] = useState(true);
  let [ isError, setIsError ] = useState(false);

  let [ users, setUsers ] = useState([] as DbOldTypes.AuditConfig['users']);
  let [ review, setReview ] = useState(null as null | DbOldTypes.AuditConfig['review']);

  let loadConfig = useCallback(async () => {
    console.log(`Getting ac.`);
    setIsLoading(true);
    setIsError(false);
    let result = await callApi(ApiRoute.GetAuditConfig, {});
    console.log(result);
    if(!result || !result.success || !('auditConfig' in result)){
      setIsError(true);
      return;
    }
    setUsers(result.auditConfig.users);
    setReview(result.auditConfig.review);
    setIsLoading(false);
  }, []);

  let setConfig = useCallback(async (users: DbOldTypes.AuditConfig['users'], review: null | DbOldTypes.AuditConfig['review']) => {
    if(!review) return;
    let result = await callApi(ApiRoute.SetAuditConfig, { users, review });
    console.log(result);
    if(!result || !result.success){
      alert((result && result.msg) || `Error actualizando cambios.`);
      return;
    }
    alert('Se guardaron los cambios.');
    await loadConfig();
  }, [ loadConfig ]);


  const userLevelOptions: { name: string, value: DbOldValues._UserAuditLevel }[] = Globals.Utils.Object.entries(DbOldDetails.userAuditLevel).map(entry => ({
    name: entry.value.name,
    value: entry.key,
  }));
  const distCenterIdOptions: { name: string, value: DbOldValues._DistCenterId }[] = Globals.Utils.Object.entries(DbOldDetails.distCenterId).map(entry => ({
    name: entry.value.name,
    value: entry.key,
  }));

  type ACUser = DbOldTypes.AuditConfig['users'][number];
  let changeUserData = useCallback(<K extends keyof Omit<ACUser, 'email'>>(userEmail: ACUser['email'], key: K, value: ACUser[K]) =>  {
    setUsers(pUsers => {
      let newUsers: ACUser[] = [];
      for(let pUser of pUsers){
        newUsers.push({ ...pUser, [key]: pUser.email === userEmail ? value : pUser[key] });
      }
      return newUsers;
    });
    //setTimeout(() => setUsers(pUsers => [ ...pUsers ]), 400);
  }, [ users, setUsers ]);
  
  useEffect(() => {
    if(visible){
      loadConfig();
    }
  }, [ visible, loadConfig ]);

  let modalContent: JSX.Element;
  if(isError || !review) modalContent = (
    <div style={{ marginTop: 20, textAlign: 'center' }} >
      <span> Error obteniendo configuración. </span>
    </div>
  );
  else if(isLoading) modalContent = (
    <div style={{ marginTop: 20, textAlign: 'center' }} >
      <Spin size="large" />
    </div>
  );
  else modalContent = (
    <div style={ { height: '88vh', overflowY: 'auto' } } >
      <Row style={ { textAlign: 'right' } } >
        <Button
          type="primary"
          style={ { backgroundColor: 'green', borderColor: 'green', color: 'white' } }
          onClick={ () => { setConfig(users, review) } }
        > Guardar cambios </Button>
      </Row>
      <Row>
        <Col span={ 12 } style={ { padding: '0 16px' } } >
          <h2> Usuarios </h2>
          <table style={ { width: '100%', ...stylesheet.tableBordered } } >
            <thead>
              <tr>
                <th style={ { ...stylesheet.tableBordered } } > Email </th>
                <th style={ { ...stylesheet.tableBordered } } > Nombre </th>
                <th style={ { ...stylesheet.tableBordered } } > Nivel </th>
                <th style={ { ...stylesheet.tableBordered } } > Gerencias </th>
                <th style={ { ...stylesheet.tableBordered } } >
                    <Button style={{ backgroundColor: 'royalblue', color: 'white' }} onClick={ () => {
                      let email = prompt(`Ingrese el correo electrónico:`, '@centraldegas.net') || '';
                      if(!email) return;
                      setUsers(previousUsers => {
                        let newUsers: typeof previousUsers = [
                          ...previousUsers,
                          { email, name: '', level_key: DbOldValues.UserAuditLevel.None, dist_center_ids: [] }
                        ];
                        return newUsers;
                      });
                    } } >
                      <Icon type="plus" />
                    </Button>
                </th>
              </tr>
            </thead>
            <tbody>
              { users && users.map((user, userIndex) => {
                return (
                  <tr key={ `user_${userIndex}_${user.email}` } >
                    <td style={ { ...stylesheet.tableBordered } } > { user.email } </td>
                    <td style={ { ...stylesheet.tableBordered } } > <Input id="user_name" value={ user.name } onChange={ event => changeUserData(user.email, 'name', event.target.value) } /> </td>
                    <td style={ { ...stylesheet.tableBordered } } >
                      <Select id="user_levelKey" value={ user.level_key } onChange={ value => changeUserData(user.email, 'level_key', value) } style={{ width: '90%' }} >
                        { userLevelOptions.map(userLevelOption => (
                          <Select.Option key={ `user_lko_${userLevelOption.value}` } value={ userLevelOption.value } > { userLevelOption.name } </Select.Option>
                        )) }
                      </Select>
                    </td>
                    <td style={ { ...stylesheet.tableBordered } } >
                      { distCenterIdOptions.map(option => {
                        let checked = user.dist_center_ids.includes(option.value);
                        let iconType = checked ? 'check-square' : 'close-square';
                        let bgColor = checked ? 'lightblue' : 'lightpink';
                        return (
                          <div
                            key={ `user_dcio_${option.value}` }
                            style={ { backgroundColor: bgColor, display: 'flex', alignItems: 'center', padding: 2, cursor: 'pointer' } }
                            onClick={ () => {
                              let newDistCenterIds: DbOldValues._DistCenterId[] = [ ...user.dist_center_ids ];
                              if(checked) newDistCenterIds.splice(newDistCenterIds.indexOf(option.value), 1);
                              else newDistCenterIds.push(option.value);
                              changeUserData(user.email, 'dist_center_ids', newDistCenterIds);
                            } }
                          >
                            <Icon type={ iconType } />
                            { option.name }
                          </div>
                        );
                      }) }
                    </td>
                    <td style={ { ...stylesheet.tableBordered } } >
                      <Button style={{ backgroundColor: 'red', color: 'white' }} onClick={ () => {
                        setUsers(previousUsers => {
                          let newUsers: typeof previousUsers = [
                            ...previousUsers,
                          ];
                          newUsers.splice(userIndex, 1);
                          return newUsers;
                        });
                      } } >
                        <Icon type="plus" />
                      </Button>
                    </td>
                  </tr>
                );
              }) }
            </tbody>
          </table>
        </Col>
        <Col span={ 12 } style={ { padding: '0 16px' } } >
          {/* <pre>{ JSON.stringify(users, null, '\t') }</pre> */}
          <h2> Parámetros </h2>
          <table style={ { width: '100%', ...stylesheet.tableBordered } } >
            <thead>
              <tr>
                <th style={ { width: '60%', ...stylesheet.tableBordered } } > Gerencia </th>
                <th style={ { width: '40%', ...stylesheet.tableBordered } } > Porcentaje default </th>
              </tr>
            </thead>
            <tbody>
              { Globals.Utils.Object.entries(DbOldDetails.distCenterId).map(entry => {
                if(!review) return null;
                return (
                  /*
                  let newReview: DbOldTypes.AuditConfig['review'] = {
                      ...prevReview,
                      percentage_limits: [ value || 0, prevReview.percentage_limits[1] ],
                      prevReview.default_percentage_by_dist_center_id[entry.key][0]
                    };
                    */
                  <tr key={ `param_percentage_${entry.key}` } >
                    <td style={ { ...stylesheet.tableBordered } } > { entry.value.name } </td>
                    <td style={ { ...stylesheet.tableBordered } } >
                      CP:_<InputNumber
                        id={ `param_percentage_${entry.key}` }
                        value={ review.default_percentage_by_dist_center_id[entry.key][0] }
                        style={ { width: '90%' } }
                        onChange={ value => setReview(prevReview => {
                          if(!prevReview) return null;
                          let newReviewwithValue =[...prevReview.default_percentage_by_dist_center_id[entry.key]];
                          newReviewwithValue[0] = value || 0
                          let newReview: DbOldTypes.AuditConfig['review'] = {
                            ...prevReview,
                            default_percentage_by_dist_center_id: {
                              ...prevReview.default_percentage_by_dist_center_id,
                              [entry.key]: newReviewwithValue,
                            },
                          };
                          return newReview;
                        }) }
                      />SP:_<InputNumber
                      id={ `param_percentage_${entry.key}` }
                      value={ review.default_percentage_by_dist_center_id[entry.key][1] }
                      style={ { width: '90%' } }
                      onChange={ value => setReview(prevReview => {
                        if(!prevReview) return null;
                        let newReviewwithValue =[...prevReview.default_percentage_by_dist_center_id[entry.key]];
                        newReviewwithValue[1] = value || 0
                        let newReview: DbOldTypes.AuditConfig['review'] = {
                          ...prevReview,
                          default_percentage_by_dist_center_id: {
                            ...prevReview.default_percentage_by_dist_center_id,
                            [entry.key]: newReviewwithValue,
                          },
                        };
                        return newReview;
                      }) }
                    />PI:_<InputNumber
                    id={ `param_percentage_${entry.key}` }
                    value={ review.default_percentage_by_dist_center_id[entry.key][2] }
                    style={ { width: '90%' } }
                    onChange={ value => setReview(prevReview => {
                      if(!prevReview) return null;
                      let newReviewwithValue =[...prevReview.default_percentage_by_dist_center_id[entry.key]];
                      newReviewwithValue[2] = value || 0
                      let newReview: DbOldTypes.AuditConfig['review'] = {
                        ...prevReview,
                        default_percentage_by_dist_center_id: {
                          ...prevReview.default_percentage_by_dist_center_id,
                          [entry.key]: newReviewwithValue,
                        },
                      };
                      return newReview;
                    }) }
                  />
                    </td>
                  </tr>
                );
              }) }
            </tbody>
          </table>
          <Row style={ { marginTop: 22 } } >
            <Col span={ 12 } >
              <div>
                <label style={ { fontWeight: 'bold' } } > Porcentage mínimo: </label>
                <InputNumber id={ `param_rangeMin` } value={ review.percentage_limits[0] } style={ { width: '100%' } }
                  onChange={ value => setReview(prevReview => {
                    if(!prevReview) return null;
                    let newReview: DbOldTypes.AuditConfig['review'] = {
                      ...prevReview,
                      percentage_limits: [ value || 0, prevReview.percentage_limits[1] ],
                    };
                    return newReview;
                  }) }
                />
              </div>
            </Col>
            <Col span={ 12 } >
              <div>
                <label style={ { fontWeight: 'bold' } } > Porcentage máximo: </label>
                <InputNumber id={ `param_rangeMax` } value={ review.percentage_limits[1] } style={ { width: '100%' } }
                  onChange={ value => setReview(prevReview => {
                    if(!prevReview) return null;
                    let newReview: DbOldTypes.AuditConfig['review'] = {
                      ...prevReview,
                      percentage_limits: [ prevReview.percentage_limits[0], value || 0 ],
                    };
                    return newReview;
                  }) }
                />
              </div>
            </Col>
          </Row>
          <div>
            <label style={ { fontWeight: 'bold' } } > Valor mínimo de venta: </label>
              <InputNumber id={ `param_rangeMax` } value={ review.sale_amount_minimum_value } style={ { width: '100%' } }
                onChange={ value => setReview(prevReview => {
                  if(!prevReview) return null;
                  let newReview: DbOldTypes.AuditConfig['review'] = {
                    ...prevReview,
                    sale_amount_minimum_value: value || 50,
                  };
                  return newReview;
                }) }
              />
          </div>
          <label style={ { fontWeight: 'bold' } } > Posibles valores finales: </label>
          <br />
          { review.sale_random_final_amounts.map((amount, aIndex) => {
            return (
              <Tag key={ `param_srfa_${aIndex}_${amount}` } closable onClose={ (event: Event) => {
                event.preventDefault();
                setReview(prevReview => {
                  if(!prevReview) return null;
                  let { sale_random_final_amounts: [ ...newSRFA ] } = prevReview;
                  newSRFA.splice(aIndex, 1);
                  let newReview: DbOldTypes.AuditConfig['review'] = {
                    ...prevReview,
                    sale_random_final_amounts: newSRFA,
                  };
                  return newReview;
                });
              } } > { amount } </Tag>
            );
          }) }
          <br />
          <label> Puede haber valores repetidos para aumentar probabilidad de ser elegido para la venta. </label>
          <Tag onClick={ () => {
            let value = +(prompt(`Ingrese valor:`) || '');
            if(isNaN(value) || value < 1) return;
            setReview(prevReview => {
              if(!prevReview) return null;
              let { sale_random_final_amounts: [ ...newSRFA ] } = prevReview;
              newSRFA.push(value);
              let newReview: DbOldTypes.AuditConfig['review'] = {
                ...prevReview,
                sale_random_final_amounts: newSRFA.sort((a, b) => a - b),
              };
              return newReview;
            });
          } } >
            <Icon type='plus' /> Añadir
          </Tag>
          {/* <Row>
            <pre> { JSON.stringify(review, null, '\t') } </pre>
          </Row> */}
        </Col>
      </Row>
    </div>
  );


  return (
    <div>
      <Button type="primary" style={{ backgroundColor: BTN_COLOR, borderColor: BTN_COLOR, color: 'white', fontWeight: 'bold' }} onClick={ () => setVisible(true) } >
        Configuración
      </Button>
      <Modal
        width={ '100vw' }
        style={ { position: 'fixed', top: 0, left: 0 } }
        title="Configuración Auditoría"
        centered
        visible={ visible }
        footer={ null }
        onCancel={ () => setVisible(false) }
      >
        { modalContent }
      </Modal>
    </div>
  );
};

const stylesheet = {
  tableBordered: {
    border: '2px solid black',
  } as React.CSSProperties,
};