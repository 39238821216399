/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Ricardo Sansores <ricardo@ssf.mx>, May 2019
 *
 * This component is intended to allow users to issue invoice from tickets.
 * A ticket of a purchase always include a IUS (Unique Service Identifier)
 * That IUS can be captured in this screen, if there is a sale with that IUS
 * the sale is added to the "salesStagingArea" and finally the user can issue
 * an invoice of all the sales that are in the staging area.
 */

import React, { Component, FormEvent } from 'react';
import {
  saveInvoce,
  InvoiceActionConstructors,
  addSaleToStagingArea,
  removeSaleFromStagingArea,
  setFiscalData,
  getFiscalInformation,
} from 'routes/invoices/redux/actions';
import './style.css';
import { connect } from 'react-redux';
import { FormComponentProps, WrappedFormUtils } from 'antd/lib/form/Form';
import { RootState } from 'store/configureStore';
import { Sale, Email, InvoicingPortalFiscalInfo } from 'types/type';
import { Card, Form, Col, List, Layout, Divider, Row, Icon, Radio } from 'antd';
import Text from 'antd/lib/typography/Text';
import { PageHeader, Input, Button } from 'antd';
import { Redirect, RouteComponentProps } from 'react-router';
import SaleForm from './components/sale/saleForm';
import { enterpriceLogo as centralGas } from 'boot/config';
import { InvoiceState } from 'routes/invoices/redux/reducers';
import { Popups } from 'commons/components/popups/popups';
import { Strings as i8n } from 'commons/strings';
import FiscalDataForm from './components/FiscalData/fiscalDataForm';
import { InvoiceRules as r } from './components/FiscalData/rulesFiscalData';
import { ButtonProps } from 'antd/lib/button';
import moment from 'moment';
import ticket from 'assets/ticket.png';
import ticketIus from 'assets/ticket_ius.png';
import ticketTotal from 'assets/ticket_total.png';
import Login from './customerInvoice/login';

//Redirect when invoice is created
const REDIRECT_FINISH = '/factura-finish';

/**
 * This is the state of the react component is not shared outside
 * this file, this do not go to the redux store. The state
 * represents an invoice that will be passed in different states
 * of the invoicing wizard.
 */

interface StepsInvice {
  step: number;
  content: JSX.Element;
}

/**
 * The props of the react component.
 * 1. The state inside the wizard.
 * 2. The action constructurs. This is a REDUX common pattern.
 * 3. This is required in order to be able to make redirections.
 * In this component we redirect to invoice-finish when the process
 * completes.
 */
type Props = FormComponentProps &
  InvoiceState &
  InvoiceActionConstructors &
  RouteComponentProps<{}>;

interface InvoiceFormFields {
  ius: string;
  total: number;
}

const buttonProps: ButtonProps = {
  type: 'danger',
  htmlType: 'submit',
};

/**
 * This component represents the whole screen in the system for
 * issuing invoinces of tickets. It is used to invoice only
 * tickets that have a IUS (usually the non-credit) tickets.
 */
class InvoiceFormIUSComponent extends Component<Props> {
  public state = {
    currentFormStep: 0,
    imgValue: 0,
  };
  public componentDidMount(): void {
    this.setState({ currentFormStep: 0 });
  }
  /**
   * Called when pressed button add Ticket-From-IUS
   */
  private handleOnSubmitIUS = (
    e: FormEvent,
    form: WrappedFormUtils<InvoiceFormFields>,
  ): void => {
    e.preventDefault();
    form.validateFields(
      async (err, formFields: InvoiceFormFields): Promise<void> => {
        if (!err) {
          try {
            await this.props.addSaleToStagingArea(
              `${formFields.ius.toUpperCase()}`,
              formFields.total,
              false,
            );
            form.resetFields();
            form.setFieldsValue({ ius: null });
            form.setFieldsValue({ total: null });
          } catch (error) {
            Popups.notifyException(error);
          }
        }
      },
    );
  };

  /**
   * Called when the user captured hist fiscal information
   * and is one step closer to finish the invoice process
   */
  private onFiscalDataProvidedNext = (
    e: FormEvent,
    form: WrappedFormUtils<InvoicingPortalFiscalInfo>,
  ): void => {
    e.preventDefault();
    form.validateFields(
      async (err, values: InvoicingPortalFiscalInfo): Promise<void> => {
        if (err) {
          Promise.resolve();
        } else {
          this.props.setFiscalData(values);
          this.next();
        }
      },
    );
  };
  private onFiscalDataProvidedPrevius = (
    e: FormEvent,
    fiscalInfo: InvoicingPortalFiscalInfo,
  ): void => {
    e.preventDefault();
    this.props.setFiscalData(fiscalInfo);
    this.prev();
  };

  /**
   * This function is called when the button invoicing is
   * called. Is the final step.
   */
  private onRequestInvoice = (e: FormEvent): void => {
    e.preventDefault();
    this.props.form.validateFields(
      async (err, fields: Email): Promise<void> => {
        !err &&
          Popups.executeOrNotifyError(
            (): Promise<void> =>
              this.createInvoice(this.props.salesStagingArea, fields),
          );
      },
    );
  };

  private findByRfc = (rfc: string): void => {
    this.props.getFiscalInformation(rfc);
  };
  /**
   * Create the invoice with all the tickets and the
   * correct fiscal data.
   */
  private createInvoice = async (
    salesStagingArea: Sale[],
    fields: Email,
  ): Promise<void> => {
    await this.props.saveInvoce(
      {
        id: '',
        idG4s: '',
        name: this.props.fiscalData.businessName,
        rfc: this.props.fiscalData.rfc,
        usoCfdi: this.props.fiscalData.cfdiUse,
        customerFiscalRegime: this.props.fiscalData.fiscalRegime,
        formaPago: this.props.fiscalData.paymentWay,
        street: this.props.fiscalData.street,
        externalNumber: this.props.fiscalData.externalNumber,
        internalNumber: this.props.fiscalData.internalNumber,
        zipCode: this.props.fiscalData.zipCode,
        colony: this.props.fiscalData.colony,
        city: this.props.fiscalData.city,
        municipality: this.props.fiscalData.municipality,
        state: this.props.fiscalData.state,
        status: 'PENDING',
        email: fields.email,
        sales: salesStagingArea,
        fiscalRegime: '',
      },
      this.props.fiscalData,
    );
    this.props.addSaleToStagingArea('0', 0, true);
    Popups.notifySuccess(i8n.INVOICE_CREATED);
    this.props.history.push(REDIRECT_FINISH);
  };

  /**
   * Remove an Invoice from the list showed
   */
  private removeInvoice = (sale: Sale): void => {
    this.props.removeSaleFromStagingArea(sale);
  };

  /**
   * Render the current step in the wizard.
   */
  private stepsInvoice = (): JSX.Element => {
    return (
      <Row>
        <Radio.Group
          defaultValue={this.state.currentFormStep}
          buttonStyle="solid"
          size="large"
          style={{ width: '100%', textAlign: 'center' }}
        >
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 8, offset: 0 }}
            xl={{ span: 8, offset: 0 }}
            xxl={{ span: 8, offset: 0 }}
          >
            {' '}
            <Radio.Button
              value={0}
              disabled
              style={{
                width: '100%',
                backgroundColor:
                  this.state.currentFormStep === 0 ? '#113C71' : '#E5E5E5',
              }}
            >
              1. Ingresar IUS
            </Radio.Button>
          </Col>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 8, offset: 0 }}
            xl={{ span: 8, offset: 0 }}
            xxl={{ span: 8, offset: 0 }}
          >
            <Radio.Button
              value={1}
              disabled
              style={{
                width: '100%',
                backgroundColor:
                  this.state.currentFormStep === 1 ? '#113C71' : '#E5E5E5',
              }}
            >
              2. Ingresar datos Fiscales
            </Radio.Button>
          </Col>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 8, offset: 0 }}
            xl={{ span: 8, offset: 0 }}
            xxl={{ span: 8, offset: 0 }}
          >
            <Radio.Button
              value={2}
              disabled
              style={{
                width: '100%',
                backgroundColor:
                  this.state.currentFormStep === 2 ? '#113C71' : '#E5E5E5',
              }}
            >
              3. Generar factura
            </Radio.Button>
          </Col>
        </Radio.Group>
      </Row>
    );
  };

  /**
   * The staging area that include the found tickets by IUS
   */
  private renderSalesStagingArea = (): JSX.Element => {
    return (
      <Col style={{ backgroundColor: '#fff' }}>
        {this.props.salesStagingArea.length > 0 ? (
          <Card
            className={'card-invoice-content'}
            type="inner"
            title={i8n.IUS_ADDED_IN_STAGING_AREA}
          >
            <List
              dataSource={this.props.salesStagingArea}
              renderItem={(item: Sale, index: number): JSX.Element => (
                <Col>
                  <List.Item
                    actions={[
                      <Button
                        type="link"
                        key="1"
                        id={'invoice_delete_' + index.toString()}
                        onClick={(): void => {
                          this.removeInvoice(item);
                        }}
                      >
                        Eliminar
                      </Button>,
                    ]}
                  >
                    <List.Item.Meta
                      title={
                        <div id={'invoice_list_' + index.toString()}>
                          {item.IUSCode +
                            ' | $' +
                            item.total +
                            ' | ' +
                            moment(item.endDateSave).format('DD/MM/YYYY')}
                        </div>
                      }
                    />
                  </List.Item>
                </Col>
              )}
            />{' '}
          </Card>
        ) : (
          <Card bordered={false}>
            <div>
              <p />
            </div>
          </Card>
        )}
      </Col>
    );
  };

  /**
   * This is a separate page from all the system. Because of that,
   * This page have a layout that do not inherit from index.tsx like
   * any other component. So we need to create a layout for this special
   * page.
   */
  private renderHeader = (): JSX.Element => {
    return (
      <Layout.Header className={'layout-invoice-header'}>
        <Col xs={2} sm={2} md={2} lg={3} xl={4} xxl={4}>
          <img src={centralGas} className="logo-invoice" alt="Logo" />
        </Col>
        <Col
          xs={22}
          sm={22}
          md={22}
          lg={5}
          xl={7}
          xxl={9}
          className="align-center-col"
        >
          <PageHeader title={i8n.STAMP_INVOICE_TICKET} />
        </Col>

        <Login />
      </Layout.Header>
    );
  };

  /**
   * Third step of the component wizard. Request email and create the
   * invoice.
   */
  private renderEmailForm = (): JSX.Element => {
    const {
      form: { getFieldDecorator },
      isFetching,
    } = this.props;
    return (
      <Form onSubmit={(e): void => this.onRequestInvoice(e)}>
        <Col span={24} className={'card-invoice-content'}>
          <h1>Correo electrónico</h1>
          Ingresa el correo electrónico donde quieres que te enviemos la factura
          <Card
            className={'card-invoice-content'}
            style={{
              backgroundColor: '#f1f7ff',
              margin: '0 auto',
              maxWidth: '18rem',
            }}
          >
            <Form.Item hasFeedback label="Correo Electrónico">
              {getFieldDecorator('email', {
                rules: r.emailRule,
              })(<Input size="large" placeholder="Correo electrónico" />)}
            </Form.Item>
          </Card>
        </Col>
        <Col span={24} className="align-center-col">
          <p />
          <Button {...buttonProps} id="invoice_button" disabled={isFetching}>
            {isFetching ? 'Loading...' : 'Facturar'}
          </Button>
        </Col>
      </Form>
    );
  };
  private next = (): void => {
    const currentFormStep = this.state.currentFormStep + 1;
    this.setState({ currentFormStep });
  };

  private prev = (): void => {
    const currentFormStep = this.state.currentFormStep - 1;
    this.setState({ currentFormStep });
  };
  public handlePrevious = (): void => {
    this.prev();
  };

  public handleNext = (): void => {
    const { salesStagingArea } = this.props;
    if (salesStagingArea.length < 1) {
      Popups.notifyError(i8n.NO_TICKET_IN_STAGING_AREA);
    } else {
      this.next();
    }
  };
  public changeImg = (inputSelected: number): void => {
    this.setState({ imgValue: inputSelected });
  };

  private makeSteps = (): StepsInvice[] => {
    return [
      {
        step: 0,
        content: (
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            {' '}
            <Col span={24}>
              <SaleForm
                handleOnSubmitIUS={this.handleOnSubmitIUS}
                isFetching={this.props.isFetching}
                renderCancelButton={this.renderCancelButton}
                handleNext={this.handleNext}
                changeImg={this.changeImg}
              />
              {this.renderSalesStagingArea()}
            </Col>
            <Col span={1} />
            <Col span={24} className="align-center-col">
              <Divider />
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                {this.renderCancelButton()}
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Button
                  type="primary"
                  id="ius_next_button"
                  onClick={(): void => this.handleNext()}
                >
                  {' '}
                  Siguiente <Icon type="right" />
                </Button>
              </Col>
            </Col>
          </Col>
        ),
      },
      {
        step: 1,
        content: (
          <FiscalDataForm
            onFiscalDataProvidedNext={this.onFiscalDataProvidedNext}
            onFiscalDataProvidedPrevius={this.onFiscalDataProvidedPrevius}
            renderCancelButton={this.renderCancelButton}
            fiscalData={this.props.fiscalData}
            isFetching={this.props.isFetching}
            getFiscalInformation={this.findByRfc}
          />
        ),
      },
      {
        step: 2,
        content: (
          <Row gutter={16}>
            {this.renderEmailForm()}

            <Col span={24} className="align-center-col">
              <Divider />
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Button
                  type="primary"
                  id="save_previous_button"
                  onClick={this.handlePrevious}
                >
                  {' '}
                  Anterior{' '}
                </Button>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                {this.renderCancelButton()}
              </Col>
            </Col>
          </Row>
        ),
      },
    ];
  };
  public renderCancelButton = (): JSX.Element => (
    <a href="/clientes">
      <Button id="cancel_button" type="danger">
        {' '}
        Cancelar <Icon type="close" />
      </Button>
    </a>
  );

  public renderPreviousButton = (): JSX.Element => (
    <Button type="primary" onClick={this.handlePrevious}>
      {' '}
      Anterior{' '}
    </Button>
  );
  //TODO Extract all the strings that are hardcoded.

  /**
   * Render the invoice creation component. This component is composed
   * by a wizard where first page is a IUS to tickets search, then as
   * second step a fiscal information form and finally a small form to
   * request the email where the invoice will be delivered.
   */
  public render = (): React.ReactNode => {
    const { currentFormStep } = this.state;
    let stepDisplay: StepsInvice | undefined;

    const stepContent = this.makeSteps();
    stepDisplay = stepContent.find(
      (item): boolean => currentFormStep === item.step,
    );
    if (localStorage.getItem('token')) return <Redirect to="/" />;
    return (
      <Layout>
        {this.renderHeader()}

        <Layout.Content className={'layout-invoice-content'}>
          {currentFormStep === 0 ? (
            <Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 16, offset: 1 }}
                xl={{ span: 16, offset: 1 }}
                xxl={{ span: 16, offset: 1 }}
              >
                <Col span={24}>{this.stepsInvoice()}</Col>
                <Col span={24}>
                  <Card className={'card-invoice-content'}>
                    <Col>{stepDisplay ? stepDisplay.content : null}</Col>
                  </Card>
                </Col>
              </Col>
              <Col
                xs={{ span: 0 }}
                sm={{ span: 0 }}
                md={{ span: 0 }}
                lg={{ span: 7 }}
                xl={{ span: 7 }}
                xxl={{ span: 7 }}
              >
                <img
                  alt="Card"
                  style={{ width: '69%', borderLeft: '20px solid transparent' }}
                  src={
                    this.state.imgValue === 1
                      ? ticketIus
                      : this.state.imgValue === 2
                      ? ticketTotal
                      : ticket
                  }
                />
              </Col>
              <Col>
                <p />
              </Col>
            </Col>
          ) : (
            <Col>
              {this.stepsInvoice()}
              <Col>
                <p />
              </Col>
              <Card className={'card-invoice-content'}>
                <Col span={24}>{stepDisplay ? stepDisplay.content : null}</Col>
              </Card>
            </Col>
          )}
        </Layout.Content>
        <Layout.Footer>
          <Row type="flex" justify="center">
            <Col span={23} offset={1}>
              <Text>
                <h3>¿Necesitas ayuda?</h3>
              </Text>
            </Col>
            <Col
              xs={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              md={{ span: 23, offset: 1 }}
              lg={{ span: 11, offset: 1 }}
              xl={{ span: 11, offset: 1 }}
              xxl={{ span: 11, offset: 1 }}
              style={{ borderRight: '1px solid gray' }}
            >
              <p>
                <h1>
                  Si tienes dudas o necesitas ayuda para generar tu factura
                  ponte en contacto con nosotros y con gusto te atenderemos
                </h1>
              </p>
              <p>
                <Icon type="phone" />
                {' (449) 912 22 22 '}

                <Icon type="mail" />
                {' atencionalcliente@centraldegas.net'}
              </p>
            </Col>
            <Col xs={24} sm={24} md={24} lg={11} xl={11} xxl={11} offset={1}>
              <p>
                <h1>Horario de Atención</h1>
              </p>
              <p>
                <b>Lunes Viernes:</b>07:00h a 19:00h
              </p>
              <p>
                <b>Sábado:</b>07:00h a 17:00h
              </p>
              <p>
                <b>Domingo:</b>08:00h a 14:00h
              </p>
            </Col>
          </Row>
        </Layout.Footer>
      </Layout>
    );
  };
}

/**
 * Maps the state to the component props in react
 * @param states The store of redux
 */
const mapStateToProps = (states: RootState): InvoiceState => {
  return {
    salesStagingArea: states.invoiceState.salesStagingArea,
    fiscalData: states.invoiceState.fiscalData,
    isFetching: states.invoiceState.isFetching,
  };
};

/**
 * Maps the possible actions that can be executed in this component.
 */
const mapDispatchToProps = {
  addSaleToStagingArea,
  removeSaleFromStagingArea,
  setFiscalData,
  saveInvoce,
  getFiscalInformation,
};

/**
 * Connects the react component to the REDUX store.
 */
export default connect<InvoiceState, InvoiceActionConstructors, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create()(InvoiceFormIUSComponent));
