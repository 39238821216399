import React, { Component } from 'react';
import {
  Customer,
  FiscalInformation,
  CustomerModel,
  G4sState,
  G4sMunicipality,
} from 'types/type';
import { Row, Col, Collapse, Select, Button, Popover, Input } from 'antd';
import { InvoiceRules as r } from '../../../invoices/components/FiscalData/rulesFiscalData';
import { LabelsCustomer } from 'commons/strings';
import Form, {
  GetFieldDecoratorOptions,
  ValidationRule,
} from 'antd/lib/form/Form';
import { CollapseProps } from 'antd/lib/collapse';
import {
  usoCFDI,
  formaPago,
  regimenFiscal,
} from 'commons/components/regimenFiscal';
import dump from 'assets/dump.svg';
import { initFiscalInformation } from 'commons/initTypes';
import GenericSelect from 'commons/components/genericComponents/genericSelect';
import G4sServices from '../../../../services/g4sServices/index';
const g4sServices: G4sServices = new G4sServices();

interface State {
  municipalities: G4sMunicipality[];
  states: G4sState[];
  customer: Customer;
  businessNames: FiscalInformation[];
  visiblePopover: boolean[];
  isVisibleCollapse: boolean;
}
interface Props {
  customerModel: CustomerModel;
  sizeScreen: Function;
  getFieldDecorator: <T extends Record<string, object | string | number> = {}>(
    id: keyof T,
    options?: GetFieldDecoratorOptions | undefined,
  ) => (node: React.ReactNode) => React.ReactNode;
  setFieldsValue: Function;
  getFieldsValue: Function;
  errors: [];
  isReadOnly: boolean;
}

class FiscalInformationManager extends Component<Props, State> {
  public state: State = {
    customer: this.props.customerModel.customer,
    businessNames: [],
    visiblePopover: [false],
    isVisibleCollapse: false,
    states: [],
    municipalities: [],
  };

  public componentDidMount(): void {
    this.fetchG4sData();
  }

  public activeKeys = (): CollapseProps => {
    return {
      activeKey: this.props.errors.map((item: [], index: number): string => {
        return index.toString();
      }),
    };
  };

  //Function to remove a selected collapse with businessName information
  private deleteBusinessName = (index: number): void => {
    let filteredBusiNames: FiscalInformation[] = this.state.businessNames.filter(
      (value: FiscalInformation, findex: number): boolean => findex !== index,
    );
    if (filteredBusiNames.length < 1) {
      let busiNamesList: FiscalInformation[] = [];
      busiNamesList.push(initFiscalInformation);
      this.setState({
        isVisibleCollapse: !this.state.isVisibleCollapse,
        businessNames: busiNamesList,
      });
    }
    this.props.setFieldsValue({ fiscalInformation: filteredBusiNames });
    this.setState({
      businessNames: filteredBusiNames,
    });
  };

  //Function to add a new collapse to register a new businessName
  private addBusinessName = (
    fiscalInformationList: FiscalInformation[],
  ): void => {
    fiscalInformationList.push(initFiscalInformation);
    this.setState({
      businessNames: fiscalInformationList,
    });
  };

  /**
   * This method loads data from g4s
   */
  private fetchG4sData = (): void => {
    Promise.all([
      g4sServices.getG4sStates(), //0
      g4sServices.getG4sMunicipalities(1), //1
    ]).then((results): void => {
      this.setState({
        states: results[0].data,
        municipalities: results[1].data,
      });
    });
  };

  //Get the array of fields names, according to their index in the list
  private fieldsName = (index: number): string[] => {
    return [
      `fiscalInformation[${index}].rfc`,
      `fiscalInformation[${index}].businessName`,
      `fiscalInformation[${index}].formaPago`,
      `fiscalInformation[${index}].usoCfdi`,
      `fiscalInformation[${index}].fiscalRegime`,
      `fiscalInformation[${index}].automatic`,
      `fiscalInformation[${index}].zipCode`,
      `fiscalInformation[${index}].street`,
      `fiscalInformation[${index}].externalNumber`,
      `fiscalInformation[${index}].colony`,
      `fiscalInformation[${index}].state`,
      `fiscalInformation[${index}].city`,
    ];
  };

  //Function to update the Collapse titles when specific inputs loses focus
  private updateCollapseData = (
    fiscalInformationList: FiscalInformation[],
    valueToUpdate: string | number | boolean,
    indextoUpdate: number,
  ): void => {
    let newBusinessNames: FiscalInformation[] = fiscalInformationList;

    newBusinessNames[indextoUpdate] = this.props.getFieldsValue(
      this.fieldsName(indextoUpdate),
    ).fiscalInformation[indextoUpdate];
    this.setState({ businessNames: [...newBusinessNames] });
  };

  public activeKeysSubItems = (): CollapseProps => {
    return {
      activeKey: ['0', '1', '2'],
    };
  };

  private onChangeState = async (value: string): Promise<void> => {
    const selectedState = this.state.states.find(
      (g4sState: G4sState): boolean => value === g4sState.name,
    );
    if (selectedState !== undefined) {
      const municipalities = await g4sServices.getG4sMunicipalities(
        selectedState.id,
      );
      this.setState({ municipalities: municipalities.data });
    }
  };

  private renderFormInput = (
    fiscalInformationList: FiscalInformation[],
    value: string | number,
    name: string,
    index: number,
    label: string,
    rule?: ValidationRule[],
    maxLength?: number,
  ): JSX.Element => {
    return (
      <Form.Item label={label}>
        {this.props.getFieldDecorator(name, {
          initialValue: value ? value : '',
          rules: rule,
        })(
          <Input
            maxLength={maxLength ? maxLength : 255}
            size="large"
            placeholder={label}
            onBlur={(
              event: React.SyntheticEvent<HTMLInputElement, Event>,
            ): void =>
              this.updateCollapseData(
                fiscalInformationList,
                event.currentTarget.value,
                index,
              )
            }
            readOnly={this.props.isReadOnly}
          />,
        )}
      </Form.Item>
    );
  };

  public render = (): React.ReactNode => {
    const { getFieldDecorator, sizeScreen, errors, customerModel } = this.props;
    let { businessNames, isVisibleCollapse } = this.state;

    //get the fiscal information list from the props and initialize with empty if it is null
    let propsFiscalInformation =
      customerModel.customer.fiscalInformationList || [];

    let busiNamesList: FiscalInformation[] = [];
    //define the values to use for the list
    if (businessNames.length > 0) {
      busiNamesList = businessNames;
    } else if (propsFiscalInformation.length > 0) {
      busiNamesList = propsFiscalInformation;
    }

    let visiblePopover: boolean[] =
      busiNamesList.length > 1
        ? busiNamesList.map((): false => false)
        : this.state.visiblePopover;
    //get all items with rfc different from customer and save them into a temporal array
    let tempFiscalInformation = busiNamesList;
    if (customerModel.customer.id !== '') {
      tempFiscalInformation = busiNamesList.filter(
        (item: FiscalInformation): boolean =>
          item.rfc !== customerModel.customer.rfc,
      );
    }

    return (
      <Row>
        {!this.props.isReadOnly && (
          <Button
            type="primary"
            style={{ margin: '5px 20px' }}
            onClick={(): void => {
              //when clic on button, set the data and change the collapse visibility
              if (busiNamesList.length > 0 && isVisibleCollapse) {
                this.setState({
                  isVisibleCollapse: true,
                  businessNames: [],
                });
              } else {
                this.setState({
                  isVisibleCollapse: false,
                  businessNames:
                    tempFiscalInformation.length > 0
                      ? propsFiscalInformation
                      : [initFiscalInformation],
                });
              }
            }}
          >
            {busiNamesList.length > 0 && isVisibleCollapse
              ? 'Quitar todas'
              : 'Agregar más'}
          </Button>
        )}
        {(tempFiscalInformation.length > 0 || isVisibleCollapse) && (
          <Collapse
            accordion={!(errors.length > 0)}
            {...(errors.length > 0 ? this.activeKeys() : undefined)}
          >
            {//show a Collapse.Panel for every item obtained from busiNamesList.filter
            tempFiscalInformation.map(
              (value: FiscalInformation, index: number): React.ReactNode => (
                <Collapse.Panel
                  style={{
                    paddingBottom: '8px',
                    backgroundColor: errors[index] ? '#fff4f4' : '',
                    borderColor: errors[index] ? '#fdb0b2' : '',
                  }}
                  key={index}
                  header={
                    value.businessName !== ''
                      ? `${value.businessName} ${
                          errors[index] ? ' Faltan propiedades por definir' : ''
                        } `
                      : 'Razón social sin agregar'
                  }
                  extra={
                    <span>
                      {//Show the button to add a new Fiscal Information in the last row
                      index === tempFiscalInformation.length - 1 &&
                        !this.props.isReadOnly && (
                          <Button
                            type="primary"
                            onClick={(event): void => {
                              event.stopPropagation();
                              visiblePopover.push(false);
                              this.setState({
                                visiblePopover: visiblePopover,
                              });
                              this.addBusinessName(busiNamesList);
                            }}
                          >
                            &#x271a;
                          </Button>
                        )}
                      {/*show button to delete row when the fiscal information array length > 0 */
                      tempFiscalInformation.length > 0 &&
                        !this.props.isReadOnly && (
                          <Popover
                            key={index}
                            content={
                              <div key={`fiscalInformation[${index}].rfc`}>
                                <div style={{ marginBottom: '10px' }}>
                                  Se eliminará toda la información
                                  <br />
                                  de esta razón social
                                </div>
                                <Button
                                  key={index}
                                  className={'popover-buttons'}
                                  onClick={(event): void => {
                                    event.stopPropagation();
                                    visiblePopover = visiblePopover.filter(
                                      (f: boolean): boolean => f !== true,
                                    );
                                    this.setState({
                                      visiblePopover: visiblePopover,
                                    });
                                  }}
                                >
                                  Cancelar
                                </Button>
                                <Button
                                  key={index}
                                  type={'primary'}
                                  className={'popover-buttons'}
                                  onClick={(event): void => {
                                    event.stopPropagation();
                                    visiblePopover = visiblePopover.filter(
                                      (f: boolean): boolean => f !== true,
                                    );
                                    this.setState({
                                      visiblePopover: visiblePopover,
                                    });
                                    this.deleteBusinessName(index);
                                  }}
                                >
                                  Aceptar
                                </Button>
                              </div>
                            }
                            title={`Eliminar ${value.businessName}`}
                            trigger="click"
                            visible={this.state.visiblePopover[index]}
                            onVisibleChange={(): void => {
                              visiblePopover = visiblePopover.map(
                                (f: boolean, indexF: number): boolean => {
                                  return indexF === index;
                                },
                              );
                              this.setState({
                                visiblePopover: visiblePopover,
                              });
                            }}
                          >
                            <Button
                              className={'delete-customer-address-button'}
                              type="link"
                            >
                              <img key={index} src={dump} alt="Delete icon" />
                            </Button>
                          </Popover>
                        )}
                    </span>
                  }
                >
                  <Row key={index}>
                    <Collapse
                      accordion={!(errors.length > 0)}
                      {...(errors.length > 0 ? this.activeKeys() : undefined)}
                    >
                      <Collapse.Panel key={0} header="General">
                        <Col {...sizeScreen(11)}>
                          {this.renderFormInput(
                            tempFiscalInformation,
                            value ? value.rfc : '',
                            `fiscalInformation[${index}].rfc`,
                            index,
                            LabelsCustomer.RFC,
                            r.rfcRule,
                          )}
                        </Col>
                        <Col {...sizeScreen(11)}>
                          {this.renderFormInput(
                            tempFiscalInformation,
                            value ? value.businessName : '',
                            `fiscalInformation[${index}].businessName`,
                            index,
                            LabelsCustomer.BUSINESS_NAME,
                            r.nameRule,
                          )}
                        </Col>
                        <Col {...sizeScreen(11)}>
                          <Form.Item label={LabelsCustomer.PAYMENY_FORM}>
                            {getFieldDecorator(
                              `fiscalInformation[${index}].formaPago`,
                              {
                                initialValue: value ? value.formaPago : '',
                                rules: r.formaPagoRule,
                              },
                            )(
                              <Select
                                showSearch
                                size="large"
                                onChange={(value: string): void => {
                                  this.updateCollapseData(
                                    busiNamesList,
                                    value,
                                    index,
                                  );
                                }}
                                disabled={this.props.isReadOnly}
                              >
                                {formaPago.map(
                                  (item, index): React.ReactNode => (
                                    <Select.Option
                                      key={item.key}
                                      value={item.key}
                                    >
                                      <div id={'formaPago_' + index.toString()}>
                                        {item.name}
                                      </div>
                                    </Select.Option>
                                  ),
                                )}
                              </Select>,
                            )}
                          </Form.Item>
                        </Col>
                        <Col {...sizeScreen(11)}>
                          <Form.Item label={LabelsCustomer.USE_CFDI}>
                            {getFieldDecorator(
                              `fiscalInformation[${index}].usoCfdi`,
                              {
                                initialValue: value.usoCfdi
                                  ? value.usoCfdi
                                  : 'G03',
                                rules: r.usoCfdiRule,
                              },
                            )(
                              <Select
                                showSearch
                                size="large"
                                onChange={(value: string): void => {
                                  this.updateCollapseData(
                                    busiNamesList,
                                    value,
                                    index,
                                  );
                                }}
                                disabled={this.props.isReadOnly}
                              >
                                {usoCFDI.map(
                                  (item, index): React.ReactNode => (
                                    <Select.Option
                                      key={item.key}
                                      value={item.key}
                                    >
                                      <div id={'usoCFDI_' + index.toString()}>
                                        {item.name}
                                      </div>
                                    </Select.Option>
                                  ),
                                )}
                              </Select>,
                            )}
                          </Form.Item>
                        </Col>
                        <Col {...sizeScreen(11)}>
                          <Form.Item label={LabelsCustomer.FISCAL_REG}>
                            {getFieldDecorator(
                              `fiscalInformation[${index}].fiscalRegime`,
                              {
                                initialValue: value.fiscalRegime
                                  ? value.fiscalRegime
                                  : 'G03',
                                rules: r.fiscalRegimeRule,
                              },
                            )(
                              <Select
                                showSearch
                                size="large"
                                onChange={(value: string): void => {
                                  this.updateCollapseData(
                                    busiNamesList,
                                    value,
                                    index,
                                  );
                                }}
                                disabled={this.props.isReadOnly}
                              >
                                {regimenFiscal.map(
                                  (item, index): React.ReactNode => (
                                    <Select.Option
                                      key={item.key}
                                      value={item.key}
                                    >
                                      <div
                                        id={'fiscalRegime_' + index.toString()}
                                      >
                                        {item.name}
                                      </div>
                                    </Select.Option>
                                  ),
                                )}
                              </Select>,
                            )}
                          </Form.Item>
                        </Col>
                        <Col {...sizeScreen(11)}>
                          {this.renderFormInput(
                            tempFiscalInformation,
                            value ? value.customerCodeComPro : '',
                            `fiscalInformation[${index}].customerCodeComPro`,
                            index,
                            LabelsCustomer.COMERTIAL_CODE,
                            r.comertialRule,
                          )}
                        </Col>
                      </Collapse.Panel>
                      <Collapse.Panel key={1} header="Domicilio">
                        <Col {...sizeScreen(5)}>
                          {this.renderFormInput(
                            tempFiscalInformation,
                            value ? value.street : '',
                            `fiscalInformation[${index}].street`,
                            index,
                            LabelsCustomer.STREET,
                            r.streetRule,
                          )}
                        </Col>
                        <Col {...sizeScreen(7)}>
                          {this.renderFormInput(
                            tempFiscalInformation,
                            value ? value.externalNumber : '',
                            `fiscalInformation[${index}].externalNumber`,
                            index,
                            LabelsCustomer.EXTERNAL_NUMBER,
                            r.externalNumberRule,
                          )}
                        </Col>
                        <Col {...sizeScreen(5)}>
                          {this.renderFormInput(
                            tempFiscalInformation,
                            value ? value.colony : '',
                            `fiscalInformation[${index}].colony`,
                            index,
                            LabelsCustomer.COLONY,
                            r.colonyRule,
                          )}
                        </Col>
                        <Col {...sizeScreen(5)}>
                          {this.renderFormInput(
                            tempFiscalInformation,
                            value ? value.city : '',
                            `fiscalInformation[${index}].city`,
                            index,
                            LabelsCustomer.CITY,
                            r.comertialRule,
                          )}
                        </Col>
                        <Col {...sizeScreen(5)}>
                          {getFieldDecorator(
                            `fiscalInformation[${index}].state`,
                            {
                              initialValue: value.state ? value.state : '',
                              rules: r.stateRule,
                            },
                          )}
                          <GenericSelect
                            options={this.state.states}
                            valueVarName={'name'}
                            labelVarName={'name'}
                            action={this.onChangeState}
                            isForm={true}
                            name={`fiscalInformation[${index}].state`}
                            label={LabelsCustomer.STATE}
                            rule={r.stateRule}
                            getFieldDecorator={getFieldDecorator}
                            defaultValues={value.state ? value.state : ''}
                            isDisabled={this.props.isReadOnly}
                          />
                        </Col>
                        <Col {...sizeScreen(7)}>
                          {this.renderFormInput(
                            tempFiscalInformation,
                            value ? value.zipCode : '',
                            `fiscalInformation[${index}].zipCode`,
                            index,
                            LabelsCustomer.ZIP_CODE,
                            r.zipCodeRule,
                          )}
                        </Col>
                      </Collapse.Panel>
                    </Collapse>
                  </Row>
                </Collapse.Panel>
              ),
            )}
          </Collapse>
        )}
      </Row>
    );
  };
}
export default FiscalInformationManager;
