import axios, { AxiosResponse } from 'axios';
import Service from '../Service';
import {
  createCoreServiceUrl,
  createParamsService,
} from '../../commons/services';
import {
  Statement,
  Pageable,
  Acount,
  StatementFilters,
  DataToCustomInvoice,
  ModelSaleMove,
  CreNoteInvoice,
  ModelSaleMoveAll,
} from '../../types/type';
import moment from 'moment';

class StatementService extends Service {
  public statementList = (
    customerCode: string,
    filters: Record<string, any>, // eslint-disable-line
  ): Promise<Pageable> => {
    let baseEndPoint = `customers/${customerCode}/statement?currentPage=${filters.currentPage}&pageSize=${filters.pageSize}
    &invoiceFolio=${filters.invoiceFolio}&invoiceSerie=${filters.invoiceSerie}&initDate=${filters.initDate}&endDate=${filters.endDate}`;

    if (filters.movementType !== undefined)
      baseEndPoint = baseEndPoint + `&isSaleOrPayment=${filters.movementType}`;

    if (filters.paymentType !== undefined)
      baseEndPoint =
        baseEndPoint + `&isCreditNoteOrPayment=${filters.paymentType}`;

    if (filters.statusType !== undefined)
      baseEndPoint = baseEndPoint + `&statusPayment=${filters.statusType}`;

    if (filters.receptionUnit !== undefined)
      baseEndPoint = baseEndPoint + `&receptionUnit=${filters.receptionUnit}`;

    return axios
      .get(createCoreServiceUrl(baseEndPoint))
      .then((response: AxiosResponse<Pageable>): Pageable => response.data);
  };

  public statementLisSearch = (
    customerCode: string,
    reference: string,
  ): Promise<Statement[]> =>
    axios
      .get(
        createCoreServiceUrl(`statement/filter/${customerCode}/${reference}/`),
      )
      .then(
        (response: AxiosResponse<Statement[]>): Statement[] => response.data,
      );

  public statementStatus = (code: string): Promise<Acount> =>
    axios
      .get(createCoreServiceUrl(`statement/status/${code}`))
      .then((response: AxiosResponse<Acount>): Acount => response.data);

  public downloadFile = (
    id: string,
    name: string,
    saleFolio: string,
  ): Promise<void> =>
    axios({
      url: createCoreServiceUrl(`sale/files/${id}/${name}`),
      method: 'GET',
    }).then((response: AxiosResponse<string>): void => {
      console.log(saleFolio);
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.href = response.data;
      link.click();
    });

  public savePayment = (payment: FormData): Promise<Statement> => {
    return axios({
      method: 'post',
      url: createCoreServiceUrl(`statement`),
      data: payment,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }).then((response: AxiosResponse<Statement>): Statement => response.data);
  };
  public statmpSale = async (
    saleId: string,
    customData: DataToCustomInvoice,
  ): Promise<void> =>
    await axios
      .put(createCoreServiceUrl(`stampById/${saleId}/`), customData)
      .then((response: AxiosResponse<void>): void => response.data);

  public generateReport = async (
    customerCode: string,
    filters: StatementFilters,
  ): Promise<void> => {
    let baseEndPoint = `statement/${customerCode}/excelReport?currentPage=${filters.currentPage}&pageSize=${filters.pageSize}
    &invoiceFolio=${filters.invoiceFolio}&invoiceSerie=${filters.invoiceSerie}&initDate=${filters.initDate}&endDate=${filters.endDate}`;

    if (filters.movementType !== undefined)
      baseEndPoint = baseEndPoint + `&isSaleOrPayment=${filters.movementType}`;

    if (filters.paymentType !== undefined)
      baseEndPoint =
        baseEndPoint + `&isCreditNoteOrPayment=${filters.paymentType}`;

    if (filters.statusType !== undefined)
      baseEndPoint = baseEndPoint + `&statusPayment=${filters.statusType}`;

    if (filters.receptionUnit !== undefined)
      baseEndPoint = baseEndPoint + `&receptionUnit=${filters.receptionUnit}`;

    const response = await axios({
      url: createCoreServiceUrl(baseEndPoint),
      method: 'GET',
      responseType: 'arraybuffer',
    });

    //Get the current values of date to set them in the file name
    const year = moment().year();
    const month = moment().month() + 1;
    const day = moment().date();

    const excelUrl = window.URL.createObjectURL(new Blob([response.data]));
    const excelLink = document.createElement('a');
    excelLink.href = excelUrl;
    excelLink.setAttribute(
      'download',
      `Edo_de_cuenta_${year}_${month}_${day}.xlsx`,
    );
    document.body.appendChild(excelLink);
    excelLink.click();
  };

  public splitStatementById = (statementId: string): Promise<Statement[]> => {
    return axios({
      method: 'put',
      url: createCoreServiceUrl(`statement/${statementId}/splitStatement`),
    }).then(
      (response: AxiosResponse<Statement[]>): Statement[] => response.data,
    );
  };

  public saveSaleMove = (filters: ModelSaleMove): Promise<Statement> => {
    return axios({
      method: 'PUT',
      url: createCoreServiceUrl(`statement/move/${filters.customerId}`),
      data: filters,
    }).then((response: AxiosResponse<Statement>): Statement => response.data);
  };

  public saveSaleMoveAll = (data: ModelSaleMoveAll): Promise<Statement> => {
    return axios({
      method: 'PUT',
      url: createCoreServiceUrl(`statement/move/all/${data.oldCustomerCode}`),
      data,
    }).then((response: AxiosResponse<Statement>): Statement => response.data);
  };

  public mergeStatementList = (statementIds: string[]): Promise<Statement> => {
    return axios({
      method: 'put',
      url: createCoreServiceUrl(`statement/merge`),
      data: statementIds,
    }).then((response: AxiosResponse<Statement>): Statement => response.data);
  };

  public updateStatement = (statement: Statement): Promise<Statement> => {
    return axios({
      method: 'put',
      url: createCoreServiceUrl(`statement/edit`),
      data: statement,
    }).then((response: AxiosResponse<Statement>): Statement => response.data);
  };

  public statementCancelation = (id: string): Promise<Statement> => {
    return axios({
      method: 'put',
      url: createCoreServiceUrl(`statement/${id}/cancellation`),
    }).then((response: AxiosResponse<Statement>): Statement => response.data);
  };

  public forceCanceInvoice = (
    id: string,
    selectedInvoiceId: string,
  ): Promise<Statement> => {
    return axios({
      method: 'put',
      url: createCoreServiceUrl(
        `statement/${id}/${selectedInvoiceId}/forcecancelation`,
      ),
    }).then((response: AxiosResponse<Statement>): Statement => response.data);
  };

  public getCreditNotesOfStatement = (
    id: string,
  ): Promise<CreNoteInvoice[]> => {
    return axios({
      method: 'get',
      url: createCoreServiceUrl(`statement/${id}/creditNotes`),
    }).then(
      (response: AxiosResponse<CreNoteInvoice[]>): CreNoteInvoice[] =>
        response.data,
    );
  };

  public getZipFile = async (customerCode: string, filters: Record<string, any>// eslint-disable-line
  ): Promise<void> => {
    const response = await axios({
      method: 'get',
      url:
        createCoreServiceUrl(`statement/${customerCode}/zip`) +
        createParamsService(filters),
      responseType: 'arraybuffer',
    });

    const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
    const fileLink = document.createElement('a');
    fileLink.href = fileUrl;
    fileLink.setAttribute('download', `Archivos_${customerCode}.zip`);
    document.body.appendChild(fileLink);
    fileLink.click();
  };
}

export default StatementService;
