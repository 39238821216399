/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by David Canul <david@ssf.com.mx>, Jun 2019
 *
 *
 */

import { combineReducers, Reducer } from 'redux';
import {
  Customer,
  DistributionCenter,
  CustomerModel,
  AddressContactModel,
  CustomerTotals,
  CustomerFilters,
  User,
  ZipCode,
} from 'types/type';
import { RootState } from 'store/configureStore';
import {
  CustomerAction,
  BEGIN_FETCH,
  FETCH_COMPLETED,
  SET_CUSTOMER,
  SET,
  SET_DISTRIBUTION_CENTER,
  GET_CUSTOMER_ADDRESS,
  EDIT_CUSTOMER_ADDRESS,
  SET_CUSTOMER_DEBT,
  SET_CUSTOMER_FILTERS,
  SET_USERS,
  SET_ZIP_CODE,
  RESET_CUSTOMER_MODEL,
} from './types';
import {
  initCustomerModel,
  initAddressContactModel,
  initCustomerDebt,
  initCustomerFilters,
  initUser,
  initZipcode,
} from 'commons/initTypes';

export interface CustomerI {
  customer: Customer;
}

export interface CustomerList {
  content?: Customer[];
  number?: number;
  totalElements?: number;
}

export interface CustomerState {
  readonly customerList: CustomerList;
  readonly zipCode: ZipCode;
  readonly customerDebt: CustomerTotals;
  readonly isFetching: boolean;
  readonly customer: CustomerModel;
  readonly customerAddressModel: AddressContactModel;
  readonly distributionCenter: DistributionCenterI;
  readonly customerFilters: CustomerFilters;
  readonly users: User[];
}

export interface DistributionCenterI {
  distributionCenter: DistributionCenter[];
}

export interface ZipCodeI {
  zipCode: ZipCode;
}

const customerList: Reducer<CustomerList> = (
  state: CustomerList = { content: [] },
  action,
): CustomerList => {
  if (action.type === SET_CUSTOMER) {
    return { ...state, ...action.customerList };
  }
  return state;
};

const distributionCenter: Reducer<DistributionCenterI> = (
  state: DistributionCenterI = { distributionCenter: [] },
  action,
): DistributionCenterI => {
  if (action.type === SET_DISTRIBUTION_CENTER) {
    return { ...state, distributionCenter: action.distributionCenter };
  }
  return state;
};
const zipCode: Reducer<ZipCode> = (
  state: ZipCode = initZipcode,
  action,
): ZipCode => {
  if (action.type === SET_ZIP_CODE) {
    return { ...state, ...action.zipCode };
  }
  return state;
};

const isFetching: Reducer<boolean> = (
  state = false,
  action: CustomerAction,
): boolean => {
  switch (action.type) {
    case BEGIN_FETCH:
      return true;
    case FETCH_COMPLETED:
      return false;
    default:
      return state;
  }
};

const customer: Reducer<CustomerModel> = (
  state: CustomerModel = initCustomerModel,
  action,
): CustomerModel => {
  switch (action.type) {
    case SET:
      return { ...state, ...action.customer };
    case RESET_CUSTOMER_MODEL:
      return initCustomerModel;
    default:
      return state;
  }
};

const customerAddressModel: Reducer<AddressContactModel> = (
  state: AddressContactModel = initAddressContactModel,
  action,
): AddressContactModel => {
  if (action.type === GET_CUSTOMER_ADDRESS) {
    return { ...state, ...action.customerAddressModel };
  }
  return state;
};

const editCustomerAddress: Reducer<AddressContactModel> = (
  state: AddressContactModel = initAddressContactModel,
  action,
): AddressContactModel => {
  if (action.type === EDIT_CUSTOMER_ADDRESS) {
    return { ...state, ...action.customerAddress };
  }
  return state;
};

const customerDebt: Reducer<CustomerTotals> = (
  state: CustomerTotals = initCustomerDebt,
  action,
): CustomerTotals => {
  if (action.type === SET_CUSTOMER_DEBT) {
    return { ...state, ...action.customerDebt };
  }
  return state;
};

export const customerFilters: Reducer<CustomerFilters> = (
  state: CustomerFilters = { ...initCustomerFilters },
  action,
): CustomerFilters => {
  if (action.type === SET_CUSTOMER_FILTERS) {
    return { ...state, ...action.customerFilters };
  }
  return state;
};

export const users: Reducer<User[]> = (
  state: User[] = [initUser],
  action,
): User[] => {
  if (action.type === SET_USERS) {
    return { ...state, ...action.users };
  }
  return state;
};
export const getIsFetching = (store: RootState): boolean =>
  store.invoiceState.isFetching;

export default combineReducers<CustomerState>({
  customer,
  customerList: customerList,
  zipCode,
  distributionCenter,
  isFetching,
  customerAddressModel,
  editCustomerAddress,
  customerDebt,
  customerFilters,
  users,
});
