export const banks = [
  { name: 'Pago por taskit' },
  { name: 'Banorte' },
  { name: 'BBVA Bancomer' },
  { name: 'Banamex' },
  { name: 'BanBajio' },
  { name: 'Santander' },
  { name: 'HSBC' },
  { name: 'Scotiabank' },
  { name: 'BANREGIO' },
];
