import {
  Form,
  Col,
  InputNumber,
  Button,
  Row,
  Card,
  Timeline,
  Modal,
} from 'antd';
import React, { FormEvent } from 'react';
import { FormComponentProps } from 'antd/lib/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { iusRule, totalRule } from './saleRules';
import MaskedInput from 'react-text-mask';
import ticket from 'assets/ticket.png';

interface CounterProps extends FormComponentProps {
  handleOnSubmitIUS: (e: FormEvent, form: WrappedFormUtils) => void;
  handleNext: () => void;
  renderCancelButton: () => JSX.Element;
  isFetching: boolean;
  changeImg: (inputSelected: number) => void;
}

type Props = CounterProps & FormComponentProps;

class SaleForm extends React.Component<Props> {
  public state = { visible: false };

  private showModal = (): void => {
    this.setState({
      visible: true,
    });
  };

  private handleCancel = (): void => {
    this.setState({
      visible: false,
    });
  };

  private formImputItemRender = (
    label: string,
    name: string,
    mark: boolean,
    rule: any[],// eslint-disable-line
  ): JSX.Element => {
    const hexRegex = /[0-9a-fA-F]/;
    const iusFragment = [hexRegex, hexRegex, hexRegex, hexRegex];
    return (
      <Col xs={24} sm={6} md={6} lg={6} xl={6} xxl={6}>
        <Form.Item label={label} colon={mark}>
          {this.props.form.getFieldDecorator(name, { rules: rule })(
            <MaskedInput
              mask={[
                ...iusFragment,
                '-',
                ...iusFragment,
                '-',
                ...iusFragment,
                '-',
                ...iusFragment,
                '-',
                ...iusFragment,
              ]}
              placeholder="####-####-####-####-####"
              id="ius"
              showMask
              className="ant-input ant-input-large"
              onFocus={(): void => this.props.changeImg(1)}
              autoFocus
              minLength={24}
              required={true}
            />,
          )}
        </Form.Item>
      </Col>
    );
  };

  public render = (): JSX.Element => {
    const {
      form: { getFieldDecorator },
      handleOnSubmitIUS,
      isFetching,
      changeImg,
    } = this.props;

    return (
      <Row gutter={16}>
        <Col span={24}>
          <Card style={{ backgroundColor: '#f1f7ff' }}>
            <Timeline>
              <Timeline.Item color="red">
                <Col span={24}>
                  {
                    'Tienes 24 horas a partir de la fecha de tu ticket para generar la Factura '
                  }
                </Col>
              </Timeline.Item>
              <Timeline.Item color="red">
                <Col xs={0} sm={0} md={0} lg={24} xl={24} xxl={24}>
                  {'Identifica los datos de referencia de tu ticket '}
                </Col>
                <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
                  {'Identifica los datos de referencia de tu ticket '}
                  <Button
                    onClick={(): void => this.showModal()}
                    size="small"
                    id="modal_button"
                  >
                    Mostrar Ticket
                  </Button>
                  <Modal
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    onOk={this.handleCancel}
                    style={{ textAlign: 'center' }}
                  >
                    <img src={ticket} alt="Ticket" />
                  </Modal>
                </Col>
              </Timeline.Item>
              <Timeline.Item color="red">
                <Col span={24}>
                  {'Puedes facturar mas de un ticket a la vez '}
                </Col>
              </Timeline.Item>
            </Timeline>
          </Card>
        </Col>
        <Form onSubmit={(e): void => handleOnSubmitIUS(e, this.props.form)}>
          {this.formImputItemRender('IUS', 'ius', true, iusRule)}
          <Col xs={24} sm={6} md={6} lg={6} xl={6} xxl={6}>
            <Form.Item label="Monto $">
              {getFieldDecorator('total', {
                rules: totalRule,
              })(<InputNumber onFocus={(): void => changeImg(2)} />)}
            </Form.Item>
          </Col>
          <Col xs={24} sm={6} md={6} lg={6} xl={6} xxl={6}>
            <Form.Item label=" .">
              <Button
                className="button-invoice-serch-style"
                type="primary"
                htmlType="submit"
                id="add_button"
                block
              >
                {isFetching ? 'Loading...' : '  Añadir  '}
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </Row>
    );
  };
}
export default Form.create<CounterProps>()(SaleForm);
