/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Ricardo Sansores <ricardo@ssf.mx>, May 2019
 *
 * This class construct the menu of the application when a user logins.
 */

import React, { Component } from 'react';
import JwtDecode from 'jwt-decode';
import Dashboard from './dashboard';
import Main from './main';
import Customers from './customers';
import users from './users';
import { MenuItem, SubmenuItems } from '../types/type';
import company from './company';
import sales from './sales';
import Companyformroute from './company/components/Companyformroute';
import { enterpriceLogo as centralGas } from 'boot/config';
import { Divider, Avatar, Icon } from 'antd';
import LogOut from './login/logout';
import IncognitMode from './login/incognitMode';
import { Permissions as P } from 'commons/permissions';
import { Token, getToken } from 'security';
import statement from './statement';
import payment from './payment';
import settlements from './settlements';
import bankdeposits from './bankDeposit';
import Inventory from './inventory';
import Cylinders from './cylinders';
import Route from './route';

const token =
  getToken() != null ? JwtDecode<Token>(getToken() as string) : null;
class Empty extends Component {
  public render = (): JSX.Element => {
    return <div />;
  };
}
const getSubmenu = (): SubmenuItems[] =>
  [
    {
      key: 'closeSession',
      path: '/logout',
      component: LogOut,
      title: 'Cerrar sesión',
      visible: true,
    },
    {
      key: 'Ingognit',
      path: '/settlements',
      component: IncognitMode,
      title: 'Desarrollo',
      visible: true,
      permissions: [P.DEVELOP_MODE],
    },
  ].filter((config): boolean => config.visible);

export const Menu: MenuItem[] = [
  {
    key: 'x',
    path: '/',
    component: Empty,
    title: (
      <div>
        <img src={centralGas} className="logo" alt="Logo" />
      </div>
    ),
    visible: true,
    icon: 'home',
    secure: true,
  },
  {
    key: 'home',
    path: '/home',
    component: Main,
    title: 'Dashboard',
    visible: true,
    icon: 'home',
    secure: true,
    permissions: [P.CAN_VIEW_DASHBOARD],
  },
  {
    key: 'C',
    path: '/C',
    component: Dashboard,
    title: 'Auditoría',
    visible: true,
    icon: 'home',
    secure: true,
    permissions: [P.DEVELOP_MODE],
  },
  {
    key: 'users',
    path: '/users',
    component: users,
    title: 'Usuarios',
    visible: true,
    icon: 'user',
    secure: true,
    permissions: [P.CAN_VIEW_USERS],
  },
  {
    key: 'enterprice',
    path: '/enterprice',
    component: company,
    title: 'Empresa',
    visible: true,
    icon: 'bank',
    secure: true,
    permissions: [P.CAN_VIEW_COMPANIES],
  },
  {
    key: 'CompanyForm',
    path: '/enterprice/form/:id',
    component: Companyformroute,
    title: 'Empresa form',
    visible: false,
    icon: 'bank',
    secure: true,
    permissions: [P.CAN_VIEW_COMPANY_DETAILS],
  },
  {
    key: 'sales',
    path: '/sales',
    component: sales,
    title: 'Ventas',
    visible: true,
    icon: 'dollar',
    secure: true,
    permissions: [P.CAN_VIEW_SALES],
  },
  {
    key: 'salesCustomer',
    path: '/salesCustomer',
    component: sales,
    title: 'Mis Compras',
    visible: true,
    icon: 'dollar',
    secure: true,
    permissions: [P.CAN_VIEW_MY_SALES],
  },
  {
    key: 'inventory',
    path: '/inventory',
    component: Inventory,
    title: 'Inventario',
    visible: true,
    icon: 'smile',
    secure: true,
    permissions: [P.CAN_SEE_INVENTORY_MENU],
  },
  {
    key: 'customer',
    path: '/customer',
    component: Customers,
    title: 'Crédito y Cobranza',
    visible: true,
    icon: 'smile',
    secure: true,
    permissions: [P.CAN_CIEW_CUSTOMER_PORTFOLIO],
  },
  {
    key: 'statements',
    path: '/statements',
    component: statement,
    title: 'Mi cuenta',
    visible: true,
    icon: 'smile',
    secure: true,
    permissions: [P.CAN_VIEW_MY_STATEMENTS],
  },
  {
    key: 'paymentscustomer',
    path: '/paymentscustomer',
    component: payment,
    title: 'Mis Pagos',
    visible: true,
    icon: 'smile',
    secure: true,
    permissions: [P.CAN_VIEW_MY_PAYMENTS],
  },
  {
    key: 'payments',
    path: '/payments',
    component: payment,
    title: 'Pagos',
    visible: true,
    icon: 'smile',
    secure: true,
    permissions: [P.CAN_VIEW_PAYMENTS],
  },
  {
    key: 'settlements',
    path: '/settlements',
    component: settlements,
    title: 'Liquidaciones',
    visible: true,
    icon: 'smile',
    secure: true,
    permissions: [P.CAN_VIEW_SETTLEMENTS],
  },
  {
    key: 'cylinders',
    path: '/cylinders',
    component: Cylinders,
    title: 'Cilindros',
    visible: true,
    icon: 'file',
    secure: true,
    permissions: [P.CAN_SEE_CYLINDERS_MENU],
  },
  {
    key: 'bankdeposits',
    path: '/bankdeposits',
    component: bankdeposits,
    title: 'Depósitos',
    visible: true,
    icon: 'smile',
    secure: true,
    permissions: [P.CAN_VIEW_DEPOSIT_MENU],
  },
  {
    key: 'Route',
    path: '/route',
    component: Route,
    title: 'Rutas',
    visible: true,
    icon: 'smile',
    secure: true,
    permissions: [P.CAN_SEE_ROUTE_MENU],
  },
  {
    key: 'cuenta',
    path: '/cuenta',
    component: Empty,
    secure: true,
    title: (
      <div id={'user_id'}>
        <span onClick={ () => window.location.reload(...[true] as any as []) } > Actualizar </span>
        <Divider type="vertical" style={{ height: '2.8rem' }} />
        {token ? token.name : null}
        <Icon type="down-circle" style={{ marginRight: 0, marginLeft: 10 }} />
        <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
      </div>
    ),
    visible: true,
    icon: 'smile',
    style: {
      float: 'right',
    },
    subMenu: {
      subTitle: 'Usuario',
      subMenuItems: getSubmenu(),
    },
  },
];

export default Menu;
