import React, { Component, ReactNode } from 'react';
import JwtDecode from 'jwt-decode';
import {
  Row,
  Pagination,
  Col,
  Icon,
  DatePicker,
  Table,
  Divider,
  Card,
  Tag,
  Select,
  Badge,
  Tooltip,
  Popconfirm,
  List,
  Button,
} from 'antd';
import {
  Settlement,
  SettlementFilters,
  DistributionCenter,
  Sale,
  SettlementsTotals,
  ModelSaleToUpdate,
  Equipment,
  SalesAuditArray,
  Price,
  ContainerBuilder,
  CustomerAndUDR,
  ContainerPurchase,
} from 'types/type';
import { connect } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { SettlementList } from './redux/reducers';
import {
  saveSaleToUpdate,
  page,
  applySettlement,
  getDistributionCenter,
  getSettlementbyId,
  getSettlementbyIdfiltred,
  getSaleAudit,
  getCustomersByFilter,
  getEquipmentsByDistributionCenter,
  downloadTicket,
  restoreSettlement,
  setZeroAllSettlementAndSales,
  getPrices,
  setEndFetch,
  downloadReport,
  downloadPendingSettlements,
} from './redux/actions';
import { Moment } from 'moment';
import Text from 'antd/lib/typography/Text';
import { Permissions as P } from 'commons/permissions';
import {
  isAllowed,
  Token,
  getToken,
  getIncognitMode,
  removeIncognitMode,
} from 'security/index';
import { Redirect } from 'react-router';
import { toNumber } from 'commons/numbersFormat';
import CreateFormSettlement from './components/CreateFormSettlement';
import EditSalesForm from './components/editSalesForm';
import { Link } from 'react-router-dom';
import {
  initSettlement,
  initDistributionCenter,
  initSale,
} from 'commons/initTypes';
import { ColumnProps } from 'antd/lib/table';
import { Popups } from 'commons/components/popups/popups';
import locale from 'antd/es/date-picker/locale/es_ES';
import SaleAuditModal from './components/saleAuditModal';
import moment from 'moment-timezone';
import { isDateWrong } from 'routes/functions';
import { paymentConditionEs, paymentWayEs } from 'commons/components/PaymentEs';
import { notification } from 'antd';
import { parseApiError } from 'commons/error';
import Form, { FormComponentProps } from 'antd/lib/form';
import { DATE_FORMAT, getEndTime, getInitialTime } from 'commons/services';
import { ApiRoute, callApiGetFile } from 'commons/services/api';
import { DbOldDetails, DbOldValues } from 'database';
import { Globals } from 'commons/globals';

interface DispatchProps {
  page: (filters: SettlementFilters) => void;
  applySettlement: (
    operator: string,
    assistant: string,
    id: string,
    apply: string,
    comment: string,
    containerValues: ContainerBuilder[],
    purchases: ContainerPurchase[],
  ) => void;
  getDistributionCenter: () => void;
  getSettlementbyId: (id: string) => void;
  getSaleAudit: (id: string) => void;
  getSettlementbyIdfiltred: (id: string, all: boolean, status: string) => void;
  saveSaleToUpdate: (modelSaleToUpdate: ModelSaleToUpdate) => void;
  getCustomersByFilter: (filter: string) => void;
  getEquipmentsByDistributionCenter: (distributionCenterId: string) => void;
  downloadTicket: (id: string, name: string, saleFolio: string) => void;
  restoreSettlement: (id: string) => void;
  setZeroAllSettlementAndSales: (id: string) => void;
  getPrices: () => void;
  setEndFetch: () => void;
  downloadReport: (settlementFilters: SettlementFilters) => void;
  downloadPendingSettlements: (settlementFilters: SettlementFilters) => void;
}

interface StateProps {
  settlementPage: SettlementList;
  distributionCenters: DistributionCenter[];
  customersList: CustomerAndUDR[];
  usersList: string[];
  salesAuditList: SalesAuditArray[];
  equipmentsList: Equipment[];
  isFetching: boolean;
  settlementToApplyState: Settlement;
  settlementTotal: SettlementsTotals;
  settlementFilters: SettlementFilters;
  prices: Price[];
}

interface States {
  endOpen: boolean;
  showSettlement: boolean;
  showSale: boolean;
  settlementToApply: Settlement;
  distributionCent: [];
  expanded: [];
  customerEdit: string;
  saleToApply: Sale;
  idSettlementToApply: string;
  saleIdG4s: string;
  showModalSaleAudit: boolean;
  idNoG4s: string;
  status: string;
  isCommentVisible: boolean;
}

type Props = StateProps & DispatchProps & FormComponentProps;

const rowsAllowed = 10000;

class Settlements extends Component<Props> {
  public state: States = {
    endOpen: false,
    showSettlement: false,
    showSale: false,
    customerEdit: '',
    settlementToApply: initSettlement,
    distributionCent: [],
    expanded: [],
    saleToApply: initSale,
    idSettlementToApply: '',
    saleIdG4s: '',
    showModalSaleAudit: false,
    idNoG4s: '',
    status: '',
    isCommentVisible: false,
  };

  public UNSAFE_componentWillMount(): void {
    if (getIncognitMode() === '1') {
      const asyncAction = async (): Promise<void> => {
        setTimeout((): void => {
          removeIncognitMode();
          window.location.reload();
        }, 600000);
      };
      asyncAction();
    }
  }

  public componentDidMount(): void {
    if (this.valuecuts().length < 1) return;
    const tockenDistCenterId: string = JwtDecode<Token>(getToken() as string)
      .distributionCenterId;
    const userCanViewAllDistCenter: boolean = isAllowed([
      P.CAN_VIEW_ALL_DISTRIBUTION_CENTERS,
    ]);
    const startAsync = async (): Promise<void> => {
      try {
        await this.props.getDistributionCenter();

        const userDistCenter: DistributionCenter[] = this.props.distributionCenters.filter(
          (d: DistributionCenter): boolean => tockenDistCenterId === d.id,
        );

        await this.props.getEquipmentsByDistributionCenter(
          userCanViewAllDistCenter ? '' : userDistCenter[0].id,
        );

        await this.setState({
          distributionCent: this.props.distributionCenters,
        });

        const { settlementFilters } = this.props;
        await this.props.page(settlementFilters);
        await this.setState({
          distributionCent: this.props.distributionCenters,
        });

        this.props.getPrices();
      } catch {}
    };
    startAsync();
  }

  public handleChangeDistributionCenter = (
    distributionCenter1: string,
  ): void => {
    this.props.page({
      ...this.props.settlementFilters,
      pagePosition: 1,
      distributionCenter: distributionCenter1,
    });
  };
  private valuecuts = (): string[] => {
    let seeSettlements: string[] = [];

    if (isAllowed([P.CAN_POS_SETTLE])) {
      seeSettlements.push('Liquidado?');
    }
    if (isAllowed([P.CAN_MANAGE_SETTLEMENTS])) {
      seeSettlements.push('Nuevo');
    }
    if (isAllowed([P.CAN_VIEW_SETTLEMENT_APPLY])) {
      seeSettlements.push('Liquidado');
    }
    return seeSettlements;
  };

  public handleChangeType = (serviceType: string): void => {
    this.props.page({
      ...this.props.settlementFilters,
      pagePosition: 1,
      serviceType: serviceType,
    });
  };
  public validatePendingSettlements = (
    settlementToApply: Settlement,
  ): boolean => {
    if (settlementToApply.settlementsPending) {
      //is true when is the last cut pending from de equipments to the container patern
      return settlementToApply.settlementsPending <= 0 ? true : false;
    }
    return true;
  };
  private renderStartDatePicker = (): JSX.Element => {
    const { endDate } = this.props.settlementFilters;
    return (
      <Row>
        <label>{'De:\u00A0\u00A0\u00A0\u00A0\u00A0'}</label>
        <Divider type="vertical" />
        <DatePicker
          showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
          locale={locale}
          disabled={this.props.isFetching}
          disabledDate={(date: moment.Moment | undefined): boolean =>
            isDateWrong(date, moment(endDate))
          }
          format={DATE_FORMAT}
          placeholder={getInitialTime()
            .format('YYYY-MM-DD HH:mm:SS')
            .toString()}
          onOk={(startDate: moment.Moment): void =>
            this.onDatePickerChange(startDate, moment(endDate))
          }
          defaultValue={moment(getInitialTime())}
        />
      </Row>
    );
  };

  private renderEndDatePicker = (): JSX.Element => {
    const { initDate } = this.props.settlementFilters;
    return (
      <Row>
        <label>{'Hasta:'}</label>
        <Divider type="vertical" />
        <DatePicker
          showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }}
          locale={locale}
          disabled={this.props.isFetching}
          disabledDate={(date: Moment | undefined): boolean =>
            isDateWrong(moment(initDate), date)
          }
          format={DATE_FORMAT}
          placeholder={getEndTime()
            .format('YYYY-MM-DD HH:mm:SS')
            .toString()}
          onOk={(endDate: Moment): void =>
            this.onDatePickerChange(moment(initDate), endDate)
          }
          defaultValue={moment(getEndTime())}
        />
      </Row>
    );
  };

  /**metodo para paginar sin perder filtros*/
  public handlePaginationChange = (
    currentPage: number,
    pageSize?: number,
  ): void => {
    this.props.page({
      ...this.props.settlementFilters,
      pagePosition: currentPage,
      pageSize: pageSize || 25,
    });
  };
  /**metodo para paginar sin perder filtros*/
  public handlePaginationChangeNew = (currentPage: number): void => {
    this.props.page({
      ...this.props.settlementFilters,
      pagePosition: currentPage,
    });
  };

  /**metodo para buscar cambiando los parametros de las fechas */
  public onDatePickerChange = (
    startValueDate: Moment,
    endValueDate: Moment,
  ): void => {
    const startAsync = async (): Promise<void> => {
      try {
        await this.props.page({
          ...this.props.settlementFilters,
          pagePosition: 1,
          initDate: startValueDate.toISOString(),
          endDate: endValueDate.toISOString(),
        });
      } catch {}
    };
    startAsync();
  };

  /**metodo para ejecutar cuando se cambie el select de unidad */
  public onChangeEquipment = (equipment: string): void => {
    this.props.page({
      ...this.props.settlementFilters,
      pagePosition: 1,
      equipment: equipment,
    });
  };
  public showModal = (item: Settlement): void => {
    const startAsync = async (): Promise<void> => {
      try {
        let isLiquidable = await true;
        await this.props.getSettlementbyId(item.idNoG4s);
        if (this.props.settlementToApplyState.sales)
          await this.props.settlementToApplyState.sales.forEach(
            (record: Sale) => {
              if (
                (record.customerName === '' &&
                  record.paymentConditionType !== 'CASH' &&
                  record.paymentConditionType !== 'FAILED') ||
                (record.customerType === 'CASH' &&
                  record.paymentConditionType !== 'CASH' &&
                  record.paymentConditionType !== 'FAILED')
              ) {
                Popups.notifyError(
                  'No se puede Liquidar este Corte:\n Favor de revisar las Ventas',
                );
                isLiquidable = false;
              }
            },
          );
        await this.setState({
          showSettlement: isLiquidable,
          expanded: isLiquidable ? [] : this.state.expanded,
        });
      } catch {}
    };

    startAsync();
  };
  public showModalSale = (
    record: Sale,
    idSettlementToApply: string,
    customerEdit: string,
  ): void => {
    const startAsync = async (): Promise<void> => {
      try {
        await this.setState({
          showSale: true,
          saleToApply: record,
          idSettlementToApply,
          customerEdit,
        });
      } catch {}
    };

    startAsync();
  };
  public showModalSaleAudit = (record: Sale): void => {
    const startAsync = async (): Promise<void> => {
      try {
        await this.props.getSaleAudit(record.idG4s);
        const asyncAction = async (): Promise<void> => {
          setTimeout((): void => {
            this.setState({
              saleToApply: record,
              showModalSaleAudit: true,
              saleIdG4s: record.idG4s,
            });
          }, 500);
        };
        asyncAction();
      } catch {}
    };

    startAsync();
  };

  public handleRestoreSettlement = (record: Settlement): void => {
    const startAsync = async (): Promise<void> => {
      try {
        await this.props.restoreSettlement(record.idNoG4s);
        await this.props.page(this.props.settlementFilters);
      } catch {}
    };
    startAsync();
  };
  public handleSetZeroAllSettlementAndSales = (record: Settlement): void => {
    const startAsync = async (): Promise<void> => {
      try {
        await this.props.setZeroAllSettlementAndSales(record.idNoG4s);
        await this.props.page(this.props.settlementFilters);
      } catch {}
    };
    startAsync();
  };

  public showZeroData = (record: Settlement): ReactNode => {
    if (
      isAllowed([P.CAN_RESTORE_SETTLEMENT]) &&
      record.bankDepositStatus === null
    ) {
      return (
        <span>
          <Link
            key="3"
            to={{}}
            onClick={(): void =>
              this.handleSetZeroAllSettlementAndSales(record)
            }
          >
            Poner en cero
          </Link>
        </span>
      );
    }
    return <div />;
  };

  public showRestoreData = (record: Settlement): ReactNode => {
    if (isAllowed([P.CAN_RESTORE_SETTLEMENT])) {
      if (record.bankDepositStatus === null) {
        return (
          <span>
            <Link
              key="3"
              to={{}}
              onClick={(): void => this.handleRestoreSettlement(record)}
            >
              Deshacer
            </Link>
          </span>
        );
      }
      if (record.bankDepositStatus === 'A validar') {
        return (
          <span>
            <Popconfirm
              placement="topLeft"
              title={
                'Se rechazará un deposito pendiente estas seguro que deseas restaurarlo'
              }
              onConfirm={(): void => this.handleRestoreSettlement(record)}
              okText="Sí"
              cancelText="No"
            >
              <Link key="3" to={{}}>
                Deshacer
              </Link>
            </Popconfirm>
          </span>
        );
      }
    }
    return <div />;
  };

  public handleCreatelSale = (modelSaleToUpdate: ModelSaleToUpdate): void => {
    const startAsync = async (): Promise<void> => {
      try {
        await this.setState({ showSale: false });
        const paymentWay =
          modelSaleToUpdate.formaPago === 'CASH'
            ? modelSaleToUpdate.paymentWay
            : 'POR_DEFINIR';
        await this.props.saveSaleToUpdate({ ...modelSaleToUpdate, paymentWay });
        await Popups.notifySuccess('Venta modificada exitosamente');
        await this.props.page(this.props.settlementFilters);

        await this.props.getSettlementbyIdfiltred(
          this.state.idNoG4s,
          getIncognitMode() === '1',
          this.state.status,
        );
      } catch (error) {
        Popups.notifyError(error);
      }
    };

    startAsync();
  };
  public handleCancel = (): void => {
    if (!this.props.isFetching) {
      this.setState({ showSettlement: false, isCommentVisible: false });
    }
  };
  public handleCancelAudit = (): void => {
    if (!this.props.isFetching) {
      this.setState({ showModalSaleAudit: false });
    }
  };
  public handleCancelSale = (): void => {
    if (!this.props.isFetching) {
      this.setState({ showSale: false });
    }
  };
  public estadoLiquidadoChecked = (): JSX.Element => {
    return (
      <Tag color="#10AB16" style={{ color: '#FFFFFF', borderColor: '#FFFFFF' }}>
        {'Liquidado '}
        <Icon type="check" />
      </Tag>
    );
  };

  public addressListContent = (
    liquidado: string,
    id: string,
  ): ColumnProps<Sale>[] => [
    {
      title: 'Fecha y hora',
      dataIndex: 'endDateSave',
      key: 'endDateSave',
      render: (text: string, record: Sale): React.ReactNode => {
        return (
          <div>
            {record.disabled && getIncognitMode() === '1' ? (
              <Badge color={'cyan'} />
            ) : (
              <div />
            )}
            {moment(record.endDateSave).format(DATE_FORMAT)}
          </div>
        );
      },
    },
    {
      title: 'Ticket',
      dataIndex: isAllowed(['CAN_SEE_FOLIOS']) ? 'salesFolio' : 'IUSCode',
      key: isAllowed(['CAN_SEE_FOLIOS']) ? 'salesFolio' : 'IUSCode',
      render: (text: string): React.ReactNode => {
        return <div>{text}</div>;
      },
    },
    {
      title: 'Cliente',
      dataIndex: 'customerName',
      key: 'customerName',
    },

    {
      title: 'Código',
      dataIndex: 'customerCode',
      key: 'customerCode',
      render: (text: string, record: Sale): React.ReactNode => {
        return (record.customerName === '' &&
          record.paymentConditionType !== 'CASH' &&
          record.paymentConditionType !== 'FAILED') ||
          (record.customerType === 'CASH' &&
            record.paymentConditionType !== 'CASH' &&
            record.paymentConditionType !== 'FAILED') ? (
          <Text strong type="warning">
            {text}
          </Text>
        ) : (
          text
        );
      },
    },
    {
      title: 'UDR',
      dataIndex: 'reseptionUnit',
      key: 'reseptionUnit',
    },
    {
      title: 'RFC',
      dataIndex: 'customerRfc',
      key: 'customerRfc',
    },
    {
      title: 'Condición de pago',
      dataIndex: 'paymentConditionType',
      key: 'paymentConditionType',
      render: (text: string, record: Sale): React.ReactNode => {
        return (record.customerName === '' &&
          record.paymentConditionType !== 'CASH' &&
          record.paymentConditionType !== 'FAILED') ||
          (record.customerType === 'CASH' &&
            record.paymentConditionType !== 'CASH' &&
            record.paymentConditionType !== 'FAILED') ? (
          <Text strong type="warning">
            {paymentConditionEs(text)}
          </Text>
        ) : (
          paymentConditionEs(text)
        );
      },
    },
    {
      title: 'Forma de pago',
      dataIndex: 'paymentWay',
      key: 'paymentWay',
      render: (text: string): React.ReactNode => {
        return paymentWayEs(text);
      },
    },
    {
      title: 'Requiere factura',
      dataIndex: 'invoiceRequired',
      key: 'invoiceRequired',
      render: (text: string, record: Sale): React.ReactNode => {
        return <div>{record.invoiceRequired === true ? 'Si' : 'No'}</div>;
      },
    },
    {
      title: 'Cantidad (L)',
      dataIndex: 'totalVolume',
      key: 'totalVolume',
      render: (text: string, record: Sale): React.ReactNode => {
        return getIncognitMode() === '1'
          ? toNumber(record.totalVolume || 0) +
              ((record.totalVolumeC || 0) !== 0
                ? ' - ' + toNumber(record.totalVolumeC || 0) + ' Litros'
                : ' Litros')
          : (record.totalVolumeC || 0) !== 0
          ? toNumber(record.totalVolumeC || 0) + ' Litros'
          : toNumber(record.totalVolume || 0) + ' Litros';
      },
    },
    {
      title: 'Precio unitario',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      render: (text: string, record: Sale): React.ReactNode => {
        return toNumber(record.unitPrice || 0);
      },
    },
    {
      title: 'Monto',
      dataIndex: 'monto',
      key: 'monto',
      render: (text: string, record: Sale): React.ReactNode => {
        const amount =
          getIncognitMode() === '1'
            ? toNumber(record.total || 0) +
              ((record.totalAmountC || 0) !== 0
                ? ' - ' + toNumber(record.totalAmountC || 0)
                : '')
            : (record.totalAmountC || 0) !== 0
            ? toNumber(record.totalAmountC || 0)
            : toNumber(record.total || 0);
        return `$${amount}`;
      },
    },
    {
      title: 'Acciones',
      dataIndex: 'modificar',
      key: 'acciones',
      render: (text: string, record: Sale): React.ReactNode => {
        return (
          <div>
            {isAllowed([P.CAN_EDIT_SALES]) &&
            (((liquidado === 'Nuevo' &&
            record.invoice === null)) || ((liquidado === 'Liquidado' && record.paymentConditionType ==="CASH" &&
            record.invoiceFromSale !== null && record.invoiceFromSale.status ==="CANCELED")) || (( record.invoiceFromSale===null && record.paymentConditionType==="CASH"))) ? (
              <Link
                key="editar"
                to={{}}
                onClick={(): void =>
                  this.showModalSale(
                    record,
                    id,
                    record.customerId === '' || record.customerId === null
                      ? 'NA'
                      : record.customerId,
                  )
                }
              >
                <Icon type="edit" theme="twoTone" />
              </Link>
            ) : (
              <div />
            )}
            <Divider type={'vertical'} />
            <Link
              key="historial"
              to={{}}
              onClick={(): void => this.showModalSaleAudit(record)}
            >
              <Icon type="clock-circle" theme="twoTone" />
            </Link>
          </div>
        );
      },
    },
  ];
  public setSalestoApply = (
    isExpanded: boolean,
    settlement: Settlement,
  ): void => {
    if (isExpanded) {
      this.setState({
        expanded: [settlement.idNoG4s],
        idNoG4s: settlement.idNoG4s,
        status: settlement.status,
      });
      this.props.getSettlementbyIdfiltred(
        settlement.idNoG4s,
        getIncognitMode() === '1',
        settlement.status,
      );
    } else {
      this.setState({ expanded: [] });
    }
  };

  public handleCreate = (
    operator: string,
    assistant: string,
    id: string,
  ): void => {
    if (operator !== '' && assistant !== '') {
      //Async function to save data
      const startAsync = async (
        ids: string[],
        initialPercentage: number[],
        finalPercentage: number[],
        comment: string,
        purchases: ContainerPurchase[],
      ): Promise<void> => {
        //Add the elements into an array of percentages (initial and final)
        let containerValues: ContainerBuilder[] = [];
        initialPercentage.forEach((percentage: number, index: number): void => {
          containerValues.push({
            id: ids[index],
            percentageInitialVolume: percentage,
            percentageFinalVolume: finalPercentage[index],
          });
        });
        //Add the elements into an array of container purchases
        let containerPurchases: ContainerPurchase[] = [];
        containerPurchases = purchases.map(
          (purchase): ContainerPurchase => purchase,
        );

        try {
          await this.props.applySettlement(
            operator,
            assistant,
            id,
            '',
            comment,
            containerValues,
            containerPurchases,
          );

          await this.props.page(this.props.settlementFilters);
          this.setState({ showSettlement: false, isCommentVisible: false });
        } catch (err) {
          await this.props.setEndFetch();
          const parsed = parseApiError(err);
          if (parsed.description.indexOf('porcentaje') > 0) {
            this.setState({ isCommentVisible: true });
          }
          notification.error(parseApiError(err));
        }
      };
      //If it is not fetching data, try to save the register
      if (!this.props.isFetching) {
        this.props.form.validateFields(
          async (err, values): Promise<void> => {
            if (!err) {
              const {
                id,
                initialPercentage,
                finalPercentage,
                comment,
                purchases,
              } = values;
              //Call function and send parameters possibly undefined
              startAsync(
                id ? id : [],
                initialPercentage ? initialPercentage : [],
                finalPercentage ? finalPercentage : [],
                comment ? comment : '',
                purchases ? purchases : [],
              );
            }
          },
        );
      }
    } else {
      Popups.notifyError('Seleccione un Operador y un Asistente');
    }
  };

  public saleDataosurse = (
    record: Settlement | undefined,
  ): Sale[] | undefined => {
    return record
      ? record.id === this.props.settlementToApplyState.id
        ? this.props.settlementToApplyState.sales
        : []
      : [];
  };

  private renderDetails = (item: Settlement): JSX.Element => {
    let statusIcon;
    if (item.status === 'Nuevo') {
      //Set blue icon
      statusIcon = (
        <Tag
          color="#EDFAFF"
          style={{ color: '#248BEC', borderColor: '#248BEC' }}
        >
          {item.status}
        </Tag>
      );
    } else if (item.status === 'Liquidado?' && !isAllowed([P.CAN_POS_SETTLE])) {
      //Set light green icon
      statusIcon = this.estadoLiquidadoChecked();
    } else if (item.status === 'Liquidado') {
      statusIcon = this.estadoLiquidadoChecked();
    } else {
      //Set dark green icon
      statusIcon = (
        <Tag
          color="#F0FFE9"
          style={{ color: '#08A20E', borderColor: '#08A20E' }}
        >
          {'Liquidado'}
        </Tag>
      );
    }

    //Get distribution center name
    const distributionCenter: DistributionCenter =
      this.props.distributionCenters.find(
        (d: DistributionCenter): boolean => d.id === item.distributionCenterId,
      ) || initDistributionCenter;

    //Get the amount cash
    let amountCash;
    if (this.props.settlementFilters.all) {
      amountCash = toNumber(item.amountCash || 0);
    } else {
      amountCash = toNumber(
        item.status === 'Liquidado'
          ? item.postAmountCash
          : item.amountCash || 0,
      );
    }

    //Get total amount
    let totalAmount;
    if (this.props.settlementFilters.all) {
      totalAmount = toNumber(item.total || 0);
    } else {
      totalAmount = toNumber(
        item.status === 'Liquidado' ? item.postTotal : item.total || 0,
      );
    }

    return (
      <Row>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Estado: '}</Text>
          {statusIcon}
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Fecha: '}</Text>
          <Text>{moment(item.movementDate).format(DATE_FORMAT)}</Text>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Folio: '}</Text>
          <Text>{item.folio}</Text>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Centro distribución: '}</Text>
          <Text>{distributionCenter.name}</Text>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Tipo servicio: '}</Text>
          <Text>{item.serviceType}</Text>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Unidad: '}</Text>
          <Text>{item.unit}</Text>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Monto contado: '}</Text>
          <Text>{'$ ' + amountCash}</Text>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Monto crédito: '}</Text>
          <Text>{'$' + toNumber(item.amountCredit || 0)}</Text>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Volumen final: '}</Text>
          <Text>{toNumber(item.finalvolumetotalizer || 0)}</Text>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Monto total: '}</Text>
          <Text>{'$ ' + totalAmount}</Text>
        </Col>
      </Row>
    );
  };

  private renderActions = (item: Settlement): JSX.Element => {
    if (isAllowed([P.CAN_POS_SETTLE])) {
      return item.status === 'Nuevo' ? (
        <span>
          <Link key="1" to={{}} onClick={(): void => this.showModal(item)}>
            Liquidar
          </Link>
          <Divider type={'vertical'} />
          {isAllowed([P.CAN_RESTORE_SETTLEMENT]) &&
            item.bankDepositStatus === null && (
              <Link
                key="3"
                to={{}}
                onClick={(): void =>
                  this.handleSetZeroAllSettlementAndSales(item)
                }
              >
                Poner en cero
              </Link>
            )}
        </span>
      ) : item.status === 'Liquidado' ? (
        <span>
          <Icon
            type="check-circle"
            theme="twoTone"
            twoToneColor="#52c41a"
            style={{ fontSize: '16px' }}
          />
          {isAllowed([P.CAN_DOWNLOAD_CUTS_TICKET]) && (
            <span>
              <Divider type={'vertical'} />
              <Link
                key="2"
                to={{}}
                onClick={(): void =>
                  this.props.downloadTicket(item.idNoG4s, item.folio, item.unit)
                }
              >
                <Tooltip title="Imprimir PDF">
                  <Icon
                    type="file-pdf"
                    theme="twoTone"
                    style={{ fontSize: '26px' }}
                  />
                </Tooltip>
              </Link>
            </span>
          )}
          {/* this.showRestoreData(item) */}
        </span>
      ) : (
        <span>
          {/* this.validatePendingSettlements(item) && (
            <Link
              key="1"
              to={{
                pathname:
                  '/settlement/posSettlement/' +
                  item.idNoG4s +
                  '/' +
                  (
                    this.props.distributionCenters.find(
                      (d: DistributionCenter): boolean =>
                        d.id === item.distributionCenterId,
                    ) || initDistributionCenter
                  ).name,
              }}
            >
              {'Posliquidar'}
            </Link>
          ) */}
          <Divider type={'vertical'} />
          {/* this.showRestoreData(item) */}
        </span>
      );
    } else {
      return item.status === 'Nuevo' ? (
        <span>
          <Link key="2" to={{}} onClick={(): void => this.showModal(item)}>
            {'Liquidar '}
          </Link>
          <Divider type={'vertical'} />

          {isAllowed([P.CAN_RESTORE_SETTLEMENT]) &&
            item.bankDepositStatus === null && (
              <Link
                key="3"
                to={{}}
                onClick={(): void =>
                  this.handleSetZeroAllSettlementAndSales(item)
                }
              >
                {'Poner en cero'}
              </Link>
            )}
        </span>
      ) : (
        <span>
          <Icon
            type="check-circle"
            theme="twoTone"
            twoToneColor="#52c41a"
            style={{ fontSize: '16px' }}
          />
          {isAllowed([P.CAN_DOWNLOAD_CUTS_TICKET]) && (
            <span>
              <Divider type={'vertical'} />
              <Link
                key="4"
                to={{}}
                onClick={(): void =>
                  this.props.downloadTicket(item.idNoG4s, item.folio, item.unit)
                }
              >
                <Tooltip title="Imprimir PDF">
                  <Icon
                    type="file-pdf"
                    theme="twoTone"
                    style={{ fontSize: '26px' }}
                  />
                </Tooltip>
              </Link>
            </span>
          )}
          <Divider type={'vertical'} />
          {/* this.showRestoreData(item) */}
        </span>
      );
    }
  };

  public render = (): React.ReactNode => {
    if (this.valuecuts().length < 1) return <Redirect to="/login" />;
    const { settlementPage, settlementFilters } = this.props;
    return (
      <Row>
        <Row style={{ background: '#F1F7FF', padding: '15px' }}>
          {!isAllowed([P.CAN_VIEW_ALL_DISTRIBUTION_CENTERS]) && (
            <Col xs={23} sm={23} md={5} lg={5} xl={5} xxl={4} offset={1}>
              <Text strong className={'ticket-text-name'}>
                Centro de Distribución:
              </Text>

              <Text strong className={'ticket-text-name'}>
                <h3>
                  {
                    (
                      this.props.distributionCenters.find(
                        (d: DistributionCenter): boolean =>
                          d.id ===
                          JwtDecode<Token>(getToken() as string)
                            .distributionCenterId,
                      ) || initDistributionCenter
                    ).name
                  }
                </h3>
              </Text>
            </Col>
          )}
          <Col
            xs={23}
            sm={23}
            md={6}
            lg={5}
            xl={4}
            xxl={4}
            offset={1}
            style={{ top: '2px' }}
          >
            {this.renderStartDatePicker()}
            {this.renderEndDatePicker()}
          </Col>
          {isAllowed([P.CAN_VIEW_ALL_DISTRIBUTION_CENTERS]) && (
            <Col xs={23} sm={23} md={6} lg={5} xl={5} xxl={4} offset={1}>
              <Text strong className={'ticket-text-name'}>
                Centro de Distribución:
              </Text>
              <Select
                disabled={this.props.isFetching}
                defaultValue={settlementFilters.distributionCenter}
                placeholder="Todos"
                style={{ width: '100%' }}
                onChange={async (value: string): Promise<void> => {
                  await this.props.getEquipmentsByDistributionCenter(value);
                  await this.handleChangeDistributionCenter(value);
                }}
              >
                <Select.Option value="">Todos</Select.Option>
                {this.props.distributionCenters.map(
                  (
                    item: DistributionCenter,
                    index: number,
                  ): React.ReactNode => (
                    <Select.Option key={item.id} value={item.id}>
                      <div id={'dist_' + index.toString()}> {item.name}</div>
                    </Select.Option>
                  ),
                )}
              </Select>
            </Col>
          )}

          <Col xs={23} sm={11} md={5} lg={5} xl={5} xxl={4} offset={1}>
            <Text strong className={'ticket-text-name'}>
              Tipo de Servicio:
            </Text>
            <Select
              defaultValue={this.props.settlementFilters.serviceType}
              placeholder="Todos"
              disabled={this.props.isFetching}
              value={settlementFilters.serviceType}
              style={{ width: '100%' }}
              onChange={(value: string): void => this.handleChangeType(value)}
            >
              <Select.Option value="">Todos</Select.Option>
              <Select.Option value="AUTOTANQUE">AUTOTANQUE</Select.Option>
              <Select.Option value="CARBURACION">CARBURACON</Select.Option>
              <Select.Option value="CILINDRERA">CILINDRERA</Select.Option>
            </Select>
          </Col>

          <Col xs={23} sm={11} md={4} lg={4} xl={4} xxl={3} offset={1}>
            <Text strong className={'ticket-text-name'}>
              Unidad:
            </Text>
            <Select
              showSearch
              disabled={this.props.isFetching}
              defaultValue={settlementFilters.equipment}
              placeholder="Todos"
              value={settlementFilters.equipment}
              style={{ width: '100%' }}
              onChange={(value: string): void => this.onChangeEquipment(value)}
            >
              <Select.Option key="Todos" value="">
                Todos
              </Select.Option>
              {undefined !== this.props.equipmentsList &&
                this.props.equipmentsList.map(
                  (item: Equipment, index: number): React.ReactNode => (
                    <Select.Option key={index} value={item.idG4s}>
                      <div id={'equipment_' + item.number}> {item.tag}</div>
                    </Select.Option>
                  ),
                )}
            </Select>
          </Col>
          <Col
            xs={23}
            sm={23}
            md={8}
            lg={6}
            xl={6}
            xxl={4}
            offset={1}
            style={{ paddingTop: '18px' }}
          >
            <Tooltip
              //title={`Descargar como Excel. Máximo 10,000 registros`}
              title={`Descargar como Excel.`}
              placement="topRight"
            >
              <Button
                type="primary"
                shape="round"
                size={'default'}
                onClick={(): void => {
                  let { initDate, endDate, distributionCenter } = this.props.settlementFilters;
                  /* if(distributionCenter == GuayanaLoreto || distributionCenter == Bajio){
                    this.props.downloadReport(this.props.settlementFilters);
                    return;
                  } */
                  let dateStart = Globals.Utils.Date.dateToString({ date: new Date(initDate) });
                  let dateEnd = Globals.Utils.Date.dateToString({ date: new Date(endDate) });
                  console.log(dateStart, dateEnd, this.props.settlementFilters);
                  let distCenterId = distributionCenter as DbOldValues._DistCenterId || undefined;
                  let dateFileName = dateStart !== dateEnd ? `${dateStart}_${dateEnd}`: `${dateStart}`;
                  callApiGetFile(ApiRoute.GetDasCutCsv, { mode: 'FULL', dateRange: [ dateStart, dateEnd ], distCenterId }).then(result => {
                    let fileName = `Cortes_ ${dateFileName}${distCenterId ? `_${DbOldDetails.distCenterId[distCenterId].name}` : ''}.csv`;
                    if(result) Globals.Utils.File.downloadBlob(result, fileName);
                    else alert(`Error obteniendo archivo.`);
                  });
                  //this.props.downloadReport(this.props.settlementFilters);
                }}
                disabled={
                  this.props.isFetching /* ||
                  (settlementPage.totalElements !== undefined &&
                    settlementPage.totalElements > rowsAllowed) ||
                  (settlementPage.totalElements !== undefined &&
                    settlementPage.totalElements <= 0) */
                }
              >
                <Icon type={'download'} />
                {' Excel'}
              </Button>
            </Tooltip>
            {isAllowed([P.CAN_DOWNLOAD_PENDING_SETTLEMENTS_EXCEL]) && (
              <Tooltip
                title={`Descargar reporte de 
              liquidaciones pendientes`}
                placement="topRight"
              >
                <Button
                  type="primary"
                  shape="round"
                  size={'default'}
                  onClick={(): void => {
                    this.props.downloadPendingSettlements(
                      this.props.settlementFilters,
                    );
                  }}
                  disabled={
                    this.props.isFetching ||
                    (settlementPage.totalElements !== undefined &&
                      settlementPage.totalElements > rowsAllowed) ||
                    (settlementPage.totalElements !== undefined &&
                      settlementPage.totalElements <= 0)
                  }
                >
                  <Icon type={'download'} />
                  {' Pendientes'}
                </Button>
              </Tooltip>
            )}
          </Col>
        </Row>
        <Row>
          <Col
            xs={{ span: 23, offset: 1 }}
            sm={{ span: 23, offset: 1 }}
            md={{ span: 11, offset: 1 }}
            lg={{ span: 11, offset: 1 }}
            xl={{ span: 8, offset: 0 }}
            xxl={{ span: 8, offset: 0 }}
          >
            <Card className="card-statement">
              <Text>{'Total contado'}</Text>
              <Text>
                <h3>
                  {'$ ' + toNumber(this.props.settlementTotal.amountCash)}
                </h3>
              </Text>
            </Card>
          </Col>

          <Col
            xs={{ span: 23, offset: 1 }}
            sm={{ span: 23, offset: 1 }}
            md={{ span: 11, offset: 1 }}
            lg={{ span: 11, offset: 1 }}
            xl={{ span: 8, offset: 0 }}
            xxl={{ span: 8, offset: 0 }}
          >
            <Card className="card-statement">
              <Text>{'Total crédito'}</Text>
              <Text>
                <h3>
                  {'$ ' + toNumber(this.props.settlementTotal.amountCredit)}
                </h3>
              </Text>
            </Card>
          </Col>

          <Col
            xs={{ span: 23, offset: 1 }}
            sm={{ span: 23, offset: 1 }}
            md={{ span: 11, offset: 1 }}
            lg={{ span: 11, offset: 1 }}
            xl={{ span: 8, offset: 0 }}
            xxl={{ span: 8, offset: 0 }}
          >
            <Card className="card-statement">
              <Text>{'Gran total'}</Text>
              <Text>
                <h3>{'$ ' + toNumber(this.props.settlementTotal.total)}</h3>
              </Text>
            </Card>
          </Col>
          <Col
            xs={{ span: 23, offset: 1 }}
            sm={{ span: 23, offset: 1 }}
            md={{ span: 11, offset: 1 }}
            lg={{ span: 11, offset: 1 }}
            xl={{ span: 8, offset: 0 }}
            xxl={{ span: 8, offset: 0 }}
          >
            {getIncognitMode() === '1' && (
              <Card className="card-statement">
                <Text>{'Total Ganado'}</Text>
                <Text>
                  <h3>
                    {'$ ' + toNumber(this.props.settlementTotal.postAmountCash)}
                  </h3>
                </Text>
              </Card>
            )}
          </Col>
          <p />
        </Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={0} xxl={0}>
          <List
            dataSource={this.props.settlementPage.content}
            renderItem={(item: Settlement, index: number): JSX.Element => (
              <List.Item>
                <List.Item.Meta
                  key={index}
                  description={
                    <Row
                      style={{
                        background: '#FFFFFF',
                        padding: '10px',
                        margin: '-7px',
                      }}
                    >
                      <Col span={20}>{this.renderDetails(item)}</Col>
                      <Col span={4}>{this.renderActions(item)}</Col>
                    </Row>
                  }
                />
              </List.Item>
            )}
          />
        </Col>
        <Col xs={0} sm={0} md={0} lg={0} xl={24} xxl={24}>
          <p />
          <Card bordered={false}>
            <Table
              onExpand={(expand: boolean, record: Settlement): void =>
                this.setSalestoApply(expand, record)
              }
              rowKey="idNoG4s"
              loading={this.props.isFetching}
              expandedRowKeys={this.state.expanded}
              dataSource={this.props.settlementPage.content}
              pagination={false}
              expandedRowRender={(record): JSX.Element => (
                <Table
                  className={'custom-table'}
                  dataSource={this.saleDataosurse(record)}
                  pagination={false}
                  columns={this.addressListContent(record.status, record.id)}
                />
              )}
            >
              <Table.Column
                title="Estatus"
                dataIndex="status"
                render={(text: string, record: Settlement): React.ReactNode => {
                  if (record.status === 'Nuevo')
                    return (
                      <Tag
                        color="#EDFAFF"
                        style={{ color: '#248BEC', borderColor: '#248BEC' }}
                      >
                        {record.status}
                      </Tag>
                    );
                  else if (
                    record.status === 'Liquidado?' &&
                    !isAllowed([P.CAN_POS_SETTLE])
                  )
                    return this.estadoLiquidadoChecked();
                  else if (record.status === 'Liquidado')
                    return this.estadoLiquidadoChecked();
                  else
                    return (
                      <Tag
                        color="#F0FFE9"
                        style={{ color: '#08A20E', borderColor: '#08A20E' }}
                      >
                        {'Liquidado'}
                      </Tag>
                    );
                }}
              />
              <Table.Column
                title="Fecha de corte"
                dataIndex="movementDate"
                render={(text: string, record: Settlement): React.ReactNode => {
                  return moment(record.movementDate).format(DATE_FORMAT);
                }}
              />
              <Table.Column title="Folio corte" dataIndex="folio" />

              <Table.Column
                title="Centro de distribución"
                dataIndex="distributionCenterId"
                render={(text: string): JSX.Element => {
                  const x: DistributionCenter =
                    this.props.distributionCenters.find(
                      (d: DistributionCenter): boolean => d.id === text,
                    ) || initDistributionCenter;
                  return <div>{x.name}</div>;
                }}
              />
              <Table.Column
                title="Tipo de Servicio"
                dataIndex="serviceType"
                render={(text: string, record: Settlement): React.ReactNode => {
                  return record.serviceType;
                }}
              />
              <Table.Column
                title="Unidad"
                dataIndex="unit"
                render={(text: string, record: Settlement): React.ReactNode => {
                  return record.unit;
                }}
              />
              <Table.Column
                title="Monto Contado"
                dataIndex="amountcash"
                render={(text: string, record: Settlement): React.ReactNode => {
                  if (this.props.settlementFilters.all) {
                    return '$' + toNumber(record.amountCash || 0);
                  } else {
                    return (
                      '$' +
                      toNumber(
                        record.status === 'Liquidado'
                          ? record.postAmountCash
                          : record.amountCash || 0,
                      )
                    );
                  }
                }}
              />
              <Table.Column
                title="Monto Credito"
                dataIndex="amountCredit"
                render={(text: string, record: Settlement): React.ReactNode => {
                  return '$' + toNumber(record.amountCredit || 0);
                }}
              />
              {isAllowed([P.CAN_VIEW_TOTALVOLUME]) && (
                <Table.Column
                  title="Volumen Final"
                  dataIndex="finalvolumetotalizer"
                  render={(
                    text: string,
                    record: Settlement,
                  ): React.ReactNode => {
                    return toNumber(record.finalvolumetotalizer || 0);
                  }}
                />
              )}
              <Table.Column
                title="Monto Total"
                dataIndex="total"
                render={(text: string, record: Settlement): React.ReactNode => {
                  if (this.props.settlementFilters.all) {
                    return '$' + toNumber(record.total || 0);
                  } else {
                    return (
                      '$' +
                      toNumber(
                        record.status === 'Liquidado'
                          ? record.postTotal
                          : record.total || 0,
                      )
                    );
                  }
                }}
              />
              <Table.Column
                title="Acciones"
                dataIndex="id"
                render={(text: string, record: Settlement): React.ReactNode => {
                  return this.renderActions(record);
                }}
              />
            </Table>
          </Card>
          {
            <div>
              <SaleAuditModal
                visible={this.state.showModalSaleAudit}
                saleToApply={this.state.saleToApply}
                onCancel={(): Function => this.handleCancelAudit}
                salesAuditList={this.props.salesAuditList}
              />
            </div>
          }
          ,
          {this.state.showSettlement && (
            <div>
              <CreateFormSettlement
                isFetching={this.props.isFetching}
                visible={this.state.showSettlement}
                onCancel={(): Function => this.handleCancel}
                onCreate={this.handleCreate}
                settlementToApply={this.props.settlementToApplyState}
                userList={this.props.usersList}
                distributionCenterName={this.state.distributionCent}
                totalCash={100}
                totalCretid={100}
                isCommentVisible={this.state.isCommentVisible}
                form={this.props.form}
              />
            </div>
          )}
          ,
          {this.state.showSale && (
            <div>
              <EditSalesForm
                customerEdit={this.state.customerEdit}
                isFetching={this.props.isFetching}
                visible={this.state.showSale}
                onCancel={(): Function => this.handleCancelSale}
                onCreate={this.props.saveSaleToUpdate}
                saleToApply={this.state.saleToApply}
                customersList={this.props.customersList}
                fetchCustomer={this.props.getCustomersByFilter}
                onSave={this.handleCreatelSale}
                pricesList={this.props.prices}
              />
            </div>
          )}
        </Col>
        <p />
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Pagination
            showSizeChanger
            disabled={this.props.isFetching}
            current={(this.props.settlementPage.number || 0) + 1}
            total={this.props.settlementPage.totalElements}
            onShowSizeChange={this.handlePaginationChange}
            onChange={this.handlePaginationChangeNew}
            defaultPageSize={25}
            pageSizeOptions={['25', '50', '75', '100']}
          />
        </Col>
      </Row>
    );
  };
}

const mapStateToProps = (states: RootState): StateProps => {
  return {
    distributionCenters:
      states.settlementState.distributionCenterList.distributionCenter,
    settlementPage: states.settlementState.settlementList,
    usersList: Object.values(states.settlementState.usersList),
    salesAuditList: Object.values(states.settlementState.salesAuditList),
    equipmentsList: Object.values(states.settlementState.equipmentList),
    isFetching: states.settlementState.isFetching,
    settlementToApplyState: states.settlementState.settlementToApply,
    settlementTotal: states.settlementState.settlementTotal,
    customersList: states.settlementState.customersList.customersList,
    settlementFilters: states.settlementState.settlementFiltes,
    prices: Object.values(states.settlementState.prices),
  };
};

const mapDispatchToProps: DispatchProps = {
  page,
  applySettlement,
  getDistributionCenter,
  getSettlementbyId,
  getSaleAudit,
  getSettlementbyIdfiltred,
  saveSaleToUpdate,
  getCustomersByFilter,
  getEquipmentsByDistributionCenter,
  downloadTicket,
  restoreSettlement,
  setZeroAllSettlementAndSales,
  getPrices,
  setEndFetch,
  downloadReport,
  downloadPendingSettlements,
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create()(Settlements));
