import { Company } from "types/type";

/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Hiram Padilla <hiram@ssf.com.mx>, July 2020
 *
 * This file contains the redux types for equipments component
 */
export const SET_EQUIPMENTS = 'SET_EQUIPMENTS';
export const SET_DISTRIBUTION_CENTERS = 'SET_DISTRIBUTION_CENTERS';
export const SET_GOVERNMENT_PERMISSIONS = 'SET_GOVERNMENT_PERMISSIONS';
export const SET_COMPANY_LIST = 'SET_COMPANY_LIST';
export const SET_EQUIPMENT_FILTERS = 'SET_EQUIPMENT_FILTERS';

/*
 * Type to begin a fetch operation action
 */
export const BEGIN_FETCH = 'BEGIN_FETCH';

/*
 * Type when a fetch operation action has been completed
 */
export const FETCH_COMPLETED = 'FETCH_COMPLETED';

interface BeginFetchActionAction {
  type: typeof BEGIN_FETCH;
}

interface EndFetchAction {
  type: typeof FETCH_COMPLETED;
}

interface SetEquipmentsAction {
  type: typeof SET_EQUIPMENTS;
  equipments: {};
}

interface SetDistributionCentersAction {
  type: typeof SET_DISTRIBUTION_CENTERS;
  distributionCenters: {};
}

interface SetGovernmentPermissionsAction {
  type: typeof SET_GOVERNMENT_PERMISSIONS;
  governmentPermissions: {};
}

interface SetEquipmentFilters {
  type: typeof SET_EQUIPMENT_FILTERS;
  equipmentFilters: {};
}
interface SetCompanyListAction {
  type: typeof SET_COMPANY_LIST;
  companyList: Company[];
}

export type EquipmentAction =
  | BeginFetchActionAction
  | SetCompanyListAction
  | EndFetchAction
  | SetEquipmentsAction
  | SetDistributionCentersAction
  | SetGovernmentPermissionsAction
  | SetEquipmentFilters;
