export const getUdrFieldsNames = (index: number): string[] => {
  return [
    `customerAddress[${index}].id`,
    `customerAddress[${index}].customer`,
    `customerAddress[${index}].code`,
    `customerAddress[${index}].description`,
    `customerAddress[${index}].price`,
    `customerAddress[${index}].capacity`,
    `customerAddress[${index}].generalPurpose1`,
    `customerAddress[${index}].generalPurpose2`,
    `customerAddress[${index}].country`,
    `customerAddress[${index}].commode`,
    `customerAddress[${index}].zipCode`,
    `customerAddress[${index}].internalNumber`,
    `customerAddress[${index}].externalNumber`,
    `customerAddress[${index}].product`,
    `customerAddress[${index}].street`,
    `customerAddress[${index}].suburb`,
    `customerAddress[${index}].city`,
    `customerAddress[${index}].municipality`,
    `customerAddress[${index}].state`,
  ];
};
