import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { Button, Col, DatePicker, Input, Modal, Row } from 'antd';

import { Globals } from 'commons/globals';
import { ApiRoute, ApiTypes, callApiWithFiles } from 'commons/services/api';

type AddExternalInvoiceModalProps = { statementId: string, onSave: () => void | Promise<void> };
const AddExternalInvoiceModal = (props: AddExternalInvoiceModalProps): JSX.Element => {

    const { FileInput } = Globals.Components;

    const [visible, setVisible] = useState(false);

    const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
    const [ govPerm, setGovPerm ] = useState('');

    const [rfc, setRfc] = useState('');
    const [stampDate, setStampDate] = useState<moment.Moment>(moment());
    const [serie, setSerie] = useState('');
    const [folio, setFolio] = useState('');
    const [timbreUuid, setTimbreUuid] = useState('');
    const [govPerms, setGovPerms] = useState<string[]>([]);
    const [xml, setXml] = useState<null | File>(null);
    const [pdf, setPdf] = useState<null | File>(null);

    let addInvoice = useCallback(async (data: ApiTypes.StatementService.ExternalInvoiceData) => {
        console.log(props.statementId, data, xml, pdf);
        for(let item of [data.serie, data.folio, data.rfc, data.timbreUuid]){
            if(!item) return alert(`Faltan campos.`);
        }
        if(!xml || !pdf){
            alert(`Faltan archivos.`);
            return;
        }
        if(data.govPerms.length < 1){
            if(!Globals.Utils.Prompt.confirm(`¿Añadir la factura sin permisos?`)) return;
        }
        let result = await callApiWithFiles(ApiRoute.AddExternalInvoice, { statementId: props.statementId, data }, [
            { id: 'xml', file: xml },
            { id: 'pdf', file: pdf },
        ]);
        console.log(result);
        if (!result || !result.success) {
            alert((result && result.msg) || `Error añadiendo factura.`);
            return;
        }
        alert(result.msg || 'Se añadió la factura.');
        setVisible(false);
        await props.onSave();
    }, [ props.statementId, xml, pdf, setVisible ]);

    let modalContent: JSX.Element = (
        <div style={{ height: '80vh', overflowY: 'auto' }} >
            <Row>
                <Col span={22} offset={1} >
                    <Row>
                        <Col span={ 6 } style={ { padding: '0px 5px' } } >
                            <label> { `Serie:` } </label>
                            <Input id="serie" value={ serie } onChange={event => setSerie(event.target.value)} style={{ width: '100%' }} />
                        </Col>
                        <Col span={ 6 } style={ { padding: '0px 5px' } } >
                            <label> { `Folio:` } </label>
                            <Input id="folio" value={ folio } onChange={event => setFolio(event.target.value)} style={{ width: '100%' }} />
                        </Col>
                        <Col span={ 12 } style={ { padding: '0px 5px' } } >
                            <label> { `Timbre Uuid:` } </label>
                            <Input id="timbreUuid" value={ timbreUuid } onChange={event => setTimbreUuid(event.target.value)} style={{ width: '100%' }} />
                        </Col>
                        <Col span={ 12 } style={ { padding: '0px 5px' } } >
                            <label> { `Rfc:` } </label>
                            <Input id="rfc" value={ rfc } onChange={event => setRfc(event.target.value)} style={{ width: '100%' }} />
                        </Col>
                        <Col span={ 12 } style={ { padding: '0px 5px' } } >
                            <label> {`Fecha timbrado:`} </label> <br />
                            <DatePicker
                                open={datePickerIsOpen}
                                value={ stampDate }
                                onChange={value => setStampDate(value || moment())}
                                onOpenChange={status => setDatePickerIsOpen(status)}
                            />
                        </Col>
                        <Col span={ 24 } style={ { padding: '0px 5px' } } >
                            <label> { `Permisos:` } </label>
                            <div>
                                <Input id="govPerm" value={ govPerm } onChange={event => setGovPerm(event.target.value)} style={{ width: '80%' }} />
                                <div style={ { width: '20%', textAlign: 'right', display: 'inline-block' } } >
                                    <Button type="link" onClick={ () => setGovPerms(pgp => [ ...pgp, govPerm ].filter(gp => !!gp)) } > Añadir </Button>
                                </div>
                            </div>
                            <ul>
                                { govPerms.map((govPerm, gpIndex) => (
                                    <li key={ `gp${gpIndex}` } >
                                        <Button type="danger" onClick={ () => setGovPerms(pgp => pgp.filter((_, index) => index !== gpIndex )) } > X </Button>
                                        <span style={ { marginLeft: 20 } } > { govPerm } </span>
                                    </li>
                                )) }
                            </ul>
                        </Col>
                        <Col span={ 12 } style={ { padding: '0px 5px' } } >
                            <FileInput id="xml" file={ xml } onChange={ file => setXml(file) } placeholder="-- Adjuntar xml --" />
                        </Col>
                        <Col span={ 12 } style={ { padding: '0px 5px' } } >
                            <FileInput id="pdf" file={ pdf } onChange={ file => setPdf(file) } placeholder="-- Adjuntar pdf --" />
                        </Col>
                    </Row>
                    <div style={{ textAlign: 'right' }} >
                        <Button type="primary" onClick={() => {
                            addInvoice({
                                rfc,
                                serie,
                                folio,
                                timbreUuid,
                                stampDate: stampDate.toDate(),
                                govPerms,
                            });
                        }} >
                            Guardar
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    );

    return (
        <span>
            <Button type="link" className='ant-button' onClick={() => setVisible(true)} >
                Factura externa
            </Button>
            <Modal
                width={'64vw'}
                //style={ { position: 'fixed', top: 0, left: 0 } }
                title="Añadir factura externa"
                centered
                visible={visible}
                footer={null}
                onCancel={() => setVisible(false)}
            >
                {modalContent}
            </Modal>
        </span>
    );
};
export default AddExternalInvoiceModal;