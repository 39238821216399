import Service from 'services/Service';
import axios from 'axios';
import { createCoreServiceUrl } from 'commons/services';
import { Marketstall } from 'types/type';

class MarketstallService extends Service {
  public getAll = async (): Promise<Marketstall[]> =>
    (await axios.get(createCoreServiceUrl(`marketstall`))).data;
}
export default MarketstallService;
