import { Sale, SalesAuditArray } from 'types/type';
import React, { Component } from 'react';
import { Modal, List } from 'antd';
import moment from 'moment';
import './../index.css';

interface CounterProps {
  visible: boolean;
  saleToApply: Sale;
  onCancel: Function;
  salesAuditList: SalesAuditArray[];
}

class SaleAuditModal extends Component<CounterProps> {
  public state = {};
  public render = (): JSX.Element => {
    const { visible, onCancel, saleToApply } = this.props;
    return (
      <Modal
        width={800}
        visible={visible}
        title={'Actividades de la venta: ' + saleToApply.salesFolio}
        okText=""
        cancelText="Cancelar"
        onCancel={onCancel()}
        okButtonProps={{ disabled: true }}
      >
        <List>
          {this.props.salesAuditList
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .sort((a: any, b: any): number => {
              if (a[1].revtstmp > b[1].revtstmp) return -1;
              if (a[1].revtstmp < b[1].revtstmp) return 1;
              return 0;
            })
            .map(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (item: any, index): React.ReactNode => (
                <List.Item key={index}>
                  <List.Item.Meta
                    title={
                      <div className="history-item-title">
                        {moment(item[1].revtstmp).format('YYYY/MM/DD HH:mm')}
                      </div>
                    }
                    description={
                      <table>
                        <tbody>
                          <tr>
                            <td className="history-item-first-column">
                              Acción:
                            </td>
                            <td>{item[2].toString()}</td>
                            <td className="history-item-next-columns">
                              Forma de pago:
                            </td>
                            <td>{item[0].paymentWay}</td>
                            <td className="history-item-next-columns">
                              Condición de pago:
                            </td>
                            <td>{item[0].paymentConditionType}</td>
                          </tr>
                          <tr>
                            <td className="history-item-first-column">
                              Usuario:
                            </td>
                            <td>{item[0].name}</td>
                            <td className="history-item-next-columns">
                              Código de cliente:
                            </td>
                            <td>{item[0].customerCode}</td>
                          </tr>
                        </tbody>
                      </table>
                    }
                  />
                </List.Item>
              ),
            )}
        </List>
      </Modal>
    );
  };
}
export default SaleAuditModal;
