/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { Table } from 'antd';
import BaseWidget from './baseWidget';
import axios, { AxiosResponse } from 'axios';
import { createCoreServiceUrl } from '../../../commons/services';
import { BaseWidgetProps, DistributionCenterIndicator } from './types';
import { getDistCenterName } from '../utilities';
import { toNumber } from 'commons/numbersFormat';

const columns = [
  {
    title: 'C. Dist',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Pendiente Depósito',
    dataIndex: 'pendingDeposit',
    key: 'pendingDeposit',
  },
  {
    title: 'Cuentas X Cobrar',
    key: 'accountsReceivable',
    dataIndex: 'accountsReceivable',
  },
];

class PendingBalancesDistributionCenterTable extends BaseWidget {
  private fetchData(): void {
    const depositsUrl = createCoreServiceUrl(
      `financialInformation/pendingDeposit`,
    );

    const accountsReceivableUrl = createCoreServiceUrl(
      `financialInformation/accountsReceivable`,
    );

    const promises: [
      Promise<AxiosResponse<DistributionCenterIndicator[]>>,
      Promise<AxiosResponse<DistributionCenterIndicator[]>>,
    ] = [axios.get(depositsUrl), axios.get(accountsReceivableUrl)];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Promise.all(promises).then((results: any[]): void => {
      this.setState({
        pendingDeposits: results[0].data,
        accountsReceivables: results[1].data,
      });
    });
  }

  public componentDidUpdate = (prevProps: BaseWidgetProps): void => {
    const { initialDate, endDate } = this.props;
    if (prevProps.initialDate === initialDate && prevProps.endDate === endDate)
      return;

    this.fetchData();
  };

  public componentDidMount(): void {
    this.fetchData();
  }

  private calculateTableData(
    pendingDeposits: DistributionCenterIndicator[],
    accountsReceivable: DistributionCenterIndicator[],
  ) {
    return accountsReceivable.map(
      (ar: DistributionCenterIndicator, index: number) => {
        return {
          key: index,
          name: getDistCenterName(ar.distributionCenterId),
          accountsReceivable: `$${toNumber(ar.value)}`,
          pendingDeposit: `$${toNumber(
            (
              pendingDeposits.find(
                pd => pd.distributionCenterId === ar.distributionCenterId,
              ) || { value: 0.0 }
            ).value,
          )}`,
        };
      },
    );
  }

  public widgetRender = (): React.ReactNode => {
    const tableData = this.calculateTableData(
      this.state.pendingDeposits,
      this.state.accountsReceivables,
    );
    return (
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={false}
        style={{ background: 'white' }}
      />
    );
  };
}

export default PendingBalancesDistributionCenterTable;
