import React from 'react';
import { InventoryList } from '../redux/reducers';
import { Row, Col, List, Tag } from 'antd';
import { Container } from 'types/type';
import Text from 'antd/lib/typography/Text';
import { toNumber } from 'commons/numbersFormat';

export const responsiveList = (inventory: InventoryList): JSX.Element => {
  return (
    <List
      dataSource={inventory.content}
      renderItem={(item: Container, index: number): JSX.Element => {
        return (
          <List.Item>
            <List.Item.Meta
              key={index}
              description={
                <Row
                  style={{
                    background: 'white',
                    padding: '10px',
                    margin: '-7px',
                  }}
                >
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Text strong>{'Nombre: '}</Text>
                    <Text>{item.name}</Text>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Text strong>{'Tipo: '}</Text>
                    <Text>{item.type}</Text>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Text strong>{'Capacidad (L): '}</Text>
                    <Text>{toNumber(item.capacity, true)}</Text>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Text strong>{'Gaspares: '}</Text>
                    <Text>
                      {item.saleEquipments.map(
                        (saleEquipment): React.ReactNode => {
                          return (
                            <Tag color="blue" key={saleEquipment.id}>
                              {saleEquipment.tag}
                            </Tag>
                          );
                        },
                      )}
                    </Text>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Text strong>{'Código de cliente / UDR: '}</Text>
                    <Text>{item.customerCode}</Text>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Text strong>{'% / Volumen de tolerancia: '}</Text>
                    <Text>{toNumber(item.tolerance, true)}</Text>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Text strong>{'Inventario actual %: '}</Text>
                    <Text>{item.currentTheoreticalInventoryLevel}</Text>
                  </Col>
                </Row>
              }
            />
          </List.Item>
        );
      }}
    />
  );
};
