/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import BaseWidget from './baseWidget';
import axios, { AxiosResponse } from 'axios';
import * as _ from 'lodash';
import { createCoreServiceUrl } from '../../../commons/services';
import { SettlementSummaryModel, BaseWidgetProps } from './types';
import { toNumber } from 'commons/numbersFormat';
import { getDistCenterName } from '../utilities';
import { hasValue } from 'commons/functions';
import { Row } from 'antd';

interface BarChartItem {
  distributionCenter: string;
  'Aut. Contado': number;
  'Carb. Contado': number;
  'Aut. Crédito': number;
  'Carb. Crédito': number;
}

class DistributionCenterSaleBarChart extends BaseWidget {
  public fetchData(): void {
    const { initialDate, endDate } = this.props;
    const url = `financialInformation/settlements?dateGreaterThan=${initialDate}&dateLessThan=${endDate}`;
    axios
      .get(createCoreServiceUrl(url))
      .then((response: AxiosResponse<SettlementSummaryModel[]>): void => {
        this.setState({ settlements: response.data });
      });
  }

  public componentDidUpdate = (prevProps: BaseWidgetProps): void => {
    const { initialDate, endDate } = this.props;
    if (prevProps.initialDate === initialDate && prevProps.endDate === endDate)
      return;
    this.fetchData();
  };

  public componentDidMount(): void {
    this.fetchData();
  }

  private calculateData(settlements: SettlementSummaryModel[]): BarChartItem[] {
    let kilogramsValue = 0.56;
    if (hasValue(this.props.kilogramsValue)) {
      kilogramsValue = this.props.kilogramsValue;
    }
    return _.chain(settlements)
      .groupBy('distributionCenterId')
      .map((value, key) => {
        return {
          distributionCenter: getDistCenterName(key),
          'Aut. Contado': value
            .filter(s => s.serviceType === 'AUTOTANQUE')
            .reduce((acc, s) => {
              switch (this.props.radioSelected) {
                case 'volume':
                  return s.totalVolumeCash + acc;
                case 'weight':
                  return s.totalVolumeCash * kilogramsValue + acc;
                default:
                  return s.amountCash + acc;
              }
            }, 0),
          'Aut. Crédito': value
            .filter(s => s.serviceType === 'AUTOTANQUE')
            .reduce((acc, s) => {
              switch (this.props.radioSelected) {
                case 'volume':
                  return s.totalVolumeCredit + acc;
                case 'weight':
                  return s.totalVolumeCredit * kilogramsValue + acc;
                default:
                  return s.amountCredit + acc;
              }
            }, 0),
          'Carb. Contado': value
            .filter(s => s.serviceType === 'CARBURACION')
            .reduce((acc, s) => {
              switch (this.props.radioSelected) {
                case 'volume':
                  return s.totalVolumeCash + acc;
                case 'weight':
                  return s.totalVolumeCash * kilogramsValue + acc;
                default:
                  return s.amountCash + acc;
              }
            }, 0),
          'Carb. Crédito': value
            .filter(s => s.serviceType === 'CARBURACION')
            .reduce((acc, s) => {
              switch (this.props.radioSelected) {
                case 'volume':
                  return s.totalVolumeCredit + acc;
                case 'weight':
                  return s.totalVolumeCredit * kilogramsValue + acc;
                default:
                  return s.amountCredit + acc;
              }
            }, 0),
        };
      })
      .value();
  }

  public widgetRender = (): React.ReactNode => {
    const data = this.calculateData(this.state.settlements);
    return (
      <div>
        <p
          style={{
            fontFamily: 'arial',
            fontSize: '20px',
            textAlign: 'center',
            height: 15,
          }}
        >
          Venta Por Gerencia
        </p>
        <Row style={{ height: 380 }}>
          <ResponsiveBar
            data={data}
            keys={[
              'Aut. Contado',
              'Aut. Crédito',
              'Carb. Contado',
              'Carb. Crédito',
            ]}
            indexBy="distributionCenter"
            colors={['#2166ac', '#4393c3', '#d65f4c', '#f4a582']}
            margin={{ top: 20, right: 130, bottom: 50, left: 60 }}
            padding={0.3}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisTop={null}
            axisRight={null}
            label={d => {
              const { radioSelected } = this.props;
              switch (radioSelected) {
                case 'volume':
                  return `${toNumber(+d.value)} Lts`;
                case 'weight':
                  return `${toNumber(+d.value)} Kgs`;
                default:
                  return `$${toNumber(+d.value)}`;
              }
            }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Centro de Distribución',
              legendPosition: 'middle',
              legendOffset: 32,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: 'middle',
              legendOffset: -40,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            legends={[
              {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
          />
        </Row>
      </div>
    );
  };
}

export default DistributionCenterSaleBarChart;
