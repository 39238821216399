/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Ricardo Sansores <ricardo@ssf.mx>, May 2019
 *
 *
 */

import { combineReducers, Reducer } from 'redux';
import {
  User,
  DistributionCenter,
  Permissions,
  Marketstall,
  DebtCollector,
  PortfolioManager,
} from 'types/type';
import { UserActionTypes } from './types';
import {
  initUser,
  initDistributionCenter,
  initMarketstall,
  initDebtCollector,
  initPortfolioManager,
} from 'commons/initTypes';
import { RootState } from 'store/configureStore';

export interface UsersListI {
  content: User[];
  number?: number;
  totalElements?: number;
}

export interface UserSavedI {
  usersaved: User | {};
}

export interface DistributionCenterI {
  distributionCenters: DistributionCenter[];
}

export interface PermissionsI {
  permissions: Permissions[];
}

export interface MarketstallI {
  marketstall: Marketstall[];
}

export interface DebtCollectorI {
  DebtCollector: DebtCollector[];
}
export interface PortfolioManagerI {
  PortfolioManager: PortfolioManager[];
}

export interface UsersState {
  readonly content: User[];
  readonly usersList: UsersListI;
  readonly usersSave: UserSavedI;
  readonly userupdate: {};
  readonly distributionCenterInterface: DistributionCenterI;
  readonly permissionInterface: PermissionsI;
  readonly user: User;
  readonly marketstallInterface: MarketstallI;
  readonly isFetching: boolean;
  readonly debtCollectorInterface: DebtCollectorI;
  readonly portfolioManagerInterface: PortfolioManagerI;
}

const updateActiveUser = (state: UsersListI, updatedUser: User): User[] => {
  const contentList = state.content.map(
    (item: User): User => {
      if (item.id === updatedUser.id) {
        return { ...item, isActive: updatedUser.isActive };
      }
      return item;
    },
  );
  return contentList;
};

const usersList: Reducer<UsersListI> = (
  state: UsersListI = { content: [] },
  action,
): UsersListI => {
  switch (action.type) {
    case UserActionTypes.SET:
      return { ...state, ...action.payload };
    case UserActionTypes.UPDATE:
      const content = updateActiveUser(state, action.payload);
      return { ...state, content };
    default:
      return state;
  }
};

const usersSave: Reducer<UserSavedI> = (
  state: UserSavedI = { usersaved: {} },
  action,
): UserSavedI => {
  if (action.type === UserActionTypes.SAVE) {
    return { usersaved: { ...state, ...action.payload } };
  }
  return state;
};

const user: Reducer<User> = (state: User = initUser, action): User => {
  switch (action.type) {
    case UserActionTypes.GET:
      return { ...state, ...action.payload };
    case UserActionTypes.CLEAN_USER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const distributionCenterInterface: Reducer<DistributionCenterI> = (
  state: DistributionCenterI = {
    distributionCenters: [initDistributionCenter],
  },
  action,
): DistributionCenterI => {
  if (action.type === UserActionTypes.GET_DISTRIBUTION_CENTER) {
    return { ...state, distributionCenters: action.payload };
  }
  return state;
};

const permissionInterface: Reducer<PermissionsI> = (
  state: PermissionsI = { permissions: [] },
  action,
): PermissionsI => {
  if (action.type === UserActionTypes.GET_PERMISSIONS) {
    return { ...state, permissions: action.payload };
  }
  return state;
};

const marketstallInterface: Reducer<MarketstallI> = (
  state: MarketstallI = { marketstall: [initMarketstall] },
  action,
): MarketstallI => {
  if (action.type === UserActionTypes.GET_MARKETSTALL) {
    return { ...state, marketstall: action.payload };
  }
  return state;
};

const isFetching: Reducer<boolean> = (
  state: boolean = false,
  action,
): boolean => {
  if (action.type === UserActionTypes.FETCHING) {
    return action.payload;
  }
  return state;
};

const debtCollectorInterface: Reducer<DebtCollectorI> = (
  state: DebtCollectorI = { DebtCollector: [initDebtCollector] },
  action,
): DebtCollectorI => {
  if (action.type === UserActionTypes.GET_DEBT_COLLECTOR) {
    return { ...state, DebtCollector: action.payload };
  }
  return state;
};
const portfolioManagerInterface: Reducer<PortfolioManagerI> = (
  state: PortfolioManagerI = { PortfolioManager: [initPortfolioManager] },
  action,
): PortfolioManagerI => {
  if (action.type === UserActionTypes.GET_PORTFOLIO_MANAGER) {
    return { ...state, PortfolioManager: action.payload };
  }
  return state;
};

export const getIsFetching = (store: RootState): boolean =>
  store.invoiceState.isFetching;

export default combineReducers<UsersState>({
  usersList,
  usersSave,
  user,
  distributionCenterInterface,
  permissionInterface,
  marketstallInterface,
  isFetching,
  debtCollectorInterface,
  portfolioManagerInterface,
});
