import React, { MouseEvent } from 'react';
import { connect } from 'react-redux';
import Form, { FormComponentProps, ValidationRule } from 'antd/lib/form';
import {
  Input,
  Card,
  Row,
  Select,
  Col,
  DatePicker,
  Checkbox,
  Divider,
  Button,
  Collapse,
} from 'antd';
import '../../../commons/components/layout/main/styles';
import { RouteComponentProps } from 'react-router';
import {
  User,
  DistributionCenter,
  Permissions,
  Marketstall,
  DebtCollector,
  PortfolioManager,
} from 'types/type';
import { RootState } from 'store/configureStore';
import Text from 'antd/lib/typography/Text';
import {
  getUserAction,
  saveuser,
  getDistributionCenterAction,
  getDebtCollectorAction,
  getPortfolioManagerAction,
  getPermissionsAction,
  cleanUserAction,
  getMarketstallAction,
} from '../redux/actions';
import { UserRules as us } from '../userRules/rules';
import { MessagesUser, LabelsUser } from 'commons/strings';
import moment from 'moment';
import { Popups } from 'commons/components/popups/popups';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';

interface HomeRouterProps {
  id: string;
}

interface DispatchProps {
  getUserAction: (userId: string) => void;
  saveuser: (values: User) => void;
  getDistributionCenterAction: () => void;
  getPermissionsAction: () => void;
  getMarketstallAction: () => void;
  cleanUserAction: () => void;
  getDebtCollectorAction: () => void;
  getPortfolioManagerAction: () => void;
}

interface StateProps {
  user: User;
  distributionCenters: DistributionCenter[];
  marketstall: Marketstall[];
  permissions: Permissions[];
  isFetching: boolean;
  debtCollector: DebtCollector[];
  portfolioManager: PortfolioManager[];
}

interface State {
  checkedPermissions: Permissions[];
  indeterminate: boolean;
  checkAll: boolean;
  isAnyChecked: boolean;
}

/**
 * this const build a style depending value send the size in the screen interact.
 * @param value is a value size in the lg xl and xxl proportion.
 */
const sizeScreen = (value: number): {} => ({
  xs: { span: 23, offset: 1 },
  sm: { span: 23, offset: 1 },
  md: { span: 23, offset: 1 },
  lg: { span: value, offset: 1 },
  xl: { span: value, offset: 1 },
  xxl: { span: value, offset: 1 },
});

type Props = State &
  DispatchProps &
  StateProps &
  RouteComponentProps<HomeRouterProps> &
  FormComponentProps;

class CollectionCreateForm extends React.Component<Props> {
  public state: State = {
    checkedPermissions: this.props.user.userPermission,
    indeterminate: true,
    checkAll: true,
    isAnyChecked: true,
  };

  public componentDidMount(): void {
    const { id } = this.props.match.params;
    if (id !== '0') this.props.getUserAction(id);
    this.props.getDistributionCenterAction();
    this.props.getPortfolioManagerAction();
    this.props.getDebtCollectorAction();
    this.props.getPermissionsAction();
    this.props.getMarketstallAction();
  }

  public componentWillUnmount(): void {
    this.props.form.resetFields();
    this.props.cleanUserAction();
  }

  /**
   * this method validates if the "id" is not "0" to submit edit user or save user
   */
  private validateData = async (values: User, id: string): Promise<void> => {
    if (id !== '0') {
      const user: User = { ...values, id };
      await this.props.saveuser({ ...user, id });
    } else {
      const user: User = { ...values };
      await this.props.saveuser({ ...user });
    }
  };

  public handleSubmitAction = (e: MouseEvent<HTMLButtonElement>): void => {
    let { checkedPermissions } = this.state;

    e.preventDefault();

    if (checkedPermissions.length === 0)
      checkedPermissions = this.props.form.getFieldValue('userPermission');

    if (e.currentTarget.value === 'cancel') {
      window.scroll(0, 0);
      this.props.history.push('/users');
    } else {
      this.props.form.validateFieldsAndScroll(
        async (err, values: User): Promise<void> => {
          const { id } = this.props.match.params;

          if (checkedPermissions.length !== 0) {
            if (!err) {
              try {
                values.distributionCenterId =
                  values.assignedDistributionCenters[0];
                await this.validateData(values, id);
                window.scroll(0, 0);
                this.props.history.push('/users');
              } catch (error) {
                Popups.notifyException(error);
              }
            }
            this.setState({ isAnyChecked: true });
          } else {
            window.scroll(0, 0);
            this.setState({ isAnyChecked: false });
          }
        },
      );
    }
  };

  public handleOnSelectAll = (e: CheckboxChangeEvent): void => {
    this.setState({
      checkAll: e.target.checked,
      indeterminate: false,
      checkedPermissions: e.target.checked ? this.props.permissions : [],
    });
    this.props.form.setFieldsValue({
      userPermission: e.target.checked
        ? this.props.permissions.filter(
            (f: Permissions): boolean => f.priority === 4,
          )
        : [],
    });
  };

  public onChangeCheckboxGroup = (e: CheckboxValueType[]): void => {
    this.setState({
      checkedPermissions: e,
      indeterminate: e.length !== 0 && e.length < this.props.permissions.length,
      checkAll: e.length === this.props.permissions.length,
    });
  };

  /**
   *this method render a custom form item to type Input
   *
   */
  private renderInput = (
    initValue: string | number,
    label: string,
    formVarName: string,
    rule: ValidationRule[],
    maxCharacters?: number,
    inputType?: string,
  ): JSX.Element => {
    return (
      <Form.Item hasFeedback label={label}>
        {this.props.form.getFieldDecorator(formVarName, {
          initialValue: initValue,
          rules: rule,
        })(
          <Input
            size="large"
            placeholder={label}
            maxLength={maxCharacters ? maxCharacters : 255}
            type={inputType ? inputType : ''}
          />,
        )}
      </Form.Item>
    );
  };

  public filterPermissions = (
    permissions: Permissions[],
    user: User,
  ): Permissions[] => {
    let result: Permissions[] = [];
    for (let i = 0; i < permissions.length; i++) {
      for (let t = 0; t < user.userPermission.length; t++) {
        if (user.userPermission[t].id === permissions[i].id) {
          result.push(permissions[i]);
        }
      }
    }
    return result;
  };

  public setPermissionGroupName = (name: string): string => {
    switch (name) {
      case 'dashboard':
        return 'Dashboard';
      case 'user':
        return 'Usuario';
      case 'company':
        return 'Compañia';
      case 'sale':
        return 'Venta';
      case 'statement':
        return 'Cuentas';
      case 'customer':
        return 'Cliente';
      case 'payment':
        return 'Pago';
      case 'settlement':
        return 'Liquidaciones';
      case 'cylinder':
        return 'Cilindros';
      case 'bankDeposit':
        return 'Depósito';
      case 'develop':
        return 'Desarrollo';
      case 'inventory':
        return 'Inventario';
      case 'route':
        return 'Rutas';
    }
    return '';
  };

  public groupPermissions = (
    permissions: Permissions[],
    orderElements: string[],
  ): React.ReactNode => {
    const groupedPermissions = orderElements.map(
      (order: string, index: number): JSX.Element => (
        <Collapse
          key={index}
          className="permission-collapse"
          bordered={false}
          defaultActiveKey={[index]}
        >
          <CollapsePanel
            className="permission-collapse-panel"
            header={this.setPermissionGroupName(order)}
            key={index}
          >
            {permissions
              .filter((f: Permissions): boolean => f.group === order)
              .map(
                (permission: Permissions, ind: number): JSX.Element => (
                  <Col offset={1} span={23} key={ind}>
                    <p>
                      <Checkbox
                        className={'priority-' + permission.priority}
                        value={permission}
                      >
                        {permission.description}
                      </Checkbox>
                    </p>
                  </Col>
                ),
              )}
          </CollapsePanel>
        </Collapse>
      ),
    );
    return groupedPermissions;
  };

  public render = (): React.ReactNode => {
    const {
      form: { getFieldDecorator },
      user,
      distributionCenters,
      permissions,
      isFetching,
      marketstall,
      debtCollector,
      portfolioManager,
    } = this.props;
    const permissionOrder: string[] = [
      'dashboard',
      'user',
      'company',
      'sale',
      'statement',
      'customer',
      'payment',
      'settlement',
      'cylinder',
      'bankDeposit',
      'inventory',
      'develop',
      'route',
    ];
    const {
      checkAll,
      indeterminate,
      checkedPermissions,
      isAnyChecked,
    } = this.state;
    const { id } = this.props.match.params;
    const isStateNotCheckall: boolean =
      checkedPermissions.length !== 0 &&
      checkedPermissions.length < permissions.length;
    const isStateCheckAll: boolean =
      checkedPermissions.length === permissions.length;
    const isPropsNotCheckAll: boolean =
      user.userPermission.length !== 0 &&
      user.userPermission.length < permissions.length;
    const isPropsCkeckAll: boolean =
      permissions.length === user.userPermission.length;

    return (
      <Row>
        <Card bordered={false} className={'user-card'}>
          <Row>
            <h1>
              <Text strong>
                Llena los campos a continuación para crear un nuevo usuario
              </Text>
            </h1>
          </Row>
          <Row>
            <Text disabled>Los campos marcados con * son obligadorios</Text>
          </Row>
        </Card>
        <Form layout="vertical" className="user-form">
          <Card
            title="Información del usuario"
            bordered={false}
            className="user-card"
          >
            <Col {...sizeScreen(6)}>
              {this.renderInput(
                user.name,
                LabelsUser.USER_NAME,
                'name',
                us.userNameRule,
              )}
            </Col>
            <Col {...sizeScreen(6)}>
              {this.renderInput(
                user.lastName1,
                LabelsUser.USER_FIRST_NAME,
                'lastName1',
                us.userFirstNameRule,
              )}
            </Col>
            <Col {...sizeScreen(6)}>
              {this.renderInput(
                user.lastName2,
                LabelsUser.USER_LAST_NAME,
                'lastName2',
                us.userLastNameRule,
              )}
            </Col>
            <Col {...sizeScreen(9)}>
              {this.renderInput(
                user.email,
                LabelsUser.USER_EMAIL,
                'email',
                us.getUserEmailRules(user),
                50,
              )}
            </Col>
            <Col {...sizeScreen(9)}>
              {this.renderInput(
                user.phone,
                LabelsUser.USER_PHONE,
                'phone',
                us.userPhoneRule,
                10,
              )}
            </Col>
            <Col {...sizeScreen(0)}>
              <Form.Item hasFeedback label={LabelsUser.USER_DISTRIBUTIONCENTER}>
                {this.props.form.getFieldDecorator('distributionCenterId', {
                  initialValue: user.distributionCenterId,
                })}
              </Form.Item>
            </Col>
            <Col {...sizeScreen(0)}>
              <Form.Item hasFeedback label={'taskitId'}>
                {this.props.form.getFieldDecorator('taskitId', {
                  initialValue: user.taskitId,
                })}
              </Form.Item>
            </Col>
            <Col {...sizeScreen(9)}>
              <Form.Item label={'Centros de distribucion asignados'}>
                {this.props.form.getFieldDecorator(
                  'assignedDistributionCenters',
                  {
                    initialValue:
                      //First set the assgined distribution centers
                      user && user.assignedDistributionCenters
                        ? user.assignedDistributionCenters
                        : user && user.distributionCenterId
                        ? //If not present, then set the distribution center id
                          [user.distributionCenterId]
                        : //Else set empty
                          [],
                    rules: [
                      {
                        required: true,
                        message: 'Selecciona almenos un centro de distribución',
                      },
                    ],
                  },
                )(
                  <Select
                    size="large"
                    placeholder="Seleccionar uno o más"
                    mode="multiple"
                    allowClear
                    onChange={(value: string[]): void => {
                      let distrCentersIds: string[] = [];
                      value.forEach((id: string): void => {
                        distrCentersIds.push(id);
                      });
                    }}
                  >
                    {distributionCenters.map(
                      (item, index): React.ReactNode => (
                        <Select.Option key={item.id} value={item.id}>
                          <div id={'dist_' + index.toString()}>
                            {' '}
                            {item.name}
                          </div>
                        </Select.Option>
                      ),
                    )}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col {...sizeScreen(9)}>
              <Form.Item hasFeedback label={LabelsUser.USER_MARKETSTALL}>
                {this.props.form.getFieldDecorator('marketstall', {
                  initialValue: user.marketstall,
                  rules: us.userMarketstallRule,
                })(
                  <Select
                    size="large"
                    placeholder={LabelsUser.USER_MARKETSTALL}
                  >
                    {marketstall.map(
                      (item, index): React.ReactNode => (
                        <Select.Option key={item.id} value={item.id}>
                          <div id={'dist_' + index.toString()}>
                            {' '}
                            {item.name}
                          </div>
                        </Select.Option>
                      ),
                    )}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col {...sizeScreen(9)}>
              <Form.Item label={'Cobradores'}>
                {this.props.form.getFieldDecorator('debtCollector', {
                  initialValue: user.debtCollector ? user.debtCollector : [],
                  rules: [
                    {
                      required: false,
                      message: 'Selecciona almenos un cobrador',
                    },
                  ],
                })(
                  <Select
                    size="large"
                    placeholder="Seleccionar uno o más"
                    mode="multiple"
                    allowClear
                    onChange={(value: string[]): void => {
                      let debtCollectorIds: string[] = [];
                      value.forEach((id: string): void => {
                        debtCollectorIds.push(id);
                      });
                    }}
                  >
                    {debtCollector.map(
                      (item, index): React.ReactNode => (
                        <Select.Option key={item.id} value={item.id}>
                          <div id={index.toString()}> {item.name}</div>
                        </Select.Option>
                      ),
                    )}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col {...sizeScreen(9)}>
              <Form.Item label={'Encargados de cartera'}>
                {this.props.form.getFieldDecorator('portfolioManager', {
                  initialValue: user.portfolioManager
                    ? user.portfolioManager
                    : [],
                  rules: [
                    {
                      required: false,
                      message: 'Selecciona almenos un encargado de cartera',
                    },
                  ],
                })(
                  <Select
                    size="large"
                    placeholder="Seleccionar uno o más"
                    mode="multiple"
                    allowClear
                    onChange={(value: string[]): void => {
                      let portfolioManagerIds: string[] = [];
                      value.forEach((id: string): void => {
                        portfolioManagerIds.push(id);
                      });
                    }}
                  >
                    {portfolioManager.map(
                      (item, index): React.ReactNode => (
                        <Select.Option key={item.id} value={item.id}>
                          <div id={index.toString()}> {item.name}</div>
                        </Select.Option>
                      ),
                    )}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col {...sizeScreen(4)}>
              <Form.Item label={LabelsUser.USER_BIRTHDAY}>
                {getFieldDecorator('birthday', {
                  initialValue: user.birthday
                    ? moment(user.birthday)
                    : moment(),
                  rules: [
                    {
                      required: true,
                      message: MessagesUser.USER_BIRTHDAY_RULE,
                    },
                  ],
                })(<DatePicker format="YYYY/MM/DD" />)}
              </Form.Item>
            </Col>
            <Col {...sizeScreen(4)}>
              <Form.Item label={LabelsUser.USER_ADMISSION_DATE}>
                {getFieldDecorator('admissionDate', {
                  initialValue: user.admissionDate
                    ? moment(user.admissionDate)
                    : moment(),
                  rules: [
                    {
                      required: true,
                      message: MessagesUser.USER_ADMISSION_DATE_RULE,
                    },
                  ],
                })(<DatePicker format="YYYY/MM/DD" />)}
              </Form.Item>
            </Col>
            <Col {...sizeScreen(9)} style={{ display: 'none' }}>
              <Form.Item label={'Última conexión'}>
                {getFieldDecorator('lastActiveDate', {
                  initialValue: user.lastActiveDate
                    ? moment(user.lastActiveDate)
                    : '',
                })(<DatePicker format="YYYY/MM/DD" />)}
              </Form.Item>
            </Col>
          </Card>

          <Card title="Permisos" bordered={false} className="user-card">
            <Row>
              <Col {...sizeScreen(10)}>
                <p>
                  <Text strong>Seleccione los permisos para el usuario</Text>
                </p>
                {isAnyChecked ? (
                  ''
                ) : (
                  <Text
                    className={
                      isAnyChecked ? '' : 'any-checkbox-is-cheked-rule'
                    }
                    strong
                  >
                    Es necesario seleccionar algun permiso para el usuario
                  </Text>
                )}
              </Col>
            </Row>
            <Row>
              <Col {...sizeScreen(4)}>
                <Checkbox
                  onChange={this.handleOnSelectAll}
                  indeterminate={
                    checkedPermissions.length !== 0
                      ? isStateNotCheckall
                      : isPropsNotCheckAll && indeterminate
                  }
                  checked={
                    checkedPermissions.length !== 0
                      ? isStateCheckAll
                      : isPropsCkeckAll && checkAll
                  }
                >
                  Seleccionar Todos
                </Checkbox>
                <p />
              </Col>
              <Divider />
              {getFieldDecorator(`userPermission`, {
                initialValue: this.filterPermissions(permissions, user),
              })(
                <Checkbox.Group onChange={this.onChangeCheckboxGroup}>
                  {this.groupPermissions(permissions, permissionOrder)}
                </Checkbox.Group>,
              )}
            </Row>
          </Card>
          <Card bordered={false} className="user-card">
            <Divider />
            <Row>
              <Col>
                <Button
                  className="button-size form-cancel-button"
                  value="cancel"
                  type="default"
                  disabled={isFetching}
                  onClick={this.handleSubmitAction}
                >
                  Cancelar
                </Button>
                <Button
                  className="button-size form-submit-button"
                  value="submit"
                  type="primary"
                  disabled={isFetching}
                  onClick={this.handleSubmitAction}
                >
                  {isFetching === false
                    ? id === '0'
                      ? 'Crear Usuario' //if create new user
                      : 'Guardar cambios' //if edit user
                    : 'Cargando...'}
                </Button>
              </Col>
            </Row>
          </Card>
        </Form>
      </Row>
    );
  };
}

const mapStateToProps = (states: RootState): StateProps => {
  return {
    user: states.usersState.user,
    distributionCenters:
      states.usersState.distributionCenterInterface.distributionCenters,
    marketstall: states.usersState.marketstallInterface.marketstall,
    permissions: states.usersState.permissionInterface.permissions,
    isFetching: states.usersState.isFetching,
    debtCollector: states.usersState.debtCollectorInterface.DebtCollector,
    portfolioManager:
      states.usersState.portfolioManagerInterface.PortfolioManager,
  };
};
const mapDispatchToProps: DispatchProps = {
  getUserAction,
  saveuser,
  getDistributionCenterAction,
  getMarketstallAction,
  getPermissionsAction,
  cleanUserAction,
  getDebtCollectorAction,
  getPortfolioManagerAction,
};
export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create()(CollectionCreateForm));
