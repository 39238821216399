import {
  Settlement,
  Sale,
  DistributionCenter,
  Container,
  ContainerPurchase,
} from 'types/type';
import React, { Component } from 'react';
import {
  Modal,
  Select,
  Row,
  Col,
  Spin,
  Input,
  Form,
  InputNumber,
  DatePicker,
  Collapse,
  Button,
  Icon,
} from 'antd';
import Text from 'antd/lib/typography/Text';
import { toNumber } from 'commons/numbersFormat';
import {
  initDistributionCenter,
  initContainerPurchase,
} from 'commons/initTypes';
import { onlyNumberDecimalPattern } from 'commons/regularExpressions';
import { WrappedFormUtils, FormComponentProps } from 'antd/lib/form/Form';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroller';
import _ from 'lodash';
interface CounterProps {
  visible: boolean;
  onCancel: Function;
  onCreate: (operator: string, assistant: string, id: string) => void;
  settlementToApply: Settlement;
  userList: string[];
  distributionCenterName: DistributionCenter[];
  totalCretid: number;
  totalCash: number;
  isFetching: boolean;
  isCommentVisible: boolean;
  form: WrappedFormUtils;
}

interface ContainerValues {
  id: string;
  container: number;
  numb: number;
}

interface States {
  operator: string;
  assistant: string;
  containerValuesInitial: ContainerValues[];
  containerValuesFinal: ContainerValues[];
  containerValues: Container[];
  showDetails: boolean;
  purchases: ContainerPurchase[];
  showPaymentWayDetails: boolean;
  showPricesDetails: boolean;
}

interface TotalsByPrice {
  price: string;
  total: number;
  totalLitters: number;
  totalTickets: number;
}

const paymentConditions = [
  { title: 'Total contado', paymentCondition: 'CASH' },
  { title: 'Total crédito', paymentCondition: 'CREDIT' },
  { title: 'Traspasos', paymentCondition: 'TRASLATE' },
  { title: 'Autoconsumo', paymentCondition: 'SELF_CONSUMPTION' },
];

const paymentWays = [
  { title: 'Efectivo', paymentWay: 'EFECTIVO' },
  { title: 'Tarjeta de crédito', paymentWay: 'CREDITO' },
  { title: 'Tarjeta de débito', paymentWay: 'DEBITO' },
  { title: 'Cheque', paymentWay: 'CHEQUE' },
  { title: 'Transferencia', paymentWay: 'TRANSFERENCIA' },
  { title: 'Por definir', paymentWay: 'POR_DEFINIR' },
];

class CreateFormStatement extends Component<CounterProps & FormComponentProps> {
  public state: States = {
    operator: 'Operador',
    assistant: 'Ayudante',
    containerValuesInitial: [],
    containerValuesFinal: [],
    containerValues: [],
    showDetails: true,
    purchases: [initContainerPurchase],
    showPaymentWayDetails: false,
    showPricesDetails: false,
  };

  private calculateTotal = (): number => {
    let total = 0;
    const { settlementToApply } = this.props;
    if (settlementToApply.sales) {
      total = settlementToApply.sales.reduce(
        (accumulator, sale): number => sale.total + accumulator,
        0,
      );
    }
    return total;
  };

  private calculateTotalLitters = (): number => {
    let total = 0;
    const { settlementToApply } = this.props;
    if (settlementToApply.sales) {
      total = settlementToApply.sales.reduce(
        (accumulator, sale): number => sale.totalVolume + accumulator,
        0,
      );
    }
    return total;
  };

  private getTotalLittersByPaymentCondition = (
    paymentCondition: string,
  ): number => {
    let total = 0;
    const { settlementToApply } = this.props;
    if (settlementToApply.sales) {
      total = settlementToApply.sales
        .filter(
          (s: Sale): boolean => s.paymentConditionType === paymentCondition,
        )
        .reduce(
          (accumulator, sale): number => sale.totalVolume + accumulator,
          0,
        );
    }
    return total;
  };

  private getTotalByPaymentCondition = (paymentCondition: string): number => {
    let total = 0;
    const { settlementToApply } = this.props;
    if (settlementToApply.sales) {
      total = settlementToApply.sales
        .filter(
          (s: Sale): boolean => s.paymentConditionType === paymentCondition,
        )
        .reduce((accumulator, sale): number => sale.total + accumulator, 0);
    }
    return total;
  };

  private getTotalLittersByPaymentWay = (paymentWay: string): number => {
    let total = 0;
    const { settlementToApply } = this.props;
    if (settlementToApply.sales) {
      total = settlementToApply.sales
        .filter((s: Sale): boolean => s.paymentWay === paymentWay)
        .reduce(
          (accumulator, sale): number => sale.totalVolume + accumulator,
          0,
        );
    }
    return total;
  };

  private getTotalByPaymentWay = (paymentWay: string): number => {
    let total = 0;
    const { settlementToApply } = this.props;
    if (settlementToApply.sales) {
      total = settlementToApply.sales
        .filter((s: Sale): boolean => s.paymentWay === paymentWay)
        .reduce((accumulator, sale): number => sale.total + accumulator, 0);
    }
    return total;
  };

  private calculatePurchasesTotal = (): number => {
    let total = 0;
    let purchases: Sale[] = [];
    if (this.props.settlementToApply.settlementContainer)
      purchases = this.props.settlementToApply.settlementContainer.purchases.map(
        (sale): Sale => sale,
      );

    if (purchases.length > 0) {
      total = purchases.reduce(
        (acumulator, sale): number => acumulator + sale.total,
        0,
      );
    }
    return total;
  };

  private calculatePurchasesLitters = (): number => {
    let total = 0;
    let purchases: Sale[] = [];
    if (this.props.settlementToApply.settlementContainer)
      purchases = this.props.settlementToApply.settlementContainer.purchases.map(
        (sale): Sale => sale,
      );

    if (purchases.length > 0) {
      total = purchases.reduce(
        (acumulator, sale): number => acumulator + sale.totalVolume,
        0,
      );
    }
    return total;
  };

  private getLittersAndTotalByPrices = (): TotalsByPrice[] => {
    const { settlementToApply } = this.props;
    //If list is empty, return an initialized object
    if (!settlementToApply.sales)
      return [{ price: '0', total: 0, totalLitters: 0, totalTickets: 0 }];

    return _.chain(settlementToApply.sales)
      .groupBy('unitPrice')
      .map(
        (value, key): TotalsByPrice => {
          return {
            price: key,
            total: value
              //Convert price to string to be able to compare it with the key (value grouped)
              .filter((s): boolean => s.unitPrice.toString() === key)
              .reduce(
                (accumulator, sale): number => sale.total + accumulator,
                0,
              ),
            totalLitters: value
              .filter((s): boolean => s.unitPrice.toString() === key)
              .reduce(
                (accumulator, sale): number => sale.totalVolume + accumulator,
                0,
              ),
            totalTickets: value.filter(
              (s): boolean => s.unitPrice.toString() === key,
            ).length,
          };
        },
      )
      .value();
  };

  public okevent = (): void => {
    const { operator, assistant } = this.state;
    const { idNoG4s } = this.props.settlementToApply;
    this.props.onCreate(operator, assistant, idNoG4s);
  };

  public validatePendingSettlements = (): boolean => {
    const { settlementToApply } = this.props;
    if (settlementToApply.settlementsPending) {
      //is true when is the last cut pending from de equipments to the container patern
      return settlementToApply.settlementsPending <= 1 ? false : true;
    }
    return true;
  };

  //Get the array of fields names, according to their index in the list
  private fieldsName = (index: number): string[] => {
    return [`purchases[${index}].volume`, `purchases[${index}].date`];
  };

  public addPurchase = (purchases: ContainerPurchase[]): void => {
    let currentPurchases = purchases.map(
      (purchase): ContainerPurchase => purchase,
    );
    currentPurchases.push(initContainerPurchase);
    this.setState({ purchases: currentPurchases });
  };

  public removePurchase = (index: number): void => {
    let indexesKept: number[] = [];

    //Remove the item by index and save the index from kept rows into an array
    this.state.purchases.filter((item, filterIndex: number): boolean => {
      if (filterIndex !== index) indexesKept.push(filterIndex);
      return filterIndex !== index;
    });
    let newPurchases: ContainerPurchase[] = [];

    //Get the values from fields not removed and update the fields
    indexesKept.map((value: number): number => {
      const purchase = this.props.form.getFieldsValue(this.fieldsName(value))
        .purchases[value];
      newPurchases.push(purchase);
      return value;
    });
    //Update the form values
    this.props.form.setFieldsValue({ purchases: newPurchases });
    this.setState({ purchases: newPurchases });
  };

  public render = (): JSX.Element => {
    const { visible, onCancel, settlementToApply, form } = this.props;
    //const { containerValuesFinal, containerValuesInitial } = this.state;
    const { TextArea } = Input;
    const { getFieldDecorator } = form;
    return (
      <Modal
        visible={visible}
        title={'Liquidar corte N°: ' + settlementToApply.folio}
        okText="Liquidar"
        cancelText="Cancelar"
        onCancel={onCancel()}
        onOk={this.okevent}
        okButtonProps={{ disabled: this.props.isFetching }}
        cancelButtonProps={{ disabled: this.props.isFetching }}
      >
        <Row>
          <Row
            style={{
              marginTop: '-24px',
              padding: '5px 24px',
            }}
            className="modal-light-blue-background"
          >
            <p />
            Verifica la información y liquida el corte, puedes modificar al
            operador y ayudante si así lo requieres.
          </Row>
          <Row
            style={{
              padding: '5px 24px',
            }}
            className="modal-light-blue-background"
          >
            <Col span={11}>
              Operador:
              <Select
                style={{ width: '100%' }}
                defaultValue={'Operador'}
                disabled={true}
                onChange={(value: string): void =>
                  this.setState({ operator: value })
                }
              >
                <Select.Option value={'Operador'}>Operador</Select.Option>
                {//TODO this map is unused because the select is disabled
                this.props.userList.map(
                  (user: string): JSX.Element => {
                    return (
                      <Select.Option value={user} key={user}>
                        {user}
                      </Select.Option>
                    );
                  },
                )}
              </Select>
            </Col>
            <Col span={11} offset={2}>
              Ayudante:
              <Select
                style={{ width: '100%' }}
                defaultValue={'Ayudante'}
                disabled={true}
                onChange={(value: string): void =>
                  this.setState({ assistant: value })
                }
              >
                <Select.Option value={'Ayudante'}>Ayudante</Select.Option>
                {//TODO this map is unused because the select is disabled
                this.props.userList.map(
                  (user: string): JSX.Element => {
                    return (
                      <Select.Option value={user} key={user}>
                        {user}
                      </Select.Option>
                    );
                  },
                )}
              </Select>
            </Col>
          </Row>
          <Spin spinning={this.props.isFetching}>
            <Row
              style={{
                padding: '10px 0px',
              }}
              className="modal-light-blue-background"
            >
              <Col span={23} offset={1}>
                <Text strong>
                  {'Totalizador final de volumen: '}
                  {toNumber(this.props.settlementToApply.finalvolumetotalizer)}
                </Text>
              </Col>
            </Row>
            <Form>
              <Row>
                {settlementToApply.settlementContainer &&
                  this.validatePendingSettlements() &&
                  settlementToApply.settlementContainer.containers.length >
                    0 && (
                    <Row>
                      <Col
                        span={24}
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        {'Niveles del Contenedor:'}
                      </Col>
                    </Row>
                  )}
                <Row>
                  {settlementToApply.settlementContainer &&
                    settlementToApply.settlementContainer.containers.length >
                      0 &&
                    this.validatePendingSettlements() &&
                    settlementToApply.settlementContainer.containers.map(
                      (container: Container, index: number): JSX.Element => {
                        return (
                          <Row key={index}>
                            <Col span={24} className="container-name">
                              {container.name + ':'}
                            </Col>
                            <Col span={0}>
                              <Form.Item>
                                {getFieldDecorator(`id[${index}]`, {
                                  initialValue: container
                                    ? container.id
                                    : undefined,
                                })(<div />)}
                              </Form.Item>
                            </Col>
                            <Col span={5} style={{ padding: '10px 0px' }}>
                              {'Nivel inicial:'}
                            </Col>
                            <Col span={6}>
                              <Form.Item style={{ marginBottom: '0px' }}>
                                {getFieldDecorator(
                                  `initialPercentage[${index}]`,
                                  {
                                    //If there is value of child container and the lenght of both lists is the same, show it
                                    initialValue:
                                      settlementToApply.childContainerValues &&
                                      settlementToApply.childContainerValues
                                        .length ===
                                        settlementToApply.settlementContainer
                                          .containers.length
                                        ? settlementToApply
                                            .childContainerValues[index]
                                            .percentageInitialVolume
                                        : settlementToApply.initialPercentTank //Else if there is value of initial percentage, show it
                                        ? settlementToApply.initialPercentTank
                                        : undefined, //Else show nothing
                                    rules: [
                                      {
                                        pattern: onlyNumberDecimalPattern,
                                        required: true,
                                        message: 'Introduce un porcentaje',
                                      },
                                    ],
                                  },
                                )(
                                  <InputNumber
                                    min={0.01}
                                    max={100}
                                    style={{
                                      width: '100%',
                                    }}
                                    placeholder={'Porcentaje'}
                                  />,
                                )}
                              </Form.Item>
                            </Col>
                            <Col
                              span={5}
                              offset={2}
                              style={{ padding: '10px 0px' }}
                            >
                              {'Nivel final:'}
                            </Col>
                            <Col span={6}>
                              <Form.Item style={{ marginBottom: '0px' }}>
                                {getFieldDecorator(
                                  `finalPercentage[${index}]`,
                                  {
                                    //If there is value of child container and the lenght of both lists is the same, show it
                                    initialValue:
                                      settlementToApply.childContainerValues &&
                                      settlementToApply.childContainerValues
                                        .length ===
                                        settlementToApply.settlementContainer
                                          .containers.length
                                        ? settlementToApply
                                            .childContainerValues[index]
                                            .percentageFinalVolume
                                        : settlementToApply.finalPercentTank //Else if there is value of final percentage, show it
                                        ? settlementToApply.finalPercentTank
                                        : undefined, //Else, show nothing
                                    rules: [
                                      {
                                        pattern: onlyNumberDecimalPattern,
                                        required: true,
                                        message: 'Introduce un porcentaje',
                                      },
                                    ],
                                  },
                                )(
                                  <InputNumber
                                    min={0.01}
                                    max={100}
                                    placeholder={'Porcentaje'}
                                    style={{
                                      width: '100%',
                                    }}
                                  />,
                                )}
                              </Form.Item>
                            </Col>
                            <p />
                          </Row>
                        );
                      },
                    )}
                </Row>
                <Row>
                  {this.props.isCommentVisible && (
                    <Form.Item>
                      {getFieldDecorator(`comment`, {
                        initialValue: '',
                        rules: [
                          {
                            required: true,
                            message: 'Introduce un comentario',
                          },
                        ],
                      })(
                        <TextArea
                          rows={4}
                          placeholder="Por favor escribe la razón del porcentaje de desviación elevado"
                        />,
                      )}
                    </Form.Item>
                  )}
                </Row>
              </Row>
              {settlementToApply.settlementContainer &&
                settlementToApply.settlementContainer.containerType ===
                  'Autotanque' && (
                  <Row>
                    <Row>
                      <Col
                        span={24}
                        style={{
                          textAlign: 'center',
                          marginTop: '15px',
                        }}
                      >
                        {'Registrar cargas al contenedor:'}
                      </Col>
                    </Row>
                    <Row>
                      <div className="scroll-container">
                        <InfiniteScroll
                          initialLoad={false}
                          pageStart={0}
                          loadMore={(): void => {}}
                          hasMore={false}
                          useWindow={false}
                        >
                          {this.state.purchases.map(
                            (
                              purchase: ContainerPurchase,
                              index: number,
                            ): React.ReactNode => {
                              return (
                                <Row key={index}>
                                  <Col span={10}>
                                    <Form.Item>
                                      {getFieldDecorator(
                                        `purchases[${index}].volume`,
                                        {
                                          initialValue: '',
                                          rules: [
                                            {
                                              pattern: onlyNumberDecimalPattern,
                                              message:
                                                'Introduce una capacidad válida',
                                            },
                                            {
                                              min: 1,
                                            },
                                            {
                                              max: 10,
                                              message: 'Máximo 10 dígitos',
                                            },
                                          ],
                                        },
                                      )(
                                        <Input
                                          type="number"
                                          style={{ width: '100%' }}
                                          placeholder="Litros"
                                        />,
                                      )}
                                    </Form.Item>
                                  </Col>
                                  <Col span={12} offset={1}>
                                    <Form.Item>
                                      {getFieldDecorator(
                                        `purchases[${index}].date`,
                                        {
                                          initialValue: purchase.date,
                                        },
                                      )(
                                        <DatePicker
                                          format="YYYY/MM/DD HH:mm:ss"
                                          showTime={{
                                            defaultValue: moment(
                                              '00:00:00',
                                              'HH:mm:ss',
                                            ),
                                          }}
                                          disabled={this.props.isFetching}
                                          style={{ width: '70%' }}
                                        />,
                                      )}
                                      {this.state.purchases.length > 1 ? (
                                        <Icon
                                          type="minus-circle"
                                          className="dynamic-delete-button"
                                          style={{ margin: '0 5px' }}
                                          onClick={(): void => {
                                            this.removePurchase(index);
                                          }}
                                        />
                                      ) : null}
                                    </Form.Item>
                                  </Col>
                                </Row>
                              );
                            },
                          )}
                        </InfiniteScroll>
                      </div>
                      <Row>
                        <Col span={21} offset={1}>
                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={(): void => {
                                this.addPurchase(this.state.purchases);
                              }}
                              style={{ width: '100%' }}
                            >
                              <Icon type="plus" />
                              {' Agregar fila'}
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Row>
                  </Row>
                )}
            </Form>
            <Row
              className="modal-light-blue-background"
              style={{ padding: '5px 0px' }}
            >
              <Col span={7} offset={1}>
                <Text strong>{'Centro de dist.:'}</Text>
              </Col>
              <Col span={7} offset={1}>
                <Text strong>{'Tipo de servicio:'}</Text>
              </Col>
              <Col span={7} offset={1}>
                <Text strong>{'Unidad:'}</Text>
              </Col>
              <Col span={7} offset={1}>
                {
                  (
                    this.props.distributionCenterName.find(
                      (d: DistributionCenter): boolean =>
                        d.id ===
                        this.props.settlementToApply.distributionCenterId,
                    ) || initDistributionCenter
                  ).name
                }
              </Col>
              <Col span={7} offset={1}>
                {this.props.settlementToApply.serviceType}
              </Col>
              <Col span={7} offset={1}>
                {this.props.settlementToApply.unit}
              </Col>
            </Row>
            <Row>
              {/* Collapse panel which shows sales information grouped by payment conditions */}
              <Collapse
                className="collapse-panel-modal"
                defaultActiveKey={1}
                onChange={(): void =>
                  this.setState({
                    showDetails: !this.state.showDetails,
                  })
                }
              >
                <Collapse.Panel
                  header={
                    this.state.showDetails ? 'Ocultar detalles' : 'Ver detalles'
                  }
                  key="1"
                >
                  <Row style={{ margin: '-16px 4px' }}>
                    <Row className="table-header">
                      <Col span={5}>{'\u00A0'}</Col>
                      <Col span={4} offset={0}>
                        {'Monto'}
                      </Col>
                      <Col span={4} offset={1}>
                        {'Litros'}
                      </Col>
                      <Col span={4} offset={1}>
                        {'Kilos'}
                      </Col>
                      <Col span={4} offset={1}>
                        {'Ticket'}
                      </Col>
                    </Row>
                    {paymentConditions.map(
                      (item, index: number): JSX.Element => (
                        <Row className="row-blue-letters" key={index}>
                          <Col span={5} style={{ textAlign: 'left' }}>
                            {item.title}
                          </Col>
                          <Col span={4} offset={0}>
                            {'$' +
                              toNumber(
                                this.getTotalByPaymentCondition(
                                  item.paymentCondition,
                                ),
                              )}
                          </Col>
                          <Col span={4} offset={1}>
                            {toNumber(
                              this.getTotalLittersByPaymentCondition(
                                item.paymentCondition,
                              ),
                            )}
                          </Col>
                          <Col span={4} offset={1}>
                            {toNumber(
                              this.getTotalLittersByPaymentCondition(
                                item.paymentCondition,
                              ) * 0.54,
                            )}
                          </Col>
                          <Col span={4} offset={1}>
                            {settlementToApply.sales
                              ? settlementToApply.sales.filter(
                                  (s: Sale): boolean =>
                                    s.paymentConditionType ===
                                    item.paymentCondition,
                                ).length
                              : 0}
                          </Col>
                        </Row>
                      ),
                    )}
                    <Row className="row-blue-letters table-header">
                      <Col span={5} style={{ textAlign: 'left' }}>
                        {'Total'}
                      </Col>
                      <Col span={4} offset={0}>
                        {'$' + toNumber(this.calculateTotal())}
                      </Col>
                      <Col span={4} offset={1}>
                        {toNumber(this.calculateTotalLitters())}
                      </Col>
                      <Col span={4} offset={1}>
                        {toNumber(this.calculateTotalLitters() * 0.54)}
                      </Col>
                      <Col span={4} offset={1}>
                        {settlementToApply.sales
                          ? settlementToApply.sales.length
                          : 0}
                      </Col>
                    </Row>
                    <Row className="row-blue-letters table-header">
                      <Col span={5} style={{ textAlign: 'left' }}>
                        {'Total compras'}
                      </Col>
                      <Col span={4} offset={0}>
                        {'$' + toNumber(this.calculatePurchasesTotal())}
                      </Col>
                      <Col span={4} offset={1}>
                        {toNumber(this.calculatePurchasesLitters())}
                      </Col>
                      <Col span={4} offset={1}>
                        {toNumber(this.calculatePurchasesLitters() * 0.54)}
                      </Col>
                      <Col span={4} offset={1}>
                        {settlementToApply.settlementContainer
                          ? settlementToApply.settlementContainer.purchases
                              .length
                          : 0}
                      </Col>
                    </Row>
                  </Row>
                </Collapse.Panel>
              </Collapse>
            </Row>
            <Row>
              {/* Collapse panel which shows sales information grouped by payment ways */}
              <Collapse
                className="collapse-panel-modal"
                onChange={(): void =>
                  this.setState({
                    showPaymentWayDetails: !this.state.showPaymentWayDetails,
                  })
                }
              >
                <Collapse.Panel
                  header={
                    this.state.showPaymentWayDetails
                      ? 'Ocultar detalles por forma de pago'
                      : 'Ver detalles por forma de pago'
                  }
                  key="1"
                >
                  <Row style={{ margin: '-16px 4px' }}>
                    <Row className="table-header">
                      <Col span={5}>{'\u00A0'}</Col>
                      <Col span={4} offset={0}>
                        {'Monto'}
                      </Col>
                      <Col span={4} offset={1}>
                        {'Litros'}
                      </Col>
                      <Col span={4} offset={1}>
                        {'Kilos'}
                      </Col>
                      <Col span={4} offset={1}>
                        {'Ticket'}
                      </Col>
                    </Row>
                    {paymentWays.map(
                      (item, index: number): JSX.Element => (
                        <Row className="row-blue-letters" key={index}>
                          <Col span={5} style={{ textAlign: 'left' }}>
                            {item.title}
                          </Col>
                          <Col span={4} offset={0}>
                            {'$' +
                              toNumber(
                                this.getTotalByPaymentWay(item.paymentWay),
                              )}
                          </Col>
                          <Col span={4} offset={1}>
                            {toNumber(
                              this.getTotalLittersByPaymentWay(item.paymentWay),
                            )}
                          </Col>
                          <Col span={4} offset={1}>
                            {toNumber(
                              this.getTotalLittersByPaymentWay(
                                item.paymentWay,
                              ) * 0.54,
                            )}
                          </Col>
                          <Col span={4} offset={1}>
                            {settlementToApply.sales
                              ? settlementToApply.sales.filter(
                                  (s: Sale): boolean =>
                                    s.paymentWay === item.paymentWay,
                                ).length
                              : 0}
                          </Col>
                        </Row>
                      ),
                    )}
                    <Row className="row-blue-letters table-header">
                      <Col span={5} style={{ textAlign: 'left' }}>
                        {'Total'}
                      </Col>
                      <Col span={4} offset={0}>
                        {'$' + toNumber(this.calculateTotal())}
                      </Col>
                      <Col span={4} offset={1}>
                        {toNumber(this.calculateTotalLitters())}
                      </Col>
                      <Col span={4} offset={1}>
                        {toNumber(this.calculateTotalLitters() * 0.54)}
                      </Col>
                      <Col span={4} offset={1}>
                        {settlementToApply.sales
                          ? settlementToApply.sales.length
                          : 0}
                      </Col>
                    </Row>
                  </Row>
                </Collapse.Panel>
              </Collapse>
            </Row>
            <Row>
              {/* Collapse panel which shows sales information grouped by prices */}
              <Collapse
                className="collapse-panel-modal"
                onChange={(): void =>
                  this.setState({
                    showPricesDetails: !this.state.showPricesDetails,
                  })
                }
              >
                <Collapse.Panel
                  header={
                    this.state.showPricesDetails
                      ? 'Ocultar detalles por precio'
                      : 'Ver detalles por precio'
                  }
                  key="1"
                >
                  <Row style={{ margin: '-16px 4px' }}>
                    <Row className="table-header">
                      <Col span={5} style={{ textAlign: 'left' }}>
                        {'Precio'}
                      </Col>
                      <Col span={4} offset={0}>
                        {'Monto'}
                      </Col>
                      <Col span={4} offset={1}>
                        {'Litros'}
                      </Col>
                      <Col span={4} offset={1}>
                        {'Kilos'}
                      </Col>
                      <Col span={4} offset={1}>
                        {'Ticket'}
                      </Col>
                    </Row>
                    {this.getLittersAndTotalByPrices().map(
                      (item: TotalsByPrice, index: number): JSX.Element => (
                        <Row className="row-blue-letters" key={index}>
                          <Col span={5} style={{ textAlign: 'left' }}>
                            {item.price}
                          </Col>
                          <Col span={4} offset={0}>
                            {'$' + toNumber(item.total)}
                          </Col>
                          <Col span={4} offset={1}>
                            {toNumber(item.totalLitters)}
                          </Col>
                          <Col span={4} offset={1}>
                            {toNumber(item.totalLitters * 0.54)}
                          </Col>
                          <Col span={4} offset={1}>
                            {item.totalTickets}
                          </Col>
                        </Row>
                      ),
                    )}
                    <Row className="row-blue-letters table-header">
                      <Col span={5} style={{ textAlign: 'left' }}>
                        {'Total'}
                      </Col>
                      <Col span={4} offset={0}>
                        {'$' + toNumber(this.calculateTotal())}
                      </Col>
                      <Col span={4} offset={1}>
                        {toNumber(this.calculateTotalLitters())}
                      </Col>
                      <Col span={4} offset={1}>
                        {toNumber(this.calculateTotalLitters() * 0.54)}
                      </Col>
                      <Col span={4} offset={1}>
                        {settlementToApply.sales
                          ? settlementToApply.sales.length
                          : 0}
                      </Col>
                    </Row>
                  </Row>
                </Collapse.Panel>
              </Collapse>
            </Row>
          </Spin>
        </Row>
      </Modal>
    );
  };
}
export default CreateFormStatement;
