/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Hiram Padilla <hiram@ssf.com.mx>, August 2020
 *
 * This file contains the redux types for inventory component
 */

/* Begin a fetch operation action */
export const BEGIN_FETCH = 'BEGIN_FETCH';

/* A fetch operation action has been completed */
export const FETCH_COMPLETED = 'FETCH_COMPLETED';

export const SET_INVENTORY = 'SET_INVENTORY';
export const SET_EQUIPMENTS = 'SET_EQUIPMENTS';
export const SET_CONTAINER_FILTERS = 'SET_CONTAINER_FILTERS';
export const SET_EQUIVALENCES = 'SET_EQUIVALENCES';
export const RESET_EQUIVALENCES = 'RESET_EQUIVALENCES';
export const SET_TOTALS_INDICATORS = 'SET_TOTALS_INDICATORS';

interface BeginFetchAction {
  type: typeof BEGIN_FETCH;
}

interface EndFetchAction {
  type: typeof FETCH_COMPLETED;
}

interface SetInventoryAction {
  type: typeof SET_INVENTORY;
  inventory: {};
}

interface SetEquipmentsAction {
  type: typeof SET_EQUIPMENTS;
  equipments: {};
}

interface SetFiltersAction {
  type: typeof SET_CONTAINER_FILTERS;
  containerFilters: {};
}

interface SetEquivalencesAction {
  type: typeof SET_EQUIVALENCES;
  equivalences: {};
}

interface ResetEquivalences {
  type: typeof RESET_EQUIVALENCES;
}

interface TotalIndicatorsAction {
  type: typeof SET_TOTALS_INDICATORS;
  totalIndicators: {};
}

export type InventoryAction =
  | BeginFetchAction
  | EndFetchAction
  | SetInventoryAction
  | SetEquipmentsAction
  | SetFiltersAction
  | SetEquivalencesAction
  | ResetEquivalences
  | TotalIndicatorsAction;
