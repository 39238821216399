/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Hiram Padilla <hiram@ssf.com.mx>, September 2020
 *
 * This component shows a form to create or edit a cubic capacity
 */
import React, { Component } from 'react';
import { FormComponentProps } from 'antd/lib/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Modal, Row, Col, InputNumber, Form, Input, Button, Icon } from 'antd';
import { Container, Equivalence } from 'types/type';
import { onlyNumberDecimalPattern } from 'commons/regularExpressions';
import Text from 'antd/lib/typography/Text';
import { toNumber } from 'commons/numbersFormat';
import InfiniteScroll from 'react-infinite-scroller';
import './../styles.css';
import { initEquivalence } from 'commons/initTypes';

interface CounterProps {
  isEquivalenceVisible: boolean;
  onCancel: Function;
  onCreate: Function;
  form: WrappedFormUtils;
  currentContainer: Container | undefined;
  currentEquivalences: Equivalence[];
}

class EquivalencesFormComponent extends Component<
  CounterProps & FormComponentProps
> {
  public state = {
    equivalences: [initEquivalence],
  };

  //Get the array of fields names, according to their index in the list
  private fieldsName = (index: number): string[] => {
    return [
      `equivalences[${index}].id`,
      `equivalences[${index}].containerId`,
      `equivalences[${index}].percentage`,
      `equivalences[${index}].volume`,
    ];
  };

  public addEquivalence = (equivalences: Equivalence[]): void => {
    let capacities = equivalences;
    capacities.push(initEquivalence);
    this.setState({ equivalences: capacities });
  };

  public removeEquivalence = (index: number): void => {
    let indexesKept: number[] = [];

    //Remove the item by index and save the index from kept rows into an array
    this.state.equivalences.filter((item, filterIndex: number): boolean => {
      if (filterIndex !== index) indexesKept.push(filterIndex);
      return filterIndex !== index;
    });
    let newCapacities: Equivalence[] = [];

    //Get the values from fields not removed and update the fields
    indexesKept.map((value: number): number => {
      const capacity = this.props.form.getFieldsValue(this.fieldsName(value))
        .equivalences[value];
      newCapacities.push(capacity);
      return value;
    });
    //Update the form values
    this.props.form.setFieldsValue({ equivalences: newCapacities });
    this.setState({ equivalences: newCapacities });
  };

  public componentDidUpdate = (prevProps: CounterProps): void => {
    const { currentEquivalences } = this.props;
    if (prevProps.currentEquivalences === currentEquivalences) return;
    if (currentEquivalences.length > 0)
      this.setState({ equivalences: currentEquivalences });
  };

  public render = (): JSX.Element => {
    const {
      isEquivalenceVisible,
      onCancel,
      onCreate,
      form,
      currentContainer,
    } = this.props;
    const { getFieldDecorator } = form;
    const containerId = currentContainer ? currentContainer.id : '';

    return (
      <Modal
        width={550}
        visible={isEquivalenceVisible}
        title={
          'Definir cubicaje para ' +
          (currentContainer ? currentContainer.name : 'el contenedor')
        }
        okText="Guardar"
        cancelText="Cancelar"
        onCancel={onCancel()}
        onOk={onCreate()}
        centered
      >
        <Row
          style={{
            background: '#f1f7ff',
            marginTop: '-24px',
            marginLeft: '-24px',
            marginRight: '-24px',
          }}
        >
          <Col span={8} style={{ padding: '20px 15px' }}>
            <Text strong>{'Tipo de contenedor:'}</Text>
            <Text>
              <h3>{currentContainer ? currentContainer.type : ''}</h3>
            </Text>
          </Col>
          <Col span={8} style={{ padding: '20px 15px' }}>
            <Text strong>{'Capacidad:'}</Text>
            <Text>
              <h3>
                {toNumber(currentContainer ? currentContainer.capacity : 0)}
                {' L'}
              </h3>
            </Text>
          </Col>
          <Col span={8} style={{ padding: '20px 15px' }}>
            <Text strong>{'% Vol. de tolerancia:'}</Text>
            <Text>
              <h3>
                {toNumber(currentContainer ? currentContainer.tolerance : 0)}
                {' %'}
              </h3>
            </Text>
          </Col>
        </Row>
        <Row style={{ marginTop: '10px' }}>
          {'Define los pares de equivalencias que necesites'}
        </Row>
        <div className="infinite-container">
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={(): void => {}}
            hasMore={false}
            useWindow={false}
          >
            {this.state.equivalences.map(
              (value: Equivalence, index: number): React.ReactNode => {
                return (
                  <Row key={index}>
                    <Col span={0}>
                      <Form.Item label="Id">
                        {getFieldDecorator(`equivalences[${index}].id`, {
                          initialValue: value ? value.id : '',
                          rules: [],
                        })(<Input />)}
                      </Form.Item>
                    </Col>
                    <Col span={0}>
                      <Form.Item label="containerId">
                        {getFieldDecorator(
                          `equivalences[${index}].containerId`,
                          {
                            initialValue: containerId,
                            rules: [],
                          },
                        )(<Input />)}
                      </Form.Item>
                    </Col>
                    <Col span={10} offset={1}>
                      <Form.Item style={{ marginBottom: '15px' }}>
                        {getFieldDecorator(
                          `equivalences[${index}].percentage`,
                          {
                            initialValue: value ? value.percentage : 0,
                            rules: [
                              {
                                pattern: onlyNumberDecimalPattern,
                                required: true,
                                message: 'Introduce un porcentaje',
                              },
                            ],
                          },
                        )(
                          <InputNumber
                            min={0}
                            max={100}
                            style={{ width: '100%' }}
                            formatter={(value): string => `${value}%`}
                            parser={(value): string =>
                              value ? value.replace('%', '') : ''
                            }
                          />,
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={11} offset={1}>
                      <Form.Item style={{ marginBottom: '15px' }}>
                        {getFieldDecorator(`equivalences[${index}].volume`, {
                          initialValue: value ? value.volume : 0,
                          rules: [
                            {
                              pattern: onlyNumberDecimalPattern,
                              required: true,
                              message: 'Introduce una cantidad',
                            },
                          ],
                        })(
                          <InputNumber
                            min={0}
                            style={{ width: '89%' }}
                            formatter={(value): string => `${value}L`}
                            parser={(value): string =>
                              value ? value.replace('L', '') : ''
                            }
                          />,
                        )}
                        {this.state.equivalences.length > 1 ? (
                          <Icon
                            type="minus-circle"
                            className="dynamic-delete-button"
                            style={{ margin: '0 5px' }}
                            onClick={(): void => {
                              this.removeEquivalence(index);
                            }}
                          />
                        ) : null}
                      </Form.Item>
                    </Col>
                  </Row>
                );
              },
            )}
          </InfiniteScroll>
        </div>
        <Row>
          <Col span={21} offset={1}>
            <Form.Item>
              <Button
                type="dashed"
                onClick={(): void => {
                  this.addEquivalence(this.state.equivalences);
                }}
                style={{ width: '100%' }}
              >
                <Icon type="plus" />
                {' Agregar equivalencia'}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    );
  };
}

export default EquivalencesFormComponent;
