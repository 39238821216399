/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Hiram Padilla <hiram@ssf.com.mx>, March 2021
 *
 * This component shows a form to edit a Statement register
 */

import { Col, DatePicker, Input, Modal, Row, Select } from 'antd';
import Form, { FormComponentProps, WrappedFormUtils } from 'antd/lib/form/Form';
import { paymentWayEs } from 'commons/components/PaymentEs';
import { rfcformat } from 'commons/regularExpressions';
import { DATE_FORMAT } from 'commons/services';
import moment from 'moment';
import React, { Component, ReactNode } from 'react';
import { Sale, Statement } from 'types/type';
import { isAllowed } from 'security';
import { Permissions as P } from 'commons/permissions';

interface ReceivedProps {
  isVisible: boolean;
  onCancel: Function;
  onCreate: Function;
  isFetching: boolean;
  form: WrappedFormUtils;
  statement: Statement;
  sale: Sale;
}

class EditStatementComponent extends Component<
  ReceivedProps & FormComponentProps
> {
  public state = {
    paymentCondition: '',
  };

  public render = (): ReactNode => {
    const { isVisible, onCreate, onCancel, form, statement } = this.props;
    const { sale, isFetching } = this.props;
    return (
      <Modal
        visible={isVisible}
        title="Editar cargo"
        width={550}
        centered
        okText="Guardar"
        cancelText="Cancelar"
        onOk={onCreate()}
        onCancel={onCancel()}
      >
        <Row>
          <Col span={11} offset={1}>
            <Form.Item label="Fecha">
              {form.getFieldDecorator(`movementDate`, {
                initialValue: moment(),
              })(
                <DatePicker
                  format={DATE_FORMAT}
                  disabled
                  style={{ width: '100%' }}
                />,
              )}
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item label="Referencia">
              {form.getFieldDecorator(`reference`, {
                initialValue: statement.reference,
              })(<Input placeholder="Referencia" disabled />)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={11} offset={1}>
            <Form.Item label="RFC">
              {form.getFieldDecorator(`customerInvoiceRfc`, {
                initialValue: statement.customerInvoiceRfc
                  ? statement.customerInvoiceRfc
                  : 'N/A',
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: 'Ingresa un RFC',
                  },
                  {
                    pattern: rfcformat,
                    message: 'Ingresa un rfc válido',
                  },
                  {
                    max: 20,
                    whitespace: true,
                    message: 'No se permiten mas de 20 caracteres',
                  },
                ],
              })(
                <Input
                  placeholder="RFC"
                  disabled={isFetching || !isAllowed([P.EDIT_RFC_SAVED])}
                />,
              )}
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item label="Condición de pago">
              {form.getFieldDecorator(`paymentCondition`, {
                initialValue: sale.paymentConditionType,
                rules: [
                  {
                    required: true,
                    message: 'Seleccione una condición de pago',
                  },
                ],
              })(
                <Select
                  style={{ width: '100%' }}
                  onChange={(value: string): void =>
                    this.setState({ paymentCondition: value })
                  }
                  disabled
                >
                  <Select.Option value="">Seleccionar</Select.Option>
                  <Select.Option value="CASH">Contado</Select.Option>
                  <Select.Option value="CREDIT">Crédito</Select.Option>
                </Select>,
              )}
            </Form.Item>
          </Col>
        </Row>
        {((this.state.paymentCondition === '' &&
          sale.paymentConditionType === 'CASH') ||
          this.state.paymentCondition === 'CASH') && (
          <Row>
            <Col span={23} offset={1}>
              <Form.Item label="Forma de pago">
                {form.getFieldDecorator(`paymentWay`, {
                  initialValue: paymentWayEs(sale.paymentWay),
                  rules: [
                    {
                      required: true,
                      message: 'Seleccione una forma de pago',
                    },
                  ],
                })(
                  <Select
                    style={{ width: '100%' }}
                    disabled={
                      isFetching || !isAllowed([P.EDIT_PAYMENT_WAY_SAVED])
                    }
                  >
                    <Select.Option value="">Seleccionar</Select.Option>
                    <Select.Option value="Efectivo">Efectivo</Select.Option>
                    <Select.Option value="Tarjeta de crédito">
                      Tarjeta de crédito
                    </Select.Option>
                    <Select.Option value="Tarjeta de débito">
                      Tarjeta de Débito
                    </Select.Option>
                    <Select.Option value="Cheque">Cheque</Select.Option>
                    <Select.Option value="Transferencia">
                      Transferencia
                    </Select.Option>
                  </Select>,
                )}
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={11} offset={1}>
            <Form.Item label="Monto">
              {form.getFieldDecorator(`amount`, {
                initialValue: statement.amount,
              })(<Input placeholder="Monto" disabled />)}
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item label="Saldo">
              {form.getFieldDecorator(`balance`, {
                initialValue: statement.balance,
              })(<Input placeholder="Saldo" disabled />)}
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    );
  };
}

export default EditStatementComponent;
