import React from 'react';
import { Layout, Menu, Icon, Spin, Col, Row, Modal, List, Input } from 'antd';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import './styles.css';
import styles from './styles';
import * as H from 'history';
import { MenuItem, SubmenuItems } from '../../../../types/type';
import moment from 'moment';

import { enterpriceLogo as centralGas } from 'boot/config';
import {
  isAllowed,
  getIncognitMode,
  addIncognitMode,
  removeIncognitMode,
} from 'security/index';
import { ConfigProvider } from 'antd';
import esEs from 'antd/es/locale/es_ES';

interface PathParamsType {
  param1: string;
}
const { Content, Footer } = Layout;
type Props = RouteComponentProps<PathParamsType> & {
  menu: MenuItem[];
  loading?: boolean;
  location: H.Location;
};
class MainLayout extends React.Component<Props> {
  public state = {
    collapsed: true,
    visible: false,
    modal2Visible: false,
    password: '',
  };
  // Function that helps persist the menu focus after a refresh.
  private getSubMenuNameFromURL = (pathname: string): string[] => {
    const stringWithOutFirstSlash = pathname.slice(1);

    // Returns an array with just one item.
    return stringWithOutFirstSlash.indexOf('/') === -1
      ? [stringWithOutFirstSlash]
      : [
          stringWithOutFirstSlash.substring(
            0,
            stringWithOutFirstSlash.indexOf('/'),
          ),
        ];
  };

  private renderMenuItem = (item: MenuItem): JSX.Element => {
    if (!isAllowed(item.permissions)) {
      return <></>;
    }
    if (item.key === 'C' && getIncognitMode() !== '1') {
      return <></>;
    }
    if (item.visible) {
      if (item.subMenu) {
        const subMenuItem = item.subMenu;

        return (
          <Menu.SubMenu key={item.key} title={item.title} style={item.style}>
            <Menu.ItemGroup title={subMenuItem.subTitle}>
              {isAllowed(item.permissions) &&
                item.visible &&
                subMenuItem.subMenuItems.map(
                  (subMenu: SubmenuItems): JSX.Element =>
                    this.renderNormalMenuItem(subMenu),
                )}
            </Menu.ItemGroup>
          </Menu.SubMenu>
        );
      }
      return this.renderNormalMenuItem(item);
    }
    return <></>;
  };
  private setvisiblemodal(modal2Visible: boolean): void {
    if (getIncognitMode() !== '1') {
      this.setState({ modal2Visible });
    } else {
      removeIncognitMode();
      window.location.reload();
    }
  }

  public okmodal(modal2Visible: boolean): void {
    var check = moment();

    var month: string = check.format('M');
    var day: string = check.format('D');
    var year: string = check.format('YYYY');
    const total: string = (+day * +month * +year).toFixed(0);
    if (this.state.password === total) {
      addIncognitMode();
      this.setState({ modal2Visible });
      window.location.reload();
    }
  }

  private renderNormalMenuItem = (
    item: MenuItem | SubmenuItems,
  ): JSX.Element => {
    if ((item as MenuItem).permissions) {
      let permissions = (item as MenuItem).permissions;
      if (!isAllowed(permissions)) {
        return <></>;
      }
    }
    return (
      <Menu.Item
        className="item-menu-central"
        key={item.key}
        style={item.style}
        onClick={
          item.key === 'Ingognit'
            ? (): void => this.setvisiblemodal(true)
            : undefined
        }
      >
        {item.key !== 'Ingognit' ? (
          <Link to={item.path} id={item.key}>
            <div id={item.key} />
            <span>{item.title}</span>
          </Link>
        ) : (
          <div>
            <div id={item.key} />
            <span>{item.title}</span>
          </div>
        )}
      </Menu.Item>
    );
  };

  private showModal = (): void => {
    this.setState({
      visible: true,
    });
  };

  private handleOk = (): void => {
    this.setState({
      visible: false,
    });
  };

  private handleCancel = (): void => {
    this.setState({
      visible: false,
    });
  };

  public onChangeinput = (value: string): void => {
    this.setState({ password: value });
  };

  private isIncognitModeActive = (item: MenuItem): boolean => {
    if (item.key !== 'C') {
      return true;
    }
    return getIncognitMode() === '1';
  };

  public render = (): JSX.Element => {
    const { children, menu, loading, location } = this.props;

    if (!localStorage.getItem('token'))
      return (
        <Layout>
          <Layout.Content>
            <ConfigProvider locale={esEs}>{children}</ConfigProvider>
          </Layout.Content>
          <Layout.Footer style={{ textAlign: 'center' }}>
            ©2019 projects
          </Layout.Footer>
        </Layout>
      );
    return (
      <Layout>
        <Layout.Header style={styles.header}>
          <Row type="flex" justify="space-around">
            <Col
              xs={{ span: 11, offset: 0 }}
              sm={{ span: 11, offset: 0 }}
              md={{ span: 11, offset: 0 }}
              lg={{ span: 0, offset: 0 }}
              xl={{ span: 0, offset: 0 }}
              xxl={{ span: 0, offset: 0 }}
            >
              <img src={centralGas} className="logo" alt="Logo" />
            </Col>
            <Col
              xs={{ span: 11, offset: 0 }}
              sm={{ span: 11, offset: 0 }}
              md={{ span: 11, offset: 0 }}
              lg={{ span: 0, offset: 0 }}
              xl={{ span: 0, offset: 0 }}
              xxl={{ span: 0, offset: 0 }}
            >
              <h1 style={{ color: '#113c71' }}>Central de gas</h1>
            </Col>
            <Col
              xs={{ span: 2, offset: 0 }}
              sm={{ span: 2, offset: 0 }}
              md={{ span: 2, offset: 0 }}
              lg={{ span: 0, offset: 0 }}
              xl={{ span: 0, offset: 0 }}
              xxl={{ span: 0, offset: 0 }}
            >
              <Icon
                type="unordered-list"
                style={{ color: '#113c71' }}
                onClick={this.showModal}
              />

              <Modal
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                okButtonProps={{ disabled: true, type: 'link' }}
                cancelButtonProps={{ type: 'link' }}
                okText={<Icon type="stop" />}
                cancelText="Cancelar"
                style={{ width: '100%', height: '100%', textAlign: 'center' }}
                centered
              >
                <List
                  style={{ textAlign: 'center' }}
                  size={'large'}
                  bordered
                  dataSource={menu}
                  renderItem={(item: MenuItem): JSX.Element => (
                    <Row>
                      {isAllowed(item.permissions) &&
                        item.visible &&
                        this.isIncognitModeActive(item) && (
                          <List.Item style={{ textAlign: 'center' }}>
                            <Link
                              to={item.path}
                              onClick={this.handleCancel}
                              style={{ color: 'black', textAlign: 'center' }}
                            >
                              <div style={{ textAlign: 'center' }}>
                                {item.title}
                              </div>
                            </Link>
                            {isAllowed(item.permissions) &&
                            item.visible &&
                            item.subMenu ? (
                              <Link
                                to={
                                  item.subMenu
                                    ? item.subMenu.subMenuItems[0].path
                                    : '/'
                                }
                                onClick={this.handleCancel}
                                style={{ color: 'black', textAlign: 'center' }}
                              >
                                <div>
                                  {item.subMenu
                                    ? item.subMenu.subMenuItems[0].title
                                    : null}
                                </div>
                              </Link>
                            ) : null}
                          </List.Item>
                        )}
                    </Row>
                  )}
                />
              </Modal>
            </Col>
            <Modal
              title="Modo Desarrollador"
              centered
              visible={this.state.modal2Visible}
              onOk={(): void => this.okmodal(false)}
              onCancel={(): void => this.setvisiblemodal(false)}
            >
              <Input
                placeholder="password"
                value={this.state.password}
                type="password"
                onChange={({ target: { value } }): void =>
                  this.onChangeinput(value)
                }
                style={{ width: 200 }}
              />
            </Modal>
            <Col
              xs={{ span: 0, offset: 0 }}
              sm={{ span: 0, offset: 0 }}
              md={{ span: 0, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
              xxl={{ span: 24, offset: 0 }}
            >
              <Menu
                className="header-menu"
                style={styles.menu}
                theme={getIncognitMode() === '1' ? 'dark' : 'light'}
                mode="horizontal"
                selectedKeys={this.getSubMenuNameFromURL(location.pathname)}
              >
                {menu.map(
                  (item: MenuItem): JSX.Element => this.renderMenuItem(item),
                )}
              </Menu>
            </Col>
          </Row>
        </Layout.Header>

        <Layout>
          <Content
            style={{
              margin: '24px 16px 0',
              minHeight: '50rem',
              backgroundColor: '#f0f2f5',
            }}
          >
            {loading && (
              <div
                className="justify-center"
                style={{ backgroundColor: '#f0f2f5' }}
              >
                <Spin
                  indicator={<Icon type="loading" style={styles.spin} spin />}
                />
              </div>
            )}

            <div>
              <ConfigProvider locale={esEs}>{children}</ConfigProvider>
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>©2019 projects</Footer>
        </Layout>
      </Layout>
    );
  };
}

export default withRouter(MainLayout);
