import React, { Component, FormEvent } from 'react';
import { Row, Col, Input, Icon, Button, notification } from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import { connect } from 'react-redux';
import { RootState } from 'store/configureStore';
import { loginCustomer } from '../../login/redux/actions';
import { AccessToken } from '../../login/redux/reducers';
import { Redirect } from 'react-router';
import { parseApiError } from 'commons/error';

interface DispatchProps {
  loginCustomer: (username: string, password: string) => void;
}
interface StateProps {
  accessToken: AccessToken;
}
type Props = FormComponentProps & StateProps & DispatchProps;

class Login extends Component<Props> {
  private handleOnSubmit = (e: FormEvent): void => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(
      async (err, values): Promise<void> => {
        if (!err) {
          try {
            await this.props.loginCustomer(values.username, values.password);
            window.location.reload();
          } catch (err) {
            notification.error(parseApiError(err));
          }
        }
      },
    );
  };
  public render = (): React.ReactNode => {
    const { getFieldDecorator } = this.props.form;

    if (localStorage.getItem('token')) return <Redirect to="/salesCustomer" />;
    return (
      <Row>
        <Form onSubmit={this.handleOnSubmit} className="login-form">
          <Col
            xs={12}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            xxl={3}
            className="align-center-col"
          >
            <Form.Item hasFeedback>
              {getFieldDecorator('username', {
                rules: [{ required: true, message: 'Ingresa un codigo' }],
              })(
                <Input
                  placeholder="Codigo"
                  prefix={<Icon type="mail" style={{ color: '#a7c2e4' }} />}
                />,
              )}
            </Form.Item>
          </Col>
          <Col span={1}>
            <div>&nbsp;</div>
          </Col>
          <Col
            xs={12}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            xxl={3}
            className="align-center-col"
          >
            <Form.Item hasFeedback>
              {getFieldDecorator('password', {
                rules: [{ required: true, message: 'Ingresa una contraseña' }],
              })(
                <Input
                  placeholder="Contraseña"
                  type="password"
                  prefix={<Icon type="lock" style={{ color: '#a7c2e4' }} />}
                />,
              )}
            </Form.Item>
          </Col>
          <Col
            xs={1}
            sm={2}
            md={2}
            lg={3}
            xl={3}
            xxl={3}
            className="align-center-col"
          >
            <Button
              className="button-invoice-serch-style"
              type="primary"
              htmlType="submit"
              id="search_sale_button"
            >
              Iniciar Sesión
            </Button>
          </Col>
        </Form>
      </Row>
    );
  };
}

const mapStateToProps = (states: RootState): StateProps => {
  return {
    accessToken: states.loginState.accessToken,
  };
};

const mapDispatchToProps = {
  loginCustomer,
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create()(Login));
