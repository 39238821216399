// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toNumber = (value: number, zeroWhenNull?: boolean): any => {
  if (value || value === 0 || value === 0.0) {
    return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

  if (zeroWhenNull) {
    return '0.00';
  }

  return '';
};
