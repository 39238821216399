/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Ricardo Sansores <ricardo@ssf.mx>, May 2019
 * Edit by David Canul, 27 may 2019
 *
 */

import React, { Component, FormEvent } from 'react';
import { connect } from 'react-redux';
import {
  editCompany,
  getCompany,
  cleanCompany,
  page,
  getDistributionCenter,
} from '../redux/actions';
import Form, { FormComponentProps } from 'antd/lib/form';
import { RootState } from 'store/configureStore';
import {
  Card,
  Button,
  Input,
  Select,
  Col,
  Upload,
  Icon,
  Checkbox,
  InputNumber,
} from 'antd';
import '../style.css';
import { Company, DistributionCenter, Files as filesType } from 'types/type';
import { rfcformat, postalCodePattern } from 'commons/regularExpressions';
import { regimenFiscal } from 'commons/components/regimenFiscal';
import { RouteComponentProps } from 'react-router';
import { Popups } from 'commons/components/popups/popups';
import { Link } from 'react-router-dom';
import { isEmpty } from 'commons/functions';

interface DispatchProps {
  editCompany: (company: Company, files: filesType) => void;
  getCompany: (company: string) => void;
  cleanCompany: () => void;
  page: (page: number) => void;
  getDistributionCenter: () => void;
}

interface HomeRouterProps {
  id: string;
}

interface StateProps {
  company: Company;
  distributionCenter: DistributionCenter[];
  isFetching: boolean;
}

type Props = FormComponentProps &
  StateProps &
  DispatchProps &
  RouteComponentProps<HomeRouterProps>;

/**
 * FIXME describe the porpouse of this component
 */
class CompanyFormComponent extends Component<Props> {
  public state = {
    KeyList: new File([''], ''),
    CerList: new File([''], ''),
  };

  public handleBeforeUploadKey = (file: File): boolean => {
    this.setState({ KeyList: file });
    return false;
  };
  private handleRemoveKey = (): void => {
    this.setState({ KeyList: new File([''], '') });
  };

  public handleBeforeUploadCer = (file: File): boolean => {
    this.setState({ CerList: file });
    return false;
  };
  private handleRemoveCer = (): void => {
    this.setState({ CerList: new File([''], '') });
  };

  public uploadButton: JSX.Element = (
    <div>
      <Button>
        <Icon type="upload" /> Subir
      </Button>
    </div>
  );

  public componentWillUnmount(): void {
    this.props.cleanCompany();
  }
  public componentDidMount(): void {
    const { id } = this.props.match.params;
    if (id !== '0') {
      this.props.form.resetFields();
      this.props.getCompany(id);
    }
    this.props.getDistributionCenter();
  }
  public conextionErrorObject = {
    response: {
      status: 400,
      data: {
        message: 'Es obligatorio seleccionar todas las credenciales',
      },
    },
  };
  private handleOnSubmit = (e: FormEvent): void => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(
      async (err, values): Promise<void> => {
        console.log('values ---> ', values);
        const { CerList, KeyList } = this.state;
        const { id } = this.props.match.params;
        if (!err) {
          try {
            if (id !== '0') {
              const company = { ...values, id };
              let files;
              if (
                CerList.size < 1 ||
                KeyList.size < 1 ||
                company.password === ''
              ) {
                files = {
                  certificate: new File([''], ''),
                  privateKey: new File([''], ''),
                };
              } else {
                files = { certificate: CerList, privateKey: KeyList };
              }
              await this.props.editCompany(company, files);
            } else {
              const company = { ...values, id: null };
              const files = { certificate: CerList, privateKey: KeyList };
              await this.props.editCompany(company, files);
            }
            this.props.history.push('/enterprice');
          } catch (error) {
            Popups.notifyException(error);
          }
        }
      },
    );
  };
  public render = (): JSX.Element => {
    const { company, isFetching } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Card>
        <Form onSubmit={this.handleOnSubmit}>
          <Card
            className={'customer-card'}
            title={'Información de Facturación'}
          >
            <Col
              xs={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              md={{ span: 23, offset: 1 }}
              lg={{ span: 11, offset: 1 }}
              xl={{ span: 11, offset: 1 }}
              xxl={{ span: 11, offset: 1 }}
            >
              <Form.Item hasFeedback label="Nombre">
                {getFieldDecorator('name', {
                  initialValue: company ? company.name : '',
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: 'Ingresa un nombre de empresa',
                    },
                    {
                      max: 100,
                      whitespace: true,
                      message: 'No se permiten mas de 100 caracteres',
                    },
                  ],
                })(<Input size="large" placeholder="nombre" />)}
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              md={{ span: 23, offset: 1 }}
              lg={{ span: 11, offset: 1 }}
              xl={{ span: 11, offset: 1 }}
              xxl={{ span: 11, offset: 1 }}
            >
              <Form.Item hasFeedback label="Nombre Comercial">
                {getFieldDecorator('comercialName', {
                  initialValue: company ? company.comercialName : '',
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: 'Ingresa un nombre comercial',
                    },
                    {
                      max: 100,
                      whitespace: true,
                      message: 'No se permiten mas de 100 caracteres',
                    },
                  ],
                })(<Input size="large" placeholder="Nombre comercial" />)}
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              md={{ span: 23, offset: 1 }}
              lg={{ span: 11, offset: 1 }}
              xl={{ span: 11, offset: 1 }}
              xxl={{ span: 11, offset: 1 }}
            >
              <Form.Item hasFeedback label="RFC">
                {getFieldDecorator('rfc', {
                  initialValue: company ? company.rfc : '',
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: 'Ingresa un RFC',
                    },
                    {
                      pattern: rfcformat,
                      message: 'Ingresa un rfc válido',
                    },
                    {
                      max: 20,
                      whitespace: true,
                      message: 'No se permiten mas de 20 caracteres',
                    },
                  ],
                })(<Input size="large" placeholder="RFC" />)}
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              md={{ span: 23, offset: 1 }}
              lg={{ span: 11, offset: 1 }}
              xl={{ span: 11, offset: 1 }}
              xxl={{ span: 11, offset: 1 }}
            >
              <Form.Item hasFeedback label="Régimen fiscal">
                {getFieldDecorator('fiscalRegime', {
                  initialValue: company ? company.fiscalRegime : '',
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: 'Ingresa un nombre de empresa',
                    },
                    {
                      max: 100,
                      whitespace: true,
                      message: 'No se permiten mas de 100 caracteres',
                    },
                  ],
                })(
                  <Select showSearch size="large">
                    {regimenFiscal.map(
                      (item, index): React.ReactNode => (
                        <Select.Option key={item.key} value={item.key}>
                          <div id={'reg_' + index.toString()}> {item.name}</div>
                        </Select.Option>
                      ),
                    )}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              md={{ span: 23, offset: 1 }}
              lg={{ span: 11, offset: 1 }}
              xl={{ span: 11, offset: 1 }}
              xxl={{ span: 11, offset: 1 }}
            >
              <Form.Item hasFeedback label="Folio de factura">
                {getFieldDecorator('invoiceFolio', {
                  initialValue: company ? company.invoiceFolio : 1,
                  rules: [],
                })(
                  <InputNumber
                    disabled={true}
                    size="large"
                    placeholder="folio"
                  />,
                )}
              </Form.Item>
            </Col>
          </Card>
          <p />
          <Card className={'customer-card'} title={'Domicilio'}>
            <Col
              xs={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              md={{ span: 23, offset: 1 }}
              lg={{ span: 11, offset: 1 }}
              xl={{ span: 11, offset: 1 }}
              xxl={{ span: 11, offset: 1 }}
            >
              <Form.Item hasFeedback label="Calle">
                {getFieldDecorator('street', {
                  initialValue: company ? company.street : '',
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: 'Ingresa una calle',
                    },
                    {
                      max: 100,
                      whitespace: true,
                      message: 'No se permiten mas de 100 caracteres',
                    },
                  ],
                })(<Input size="large" placeholder="calle" />)}
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              md={{ span: 23, offset: 1 }}
              lg={{ span: 11, offset: 1 }}
              xl={{ span: 11, offset: 1 }}
              xxl={{ span: 11, offset: 1 }}
            >
              <Form.Item hasFeedback label="Número exterior">
                {getFieldDecorator('externalNumber', {
                  initialValue: company ? company.externalNumber : '',
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: 'Ingresa un número exterior',
                    },
                    {
                      max: 100,
                      whitespace: true,
                      message: 'No se permiten mas de 100 caracteres',
                    },
                  ],
                })(<Input size="large" placeholder="Número exterior" />)}
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              md={{ span: 23, offset: 1 }}
              lg={{ span: 11, offset: 1 }}
              xl={{ span: 11, offset: 1 }}
              xxl={{ span: 11, offset: 1 }}
            >
              <Form.Item hasFeedback label="Número interior">
                {getFieldDecorator('internalNumber', {
                  initialValue: company ? company.internalNumber : '',
                  rules: [
                    {
                      whitespace: true,
                      message: 'Ingresa un municipio',
                    },
                    {
                      max: 100,
                      whitespace: true,
                      message: 'No se permiten mas de 100 caracteres',
                    },
                  ],
                })(<Input size="large" placeholder="número Interior" />)}
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              md={{ span: 23, offset: 1 }}
              lg={{ span: 11, offset: 1 }}
              xl={{ span: 11, offset: 1 }}
              xxl={{ span: 11, offset: 1 }}
            >
              <Form.Item hasFeedback label="Código postal">
                {getFieldDecorator('zipCode', {
                  initialValue: company ? company.zipCode : '',
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: 'Ingresa un código postal',
                    },
                    {
                      max: 5,
                      whitespace: true,
                      message: 'El codigo postal solo tiene 5 numeros',
                    },
                    {
                      pattern: postalCodePattern,
                      message: 'Ingresa un código postal Válido',
                    },
                  ],
                })(<Input size="large" placeholder="código postal" />)}
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              md={{ span: 23, offset: 1 }}
              lg={{ span: 11, offset: 1 }}
              xl={{ span: 11, offset: 1 }}
              xxl={{ span: 11, offset: 1 }}
            >
              <Form.Item hasFeedback label="Colonia">
                {getFieldDecorator('colony', {
                  initialValue: company ? company.colony : '',
                  rules: [
                    { required: true, message: 'Ingresa una colonia' },
                    {
                      max: 100,
                      whitespace: true,
                      message: 'No se permiten mas de 100 caracteres',
                    },
                  ],
                })(<Input size="large" placeholder="colonia" />)}
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              md={{ span: 23, offset: 1 }}
              lg={{ span: 11, offset: 1 }}
              xl={{ span: 11, offset: 1 }}
              xxl={{ span: 11, offset: 1 }}
            >
              <Form.Item hasFeedback label="Ciudad">
                {getFieldDecorator('city', {
                  initialValue: company ? company.city : '',
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: 'Ingresa una ciudad',
                    },
                  ],
                })(<Input size="large" placeholder="ciudad" />)}
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              md={{ span: 23, offset: 1 }}
              lg={{ span: 11, offset: 1 }}
              xl={{ span: 11, offset: 1 }}
              xxl={{ span: 11, offset: 1 }}
            >
              <Form.Item hasFeedback label="Municipio">
                {getFieldDecorator('municipality', {
                  initialValue: company ? company.municipality : '',
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: 'Ingresa un municipio',
                    },
                    {
                      max: 100,
                      whitespace: true,
                      message: 'No se permiten mas de 100 caracteres',
                    },
                  ],
                })(<Input size="large" placeholder="municipio" />)}
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              md={{ span: 23, offset: 1 }}
              lg={{ span: 11, offset: 1 }}
              xl={{ span: 11, offset: 1 }}
              xxl={{ span: 11, offset: 1 }}
            >
              <Form.Item hasFeedback label="Estado">
                {getFieldDecorator('state', {
                  initialValue: company ? company.state : '',
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: 'Ingresa un estado',
                    },
                    {
                      max: 100,
                      whitespace: true,
                      message: 'No se permiten mas de 100 caracteres',
                    },
                  ],
                })(<Input size="large" placeholder="Estado" />)}
              </Form.Item>
            </Col>
          </Card>
          <p />
          <Card className={'customer-card'} title={'Imagen'}>
            <Col
              xs={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              md={{ span: 23, offset: 1 }}
              lg={{ span: 11, offset: 1 }}
              xl={{ span: 11, offset: 1 }}
              xxl={{ span: 11, offset: 1 }}
            >
              <Form.Item hasFeedback label="Enlace del logo">
                {getFieldDecorator('logoUrl', {
                  initialValue: company ? company.logoUrl : '',
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: 'Ingresa la ruta del logo',
                    },
                    {
                      max: 100,
                      whitespace: true,
                      message: 'No se permiten mas de 100 caracteres',
                    },
                  ],
                })(<Input size="large" placeholder="enlace del Logo" />)}
              </Form.Item>
            </Col>
          </Card>
          <p />
          <Card className={'customer-card'} title={'Centro de distribución'}>
            <Col
              xs={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              md={{ span: 23, offset: 1 }}
              lg={{ span: 11, offset: 1 }}
              xl={{ span: 11, offset: 1 }}
              xxl={{ span: 11, offset: 1 }}
            >
              <Form.Item hasFeedback label="Centro de distribución G4S">
                {getFieldDecorator('distributionCenterId', {
                  initialValue: company ? company.distributionCenterId : '',
                  rules: [],
                })(
                  <Select showSearch size="large">
                    {this.props.distributionCenter.map(
                      (item, index): React.ReactNode => (
                        <Select.Option key={item.id} value={item.id}>
                          <div id={'dist_' + index.toString()}>
                            {' '}
                            {item.name}
                          </div>
                        </Select.Option>
                      ),
                    )}
                  </Select>,
                )}
              </Form.Item>
            </Col>
          </Card>
          <p />
          <Card className={'customer-card'} title={'Credenciales'}>
            <Col
              xs={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              md={{ span: 7, offset: 1 }}
              lg={{ span: 7, offset: 1 }}
              xl={{ span: 7, offset: 1 }}
              xxl={{ span: 7, offset: 1 }}
            >
              <label>Archivo Key: </label>
              <Upload
                name="keyFile"
                action="/"
                accept=".key"
                listType="picture"
                beforeUpload={this.handleBeforeUploadKey}
                onRemove={this.handleRemoveKey}
                showUploadList={{ showPreviewIcon: false }}
              >
                {this.state.KeyList.size <= 1 ? this.uploadButton : null}
              </Upload>
            </Col>
            <Col
              xs={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              md={{ span: 7, offset: 1 }}
              lg={{ span: 7, offset: 1 }}
              xl={{ span: 7, offset: 1 }}
              xxl={{ span: 7, offset: 1 }}
            >
              <label>Archivo Cer: </label>
              <Upload
                name="cerFile"
                action="/"
                accept=".cer"
                listType="picture"
                beforeUpload={this.handleBeforeUploadCer}
                onRemove={this.handleRemoveCer}
                showUploadList={{ showPreviewIcon: false }}
              >
                {this.state.CerList.size <= 1 ? this.uploadButton : null}
              </Upload>
            </Col>
            <Col
              xs={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              md={{ span: 7, offset: 1 }}
              lg={{ span: 7, offset: 1 }}
              xl={{ span: 7, offset: 1 }}
              xxl={{ span: 7, offset: 1 }}
            >
              <Form.Item>
                {getFieldDecorator('stampCompany', {
                  valuePropName: 'checked',
                  initialValue: company ? company.stampCompany : false,
                })(<Checkbox disabled={isFetching}>Autofacturable</Checkbox>)}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item hasFeedback label="Password">
                {getFieldDecorator('password', {
                  initialValue: company.password ? company.password : '',
                  rules: [
                    {
                      required: isEmpty(company),
                      whitespace: true,
                      message: 'Ingrese su contraseña del Sat',
                    },
                    {
                      max: 100,
                      whitespace: true,
                      message: 'No se permiten mas de 100 caracteres',
                    },
                  ],
                })(
                  <Input size="large" placeholder="password" type="password" />,
                )}
              </Form.Item>
            </Col>
          </Card>
          <p />
          <Card className={'customer-card'} title={'Datos de FTP'}>
            <Col
              xs={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              md={{ span: 23, offset: 1 }}
              lg={{ span: 23, offset: 1 }}
              xl={{ span: 23, offset: 1 }}
              xxl={{ span: 23, offset: 1 }}
            >
              <Form.Item hasFeedback label="Host">
                {getFieldDecorator('ftpHost', {
                  initialValue: company ? company.ftpHost : '',
                  rules: [
                    {
                      whitespace: false,
                      message: 'Ingresa una dirección de host',
                    },
                    {
                      max: 300,
                      whitespace: true,
                      message: 'No se permiten más de 300 caracteres',
                    },
                  ],
                })(<Input size="large" placeholder="Host" />)}
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              md={{ span: 23, offset: 1 }}
              lg={{ span: 7, offset: 1 }}
              xl={{ span: 7, offset: 1 }}
              xxl={{ span: 7, offset: 1 }}
            >
              <Form.Item hasFeedback label="Usuario">
                {getFieldDecorator('ftpUser', {
                  initialValue: company ? company.ftpUser : '',
                  rules: [
                    {
                      whitespace: true,
                      message: 'Ingresa un usuario',
                    },
                    {
                      max: 300,
                      whitespace: true,
                      message: 'No se permiten más de 300 caracteres',
                    },
                  ],
                })(<Input size="large" placeholder="Usuario" />)}
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              md={{ span: 23, offset: 1 }}
              lg={{ span: 7, offset: 1 }}
              xl={{ span: 7, offset: 1 }}
              xxl={{ span: 7, offset: 1 }}
            >
              <Form.Item hasFeedback label="Contraseña">
                {getFieldDecorator('ftpPassword', {
                  initialValue: company ? company.ftpPassword : '',
                  rules: [
                    {
                      whitespace: true,
                      message: 'Ingresa una contraseña',
                    },
                    {
                      max: 300,
                      whitespace: true,
                      message: 'No se permiten más de 300 caracteres',
                    },
                  ],
                })(<Input size="large" placeholder="Contraseña" />)}
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              md={{ span: 23, offset: 1 }}
              lg={{ span: 7, offset: 1 }}
              xl={{ span: 7, offset: 1 }}
              xxl={{ span: 7, offset: 1 }}
            >
              <Form.Item hasFeedback label="Puerto">
                {getFieldDecorator('ftpPort', {
                  initialValue: company ? company.ftpPort : '',
                  rules: [
                    {
                      whitespace: true,
                      message: 'Ingresa un puerto',
                    },
                    {
                      max: 5,
                      whitespace: true,
                      message: 'No se permiten más de 5 caracteres',
                    },
                  ],
                })(<Input size="large" placeholder="Puerto" />)}
              </Form.Item>
            </Col>
          </Card>

          <Card className={'customer-card'}>
            <Col span={11} />
            <Col span={6}>
              <Link to="/enterprice">
                <Button id="cancel_button" type="default" block size="large">
                  Cancelar
                </Button>
              </Link>
            </Col>
            <Col span={6} offset={1}>
              <Button
                className="button-customer-serch-style"
                block
                size="large"
                type="primary"
                htmlType="submit"
              >
                {isFetching ? 'Loading...' : 'Guardar'}
              </Button>
            </Col>
          </Card>
        </Form>
      </Card>
    );
  };
}

const mapStateToProps = (states: RootState): StateProps => {
  return {
    company: states.companyState.company,
    distributionCenter:
      states.companyState.distributionCenter.distributionCenter,
    isFetching: states.companyState.isFetching,
  };
};

const mapDispatchToProps = {
  editCompany,
  getCompany,
  cleanCompany,
  getDistributionCenter,
  page,
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create()(CompanyFormComponent));
