/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Ricardo Sansores <ricardo@ssf.mx>, May 2019
 *
 * This are the permissions that exist in the application. This are stored in the database
 * and could look as exesive to create a constats file in the front end but this way
 * is easier to mantain the code base because we can track the places where a permission is applied
 * without the need to search for a given string but looking for the usages of the constant.
 */

export class Permissions {
  public static CAN_VIEW_SALES = 'CAN_VIEW_SALES';
  public static CAN_VIEW_COMPANIES = 'CAN_VIEW_COMPANIES';
  public static CAN_VIEW_COMPANY_DETAILS = 'CAN_VIEW_COMPANY_DETAILS';
  public static CAN_CIEW_CUSTOMER_PORTFOLIO = 'CAN_CIEW_CUSTOMER_PORTFOLIO';
  public static CAN_VIEW_STATEMENT = 'CAN_VIEW_STATEMENT';
  public static CAN_VIEW_USERS = 'CAN_VIEW_USERS';
  public static CAN_ADD_USER = 'CAN_ADD_USER';
  public static CAN_EDIT_USER = 'CAN_EDIT_USER';
  public static CAN_SEND_USER_CREDENTIALS = 'CAN_SEND_USER_CREDENTIALS';
  public static CAN_ENABLE_OR_DISABLE_CREDENTIALS =
    'CAN_ENABLE_OR_DISABLE_CREDENTIALS';
  public static CAN_REFUSE_PAYMENTS_WITHOUT_TICKET =
    'CAN_REFUSE_PAYMENTS_WITHOUT_TICKET';

  public static CAN_CREATE_INVOICES = 'CAN_CREATE_INVOICES';
  public static CAN_VIEW_MY_SALES = 'CAN_VIEW_MY_SALES';
  public static CAN_VIEW_DASHBOARD = 'CAN_VIEW_DASHBOARD';
  public static CAN_VIEW_STATEMENTS = 'CAN_VIEW_STATEMENTS';
  public static CAN_VIEW_MY_STATEMENTS = 'CAN_VIEW_MY_STATEMENTS';
  public static CAN_EDIT_STATEMENTS = 'CAN_EDIT_STATEMENTS';
  public static CAN_MERGE_AND_SEPARATE_CHARGES =
    'CAN_MERGE_AND_SEPARATE_CHARGES';
  public static CAN_MOVE_CHARGES = 'CAN_MOVE_CHARGES';
  public static CAN_CANCEL_INVOICE = 'CAN_CANCEL_INVOICE';
  public static CAN_FORCE_CANCEL_INVOICE='CAN_FORCE_CANCEL_INVOICE';
  public static CANCEL_INVOICE_UNREGISTERED_CUSTOMER =
    'CANCEL_INVOICE_UNREGISTERED_CUSTOMER';
  public static CAN_VIEW_PAYMENTS = 'CAN_VIEW_PAYMENTS';
  public static CAN_VIEW_MY_PAYMENTS = 'CAN_VIEW_MY_PAYMENTS';
  public static CAN_VIEW_SETTLEMENTS = 'CAN_VIEW_SETTLEMENTS';
  public static CAN_VIEW_BANK_DEPOSITS = 'CAN_VIEW_BANK_DEPOSITS';
  public static CAN_APPLY_BANK_DEPOSITS = 'CAN_APPLY_BANK_DEPOSITS';
  public static CAN_CREATE_BANK_DEPOSITS = 'CAN_CREATE_BANK_DEPOSITS';
  public static CAN_POS_SETTLE = 'CAN_POS_SETTLE';
  public static CAN_VIEW_ALL_DISTRIBUTION_CENTERS =
    'CAN_VIEW_ALL_DISTRIBUTION_CENTERS';
  public static CAN_MANAGE_COMPANIES = 'CAN_MANAGE_COMPANIES';
  public static CAN_APPLY_PAYMENTS = 'CAN_APPLY_PAYMENTS';
  public static CAN_ADD_AND_EDIT_CUSTOMERS = 'CAN_ADD_AND_EDIT_CUSTOMERS';
  public static CAN_SET_CUSTOMER_TYPE_RETURN = 'CAN_SET_CUSTOMER_TYPE_RETURN';
  public static CAN_INIT_DEBT = 'CAN_INIT_DEBT';
  public static DEVELOP_MODE = 'DEVELOP_MODE';
  public static CAN_MANAGE_SETTLEMENTS = 'CAN_MANAGE_SETTLEMENTS';
  public static CAN_SEE_FOLIOS = 'CAN_SEE_FOLIOS';
  public static CAN_EDIT_SALES = 'CAN_EDIT_SALES';
  public static CAN_DOWNLOAD_CUTS_TICKET = 'CAN_DOWNLOAD_CUTS_TICKET';
  public static CAN_EDIT_PAYMENT_CONDITION_3LT =
    'CAN_EDIT_PAYMENT_CONDITION_3LT';
  public static CAN_EDIT_PAYMENT_CONDITIONS = 'CAN_EDIT_PAYMENT_CONDITIONS';
  public static CAN_APPLY_DEPOSIT_IUS = 'CAN_APPLY_DEPOSIT_IUS';
  public static CAN_PRINT_SALE_TICKET = 'CAN_PRINT_SALE_TICKET';
  public static CAN_VIEW_APPLICATED_DEPOSIT_UIS =
    'CAN_VIEW_APPLICATED_DEPOSIT_UIS';
  public static CAN_VIEW_DEPOSIT_IUS = 'CAN_VIEW_DEPOSIT_IUS';
  public static CAN_VIEW_SETTLEMENT_APPLY = 'CAN_VIEW_SETTLEMENT_APPLY';
  public static CAN_VIEW_TOTALVOLUME = 'CAN_VIEW_TOTALVOLUME';
  public static CAN_VIEW_DEPOSIT_MENU = 'CAN_VIEW_DEPOSIT_MENU';
  public static CAN_ADD_DEPOSIT_CLARIFICATIONS =
    'CAN_ADD_DEPOSIT_CLARIFICATIONS';
  public static CAN_RESTORE_SETTLEMENT = 'CAN_RESTORE_SETTLEMENT';
  public static CAN_DOWNLOAD_PENDING_SETTLEMENTS_EXCEL =
    'CAN_DOWNLOAD_PENDING_SETTLEMENTS_EXCEL';
  public static CAN_EDIT_CIE_KEY = 'CAN_EDIT_CIE_KEY';
  public static CAN_MANAGE_EQUIPMENTS = 'CAN_MANAGE_EQUIPMENTS';
  public static CAN_GENERATE_CREDIT_NOTE = 'CAN_GENERATE_CREDIT_NOTE';
  public static CAN_SEE_ALL_SALES = 'CAN_SEE_ALL_SALES';
  public static CAN_DELETE_CUSTOMER = 'CAN_DELETE_CUSTOMER';
  public static CAN_EDIT_CODE = 'CAN_EDIT_CODE';
  public static CAN_SEE_INVENTORY_MENU = 'CAN_SEE_INVENTORY_MENU';
  public static CAN_ASSIGN_TRANSFER_OR_SELF_CONSUMPTION_TO_SALE =
    'CAN_ASSIGN_TRANSFER_OR_SELF_CONSUMPTION_TO_SALE';
  public static SEE_INDICATOR_POSLIQUIDATE_SALE =
    'SEE_INDICATOR_POSLIQUIDATE_SALE';
  public static CAN_ASSIGN_APARCEROS = 'CAN_ASSIGN_APARCEROS';
  public static CAN_CHANGUE_INVOICE_REQUIRED = 'CAN_CHANGUE_INVOICE_REQUIRED';
  public static CAN_EDIT_UDR = 'CAN_EDIT_UDR';
  public static IS_DISABLED = 'IS_DISABLED';
  public static CAN_SEE_CYLINDERS_MENU = 'CAN_SEE_CYLINDERS_MENU';
  public static CAN_SEE_ROUTE_MENU = 'CAN_SEE_ROUTE_MENU';
  public static IS_BOSS_OF_PORTFOLIO_MANAGERS = 'IS_BOSS_OF_PORTFOLIO_MANAGERS';
  public static IS_DEBT_COLLECTOR = 'IS_DEBT_COLLECTOR';
  public static IS_PORTFOLIO_MANAGER = 'IS_PORTFOLIO_MANAGER';
  public static EDIT_CUSTOMER_DISCOUNT = 'EDIT_CUSTOMER_DISCOUNT';
  public static SEE_CANCELLATION_DETAILS = 'SEE_CANCELLATION_DETAILS';
  public static SEE_ALL_PORTFOLIOS = 'SEE_ALL_PORTFOLIOS';
  public static CLOSE_PORTFOLIO = 'CLOSE_PORTFOLIO';
  public static EDIT_RFC_SAVED = 'EDIT_RFC_SAVED';
  public static EDIT_PAYMENT_WAY_SAVED = 'EDIT_PAYMENT_WAY_SAVED';
  public static UPDATE_ALL_CUSTOMER_SALES = 'UPDATE_ALL_CUSTOMER_SALES';
  public static CAN_DOWNLOAD_CUSTOMERS_REPORT = 'CAN_DOWNLOAD_CUSTOMERS_REPORT';


  public static VIEW_SALE_G4S_FOLIO = 'VIEW_SALE_G4S_FOLIO';

  public static DEPOSIT_DIFFERENCE_VIEW = 'DEPOSIT_DIFFERENCE_VIEW';
  public static DEPOSIT_DIFFERENCE_RESOLVE = 'DEPOSIT_DIFFERENCE_RESOLVE';
  public static DEPOSIT_DIFFERENCE_CLOSE = 'DEPOSIT_DIFFERENCE_CLOSE';
  public static DEPOSIT_DIFFERENCE_OPEN = 'DEPOSIT_DIFFERENCE_OPEN';
  public static DEPOSIT_DIFFERENCE_COMMENT_WRITE = 'DEPOSIT_DIFFERENCE_COMMENT_WRITE';

  public static DOWNLOAD_DEI_REPORT = 'DOWNLOAD_DEI_REPORT';
  public static CAN_SEE_ALL_PERM_DEPOSIT = 'CAN_SEE_ALL_PERM_DEPOSIT';
}
