
export type DbOldTypeByTable<T extends DbOldTables> = (
    T extends DbOldTables.SystemUser ? DbOldTypes.SystemUser :
    T extends DbOldTables.Sale ? DbOldTypes.Sale :
    T extends DbOldTables.Payment ? DbOldTypes.Payment :
    T extends DbOldTables.Settlement ? DbOldTypes.Settlement :
    T extends DbOldTables.Statement ? DbOldTypes.Statement :
    T extends DbOldTables.Equipment ? DbOldTypes.Equipment :
    T extends DbOldTables.BankDeposit ? DbOldTypes.BankDeposit :
    T extends DbOldTables.DepositDifference ? DbOldTypes.DepositDifference :
    T extends DbOldTables.DepositDifferenceComment ? DbOldTypes.DepositDifferenceComment :
    never
);

export enum DbOldTables{
    SystemUser= 'system_user',
    Sale = 'sale',
    Payment = 'payment',
    Settlement = 'settlement',
    Statement = 'statement',
    Equipment = 'equipments',
    BankDeposit = 'bank_deposit',
    DepositDifference = 'deposit_difference',
    DepositDifferenceComment = 'deposit_difference_comment',
}



enum UserAuditLevel{
    Level0 = 'key000',
    Level1 = 'key001',
    Level2 = 'key002',

    None = 'key900',
}
enum DistCenterId{
    Pabellon = '43ba830d-f619-4ee0-bc8e-99fd226e7ec1',
    GuayanaLoreto = 'f6e58e7d-dc71-45a5-a555-5e227cff93e3',
    EstAguascalientes = '37a91121-f679-47ef-81cd-fe3310ffb12f',
    Bajio = '94e058cc-f074-4471-9fc6-6afbf5da535d',
}
enum BucketFileType{
    IMAGE = 'key000',
    PDF = 'key001',
    OTHER = 'key999',
}
enum CustomerPayType{
    CREDIT = '0',
    CASH = '1',
    SELF_CONSUMPTION = '2',
    TRASLATE = '3',
    RETURN = '4',
    PREPAID = '5',
}
enum AuditStatus{
    Received = 'key000',
    Arranged = 'key001',
    Reviewed = 'key002',
    Approved = 'key003',
}
enum DasReviewTransitionStatus{
    Pending = 'key000',
    ReceivedByLevel2 = 'key001',
    GivenByLevel2 = 'key002',
    ReceivedByLevel1 = 'key003',
    GivenByLevel1 = 'key004',
    ClosedByLevel0 = 'key099',
}
enum SaleAuditRoleType{
    Untouched = 'key000',
    Rejected = 'key001',
    BecameSp = 'key002',
    BecameCp = 'key003',
    Fallback = 'key004',
    DonorToFallback = 'key005',
}
enum SettlementAuditRoleType{
    Untouched = 'key000',
    ObtainedSpSales = 'key001',
    ObtainedCpSales = 'key002',
}
enum SalePaymentCondition{
    TRASLATE = 'TRASLATE',
    SELF__CONSUMPTION = 'SELF-CONSUMPTION',
    RETURN = 'RETURN',
    FAILED = 'FAILED',
    SELF_CONSUMPTION = 'SELF_CONSUMPTION',
    CASH = 'CASH',
    CREDIT = 'CREDIT',
    VOUCHERS = 'VOUCHERS',
    PREPAID = 'PREPAID',
}
enum SalePaymentWay{
    EFECTIVO = 0,
    CREDITO = 1,
    DEBITO = 2,
    CHEQUE = 3,
    TRANSFERENCIA = 4,
    POR_DEFINIR = 5,
}
enum SettlementStatus{
    NEW = 'Nuevo',
    VALID = 'Liquidado?',
    POST_VALID = 'Liquidado',
}
enum EquipmentType{
    Pipa = 'P',
    Station = 'E',
}
enum EquipmentArrangeType{
    SP = 'SP',
    CP = 'CP',
    NA = 'NA',
}
enum DepositDifferenceStatus{
    CREATED = 'key000',
    RESOLVED = 'key001',
    CLOSED = 'key002',
}
export class DbOldValues{
    static UserAuditLevel = UserAuditLevel;
    static DistCenterId = DistCenterId;
    static BucketFileType = BucketFileType;
    static CustomerPayType = CustomerPayType;
    static AuditStatus = AuditStatus;
    static DasReviewTransitionStatus = DasReviewTransitionStatus;
    static SaleAuditRoleType = SaleAuditRoleType;
    static SettlementAuditRoleType = SettlementAuditRoleType;
    static SalePaymentCondition = SalePaymentCondition;
    static SalePaymentWay = SalePaymentWay;
    static SettlementStatus = SettlementStatus;
    static EquipmentType = EquipmentType;
    static EquipmentArrangeType = EquipmentArrangeType;
    static DepositDifferenceStatus = DepositDifferenceStatus;
}

// eslint-disable-next-line
export declare namespace DbOldValues{
    export import _UserAuditLevel = UserAuditLevel;
    export import _DistCenterId = DistCenterId;
    export import _BucketFileType = BucketFileType;
    export import _CustomerPayType = CustomerPayType;
    export import _AuditStatus = AuditStatus;
    export import _DasReviewTransitionStatus = DasReviewTransitionStatus;
    export import _SaleAuditRoleType = SaleAuditRoleType;
    export import _SettlementAuditRoleType = SettlementAuditRoleType;
    export import _SalePaymentCondition = SalePaymentCondition;
    export import _SalePaymentWay = SalePaymentWay;
    export import _SettlementStatus = SettlementStatus;
    export import _EquipmentType = EquipmentType;
    export import _EquipmentArrangeType = EquipmentArrangeType;
    export import _DepositDifferenceStatus = DepositDifferenceStatus;
}

export class DbOldDetails {
    static readonly distCenterId: { [K in DbOldValues._DistCenterId]: { name: string, fileName: string } } = {
        [DbOldValues.DistCenterId.Pabellon]: { name: `Pabellón`, fileName: 'Pabellon' },
        [DbOldValues.DistCenterId.GuayanaLoreto]: { name: `Guayana`, fileName: `Guayana` },
        [DbOldValues.DistCenterId.EstAguascalientes]: { name: `EstAguascalientes`, fileName: `EstAgs` },
        [DbOldValues.DistCenterId.Bajio]: { name: `Bajio`, fileName: `Bajio` },
    };
    static readonly userAuditLevel: { [K in DbOldValues._UserAuditLevel]: { name: string } } = {
        [DbOldValues.UserAuditLevel.Level0]: { name: 'Nivel 0' },
        [DbOldValues.UserAuditLevel.Level1]: { name: 'Nivel 1' },
        [DbOldValues.UserAuditLevel.Level2]: { name: 'Nivel 2' },
        [DbOldValues.UserAuditLevel.None]: { name: 'Sin nivel' },
    };
    static readonly dasReviewTransitionStatus: { [K in DbOldValues._DasReviewTransitionStatus]: { name: string, tagColor: string } } = {
        [DbOldValues.DasReviewTransitionStatus.Pending]: { name: `Pendiente Cajero`, tagColor: 'volcano' },
        [DbOldValues.DasReviewTransitionStatus.ReceivedByLevel2]: { name: `Recibido 2`, tagColor: 'geekblue' },
        [DbOldValues.DasReviewTransitionStatus.GivenByLevel2]: { name: `Entregado 2`, tagColor: 'volcano' },
        [DbOldValues.DasReviewTransitionStatus.ReceivedByLevel1]: { name: `Recibido 1`, tagColor: 'geekblue' },
        [DbOldValues.DasReviewTransitionStatus.GivenByLevel1]: { name: `Entregado 1`, tagColor: 'volcano' },
        [DbOldValues.DasReviewTransitionStatus.ClosedByLevel0]: { name: `Cerrado`, tagColor: 'green' },
    };
    static readonly dasReviewTransitionStatusLevel0: (typeof DbOldDetails)['dasReviewTransitionStatus'] = {
        [DbOldValues.DasReviewTransitionStatus.Pending]: { name: `Pendiente Cajero`, tagColor: 'volcano' },
        [DbOldValues.DasReviewTransitionStatus.ReceivedByLevel2]: { name: `Recibió Nivel 2`, tagColor: 'geekblue' },
        [DbOldValues.DasReviewTransitionStatus.GivenByLevel2]: { name: `Entregó Nivel 2`, tagColor: 'volcano' },
        [DbOldValues.DasReviewTransitionStatus.ReceivedByLevel1]: { name: `Recibió Nivel 1`, tagColor: 'geekblue' },
        [DbOldValues.DasReviewTransitionStatus.GivenByLevel1]: { name: `Entregó Nivel 1`, tagColor: 'volcano' },
        [DbOldValues.DasReviewTransitionStatus.ClosedByLevel0]: { name: `Cerró Nivel 0`, tagColor: 'green' },
    };
    static readonly depositDifferenceStatus: { [K in DbOldValues._DepositDifferenceStatus]: { name: string, pluralName: string, color: string, tagColor: string } } = {
        [DbOldValues.DepositDifferenceStatus.CREATED]: { name: 'Sin resolver', pluralName: 'Sin resolver', color: 'palevioletred', tagColor: 'volcano' },
        [DbOldValues.DepositDifferenceStatus.RESOLVED]: { name: 'Resuelta', pluralName: 'Resueltas', color: 'cornflowerblue', tagColor: 'geekblue' },
        [DbOldValues.DepositDifferenceStatus.CLOSED]: { name: 'Cerrada', pluralName: 'Cerradas', color: 'darkseagreen', tagColor: 'green' },
    };
    static readonly salePaymentWay: { [K in DbOldValues._SalePaymentWay]: { name: string } } = {
        [DbOldValues.SalePaymentWay.EFECTIVO]: { name: 'Efectivo' },
        [DbOldValues.SalePaymentWay.CREDITO]: { name: 'Tarjeta crédito' },
        [DbOldValues.SalePaymentWay.DEBITO]: { name: 'Tarjeta débito' },
        [DbOldValues.SalePaymentWay.CHEQUE]: { name: 'Cheque' },
        [DbOldValues.SalePaymentWay.TRANSFERENCIA]: { name: 'Transferencia' },
        [DbOldValues.SalePaymentWay.POR_DEFINIR]: { name: 'Por definir' },
    };
    static readonly salePaymentCondition: { [K in DbOldValues._SalePaymentCondition]: { name: string } } = {
        [DbOldValues.SalePaymentCondition.TRASLATE]: { name: 'Traslado' },
        [DbOldValues.SalePaymentCondition.SELF__CONSUMPTION]: { name: 'Uso propio' },
        [DbOldValues.SalePaymentCondition.RETURN]: { name: 'Retorno' },
        [DbOldValues.SalePaymentCondition.FAILED]: { name: 'Fallido' },
        [DbOldValues.SalePaymentCondition.SELF_CONSUMPTION]: { name: 'Uso propio' },
        [DbOldValues.SalePaymentCondition.CASH]: { name: 'Contado' },
        [DbOldValues.SalePaymentCondition.CREDIT]: { name: 'Crédito' },
        [DbOldValues.SalePaymentCondition.VOUCHERS]: { name: 'Vouchers' },
        [DbOldValues.SalePaymentCondition.PREPAID]: { name: 'Prepago' },
    };
    static readonly getDistCenterId: (dci: null | undefined | DbOldValues._DistCenterId | string) => { name: string } = dci => {
        if(!dci) return { name: '' };
        return DbOldDetails.distCenterId[dci as DbOldValues._DistCenterId] || { name: '' };
    };
    static readonly getSalePaymentWay: (spw: null | DbOldTypes.Sale['payment_way']) => { name: string } = spw => {
        if(spw === null) return { name: '' };
        return DbOldDetails.salePaymentWay[spw] || { name: '' };
    };
    static readonly getSalePaymentCondition: (spc: null | DbOldTypes.Sale['payment_condition_type']) => { name: string } = spc => {
        if(spc === null) return { name: '' };
        return DbOldDetails.salePaymentCondition[spc] || { name: '' };
    };
    static getBucketFileTypeByMime(mime: string): BucketFileType{
        if(mime.includes('image/')) return DbOldValues.BucketFileType.IMAGE;
        if(mime.includes('/pdf')) return DbOldValues.BucketFileType.PDF;
        return DbOldValues.BucketFileType.OTHER;
    };
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace DbOldTypes{
    export type NOT_SPECIFIED = any;
    export type Uuid<T = string> = T;
    export type Bytea = string;
    export type Int<T extends number = number> = T;
    export type Numeric<Precision, Scale = 0> = number;
    export type Varchar<Len, Type = string> = Type;
    export type Jsonb<T = any> = T;
    export type CONCAT<P1, P2, P3 = any, P4 = any> = string;

    export type BucketFile = {
        name: string,
        path: string,
        mime: string,
        type_key: DbOldValues._BucketFileType,
    };

    export type SystemUser = {
        id: Uuid,
        email: string,
    };
    export type Customer = {
        id: Uuid,
        created_at: null | Date,
        updated_at: null | Date,
        id_user_create: null | Uuid,
        id_user_update: null | Uuid,
        business_name: null | string,
        rfc: null | string,
        street: null | string,
        address: null | string,
        external_number: null | string,
        internal_number: null | string,
        zip_code: null | string,
        colony: null | string,
        city: null | string,
        municipality: null | string,
        state: null | string,
        uso_cfdi: NOT_SPECIFIED,
        forma_pago: NOT_SPECIFIED,
        email: NOT_SPECIFIED,
        code: NOT_SPECIFIED,
        credit_days: NOT_SPECIFIED,
        credit_limit: NOT_SPECIFIED,
        debt: NOT_SPECIFIED,
        is_automatic: NOT_SPECIFIED,
        distribution_center_id: NOT_SPECIFIED,
        password: NOT_SPECIFIED,
        login_date: NOT_SPECIFIED,
        discount: NOT_SPECIFIED,
        birthday: NOT_SPECIFIED,
        balance: NOT_SPECIFIED,
        comercial_name: null | string,
        observations: NOT_SPECIFIED,
        status_customer: NOT_SPECIFIED,
        status_creit: NOT_SPECIFIED,
        is_credit: DbOldValues._CustomerPayType,
        phone_number: NOT_SPECIFIED,
        is_group_sales: NOT_SPECIFIED,
        additional_fiscal_information: NOT_SPECIFIED,
        cie_key: NOT_SPECIFIED,
        assigned_user_id: null | Uuid,
        customer_code_com_pro: NOT_SPECIFIED,
        aparcero_type: NOT_SPECIFIED,
        assigned_distribution_centers: NOT_SPECIFIED,
        accounting_account: null | string,
        email_invoice: NOT_SPECIFIED,
        is_disabled: NOT_SPECIFIED,
        auto_stamp_date: NOT_SPECIFIED,
        latitude: NOT_SPECIFIED,
        longitude: NOT_SPECIFIED,
        location: NOT_SPECIFIED,
        fiscal_regime: NOT_SPECIFIED,
    };
    export type Sale = {
        id: Uuid,
        created_at: null | Date,
        updated_at: null | Date,
        id_user_create: null | Uuid,
        id_user_update: null | Uuid,
        total: number,
        end_date: Date,
        ius_code: string,
        total_volume: number,
        dist_center_id: null | Uuid,
        sales_folio: string,
        id_g4s: Uuid,
        invoice_id: null | Uuid,
        customer_rfc: null | string,
        customer_code: string,
        reseption_unit: null | string,
        cut_id: null | Uuid,
        payment_condition_type: null | DbOldValues._SalePaymentCondition,
        disabled: null | boolean,
        objetive_sale: null | number,
        latitude: null | number,
        longitude: null | number,
        unit_price: null | number,
        equipment_number: null | Equipment['number'],
        tag: null | string,
        invoice_required: null | boolean,
        equipment_app_type: null | number,
        payment_way: null | DbOldValues._SalePaymentWay,
        equipment_id: null | Equipment['id_g4s'],
        bank_deposit_id: null | Uuid,
        total_amount_c: null | number,
        total_volume_c: null | number,
        statement_id: null | Uuid,
        is_forced_general_customer: null | boolean,
        scheme: null | number,
        is_disable_invoice: boolean,
        total_invoice: null | number,
        status_cancelation: null | string,
        //
        daily_audit_status_id: null | DailyAuditStatus['id'],
        audit_status_key: null | DbOldValues._AuditStatus,
        audit_snapshot: null | Jsonb<SaleAuditSnapshot>,
        audit_role: null | Jsonb<{ typeKey: DbOldValues._SaleAuditRoleType, id: Sale['id'] }>,
        edit_snapshots: null | Jsonb<SaleEditSnapshot[]>,
        cfdi_folio_retry: null | Int,
        audit_invoice_record_id: null | AuditInvoiceRecord['id'],
        cylinder_snapshot: null | Jsonb<{
            units: { kg: number, price: number, quantity: number }[],
            density: number,
            discount: number,
        }>
    };
    export type SaleAuditSnapshot = {
        [DbOldValues.AuditStatus.Received]?: SaleAuditArrangeSnapshot,
        [DbOldValues.AuditStatus.Arranged]?: SaleAuditArrangeSnapshot,
        [DbOldValues.AuditStatus.Reviewed]?: { apply: false, daily_audit_status_id: DailyAuditStatus['id'] } | SaleAuditReviewSnapshot,
    };
    export type SaleAuditArrangeSnapshot = {
        sales_folio: Sale['sales_folio'],
        total: Sale['total'],
        total_volume: Sale['total_volume'],
        unit_price: Sale['unit_price'],
        cut_id: Sale['cut_id'],
        dist_center_id: Sale['dist_center_id'],
        equipment_number: Sale['equipment_number'],
        equipment_id: Sale['equipment_id'],
        equipment_app_type: Sale['equipment_app_type'],
        tag: Sale['tag'],
    };
    export type SaleAuditReviewSnapshot = {
        daily_audit_status_id: DailyAuditStatus['id'],
        apply: true,
        finalBasedOn: 'volume' | 'amount',
        amount: Sale['total'],
        reviewAmount: Sale['total'],
        finalAmount: Sale['total'],
        volume: Sale['total_volume'],
        reviewVolume: Sale['total_volume'],
        finalVolume: Sale['total_volume'],
    };
    export type SaleEditSnapshot = {
        datetime: string,
        userId: SystemUser['id'],
        userEmail: SystemUser['email'],
        customer_code: Sale['customer_code'],
        customer_rfc: Sale['customer_rfc'],
        payment_condition_type: Sale['payment_condition_type'],
        payment_way: Sale['payment_way'],
        unit_price: Sale['unit_price'],
        total: Sale['total'],
    };
    export type Payment = {
        id: Uuid,
        created_at: null | Date,
        updated_at: null | Date,
        id_user_create: null | Uuid,
        id_user_update: null | Uuid,
        amount: number,
        balance: number,
        bank: null | string,
        reference: null | string,
        payment_form: string,
        voucher: null | Bytea,
        payment_date: null | Date,
        customer_code: string,
        is_apply: null | string,
        acount_number: null | string,
        invoice_id: null | Uuid,
        payment_voucher_link: null | string,
        statement_id: null | Uuid,
        cie_key: null | string,
        is_automatic: null | boolean,
        pemex_price: null | number,
        total_weight: null | number,
        taskit_folio: null | string,
        latitude: null | number,
        longitude: null | number,
    };
    export type Settlement = {
        id: Uuid,
        created_at: null | Date,
        updated_at: null | Date,
        id_user_create: null | Uuid,
        id_user_update: null | Uuid,
        amount_credit: null | number,
        amount_cash: null | number,
        total: null | number,
        unit: string,
        service_type: null | string,
        reference: null | string,
        folio: string,
        movement_date: null | Date,
        distribution_center_id: Uuid,
        status: DbOldValues._SettlementStatus,
        operator: null | string,
        assistant: null | string,
        id_no_g4s: Uuid,
        post_amount_cash: null | number,
        post_total: null | number,
        equipment_number: null | number,
        equipment_idg4s: null | Uuid,
        bank_deposit_id: null | Uuid,
        final_volume_totalizer: null | number,
        objective: null | number,
        objective_c: null | number,
        total_volume_cash: null | number,
        total_volume_credit: null | number,
        total_volume_used: null | number,
        total_volume_transfer: null | number,
        total_volume_failed: null | number,
        total_volume: null | number,
        total_volume_cash_c: null | number,
        initial_percent_tank: null | number,
        final_percent_tank: null | number,
        theoretical_inventory: null | number,
        theoretical_container_volume: null | number,
        initial_date: null | Date,
        child_container_values: null | Jsonb<{ id: Uuid, percentageFinalVolume: number, percentageInitialVolume: number }[]>,
        comment: null | string,
        efficiency: null | number,
        container_purchases: null | Jsonb<{}>,
        // audit
        daily_audit_status_id: null | DailyAuditStatus['id'],
        audit_status_key: DbOldValues._AuditStatus,
        audit_snapshot: null | Jsonb<SettlementAuditSnapshot>,
        audit_role_type_key: null | DbOldValues._SettlementAuditRoleType,
        audit_invoice_record_id?: null | AuditInvoiceRecord['id'],
    };
    export type SettlementAuditSnapshot = {
        [DbOldValues.AuditStatus.Received]?: Sale['id'][],
        [DbOldValues.AuditStatus.Arranged]?: Sale['id'][],
        [DbOldValues.AuditStatus.Reviewed]?: { apply: false, daily_audit_status_id: DailyAuditStatus['id'] } | SettlementAuditReviewSnapshot,
    };
    export type SettlementAuditReviewSnapshot = {
        apply: true,
        daily_audit_status_id: DailyAuditStatus['id'],
        amount: NonNullable<Settlement['total']>,
        reviewAmount: NonNullable<Settlement['total']>,
        finalAmount: NonNullable<Settlement['total']>,
        amountCash: NonNullable<Settlement['amount_cash']>,
        reviewAmountCash: NonNullable<Settlement['amount_cash']>,
        finalAmountCash: NonNullable<Settlement['amount_cash']>,
        volume: NonNullable<Settlement['total_volume']>,
        reviewVolume: NonNullable<Settlement['total_volume']>,
        finalVolume: NonNullable<Settlement['total_volume']>,
        volumeCash: NonNullable<Settlement['total_volume_cash']>,
        reviewVolumeCash: NonNullable<Settlement['total_volume_cash']>,
        finalVolumeCash: NonNullable<Settlement['total_volume_cash']>,
    };
    export type Statement = {
        id: Uuid,
        created_at: null | Date,
        updated_at: null | Date,
        id_user_create: null | Uuid,
        id_user_update: null | Uuid,
        status_statment: string,
        is_sale: boolean,
        amount: number,
        balance: number,
        movement_date: Date,
        reference: string,
        customer_code: string,
        distribution_center_id: null | Uuid,
        previous_balance: null | number,
        invoice_id: null | Uuid,
        udr_code: null | string,
        total_invoice: null | number,
        status_cancelation: null | string,
    };
    export type Invoice = {
        id: Uuid,
        created_at: null | Date,
        updated_at: null | Date,
        id_user_create: null | Uuid,
        id_user_update: null | Uuid,
        name: Varchar<unknown>,
        rfc: Varchar<20>,
        street: Varchar<100>,
        external_number: Varchar<100>,
        internal_number: null | Varchar<100>,
        fiscal_regime: Varchar<100>,
        zip_code: Varchar<5>,
        colony: Varchar<100>,
        city: Varchar<100>,
        municipality: Varchar<100>,
        state: Varchar<100>,
        uso_cfdi: Varchar<100>,
        forma_pago: Varchar<100>,
        email: Varchar<250>,
        xml_invoice: null | Varchar<200>,
        pdf: null | Varchar<200>,
        downladed: null | boolean,
        error: null | Varchar<1024>,
        invoice_type: null | Varchar<10>,
        timbre_uuid: null | Uuid,
        serie: null | Varchar<50>,
        folio: null | Varchar<50>,
        status: Varchar<100, '0' | '1' | '2'>,
        company_id: null | Uuid,
        stamp_date: null | Date,
        total: null | Numeric<10, 2>,
        gov_perms: null | Jsonb<string[]>,
        customer_code_com_pro: null | Varchar<100>,
        invoice_observations: null | Varchar<unknown>,
        previous_invoice_id: null | Uuid,
        xml_invoice_cancelation: null | Varchar<300>,
        cancellation_date: null | Date,
        cancellation_user_id: null | Uuid,
        linked_invoices: null | Varchar<500>,
        customer_fiscal_regime: null | Varchar<100>,
    };
    export type BankDeposit = {
        id: Uuid,
        created_at: null | Date,
        updated_at: null | Date,
        status: null | string,
        deposit_date: null | Date,
        distribution_center_id: null | Uuid,
        amount: null | number,
        acount_recipt: null | string,
        folio_deposit: null | string,
        refuce_description: null | string,
        url_ticket: null | string,
        bank: null | string,
        id_user_create: null | Uuid,
        id_user_update: null | Uuid,
        deposit_type: null | string,
        is_disconted: null | boolean,
        debt: null | number,
        balance: null | number,
        comments: null | string,
        payment_method: null | string,
        file_ext: null | string,
        amount_differences: null | number,
    };
    export type Equipment = {
        id: Uuid,
        id_g4s: Uuid,
        tag: null | string,
        number: null | number,
        distribution_center_id: null | Uuid<DbOldValues._DistCenterId>,
        government_permission_id: null | Uuid,
        fiscal_company_id: null | Uuid,
        // audit
        type: DbOldValues._EquipmentType,
        volume_l_capacity: Numeric<9, 2>,
        arrange_type: DbOldValues._EquipmentArrangeType,
        twin_equipment_id: null | Uuid,
    };
    export type CylinderEquipmentRoute = {
        id: Uuid;
        tag: Varchar<50>;
        distribution_center_id: null | Uuid<DbOldValues._DistCenterId>;
        //
        government_permission_id: null | Uuid,
        virtual_equipment_id_g4s: null | Uuid<Equipment['id']>;
        open_virtual_cut_id: null | Uuid<Settlement['id']>;
    };
    export type DepositDifference = {
        id: Uuid,
        created_at: string,
        created_by_user_email: string,
        bank_deposit_id: BankDeposit['id'],
        amount: number,
        payment_way: DbOldValues._SalePaymentWay,
        other_payment_way: string,
        status_key: DbOldValues._DepositDifferenceStatus,
        file: null | Jsonb<BucketFile>,
        snapshot: Jsonb<DepositDifferenceSnapshot>,
    };
    export type DepositDifferenceSnapshot = {
        [DbOldValues.DepositDifferenceStatus.CREATED]: { user_email: SystemUser['email'], date: string },
        [DbOldValues.DepositDifferenceStatus.RESOLVED]?: { user_email: SystemUser['email'], date: string },
        [DbOldValues.DepositDifferenceStatus.CLOSED]?: { user_email: SystemUser['email'], date: string },
    };
    export type DepositDifferenceComment = {
        id: Uuid,
        created_at: string,
        updated_at: string,
        deleted_at: null | string,
        created_by_user_email: string,
        deposit_difference_id: DepositDifference['id'],
        content: string,
        file: null | Jsonb<BucketFile>,
        snapshot: Jsonb<{ date: string, content: string, file: DepositDifferenceComment['file'] }[]>,
    };
    export type GovernmentPermissions = {
        id: Uuid,
        name: Varchar<255>,
    };

    export type AuditConfig = {
        id_datetime_ms: string,
        users: Jsonb<{
            email: SystemUser['email'],
            name: string,
            level_key: DbOldValues._UserAuditLevel,
            dist_center_ids: DbOldValues._DistCenterId[],
        }[]>,
        review: Jsonb<{
            default_percentage_by_dist_center_id: { [DCI in DbOldValues._DistCenterId]: [number, number, number] },
            percentage_limits: [ number, number ],
            sale_amount_minimum_value: number,
            sale_random_final_amounts: number[],
        }>,
    }
    export type AuditInvoiceRecord = {
        id: Uuid,
        created_at: string,
        invoice_id: Invoice['id'],
        day_group_date: string,
        amount: Numeric<12, 4>,
        sales_quantity: Int,
        customer_code: Customer['code'],
        equipment_id_g4s: Equipment['id_g4s'],
        payment_way: Int<DbOldValues._SalePaymentWay>,
    };
    export type DailyAuditStatus = {
        id: string,
        date: string;
        dist_center_id: DbOldValues._DistCenterId,
        arrange_finished: boolean;
        arrange_snapshot: null | Jsonb<DailyAuditStatusArrangeSnapshot>;
        review_finished: boolean;
        review_objective_completed: boolean;
        review_snapshot: null | Jsonb<DailyAuditStatusReviewSnapshot>;
        review_transition_status_key: DbOldValues._DasReviewTransitionStatus,
        review_transition: Jsonb<{ [RTS in DbOldValues._DasReviewTransitionStatus]: null | DailyAuditStatusReviewTransition }>;
    };
    export type DailyAuditStatusArrangeSnapshot = {
        sales_processed: number,
        sales_arranged: number,
        settlements_processed: number,
        settlements_arranged: number,
        sale_amount_by_role_type_key: { [RT in DbOldValues._SaleAuditRoleType]: number };
        settlement_amount_by_role_type_key: { [RT in DbOldValues._SettlementAuditRoleType]: number };
    };
    export type DailyAuditStatusReviewSnapshot = {
        sales_processed: number,
        sales_meet_conditions: number,
        sales_reviewed: number,
        settlements_processed: number,
        settlements_reviewed: number,
        settlements_o_volume_l: number,
        settlements_o_volume_cash_l: number,
        settlements_o_amount: number,
        settlements_o_amount_cash: number,
        expected_volume_l: number,
        expected_volume_l_percentage: number,
        volume_l: number, // c
        amount: number, // c
    };
    export type DailyAuditStatusReviewTransition = {
        datetime: string,
        user_email: SystemUser['email'],
        amount: number,
        notes: string,
        //files: BucketFile[],
    };
}