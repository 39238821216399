/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Hiram Padilla <hiram@ssf.com.mx>, February 2021
 *
 * This file contains the service functions to make requests for Cylinder Settlements & Sales
 */
import axios, { AxiosResponse } from 'axios';
import { createCoreServiceUrl, createParamsService } from 'commons/services';
import { CylinderModel } from 'routes/cylinders';
import Service from 'services/Service';
import {
  CylinderEquipment,
  CylinderSale,
  CylinderSettlement,
  Pageable,
} from 'types/type';

class CylindersService extends Service {
  public getSettlementsPaginated = async (
    cylinderFilters: Record<string, any>,//eslint-disable-line
  ): Promise<Pageable> => {
    return axios({
      method: 'get',
      url:
        createCoreServiceUrl(`cylinderSettlements`) +
        createParamsService(cylinderFilters),
    }).then((response: AxiosResponse<Pageable>): Pageable => response.data);
  };

  public saveCylinderSettlement = async (
    model: CylinderModel,
  ): Promise<CylinderSettlement> => {
    return axios({
      method: 'post',
      url: createCoreServiceUrl(`cylinderSettlements`),
      data: model,
    }).then(
      (response: AxiosResponse<CylinderSettlement>): CylinderSettlement =>
        response.data,
    );
  };

  public getSalesBySettlementID = async (
    settlementId: string,
  ): Promise<CylinderSale[]> => {
    return axios({
      method: 'get',
      url: createCoreServiceUrl(`cylinderSales/${settlementId}`),
    }).then(
      (response: AxiosResponse<CylinderSale[]>): CylinderSale[] =>
        response.data,
    );
  };

  public getCylinderEquipments = async (): Promise<CylinderEquipment[]> => {
    return axios({
      method: 'get',
      url: createCoreServiceUrl(`cylinderEquipments`),
    }).then(
      (response: AxiosResponse<CylinderEquipment[]>): CylinderEquipment[] =>
        response.data,
    );
  };

  public saveSalesAtSettlement = async (
    sales: CylinderSale[],
    settlementId: string,
  ): Promise<CylinderSale[]> => {
    return axios({
      method: 'post',
      url: createCoreServiceUrl(`cylinderSettlements/${settlementId}`),
      data: sales,
    }).then(
      (response: AxiosResponse<CylinderSale[]>): CylinderSale[] =>
        response.data,
    );
  };
}

export default CylindersService;
