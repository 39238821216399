/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by David Canul <david@ssf.com.mx>, Jun 2019
 *
 * This component lists all the sales crawled from g4s
 */
import React, { Component, ReactNode } from 'react';
import {
  Row,
  List,
  Pagination,
  Col,
  Table,
  Tag,
  Icon,
  Button,
  Form,
  Card,
  Modal,
  Select,
  Tooltip,
  Input,
  Popconfirm,
  Typography,
  DatePicker,
  notification,
} from 'antd';
import {
  Statement,
  Acount,
  FiscalInformation,
  CustomerAddress,
  StatementFilters,
  Sale,
  DataToCustomInvoice,
  CustomerAndUDR,
  ModelSaleMove,
  ModelSaleMoveAll,
} from 'types/type';
import { connect } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { StatementList } from './redux/reducers';
import {
  page,
  save,
  search,
  stampSale,
  downloadFile,
  downloadReport,
  getSalesByStatementId,
  resetStatementSales,
  getCustomersByFilter,
  splitStatement,
  saveSaleMove,
  saveSaleMoveAll,
  mergeStatements,
  editStatement,
  findSaleByStatement,
  resetStatementSale,
  cancelStatement,
  forceCanceInvoice,
  downloadZip,
} from './redux/actions';
import moment, { Moment } from 'moment';
import Text from 'antd/lib/typography/Text';
import { Token, getToken, isAllowed } from 'security';
import JwtDecode from 'jwt-decode';
import { RouteComponentProps, Redirect } from 'react-router';
import CollectionCreateFormStatement from './components/CreateFormStatement';
import { FormComponentProps } from 'antd/lib/form';
import { omit } from 'lodash';
import './style.css';
import { Popups } from 'commons/components/popups/popups';
import { toNumber } from 'commons/numbersFormat';
import { StatementStagingItem } from './types';
import {
  initDataToCustomInvoice,
  initFiscalInformation,
  initSale,
  initCustomerAndUDR,
  initStatement,
  initStatementFilter,
} from 'commons/initTypes';
import { Permissions as P } from 'commons/permissions';
import { parseApiError } from '../../commons/error';
import TextArea from 'antd/lib/input/TextArea';
import InfiniteScroll from 'react-infinite-scroller';
import { DATE_FORMAT, getEndTime, getInitialTime } from 'commons/services';
import EditStatementComponent from './components/editForm';
import { isDateWrong } from 'routes/functions';
import AddExternalInvoiceModal from './components/AddExternalInvoiceModal.component';
import { ApiRoute, callApi } from 'commons/services/api';
import { Globals } from 'commons/globals';

const token =
  getToken() !== null ? JwtDecode<Token>(getToken() as string) : null;

const rowsAllowed = 10000;

interface DispatchProps {
  page: (customerCode: string, filters: StatementFilters) => void;
  save: (statement: FormData) => void;
  search: (customerCode: string, reference: string) => void;
  stampSale: (saleId: string, customData: DataToCustomInvoice) => void;
  downloadFile: (id: string, name: string, saleFolio: string) => void;
  downloadReport: (
    customerCode: string,
    statementFilters: StatementFilters,
  ) => void;
  getSalesByStatementId: (statementId: string) => void;
  resetStatementSales: () => void;
  getCustomersByFilter: (filter: string) => void;
  splitStatement: (id: string) => void;
  saveSaleMove: (modelSaleMove: ModelSaleMove) => void;
  saveSaleMoveAll: (modelSaleMove: ModelSaleMoveAll) => void;
  mergeStatements: (ids: string[]) => void;
  editStatement: (statement: Statement) => void;
  findSaleByStatement: (id: string) => void;
  resetStatementSale: () => void;
  cancelStatement: (id: string) => void;
  forceCanceInvoice: (id: string, selectedInvoiceId: string) => void;
  downloadZip: (code: string, filters: StatementFilters) => void;
}

interface StateProps {
  statementPage: StatementList;
  acountStatus: Acount;
  statementSearchList: Statement[];
  isFetching: boolean;
  statementSales: Sale[];
  customersList: CustomerAndUDR[];
  singleStatementSale: Sale;
}

interface HomeRouterProps {
  id: string;
}

type Props = StateProps &
  DispatchProps &
  FormComponentProps &
  RouteComponentProps<HomeRouterProps>;

interface StatementSates {
  invoiceStatus: string;
  unitType: string;
  visible: boolean;
  statementIdToStamp: string;
  stagingArea: StatementStagingItem[];
  isCreditNote: boolean;
  selectedRowKeys: string[];
  selectedRows: string[];
  expanded: string[];
  visibleInvoice: boolean;
  fiscalInformation: number;
  invoiceDescriptions: string[];
  visibleMove: boolean;
  visibleMoveAll: boolean;
  saleToApply: Sale;
  customerFilter: string;
  customerSelected: CustomerAndUDR;
  isEditFormVisible: boolean;
  statementToEdit: Statement;
  filters: StatementFilters;
  cancelationModal: boolean;
  cancelInvoiceId: string;
  selectedInvoiceId: string;
}

class SalesListComponent extends Component<Props & FormComponentProps> {
  public state: StatementSates = {
    invoiceStatus: 'ALL',
    unitType: 'L',
    visible: false,
    statementIdToStamp: '',
    visibleInvoice: false,
    stagingArea: [],
    selectedRowKeys: [],
    selectedRows: [],
    expanded: [],
    isCreditNote: false,
    fiscalInformation: 200,
    invoiceDescriptions: [''],
    visibleMove: false,
    visibleMoveAll: false,
    saleToApply: initSale,
    customerFilter: '',
    customerSelected: initCustomerAndUDR,
    isEditFormVisible: false,
    statementToEdit: initStatement,
    filters: initStatementFilter,
    cancelationModal: false,
    cancelInvoiceId: '',
    selectedInvoiceId: '',
  };

  private fetchData = async (): Promise<void> => {
    const { id } = this.props.match.params;
    if (token) {
      if (token.customerCode !== '') {
        await this.props.page(token.customerCode, this.state.filters);
      } else {
        await this.props.page(id, this.state.filters);
      }
    }
  };

  /**
   * This function execute when the component is mounting and get the first page from
   * sales
   */
  public componentDidMount(): void {
    this.fetchData();
  }

  /**
   * This function change page and replaces current position in the pagination
   */
  public handlePaginationChange = async (
    currentPage: number,
    pageSize?: number,
  ): Promise<void> => {
    let { filters } = this.state;
    filters.currentPage = currentPage;
    filters.pageSize = pageSize || 25;

    const { id } = this.props.match.params;
    if (token) {
      if (token.customerCode !== '') {
        await this.props.page(token.customerCode, filters);
      } else {
        await this.props.page(id, filters);
      }
    }

    this.setState({ filters, stagingArea: [], selectedRowKeys: [] });
  };

  public showModal = (isCreditNote: boolean): void => {
    this.setState({ visible: true, isCreditNote });
  };
  public showModalMove = (): void => {
    this.setState({ visibleMove: true });
  };
  public showModalMoveAll = (): void => {
    this.setState({ visibleMoveAll: true });
  };

  public handleCancelPaymentForm = (): void => {
    this.setState({ visible: false });
  };

  public handleCreatePayment = (): void => {
    const form = this.props.form;
    form.validateFields(
      async (err, values): Promise<void> => {
        if (!err) {
          try {
            if (values.amount <= 0) {
              Popups.notifyError('No se puede guardar con monto 0');
              return;
            }
            await this.props.save(this.buildEntity(values));
            await this.fetchData();
            form.resetFields();
            this.setState({
              visible: false,
              stagingArea: [],
              selectedRowKeys: [],
            });
            Popups.notifySuccess('Guardado Exitoso');
          } catch (err) {
            Popups.notifyError(parseApiError(err).description);
          }
        }
      },
    );
  };

  public handleDeleteChargesToPay = (passedInId: string): void => {
    const { stagingArea, selectedRowKeys } = this.state;

    const newArray = stagingArea.filter(
      (item: StatementStagingItem): boolean => item.id !== passedInId,
    );

    const newArrayRow = selectedRowKeys.filter(
      (item: string): boolean => item !== passedInId,
    );

    this.setState({
      stagingArea: newArray,
      selectedRowKeys: newArrayRow,
    });
  };
  public handleChangePrice = (
    passed: StatementStagingItem,
    price: number,
  ): void => {
    const { stagingArea } = this.state;

    const newArray = stagingArea.map(
      (item: StatementStagingItem): StatementStagingItem => {
        if (passed.id === item.id) {
          item.amountToPay = price;
        }
        return item;
      },
    );
    this.setState({
      stagingArea: newArray,
    });
  };

  // eslint-disable-next-line
  private handleChangePaymentForm = (value: any, option: any): void => {
    const { stagingArea, selectedRowKeys } = this.state;
    const id = option.props.children[0].props.children;
    const typeStatement = option.props.children[1].props.children;
    const reference = option.props.children[2].props.children;
    const amount = option.props.children[3].props.children.replace(/,/g, '');
    const days = option.props.children[4].props.children;
    const itemState: string[] = [];
    const invoiceFolio = option.props.children[8].props.children;
    const invoiceSerie = option.props.children[9].props.children;
    let valitador = false;
    stagingArea.forEach((item: StatementStagingItem): void => {
      if (item.id === option.props.children[0].props.children) {
        valitador = true;
      }
    });
    let keysRows = selectedRowKeys !== undefined ? selectedRowKeys : [];

    if (valitador === false) {
      let newStagingArea: StatementStagingItem[] = stagingArea;

      if (
        this.state.stagingArea.length >= 1 &&
        this.state.isCreditNote === true
      ) {
        newStagingArea = [];
        keysRows = [];
      }
      newStagingArea.push({
        id,
        typeStatement,
        reference,
        amount,
        amountToPay: amount,
        days,
        itemState,
        totalInvoice: undefined,
        invoiceFolio,
        invoiceSerie,
      });
      keysRows.push(option.props.children[0].props.children);
      this.setState({
        stagingArea: newStagingArea,
        selectedRowKeys: [...keysRows],
      });
    }
  };

  public onOkMoveModal = (): void => {
    const asyncAction = async (): Promise<void> => {
      const customerId =
        this.state.customerFilter === ''
          ? this.state.saleToApply.customerId
          : this.state.customerFilter.substring(
              0,
              this.state.customerFilter.indexOf(','),
            );
      const udrCode =
        this.state.customerFilter === ''
          ? this.state.saleToApply.reseptionUnit
          : this.state.customerFilter.substring(
              this.state.customerFilter.indexOf(',') + 1,
              this.state.customerFilter.length,
            );
      //Get ids from statements to move
      const statementIds = this.getSelectedStatementsToMove().map(
        (s: StatementStagingItem): string => s.id,
      );

      if (udrCode === '') {
        Popups.notifyError('Ingrese un cliente, por favor');
      } else {
        const modal: ModelSaleMove = {
          customerId,
          udrCode,
          statementIds,
        };
        await this.props.saveSaleMove(modal);
        await this.fetchData();
        await this.setState({
          visibleMove: false,
          stagingArea: [],
          selectedRowKeys: [],
          customerFilter: '',
          customerSelected: null,
        });
      }
    };
    asyncAction();
  };
  public onOkMoveAllModal = (): void => {
    const asyncAction = async (): Promise<void> => {
      const customerId =
        this.state.customerFilter === ''
          ? this.state.saleToApply.customerId
          : this.state.customerFilter.substring(
              0,
              this.state.customerFilter.indexOf(','),
            );
      const udrCode =
        this.state.customerFilter === ''
          ? this.state.saleToApply.reseptionUnit
          : this.state.customerFilter.substring(
              this.state.customerFilter.indexOf(',') + 1,
              this.state.customerFilter.length,
            );

      if (udrCode === '') {
        Popups.notifyError('Ingrese un cliente, por favor');
      } else {
        const oldCustomerCode = this.props.match.params.id;
        const modal: ModelSaleMoveAll = {
          oldCustomerCode,
          customerId,
          udrCode,
        };
        await this.props.saveSaleMoveAll(modal);
        await this.fetchData();
        await this.setState({
          visibleMoveAll: false,
          stagingArea: [],
          selectedRowKeys: [],
          customerFilter: '',
          customerSelected: null,
        });
      }
    };
    asyncAction();
  };
  private onCancelMoveModal = (): void => {
    this.setState({
      visibleMove: false,
      stagingArea: [],
      selectedRowKeys: [],
      customerFilter: '',
      customerSelected: null,
    });
  };
  private onCancelMoveAllModal = (): void => {
    this.setState({
      visibleMoveAll: false,
      stagingArea: [],
      selectedRowKeys: [],
      customerFilter: '',
      customerSelected: null,
    });
  };
  public onChangeFilters = (selectedFilter: string, value: string): void => {
    let { filters } = this.state;

    //When the movement type filter changes, validate the type and search by the value received
    if (selectedFilter === 'mov') {
      let newVal = undefined;
      if (value === 'Sales') newVal = true;

      if (value === 'Payments') newVal = false;

      filters.paymentType = undefined;
      filters.statusType = undefined;
      filters.movementType = newVal;
    }

    //When "Payments" is selected in movement type filter & the payment type filter changes, search by value received
    if (selectedFilter === 'pay') {
      let newVal = undefined;
      if (value !== '')
        newVal = value === 'CreditNotes' ? 'Nota de Crédito' : 'Pago';

      filters.paymentType = newVal;
    }

    //When status filter changes, search by value received
    if (selectedFilter === 'sta') filters.statusType = value;

    //When udr filter changes, search by value received
    if (selectedFilter === 'udr') {
      let newVal = undefined;
      if (value !== '') newVal = value;

      filters.receptionUnit = newVal;
    }

    //When serie or folio or initDate or endDate filter changes, search by value received
    if (selectedFilter === 'serie') filters.invoiceSerie = value;
    if (selectedFilter === 'folio') filters.invoiceFolio = value;
    if (selectedFilter === 'initDate') filters.initDate = value;
    if (selectedFilter === 'endDate') filters.endDate = value;

    filters.currentPage = 1;

    // Update state properties
    this.setState({ filters, stagingArea: [], selectedRowKeys: [] });
  };

  private applyFilters = (): void => {
    const loadInfo = async (): Promise<void> => {
      const { id } = this.props.match.params;
      let newId = id;
      if (token && token.customerCode !== '') newId = token.customerCode;
      await this.props.page(newId, this.state.filters);
    };
    loadInfo();
  };

  private onCloseEditForm = (): void => {
    this.setState({ isEditFormVisible: false, statementToEdit: initStatement });
    this.props.resetStatementSale();
  };

  private onSaveUpdatedStatement = (): void => {
    this.props.form.validateFields(
      async (errors, values: Statement): Promise<void> => {
        if (!errors) {
          const statement = this.state.statementToEdit;
          statement.paymentCondition = values.paymentCondition;
          statement.paymentWay = values.paymentWay;
          statement.idUserCreate = token ? token.id : '';
          statement.customerInvoiceRfc = values.customerInvoiceRfc;
          if (values.paymentCondition !== 'CASH')
            statement.paymentWay = 'Por definir';
          await this.props.editStatement(statement);
          await this.props.resetStatementSale();
          this.props.form.resetFields();
          await this.fetchData();
          this.setState({
            isEditFormVisible: false,
            statementToEdit: initStatement,
          });
        }
      },
    );
  };

  // eslint-disable-next-line
  public buildEntity = (values: any): FormData => {
    let formData = new FormData();
    //Set apply status for payment/credit note
    let applyStatus = token
      ? token.customerCode === ''
        ? 'APLICADA'
        : 'PENDIENTE'
      : 'PENDIENTE';
    if (!isAllowed([P.CAN_APPLY_PAYMENTS]) && !this.state.isCreditNote)
      applyStatus = 'PENDIENTE';

    const newValues = {
      id: values.id,
      statementRelation: this.state.stagingArea.map(
        (
          element: StatementStagingItem,
        ): {
          idPayment: string;
          idCharge: string;
          amount: number;
        } => {
          return {
            idPayment: values.id,
            idCharge: element.id,
            amount: this.state.isCreditNote ? 0 : element.amountToPay,
          };
        },
      ),
      createdAt: values.createdAt,
      updatedAt: values.updatedAt,
      amount: values.amount ? values.amount : 0,
      balance: values.amount ? values.amount : 0,
      paymentForm: this.state.isCreditNote ? '' : values.paymentForm,
      bank: this.state.isCreditNote ? 'Nota de Crédito' : values.bank,
      reference: this.state.isCreditNote ? '' : values.reference,
      acountNumber: this.state.isCreditNote ? '' : values.acountNumber,

      paymentDate: values.paymentDate ? values.paymentDate : moment(),
      cieKey: values.cieKey,
      creditNote: this.state.isCreditNote,
      idUserCreate: token ? token.id : '',
      idUserUpdate: token ? (token.customerCode === '' ? token.id : '') : '',
      customerCode: token
        ? token.customerCode !== ''
          ? token.customerCode
          : this.props.match.params.id
        : null,
      apply: applyStatus,
      pemexPrice: values.pemexPrice,
      totalWeight: values.totalWeight,
    };

    if (this.state.isCreditNote) {
      const auxEstate = omit(newValues, ['images']);
      const payment = Object.assign(
        {},
        {
          ...auxEstate,
          fileType: '',
          fileExt: '',
        },
      );
      formData.append('payment', JSON.stringify(payment));
      //formData.append('images', values.voucher[0].originFileObj || []);
      return formData;
    }
    const auxEstate = omit(newValues, ['images']);
    const payment = Object.assign(
      {},
      {
        ...auxEstate,
        fileType: values.voucher[0].originFileObj.type,
        fileExt: values.voucher[0].originFileObj.name.substring(
          values.voucher[0].name.lastIndexOf('.') + 1,
        ),
      },
    );
    formData.append('payment', JSON.stringify(payment));
    formData.append('images', values.voucher[0].originFileObj || []);
    return formData;
  };

  public search = (value: string): void => {
    if (value.length > 2) {
      this.props.getCustomersByFilter(value.toLowerCase());
    }
    this.setState({ customerFilter: value.toLowerCase() });
  };
  public select = (value: string): void => {
    this.setState({
      customerFilter: value,
      customerSelected:
        this.props.customersList.find(
          (d: CustomerAndUDR): boolean => d.customer.id + ',' + d.udr === value,
        ) || initCustomerAndUDR,
    });
  };

  public showModalInvoice = (
    visibleInvoice: boolean,
    statementIdToStamp: string,
  ): void => {
    if (visibleInvoice) this.props.getSalesByStatementId(statementIdToStamp);
    else this.props.resetStatementSales();
    this.setState({ visibleInvoice, statementIdToStamp });
  };

  public addDataAndSendCharge = (
    saleId: string,
    statementSales: Sale[],
    observations: string,
  ): void => {
    const asyncAction = async (): Promise<void> => {
      try {
        //Get variables from state
        const { fiscalInformationList } = this.props.acountStatus;
        const { fiscalInformation, filters } = this.state;

        const fiscalInformations: FiscalInformation[] = fiscalInformationList
          ? fiscalInformationList
          : [initFiscalInformation];
        let fiscalInformationItem: FiscalInformation[] = [];

        //Get the fiscal information by index to stamp
        if (fiscalInformation !== 100) {
          fiscalInformationItem = fiscalInformations.filter(
            (item: FiscalInformation, index: number): boolean =>
              index === fiscalInformation,
          );
        } else {
          //Get fiscal information to stamp for general customer
          let fiscalInformationVal: FiscalInformation = initFiscalInformation;
          fiscalInformationVal.businessName = 'PÚBLICO GENERAL';
          fiscalInformationVal.formaPago = '01';
          fiscalInformationVal.rfc = 'XAXX010101000';
          fiscalInformationVal.usoCfdi = 'S01';
          fiscalInformationVal.fiscalRegime = '616';
          fiscalInformationVal.zipCode = '20140';
          fiscalInformationItem.push(fiscalInformationVal);
        }

        //Add extra information typed
        let customData: DataToCustomInvoice = initDataToCustomInvoice;
        customData.fiscalInformation = fiscalInformationItem[0];
        customData.observations = observations;
        customData.statementSales = statementSales;

        //Send request to stamp
        await this.props.stampSale(saleId, customData);

        //Refresh page registers
        const { id } = this.props.match.params;
        filters.currentPage = 1;

        if (token) {
          if (token.customerCode !== '') {
            this.props.page(token.customerCode, filters);
          } else {
            this.props.page(id, filters);
          }
        }
        Popups.notifySuccess('Guardado Exitoso');
      } catch (err) {
        Popups.notifyError(parseApiError(err).description);
      }
    };
    asyncAction();
  };

  public stampChargeModal = (
    visibleInvoice: boolean,
    statementSales: Sale[],
    observations: string,
  ): void => {
    if (this.state.fiscalInformation === 200) {
      Popups.notifyError(
        '"Seleccione al menos una Información fiscal, Puede agregar una o más en la edición / Alta del cliente"',
      );
    } else {
      //Reset statement sales in props and call method to stamp
      this.props.resetStatementSales();
      this.props.form.resetFields();
      this.addDataAndSendCharge(
        this.state.statementIdToStamp,
        statementSales,
        observations,
      );
      this.setState({ visibleInvoice });
    }
  };

  private onSearchChargeToPay = (val: string): void => {
    if (val.length > 2) {
      const { id } = this.props.match.params;
      if (token) {
        if (token.customerCode !== '') {
          this.props.search(token.customerCode, val);
        } else {
          this.props.search(id, val);
        }
      }
    }
  };
  public selected = (record: Statement, selected: boolean): void => {
    if (selected === false) {
      const myItems: StatementStagingItem[] = this.state.stagingArea;
      const newArray = myItems.filter(
        (item: StatementStagingItem): boolean => item.id !== record.id,
      );
      this.setState({
        stagingArea: newArray,
      });
    }
  };
  public onSelectAll = (selected: boolean, changeRows: Statement[]): void => {
    if (selected === false) {
      let newStagingArea: StatementStagingItem[] = this.state.stagingArea;
      changeRows.forEach((element: Statement): void => {
        let ifExist = null;
        newStagingArea.forEach(
          (item: StatementStagingItem, index: number): void => {
            if (element.id === item.id) ifExist = index;
          },
        );

        if (ifExist !== null) {
          newStagingArea.splice(ifExist, 1);
        }
      });

      this.setState({
        stagingArea: newStagingArea,
      });
    }
  };

  public onSelectChange = (
    selectedRowKey: string[] | number[],
    selectedRows: Statement[],
  ): void => {
    const stagingAreaSelected: StatementStagingItem[] = selectedRows.map(
      (statement: Statement, index: number): StatementStagingItem => {
        const id = statement.id;
        const typeStatement =
          moment().diff(moment(statement.creditDays)) >= 0 &&
          statement.balance > 0
            ? 'VEN '
            : 'PEN ';
        const reference = statement.reference;
        const amount = statement.balance;
        const itemState: Statement = selectedRows[index];
        const days = `${moment(statement.creditDays).diff(
          moment().format(DATE_FORMAT),
          'days',
        )}${this.showDayText(
          moment(statement.creditDays).diff(
            moment().format(DATE_FORMAT),
            'days',
          ),
        )}${moment(statement.creditDays).format(DATE_FORMAT)})`;
        const totalInvoice = statement.totalInvoice;
        const invoiceFolio = statement.invoiceFolio
          ? statement.invoiceFolio
          : null;
        const invoiceSerie = statement.invoiceSerie
          ? statement.invoiceSerie
          : null;
        const stagingArea: StatementStagingItem = {
          id,
          typeStatement,
          reference,
          amount,
          amountToPay: amount,
          days,
          itemState: [itemState.id],
          totalInvoice,
          customerInvoiceRfc: itemState.customerInvoiceRfc,
          invoiceFolio,
          invoiceSerie,
        };

        return stagingArea;
      },
    );
    const newSelectedRowKeys: string[] = selectedRowKey as string[];
    let preStagingArea = stagingAreaSelected.filter(
      (item: StatementStagingItem): boolean => item !== undefined,
    );
    let preSelectedRows: string[] = newSelectedRowKeys.filter(
      (item: string): boolean => item !== undefined,
    );
    let newpreStagingArea: StatementStagingItem[] = preStagingArea.filter(
      (item: StatementStagingItem): boolean => {
        let stagingAreaFinded:
          | StatementStagingItem
          | undefined = this.state.stagingArea.find(
          (stagingArea: StatementStagingItem): boolean =>
            stagingArea.id === item.id,
        );
        return (
          stagingAreaFinded === undefined || stagingAreaFinded.id !== item.id
        );
      },
    );
    this.setState({
      selectedRowKeys: preSelectedRows,
      stagingArea:
        this.state.stagingArea.length < 1
          ? preStagingArea
          : [...this.state.stagingArea, ...newpreStagingArea],
    });
  };
  public showDayText = (days: number): string => {
    return days !== 1 ? 'Dias (' : 'Dia (';
  };
  public checkedDisable = (record: Statement): boolean => {
    if (record.customerInvoiceRfc && this.state.stagingArea.length > 0) {
      if (this.state.stagingArea[0].customerInvoiceRfc) {
        if (
          this.state.stagingArea[0].customerInvoiceRfc !==
          record.customerInvoiceRfc
        ) {
          return true;
        }
      }
    }
    if (!record.sale) {
      return true;
    }

    return false;
  };

  private setDescriptionsToSales = (
    statementSales: Sale[],
    invoiceDescriptions: string[],
  ): Sale[] => {
    const updatedSales: Sale[] = statementSales.map(
      (sale: Sale, index: number): Sale => {
        const tag = sale.tag ? sale.tag : '';
        sale.invoiceDescription = `Gas LP ${sale.salesFolio} ${tag} ${invoiceDescriptions[index]}`;
        return sale;
      },
    );
    return updatedSales;
  };

  /* Based on statement reference, returns true if it has more than 1 sale (a comma is found in the string reference) */
  private hasMultipleSales = (reference: string): boolean => {
    return reference.includes(',');
  };

  private getStatementIdsToMerge = (): string[] => {
    //Get the list of statement selected according to conditions and make request
    return this.state.stagingArea
      .filter(
        (s: StatementStagingItem): boolean =>
          (s.invoiceFolio === null && !this.hasMultipleSales(s.reference)) ||
          (s.invoiceFolio !== null &&
            !s.invoiceFolio.includes('CANCELADO') &&
            !this.hasMultipleSales(s.reference)),
      )
      .map((s: StatementStagingItem): string => s.id);
  };

  private executeSelectedAction = (value: string, record: Statement): void => {
    switch (value) {
      case 'toInvoice':
        // Show modal to invoice
        this.showModalInvoice(true, record.id);
        break;
      case 'pdf':
        //Download PDF file
        this.props.downloadFile(
          record.invoice !== undefined ? record.invoice : '',
          'file.pdf',
          record.createdAt,
        );
        break;
      case 'xml':
        // Download XML file
        this.props.downloadFile(
          record.invoice !== undefined ? record.invoice : '',
          'file.xml',
          record.createdAt,
        );
        break;
      case 'edit':
        const getSale = async (): Promise<void> => {
          await this.props.findSaleByStatement(record.id);
        };
        getSale();
        //Show modal to edit Statement
        this.setState({
          isEditFormVisible: true,
          statementToEdit: record,
        });
        break;
      default:
        break;
    }
  };

  private getSelectedStatementsToMove = (): StatementStagingItem[] => {
    return this.state.stagingArea.filter(
      (s: StatementStagingItem): boolean =>
        s.invoiceFolio === null ||
        //Show this option when is sale and it's canceled
        (s.invoiceSerie !== null && s.invoiceSerie.includes('CANCELADO')),
    );
  };

  public renderDownloadFiles = (record: Statement): JSX.Element => {
    const tokenRfc: string =
      getToken() !== null ? JwtDecode<Token>(getToken() as string).rfc : '';
    if (tokenRfc !== '' && record.invoice === undefined) {
      return <div />;
    }
    let errorIcon = <div />;
    if (record.invoiceError !== null) {
      //If the invoice had some error, show it in a tooltip of a warning icon
      errorIcon = (
        <Col span={5}>
          <Tooltip title={record.invoiceError}>
            <Icon type="warning" style={{ fontSize: '26px', color: 'red' }} />
          </Tooltip>
        </Col>
      );
    }
    return record.invoice !== undefined &&
      record.invoice !== null &&
      record.invoiceDownloaded &&
      record.invoiceError === null ? (
      <div>
        <Button
          className="ant-button"
          key={record.id + '4'}
          onClick={(): void =>
            this.props.downloadFile(
              record.invoice !== undefined ? record.invoice : '',
              'file.pdf',
              record.createdAt,
            )
          }
        >
          <Icon type="download" />
          PDF
        </Button>
        <Button
          className="ant-button"
          key={record.id + '3'}
          onClick={(): void =>
            this.props.downloadFile(
              record.invoice !== undefined ? record.invoice : '',
              'file.xml',
              record.createdAt,
            )
          }
        >
          <Icon type="download" />
          XML
        </Button>
      </div>
    ) : (
      errorIcon
    );
  };

  public renderActions = (item: Statement): JSX.Element => {
    return (
      <Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          {this.renderDownloadFiles(item)}
          <p />
        </Col>
      </Row>
    );
  };

  private renderStatus = (item: Statement): React.ReactNode => {
    if (item.sale) {
      const pendingPayment =
        item.statusStatment === 'VERIFICANDO' ? ' Con pago pendiente' : '';
      const tag =
        moment().diff(moment(item.creditDays)) >= 0 && item.balance > 0 ? (
          <Tag color="#D71F49">Vencida</Tag>
        ) : item.balance === item.amount ? (
          <div>
            <Tag color="gray">Pendiente</Tag>
          </div>
        ) : item.balance > 0 ? (
          <Tag color="yellow">Parcial</Tag>
        ) : (
          <Tag color="blue">Pagada</Tag>
        );
      return (
        <div>
          {tag}
          {pendingPayment}
        </div>
      );
    } else {
      if (item.balance === item.amount) {
        return <Tag>En revisión</Tag>;
      } else {
        return item.balance > 0 ? (
          <Tag color="green">Parcial</Tag>
        ) : (
          <Tag color="cyan">Aplicado</Tag>
        );
      }
    }
  };

  private renderDetails = (item: Statement): JSX.Element => {
    return (
      <Row>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong className={'ticket-text-name'}>
            {'Estatus de pago: '}
          </Text>
          {this.renderStatus(item)}
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Tipo de movimiento: '}</Text>
          <Text>{item.sale ? 'Cargo' : 'Abono'}</Text>
        </Col>

        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Fecha y hora del movimiento: '}</Text>
          <Text>{moment(item.movementDate).format(DATE_FORMAT)}</Text>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Referencia: '}</Text>
          <Text>{item.reference}</Text>
        </Col>
        <Col xs={24} sm={24} md={3} lg={3} xl={3} xxl={3}>
          <Text strong>{'Monto: '}</Text>
          {item.sale ? (
            <Text className="font-color-down">
              {item.totalInvoice !== 0 && item.totalInvoice !== null
                ? '- $' + toNumber(item.totalInvoice || 0) + ' '
                : '- $' + toNumber(item.amount) + ' '}
              <Icon type="arrow-down" />
            </Text>
          ) : (
            <Text className="font-color-up">
              {item.totalInvoice !== 0 && item.totalInvoice !== null
                ? '- $' + toNumber(item.totalInvoice || 0) + ' '
                : '- $' + toNumber(item.amount) + ' '}
              <Icon type="arrow-up" />
            </Text>
          )}
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Saldo: '}</Text>
          <Text>{item.balance}</Text>
        </Col>

        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          {item.sale
            ? moment(item.creditDays).diff(
                moment().format(DATE_FORMAT),
                'days',
              ) +
              this.showDayText(
                moment(item.creditDays).diff(
                  moment().format(DATE_FORMAT),
                  'days',
                ),
              ) +
              moment(item.creditDays).format(DATE_FORMAT) +
              ')'
            : 'N/A'}
        </Col>
      </Row>
    );
  };

  private renderEditForm = (): ReactNode => {
    return (
      <EditStatementComponent
        isVisible={this.state.isEditFormVisible}
        onCancel={(): Function => this.onCloseEditForm}
        onCreate={(): Function => this.onSaveUpdatedStatement}
        isFetching={this.props.isFetching}
        form={this.props.form}
        statement={this.state.statementToEdit}
        sale={this.props.singleStatementSale}
      />
    );
  };

  public render = (): React.ReactNode => {
    const { selectedRowKeys, cancelationModal } = this.state;
    const { statementPage, statementSearchList } = this.props;
    const {
      balance,
      balanceLost,
      fiscalInformationList,
    } = this.props.acountStatus;
    if (!localStorage.getItem('token')) return <Redirect to="/login" />;
    return (
      <div className={'content-backgroud'}>
        <Row>
          <Row>
            <Col
              xs={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              md={{ span: 11, offset: 1 }}
              lg={{ span: 11, offset: 1 }}
              xl={{ span: 6, offset: 0 }}
              xxl={{ span: 6, offset: 0 }}
            >
              <Text disabled>{'Estado de cuenta'}</Text>
              <div />
              <Text strong className={'title-text-name'} id="customer-name">
                {this.props.acountStatus.customerComercialName + ' '}
              </Text>
              <Text>{this.props.acountStatus.customerBusinessName}</Text>
              <p />
            </Col>
            <Col
              xs={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              md={{ span: 11, offset: 1 }}
              lg={{ span: 11, offset: 1 }}
              xl={{ span: 4, offset: 0 }}
              xxl={{ span: 4, offset: 0 }}
            >
              <Card
                className="card-statement"
                id={balance < 0 ? 'pro-sale-state' : ''}
              >
                <Text>{'Saldo'}</Text>
                <Text>
                  <h3 id={balance < 0 ? 'pro-state-text' : ''}>
                    {'$' +
                      (balance !== null
                        ? balance < 0
                          ? toNumber(Math.abs(balance))
                          : toNumber(balance)
                        : 0)}
                  </h3>
                </Text>
              </Card>
            </Col>
            <Col
              xs={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              md={{ span: 11, offset: 1 }}
              lg={{ span: 11, offset: 1 }}
              xl={{ span: 4, offset: 0 }}
              xxl={{ span: 4, offset: 0 }}
            >
              <Card
                className="card-statement"
                id={balanceLost > 0 ? 'against-sale-state' : ''}
              >
                <Text>{'Saldo vencido'}</Text>
                <Text>
                  <h3 id={balanceLost > 0 ? 'against-state-text' : ''}>
                    {'$' + (balanceLost > 0 ? toNumber(balanceLost) : 0)}
                  </h3>
                </Text>
              </Card>
            </Col>
            <Col
              xs={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              md={{ span: 11, offset: 1 }}
              lg={{ span: 11, offset: 1 }}
              xl={{ span: 4, offset: 0 }}
              xxl={{ span: 4, offset: 0 }}
            >
              <Card className="card-statement">
                <Text>{'Crédito disponible'}</Text>
                <Text>
                  <h3>
                    {'$' +
                      (this.props.acountStatus.creditAvailable !== null
                        ? toNumber(this.props.acountStatus.creditAvailable)
                        : 0)}
                  </h3>
                </Text>
              </Card>
            </Col>
            <Col
              xs={{ span: 7, offset: 1 }}
              sm={{ span: 7, offset: 1 }}
              md={{ span: 11, offset: 1 }}
              lg={{ span: 11, offset: 1 }}
              xl={{ span: 4, offset: 0 }}
              xxl={{ span: 4, offset: 0 }}
            >
              <Card className="card-statement">
                <Text>{'Límite de Crédito'}</Text>
                <Text>
                  <h3>
                    {'$' +
                      (this.props.acountStatus.creditLimit !== null
                        ? toNumber(this.props.acountStatus.creditLimit)
                        : 0)}
                  </h3>
                </Text>
              </Card>
            </Col>
            <Col
              xs={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              md={{ span: 2, offset: 21 }}
              lg={{ span: 2, offset: 21 }}
              xl={{ span: 2, offset: 22 }}
              xxl={{ span: 2, offset: 22 }}
            >
              {this.state.visible && (
                <CollectionCreateFormStatement
                  maxAmount={
                    this.state.isCreditNote
                      ? 0
                      : this.props.acountStatus.balance
                  }
                  visible={this.state.visible}
                  isCreditNote={this.state.isCreditNote}
                  onCancel={(): Function => this.handleCancelPaymentForm}
                  onCreate={(): Function => this.handleCreatePayment}
                  handleChange={(): Function => this.handleChangePaymentForm}
                  onSearch={(): Function => this.onSearchChargeToPay}
                  form={this.props.form}
                  handleDelete={(passedInId: string): void =>
                    this.handleDeleteChargesToPay(passedInId)
                  }
                  handleChangePrice={(
                    passedStatement: StatementStagingItem,
                    price: number,
                  ): void => this.handleChangePrice(passedStatement, price)}
                  stagingArea={this.state.stagingArea.filter(
                    (s: StatementStagingItem): boolean =>
                      s.totalInvoice !== null,
                  )}
                  statementSearchList={statementSearchList}
                  customerName={
                    token
                      ? token.customerCode === ''
                        ? this.props.acountStatus.customerComercialName
                        : 'Local'
                      : 'Local'
                  }
                  account={this.props.acountStatus}
                />
              )}
              {
                <Modal
                  visible={cancelationModal}
                  title={'Ingrese el Timbre UUID DEl PORTAL DEL SAT'}
                  okText={'Forzar'}
                  cancelText={'Cancelar'}
                  okType={'danger'}
                  onCancel={(): void =>
                    this.setState({ cancelationModal: false })
                  }
                  onOk={(): void => {
                    const asyncAction = async (): Promise<void> => {
                      const { cancelInvoiceId, selectedInvoiceId } = this.state;
                      const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
                      const regexpv1 = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
                      if (
                        regexExp.test(cancelInvoiceId) ||
                        regexpv1.test(cancelInvoiceId)
                      ) {
                        await this.setState({ cancelationModal: false });
                        await this.props.forceCanceInvoice(
                          cancelInvoiceId,
                          selectedInvoiceId,
                        );
                        await this.fetchData();
                      } else {
                        notification.error({
                          message: 'Notification Title',
                          description: 'No es un Timbre con formato válido',
                        });
                      }
                    };
                    asyncAction();
                  }}
                >
                  <Input
                    style={{ width: '100%' }}
                    placeholder="Descripción"
                    addonBefore={`Ingrese el timbre uuid`}
                    onChange={(e): void =>
                      this.setState({
                        cancelInvoiceId: e.currentTarget.value,
                      })
                    }
                  />
                  ,
                </Modal>
              }
              {
                <Modal
                  width={800}
                  visible={this.state.visibleInvoice}
                  title={'Facturar a '}
                  okText="Facturar"
                  cancelText="Cancelar"
                  onCancel={(): void => this.showModalInvoice(false, '')}
                  onOk={(): void => {
                    //Update sales with their descriptions
                    const updatedSales: Sale[] = this.setDescriptionsToSales(
                      this.props.statementSales,
                      this.props.form.getFieldsValue().invoiceDescriptions,
                    );
                    //Get observations value
                    const observations: string = this.props.form.getFieldsValue()
                      .observations;
                    this.stampChargeModal(false, updatedSales, observations);
                  }}
                >
                  <Row>
                    {'Seleccione uno de los siguientes Datos Fiscales:'}
                  </Row>
                  <p />
                  <Row className="invoice-modal-row">
                    <Select
                      defaultValue={200}
                      size="large"
                      style={{ width: '100%' }}
                      onChange={(fiscalInformation: number): void =>
                        this.setState({ fiscalInformation })
                      }
                    >
                      <Select.Option key={0} value={200}>
                        {'Selecciona uno'}
                      </Select.Option>
                      <Select.Option key={10} value={100}>
                        {'Nombre: ' +
                          'PÚBLICO GENERAL' +
                          ' RFC: ' +
                          'XAXX010101000' +
                          ' Uso CFDI:' +
                          'Gastos en general' +
                          ' F. pago: ' +
                          'Efectivo'}
                      </Select.Option>
                      {(fiscalInformationList !== undefined &&
                      fiscalInformationList !== null
                        ? fiscalInformationList
                        : [initFiscalInformation]
                      ).map(
                        (
                          option: FiscalInformation,
                          index: number,
                        ): JSX.Element => (
                          <Select.Option key={index} value={index}>
                            {'Nombre: ' +
                              option.businessName +
                              ' RFC: ' +
                              option.rfc +
                              ' Uso CFDI:' +
                              option.usoCfdi +
                              ' F. pago: ' +
                              option.formaPago}
                          </Select.Option>
                        ),
                      )}
                    </Select>
                  </Row>
                  <Row>
                    <div className="invoicing-scroll-container">
                      <InfiniteScroll
                        initialLoad={false}
                        pageStart={0}
                        loadMore={(): void => {}}
                        hasMore={false}
                        useWindow={false}
                      >
                        {this.props.statementSales.map(
                          (sale: Sale, index: number): React.ReactNode => {
                            return (
                              <Row key={index}>
                                <Col span={24}>
                                  <Form.Item style={{ marginBottom: '12px' }}>
                                    {this.props.form.getFieldDecorator(
                                      `invoiceDescriptions[${index}]`,
                                      {
                                        initialValue: '',
                                        rules: [
                                          {
                                            max: 100,
                                            message: 'Máximo 100 caracteres',
                                          },
                                        ],
                                      },
                                    )(
                                      <Input
                                        style={{ width: '100%' }}
                                        placeholder="Descripción"
                                        addonBefore={`GAS LP ${sale.salesFolio} ${sale.tag} `}
                                      />,
                                    )}
                                  </Form.Item>
                                </Col>
                              </Row>
                            );
                          },
                        )}
                      </InfiniteScroll>
                    </div>
                  </Row>
                  <Row className="invoice-modal-row">
                    <Form.Item
                      style={{ marginBottom: '12px' }}
                      label="Observaciones"
                    >
                      {this.props.form.getFieldDecorator(`observations`, {
                        initialValue: '',
                      })(
                        <TextArea
                          rows={3}
                          placeholder="Si se tiene alguna observación, introdúzcala aquí"
                        />,
                      )}
                    </Form.Item>
                  </Row>
                </Modal>
              }
            </Col>
          </Row>
          <Row>
            <Modal
              title={'Mover cargos a otro cliente'}
              visible={this.state.visibleMove}
              onOk={this.onOkMoveModal}
              onCancel={this.onCancelMoveModal}
              okText="Guardar"
              cancelText="Cancelar"
            >
              <Row>
                <Row>
                  <Col span={24}>
                    <div>
                      <List
                        size="small"
                        bordered
                        dataSource={this.getSelectedStatementsToMove()}
                        renderItem={(
                          item: StatementStagingItem,
                        ): JSX.Element => (
                          <List.Item key={item.id}>
                            <Typography.Text mark>
                              {item.reference}{' '}
                            </Typography.Text>{' '}
                            {', Total: $' + toNumber(item.amount)}
                          </List.Item>
                        )}
                      />
                    </div>
                  </Col>
                </Row>
                <Row style={{ paddingTop: '30px' }}>
                  {'Seleccione el cliente al cual se cambiarán los cargos:'}
                </Row>
                <Row>
                  <Col span={24}>
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Seleccione un Cliente"
                      onSearch={(value: string): void => this.search(value)}
                      optionFilterProp="children"
                      value={this.state.customerFilter}
                      onSelect={this.select}
                      loading={this.props.isFetching}
                    >
                      {(this.props.customersList || [initCustomerAndUDR]).map(
                        (item: CustomerAndUDR): React.ReactNode => (
                          <Select.Option
                            key={item.customer.id}
                            value={item.customer.id + ',' + item.udr}
                          >
                            {'Nom: ' +
                              item.customer.comercialName +
                              ', Cod: ' +
                              item.customer.code +
                              ', UDR: ' +
                              item.udr}
                          </Select.Option>
                        ),
                      )}
                    </Select>
                  </Col>
                </Row>
              </Row>
            </Modal>
          </Row>
          <Row>
            <Modal
              title={'Mover todos loscargos a otro cliente'}
              visible={this.state.visibleMoveAll}
              onOk={this.onOkMoveAllModal}
              onCancel={this.onCancelMoveAllModal}
              okText="Guardar"
              cancelText="Cancelar"
            >
              <Row>
                <Row style={{ paddingTop: '30px' }}>
                  {'Seleccione el cliente al cual se cambiarán los cargos:'}
                </Row>
                <Row>
                  <Col span={24}>
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Seleccione un Cliente"
                      onSearch={(value: string): void => this.search(value)}
                      optionFilterProp="children"
                      value={this.state.customerFilter}
                      onSelect={this.select}
                      loading={this.props.isFetching}
                    >
                      {(this.props.customersList || [initCustomerAndUDR]).map(
                        (item: CustomerAndUDR): React.ReactNode => (
                          <Select.Option
                            key={item.customer.id}
                            value={item.customer.id + ',' + item.udr}
                          >
                            {'Nom: ' +
                              item.customer.comercialName +
                              ', Cod: ' +
                              item.customer.code +
                              ', UDR: ' +
                              item.udr}
                          </Select.Option>
                        ),
                      )}
                    </Select>
                  </Col>
                </Row>
              </Row>
            </Modal>
          </Row>
          {this.state.isEditFormVisible && this.renderEditForm()}
          <Row>
            {/* Filters section */}
            <Row>
              <Col xs={11} sm={11} md={5} lg={3} xl={2} xxl={2} offset={1}>
                <Row>
                  <Text className="font-color-Labeln">{'UDR:'}</Text>
                </Row>
                <Select
                  style={{ width: '100%', paddingBottom: '10px' }}
                  onChange={(event: string): void => {
                    this.onChangeFilters('udr', event);
                  }}
                  value={
                    this.state.filters.receptionUnit === undefined
                      ? ''
                      : this.state.filters.receptionUnit
                  }
                  disabled={this.props.isFetching}
                >
                  <Select.Option key={''} value={''}>
                    {'Todos'}
                  </Select.Option>
                  {this.props.acountStatus.customerAddresses.map(
                    (option: CustomerAddress): JSX.Element => (
                      <Select.Option key={option.code} value={option.code}>
                        {option.code}
                      </Select.Option>
                    ),
                  )}
                </Select>
              </Col>
              <Col xs={11} sm={11} md={5} lg={4} xl={3} xxl={2} offset={1}>
                <Row>
                  <Text className="font-color-Labeln">
                    {'Ver movimientos:'}
                  </Text>
                </Row>
                <Select
                  defaultValue=""
                  onChange={(value: string): void =>
                    this.onChangeFilters('mov', value)
                  }
                  style={{ width: '100%', paddingBottom: '10px' }}
                  disabled={this.props.isFetching}
                >
                  <Select.Option value="">Todos</Select.Option>
                  <Select.Option value="Payments">Abonos</Select.Option>
                  <Select.Option value="Sales">Cargos</Select.Option>
                </Select>
              </Col>
              <Col xs={23} sm={23} md={7} lg={10} xl={8} xxl={6} offset={1}>
                <div>
                  <Text className="font-color-Labeln">Filtrar por fecha</Text>
                </div>
                <Col xs={24} sm={12} md={24} lg={12} xl={12} xxl={12}>
                  <DatePicker
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    disabled={this.props.isFetching}
                    disabledDate={(date: moment.Moment | undefined): boolean =>
                      isDateWrong(date, moment(this.state.filters.endDate))
                    }
                    format={DATE_FORMAT}
                    placeholder="Desde"
                    onChange={(
                      date: moment.Moment | null,
                      dateString: string,
                    ): void => {
                      this.setState({
                        initDate: (date || moment()).toISOString(),
                      });
                      this.onChangeFilters(
                        'initDate',
                        (date || moment()).toISOString(),
                      );
                    }}
                    defaultValue={moment(getInitialTime())}
                    style={{ width: '100%', paddingBottom: '10px' }}
                  />
                </Col>
                <Col xs={24} sm={12} md={24} lg={12} xl={12} xxl={12}>
                  <DatePicker
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    disabled={this.props.isFetching}
                    disabledDate={(date: moment.Moment | undefined): boolean =>
                      isDateWrong(moment(this.state.filters.initDate), date)
                    }
                    format={DATE_FORMAT}
                    placeholder="Hasta"
                    onChange={(
                      date: moment.Moment | null,
                      dateString: string,
                    ): void => {
                      this.setState({
                        endDate: (date || moment()).toISOString(),
                      });
                      this.onChangeFilters(
                        'endDate',
                        (date || moment()).toISOString(),
                      );
                    }}
                    defaultValue={moment(getEndTime())}
                    style={{ width: '100%', paddingBottom: '10px' }}
                  />
                </Col>
              </Col>
              <Col xs={23} sm={11} md={7} lg={6} xl={4} xxl={3} offset={1}>
                <Text
                  className="font-color-Labeln"
                  style={{ paddingRight: '25%' }}
                >
                  Factura serie/folio
                </Text>
                <Select
                  style={{ width: '50%' }}
                  className={'sale-filters-size'}
                  defaultValue=""
                  value={this.state.filters.invoiceSerie}
                  onChange={(event: string): void => {
                    this.setState({
                      invoiceSerie: event,
                    });
                    this.onChangeFilters('serie', event);
                  }}
                  disabled={this.props.isFetching}
                >
                  <Select.Option value="">Todas</Select.Option>
                  <Select.Option value="PAG">PAG</Select.Option>
                  <Select.Option value="NC">NC</Select.Option>
                  <Select.Option value="N">N</Select.Option>
                  <Select.Option value="P">P</Select.Option>
                  <Select.Option value="SN">SN</Select.Option>
                  <Select.Option value="SP">SP</Select.Option>
                  <Select.Option value="RNC">RNC</Select.Option>
                  <Select.Option value="NCI">NCI</Select.Option>
                  <Select.Option value="REP">REP</Select.Option>
                  <Select.Option value="CRE">CRE</Select.Option>
                  <Select.Option value="CON">CON</Select.Option>
                  <Select.Option value="C">C</Select.Option>
                  <Select.Option value="CR">CR</Select.Option>
                  <Select.Option value="BI">BI</Select.Option>
                  <Select.Option value="RCO">RCO</Select.Option>
                  <Select.Option value="RCR">RCR</Select.Option>
                </Select>
                <Input.Search
                  placeholder="Folio"
                  disabled={this.props.isFetching}
                  onChange={({ target: { value } }): void => {
                    this.setState({ invoiceFolio: value });
                    this.onChangeFilters('folio', value);
                  }}
                  style={{ width: '50%', paddingBottom: '10px' }}
                />
              </Col>
              {this.state.filters.movementType === false && (
                <Col xs={11} sm={11} md={5} lg={4} xl={3} xxl={3} offset={1}>
                  <Row>
                    <Text className="font-color-Labeln">
                      {'Tipo de abono:'}
                    </Text>
                  </Row>
                  <Select
                    defaultValue=""
                    style={{ width: '100%', paddingBottom: '11px' }}
                    onChange={(e: string): void =>
                      this.onChangeFilters('pay', e)
                    }
                    disabled={this.props.isFetching}
                  >
                    <Select.Option value="">Todos</Select.Option>
                    <Select.Option value="Payments">Abonos</Select.Option>
                    <Select.Option value="CreditNotes">
                      Notas de Crédito
                    </Select.Option>
                  </Select>
                </Col>
              )}
              {this.state.filters.movementType === false && (
                <Col xs={11} sm={11} md={5} lg={4} xl={3} xxl={3} offset={1}>
                  <Row>
                    <Text className="font-color-Labeln">{'Estatus:'}</Text>
                  </Row>
                  <Select
                    defaultValue=""
                    style={{ width: '100%', paddingBottom: '10px' }}
                    onChange={(e: string): void =>
                      this.onChangeFilters('sta', e)
                    }
                    disabled={this.props.isFetching}
                  >
                    <Select.Option value="">Todos</Select.Option>
                    <Select.Option value="APLICADA">Aplicados</Select.Option>
                    <Select.Option value="PENDIENTE">
                      En verificación
                    </Select.Option>
                    <Select.Option value="RECHAZADA">Rechazados</Select.Option>
                  </Select>
                </Col>
              )}
              {this.state.filters.movementType === true && (
                <Col xs={11} sm={11} md={5} lg={4} xl={3} xxl={3} offset={1}>
                  <Row>
                    <Text className="font-color-Labeln">{'Estatus:'}</Text>
                  </Row>
                  <Select
                    defaultValue=""
                    style={{ width: '100%', paddingBottom: '10px' }}
                    onChange={(e: string): void =>
                      this.onChangeFilters('sta', e)
                    }
                    disabled={this.props.isFetching}
                  >
                    <Select.Option value="">Todos</Select.Option>
                    <Select.Option value="VENCIDA">Vencida</Select.Option>
                    <Select.Option value="PAGADA">Pagada</Select.Option>
                    <Select.Option value="PENDIENTE">Pendiente</Select.Option>
                  </Select>
                </Col>
              )}
            </Row>
            {/* Buttons section which show modals */}
            <Row type="flex" justify="end">
              <Col xs={5} sm={3} md={3} lg={2} xl={2} xxl={1} offset={1}>
                <Row>
                  <Text className="font-color-Labeln">{'\u00A0'}</Text>
                </Row>
                <Button
                  onClick={(): void => this.applyFilters()}
                  type="primary"
                  size="large"
                  disabled={this.props.isFetching}
                >
                  <Icon type="search" />
                  Buscar
                </Button>
              </Col>
              {//Disable it when rows selected (without invoice and with 1 sale) are 0 or less
              isAllowed([P.CAN_MERGE_AND_SEPARATE_CHARGES]) &&
                this.getStatementIdsToMerge().length > 1 && (
                  <Col xs={5} sm={3} md={3} lg={2} xl={2} xxl={1} offset={1}>
                    <Popconfirm
                      title="¿Seguro que quiere fusionar estos cargos?"
                      onConfirm={(): void => {
                        //Make request and update screen in async way
                        const asyncFunction = async (
                          ids: string[],
                        ): Promise<void> => {
                          await this.props.mergeStatements(ids);
                          await this.fetchData();
                          this.setState({
                            stagingArea: [],
                            selectedRowKeys: [],
                          });
                        };
                        const statementIds = this.getStatementIdsToMerge();
                        asyncFunction(statementIds);
                      }}
                      okText="Sí"
                      cancelText="No"
                    >
                      <Button
                        type="primary"
                        className="pay-button button-new"
                        size="large"
                        id="mergeChargesButton"
                        disabled={this.props.isFetching}
                        style={{ marginTop: '21px' }}
                      >
                        {'Fusionar'}
                      </Button>
                    </Popconfirm>
                  </Col>
                )}
              {isAllowed([P.CAN_MOVE_CHARGES]) && (
                <Col xs={5} sm={3} md={3} lg={2} xl={2} xxl={1} offset={1}>
                  <Row>
                    <Text className="font-color-Labeln">{'\u00A0'}</Text>
                  </Row>
                  <Button
                    onClick={(): void => this.showModalMove()}
                    type="primary"
                    className="pay-button button-new"
                    size="large"
                    id="user_new"
                    disabled={
                      this.props.isFetching ||
                      this.getSelectedStatementsToMove().length <= 0
                    }
                  >
                    Mover
                  </Button>
                  <Button
                    onClick={(): void => this.showModalMoveAll()}
                    type="primary"
                    className="pay-button button-new"
                    style={{
                      visibility: isAllowed([P.UPDATE_ALL_CUSTOMER_SALES])
                        ? 'visible'
                        : 'hidden',
                    }}
                    size="large"
                    id="user_new"
                    disabled={
                      this.props.isFetching ||
                      !isAllowed([P.UPDATE_ALL_CUSTOMER_SALES])
                    }
                  >
                    Mover Todo
                  </Button>
                </Col>
              )}
              {//Show the button when 1 row with totalInvoice is selected
              this.state.stagingArea.filter(
                (s: StatementStagingItem): boolean => s.totalInvoice !== null,
              ).length <= 1 &&
                isAllowed([P.CAN_GENERATE_CREDIT_NOTE]) && (
                  <Col xs={5} sm={3} md={3} lg={2} xl={2} xxl={1} offset={1}>
                    <Row>
                      <Text className="font-color-Labeln">{'\u00A0'}</Text>
                    </Row>

                    <Button
                      onClick={(): void => this.showModal(true)}
                      type="primary"
                      className="pay-button button-new"
                      size="large"
                      id="user_new"
                      disabled={this.props.isFetching}
                    >
                      Nota C.
                    </Button>
                  </Col>
                )}
              <Col xs={5} sm={3} md={3} lg={2} xl={2} xxl={1} offset={1}>
                <Row>
                  <Text className="font-color-Labeln">{'\u00A0'}</Text>
                </Row>
                <Button
                  onClick={(): void => this.showModal(false)}
                  type="primary"
                  className="pay-button button-new"
                  size="large"
                  id="user_new"
                  disabled={
                    //When is loading data
                    this.props.isFetching ||
                    //Or when is no row selected and is a customer logged in
                    this.state.stagingArea.length <= 0 ||
                    isAllowed([P.CAN_VIEW_MY_STATEMENTS])
                  }
                >
                  Abonar
                </Button>
              </Col>
              <Col xs={5} sm={3} md={3} lg={2} xl={2} xxl={1} offset={1}>
                <Row>
                  <Text className="font-color-Labeln">{'\u00A0'}</Text>
                </Row>
                <Button
                  onClick={(): void =>
                    this.setState({ stagingArea: [], selectedRowKeys: [] })
                  }
                  type="ghost"
                  size="large"
                  id="user_new"
                  disabled={this.props.isFetching}
                >
                  Limpiar
                </Button>
              </Col>
              <Col
                xs={5}
                sm={3}
                md={3}
                lg={2}
                xl={2}
                xxl={2}
                offset={1}
                style={{ paddingTop: '25px' }}
              >
                <Tooltip
                  title={`Descargar como Excel. 
              Máximo 10,000 registros`}
                  placement="topRight"
                >
                  <Button
                    type="primary"
                    shape="round"
                    size={'default'}
                    onClick={(): void => {
                      const asyncAction = async (): Promise<void> => {
                        const { id } = this.props.match.params;
                        let newId = id;
                        //Get properties from state
                        const { filters } = this.state;
                        if (token && token.customerCode !== '') {
                          newId = token.customerCode;
                        }
                        //Execute redux action
                        await this.props.downloadReport(newId, filters);
                      };
                      asyncAction();
                    }}
                    disabled={
                      this.props.isFetching ||
                      (statementPage.totalElements !== undefined &&
                        statementPage.totalElements > rowsAllowed) ||
                      (statementPage.totalElements !== undefined &&
                        statementPage.totalElements <= 0)
                    }
                  >
                    <Icon type={'download'} />
                    {' Excel'}
                  </Button>
                </Tooltip>
              </Col>
              <Col
                xs={8}
                sm={4}
                md={3}
                lg={3}
                xl={2}
                xxl={2}
                style={{ paddingTop: '25px' }}
              >
                <Tooltip title="Descargar xml y pdf en un ZIP. Máximo 10,000 registros">
                  <Button
                    type="primary"
                    shape="round"
                    onClick={(): void => {
                      const generateZip = async (): Promise<void> => {
                        const { id } = this.props.match.params;
                        let code = id;
                        //Get properties from state
                        const { filters } = this.state;
                        if (token && token.customerCode !== '') {
                          code = token.customerCode;
                        }
                        //Execute redux action
                        await this.props.downloadZip(code, filters);
                      };
                      generateZip();
                    }}
                    disabled={
                      this.props.isFetching ||
                      (statementPage.totalElements !== undefined &&
                        statementPage.totalElements > rowsAllowed) ||
                      (statementPage.totalElements !== undefined &&
                        statementPage.totalElements <= 0)
                    }
                  >
                    <Icon type="download" /> ZIP
                  </Button>
                </Tooltip>
              </Col>
            </Row>
          </Row>
          <Row>
            <p />
          </Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={0} xxl={0}>
            <List
              dataSource={this.props.statementPage.content}
              renderItem={(item: Statement, index: number): JSX.Element => (
                <List.Item>
                  <List.Item.Meta
                    key={index}
                    description={
                      <Row className="white-row-list">
                        <Col span={20}>{this.renderDetails(item)}</Col>
                        <Col span={4}>{this.renderActions(item)}</Col>
                      </Row>
                    }
                  />
                </List.Item>
              )}
            />
          </Col>
          <Col xs={0} sm={0} md={0} lg={0} xl={24} xxl={24}>
            <Table
              loading={this.props.isFetching}
              rowClassName={(record: Statement): string => {
                return moment().diff(moment(record.creditDays)) >= 0 &&
                  record.balance > 0
                  ? 'row-vencida'
                  : 'row-normal';
              }}
              dataSource={this.props.statementPage.content}
              rowSelection={{
                selectedRowKeys: selectedRowKeys,
                onChange: this.onSelectChange,
                onSelectAll: this.onSelectAll,
                onSelect: this.selected,
                getCheckboxProps: (record: Statement): {} => ({
                  disabled: this.checkedDisable(record),
                  name: record.id,
                }),
              }}
              pagination={false}
              rowKey="id"
              expandedRowKeys={this.state.expanded}
              expandIconAsCell={false}
              expandIconColumnIndex={-1}
              expandedRowRender={(
                record: Statement,
              ): JSX.Element | undefined => {
                if (record.statementRelation.length < 1) {
                  return undefined;
                } else {
                  return (
                    <Table
                      className={'custom-table'}
                      dataSource={record.statementRelation}
                      pagination={false}
                    >
                      {!record.sale && (
                        <Table.Column
                          title="Serie/Folio Venta:"
                          dataIndex="saleReference"
                          key="saleReference"
                          className="expanded-table"
                        />
                      )}
                      {!record.sale && (
                        <Table.Column
                          title="Abonó:"
                          dataIndex="userCreate"
                          key="userCreate"
                          className="expanded-table"
                        />
                      )}
                      {!record.sale && (
                        <Table.Column
                          title="Aplicó"
                          dataIndex="userApply"
                          key="userApply"
                        />
                      )}
                      {record.sale && (
                        <Table.Column
                          title="Número de Transferencia:"
                          dataIndex="paymentReference"
                          key="paymentReference"
                          className="expanded-table"
                        />
                      )}
                      {record.sale && (
                        <Table.Column
                          title="Fecha de pago"
                          dataIndex="paymentDate"
                          key="paymentDate"
                          className="expanded-table"
                          render={(text: string): React.ReactNode => {
                            return moment(text).format(DATE_FORMAT);
                          }}
                        />
                      )}
                      {record.sale && (
                        <Table.Column
                          title="Monto de pago"
                          dataIndex="amount"
                          key="amount"
                          render={(text: number): React.ReactNode => {
                            return toNumber(text);
                          }}
                        />
                      )}
                    </Table>
                  );
                }
              }}
            >
              <Table.Column
                title="Estatus de pago"
                dataIndex="salesFolio"
                key="salesFolio"
                render={(text: string, record: Statement): React.ReactNode => {
                  if (record.sale) {
                    const outDate =
                      (moment(record.movementDate).diff(
                        moment().format(DATE_FORMAT),
                        'hours',
                      ) < -72 &&
                        record.invoiceSerie &&
                        record.invoiceSerie.includes('CANCELADO')) ||
                      (moment(record.movementDate).diff(
                        moment().format(DATE_FORMAT),
                        'hours',
                      ) < -72 &&
                        !record.invoiceSerie)
                        ? 'Facturable Fuera de fecha'
                        : '';
                    const pendingPayment =
                      record.statusStatment === 'VERIFICANDO'
                        ? ' Con pago pendiente'
                        : '';
                    const tag =
                      moment().diff(moment(record.creditDays)) >= 0 &&
                      record.balance > 0 ? (
                        <Tag color="#D71F49" className="tag-statement-size">
                          Vencida
                        </Tag>
                      ) : record.balance === record.amount ? (
                        <div>
                          <Tag color="gray" className="tag-statement-size">
                            Pendiente
                          </Tag>
                        </div>
                      ) : record.balance > 0 ? (
                        <Tag color="#CC9603" className="tag-statement-size">
                          Parcial
                        </Tag>
                      ) : (
                        <Tag
                          color="#F0FFE9"
                          className="tag-statement-size"
                          style={{ color: '#08A20E' }}
                        >
                          Pagada
                        </Tag>
                      );
                    return (
                      <div>
                        {tag}
                        {pendingPayment}
                        {outDate}
                      </div>
                    );
                  } else {
                    if (record.statusStatment === 'RECHAZADA')
                      return (
                        <Tag color="red" className="tag-statement-size">
                          {'Rechazada'}
                        </Tag>
                      );
                    else if (
                      record.balance > 0 &&
                      record.statusStatment === 'PENDIENTE'
                    )
                      return (
                        <Tag color="gray" className="tag-statement-size">
                          {'pendiente'}
                        </Tag>
                      );
                    else
                      return (
                        <Tag color="cyan" className="tag-statement-size">
                          {'Aplicada'}
                        </Tag>
                      );
                  }
                }}
              />
              <Table.Column
                title="Tipo de movimiento"
                dataIndex="sale"
                key="sale"
                render={(text: string, record: Statement): React.ReactNode => {
                  return record.sale ? 'Cargo' : 'Abono';
                }}
              />
              <Table.Column
                title="Fecha y hora del movimiento"
                dataIndex="movementDate"
                key="movementDate"
                render={(text: string): React.ReactNode => {
                  return moment(text).format(DATE_FORMAT);
                }}
              />

              <Table.Column
                title="Referencia"
                dataIndex="reference"
                key="reference"
              />
              <Table.Column
                title="Rfc"
                dataIndex="customerInvoiceRfc"
                key="customerInvoiceRfc"
                render={(text: string, record: Statement): React.ReactNode => {
                  if (!record.customerInvoiceRfc) {
                    return 'N/A';
                  }
                  return text;
                }}
              />
              <Table.Column
                title="Monto"
                dataIndex="amount"
                key="amount"
                render={(text: string, record: Statement): React.ReactNode => {
                  return (
                    <div>
                      {record.sale ? (
                        <div>
                          <Text className="font-color-down">
                            {record.totalInvoice !== 0 &&
                            record.totalInvoice !== null
                              ? '- $' + toNumber(record.totalInvoice || 0) + ' '
                              : '- $' + toNumber(record.amount) + ' '}
                            <Icon
                              type="arrow-down"
                              className="font-arrow-statement"
                            />
                          </Text>
                        </div>
                      ) : (
                        <div>
                          <Text className="font-color-up">
                            {record.totalInvoice !== 0 &&
                            record.totalInvoice !== null
                              ? '- $' + toNumber(record.totalInvoice || 0) + ' '
                              : '- $' + toNumber(record.amount) + ' '}
                            <Icon
                              type="arrow-up"
                              className="font-arrow-statement"
                            />
                          </Text>
                        </div>
                      )}
                    </div>
                  );
                }}
              />

              <Table.Column
                title="Saldo"
                dataIndex="balance"
                key="balance"
                render={(text: string): React.ReactNode => {
                  return (
                    <div className="font-align-end">
                      {toNumber(Number(text))}
                    </div>
                  );
                }}
              />
              <Table.Column
                title="Vencimiento a"
                dataIndex="creditDays"
                key="creditDays"
                render={(text: string, record: Statement): React.ReactNode => {
                  if (record.balance === 0 || !record.sale) {
                    return 'N/A';
                  }
                  return `${moment(record.creditDays).diff(
                    moment().format(DATE_FORMAT),
                    'days',
                  )}${this.showDayText(
                    moment(record.creditDays).diff(
                      moment().format(DATE_FORMAT),
                      'days',
                    ),
                  )}${moment(record.creditDays).format(DATE_FORMAT)})`;
                }}
              />
              <Table.Column
                title="Factura Serie/Folio"
                dataIndex="invoiceSerie"
                key="invoiceSerie"
                render={(text: string, record: Statement): React.ReactNode => {
                  let serieFolio = 'N/A';
                  if (text !== null)
                    serieFolio =
                      record.invoiceSerie + '/' + record.invoiceFolio;
                  let linkedInvoices = '';
                  if (record.linkedInvoices !== null)
                    linkedInvoices = 'Vinculados: ' + record.linkedInvoices;

                  return (
                    <div>
                      {serieFolio}
                      <div>{linkedInvoices}</div>
                    </div>
                  );
                }}
              />
              {// When user has permision, show cancellation details
              isAllowed([P.SEE_CANCELLATION_DETAILS]) && (
                <Table.Column
                  title="Detalles de cancelación"
                  dataIndex="cancellationDate"
                  render={(
                    date: string,
                    record: Statement,
                  ): React.ReactNode => {
                    let cancellationUser = '';
                    let cancellationDate = '';

                    if (date !== null)
                      cancellationDate =
                        'Fecha: ' + moment(date).format(DATE_FORMAT);
                    if (record.cancellationUser !== null)
                      cancellationUser = 'Usuario: ' + record.cancellationUser;
                    return (
                      <div>
                        <div>{cancellationUser}</div>
                        <div>{cancellationDate}</div>
                      </div>
                    );
                  }}
                />
              )}
              <Table.Column
                title="Acciones"
                dataIndex="na"
                key="na"
                render={(text: string, record: Statement): React.ReactNode => {
                  const invoiceSerie = record.invoiceSerie
                    ? record.invoiceSerie
                    : '';
                  //Show an error Icon when invoice had any error
                  let errorIcon = <div />;
                  if (record.invoiceError !== null) {
                    //If the invoice had some error, show it in a tooltip of a warning icon
                    errorIcon = (
                      <Col span={5}>
                        <Tooltip title={record.invoiceError}>
                          <Icon
                            type="warning"
                            style={{ fontSize: '26px', color: 'red' }}
                          />
                        </Tooltip>
                      </Col>
                    );
                  }
                  //Define the details button to show
                  let detailsButton = (
                    <Col span={19}>
                      <Button
                        onClick={(): void => {
                          this.setState({ expanded: [record.id] });
                        }}
                      >
                        {' Ver detalles'}
                      </Button>
                    </Col>
                  );
                  if (record.statementRelation.length < 1) {
                    detailsButton = <Col span={19}>{'Sin detalles '}</Col>;
                  } else if (this.state.expanded[0] === record.id) {
                    detailsButton = (
                      <Col span={19}>
                        <Button
                          onClick={(): void => {
                            this.setState({ expanded: [] });
                          }}
                        >
                          {' Ocultar detalles'}
                        </Button>
                      </Col>
                    );
                  }
                  //Render the elements for actions column
                  return (
                    <Row>
                      {errorIcon}
                      {detailsButton}
                      <Button type="link" className='ant-button' onClick={ async () => {
                        if(!Globals.Utils.Prompt.confirm(`Desligar folio apartado de la venta? Puede causar duplicaciones.`)) return;
                        const result = await callApi(ApiRoute.ClearCfdiFolioRetryOfSale, { statementOrSaleId: record.id });
                        if(!result || !result.success){
                          alert((result && result.msg) || '');
                        }else{
                          alert(result.msg);
                        }
                      } } >
                        Desligar Folio
                      </Button>
                      <AddExternalInvoiceModal statementId={ record.id } onSave={ () => this.applyFilters() } />
                      <Select
                        style={{ width: '140px' }}
                        value=""
                        onSelect={(value: string): void =>
                          this.executeSelectedAction(value, record)
                        }
                        disabled={this.props.isFetching}
                      >
                        <Select.Option key={''} value={''}>
                          {'Seleccionar'}
                        </Select.Option>
                        {isAllowed([P.CAN_CANCEL_INVOICE]) &&
                          (record.invoiceFolio !== null ||
                            record.invoiceError != null) &&
                          record.invoiceDownloaded &&
                          !invoiceSerie.includes('CANCELADO') &&
                          (record.statusCancelation === null ||
                            record.statusCancelation === undefined ||
                            record.statusCancelation === '' ||
                            record.statusCancelation === 'RECHAZADO') && (
                            <Select.Option
                              key={'cancelation' + record.id}
                              value="cancelation"
                            >
                              <Popconfirm
                                title={
                                  '¿Realmente quiere cancelar esta factura ' +
                                  record.invoiceSerie +
                                  '/' +
                                  record.invoiceFolio +
                                  '?'
                                }
                                placement="topRight"
                                onConfirm={(): void => {
                                  //Make request and update screen in async way
                                  const asyncFunction = async (
                                    id: string,
                                  ): Promise<void> => {
                                    await this.props.cancelStatement(id);
                                    await this.fetchData();
                                  };
                                  asyncFunction(record.id);
                                }}
                                okText="Sí"
                                cancelText="No"
                              >
                                {'Cancelar factura'}
                              </Popconfirm>
                            </Select.Option>
                          )}
                        {isAllowed([P.CAN_FORCE_CANCEL_INVOICE]) &&
                          record.sale && (
                            <Select.Option
                              key={'ForzarCancelation' + record.id}
                              value="ForzarCancelation"
                            >
                              <Popconfirm
                                title={
                                  '¿Realmente quiere Forzar la cancelación? \n Asegurese que en el portal del sat esté cancelado' +
                                  record.invoiceSerie +
                                  '/' +
                                  record.invoiceFolio +
                                  '?'
                                }
                                placement="topRight"
                                onConfirm={(): void => {
                                  this.setState({
                                    cancelationModal: !cancelationModal,
                                    selectedInvoiceId: record.invoice,
                                  });
                                }}
                                icon={
                                  <Icon
                                    type="question-circle-o"
                                    style={{ color: 'red' }}
                                  />
                                }
                                okText="Sí"
                                cancelText="No"
                              >
                                {'Forzar Cancelación'}
                              </Popconfirm>
                            </Select.Option>
                          )}
                        {isAllowed([P.CAN_CANCEL_INVOICE]) &&
                          record.invoice !== null &&
                          record.invoiceDownloaded === false &&
                          record.reference.includes('Nota de Crédito') && (
                            <Select.Option
                              key={'cancelation' + record.id}
                              value="cancelation"
                            >
                              <Popconfirm
                                title={
                                  '¿Realmente quiere cancelar esta factura ' +
                                  record.invoiceSerie +
                                  '/' +
                                  record.invoiceFolio +
                                  '?'
                                }
                                placement="topRight"
                                onConfirm={(): void => {
                                  //Make request and update screen in async way
                                  const asyncFunction = async (
                                    id: string,
                                  ): Promise<void> => {
                                    await this.props.cancelStatement(id);
                                    await this.fetchData();
                                  };
                                  asyncFunction(record.id);
                                }}
                                okText="Sí"
                                cancelText="No"
                              >
                                {'Cancelar factura'}
                              </Popconfirm>
                            </Select.Option>
                          )}
                        {//Show this option when is sale and it's canceled
                        ((record.sale && invoiceSerie.includes('CANCELADO')) ||
                          //Or when it's a sale and it doesn't have invoice
                          (record.sale &&
                            (record.invoice === undefined ||
                              record.invoice === null)) ||
                          //Or when it's a sale & it has error & status cancelation is CANCELADO
                          (record.sale &&
                            record.invoiceError !== null &&
                            record.statusCancelation !== null &&
                            record.statusCancelation === 'CANCELADO')) && (
                          <Select.Option
                            key={'toInvoice' + record.id}
                            value="toInvoice"
                          >
                            {'Facturar'}
                          </Select.Option>
                        )}
                        {record.invoice !== undefined &&
                          record.invoice !== null &&
                          record.invoiceDownloaded &&
                          record.invoiceError === null && (
                            <Select.Option key={'pdf' + record.id} value="pdf">
                              {'Descargar PDF'}
                            </Select.Option>
                          )}
                        {record.invoice !== undefined &&
                          record.invoice !== null &&
                          record.invoiceDownloaded &&
                          record.invoiceError === null && (
                            <Select.Option key={'xml' + record.id} value="xml">
                              {'Descargar XML'}
                            </Select.Option>
                          )}
                        {isAllowed([P.CAN_MERGE_AND_SEPARATE_CHARGES]) &&
                          record.sale &&
                          record.invoiceStatus !== 'Enviado' &&
                          this.hasMultipleSales(record.reference) && (
                            <Select.Option
                              key={'separate' + record.id}
                              value="separate"
                            >
                              <Popconfirm
                                title="¿Realmente quiere separar este cargo?"
                                onConfirm={(): void => {
                                  //Make request and update screen in async way
                                  const asyncFunction = async (
                                    id: string,
                                  ): Promise<void> => {
                                    await this.props.splitStatement(id);
                                    await this.fetchData();
                                  };
                                  asyncFunction(record.id);
                                }}
                                okText="Sí"
                                cancelText="No"
                              >
                                {'Separar cargos'}
                              </Popconfirm>
                            </Select.Option>
                          )}
                        {isAllowed([P.CAN_EDIT_STATEMENTS]) &&
                          record.sale &&
                          (record.invoiceFolio === null ||
                            record.invoiceError !== null) &&
                          record.reference.includes('Venta Contado:') &&
                          !this.hasMultipleSales(record.reference) && (
                            <Select.Option
                              key={'edit' + record.id}
                              value="edit"
                            >
                              {'Editar'}
                            </Select.Option>
                          )}
                      </Select>
                    </Row>
                  );
                }}
              />
            </Table>
          </Col>
          <Pagination
            current={(this.props.statementPage.number || 0) + 1}
            total={this.props.statementPage.totalElements}
            onChange={this.handlePaginationChange}
            showSizeChanger
            onShowSizeChange={this.handlePaginationChange}
            defaultPageSize={25}
            pageSizeOptions={['25', '50', '75', '100']}
          />
        </Row>
      </div>
    );
  };
}

const mapStateToProps = (states: RootState): StateProps => {
  return {
    statementPage: states.statementState.statementList,
    acountStatus: states.statementState.acountStatus,
    statementSearchList: states.statementState.statementSearchList,
    isFetching: states.statementState.isFetching,
    statementSales: Object.values(states.statementState.statementSales),
    customersList: states.settlementState.customersList.customersList,
    singleStatementSale: states.statementState.singleStatementSale,
  };
};

const mapDispatchToProps = {
  page,
  save,
  search,
  stampSale,
  downloadFile,
  downloadReport,
  getSalesByStatementId,
  resetStatementSales,
  getCustomersByFilter,
  splitStatement,
  saveSaleMove,
  saveSaleMoveAll,
  mergeStatements,
  editStatement,
  findSaleByStatement,
  resetStatementSale,
  cancelStatement,
  forceCanceInvoice,
  downloadZip,
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create()(SalesListComponent));
