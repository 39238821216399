/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Hiram Padilla <hiram@ssf.com.mx>, July 2021
 *
 */

import { InvoiceForPortfolio, Portfolio } from 'types/type';

/* Operation which indicates the action begin or has been completed */
export const BEGIN_FETCH = 'BEGIN_FETCH';
export const FETCH_COMPLETED = 'FETCH_COMPLETED';

/* General actions */
export const SET_PORTFOLIOS_PAGE = 'SET_PORTFOLIOS_PAGE';
export const RESET_PORTFOLIOS_PAGE = 'RESET_PORTFOLIOS_PAGE';
export const SET_PORTFOLIOS_LIST = 'SET_PORTFOLIOS_LIST';
export const RESET_PORTFOLIOS_LIST = 'RESET_PORTFOLIOS_LIST';
export const SET_INVOICES_PAGE = 'SET_INVOICES_PAGE';
export const RESET_INVOICES_PAGE = 'RESET_INVOICES_PAGE';
export const SET_CRE_NOTES_LIST = 'SET_CRE_NOTES_LIST';
export const RESET_CRE_NOTES_LIST = 'RESET_CRE_NOTES_LIST';
export const SET_INVOICES_TO_EDIT = 'SET_INVOICES_TO_EDIT';
export const RESET_INVOICES_TO_EDIT = 'RESET_INVOICES_TO_EDIT';
export const SET_SELECTED_INVOICES = 'SET_SELECTED_INVOICES';
export const SET_OPENED_PORTFOLIO = 'SET_OPENED_PORTFOLIO';
export const RESET_OPENED_PORTFOLIO = 'RESET_OPENED_PORTFOLIO';
export const SET_PORTFOLIO_DETAILS = 'SET_PORTFOLIO_DETAILS';
export const RESET_PORTFOLIO_DETAILS = 'RESET_PORTFOLIO_DETAILS';

interface BeginFetch {
  type: typeof BEGIN_FETCH;
}

interface FetchCompleted {
  type: typeof FETCH_COMPLETED;
}

interface SetPortfolioPage {
  type: typeof SET_PORTFOLIOS_PAGE;
  portfoliosPage: {};
}
interface SetPortfolioList {
  type: typeof SET_PORTFOLIOS_LIST;
  portfolioList: Portfolio[];
}
interface ResetPortfolioList {
  type: typeof RESET_PORTFOLIOS_LIST;
}

interface ResetPortfolioPage {
  type: typeof RESET_PORTFOLIOS_PAGE;
}

interface SetInvoicesPage {
  type: typeof SET_INVOICES_PAGE;
  invoicesPage: {};
}

interface ResetInvoicesPage {
  type: typeof RESET_INVOICES_PAGE;
}

interface SetCreNotesList {
  type: typeof SET_CRE_NOTES_LIST;
  creNotes: {};
}

interface ResetCreNotesList {
  type: typeof RESET_CRE_NOTES_LIST;
}

interface SetInvoicesToEdit {
  type: typeof SET_INVOICES_TO_EDIT;
  invoicesToEdit: {};
}

interface ResetInvoicesToEdit {
  type: typeof RESET_INVOICES_TO_EDIT;
}

interface SetSelectedInvoices {
  type: typeof SET_SELECTED_INVOICES;
  selectedInvoices: InvoiceForPortfolio[];
}

interface SetOpenedPortfolio {
  type: typeof SET_OPENED_PORTFOLIO;
  openedPortfolio: Portfolio;
}

interface ResetOpenedPortfolio {
  type: typeof RESET_OPENED_PORTFOLIO;
}

interface SetPortfolioDetails {
  type: typeof SET_PORTFOLIO_DETAILS;
  invoicesInfo: InvoiceForPortfolio[];
}

interface ResetPortfolioDetails {
  type: typeof RESET_PORTFOLIO_DETAILS;
}

export type PortfolioAction =
  | ResetPortfolioList
  | SetPortfolioList
  | BeginFetch
  | FetchCompleted
  | SetPortfolioPage
  | ResetPortfolioPage
  | SetInvoicesPage
  | ResetInvoicesPage
  | SetCreNotesList
  | ResetCreNotesList
  | SetInvoicesToEdit
  | ResetInvoicesToEdit
  | SetSelectedInvoices
  | SetOpenedPortfolio
  | ResetOpenedPortfolio
  | SetPortfolioDetails
  | ResetPortfolioDetails;
