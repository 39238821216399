import {
  Modal,
  Form,
  Input,
  Select,
  Upload,
  Icon,
  Col,
  Row,
  DatePicker,
  List,
  Button,
  Collapse,
  InputNumber,
} from 'antd';
import React, { Component } from 'react';
import { FormComponentProps } from 'antd/lib/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { InvoiceRules as r } from '../../invoices/components/FiscalData/rulesFiscalData';
import { formaPago } from 'commons/components/regimenFiscal';
import { banks } from 'commons/bankCatalog';
import {
  onlyNumberDecimalPattern,
  fiveDecimalPattern,
} from '../../../commons/regularExpressions';
import Text from 'antd/lib/typography/Text';
import { MessagesCustomer, LabelsCustomer } from 'commons/strings';
import { Acount, Statement } from 'types/type';
import moment from 'moment';
import { toNumber } from 'commons/numbersFormat';
import { UploadFile } from 'antd/lib/upload/interface';
import { StatementStagingItem } from '../types';
import { Action } from 'redux';
import { isAllowed } from 'security';
import { Permissions as P } from 'commons/permissions';
import { fileSizeValidator } from 'commons/validators';
import { DATE_FORMAT } from 'commons/services';

interface CounterProps {
  maxAmount: number;
  visible: boolean;
  statementSearchList: Statement[];
  onCancel: Function;
  onCreate: Function;
  onSearch: Function;
  handleDelete: Function;
  handleChangePrice: Function;
  handleChange: Function;
  form: WrappedFormUtils;
  stagingArea: StatementStagingItem[];
  customerName: string;
  isCreditNote: boolean;
  account: Acount;
}
const uploadButton = (
  <div>
    <Icon type="plus" />
    <div className="ant-upload-text">{'subir'}</div>
  </div>
);

class CreateFormStatement extends Component<CounterProps & FormComponentProps> {
  public state = {
    imageUrl: undefined,
    loading: false,
    tempFile: false,
  };

  private normFile = (e: Storage): File[] => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  private handleBeforeUpload = (file: File): boolean => {
    this.setState({ tempFile: true });
    return true;
  };

  public showDayText = (days: number): string => {
    return days > 1 ? 'Dias ' : 'Dia ';
  };

  /* Function to calculate the percentage from a total, when is credit note */
  public calculatePercentageFromAmount = (
    amount: number,
    total: number,
  ): void => {
    const percentage = (amount * 100) / total;
    const percentageRounded = Number(
      Math.round(parseFloat(percentage + 'e' + 2)) + 'e-' + 2,
    ).toFixed(2);
    const amountRounded = Number(
      Math.round(parseFloat(amount + 'e' + 2)) + 'e-' + 2,
    ).toFixed(2);
    this.props.form.setFieldsValue({
      percentage: percentageRounded,
      amount: amountRounded,
    });
  };

  /* Function to calculate the maximum percentage, which is equivalent to maximum total, when is credit note */
  public calculateMaximumPercentageFromAmount = (
    maxAmount: number,
    total: number,
  ): number => {
    const percentage = (maxAmount * 100) / total;
    const percentageRounded = Number(
      Math.round(parseFloat(percentage + 'e' + 2)) + 'e-' + 2,
    ).toFixed(2);
    return Number(percentageRounded);
  };

  /* Function to calculate the amount for a percentage based in a total, when is credit note */
  public calculateAmountFromPercentage = (
    percentage: number,
    total: number,
  ): void => {
    const amount = (percentage * total) / 100;
    const percentageRounded = Number(
      Math.round(parseFloat(percentage + 'e' + 2)) + 'e-' + 2,
    ).toFixed(2);
    const amountRounded = Number(
      Math.round(parseFloat(amount + 'e' + 2)) + 'e-' + 2,
    ).toFixed(2);
    this.props.form.setFieldsValue({
      percentage: percentageRounded,
      amount: amountRounded,
    });
  };

  public handleRemove = (file: UploadFile): void => {
    this.props.form.setFieldsValue({ voucher: undefined });
    console.log(file);
    this.setState({ tempFile: false });
  };

  public renderFilter = (list: Statement[]): JSX.Element => {
    const { handleChange, onSearch } = this.props;
    const total: number[] = Object.values(this.props.stagingArea).map(
      (value: StatementStagingItem): number => {
        return Number(value.amountToPay);
      },
    );
    return (
      <Row>
        <Row>
          <Col>
            <Text strong>Agrega cargos para abonar</Text>
          </Col>
          <Col span={11}>
            <Text disabled>
              <h5>
                Busca por folio y selecciona los cargos a los que desees
                aplicarles el abono
              </h5>
            </Text>
          </Col>
          <Col offset={1}>
            <Select
              showSearch
              style={{ width: 250 }}
              onSearch={onSearch()}
              onSelect={handleChange()}
            >
              {Object.values(list).length > 0
                ? Object.values(list).map(
                    (item: Statement): React.ReactNode => (
                      <Select.Option value={item.reference} key={item.id}>
                        <div style={{ visibility: 'hidden', display: 'none' }}>
                          {item.id}
                        </div>
                        <div style={{ visibility: 'hidden', display: 'none' }}>
                          {moment().diff(moment(item.creditDays)) >= 0 &&
                          item.balance > 0
                            ? 'VEN '
                            : 'PEN '}
                        </div>
                        <div style={{ visibility: 'hidden', display: 'none' }}>
                          {item.reference}
                        </div>
                        <div style={{ visibility: 'hidden', display: 'none' }}>
                          {toNumber(item.balance)}
                        </div>
                        <div style={{ visibility: 'hidden', display: 'none' }}>
                          {moment(item.creditDays).diff(
                            moment().format(DATE_FORMAT),
                            'days',
                          ) +
                            this.showDayText(
                              moment(item.creditDays).diff(
                                moment().format(DATE_FORMAT),
                                'days',
                              ),
                            )}
                        </div>
                        <Text
                          type={
                            moment().diff(moment(item.creditDays)) >= 0 &&
                            item.balance > 0
                              ? 'danger'
                              : 'warning'
                          }
                        >
                          {moment().diff(moment(item.creditDays)) >= 0 &&
                          item.balance > 0
                            ? 'VEN '
                            : 'PEN '}
                        </Text>
                        <Text type="warning">{item.reference}</Text>{' '}
                        <Text type="danger">
                          {'-$ ' + toNumber(item.balance) + ' '}
                        </Text>
                        <Text disabled>
                          {moment(item.creditDays).diff(
                            moment().format(DATE_FORMAT),
                            'days',
                          ) +
                            this.showDayText(
                              moment(item.creditDays).diff(
                                moment().format(DATE_FORMAT),
                                'days',
                              ),
                            ) +
                            ''}
                        </Text>
                      </Select.Option>
                    ),
                  )
                : null}
            </Select>
          </Col>
        </Row>
        <Row>
          {Object.values(this.props.stagingArea).length < 1 ? null : (
            <Row style={{ backgroundColor: '#F1F7FF' }}>
              <Collapse
                defaultActiveKey={['1']}
                expandIconPosition={'right'}
                style={{ backgroundColor: '#F1F7FF' }}
                bordered={false}
              >
                <Collapse.Panel
                  header={
                    <Text strong>
                      {'Abonar a ' +
                        Object.values(this.props.stagingArea).length +
                        ' cargos seleccionados'}
                    </Text>
                  }
                  key="1"
                  style={{ backgroundColor: '#F1F7FF' }}
                >
                  <List
                    size="small"
                    dataSource={Object.values(this.props.stagingArea)}
                    renderItem={(item: StatementStagingItem): Action => {
                      return (
                        <List.Item key={item.id}>
                          <Col span={6}>{item.reference}</Col>
                          <Col span={3}>
                            <Text type={'danger'}>
                              {'$' + toNumber(Number(item.amount))}
                            </Text>
                          </Col>
                          <Col span={4}>
                            <InputNumber
                              style={{ width: '100%' }}
                              defaultValue={item.amount}
                              max={item.amount}
                              min={0.01}
                              disabled={this.props.isCreditNote}
                              formatter={(value): string => `$${value}`}
                              parser={(value): string =>
                                value ? value.replace('$', '') : ''
                              }
                              onChange={(value): void =>
                                this.props.handleChangePrice(item, value)
                              }
                            />
                          </Col>
                          <Col span={7} offset={1}>
                            {item.days}
                          </Col>
                          <Col span={1}>
                            {
                              <Button
                                type="link"
                                onClick={(): void =>
                                  this.props.handleDelete(item.id)
                                }
                              >
                                {'X'}
                              </Button>
                            }
                          </Col>
                        </List.Item>
                      );
                    }}
                  />
                </Collapse.Panel>
              </Collapse>
              <Col offset={1} span={5}>
                <Text>{'Total a abonar:'}</Text>
              </Col>
              <Col offset={1} span={5}>
                <Text strong>
                  <h4>
                    {'$' +
                      toNumber(
                        total.reduce(
                          (sum: number, value: number): number => sum + value,
                        ),
                      )}
                  </h4>
                </Text>
              </Col>
            </Row>
          )}
        </Row>
      </Row>
    );
  };
  public calculateMaxAmount = (): number => {
    if (this.props.maxAmount > 0) {
      return this.props.maxAmount;
    }
    if (this.props.stagingArea.length > 0) {
      return this.props.stagingArea[0].amount;
    }
    return 0;
  };

  public render = (): JSX.Element => {
    const {
      visible,
      onCancel,
      onCreate,
      form,
      customerName,
      account,
    } = this.props;
    const { getFieldDecorator } = form;
    let total = 0;
    if (this.props.stagingArea.length > 0) {
      total = Object.values(this.props.stagingArea)
        .map((value: StatementStagingItem): number => {
          return Number(value.totalInvoice || 0);
        })
        .reduce((sum: number, value: number): number => sum + value);
    }
    let maxTotal = 0;
    if (this.props.stagingArea.length > 0) {
      maxTotal = Object.values(this.props.stagingArea)
        .map((value: StatementStagingItem): number => {
          return Number(value.amountToPay || 0);
        })
        .reduce((sum: number, value: number): number => sum + value);
    }
    return (
      <Modal
        width={800}
        visible={visible}
        title={'Abonar a ' + customerName}
        okText="Abonar"
        okButtonProps={{
          style: {
            display:
              this.props.stagingArea.length < 1 && this.props.isCreditNote
                ? 'none'
                : '',
          },
        }}
        cancelText="Cancelar"
        onCancel={onCancel()}
        onOk={onCreate()}
      >
        <Row>
          <Row>{this.renderFilter(this.props.statementSearchList)}</Row>
          <p />
          <Row>
            <Text>Llena los campos a continuación para hacer un abono</Text>
          </Row>
          <Row>
            <Text disabled>Los campos marcados con * son obligatorios</Text>
          </Row>
          <Form layout="vertical">
            <p />
            {(!this.props.isCreditNote ||
              this.props.account.customerAparceroType === null ||
              this.props.account.customerAparceroType === 'NA') && (
              <Col span={11} offset={1}>
                <Form.Item label="Monto">
                  {getFieldDecorator('amount', {
                    initialValue: maxTotal.toFixed(2),
                    rules: [
                      {
                        pattern: onlyNumberDecimalPattern,
                        required: true,
                        message: 'Introduce monto válido (2 decimales máximo)',
                        max: Number(this.calculateMaxAmount().toFixed(2)),
                      },
                    ],
                  })(
                    <InputNumber
                      style={{ width: '100%' }}
                      min={0}
                      disabled={!this.props.isCreditNote}
                      max={this.calculateMaxAmount()}
                      onChange={(value: number | undefined): void => {
                        this.calculatePercentageFromAmount(
                          value !== undefined ? value : 0,
                          total,
                        );
                      }}
                      formatter={(value): string => `$${value}`}
                      parser={(value): string =>
                        value ? value.replace('$', '') : ''
                      }
                    />,
                  )}
                </Form.Item>
              </Col>
            )}
            {(this.props.isCreditNote &&
              this.props.account.customerAparceroType === null) ||
              (this.props.isCreditNote &&
                this.props.account.customerAparceroType === 'NA' && (
                  <Col span={11} offset={1}>
                    <Form.Item label="Porcentaje">
                      {getFieldDecorator('percentage', {
                        initialValue: 0,
                        rules: [
                          {
                            pattern: onlyNumberDecimalPattern,
                            required: true,
                            message:
                              'Introduce porcentaje válido (2 decimales máximo)',
                          },
                        ],
                      })(
                        <InputNumber
                          style={{ width: '100%' }}
                          min={0}
                          max={this.calculateMaximumPercentageFromAmount(
                            this.calculateMaxAmount(),
                            total,
                          )}
                          onChange={(value: number | undefined): void => {
                            this.calculateAmountFromPercentage(
                              value !== undefined ? value : 0,
                              total,
                            );
                          }}
                          formatter={(value): string => `${value}%`}
                          parser={(value): string =>
                            value ? value.replace('%', '') : ''
                          }
                          disabled={this.props.stagingArea.length <= 0}
                        />,
                      )}
                    </Form.Item>
                  </Col>
                ))}

            {this.props.isCreditNote &&
              this.props.account.customerAparceroType &&
              this.props.account.customerAparceroType !== 'NA' && (
                <Col span={11} offset={1}>
                  <Form.Item label="Precio Pemex">
                    {getFieldDecorator('pemexPrice', {
                      rules: [
                        {
                          pattern: fiveDecimalPattern,
                          required: true,
                          message: 'Introduce el precio',
                          max: Number(this.calculateMaxAmount()),
                        },
                      ],
                    })(
                      <InputNumber
                        style={{ width: '100%' }}
                        min={0.00001}
                        max={this.calculateMaxAmount()}
                      />,
                    )}
                  </Form.Item>
                </Col>
              )}
            {this.props.isCreditNote &&
              this.props.account.customerAparceroType &&
              this.props.account.customerAparceroType === 'KILO' && (
                <Col span={11} offset={1}>
                  <Form.Item label="Total kilogramos">
                    {getFieldDecorator('totalWeight', {
                      rules: [
                        {
                          pattern: onlyNumberDecimalPattern,
                          required: true,
                          message: 'Introduce el peso en kg',
                          max: Number(this.calculateMaxAmount()),
                        },
                      ],
                    })(
                      <InputNumber
                        style={{ width: '100%' }}
                        min={0.1}
                        max={this.calculateMaxAmount()}
                      />,
                    )}
                  </Form.Item>
                </Col>
              )}
            {!this.props.isCreditNote && (
              <Col span={11} offset={1}>
                <Form.Item label="Numero de cuenta">
                  {getFieldDecorator('acountNumber', {
                    rules: [],
                  })(<Input />)}
                </Form.Item>
              </Col>
            )}
            {!this.props.isCreditNote && (
              <Col span={11} offset={1}>
                <Form.Item hasFeedback label="Forma de pago">
                  {getFieldDecorator('paymentForm', {
                    rules: r.formaPagoRule,
                  })(
                    <Select showSearch size="large">
                      {formaPago.map(
                        (item, index): React.ReactNode => (
                          <Select.Option key={item.key} value={item.key}>
                            <div id={'paymentForm_' + index.toString()}>
                              {item.name}
                            </div>
                          </Select.Option>
                        ),
                      )}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            )}
            {!this.props.isCreditNote && (
              <Col span={11} offset={1}>
                <Form.Item hasFeedback label="Banco">
                  {getFieldDecorator('bank', {
                    rules: [],
                  })(
                    <Select showSearch size="large">
                      {banks.map(
                        (item, index): React.ReactNode => (
                          <Select.Option key={item.name} value={item.name}>
                            <div id={'banco_' + index.toString()}>
                              {item.name}
                            </div>
                          </Select.Option>
                        ),
                      )}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            )}
            {!this.props.isCreditNote && (
              <Col span={11} offset={1}>
                <Form.Item label="Número de Transferencia" id="reference">
                  {getFieldDecorator('reference', {
                    rules: [],
                  })(<Input />)}
                </Form.Item>
              </Col>
            )}
            {!this.props.isCreditNote && (
              <Col span={11} offset={1}>
                <Form.Item hasFeedback label="Fecha de pago">
                  {getFieldDecorator('paymentDate', {
                    rules: [
                      {
                        required: true,
                        message: MessagesCustomer.DATE_REQUIRED,
                      },
                    ],
                  })(<DatePicker format="YYYY/MM/DD" />)}
                </Form.Item>
              </Col>
            )}
            {!this.props.isCreditNote && (
              <Col span={11} offset={1}>
                <Form.Item label={LabelsCustomer.CIE_KEY}>
                  {getFieldDecorator('cieKey', {
                    initialValue: account.customerCieKey
                      ? account.customerCieKey
                      : '',
                    rules: [
                      {
                        max: 50,
                        whitespace: true,
                        message: MessagesCustomer.FIFTY_CHARACTERS,
                      },
                    ],
                  })(
                    <Input
                      size="large"
                      placeholder={LabelsCustomer.CIE_KEY}
                      disabled={!isAllowed([P.CAN_EDIT_CIE_KEY])}
                    />,
                  )}
                </Form.Item>
              </Col>
            )}
          </Form>
          {!this.props.isCreditNote && (
            <Col span={11} offset={1}>
              <Form.Item hasFeedback label="Imagen">
                {getFieldDecorator('voucher', {
                  valuePropName: 'fileList',
                  getValueFromEvent: this.normFile,
                  rules: [
                    {
                      required: true,
                      message: 'Imagen Requerida',
                    },
                    { validator: fileSizeValidator(20) },
                  ],
                  initialValue: undefined,
                })(
                  <Upload
                    name="voucher"
                    action="/"
                    accept="image/*"
                    listType="picture-card"
                    beforeUpload={this.handleBeforeUpload}
                    onRemove={this.handleRemove}
                    multiple={false}
                    showUploadList={{ showPreviewIcon: false }}
                  >
                    {this.state.tempFile === false ? uploadButton : null}
                  </Upload>,
                )}
              </Form.Item>
            </Col>
          )}
        </Row>
      </Modal>
    );
  };
}
export default CreateFormStatement;
