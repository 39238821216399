import React, { Component, ReactNode, FormEvent } from 'react';
import Form, { FormComponentProps } from 'antd/lib/form';
import { Row, Col, Button, Card } from 'antd';
import { RouteComponentProps } from 'react-router';
import { AddressContactModel, Contact } from 'types/type';
import Text from 'antd/lib/typography/Text';
import { connect } from 'react-redux';
import { RootState } from 'store/configureStore';
import {
  editCustomerAddress,
  getCustomerAddress,
  cleanCustomerAddress,
} from '../../redux/actions';
import './../../style.css';
import ContactsManager from './contactsManager';
import { Link } from 'react-router-dom';
import { ButtonProps } from 'antd/lib/button';
import { Popups } from 'commons/components/popups/popups';
const buttonProps: ButtonProps = {
  type: 'primary',
  htmlType: 'submit',
};

interface HomeRouterProps {
  id: string;
}

interface DispatchProps {
  editCustomerAddress: (id: string, contact: Contact[]) => void;
  getCustomerAddress: (id: string) => void;
  cleanCustomerAddress: () => void;
}

interface StateProps {
  customerAddressModel: AddressContactModel;
  isFetching: boolean;
}

type Props = FormComponentProps &
  StateProps &
  DispatchProps &
  RouteComponentProps<HomeRouterProps>;

/**
 * this const build a style depending value send the size in the screen interact.
 * @param value is a value size in the lg xl and xxl proportion.
 */
const sizeScreen = (value: number): {} => ({
  xs: { span: 23, offset: 1 },
  sm: { span: 23, offset: 1 },
  md: { span: 23, offset: 1 },
  lg: { span: value, offset: 1 },
  xl: { span: value, offset: 1 },
  xxl: { span: value, offset: 1 },
});

class CustomerAddressEdit extends Component<Props> {
  public componentWillUnmount(): void {
    this.props.form.resetFields();
    this.props.cleanCustomerAddress();
  }
  public UNSAFE_componentWillMount(): void {
    this.props.form.resetFields();
    const { id } = this.props.match.params;
    if (id !== '0') {
      this.props.getCustomerAddress(id);
    }
  }

  private renderTheAddressInfo = (
    id: string,
    customerAddressModel: AddressContactModel,
  ): ReactNode => {
    return (
      id !== '0' &&
      customerAddressModel && (
        <Row>
          <Row>
            <Text>{`Codigo de sucursal: ${customerAddressModel.code}`}</Text>
          </Row>
        </Row>
      )
    );
  };
  private handleOnSubmit = (e: FormEvent): void => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(
      async (err, values: AddressContactModel): Promise<void> => {
        let validator = false;
        if (values.customerAddressId !== undefined) {
          await values.contact.forEach((item: Contact, index: number): void => {
            const isEmpty = values.contact.filter(
              (itemFilter, indexFilter): boolean =>
                item.email === itemFilter.email && index !== indexFilter,
            );
            if (isEmpty.length > 0) validator = true;
            else validator = false;
          });
        }
        this.setState({ validator });
        const { id } = this.props.match.params;
        if (!err && !validator) {
          try {
            await this.validateData(values, id);
            window.scroll(0, 0);
            this.props.history.push('/customer');
          } catch (error) {
            Popups.notifyException(error);
          }
        }
      },
    );
  };

  private validateData = async (
    values: AddressContactModel,
    id: string,
  ): Promise<void> => {
    if (id !== '0') {
      const customerAddress: string = id;
      const contact: Contact[] = values.contact;
      await this.props.editCustomerAddress(customerAddress, contact);
    }
  };

  public render = (): ReactNode => {
    const {
      form: {
        getFieldDecorator,
        validateFields,
        getFieldsValue,
        setFieldsValue,
        getFieldValue,
      },
      isFetching,
    } = this.props;
    const id = this.props.match.params.id;
    const customerAddressModel = this.props.customerAddressModel;
    return (
      <Row>
        <Form onSubmit={this.handleOnSubmit}>
          <Card bordered={false} className={'customer-card'}>
            <Row>
              <h1>
                <Text strong>
                  Llena los campos a continuación para agregar a un cliente
                </Text>
              </h1>
            </Row>
            <Row>
              <Text disabled>Los campos marcados con * son obligadorios</Text>
            </Row>
            {this.renderTheAddressInfo(id, customerAddressModel)}
          </Card>
          <Card
            bordered={false}
            className={'customer-card'}
            title="Agregar contactos"
          >
            <ContactsManager
              customerAddressModel={customerAddressModel}
              sizeScreen={sizeScreen}
              getFieldDecorator={getFieldDecorator}
              getFieldsValue={getFieldsValue}
              getFieldValue={getFieldValue}
              setFieldsValue={setFieldsValue}
              validateFields={validateFields}
            />
          </Card>
          <Card className={'customer-card'}>
            <Col span={11} />
            <Col span={6}>
              <Link to="/customer">
                <Button id="cancel_button" type="default" block size="large">
                  Cancelar
                </Button>
              </Link>
            </Col>
            <Col span={6} offset={1}>
              <Button
                {...buttonProps}
                id="customer_button"
                disabled={isFetching}
                className="button-customer-serch-style"
                block
                size="large"
              >
                {isFetching ? 'Loading...' : 'Guardar cliente'}
              </Button>
            </Col>
          </Card>
        </Form>
      </Row>
    );
  };
}

const mapStateToProps = (states: RootState): StateProps => {
  return {
    customerAddressModel: states.customerState.customerAddressModel,
    isFetching: states.customerState.isFetching,
  };
};

const mapDispatchToProps: DispatchProps = {
  editCustomerAddress,
  getCustomerAddress,
  cleanCustomerAddress,
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create()(CustomerAddressEdit));
