/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by David Canul <david@ssf.com.mx>, Jul 2019
 *
 *
 */
import { Payment, PaymentFilters } from 'types/type';

/*
 * Action to add a payment to list component
 */
export const SET_PAYMENT = 'SET_PAYMENT';
export const SET_PAYMENT_LIST = 'SET_PAYMENT_LIST';
export const SET_DISTRIBUTION_CENTER = 'SET_DISTRIBUTION_CENTER';
export const SET_PAYMENT_COMPANY_LIST = 'SET_PAYMENT_COMPANY_LIST';

/*
 * Begin a fetch operation action
 */
export const BEGIN_FETCH = 'BEGIN_FETCH';

/*
 * A fetch operation action have been completed
 */
export const FETCH_COMPLETED = 'FETCH_COMPLETED';

/**
 * Action to set the actual filters selected
 */
export const SET_PAYMENT_FILTERS = 'SET_PAYMENT_FILTERS';
export const RESET_PAYMENT_FILTERS = 'RESET_PAYMENT_FILTERS';

interface BeginFetchAction {
  type: typeof BEGIN_FETCH;
}
interface SetAction {
  type: typeof SET_PAYMENT_LIST;
  payment: Payment;
}
interface SetPaymentAction {
  type: typeof SET_PAYMENT;
  paymentList: {};
}

interface EndFetchAction {
  type: typeof FETCH_COMPLETED;
}

interface SetPaymentFilters {
  type: typeof SET_PAYMENT_FILTERS;
  paymentFilters: PaymentFilters;
}

interface SetDistributionCenterAction {
  type: typeof SET_DISTRIBUTION_CENTER;
  distributionCenter: {};
}

interface SetPaymentCompanyListAction {
  type: typeof SET_PAYMENT_COMPANY_LIST;
  companyId: {};
}

export type PaymentAction =
  | EndFetchAction
  | BeginFetchAction
  | SetAction
  | SetPaymentAction
  | SetDistributionCenterAction
  | SetPaymentFilters
  | SetPaymentCompanyListAction;
