/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by David Canul <david@ssf.com.mx>, Jun 2019
 *
 * This component lists all the sales crawled from g4s
 */
import React, { Component } from 'react';
import { Row, Col, Table, Icon, Button, Form, Card, Popconfirm } from 'antd';
import { Settlement, Sale } from 'types/type';
import { connect } from 'react-redux';
import { RootState } from '../../../store/configureStore';
import { saveposSettlement, getSettlementbyId } from '../redux/actions';
import moment from 'moment';
import Text from 'antd/lib/typography/Text';
import { isAllowed } from 'security';
import { RouteComponentProps } from 'react-router';
import { FormComponentProps } from 'antd/lib/form';
import { toNumber } from 'commons/numbersFormat';
import { ColumnProps } from 'antd/lib/table';
import { CheckboxProps } from 'antd/lib/checkbox';
import { initTotalsTypes, initSale } from 'commons/initTypes';
import '../index.css';
import { paymentConditionEs, paymentWayEs } from 'commons/components/PaymentEs';
import { DATE_FORMAT } from 'commons/services';

interface DispatchProps {
  getSettlementbyId: (id: string) => void;
  saveposSettlement: (
    salesId: { id: string; total: number }[],
    id: string,
  ) => void;
  //saveposSettlement: (salesId: string[], id: string) => void;
}
interface HomeRouterProps {
  id: string;
  distributionCenterName: string;
}
interface StateProps {
  isFetching: boolean;
  settlementToApply: Settlement;
}

type Props = StateProps &
  DispatchProps &
  FormComponentProps &
  RouteComponentProps<HomeRouterProps>;

interface State {
  expanded: string[] | number[];
  stagingArea: Sale[];
}

class PosSettlement extends Component<Props> {
  public state: State = {
    expanded: [],
    stagingArea: [],
  };
  public componentDidMount(): void {
    const { id } = this.props.match.params;
    this.props.getSettlementbyId(id);
  }
  public selected = (record: Sale, selected: boolean): void => {
    if (selected === false) {
      const stagingArea = this.state.stagingArea;
      const newStagingArea = stagingArea.filter(
        (item: Sale): boolean => item.id !== record.id,
      );
      this.setState({ stagingArea: newStagingArea });
    } else {
      //Validador 1
      const stagingArea: Sale[] = this.state.stagingArea;
      var selectedValue = 0;
      selectedValue = this.getselectedValues(
        record,
        this.equipmentAppTypeAmount(record),
      );
      // postTotal: (record.total- selectedValue),300
      // postTotal: record.total-(record.total- selectedValue),500
      if (
        Number(this.props.settlementToApply.totalObjective) <
        record.total - (record.total - selectedValue)
      ) {
        stagingArea.push({
          ...record,
          postTotal: record.total - selectedValue,
        });
      } else {
        stagingArea.push({
          ...record,
          postTotal: record.total - (record.total - selectedValue),
        });
      }
      this.setState({ stagingArea: stagingArea });
    }
  };
  public equipmentAppTypeAmount = (record: Sale): number => {
    return record.equipmentAppType === 'CARBURACION'
      ? initTotalsTypes.totalAmountCarburacionC[Math.floor(Math.random() * 3)] *
          (record.total / record.totalVolume)
      : initTotalsTypes.totalAmountAutotanqueC[Math.floor(Math.random() * 3)] *
          (record.total / record.totalVolume);
  };

  public getbalanceObjectiveFromDay = (): number => {
    return (
      Number(this.props.settlementToApply.totalObjective) -
      Number(this.calculateSelected())
    );
  };

  public getselectedValues = (
    record: Sale,
    equipmentAmount: number,
  ): number => {
    let total = 0;
    const ObjetiveBalanceDays: number = this.getbalanceObjectiveFromDay();
    const totalSale: number = record.total;
    console.log(this.getbalanceObjectiveFromDay(), totalSale);
    if (
      totalSale > ObjetiveBalanceDays &&
      totalSale - ObjetiveBalanceDays > equipmentAmount
    ) {
      /*
      valida si el total de la venta sea menor al objetivo del dia 
      y que el total de la venta menos el el monto random del equipo es mejoral monto random del equipo
      */
      return totalSale - equipmentAmount;
    } else if (
      totalSale > ObjetiveBalanceDays &&
      totalSale - ObjetiveBalanceDays < equipmentAmount
    ) {
      /*
      valida si el total de la venta sea menor al objetivo del dia 
      y que el total de la venta menos el el monto random del equipo es mejoral monto random del equipo
      */
      return totalSale - ObjetiveBalanceDays;
    } else if (
      totalSale < ObjetiveBalanceDays &&
      totalSale - equipmentAmount < equipmentAmount
    ) {
      /*
      valida si el total de la venta sea menor al objetivo del dia 
      y que el total de la venta menos el el monto random del equipo es mejoral monto random del equipo
      */
      return equipmentAmount;
    } else if (totalSale < ObjetiveBalanceDays) {
      /*
      valida si el total de la venta sea menor al objetivo del dia 
      */
      return equipmentAmount;
    } else if (
      totalSale > ObjetiveBalanceDays &&
      totalSale - equipmentAmount < equipmentAmount
    ) {
      /*
      valida que el total de la venta sea mayor al objetivo del dia 
      y que el total de la venta menos el monto random del equipo sea menor al monto random del equipo
      */
      return equipmentAmount;
    } else if (
      totalSale > ObjetiveBalanceDays &&
      ObjetiveBalanceDays > equipmentAmount &&
      totalSale - ObjetiveBalanceDays > equipmentAmount
    ) {
      /*
      valida que el total de la venta sea mayor al objetivo del dia 
      y que objetivo del dia sea mayor al monto del equipo
      y que el total de la venta menos el objetivo del dia sea mayor al monto del equipo
      */
      return totalSale - ObjetiveBalanceDays;
    } else if (
      totalSale > ObjetiveBalanceDays &&
      ObjetiveBalanceDays > equipmentAmount &&
      totalSale - ObjetiveBalanceDays < equipmentAmount
    ) {
      /*
      valida que el total de la venta sea mayor al objetivo del dia 
      y que objetivo del dia sea mayor al monto del equipo
      y que el total de la venta menos el objetivo del dia sea menor al monto del equipo
      */
      return equipmentAmount;
    } else if (
      totalSale > ObjetiveBalanceDays &&
      ObjetiveBalanceDays < equipmentAmount
    ) {
      /*
      valida que el total de la venta sea mayor al objetivo del dia 
      y que el objetivo del dia sea menor al monto del equipo
      */
      return totalSale - ObjetiveBalanceDays;
    } else if (
      totalSale > ObjetiveBalanceDays &&
      ObjetiveBalanceDays > equipmentAmount
    ) {
      /*valida que el total de la venta sea mayor al objetivo del dia 
      y que el objetivo del dia sea mayor al monto del equipo
      */
      return totalSale - ObjetiveBalanceDays;
    }
    return total;
  };

  public autoSelectSalales = (): void => {
    const { sales } = this.props.settlementToApply;
    var selectedValue = 0;
    const timSale: Sale[] = sales
      ? [...sales].sort((a: Sale, b: Sale): number => b.total - a.total)
      : [initSale];

    let newStagingArea: Sale[] = [];

    timSale.forEach((foreachSale: Sale): void => {
      const posliquidable: boolean = !foreachSale.postliquidable
        ? false
        : foreachSale.postliquidable;

      const randomvalue: number = this.getselectedValues(
        foreachSale,
        this.equipmentAppTypeAmount(foreachSale),
      );
      if (
        //500 - (500-130)> 120
        //500 - 380 >120
        //
        posliquidable &&
        foreachSale.total - (foreachSale.total - randomvalue) >= randomvalue &&
        selectedValue + randomvalue <=
          Number(this.props.settlementToApply.totalObjective) &&
        foreachSale.total - (foreachSale.total - randomvalue) > 0
      ) {
        selectedValue = selectedValue + randomvalue;
        newStagingArea.push({
          ...foreachSale,
          postTotal: foreachSale.total - (foreachSale.total - randomvalue),
        });
        console.log('stagingAreaAll', newStagingArea);
      }
    });

    this.setState({ stagingArea: newStagingArea });
  };
  public resetSelect = (): void => {
    this.setState({ stagingArea: [] });
  };

  public applyPosSettlement = (): void => {
    const { id } = this.props.match.params;
    const startAsync = async (): Promise<void> => {
      try {
        await this.props.saveposSettlement(
          this.state.stagingArea.map((item: Sale): {
            id: string;
            total: number;
          } => {
            return { id: item.idG4s, total: item.postTotal };
            //return item.idG4s;
          }),
          id,
        );
        this.props.history.push('/settlements');
      } catch {}
    };
    startAsync();
  };

  public addressListContent: ColumnProps<Sale>[] = [
    {
      title: 'Fecha y hora',
      dataIndex: 'endDateSave',
      sorter: (actual: Sale, pasado: Sale): number =>
        moment(actual.endDateSave).unix() - moment(pasado.endDateSave).unix(),
      sortDirections: ['descend', 'ascend'],
      key: 'endDateSave',
      render: (text: string, record: Sale): React.ReactNode => {
        return moment(record.endDateSave).format(DATE_FORMAT);
      },
    },
    {
      title: 'Ticket',
      dataIndex: isAllowed(['CAN_SEE_FOLIOS']) ? 'salesFolio' : 'IUSCode',
      key: isAllowed(['CAN_SEE_FOLIOS']) ? 'salesFolio' : 'IUSCode',
    },
    {
      title: 'Cliente',
      dataIndex: 'customerName',
      key: 'customerName',
      sorter: (actual: Sale, pasado: Sale): number =>
        actual.customerName.localeCompare(pasado.customerName),
      sortDirections: ['descend', 'ascend'],
      render: (text: string): React.ReactNode => {
        return text !== '' ? text : 'PÚBLICO GENERAL';
      },
    },
    {
      title: 'RFC',
      dataIndex: 'customerRfc',
      key: 'customerRfc',
    },
    {
      title: 'Condición de pago',
      dataIndex: 'paymentConditionType',
      key: 'paymentConditionType',
      render: (text: string): React.ReactNode => {
        return paymentConditionEs(text);
      },
    },
    {
      title: 'Requiere Factura',
      dataIndex: 'invoiceRequired',
      key: 'invoiceRequired',
      render: (text: boolean): React.ReactNode => {
        return text ? 'SI' : 'NO';
      },
    },
    {
      title: 'Forma de Pago',
      dataIndex: 'paymentWay',
      key: 'paymentWay',
      render: (text: string): React.ReactNode => {
        return paymentWayEs(text);
      },
    },
    {
      title: 'Cantidad (L)',
      dataIndex: 'totalVolume',
      key: 'totalVolume',
      render: (text: string): React.ReactNode => {
        return text + ' Litros';
      },
    },
    {
      title: 'Monto',
      dataIndex: 'total',
      key: 'total',
      sorter: (actual: Sale, pasado: Sale): number =>
        actual.total - pasado.total,
      sortDirections: ['descend', 'ascend'],
      render: (text: string, record: Sale): React.ReactNode => {
        return this.getAmountPostSettlement(text, record);
      },
    },
  ];

  private getAmountPostSettlement = (
    text: string,
    record: Sale,
  ): React.ReactNode => {
    const objetoArea: Sale[] = this.state.stagingArea.filter(
      (item: Sale): boolean => item.id === record.id,
    );
    return objetoArea.length > 0
      ? objetoArea[0].id === record.id
        ? '$ ' +
          toNumber(record.total) +
          ' - $ ' +
          toNumber(objetoArea[0].postTotal || 0)
        : '$ ' + toNumber(record.total)
      : '$ ' + toNumber(record.total);
  };

  private calculateSaleCashTotal = (): string => {
    let total = 0;
    const { settlementToApply } = this.props;
    if (settlementToApply.sales) {
      settlementToApply.sales.forEach((s: Sale): void => {
        if (s.paymentConditionType === 'CASH' && s.customerType === 'CASH') {
          total += 1;
        }
      });
    }
    return total.toFixed(2);
  };

  public calculateSelected = (): string => {
    let total = 0;
    this.state.stagingArea.forEach((s: Sale): void => {
      total += s.total - (s.postTotal || 0);
    });
    return total.toFixed(2);
  };
  public calculateObjectiveSale = (): string => {
    let total = 0;
    if (this.props.settlementToApply.sales)
      this.props.settlementToApply.sales.forEach((s: Sale): void => {
        total += s.objetiveSale;
      });
    return total.toFixed(2);
  };
  public calculateDif = (): string => {
    let totalSelected = 0;
    if (this.props.settlementToApply.sales)
      this.props.settlementToApply.sales.forEach((s: Sale): void => {
        totalSelected += s.objetiveSale;
      });
    let totalObjetive = 0;
    this.state.stagingArea.forEach((s: Sale): void => {
      const total: number =
        s.total === s.postTotal ? s.postTotal : s.total - s.postTotal;
      totalObjetive += total;
    });
    return (totalObjetive - totalSelected).toFixed(2);
  };

  public validatorChecked = (record: Sale): boolean => {
    this.state.stagingArea.filter((s: Sale): boolean => s.id === record.id);

    if (
      this.state.stagingArea.filter((s: Sale): boolean => s.id === record.id)
        .length > 0
    ) {
      return true;
    }
    return false;
  };

  public validateCheck = (record: Sale): boolean => {
    const posliquidable: boolean = !record.postliquidable
      ? true
      : !record.postliquidable;
    const maxValue: number =
      record.equipmentAppType === 'CARBURACION'
        ? initTotalsTypes.totalAmountCarburacionC[3]
        : initTotalsTypes.totalAmountAutotanqueC[3];
    if (this.validatorChecked(record)) {
      return false;
    }
    if (
      posliquidable /*  ||
      Number(this.props.settlementToApply.totalObjective) -
        Number(this.calculateSelected()) <=
        0  */ ||
      maxValue <= record.total / record.totalVolume
    ) {
      return true;
    }
    return false;
  };
  public render = (): React.ReactNode => {
    return (
      <div className={'content-backgroud'}>
        <Row>
          <Row>
            <Col
              style={{
                height: '108px',
                background: '#F1F7FF',
              }}
            >
              <Col
                xs={23}
                sm={23}
                md={5}
                lg={5}
                xl={5}
                xxl={5}
                offset={1}
                style={{ top: '19px' }}
              >
                <Text strong className={'ticket-text-name'}>
                  posliquidar corte:
                </Text>
                <Text strong className={'ticket-text-name'}>
                  <h3>{this.props.settlementToApply.folio}</h3>
                </Text>
              </Col>
              <Col
                xs={23}
                sm={23}
                md={10}
                lg={10}
                xl={10}
                xxl={10}
                offset={1}
                style={{ top: '19px' }}
              >
                <Col>
                  {'Centro de distribución:'}
                  {this.props.match.params.distributionCenterName}
                </Col>
                <Col>
                  {'Tipo de servicio:'}
                  {this.props.settlementToApply.serviceType}
                </Col>
                <Col>
                  {'Unidad:'}
                  {this.props.settlementToApply.unit}
                </Col>
              </Col>
            </Col>
          </Row>
          <Row>
            <Col>
              <p />
              <Col
                xs={{ span: 23, offset: 1 }}
                sm={{ span: 23, offset: 1 }}
                md={{ span: 11, offset: 1 }}
                lg={{ span: 11, offset: 1 }}
                xl={{ span: 4, offset: 0 }}
                xxl={{ span: 4, offset: 0 }}
              >
                <Card className="card-statement">
                  <Text>{'Seleccionado'}</Text>
                  <Text>
                    <h3>{'$ ' + toNumber(Number(this.calculateSelected()))}</h3>
                  </Text>
                </Card>
              </Col>
              <Col
                xs={{ span: 23, offset: 0 }}
                sm={{ span: 23, offset: 0 }}
                md={{ span: 11, offset: 0 }}
                lg={{ span: 11, offset: 0 }}
                xl={{ span: 4, offset: 0 }}
                xxl={{ span: 4, offset: 0 }}
              >
                <Card className="card-statement">
                  <Text>{'Diferencia corte/seleccionado'}</Text>
                  <Text>
                    <h3>{'$ ' + toNumber(Number(this.calculateDif()))}</h3>
                  </Text>
                </Card>
              </Col>
              <Col
                xs={{ span: 23, offset: 0 }}
                sm={{ span: 23, offset: 0 }}
                md={{ span: 11, offset: 0 }}
                lg={{ span: 11, offset: 0 }}
                xl={{ span: 4, offset: 0 }}
                xxl={{ span: 4, offset: 0 }}
              >
                <Card className="card-statement">
                  <Text>{'Objetivo del corte'}</Text>
                  <Text>
                    <h3>
                      {'$ ' + toNumber(Number(this.calculateObjectiveSale()))}
                    </h3>
                  </Text>
                </Card>
              </Col>
              <Col
                xs={{ span: 23, offset: 0 }}
                sm={{ span: 23, offset: 0 }}
                md={{ span: 11, offset: 0 }}
                lg={{ span: 11, offset: 0 }}
                xl={{ span: 4, offset: 0 }}
                xxl={{ span: 4, offset: 0 }}
              >
                <Card className="card-statement">
                  <Text>{'Objetivo del Dia'}</Text>
                  <Text>
                    <h3>
                      {'$ ' +
                        toNumber(
                          Number(
                            this.props.settlementToApply.totalObjective || 0,
                          ),
                        )}
                    </h3>
                  </Text>
                </Card>
              </Col>

              {this.props.settlementToApply.totalCalculateObjective && (
                <Col
                  xs={{ span: 23, offset: 0 }}
                  sm={{ span: 23, offset: 0 }}
                  md={{ span: 11, offset: 0 }}
                  lg={{ span: 11, offset: 0 }}
                  xl={{ span: 4, offset: 0 }}
                  xxl={{ span: 4, offset: 0 }}
                >
                  <Card className="card-statement">
                    <Text>{'Objetivo Inv'}</Text>
                    <Text>
                      <h3>
                        {'$ ' +
                          toNumber(
                            Number(
                              this.props.settlementToApply
                                .totalCalculateObjective || 0,
                            ),
                          )}
                      </h3>
                    </Text>
                  </Card>
                </Col>
              )}
              <Col
                xs={{ span: 23, offset: 1 }}
                sm={{ span: 23, offset: 1 }}
                md={{ span: 11, offset: 1 }}
                lg={{ span: 11, offset: 1 }}
                xl={{ span: 4, offset: 0 }}
                xxl={{ span: 4, offset: 0 }}
              >
                <Col>{'Notas Totales:'}</Col>
                <Col>
                  {this.props.settlementToApply.sales
                    ? this.props.settlementToApply.sales.length
                    : 0}
                </Col>

                <Col>{'Notas Seleccionadas:'}</Col>
                <Col>{this.state.stagingArea.length}</Col>
              </Col>
            </Col>
            <Col
              xs={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              md={{ span: 11, offset: 1 }}
              lg={{ span: 11, offset: 1 }}
              xl={{ span: 4, offset: 0 }}
              xxl={{ span: 4, offset: 0 }}
            >
              <Button
                type="dashed"
                block
                onClick={(): void =>
                  this.state.stagingArea.length > 0
                    ? this.resetSelect()
                    : this.autoSelectSalales()
                }
              >
                {this.state.stagingArea.length > 0
                  ? 'Limpiar'
                  : 'Preseleccionar'}
              </Button>
              <p />
              <Popconfirm
                title="¿Estás seguro que quieres posliquidar el corte?"
                okText="Sí,Posliquidar"
                cancelText="No"
                onConfirm={(): void => this.applyPosSettlement()}
                icon={
                  <Icon type="question-circle-o" style={{ color: 'red' }} />
                }
              >
                <Button type="primary" block disabled={this.props.isFetching}>
                  Posliquidar
                </Button>
              </Popconfirm>
            </Col>
          </Row>
          <Col xs={0} sm={0} md={0} lg={0} xl={24} xxl={24}>
            <p />
            <Card bordered={false}>
              <Table
                loading={this.props.isFetching}
                className={'table-post-settlements ant-table-thead '}
                rowKey="id"
                expandedRowKeys={this.state.expanded}
                columns={this.addressListContent}
                pagination={false}
                rowSelection={{
                  onSelect: this.selected,
                  getCheckboxProps: (record: Sale): CheckboxProps => {
                    return {
                      checked: this.validatorChecked(record),
                      disabled: this.validateCheck(record),
                    };
                  },
                }}
                dataSource={
                  this.props.settlementToApply.sales
                    ? this.props.settlementToApply.sales
                    : []
                }
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
  };
}

const mapStateToProps = (states: RootState): StateProps => {
  return {
    settlementToApply: states.settlementState.settlementToApply,
    isFetching: states.settlementState.isFetching,
  };
};

const mapDispatchToProps = {
  saveposSettlement,
  getSettlementbyId,
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create()(PosSettlement));
