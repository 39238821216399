import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Button,
  Col,
  Pagination,
  Switch,
  Select,
  Table,
  Card,
  Badge,
  Tooltip,
  Icon,
} from 'antd';
import './style.css';
import {
  page,
  desactivateUserById,
  activateUserById,
  trysendpassword,
  getDistributionCenterAction,
  getMarketstallAction,
} from './redux/actions';
import { UsersListI } from './redux/reducers';
import { RootState } from '../../store/configureStore';
import { User, DistributionCenter, Marketstall } from '../../types/type';
import { Link } from 'react-router-dom';
import Text from 'antd/lib/typography/Text';
import Search from 'antd/lib/input/Search';
import { Strings, LabelsUser } from 'commons/strings';
import noUser from 'assets/noCustomers.svg';
import { TableEventListeners } from 'antd/lib/table';
import { getMaximumUserPermission, getDateStatus } from './functions';
import moment from 'moment';
import { isAllowed } from 'security';
import { Permissions as P } from 'commons/permissions';
interface DispatchProps {
  page: (
    filter: string,
    userMarketstall: string,
    distributionCenter: string,
    currentPage: number,
    pageSize: number,
  ) => void;
  desactivateUserById: (values: string) => void;
  activateUserById: (values: string) => void;
  trysendpassword: (values: string) => void;
  getDistributionCenterAction: () => void;
  getMarketstallAction: () => void;
}
interface StateProps {
  usersList: UsersListI;
  distributionCenters: DistributionCenter[];
  marketstall: Marketstall[];
  isFetching: boolean;
}

interface State {
  data: User[];
  currentPage: number;
  pageSize: number;
  paginationenable: boolean;
  distributionCenter: string;
  userMarketstall: string;
  filter: string;
  sizeWindow: number;
  expandedRows: string[];
}

type Props = StateProps & DispatchProps & State;

class Users extends Component<Props> {
  public state: State = {
    data: [],
    currentPage: 1,
    pageSize: 25,
    paginationenable: true,
    distributionCenter: '',
    userMarketstall: '',
    filter: '',
    sizeWindow: 0,
    expandedRows: [],
  };

  public componentDidMount(): void {
    window.addEventListener('resize', this.onWindowSyzeChange);
    this.props.getDistributionCenterAction();
    this.props.getMarketstallAction();
    this.props.page('', '', '', 1, this.state.pageSize);
  }

  public onWindowSyzeChange = (): void => {
    this.setState({
      sizeWindow: window.innerWidth,
    });
  };

  public handlePaginationChange = (current: number): void => {
    this.props.page('', '', '', current, this.state.pageSize);
    this.setState({ currentPage: current });
  };

  private handleSizePaginationChange = (
    currentPage: number,
    pageSize?: number,
  ): void => {
    this.props.page(
      '',
      '',
      '',
      currentPage,
      pageSize !== undefined ? pageSize : this.state.pageSize,
    );
    //Set state to update what screen is showing
    this.setState({ currentPage, pageSize });
  };

  public handeChangeEditingValue = (
    row: { id: string },
    isActive: boolean,
  ): void => {
    const { data } = this.state;
    if (data.length > 0) {
      const newData: { id: string; isActive: boolean } = data.filter(
        (dataRow: { id: string; isActive: boolean }): boolean =>
          dataRow.id === row.id,
      )[0];

      newData.isActive = !isActive;
      const filterContent = data.map((dataRow: { id: string }): {
        id: string;
      } => {
        if (dataRow.id === row.id) {
          return newData;
        }
        return dataRow;
      });

      this.setState({ data: [...filterContent] });
    }
    this.handleActivateOrDeactivateUser(row.id, isActive);
  };
  public handleActivateOrDeactivateUser = (
    userId: string,
    isActive: boolean,
  ): void => {
    if (isActive) this.props.desactivateUserById(userId);
    else this.props.activateUserById(userId);
  };

  public handleTrysendpassword = (email: string): void => {
    this.props.trysendpassword(email);
  };
  public handleFindUser = (userSearchString: string): void => {
    if (userSearchString === Strings.EMPTY_STRING || userSearchString) {
      const { userMarketstall, distributionCenter } = this.state;
      this.props.page(
        userSearchString,
        userMarketstall,
        distributionCenter,
        1,
        25,
      );
      this.setState({ filter: userSearchString });
    }
  };
  public handleChangeDistributionCenter = (
    distributionCenter: string,
  ): void => {
    const { filter, userMarketstall } = this.state;
    this.props.page(filter, userMarketstall, distributionCenter, 1, 25);
    this.setState({ distributionCenter: distributionCenter });
  };
  public handleChangeUserMarketstall = (userMarketstall: string): void => {
    const { filter, distributionCenter } = this.state;
    this.props.page(filter, userMarketstall, distributionCenter, 1, 25);
    this.setState({ userMarketstall: userMarketstall });
  };

  public renderSearchBar = (): React.ReactNode => {
    return (
      <Row className="search-bar">
        <Col
          xs={{ span: 23, offset: 1 }}
          sm={{ span: 11, offset: 1 }}
          md={{ span: 10, offset: 0 }}
          lg={{ span: 7, offset: 0 }}
          xl={{ span: 7, offset: 0 }}
          xxl={{ span: 7, offset: 0 }}
        >
          <Text strong className="text-filters">
            Buscar por nombre, teléfono o correo
          </Text>
          <Search
            size="large"
            placeholder="Buscar por nombre, teléfono o correo"
            onSearch={(value: string): void => this.handleFindUser(value)}
            enterButton
          />
        </Col>
        <Col xs={23} sm={11} md={6} lg={4} xl={4} xxl={3} offset={1}>
          <Text strong className="text-filters">
            {LabelsUser.USER_DISTRIBUTIONCENTER}
          </Text>

          <Select
            defaultValue=""
            size="large"
            style={{ width: '100%' }}
            onChange={(value: string): void =>
              this.handleChangeDistributionCenter(value)
            }
          >
            <Select.Option value="">Todos</Select.Option>
            {this.props.distributionCenters &&
              this.props.distributionCenters.map(
                (item, index): React.ReactNode => (
                  <Select.Option key={item.id} value={item.id}>
                    <div id={'dist_' + index.toString()}> {item.name}</div>
                  </Select.Option>
                ),
              )}
          </Select>
        </Col>
        <Col xs={23} sm={11} md={6} lg={4} xl={4} xxl={3} offset={1}>
          <Text strong className="text-filters">
            Puesto
          </Text>

          <Select
            defaultValue=""
            size="large"
            style={{ width: '100%' }}
            onChange={(value: string): void =>
              this.handleChangeUserMarketstall(value)
            }
          >
            <Select.Option value="">Todos</Select.Option>
            {this.props.marketstall.map(
              (item, index): React.ReactNode => (
                <Select.Option key={item.id} value={item.id}>
                  <div id={'dist_' + index.toString()}> {item.name}</div>
                </Select.Option>
              ),
            )}
          </Select>
        </Col>
        {this.props.usersList.content !== undefined &&
          (this.props.usersList.content.length || []) > 0 && (
            <Col
              xs={{ span: 17, offset: 7 }}
              sm={{ span: 11, offset: 1 }}
              md={{ span: 17, offset: 7 }}
              lg={{ span: 3, offset: 4 }}
              xl={{ span: 3, offset: 4 }}
              xxl={{ span: 3, offset: 6 }}
            >
              {isAllowed([P.CAN_ADD_USER]) && (
                <Link
                  key="1"
                  to={{
                    pathname: '/users/components/CollectionCreateForm/0',
                  }}
                >
                  <Button
                    type="primary"
                    className="button-add-user button-size"
                    size="small"
                    icon="plus"
                    id="user_new"
                  >
                    Agregar Usuario
                  </Button>
                </Link>
              )}
            </Col>
          )}
      </Row>
    );
  };
  public renderEmpty = (): React.ReactNode => {
    return (
      <Card className="user-main-card">
        <Card
          bordered={false}
          className="login-card"
          cover={
            <div className="center-items-card">
              <Row>
                <img src={noUser} alt="No user" />
              </Row>
              <Row>
                <Text>Sin usuarios</Text>
              </Row>
              <Row>
                <Link
                  key="1"
                  to={{
                    pathname: '/users/components/CollectionCreateForm/0',
                  }}
                >
                  <Button type="primary">{'+ Agregar Usuario'}</Button>
                </Link>
              </Row>
            </div>
          }
        />
      </Card>
    );
  };
  public render = (): ReactNode => {
    const { content } = this.props.usersList;
    const { distributionCenters, marketstall } = this.props;
    this.state.sizeWindow === 0 && this.onWindowSyzeChange();
    return (
      <Row className="main-layout">
        <Row>{this.renderSearchBar()}</Row>

        {this.props.usersList.content !== undefined &&
        (this.props.usersList.content.length || []) < 1 ? (
          <Row>{this.renderEmpty()}</Row>
        ) : (
          <Row style={{ backgroundColor: '#fff' }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Table<User>
                className="custom-table1 user-table responsive-table"
                pagination={false}
                dataSource={content}
                rowKey={(record): string => record.id}
                onExpand={(expand: boolean, record: User): void => {
                  //Expand the rows one by one when user has permission to edit
                  expand && isAllowed([P.CAN_EDIT_USER])
                    ? this.setState({ expandedRows: [record.id] })
                    : this.setState({ expandedRows: [] });
                }}
                expandedRowKeys={this.state.expandedRows}
                expandedRowRender={(record): React.ReactNode => (
                  <div className="responsive-p">
                    <p>
                      <Text strong className="texto">
                        Permisos
                      </Text>
                    </p>
                    <Row justify="start" align="middle">
                      {record.userPermission.map(
                        (permission, index: number): JSX.Element => (
                          <Col span={7} className="permission-list" key={index}>
                            <p>
                              <div className="texto">
                                <Text>{permission.description}</Text>
                              </div>
                            </p>
                          </Col>
                        ),
                      )}
                    </Row>
                  </div>
                )}
              >
                <Table.Column
                  title="Nombre"
                  dataIndex="name"
                  key="name"
                  className="table-elements"
                  onCell={(): TableEventListeners => ({
                    'table-title': 'Nombre:',
                  })}
                  render={(text: string, record: User): React.ReactNode => {
                    let badge = <div></div>;
                    if (isAllowed([P.CAN_EDIT_USER])) {
                      const permission = getMaximumUserPermission(record);
                      let estado = 'blue';
                      if (permission === 1) estado = 'red';
                      if (permission === 2) estado = 'gold';
                      if (permission === 3) estado = 'green';
                      badge = <Badge color={estado} />;
                    }

                    return (
                      <div>
                        {badge} {text}
                      </div>
                    );
                  }}
                />
                <Table.Column
                  title="Correo"
                  dataIndex="email"
                  key="email"
                  className="table-elements"
                  onCell={(): TableEventListeners => ({
                    'table-title': 'Correo:',
                  })}
                />
                <Table.Column
                  title={LabelsUser.USER_PHONE}
                  dataIndex="phone"
                  key="phone"
                  className="table-elements"
                  onCell={(): TableEventListeners => ({
                    'table-title': LabelsUser.USER_PHONE + ':',
                  })}
                />
                <Table.Column
                  title="Puesto"
                  dataIndex="marketstall"
                  key="marketstall"
                  className="table-elements"
                  onCell={(): TableEventListeners => ({
                    'table-title': 'Puesto:',
                  })}
                  render={(text: string): React.ReactNode => {
                    const itemMarketstall = marketstall.filter(
                      (f: Marketstall): boolean => f.id === text,
                    );
                    return <div>{(itemMarketstall[0] || {}).name}</div>;
                  }}
                />
                <Table.Column
                  title={LabelsUser.USER_DISTRIBUTIONCENTER}
                  dataIndex="distributionCenterId"
                  key="distributionCenter"
                  className="table-elements"
                  onCell={(): TableEventListeners => ({
                    'table-title': 'Centro de distribución:',
                  })}
                  render={(text: string): React.ReactNode => {
                    const itemDistributionCenter: DistributionCenter[] =
                      distributionCenters &&
                      distributionCenters.filter(
                        (f: DistributionCenter): boolean => f.id === text,
                      );
                    return (
                      itemDistributionCenter && (
                        <div>{(itemDistributionCenter[0] || {}).name}</div>
                      )
                    );
                  }}
                />
                <Table.Column
                  title="Última conexión"
                  dataIndex="lastActiveDate"
                  key="lastActiveDate"
                  className="table-elements"
                  render={(text: string): React.ReactNode => {
                    return (
                      <Tooltip title={text}>
                        <Icon type={'calendar'} />
                        {' ' + getDateStatus(moment(text))}
                      </Tooltip>
                    );
                  }}
                />
                <Table.Column
                  title="Acciones"
                  dataIndex="id"
                  key="actions"
                  align="center"
                  className={
                    this.state.sizeWindow > 1100 || this.state.sizeWindow === 0
                      ? 'user-actions'
                      : ''
                  }
                  onCell={(): TableEventListeners => ({
                    'table-title': 'Acciones:',
                  })}
                  render={(text: string, record: User): React.ReactNode => {
                    return (
                      <Row
                        type="flex"
                        align="middle"
                        className={
                          this.state.sizeWindow > 1100 ||
                          this.state.sizeWindow === 0
                            ? 'actions'
                            : 'actions-size'
                        }
                      >
                        <Col span={24}>
                          {isAllowed([P.CAN_EDIT_USER]) && (
                            <Col
                              xs={24}
                              sm={8}
                              md={8}
                              lg={this.state.sizeWindow <= 1100 ? 8 : 24}
                              xl={{ span: 8, offset: 0 }}
                              xxl={{ span: 8, offset: 0 }}
                            >
                              <Link
                                key="3"
                                to={{
                                  pathname: `/users/components/CollectionCreateForm/${text}`,
                                }}
                              >
                                <Button type="link"> Editar </Button>
                              </Link>
                            </Col>
                          )}

                          {isAllowed([P.CAN_SEND_USER_CREDENTIALS]) && (
                            <Col
                              xs={24}
                              sm={8}
                              md={8}
                              lg={this.state.sizeWindow <= 1100 ? 8 : 24}
                              xl={{ span: 8, offset: 0 }}
                              xxl={{ span: 8, offset: 0 }}
                            >
                              <Button
                                type="link"
                                key={record.id + '2'}
                                disabled={!record.isActive}
                                onClick={(): void =>
                                  this.handleTrysendpassword(record.email)
                                }
                              >
                                Re-enviar
                              </Button>
                            </Col>
                          )}
                          {isAllowed([P.CAN_ENABLE_OR_DISABLE_CREDENTIALS]) && (
                            <Col
                              xs={24}
                              sm={8}
                              md={8}
                              lg={this.state.sizeWindow <= 1100 ? 8 : 24}
                              xl={{ span: 7, offset: 1 }}
                              xxl={{ span: 7, offset: 1 }}
                            >
                              <Switch
                                key={record.id + '1'}
                                disabled={!record.id}
                                checked={record.isActive}
                                checkedChildren="Activo"
                                unCheckedChildren="Inactivo"
                                className="activate-user-switch"
                                onChange={(): void =>
                                  this.handeChangeEditingValue(
                                    { id: record.id },
                                    record.isActive,
                                  )
                                }
                              />
                            </Col>
                          )}
                        </Col>
                      </Row>
                    );
                  }}
                ></Table.Column>
                )
              </Table>
            </Col>
            <Pagination
              showSizeChanger
              pageSizeOptions={['25', '50', '75', '100']}
              defaultPageSize={25}
              disabled={this.props.isFetching}
              current={(this.props.usersList.number || 0) + 1}
              total={this.props.usersList.totalElements}
              onChange={this.handlePaginationChange}
              onShowSizeChange={this.handleSizePaginationChange}
            />
          </Row>
        )}
      </Row>
    );
  };
}

const mapStateToProps = (states: RootState): StateProps => {
  return {
    distributionCenters:
      states.usersState.distributionCenterInterface.distributionCenters,
    usersList: states.usersState.usersList,
    marketstall: states.usersState.marketstallInterface.marketstall,
    isFetching: states.usersState.isFetching,
  };
};

const mapDispatchToProps: DispatchProps = {
  page,
  desactivateUserById,
  activateUserById,
  trysendpassword,
  getDistributionCenterAction,
  getMarketstallAction,
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Users);
