/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Hiram Padilla <hiram@ssf.com.mx>, August 2020
 *
 * This component shows a form to create or edit a container
 */
import React, { Component } from 'react';
import { FormComponentProps } from 'antd/lib/form';
import { Modal, Row, Col, Input } from 'antd';
import Form, { WrappedFormUtils } from 'antd/lib/form/Form';
import { onlyNumberDecimalPattern } from 'commons/regularExpressions';
import { Equipment, Container } from 'types/type';

interface CounterProps {
  isSubcontainerModalVisible: boolean;
  onCancel: Function;
  onCreate: Function;
  form: WrappedFormUtils;
  equipments: Equipment[];
  currentContainerId: string;
  currentSubcontainer: Container | undefined;
}

class SubcontainerFormComponent extends Component<
  CounterProps & FormComponentProps
> {
  public render = (): JSX.Element => {
    const {
      isSubcontainerModalVisible,
      onCancel,
      onCreate,
      form,
      currentContainerId,
      currentSubcontainer,
    } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Modal
        width={430}
        visible={isSubcontainerModalVisible}
        title={'Sub contenedor'}
        okText="Guardar"
        cancelText="Cancelar"
        onCancel={onCancel()}
        onOk={onCreate()}
        centered
      >
        <Row>
          {'Llena los campos a continuación para guardar el subcontenedor'}
        </Row>
        <Row>
          <Col span={0}>
            <Form.Item label="Id">
              {getFieldDecorator('id', {
                initialValue: currentSubcontainer ? currentSubcontainer.id : '',
                rules: [],
              })(<Input />)}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Nombre" style={{ marginBottom: '0px' }}>
              {getFieldDecorator('name', {
                initialValue: currentSubcontainer
                  ? currentSubcontainer.name
                  : '',
                rules: [
                  {
                    required: true,
                    message: 'Introduce un nombre',
                  },
                ],
              })(<Input placeholder="Nombre" />)}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Capacidad" style={{ marginBottom: '0px' }}>
              {getFieldDecorator('capacity', {
                initialValue: currentSubcontainer
                  ? currentSubcontainer.capacity.toString()
                  : '',
                rules: [
                  {
                    pattern: onlyNumberDecimalPattern,
                    required: true,
                    message: 'Introduce una capacidad válida',
                  },
                  {
                    min: 1,
                  },
                  {
                    max: 10,
                    message: 'Máximo 10 dígitos',
                  },
                ],
              })(
                <Input
                  type="number"
                  style={{ width: '100%' }}
                  placeholder="Litros"
                />,
              )}
            </Form.Item>
          </Col>
          <Col span={0}>
            <Form.Item label="parentId">
              {getFieldDecorator('parentId', {
                initialValue: currentContainerId,
                rules: [],
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    );
  };
}

export default SubcontainerFormComponent;
