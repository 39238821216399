/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by David Canul <david@ssf.com.mx>, Sep 2019
 *
 *
 */
import { combineReducers, Reducer } from 'redux';
import {
  Settlement,
  DistributionCenter,
  SettlementsTotals,
  Equipment,
  SalesAuditArray,
  SettlementFilters,
  Price,
  CustomerAndUDR,
} from 'types/type';
import { RootState } from 'store/configureStore';
import {
  SettlementAction,
  BEGIN_FETCH,
  FETCH_COMPLETED,
  SET_SETTLEMENT,
  SET_SETTLEMENT_TOTALS,
  SET_SETTLEMENT_LISTS,
  SETTLEMENTS_SET_DISTRIBUTION_CENTER,
  SET_USERS_NAME,
  SET_SETTLEMENT_EQUIPMENT_LIST,
  SET_CUSTOMER_LIST,
  RESET_SETTLEMENT_EQUIPMENT_LIST,
  SET_SALE_AUDIT,
  RESET_SALE_AUDIT,
  SET_SETTLEMENT_FILTERS,
  SET_PRICES,
} from './types';
import {
  initSettlement,
  initSettlmentsTotals,
  initEquipment,
  initSaleAudit,
  initSettlementFilters,
  initPrice,
  initCustomerAndUDR,
} from 'commons/initTypes';

export interface DistributionCenterI {
  distributionCenter: DistributionCenter[];
}

export interface SettlementList {
  content?: Settlement[];
  number?: number;
  totalElements?: number;
}

export interface CustomerList {
  customersList: CustomerAndUDR[];
}

export interface Total {
  total: number;
}

export interface SettlementState {
  readonly settlementList: SettlementList;
  readonly distributionCenterList: DistributionCenterI;
  readonly usersList: string[];
  readonly salesAuditList: SalesAuditArray[];
  readonly equipmentList: Equipment[];
  readonly settlementTotal: SettlementsTotals;
  readonly isFetching: boolean;
  readonly settlementFiltes: SettlementFilters;
  readonly total: Total;
  readonly settlementToApply: Settlement;
  readonly customersList: CustomerList;
  readonly prices: Price[];
}

const distributionCenterList: Reducer<DistributionCenterI> = (
  state: DistributionCenterI = { distributionCenter: [] },
  action,
): DistributionCenterI => {
  if (action.type === SETTLEMENTS_SET_DISTRIBUTION_CENTER) {
    return { ...state, distributionCenter: [...action.distributionCenter] };
  }
  return state;
};

const isFetching: Reducer<boolean> = (
  state = false,
  action: SettlementAction,
): boolean => {
  switch (action.type) {
    case BEGIN_FETCH:
      return true;
    case FETCH_COMPLETED:
      return false;
    default:
      return state;
  }
};

const usersList: Reducer<string[]> = (
  state: string[] = [''],
  action,
): string[] => {
  if (action.type === SET_USERS_NAME) {
    return { ...state, ...action.namesUsers };
  }
  return state;
};

const salesAuditList: Reducer<SalesAuditArray[]> = (
  state: SalesAuditArray[] = initSaleAudit,
  action,
): SalesAuditArray[] => {
  switch (action.type) {
    case SET_SALE_AUDIT:
      return { ...state, ...action.salesAuditArray };
    case RESET_SALE_AUDIT:
      return initSaleAudit;
    default:
      return state;
  }
};

const settlementToApply: Reducer<Settlement> = (
  state: Settlement = initSettlement,
  action,
): Settlement => {
  if (action.type === SET_SETTLEMENT) {
    return { ...state, ...action.settlement };
  }
  return state;
};

const equipmentList: Reducer<Equipment[]> = (
  state: Equipment[] = [initEquipment],
  action,
): Equipment[] => {
  switch (action.type) {
    case SET_SETTLEMENT_EQUIPMENT_LIST:
      return { ...state, ...action.equipmentList };
    case RESET_SETTLEMENT_EQUIPMENT_LIST:
      return [initEquipment];
    default:
      return state;
  }
};

const settlementTotal: Reducer<SettlementsTotals> = (
  state: SettlementsTotals = initSettlmentsTotals,
  action,
): SettlementsTotals => {
  if (action.type === SET_SETTLEMENT_TOTALS) {
    return { ...state, ...action.settlementTotals };
  }
  return state;
};

const settlementList: Reducer<SettlementList> = (
  state: SettlementList = {},
  action,
): SettlementList => {
  if (action.type === SET_SETTLEMENT_LISTS) {
    return { ...state, ...action.settlementLists };
  }
  return state;
};

export const getIsFetching = (store: RootState): boolean =>
  store.settlementState.isFetching;

export const customersList: Reducer<CustomerList> = (
  state: CustomerList = { customersList: [initCustomerAndUDR] },
  action,
): CustomerList => {
  if (action.type === SET_CUSTOMER_LIST) {
    return { ...state, customersList: action.customersList };
  }
  return state;
};
export const settlementFiltes: Reducer<SettlementFilters> = (
  state: SettlementFilters = { ...initSettlementFilters },
  action,
): SettlementFilters => {
  if (action.type === SET_SETTLEMENT_FILTERS) {
    return { ...state, ...action.settlementFilters };
  }
  return state;
};

export const prices: Reducer<Price[]> = (
  state: Price[] = [initPrice],
  action,
): Price[] => {
  if (action.type === SET_PRICES) {
    return { ...state, ...action.prices };
  }
  return state;
};

export default combineReducers<SettlementState>({
  settlementFiltes,
  settlementList: settlementList,
  distributionCenterList: distributionCenterList,
  usersList,
  salesAuditList,
  equipmentList,
  settlementTotal: settlementTotal,
  settlementToApply,
  isFetching,
  customersList,
  prices,
});
