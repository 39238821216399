import Service from 'services/Service';
import { Price } from 'types/type';
import axios, { AxiosResponse } from 'axios';
import { createCoreServiceUrl } from 'commons/services';

class PricesService extends Service {
  public getProductType1Prices = (): Promise<Price[]> =>
    axios
      .get(createCoreServiceUrl('prices/productType/1'))
      .then((response: AxiosResponse<Price[]>): Price[] => response.data);

  public getAll = (): Promise<Price[]> => {
    return axios({
      method: 'get',
      url: createCoreServiceUrl(`prices`),
    }).then((response: AxiosResponse<Price[]>): Price[] => response.data);
  };
}

export default PricesService;
