import React, { Component } from 'react';
import { BaseWidgetProps, BaseWidgetState } from './types';

abstract class BaseWidget extends Component<BaseWidgetProps, BaseWidgetState> {
  public constructor(props: BaseWidgetProps) {
    super(props);
    this.state = {
      loading: false,
      settlements: [],
      salePositions: [],
      accountsReceivables: [],
      pendingDeposits: [],
      selectedDistributionC: '',
      selectedPaymentMethod: '',
      selectedEquipment: [],
      equipmentByDC: [],
      equipmentByID: [],
    };
  }

  public abstract widgetRender(): React.ReactNode;

  public render = (): React.ReactNode => {
    if (this.state.loading) {
      return <div>Put here something loading</div>;
    } else return this.widgetRender();
  };
}

export default BaseWidget;
