/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Ricardo Sansores <ricardo@ssf.mx>, May 2019
 *
 *
 */

import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { MainLayout } from 'commons/components/layout';
import PrivateRoute from 'security/PrivateRoute';
import menu from './menuRoutes';
import Login from './login';
import forgotPassword from './login/forgotPassword';
import Activatepassword from './activatepassword';
import InvoiceFromIUSComponent from 'routes/invoices';
import InvoiceComplete from 'routes/invoices/invoiceComplete';
import CustomerInvoice from 'routes/invoices/customerInvoice';
import NewCustomer from 'routes/customers/components/newCustomer';
import LogOut from './login/logout';
import statement from './statement';
import editCustomerAddress from './customers/components/editCustomerAddress';
import CollectionCreateForm from './users/components/CollectionCreateForm';
import PosSettlement from './settlements/components/posSettlement';
import { isAllowed } from 'security';
import { MenuItem } from 'types/type';
import Equipments from 'routes/equipments';
import Portfolios from './portfolios';
import PortfolioFormComponent from './portfolios/components/formComponent';
import CreateRoute from './route/createRoute';

const Routes = (): JSX.Element => (
  <Router>
    <MainLayout menu={menu}>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/logout" component={LogOut} />
        <Route path="/activatepassword/:token" component={Activatepassword} />
        <Route path="/forgotPassword" component={forgotPassword} />
        <Route path="/factura" component={InvoiceFromIUSComponent} />
        <Route path="/factura-finish" component={InvoiceComplete} />
        <Route path="/clientes" component={CustomerInvoice} />
        <Route path="/customer/newCustomer/:id" component={NewCustomer} />
        <Route
          path="/settlement/posSettlement/:id/:distributionCenterName"
          component={PosSettlement}
        />
        <Route path="/statements/:id" component={statement} />
        <Route
          path="/customerAddress/addContact/:id"
          component={editCustomerAddress}
        />
        <Route
          path="/users/components/CollectionCreateForm/:id"
          component={CollectionCreateForm}
        />
        <Route path="/equipments" component={Equipments} />
        <Route path="/portfolios" component={Portfolios} />
        <Route
          path="/generatePortfolio/:id"
          component={PortfolioFormComponent}
        />
        <Route path="/CreateRoute" component={CreateRoute} />
        {menu
          .filter((m: MenuItem): boolean => {
            if (!m.permissions) return true;
            return isAllowed(m.permissions);
          })
          .map(
            (item): React.ReactNode => {
              const { secure, key, component, ...rest } = item;
              if (secure === true) {
                return (
                  <PrivateRoute
                    exact
                    {...rest}
                    component={component}
                    key={key}
                  />
                );
              }
              return <Route exact {...rest} key={key} />;
            },
          )}
      </Switch>
    </MainLayout>
  </Router>
);
export default Routes;
