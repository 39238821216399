/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Hiram Padilla <hiram@ssf.com.mx>, August 2020
 *
 * This component shows a form to create or edit a container
 */
import React, { Component } from 'react';
import { FormComponentProps } from 'antd/lib/form';
import { Modal, Row, Col, Input, Select, InputNumber, Switch } from 'antd';
import Form, { WrappedFormUtils } from 'antd/lib/form/Form';
import { onlyNumberDecimalPattern } from 'commons/regularExpressions';
import { Equipment, Container, SaleEquipment } from 'types/type';

interface CounterProps {
  isVisible: boolean;
  onCancel: Function;
  onCreate: Function;
  onSearch: Function;
  form: WrappedFormUtils;
  isFetching: boolean;
  setFieldsValue: Function;
  equipments: Equipment[];
  container: Container | undefined;
  setEquipments: Function;
}

class ContainerFormComponent extends Component<
  CounterProps & FormComponentProps
> {
  public render = (): JSX.Element => {
    const {
      isVisible,
      onCancel,
      onCreate,
      form,
      equipments,
      container,
    } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Modal
        width={430}
        visible={isVisible}
        title={
          //Set a different title when is creating or editing the container
          container === undefined ? 'Nuevo contenedor' : 'Editar contenedor'
        }
        okText="Guardar"
        cancelText="Cancelar"
        onCancel={onCancel()}
        onOk={onCreate()}
        centered
      >
        <Row>
          {
            //Set a different message when is creating or editing the container
            'Llena los campos a continuación para '
          }
          {container === undefined
            ? 'registrar un nuevo contenedor'
            : 'editar el contenedor seleccionado'}
        </Row>
        <Row>
          <Col span={0}>
            <Form.Item label="Id">
              {getFieldDecorator('id', {
                initialValue: container ? container.id : '',
                rules: [],
              })(<Input />)}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Nombre" style={{ marginBottom: '0px' }}>
              {getFieldDecorator('name', {
                initialValue: container ? container.name : '',
                rules: [
                  {
                    required: true,
                    message: 'Introduce un nombre',
                  },
                  {
                    max: 50,
                    message: 'Máximo 50 caracteres',
                  },
                ],
              })(<Input placeholder="Nombre" />)}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Tipo de contenedor"
              style={{ marginBottom: '0px' }}
            >
              {getFieldDecorator('type', {
                initialValue: container ? container.type : '',
                rules: [
                  {
                    required: true,
                    message: 'Selecciona un tipo de contenedor',
                  },
                ],
              })(
                <Select style={{ width: '100%' }}>
                  <Select.Option value="">Seleccionar</Select.Option>
                  <Select.Option value="Autotanque">Autotanque</Select.Option>
                  <Select.Option value="Carburacion">Carburación</Select.Option>
                  <Select.Option value="Cilindro">Cilindro</Select.Option>
                  <Select.Option value="Almacen">Almacén</Select.Option>
                </Select>,
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Capacidad (Litros)"
              style={{ marginBottom: '0px' }}
            >
              {getFieldDecorator('capacity', {
                initialValue: container ? container.capacity.toString() : '',
                rules: [
                  {
                    pattern: onlyNumberDecimalPattern,
                    required: true,
                    message: 'Introduce una capacidad válida',
                  },
                  {
                    min: 1,
                  },
                  {
                    max: 10,
                    message: 'Máximo 10 dígitos',
                  },
                ],
              })(
                <Input
                  type="number"
                  style={{ width: '100%' }}
                  placeholder="Capacidad"
                />,
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Equipos Gaspar" style={{ marginBottom: '0px' }}>
              {getFieldDecorator('saleEquipments', {
                initialValue:
                  container && container.equipments ? container.equipments : [],
                rules: [
                  {
                    required: true,
                    message: 'Selecciona almenos un equipo',
                  },
                ],
              })(
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100% ' }}
                  placeholder="Seleccionar uno o más"
                  onChange={(value: string[]): void => {
                    let saleEquipments: SaleEquipment[] = [];
                    value.forEach((id: string): void => {
                      const foundEquipment:
                        | Equipment
                        | undefined = equipments.find(
                        (equipment: Equipment): boolean =>
                          equipment.idG4s === id,
                      );
                      const temporalEquipment: SaleEquipment = {
                        id,
                        tag: foundEquipment ? foundEquipment.tag : '',
                      };
                      saleEquipments.push(temporalEquipment);
                    });
                    this.props.setEquipments(saleEquipments);
                  }}
                >
                  {equipments.map(
                    (equipment: Equipment, index: number): React.ReactNode => (
                      <Select.Option value={equipment.idG4s} key={index}>
                        {equipment.tag}
                      </Select.Option>
                    ),
                  )}
                </Select>,
              )}
            </Form.Item>
          </Col>
          <Col span={0}>
            <Form.Item label="parent">
              {getFieldDecorator('parent', {
                initialValue: true,
                rules: [],
              })(<Switch checked={true} />)}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Código de cliente / UDR"
              style={{ marginBottom: '0px' }}
            >
              {getFieldDecorator('customerCode', {
                initialValue: container ? container.customerCode : '',
                rules: [
                  {
                    required: true,
                    message: 'Introduce un código',
                  },
                  {
                    max: 10,
                    message: 'Máximo 10 caracteres',
                  },
                ],
              })(<Input placeholder="Código" />)}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Definir % / Volumen de tolerancia"
              style={{ marginBottom: '0px' }}
            >
              {getFieldDecorator('tolerance', {
                initialValue: container ? container.tolerance : '',
                rules: [
                  {
                    pattern: onlyNumberDecimalPattern,
                    required: true,
                    message: 'Introduce un valor para la tolerancia',
                  },
                ],
              })(
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  max={100}
                  placeholder="Tolerancia"
                />,
              )}
            </Form.Item>
          </Col>
          <Col span={0}>
            <Form.Item label="Nivel actual">
              {getFieldDecorator('currentTheoreticalInventoryLevel', {
                initialValue: container
                  ? container.currentTheoreticalInventoryLevel
                  : '',
                rules: [],
              })(<Input />)}
            </Form.Item>
          </Col>
          <Col span={0}>
            <Form.Item label="Volumen actual">
              {getFieldDecorator('currentTheoreticalVolume', {
                initialValue: container
                  ? container.currentTheoreticalVolume
                  : '',
                rules: [],
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    );
  };
}

export default ContainerFormComponent;
