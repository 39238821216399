import {
  emailPattern,
  onlyNumberPattern,
  passwordvalidation,
} from 'commons/regularExpressions';
import { ValidationRule } from 'antd/lib/form';
import { MessagesUser } from 'commons/strings';
import axios, { AxiosResponse } from 'axios';
import { createCoreServiceUrl } from 'commons/services';
import { User } from 'types/type';

export declare type ValidatorFunction = (
  rule: object,
  value: string,
) => Function;

export class UserRules {
  public static userNameRule: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      message: MessagesUser.USER_NAME_RULE,
    },
  ];
  public static userFirstNameRule: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      message: MessagesUser.USER_FIRST_NAME_RULE,
    },
  ];
  public static userLastNameRule: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      message: MessagesUser.USER_LAST_NAME_RULE,
    },
  ];
  public static userPhoneRule: ValidationRule[] = [
    {
      required: true,
      message: MessagesUser.USER_PHONE_RULE,
    },
    {
      pattern: onlyNumberPattern,
      message: MessagesUser.USER_ONLY_NUMBER_RULE,
    },
    {
      max: 10,
      min: 10,
      message: MessagesUser.USER_PHONE_MIN_RULE,
    },
  ];

  public static getUserEmailRules(user: User): ValidationRule[] {
    return [
      {
        pattern: emailPattern,
        required: true,
        message: MessagesUser.USER_EMAIL_RULE,
      },
      {
        validator: async (
          _rule: ValidationRule,
          value: string,
          callback: Function,
        ): Promise<User> => {
          const response: AxiosResponse<User[]> = await axios.get(
            createCoreServiceUrl(`users`),
            {
              params: { email: value },
            },
          );
          if (response.data.length > 0 && response.data[0].id !== user.id) {
            return callback(
              'Este correo ya está registrado, favor de utilizar otro',
            );
          } else {
            return callback();
          }
        },
      },
    ];
  }

  public static userDistributionCenterRule: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      message: MessagesUser.USER_DISTRIBUTIONCENTER_RULE,
    },
  ];
  public static userMarketstallRule: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      message: MessagesUser.USER_MARKETSTALL_RULE,
    },
  ];
  public static UserPasswordRule = (
    method: ValidatorFunction,
  ): ValidationRule[] => [
    {
      required: true,
      message: 'Campo obligatorio',
    },
    {
      validator: method,
    },
    {
      pattern: passwordvalidation,
      message: 'Mínimo 8 caracteres una letra mayuscula y un numero',
    },
  ];
}
