import React, { Component } from 'react';
import { Modal, Col, Row, Divider } from 'antd';
import Text from 'antd/lib/typography/Text';
import { Sale } from 'types/type';
import { getIncognitMode, isAllowed } from 'security/index';
import { toNumber } from 'commons/numbersFormat';
import { Permissions as P } from 'commons/permissions';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker';
import moment from 'moment';
interface CounterProps {
  visible: boolean;
  onCreate: Function;
  sale: Sale;
  onCancel: Function;
}

class SaleDetailModal extends Component<CounterProps> {
  public state = {
    map: {},
  };

  public render = (): JSX.Element => {
    const { sale } = this.props;
    return (
      <Modal
        width={896}
        visible={this.props.visible}
        title={'Detalle ' + sale.salesFolio}
        okText={'Regresar'}
        cancelText=""
        onCancel={this.props.onCancel()}
        cancelButtonProps={{ disabled: true, hidden: true }}
        onOk={this.props.onCreate()}
      >
        <Row>
          <Col span={12}>
            <Row>
              <Text>Cliente</Text>
            </Row>
            <Row>
              <Text>
                <h2>{sale.customerName}</h2>
              </Text>
            </Row>
            <Divider type={'horizontal'} />
            <Col span={12}>
              <Row>
                <Row>
                  <Text strong style={{ color: '#113C71' }}>
                    EQ
                  </Text>
                </Row>
                <Row>
                  <Text>
                    {(sale.equipmentNumber ? sale.equipmentNumber : '') +
                      ' ' +
                      (sale.tag ? sale.tag : '')}
                  </Text>
                </Row>
                <Row>&nbsp;</Row>
              </Row>
              <Row>
                <Row>
                  <Text strong style={{ color: '#113C71' }}>
                    Fecha de venta
                  </Text>
                </Row>
                <Row>
                  <Text>{moment(sale.endDateSave).format('YYYY-MM-DD')}</Text>
                </Row>
                <Row>&nbsp;</Row>
              </Row>
              <Row>
                <Row>
                  <Text strong style={{ color: '#113C71' }}>
                    Precio unitario
                  </Text>
                </Row>
                <Row>
                  <Text>
                    {getIncognitMode() === '1'
                      ? '$' +
                        toNumber((sale.total || 0) / (sale.totalVolume || 0)) +
                        ((sale.totalAmountC || 0) / (sale.totalVolumeC || 0) !==
                        0
                          ? ' - ' +
                            toNumber(
                              (sale.totalAmountC || 0) /
                                (sale.totalVolumeC || 0),
                            )
                          : '')
                      : (sale.totalAmountC || 0) !== 0
                      ? '$' +
                        toNumber(
                          (sale.totalAmountC || 0) / (sale.totalVolumeC || 0),
                        )
                      : '$' +
                        toNumber((sale.total || 0) / (sale.totalVolume || 0))}
                  </Text>
                </Row>
                <Row>&nbsp;</Row>
                <Row>
                  <Row>
                    <Text strong style={{ color: '#113C71' }}>
                      Latitud
                    </Text>
                  </Row>
                  <Row>
                    <Text>{sale.latitude}</Text>
                  </Row>
                </Row>
                <Row>&nbsp;</Row>
              </Row>
              <Row>
                <Row>
                  <Text strong style={{ color: '#113C71' }}>
                    Cantidad
                  </Text>
                </Row>
                <Row>
                  <Text>
                    <h2>
                      {getIncognitMode() === '1'
                        ? toNumber(sale.totalVolume || 0) +
                          ((sale.totalVolumeC || 0) !== 0
                            ? ' - ' + toNumber(sale.totalVolumeC || 0) + ' L'
                            : '0 L')
                        : (sale.totalVolumeC || 0) !== 0
                        ? toNumber(sale.totalVolumeC || 0) + ' L'
                        : toNumber(sale.totalVolume || 0) + ' L'}
                    </h2>
                  </Text>
                </Row>
                <Row>&nbsp;</Row>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Row>
                  <Text strong style={{ color: '#113C71' }}>
                    Folio
                  </Text>
                </Row>
                <Row>
                  <Text>
                    {isAllowed([P.CAN_SEE_FOLIOS])
                      ? sale.salesFolio
                      : sale.IUSCode}
                  </Text>
                </Row>
                <Row>&nbsp;</Row>
              </Row>
              <Row>
                <Row>
                  <Text strong style={{ color: '#113C71' }}>
                    Fecha de impresión
                  </Text>
                </Row>
                <Row>
                  <Text>{moment(sale.endDateSave).format('YYYY-MM-DD')}</Text>
                </Row>
                <Row>&nbsp;</Row>
              </Row>
              <Row>
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
              </Row>
              <Row>
                <Row>
                  <Text strong style={{ color: '#113C71' }}>
                    Longitud
                  </Text>
                </Row>
                <Row>
                  <Text>{sale.longitude}</Text>
                </Row>
              </Row>
              <Row>
                <Row>&nbsp;</Row>
                <Row>
                  <Text strong style={{ color: '#113C71' }}>
                    Total
                  </Text>
                </Row>
                <Row>
                  <Text>
                    <h2>
                      {' '}
                      {getIncognitMode() === '1'
                        ? '$' +
                          toNumber(sale.total || 0) +
                          ((sale.totalAmountC || 0) !== 0
                            ? ' - ' + toNumber(sale.totalAmountC || 0)
                            : '')
                        : (sale.totalAmountC || 0) !== 0
                        ? '$' + toNumber(sale.totalAmountC || 0)
                        : '$' + toNumber(sale.total || 0)}
                    </h2>
                  </Text>
                </Row>
                <Row>&nbsp;</Row>
              </Row>
            </Col>
          </Col>
          <Col span={12}>
            <div style={{ height: '27rem', width: '100%' }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: 'AIzaSyCsBQRj84EsjEJDwCWiIf0ZoRQCdqFqwh8',
                }}
                defaultCenter={{
                  lat: this.props.sale.latitude ? this.props.sale.latitude : 0,
                  lng: this.props.sale.longitude
                    ? this.props.sale.longitude
                    : 0,
                }}
                defaultZoom={15}
              >
                <Marker
                  lat={this.props.sale.latitude ? this.props.sale.latitude : 0}
                  lng={
                    this.props.sale.longitude ? this.props.sale.longitude : 0
                  }
                  name={this.props.sale.customerName}
                  color="#113c70"
                />
              </GoogleMapReact>
            </div>
          </Col>
          <Col span={12}>
            {
              //this.state.map
            }
          </Col>
        </Row>
      </Modal>
    );
  };
}

export default SaleDetailModal;
