import { PaymentCondition } from 'types/type';

const paymentConditions: PaymentCondition[] = [
  { type: 'CASH', name: 'Contado' },
  { type: 'CREDIT', name: 'Crédito' },
  { type: 'TRASLATE', name: 'Traslado' },
  { type: 'SELF_CONSUMPTION', name: 'Consumo propio' },
  { type: 'RETURN', name: 'Retorno' },
  { type: 'PREPAID', name: 'Prepago' },
  { type: 'FAILED', name: 'Fallido' },
];

export const getPaymentConditions = (
  customerPaymentCondition: string,
  salePC: string,
  canAssignTraslateOrSelfConsumption: boolean,
): PaymentCondition[] => {
  //Get all payment conditions when user is CREDIT
  const customerPaymentConditions =
    customerPaymentCondition === 'CREDIT'
      ? paymentConditions
      : //Else, get all different from CREDIT when user is NOT credit
        paymentConditions.filter((pc): boolean => pc.type !== 'CREDIT');

  //Get the payment condition from the sale
  const currentPaymentCond: PaymentCondition[] = paymentConditions.filter(
    (n): boolean => salePC === n.type,
  );

  //Get all payment conditions when user has permission to assign traslate and self consumption
  const allowedPaymentConditions = canAssignTraslateOrSelfConsumption
    ? customerPaymentConditions
    : //Else, get all different from TRASLATE and SELF_CONSUMPTION
      customerPaymentConditions.filter(
        (n): boolean => n.type !== 'TRASLATE' && n.type !== 'SELF_CONSUMPTION'&& n.type !== 'RETURN'&& n.type !== 'PREPAID',
      );

  //Return the payment condition from sale with the filtered payment conditions
  return currentPaymentCond.concat(
    allowedPaymentConditions.filter(
      (n): boolean =>
        !currentPaymentCond.some((c): boolean => c.type === n.type),
    ),
  );
};
