import axios, { AxiosResponse } from 'axios';
import Service from '../Service';
import { createCoreServiceUrl } from '../../commons/services';
import {
  Sale,
  Pageable,
  BankDeposit,
  FilterBankDeposit,
  ModelBankTotals,
  Settlement,
  SettlementDepositModal,
  Clarification,
} from '../../types/type';

class SaleService extends Service {
  public totalsbankdeposit = (
    filterBankDeposit: FilterBankDeposit,
  ): Promise<ModelBankTotals> =>
    axios({
      method: 'post',
      url: createCoreServiceUrl(`bankdeposit/totalsbankdeposit`),
      data: filterBankDeposit,
    }).then(
      (response: AxiosResponse<ModelBankTotals>): ModelBankTotals =>
        response.data,
    );

  public pagebyfilters = (
    filterBankDeposit: FilterBankDeposit,
  ): Promise<Pageable> =>
    axios({
      method: 'post',
      url: createCoreServiceUrl(`bankdeposit/pagebyfilters`),
      data: filterBankDeposit,
    }).then((response: AxiosResponse<Pageable>): Pageable => response.data);

  public applyBankDeposit = (
    id: string,
    status: string,
    description: string,
    amountDifferences: number,
  ): Promise<number> => {
    return axios
      .put(createCoreServiceUrl(`bankdeposit/${id}/apply`), {
        status,
        description,
        amountDifferences,
      })
      .then((response: AxiosResponse<number>): number => response.data);
  };
  public setSettlementList = (
    distId: string,
  ): Promise<SettlementDepositModal> =>
    axios
      .get(
        createCoreServiceUrl(
          `bankdeposit/findallsettlementstobankdeposit/${distId}`,
        ),
      )
      .then(
        (
          response: AxiosResponse<SettlementDepositModal>,
        ): SettlementDepositModal => response.data,
      );

  public getSettlementListByBankDeposit = (id: string): Promise<Settlement[]> =>
    axios
      .get(
        createCoreServiceUrl(
          `bankdeposit/getsettlementlistbybankdeposit/${id}`,
        ),
      )
      .then(
        (response: AxiosResponse<Settlement[]>): Settlement[] => response.data,
      );

  public getSalelistbybankdeposit = (id: string): Promise<Sale[]> =>
    axios
      .get(createCoreServiceUrl(`bankdeposit/getSalelistbybankdeposit/${id}`))
      .then((response: AxiosResponse<Sale[]>): Sale[] => response.data);

  public selectsettlementbyidfilter = (
    id: string,
    status: string,
  ): Promise<BankDeposit> =>
    axios
      .get(
        createCoreServiceUrl(
          `bankdeposit/selectsettlementbyidfilter/${id}/${status}`,
        ),
      )
      .then(
        (response: AxiosResponse<BankDeposit>): BankDeposit => response.data,
      );

  public downloadFile = (
    id: string,
    name: string,
    saleFolio: string,
  ): Promise<void> =>
    axios({
      url: createCoreServiceUrl(`sale/files/${id}/${name}`),
      method: 'GET',
    }).then((response: AxiosResponse<string>): void => {
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.href = response.data;
      link.click();
      console.log(saleFolio);
    });

  public getDistrubutionCenter = (): Promise<[]> =>
    axios
      .get(createCoreServiceUrl('company/distribuciosncenters'))
      .then((response: AxiosResponse<[]>): [] => {
        return response.data;
      });

  public saveBankDeposit = (BankDeposit: FormData): Promise<BankDeposit> => {
    return axios({
      method: 'post',
      url: createCoreServiceUrl(`bankdeposit`),
      data: BankDeposit,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }).then(
      (response: AxiosResponse<BankDeposit>): BankDeposit => response.data,
    );
  };

  public getBankDepositPDF = async (
    id: string,
    folio: string,
  ): Promise<void> => {
    const response = await axios({
      url: createCoreServiceUrl(`bankdeposit/${id}/ticket`),
      method: 'GET',
      responseType: 'arraybuffer',
    });
    const archivoUrl = window.URL.createObjectURL(new Blob([response.data]));
    const archivoLink = document.createElement('a');
    archivoLink.href = archivoUrl;
    archivoLink.setAttribute('download', 'deposito' + folio + '.pdf');
    document.body.appendChild(archivoLink);
    archivoLink.click();
  };

  public getClarificationsHistory = async (
    depositId: string,
  ): Promise<Clarification[]> => {
    return axios
      .get(createCoreServiceUrl(`depositClarifications/${depositId}`))
      .then(
        (response: AxiosResponse<Clarification[]>): Clarification[] =>
          response.data,
      );
  };

  public saveClarification = async (
    clarification: FormData,
  ): Promise<Clarification> => {
    return axios({
      method: 'post',
      url: createCoreServiceUrl(`depositClarifications`),
      data: clarification,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }).then(
      (response: AxiosResponse<Clarification>): Clarification => response.data,
    );
  };
}
export default SaleService;
