import React, { Component } from 'react';
import { Row, Col, Button, Icon, Card } from 'antd';
import { Link } from 'react-router-dom';
class InvoiceComplete extends Component {
  public render = (): React.ReactNode => {
    return (
      <Row>
        <Col style={{ margin: '0 auto', textAlign: 'center' }}>
          <Card className="invoice-card">
            <p>
              <Icon
                type="check-circle"
                style={{ fontSize: '10rem', color: '#4B86C7' }}
              />
            </p>

            <p>
              <h1>Factura Generada</h1>
            </p>
            <p>
              <h3>
                En unos minutos recibiras la factura con el correo que nos
                proporcionaste
              </h3>
            </p>
            <p>
              <Link to="/clientes">
                <Button
                  type="primary"
                  className="button-invoice-serch-style"
                  block
                >
                  Ir a Inicio
                </Button>
              </Link>
            </p>
          </Card>
        </Col>
      </Row>
    );
  };
}

export default InvoiceComplete;
