/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Ricardo Sansores <ricardo@ssf.mx>, May 2019
 *
 *
 */

import React, { Component, FormEvent } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { Row, Col, Input, Form, Button, Icon, Card, notification } from 'antd';
import { connect } from 'react-redux';
import decode from 'jwt-decode';
import { FormComponentProps } from 'antd/lib/form/Form';
//FIXME Each component should use its own style. Otherwise they wont be decoupled.
import './../style.css';
import { passwordvalidation } from '../../../commons/regularExpressions';
import { changetoken } from '../redux/actions';
import { AccessToken } from '../redux/reducers';
import { RootState } from '../../../store/configureStore';
import { NewPassword } from '../../../types/type';
import { parseApiError } from 'commons/error';
import { enterpriceLogo as logo } from 'boot/config';
import moment from 'moment';

interface HomeRouterProps {
  token: string;
}
interface DispatchProps {
  changetoken: (values: NewPassword) => void;
}
interface StateProps {
  accessToken: AccessToken;
}
type Props = FormComponentProps &
  StateProps &
  DispatchProps &
  RouteComponentProps<HomeRouterProps>;

class ActivatePassword extends Component<Props> {
  public state = { confirmDirty: false };
  private handleOnSubmit = (e: FormEvent): void => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(
      async (err, values): Promise<void> => {
        if (!err) {
          try {
            await this.props.changetoken(values);
            window.location.reload();
          } catch (error) {
            notification.error(parseApiError(error));
          }
        }
      },
    );
  };
  public compareToFirstPassword = (
    rule: {},
    value: string,
    callback: Function,
  ): void => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Las contraseñas no coinciden');
    } else {
      callback();
    }
  };
  public validateToNextPassword = (
    rule: {},
    value: string,
    callback: Function,
  ): void => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['secondpassword'], { force: true });
    }
    callback();
  };

  public handleConfirmBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  //function to get the get the username and de generated date from the token
  public token = (tokenval: string): { id: string; iat: number } => {
    try {
      const value: { id: string; iat: number } = decode(tokenval);
      return value;
    } catch (error) {
      return { id: '', iat: 0 };
    }
  };

  public isLinkActive = (): boolean => {
    //Get the generated date from the token and compare it to the current date
    const fechaToken = moment.unix(
      this.token(this.props.match.params.token).iat,
    );
    const hours = moment.duration(moment().diff(fechaToken)).asHours();

    if (hours > 24) {
      return false;
    }
    return true;
  };

  public render = (): JSX.Element => {
    const { getFieldDecorator } = this.props.form;

    if (localStorage.getItem('token')) return <Redirect to="/" />;

    //If the hours difference is greater or equals to 5 hours, show a Expired link message
    if (!this.isLinkActive()) {
      return (
        <Card className="activate-card">
          <h2 className="style-text-align-center">Este link ya expiró</h2>
          <img className="header-logo" src={logo} alt="company_logo" />
        </Card>
      );
    }

    return (
      <React.Fragment>
        <Card className="activate-card">
          <img className="header-logo" src={logo} alt="company_logo" />

          <Row>
            <Col>
              <Form onSubmit={this.handleOnSubmit} className="login-form">
                <h2 className="style-text-align-center">
                  Ingrese una contraseña para acceder al sistema.
                </h2>
                <Form.Item>
                  {getFieldDecorator('password', {
                    rules: [
                      { required: true, message: 'Campo obligatorio' },
                      { validator: this.validateToNextPassword },
                      {
                        pattern: passwordvalidation,
                        message:
                          'Mínimo 8 caracteres una letra mayuscula y un numero',
                      },
                    ],
                  })(
                    <Input
                      size="large"
                      prefix={<Icon type="lock" />}
                      type="password"
                      placeholder="Contraseña"
                      onBlur={this.handleConfirmBlur}
                    />,
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('secondpassword', {
                    rules: [
                      { required: true, message: 'Campo obligatorio' },
                      { validator: this.compareToFirstPassword },
                      {
                        pattern: passwordvalidation,
                        message:
                          'Mínimo 8 caracteres una letra mayuscula y un numero',
                      },
                    ],
                  })(
                    <Input
                      size="large"
                      prefix={<Icon type="lock" />}
                      type="password"
                      placeholder="Contraseña"
                    />,
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('id', {
                    initialValue: this.token(this.props.match.params.token).id,
                  })(<Input className="style-display-none" />)}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('token', {
                    initialValue: this.props.match.params.token,
                  })(<Input className="style-display-none" />)}
                </Form.Item>
                <Button
                  className="full-width"
                  type="primary"
                  htmlType="submit"
                  id="activatePassword_button"
                >
                  Confirmar Contraseña
                </Button>
              </Form>
            </Col>
          </Row>
        </Card>
      </React.Fragment>
    );
  };
}
const mapStateToProps = (states: RootState): StateProps => {
  return {
    accessToken: states.loginState.accessToken,
  };
};

const mapDispatchToProps = {
  changetoken,
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create()(ActivatePassword));
