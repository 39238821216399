/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Hiram Padilla <hiram@ssf.com.mx>, February 2021
 *
 * This component shows a form to create a Cylinder Sale
 */

import { Col, DatePicker, Input, Modal, Row, Select } from 'antd';
import Form, { FormComponentProps, WrappedFormUtils } from 'antd/lib/form/Form';
import { onlyNumberDecimalPattern } from 'commons/regularExpressions';
import JwtDecode from 'jwt-decode';
import moment from 'moment';
import React, { Component, ReactNode } from 'react';
import { getToken, Token } from 'security';
import {
  CylinderEquipment,
  CylinderSettlement,
  DistributionCenter,
  Price,
} from 'types/type';
import { DbOldDetails, DbOldTypes, DbOldValues } from 'database';
import { Globals } from 'commons/globals';
import { ApiRoute, ApiTypes, callApi } from 'commons/services/api';
import { Popups } from 'commons/components/popups/popups';

interface CounterProps {
  isVisible: boolean;
  onCancel: Function;
  onCreate: Function;
  isFetching: boolean;
  form: WrappedFormUtils;
  distributionCenters: DistributionCenter[];
  equipments: CylinderEquipment[];
  cers: DbOldTypes.CylinderEquipmentRoute[];
  prices: Price[];
  currentSettlement: CylinderSettlement | undefined;
}

const cylinderCapacities = [
  { capacity: 10, label: '10 kgs', productType: 4 },
  { capacity: 20, label: '20 kgs', productType: 5 },
  { capacity: 30, label: '30 kgs', productType: 6 },
  { capacity: 45, label: '45 kgs', productType: 7 },
];

class CylindersFormComponent extends Component<
  CounterProps & FormComponentProps
> {

  state = {
    cerId: '',
    date: moment(new Date()),
    customerCode: '',
    discount: 0,
    paymentCondition: DbOldValues.SalePaymentCondition.CASH,
    paymentWay: DbOldValues.SalePaymentWay.EFECTIVO,
  };
  private changeState(newPartialState: Partial<CylindersFormComponent['state']>){
    this.setState({ ...newPartialState });
  }
  
  private paymentConditionOptions = Globals.Utils.Object.entries(DbOldDetails.salePaymentCondition).map(spc => ({
    value: spc.key,
    name: spc.value.name,
  }));
  private paymentWayOptions = Globals.Utils.Object.entries(DbOldDetails.salePaymentWay).map(spw => ({
    value: spw.key,
    name: spw.value.name,
  }));

  //Get distribution centers from token, to set them into the dropdown when user has no permission
  private tokenDistrCenter = JwtDecode<Token>(getToken() as string)
    .distributionCenterId;
  private tokenAssignedDistrCenters: string[] = JwtDecode<Token>(
    getToken() as string,
  ).assingedDistrCenters;

  private async onSubmit(): Promise<boolean> {
    type CylinderData = { cylinderCapacity: number, numberOfCylinders: string, unitPrice: number };
    let { cerId, date, customerCode, discount, paymentCondition, paymentWay } = this.state;
    let cylinderDatas: CylinderData[] = this.props.form.getFieldValue('cylinderSales');
    console.log('Submitted: ', JSON.stringify([ this.state, cylinderDatas ], null, '\t'));
    let cerSale: ApiTypes.CylinderService.CerSale = {
      cerId,
      date: date.format('YYYY-MM-DD'),
      customerCode,
      discount,
      paymentCondition,
      paymentWay,
      units: cylinderDatas.filter(data => +data.numberOfCylinders).map(data => ({
        kg: data.cylinderCapacity,
        price: data.unitPrice,
        quantity: +data.numberOfCylinders,
      })),
    };
    console.log(cerSale);
    if(!cerSale.cerId){
      Popups.notifyError(`Seleccione una ruta.`);
      return false;
    }
    if(!cerSale.customerCode){
      Popups.notifyError(`Ingrese un código de cliente.`);
      return false;
    }
    if(isNaN(cerSale.discount)){
      Popups.notifyError(`Ingrese un descuento válido.`);
      return false;
    }
    if(!cerSale.units.length){
      Popups.notifyError(`Seleccione al menos una unidad.`);
      return false;
    }
    let result = await callApi(ApiRoute.AddVirtualCylinderSale, { cerSale });
    console.log(result);
    if(result && result.success){
      return true;
    }else{
      Popups.notifyError((result && result.msg) || `Error añadiendo venta.`);
      return false;
    }
  }

  public render = (): ReactNode => {
    const { isVisible, onCancel, onCreate, isFetching, form } = this.props;
    const { prices, currentSettlement } = this.props;

    //Get assigned distribution centers
  
    return (
      <Modal
        width={ 932 /* 450 */ }
        visible={isVisible}
        title={ `Registrar venta` /* currentSettlement === undefined ? 'Registrar corte' : 'Registrar ventas' */ }
        okText="Guardar"
        cancelText="Cancelar"
        onOk={ async () => {
          let submitted = await this.onSubmit();
          if(!submitted) return;
          await onCreate();
        } }
        onCancel={onCancel()}
        centered
      >
        <Row>
          { `Al final del día se cerrarán los cortes automáticamente` }
          {/* currentSettlement === undefined ? 'Llena los campos a continuación para registrar un corte' : 'Llena los campos necesarios para registrar ventas' */}
        </Row>
        <Row>
          <Row>
            {/* <Col span={0}>
              <Form.Item>
                {form.getFieldDecorator(`cylinderSettlement.id`, {
                  initialValue:
                    currentSettlement === undefined ? '' : currentSettlement.id,
                })(<Input />)}
              </Form.Item>
            </Col> */}
            <Col span={ 12 /* 24 */ }>
              <Form.Item label="Ruta">
                {form.getFieldDecorator('cylinderSettlement.routeEquipmentId', {
                  initialValue: '' /* currentSettlement === undefined ? '' : currentSettlement.routeEquipmentId */,
                  rules: [
                    {
                      required: true,
                      message: 'Seleccione una ruta',
                    },
                  ],
                })(
                  <Select
                    style={{ width: '100%' }}
                    disabled={isFetching || currentSettlement !== undefined}
                    value={ this.state.cerId }
                    onChange={(value: string): void => {
                      this.changeState({ cerId: value });
                      /* let tag = '';
                      //Find the route and set the tag to field
                      equipments.forEach((route: CylinderEquipment): void => {
                        if (route.id === value) tag = route.tag;
                      });
                      this.props.form.setFieldsValue({
                        'cylinderSettlement.routeTag': tag,
                      }); */
                    }}
                  >
                    <Select.Option value="">Seleccionar</Select.Option>
                    {//When user has permission, show all options
                    /* isAllowed([P.CAN_VIEW_ALL_DISTRIBUTION_CENTERS]) &&  */this.props.cers.map(cer => (
                      <Select.Option key={ cer.id } value={ cer.id } > { cer.tag } </Select.Option>
                    ))
                      /* equipments.map(
                        (equipment: CylinderEquipment): JSX.Element => (
                          <Select.Option
                            value={equipment.id}
                            key={equipment.id}
                          >
                            {equipment.tag}
                          </Select.Option>
                        ),
                      ) */}
                    {//When user HAS NO permission, show the options of the assigned distr centers
                    /* !isAllowed([P.CAN_VIEW_ALL_DISTRIBUTION_CENTERS]) &&
                      this.props.distributionCenters && this.props.cers.filter(cer => {
                        return assignedDistrCenters.includes(cer.id);
                      }).map(cer => (
                        <Select.Option key={ cer.id } value={ cer.id } > { cer.tag } </Select.Option>
                      )) */
                      /* assignedDistrCenters.map(
                        (id: string, index: number): JSX.Element => {
                          const equipment = equipments.find(
                            (equip: CylinderEquipment): boolean =>
                              equip.distributionCenterId === id,
                          );
                          return equipment ? (
                            <Select.Option value={equipment.id} key={index}>
                              {equipment.tag}
                            </Select.Option>
                          ) : (
                            <div key={index} />
                          );
                        },
                      ) */}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col span={0}>
              <Form.Item>
                {form.getFieldDecorator(`cylinderSettlement.routeTag`, {
                  initialValue: '',
                })(<Input />)}
              </Form.Item>
            </Col>
            {/* <Col span={24}>
              <Form.Item label="Centro de distribución">
                {form.getFieldDecorator(
                  'cylinderSettlement.distributionCenterId',
                  {
                    initialValue:
                      currentSettlement === undefined
                        ? ''
                        : currentSettlement.distributionCenterId,
                    rules: [
                      {
                        required: true,
                        message: 'Seleccione un centro de distribución',
                      },
                    ],
                  },
                )(
                  <Select
                    style={{ width: '100%' }}
                    disabled={isFetching || currentSettlement !== undefined}
                  >
                    <Select.Option value="">Seleccionar</Select.Option>
                    {//When user has permission, show all options
                    isAllowed([P.CAN_VIEW_ALL_DISTRIBUTION_CENTERS]) &&
                      this.props.distributionCenters &&
                      this.props.distributionCenters.map(
                        (center: DistributionCenter): JSX.Element => (
                          <Select.Option value={center.id} key={center.id}>
                            {center.name}
                          </Select.Option>
                        ),
                      )}
                    {//When user HAS NO permission, show the options with the assigned distr centers information
                    !isAllowed([P.CAN_VIEW_ALL_DISTRIBUTION_CENTERS]) &&
                      this.props.distributionCenters &&
                      assignedDistrCenters.map(
                        (id: string, index: number): JSX.Element => {
                          const distrCenter = this.props.distributionCenters.find(
                            (center: DistributionCenter): boolean =>
                              center.id === id,
                          );
                          return distrCenter ? (
                            <Select.Option value={distrCenter.id} key={index}>
                              {distrCenter.name}
                            </Select.Option>
                          ) : (
                            <div key={index} />
                          );
                        },
                      )}
                  </Select>,
                )}
              </Form.Item>
            </Col> */}
            <Col span={12}>
              <Form.Item label="Fecha" style={{ paddingLeft: '10px' }}>
                {form.getFieldDecorator('cylinderSettlement.movementDate', {
                  initialValue: moment(),
                  rules: [
                    {
                      required: true,
                      message: 'Seleccione una fecha',
                    },
                  ],
                })(
                  <DatePicker
                    value={ this.state.date }
                    onChange={ value => this.changeState({ date: value || moment(new Date()) }) }
                    disabled={isFetching}
                    style={{ width: '100%' }}
                  />,
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Código de cliente"
                style={{ paddingLeft: 0 }}
              >
                {//Assign customer code to first element in sales list, to avoid use a field for every sale
                form.getFieldDecorator('cylinderSales[0].customerCode', {
                  initialValue: '',
                  rules: [],
                })(
                  <Input
                    value={ this.state.customerCode }
                    onChange={ event => this.changeState({ customerCode: event.target.value || '' }) }
                    disabled={isFetching}
                    placeholder="Código de cliente"
                  />,
                )}
              </Form.Item>
            </Col>
            <Col span={ 12 }>
              <Form.Item
                label="Descuento (En monto, no porcentaje)"
                style={{ paddingLeft: '10px' }}
              >
                {//Assign customer code to first element in sales list, to avoid use a field for every sale
                form.getFieldDecorator('cylinderSales[0].discount', {
                  initialValue: '',
                  rules: [],
                })(
                  <Input
                    disabled={isFetching}
                    placeholder="Descuento"
                    value={ this.state.discount }
                    onChange={ event => this.changeState({ discount: +event.target.value }) }
                  />,
                )}
              </Form.Item>
            </Col>
            <Col span={ 12 }>
              <Form.Item label="Condición de pago" style={{}} >
                {form.getFieldDecorator('cylinderSettlement.spc', {
                  initialValue: this.state.paymentCondition,
                  rules: [
                    {
                      required: true,
                      message: 'Seleccione una opción',
                    },
                  ],
                })(
                  <Select
                    style={{ width: '100%' }}
                    value={ this.state.paymentCondition }
                    onChange={(value): void => {
                      this.changeState({ paymentCondition: value });
                    }}
                  >
                    { this.paymentConditionOptions.map(pco => (
                      <Select.Option key={ pco.value } value={ pco.value } > { pco.name } </Select.Option>
                    )) }
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col span={ 12 }>
              <Form.Item label="Forma de pago" style={ { paddingLeft: 10 } } >
                {form.getFieldDecorator('cylinderSettlement.spw', {
                  initialValue: `${this.state.paymentWay}`,
                  rules: [
                    {
                      required: true,
                      message: 'Seleccione una opción',
                    },
                  ],
                })(
                  <Select
                    style={{ width: '100%' }}
                    value={ this.state.paymentWay }
                    onChange={(value): void => {
                      this.changeState({ paymentWay: value });
                    }}
                  >
                    { this.paymentWayOptions.map(pco => (
                      <Select.Option key={ pco.value } value={ pco.value } > { pco.name } </Select.Option>
                    )) }
                  </Select>,
                )}
              </Form.Item>
            </Col>
          </Row>
          {cylinderCapacities.map(
            (item, index: number): ReactNode => {
              const capacityPrices = prices.filter(
                (price: Price): boolean =>
                  price.productType === item.productType,
              );
              return (
                <Row key={item.label}>
                  <Col span={0}>
                    <Form.Item>
                      {form.getFieldDecorator(
                        `cylinderSales[${index}].cylinderCapacity`,
                        { initialValue: item.capacity },
                      )(<Input />)}
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      style={{ marginBottom: '10px', marginRight: '5px' }}
                    >
                      {form.getFieldDecorator(
                        `cylinderSales[${index}].numberOfCylinders`,
                        {
                          initialValue: '',
                          rules: [
                            {
                              pattern: onlyNumberDecimalPattern,
                              required: true,
                              message: 'Introduzca una cantidad válida',
                            },
                            {
                              min: 1,
                            },
                            {
                              max: 10,
                              message: 'Máximo 10 dígitos',
                            },
                          ],
                        },
                      )(
                        <Input
                          type="number"
                          style={{ width: '100%' }}
                          placeholder="Cantidad"
                          disabled={isFetching}
                          addonBefore={item.label}
                        />,
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={14}>
                    <Form.Item
                      style={{ marginBottom: '10px', marginLeft: '24px' }}
                    >
                      {'Precio: '}
                      {form.getFieldDecorator(
                        `cylinderSales[${index}].unitPrice`,
                        {
                          //If prices is not empty, set the newest value as default
                          initialValue:
                            capacityPrices.length > 0
                              ? capacityPrices[0].priceValue
                              : '',
                          rules: [
                            {
                              required: true,
                              message: 'Seleccione un precio',
                            },
                          ],
                        },
                      )(
                        <Select style={{ width: '76%' }} disabled={isFetching}>
                          {//Filter prices by productType to show only prices of each cylinder capacity
                          capacityPrices.map(
                            (price: Price): ReactNode => {
                              return (
                                <Select.Option
                                  value={price.priceValue}
                                  key={price.id}
                                >
                                  {'$' + price.priceValue}
                                </Select.Option>
                              );
                            },
                          )}
                        </Select>,
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              );
            },
          )}
        </Row>
      </Modal>
    );
  };
}

export default CylindersFormComponent;
