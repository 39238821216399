import Service from 'services/Service';
import axios, { AxiosResponse } from 'axios';
import { Equivalence } from 'types/type';
import { createCoreServiceUrl } from 'commons/services';

class EquivalenceService extends Service {
  public save = async (equivalences: Equivalence[]): Promise<Equivalence[]> => {
    return axios({
      method: 'post',
      url: createCoreServiceUrl(`equivalences`),
      data: equivalences,
    }).then(
      (response: AxiosResponse<Equivalence[]>): Equivalence[] => response.data,
    );
  };

  public findByContainerId = async (id: string): Promise<Equivalence[]> => {
    return axios({
      method: 'get',
      url: createCoreServiceUrl(`equivalences/${id}`),
    }).then(
      (response: AxiosResponse<Equivalence[]>): Equivalence[] => response.data,
    );
  };
}

export default EquivalenceService;
