import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import BaseWidget from './baseWidget';
import { SettlementSummaryModel, BaseWidgetProps } from './types';
import axios, { AxiosResponse } from 'axios';
import * as _ from 'lodash';
import { createCoreServiceUrl } from '../../../commons/services';
import { getDistCenterName } from '../utilities';
import { hasValue } from 'commons/functions';
import { Row, Col, Select } from 'antd';
import { Equipment } from 'types/type';
// eslint-disable-next-line @typescript-eslint/no-var-requires
let moment = require('moment-timezone');
const { Option } = Select;

class SalesDistributionCenterLineChart extends BaseWidget {
  private fetchData(equipmentList: string[]): void {
    const { initialDate, endDate } = this.props;
    //If the difference in days between the initial and end date is 0 days, we need to recalculate the initial date to get one week of info.
    //This will change once we implement the configuratio of this chart. But so far, it is not useful to have a line chart with only one point :)
    const calculatedInitialDate =
      moment(endDate).diff(moment(initialDate), 'days') > 0
        ? moment(initialDate)
        : moment(endDate).subtract(7, 'days');
    const url = `financialInformation/settlements?dateGreaterThan=${calculatedInitialDate.toISOString()}&dateLessThan=${endDate}&equipmentList=${equipmentList}`;
    axios
      .get(createCoreServiceUrl(url))
      .then((response: AxiosResponse<SettlementSummaryModel[]>): void => {
        this.setState({ settlements: response.data });
      });
  }

  public componentDidMount(): void {
    this.fetchData(this.state.selectedEquipment);
    this.getEquipmentByDC(this.state.selectedDistributionC);
  }

  public componentDidUpdate = (prevProps: BaseWidgetProps): void => {
    const { initialDate, endDate } = this.props;
    if (prevProps.initialDate === initialDate && prevProps.endDate === endDate)
      return;
    this.fetchData(this.state.selectedEquipment);
  };

  private getEquipmentByDC = (
    distributionCenterId: string,
  ): Promise<Equipment[]> =>
    axios({
      method: 'post',
      url: createCoreServiceUrl(`settlement/equipment`),
      data: { distributionCenterId },
    }).then((res: AxiosResponse<Equipment[]>): Equipment[] => {
      this.setState({ equipmentByDC: res.data });
      return res.data;
    });

  private getEquipmentName = (equipmentFilter: string): string => {
    const equip = this.props.equipment.find(
      (e: Equipment): boolean => e.idG4s === equipmentFilter,
    );
    if (equip) return equip.tag + ' Num: ' + equip.number;
    return equipmentFilter;
  };

  //Groups the information by distribution center and then groups the resultant information by the day of the month.
  //This will change soon too (The period of time to group by maybe should be configurable)
  private calculateData(
    settlements: SettlementSummaryModel[],
  ): { id: string; data: { x: string; y: number }[] }[] {
    let lineSettlements;
    let filter = '';
    if (this.state.selectedEquipment.length === 0) {
      filter = 'distributionCenterId';
      if (this.state.selectedDistributionC === '') {
        lineSettlements = settlements;
      } else {
        lineSettlements = settlements.filter(
          (item: SettlementSummaryModel): boolean => {
            return (
              item.distributionCenterId === this.state.selectedDistributionC
            );
          },
        );
      }
    }
    if (this.state.selectedEquipment.length > 0) {
      filter = 'equipmentId';
      lineSettlements = settlements.filter(
        (item: SettlementSummaryModel): boolean => {
          return (
            item.equipmentId ===
            this.state.selectedEquipment.find(
              (element: string): boolean => element === item.equipmentId,
            )
          );
        },
      );
    }
    const { initialDate, endDate } = this.props;
    let formatedDate = 'MMM-DD';
    const months = moment(endDate).diff(initialDate, 'months');
    const years = moment(endDate).diff(initialDate, 'years');
    if (months >= 3 && months <= 6) formatedDate = 'YYYY-MM';
    if (years === 1) formatedDate = 'YYYY-MM';
    if (years > 1) formatedDate = 'YYYY';

    let kilogramsValue = 0.56;
    if (hasValue(this.props.kilogramsValue)) {
      kilogramsValue = this.props.kilogramsValue;
    }
    return (
      _.chain(lineSettlements)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((s: SettlementSummaryModel): any => ({
          ...s,
          date: moment(s.movementDate)
            .tz('America/Mexico_City')
            .format(formatedDate),
        }))
        .groupBy(filter)
        .map((value, key): { id: string; data: { x: string; y: number }[] } => {
          let funtionSelect;
          if (filter === 'distributionCenterId') {
            funtionSelect = getDistCenterName(key);
          } else {
            funtionSelect = this.getEquipmentName(key);
          }
          return {
            id: funtionSelect,
            data: _.chain(value)
              .groupBy('date')
              .map((v, k): { x: string; y: number } => ({
                x: k,
                y: v.reduce((acc, s): number => {
                  let yFilter = '';
                  if (this.state.selectedPaymentMethod === '') {
                    yFilter = this.props.radioSelected;
                  } else {
                    yFilter = this.state.selectedPaymentMethod;
                  }
                  switch (yFilter) {
                    case 'volume':
                      return acc + s.totalVolume;
                    case 'Credito':
                      return acc + s.totalVolumeCredit;
                    case 'Contado':
                      return acc + s.totalVolumeCash;
                    case 'weight':
                      return acc + s.totalVolume * kilogramsValue;
                    case 'Transferencia':
                      return acc + s.totalVolumeTransfer * kilogramsValue;
                    case 'Auto consumo':
                      return acc + s.totalVolumeUsed * kilogramsValue;
                    default:
                      return acc + s.total;
                  }
                }, 0),
              }))
              .value(),
          };
        })
        .map((cd): { id: string; data: { x: string; y: number }[] } => ({
          id: cd.id,
          data: cd.data.sort((a, b): number => (a.x < b.x ? -1 : 1)),
        }))
        .value()
    );
  }

  public widgetRender = (): React.ReactNode => {
    const data = this.calculateData(this.state.settlements);
    return (
      <div>
        <p
          style={{
            fontFamily: 'arial',
            fontSize: '20px',
            textAlign: 'center',
            height: 15,
          }}
        >
          Venta a lo largo del tiempo
        </p>
        <Row style={{ display: 'flex' }} justify="center" gutter={10}>
          <Col span={3}></Col>
          <Col span={6}>
            <label className="text-filters">
              <b>{'Centro de distribución:'}</b>
            </label>
            <Select
              defaultValue=""
              style={{ width: '100%' }}
              onChange={(value: string): void => {
                this.setState({ selectedDistributionC: value });
                this.getEquipmentByDC(value);
              }}
            >
              <Select.Option value="">Todos</Select.Option>
              {this.props.distributionCenters &&
                this.props.distributionCenters.map(
                  (distCenter, index): JSX.Element => (
                    <Select.Option value={distCenter.id} key={index}>
                      {distCenter.name}
                    </Select.Option>
                  ),
                )}
            </Select>
          </Col>
          <Col span={6}>
            <label className="text-filters">
              <b>{'Equipos:'}</b>
            </label>
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              size="default"
              allowClear={true}
              maxTagCount={0}
              placeholder="Seleccionar Equipos"
              onChange={(value: []): void => {
                this.fetchData(value);
                this.setState({ selectedEquipment: value });
              }}
            >
              {this.state.equipmentByDC &&
                this.state.equipmentByDC.map(
                  (equip, index): JSX.Element => (
                    <Option value={equip.idG4s} key={index}>
                      {equip.tag}
                    </Option>
                  ),
                )}
            </Select>
          </Col>
          <Col span={6}>
            <label className="text-filters">
              <b>{'Metodo de pago:'}</b>
            </label>
            <Select
              defaultValue=""
              style={{ width: '100%' }}
              onChange={(value: string): void => {
                this.setState({ selectedPaymentMethod: value });
              }}
            >
              <Select.Option value="">Todos</Select.Option>
              {this.props.paymentMethods &&
                this.props.paymentMethods.map(
                  (payment, index): JSX.Element => (
                    <Option value={payment} key={index}>
                      {payment}
                    </Option>
                  ),
                )}
            </Select>
          </Col>
          <Col span={2}></Col>
        </Row>
        <Row style={{ height: 200 }}>
          <ResponsiveLine
            data={data}
            margin={{ right: 140, bottom: 40, left: 100, top: 5 }}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              min: 'auto',
              max: 'auto',
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: 'bottom',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -45,
              legendOffset: 36,
              legendPosition: 'middle',
            }}
            axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendOffset: -40,
              legendPosition: 'middle',
            }}
            colors={{ scheme: 'category10' }}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabel="y"
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
              {
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemBackground: 'rgba(0, 0, 0, .03)',
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        </Row>
      </div>
    );
  };
}

export default SalesDistributionCenterLineChart;
