/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Hiram Padilla <hiram@ssf.com.mx>, February 2021
 *
 * This file contains the redux types for Cylinder Settlements & Sales
 */

export const BEGIN_FETCH = 'BEGIN_FETCH';
export const FETCH_COMPLETED = 'FETCH_COMPLETED';
export const SET_CYLINDER_SALES = 'SET_CYLINDER_SALES';
export const RESET_CYLINDER_SALES = 'RESET_CYLINDER_SALES';
export const SET_CYLINDER_SETTLEMENTS = 'SET_CYLINDER_SETTLEMENTS';
export const SET_DISTRIBUTION_CENTERS = 'SET_DISTRIBUTION_CENTERS';
export const SET_CYLINDER_EQUIPMENTS = 'SET_CYLINDER_EQUIPMENTS';
export const SET_PRICES = 'SET_PRICES';
export const SET_CYLINDER_FILTERS = 'SET_CYLINDER_FILTERS';

/* Action to indicate the fetch data has started */
interface BeginFetch {
  type: typeof BEGIN_FETCH;
}

/* Action to indicate the fetch data has been completed */
interface EndFetch {
  type: typeof FETCH_COMPLETED;
}

/* Action to set the cylinder settlements into a redux property */

interface SetCylinderSettlements {
  type: typeof SET_CYLINDER_SETTLEMENTS;
  settlements: {};
}

/* Action to set cylinder sales into a redux property */
interface SetCylinderSales {
  type: typeof SET_CYLINDER_SALES;
  sales: {};
}

/* Action to reset the cylinder sales list */
interface ResetCylinderSales {
  type: typeof RESET_CYLINDER_SALES;
}

/* Action to set distribution centers into a redux property */
interface SetDistributionCenters {
  type: typeof SET_DISTRIBUTION_CENTERS;
  distributionCenters: {};
}

/* Action to set cylinder equipments into a redux property */
interface SetCylinderEquipments {
  type: typeof SET_CYLINDER_EQUIPMENTS;
  equipments: {};
}

/* Action to set the prices list into a redux property */
interface SetPrices {
  type: typeof SET_PRICES;
  prices: {};
}

/* Action to set cylinder filters values into a redux property */
interface SetFilters {
  type: typeof SET_CYLINDER_FILTERS;
  cylinderFilters: {};
}

export type CylinderSettlementAction =
  | BeginFetch
  | EndFetch
  | SetCylinderSettlements
  | SetCylinderSales
  | ResetCylinderSales
  | SetDistributionCenters
  | SetCylinderEquipments
  | SetPrices
  | SetFilters;
