import { Modal } from 'antd';
import React, { ChangeEvent, Component, ReactNode } from 'react';
import Text from 'antd/lib/typography/Text';
import TextArea from 'antd/lib/input/TextArea';
import { InvoiceForPortfolio } from 'types/type';

interface ReceivedProps {
  isVisible: boolean;
  onCancel: Function;
  onCreate: Function;
  isFetching: boolean;
  selectedRow: InvoiceForPortfolio;
}

let observations = '';

class ObservationsComponent extends Component<ReceivedProps> {
  public render = (): ReactNode => {
    const { isVisible, onCancel, onCreate, isFetching } = this.props;
    let currentInvoice = this.props.selectedRow;
    return (
      <Modal
        width={450}
        visible={isVisible}
        okText="Guardar"
        cancelText="Cancelar"
        onOk={(): void => {
          currentInvoice.observations = observations;
          onCreate(currentInvoice);
        }}
        onCancel={onCancel()}
        centered
      >
        <Text strong>Agregar observaciones: </Text>
        <TextArea
          rows={4}
          placeholder="Escriba aquí sus observaciones (opcional)"
          disabled={isFetching}
          onChange={(event: ChangeEvent<HTMLTextAreaElement>): void => {
            observations = event.target.value;
          }}
          style={{ marginTop: '15px' }}
          defaultValue={currentInvoice.observations}
        />
      </Modal>
    );
  };
}

export default ObservationsComponent;
