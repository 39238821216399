import axios, { AxiosResponse } from 'axios';
import Service from '../Service';
import {
  createCoreServiceUrl,
  createParamsService,
} from '../../commons/services';
import {
  Sale,
  Pageable,
  DistributionCenter,
  DashboardValues,
  TotalsSaleIssued,
} from '../../types/type';
import moment, { Moment } from 'moment';

class SaleService extends Service {
  public getbyiUSCodeandTotal = (
    iUSCode: string,
    total: number,
  ): Promise<Sale> =>
    axios
      .get(createCoreServiceUrl(`sale/${iUSCode}/${total}`))
      .then((response: AxiosResponse<Sale>): Sale => response.data);

  public saleListGrouped = async (
    params: Record<string, any>, // eslint-disable-line
  ): Promise<Pageable> => {
    const response: AxiosResponse<Pageable> = await axios.get(
      createCoreServiceUrl('sales') + createParamsService(params),
    );
    return response.data;
  };

  public totalCalculator = (
    initDate: Moment,
    endDate: Moment,
  ): Promise<DistributionCenter[]> =>
    axios
      .get(
        createCoreServiceUrl(
          `bankdeposit/getTotalBankDeposit/${initDate}/${endDate}`,
        ),
      )
      .then(
        (response: AxiosResponse<DistributionCenter[]>): DistributionCenter[] =>
          response.data,
      );

  public totalCalculators = (): Promise<DashboardValues> =>
    axios
      .get(createCoreServiceUrl(`sale/total`))
      .then(
        (response: AxiosResponse<DashboardValues>): DashboardValues =>
          response.data,
      );

  public downloadFile = (
    id: string,
    name: string,
    saleFolio: string,
  ): Promise<void> =>
    axios({
      url: createCoreServiceUrl(`sale/files/${id}/${name}`),
      method: 'GET',
    }).then((response: AxiosResponse<string>): void => {
      console.log(saleFolio);
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.href = response.data;
      link.click();
    });

  public downloadTicket = async (
    id: string,
    saleFolio: string,
    name: string,
  ): Promise<void> => {
    const response = await axios({
      url: createCoreServiceUrl(`sale/${id}/ticket`),
      method: 'GET',
      responseType: 'arraybuffer',
    });
    const ticketUrl = window.URL.createObjectURL(new Blob([response.data]));
    const ticketLink = document.createElement('a');
    ticketLink.href = ticketUrl;
    ticketLink.setAttribute('download', saleFolio + '-' + name + '.pdf');
    document.body.appendChild(ticketLink);
    ticketLink.click();
  };

  public getTotalsSaleIssued = async (
    saleFilters: Record<string, any>, // eslint-disable-line
  ): Promise<TotalsSaleIssued> =>
    axios
      .get(
        createCoreServiceUrl(`totalsInvoiceIssued`) +
          createParamsService(saleFilters),
      )
      .then(
        (response: AxiosResponse<TotalsSaleIssued>): TotalsSaleIssued =>
          response.data,
      );

  public getReport = async (
    saleFilters: Record<string, any>, // eslint-disable-line
  ): Promise<void> => {
    const response = await axios({
      url:
        createCoreServiceUrl(`salesExcelReport`) +
        createParamsService(saleFilters),
      method: 'GET',
      responseType: 'arraybuffer',
    });

    //Get the current values of date to set them in the file name
    const year = moment().year();
    const month = moment().month() + 1;
    const day = moment().date();

    const ticketUrl = window.URL.createObjectURL(new Blob([response.data]));
    const ticketLink = document.createElement('a');
    ticketLink.href = ticketUrl;
    ticketLink.setAttribute(
      'download',
      `Reporte_ventas_${year}_${month}_${day}.xlsx`,
    );
    document.body.appendChild(ticketLink);
    ticketLink.click();
  };

  public generateXmlZip = async (
    saleFilters: Record<string, any>, // eslint-disable-line
  ): Promise<void> => {
    const response = await axios({
      url:
        createCoreServiceUrl(`sales/buildZip`) +
        createParamsService(saleFilters),
      method: 'GET',
      responseType: 'arraybuffer',
    });

    const zipUrl = window.URL.createObjectURL(new Blob([response.data]));
    const zipLink = document.createElement('a');
    zipLink.href = zipUrl;
    zipLink.setAttribute('download', `XML_PDF_Comprimidos.zip`);
    document.body.appendChild(zipLink);
    zipLink.click();
  };

  public updateInvoice = async (
    saleId: string,
    invoiceRequired: boolean,
  ): Promise<void> => {
    const response = await axios({
      url: createCoreServiceUrl(`sale/${saleId}`),
      method: 'PUT',
      data: { invoiceRequired: invoiceRequired },
    });

    return response.data;
  };
  public getByStatementId = (statementId: string): Promise<Sale[]> =>
    axios
      .get(createCoreServiceUrl(`sales/${statementId}`))
      .then((response: AxiosResponse<Sale[]>): Sale[] => response.data);

  public generateCancellationsReport = async (
    filters: Record<string, any>, // eslint-disable-line
  ): Promise<void> => {
    const response = await axios({
      method: 'get',
      url:
        createCoreServiceUrl(`sale/cancellationsReport`) +
        createParamsService(filters),
      responseType: 'arraybuffer',
    });

    const reportUrl = window.URL.createObjectURL(new Blob([response.data]));
    const reportLink = document.createElement('a');
    reportLink.href = reportUrl;
    reportLink.setAttribute('download', `Cancelaciones.xlsx`);
    document.body.appendChild(reportLink);
    reportLink.click();
  };
}
export default SaleService;
