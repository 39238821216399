/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Ricardo Sansores <ricardo@ssf.mx>, May 2019
 *
 * Here is configured all the Stores of the application that are used by
 * the redux reducers.
 */

import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import loginState, { LoginState } from 'routes/login/redux/reducers';
import usersState, { UsersState } from 'routes/users/redux/reducers';
import passwordState, {
  PasswordState,
} from 'routes/activatepassword/redux/reducers';
import companyState, { CompanyState } from 'routes/company/redux/reducers';
import invoiceState, { InvoiceState } from 'routes/invoices/redux/reducers';
import saleState, { SaleState } from 'routes/sales/redux/reducers';
import customerState, { CustomerState } from 'routes/customers/redux/reducers';
import paymentState, { PaymentState } from 'routes/payment/redux/reducers';
import routesState, { RoutesState } from 'routes/route/redux/reducers';
import settlementState, {
  SettlementState,
} from 'routes/settlements/redux/reducers';
import statementState, {
  StatementState,
} from 'routes/statement/redux/reducers';
import bankDepositState, {
  BankDepositState,
} from 'routes/bankDeposit/redux/reducers';
import thunk from 'redux-thunk';
import equipmentState, {
  EquipmentState,
} from 'routes/equipments/redux/reducers';
import inventoryState, {
  InventoryState,
} from 'routes/inventory/redux/reducers';
import cylindersState, {
  CylindersState,
} from 'routes/cylinders/redux/reducers';
import portfoliosState, {
  PortfoliosState,
} from 'routes/portfolios/redux/reducers';

/**
 * This is the whole state of the application.
 */
export interface RootState {
  loginState: LoginState;
  usersState: UsersState;
  passwordState: PasswordState;
  companyState: CompanyState;
  invoiceState: InvoiceState;
  saleState: SaleState;
  customerState: CustomerState;
  statementState: StatementState;
  paymentState: PaymentState;
  settlementState: SettlementState;
  bankDepositState: BankDepositState;
  equipmentState: EquipmentState;
  inventoryState: InventoryState;
  cylindersState: CylindersState;
  portfoliosState: PortfoliosState;
  routesState: RoutesState;
}

/**
 * Combine all the reducers in a big unique Store for the application.
 */

// @ts-ignore
const composeEnhancers: combineReducers<RootState> =
  // @ts-ignore
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(
  combineReducers<RootState>({
    loginState,
    usersState,
    passwordState,
    companyState,
    invoiceState,
    saleState,
    customerState,
    statementState,
    paymentState,
    settlementState,
    bankDepositState,
    equipmentState,
    inventoryState,
    cylindersState,
    portfoliosState,
    routesState,
  }),
  composeEnhancers(applyMiddleware(thunk)),
);
