// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const paymentConditionEs = (value: string): string => {
  switch (value) {
    case 'CASH':
      return 'Contado';
    case 'CREDIT':
      return 'Crédito';
    case 'FAILED':
      return 'Falla';
    case 'PREPAID':
      return 'Prepago';
    case 'RETURN':
      return 'Retorno';
    default:
      return 'Traspáso/Consumo Propio';
  }
};
export const paymentWayEs = (value: string): string => {
  switch (value) {
    case 'EFECTIVO':
      return 'Efectivo';
    case 'CREDITO':
      return 'Tarjeta de crédito';
    case 'DEBITO':
      return 'Tarjeta de débito';
    case 'CHEQUE':
      return 'Cheque';
    case 'POR_DEFINIR':
      return 'Por definir';
    default:
      return 'Transferencia';
  }
};
