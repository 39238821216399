import { ColumnProps } from 'antd/lib/table';
import { toNumber } from 'commons/numbersFormat';
import { DATE_FORMAT } from 'commons/services';
import moment from 'moment';
import { RoutePortfolio } from 'types/type';

export const getPortfolioStatusText = (status: string): string => {
  switch (status) {
    case 'ABIERTA':
      return 'Abierta';
    case 'ASIGNADA':
      return 'Asignada';
    default:
      return 'Finalizada';
  }
};

export const portfoliosInfoColumns = (): ColumnProps<RoutePortfolio>[] => [
  {
    title: 'Folio cartera',
    dataIndex: 'folio',
  },
  {
    title: 'Folio Taskit',
    dataIndex: 'taskitFolio',
  },
  {
    title: 'Fecha de la factura',
    dataIndex: 'invoiceDate',
    render: (invoiceDate: string): React.ReactNode =>
      moment(invoiceDate).format(DATE_FORMAT),
  },
  {
    title: 'Cliente',
    dataIndex: 'customerName',
  },
  {
    title: 'Serie/Folio',
    dataIndex: 'invoiceFolio',
    render: (folio: string, record: RoutePortfolio): React.ReactNode => {
      if (folio === null) return 'N/A';
      return record.invoiceSerie + '/' + folio;
    },
  },
  {
    title: 'Importe total factura',
    dataIndex: 'invoiceTotal',
    render: (total: number): React.ReactNode => '$ ' + toNumber(total),
  },
  {
    title: 'Total cobrado',
    dataIndex: 'amountToPay',
    render: (amount: number): React.ReactNode => {
      if (amount === null) return 'Pendiente por cobrar';
      else return '$ ' + toNumber(amount);
    },
  },
];
