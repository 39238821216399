/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by David Canul <david@ssf.com.mx>, Jun 2019
 *
 *
 */
import CustomersService from '../../../services/customers';
import G4sService from '../../../services/g4sServices';
import { parseApiError } from '../../../commons/error';
import { notification } from 'antd';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {
  CustomerAction,
  BEGIN_FETCH,
  FETCH_COMPLETED,
  SET_CUSTOMER,
  SET,
  GET_CUSTOMER_ADDRESS,
  EDIT_CUSTOMER_ADDRESS,
  SET_CUSTOMER_DEBT,
  SET_CUSTOMER_FILTERS,
  SET_USERS,
  RESET_CUSTOMER_MODEL,
  SET_ZIP_CODE,
} from './types';
import { getIsFetching } from './reducers';
import { RootState } from 'store/configureStore';
import { initCustomerModel, initCustomerAddress } from 'commons/initTypes';
import { AnyAction } from 'redux';
import {
  Contact,
  CustomerModel,
  CustomerFilters,
  CustomerAddress,
} from 'types/type';
import { Popups } from 'commons/components/popups/popups';
import { SET_DISTRIBUTION_CENTER } from 'routes/company/redux/types';
import CompanyService from 'services/company';
import UserService from 'services/user';

export var initialContacts: Contact[];

const companyService = new CompanyService();
const customersService = new CustomersService();
const usersService = new UserService();
const g4sService = new G4sService();

const startFetch = (): CustomerAction => {
  return { type: BEGIN_FETCH };
};
type ITA = ThunkAction<Promise<void>, RootState, {}, CustomerAction>;
type IDA = ThunkDispatch<{}, {}, CustomerAction>;
/**
 * This action creator is not public beca1use it should be used
 * automatically by other action creators that need fetch data from
 * backend.
 */
const endFetch = (): CustomerAction => {
  return { type: FETCH_COMPLETED };
};

const editFilters = (filters: CustomerFilters): CustomerAction => {
  return {
    type: SET_CUSTOMER_FILTERS,
    customerFilters: filters,
  };
};

export const saveCustomerBalance = (
  customerCode: string,
  amount: number,
  userId: string,
  distId: string,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    await customersService.saveCustomerBalance(
      customerCode,
      amount,
      userId,
      distId,
    );
    dispatch(endFetch());
    Popups.notifySuccess('La cuenta del cliente ha sido actualizada');
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};
/**
 *This action give customer from db by filters(customers filter and Page)
 */
export const page = (filter: CustomerFilters): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(editFilters(filter));
    dispatch(startFetch());
    const responsepage = await customersService.customerList(filter);
    const responsecustomerTotals = await customersService.customerdebts(filter);

    dispatch({ type: SET_CUSTOMER_DEBT, customerDebt: responsecustomerTotals });
    dispatch({ type: SET_CUSTOMER, customerList: responsepage });
    const responseDistribution = await companyService.getDistrubutionCenter();
    dispatch({
      type: SET_DISTRIBUTION_CENTER,
      distributionCenter: responseDistribution,
    });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};

export const getDistributionCenter = (): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    const responseDistribution = await companyService.getDistrubutionCenter();
    dispatch({
      type: SET_DISTRIBUTION_CENTER,
      distributionCenter: responseDistribution,
    });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};

export const getCustomer = (customerId: string): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    const response = await customersService.getCustomer(customerId);
    dispatch({ type: SET, customer: response });
    const responseDistribution = await companyService.getDistrubutionCenter();
    dispatch({
      type: SET_DISTRIBUTION_CENTER,
      distributionCenter: responseDistribution,
    });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};
export const getCustomerG4SByCode = (customerCode: string): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    const response = await customersService.getCustomerG4SByCode(customerCode);
    dispatch({ type: SET, customer: response });
    const responseDistribution = await companyService.getDistrubutionCenter();
    dispatch({
      type: SET_DISTRIBUTION_CENTER,
      distributionCenter: responseDistribution,
    });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};
export const cleanCustomer = (): ThunkAction<
  Promise<void>,
  {},
  {},
  AnyAction
> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch({ type: SET, customer: initCustomerModel });
    } catch (err) {}
  };
};
export const cleanCustomerAddress = (): ThunkAction<
  Promise<void>,
  {},
  {},
  AnyAction
> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch({
        type: GET_CUSTOMER_ADDRESS,
        customerAddressModel: initCustomerAddress,
      });
    } catch (err) {}
  };
};

export const editCustomer = (customer: CustomerModel): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    await customersService.save(customer);
    const responsepage = await customersService.customerList({
      filter: '',
      statusCredit: '',
      statusCustomer: '',
      distributionCenter: '',
      withSoonDueDate: false,
      withoutAutoInvoicing: false,
      withOnlyAssignedCustomers: false,
      withInvoicePendingPay: false,
      page: 1,
      pageSize: 25,
      isDisabled: false,
      sorter: 'DESC',
      sortByColumn: 'debt',
    });
    dispatch({ type: SET_CUSTOMER, customerList: responsepage });
    Popups.notifySuccess('guardado exitosamente');
    dispatch(endFetch());
  } catch (error) {
    dispatch(endFetch());
    throw error;
  }
};

export const resetCustomer = (): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    dispatch({ type: RESET_CUSTOMER_MODEL });
    dispatch(endFetch());
  } catch (error) {
    dispatch(endFetch());
    throw error;
  }
};

export const editCustomerAddress = (
  id: string,
  contact: Contact[],
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }

  try {
    const response = await customersService.editCustomerAddress(id, contact);
    dispatch({ type: EDIT_CUSTOMER_ADDRESS, customerAddressModel: response });
    Popups.notifySuccess('guardado exitosamente');
  } catch (error) {
    dispatch(endFetch());
    throw error;
  }
};

export const getCustomerAddress = (id: string): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    const response = await customersService.getCustomerAddress(id);
    dispatch({ type: GET_CUSTOMER_ADDRESS, customerAddressModel: response });
  } catch (error) {
    dispatch(endFetch());
    notification.error(parseApiError(error));
  }
};

export const sendEmailWithDataAccess = (id: string): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }

  try {
    await customersService.sendEmailDataAccess(id);
    Popups.notifySuccess('Correo enviado');
  } catch (error) {
    dispatch(endFetch());
    notification.error(parseApiError(error));
  }
};

export const getUsers = (): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    const response = await usersService.getAll();
    dispatch({
      type: SET_USERS,
      users: response,
    });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};
export const deleteCustomerById = (customerId: string): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    await customersService.deleteCustomerById(customerId);
    dispatch(endFetch());
    notification.success({ message: 'Borrado de Cliente exitoso' });
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};
export const deleteCustomerBranches = (
  customerId: string,
  udrId: string,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    await customersService.deleteCustomerBranches(customerId, udrId);
    dispatch(endFetch());
    notification.success({ message: 'Borrado de UDR exitoso', key: '200' });
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};

export const getZipCode = (zipCode: string): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(endFetch());
    const response = await g4sService.getG4sZipCode(zipCode);
    dispatch({
      type: SET_ZIP_CODE,
      zipCode: response,
    });
  } catch (err) {
    dispatch(endFetch());
  }
};
export const downloadCustomersReport = (
  filters: CustomerFilters,
): ITA => async (dispatch: IDA, getState: Function): Promise<void> => {
  if (getIsFetching(getState())) return Promise.resolve();

  dispatch(startFetch());
  try {
    await customersService.generateCustomersReport(filters);
  } catch (error) {
    notification.error(parseApiError(error));
  }
  dispatch(endFetch());
};

export const saveUdr = (udr: CustomerAddress): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    await customersService.saveUdr(udr);
    Popups.notifySuccess('Udr guardada');
    dispatch(endFetch());
  } catch (error) {
    dispatch(endFetch());
    notification.error(parseApiError(error));
  }
};
