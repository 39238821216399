import React from 'react';
import GoogleMapReact from 'google-map-react';
import BaseWidget from './baseWidget';
import { BaseWidgetProps, SalePositionSummary } from './types';
import axios, { AxiosResponse } from 'axios';
import { createCoreServiceUrl } from '../../../commons/services';
import { Row } from 'antd';

class SalesHeatMap extends BaseWidget {
  public fetchData(): void {
    const { initialDate, endDate } = this.props;
    const url = `financialInformation/salePositions?dateGreaterThan=${initialDate}&dateLessThan=${endDate}`;
    axios
      .get(createCoreServiceUrl(url))
      .then((response: AxiosResponse<SalePositionSummary[]>): void => {
        this.setState({
          salePositions: response.data,
        });
      });
  }

  public componentDidUpdate = (prevProps: BaseWidgetProps): void => {
    const { initialDate, endDate, distributionCenters } = this.props;
    if (
      prevProps.initialDate === initialDate &&
      prevProps.endDate === endDate &&
      prevProps.distributionCenters === distributionCenters
    )
      return;
    this.fetchData();
  };

  public componentDidMount(): void {
    this.fetchData();
  }

  public widgetRender = (): React.ReactNode => {
    const data = this.state.salePositions;
    const heatMapData = {
      positions: data,
      options: {
        radius: 20,
        opacity: 0.6,
      },
    };

    return (
      // @ts-ignore
      <div>
        <p
          style={{
            fontFamily: 'arial',
            fontSize: '20px',
            textAlign: 'center',
            height: 15,
          }}
        >
          Mapa de calor de venta
        </p>
        <Row style={{ height: 320 }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyCsBQRj84EsjEJDwCWiIf0ZoRQCdqFqwh8',
            }}
            defaultCenter={{ lat: 21.903619, lng: -102.289391 }}
            defaultZoom={10}
            heatmapLibrary={true}
            heatmap={heatMapData}
          />
        </Row>
      </div>
    );
  };
}

export default SalesHeatMap;
