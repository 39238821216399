/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by David Canul <david@ssf.com.mx>, May 2019
 *
 *
 */
import {
  CustomerModel,
  AddressContactModel,
  CustomerTotals,
  CustomerFilters,
} from 'types/type';

/*
 * Action to add a customer to list component
 */
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const RESET_CUSTOMER_MODEL = 'RESET_CUSTOMER_MODEL';
export const SET = 'SET_CUSTOMER_LIST';
export const SET_DISTRIBUTION_CENTER = 'SET_DISTRIBUTION_CENTER';
export const SET_CUSTOMER_DEBT = 'SET_CUSTOMER_DEBT';
export const SET_CUSTOMER_FILTERS = 'SET_CUSTOMER_FILTERS';

/*
 * Begin a fetch operation action
 */
export const BEGIN_FETCH = 'BEGIN_FETCH';

/*
 * A fetch operation action has been completed
 */
export const FETCH_COMPLETED = 'FETCH_COMPLETED';
/**
 * Get contact action
 */
export const GET_CUSTOMER_ADDRESS = 'GET_CUSTOMER_ADDRESS';
/**
 * Edit contact action
 */
export const EDIT_CUSTOMER_ADDRESS = 'EDIT_CUSTOMER_ADDRESS';

/** Action to set a list of list of a model of user */
export const SET_USERS = 'SET_USERS';

export const SET_ZIP_CODE = 'SET_ZIP_CODE';

interface GetCustomerAddressAction {
  type: typeof GET_CUSTOMER_ADDRESS;
  customerAddressModel: AddressContactModel;
}

interface EditCustomerAddressAction {
  type: typeof EDIT_CUSTOMER_ADDRESS;
  customerAddressModel: AddressContactModel;
}
interface CustomerDebtAction {
  type: typeof SET_CUSTOMER_DEBT;
  customerDebt: CustomerTotals;
}

interface BeginFetchAction {
  type: typeof BEGIN_FETCH;
}
interface SetAction {
  type: typeof SET;
  customer: CustomerModel;
}
interface SetCustomerAction {
  type: typeof SET_CUSTOMER;
  customerList: {};
}
interface ResetCustomerModelAction {
  type: typeof RESET_CUSTOMER_MODEL;
}
interface SetDistributionCenterAction {
  type: typeof SET_DISTRIBUTION_CENTER;
  distributionCenter: {};
}
interface EndFetchAction {
  type: typeof FETCH_COMPLETED;
}
interface SetActionFilters {
  type: typeof SET_CUSTOMER_FILTERS;
  customerFilters: CustomerFilters;
}

interface SetUsersAction {
  type: typeof SET_USERS;
  users: {};
}
interface SetZipCodeAction {
  type: typeof SET_ZIP_CODE;
  zipCode: {};
}

export type CustomerAction =
  | SetZipCodeAction
  | SetActionFilters
  | CustomerDebtAction
  | EndFetchAction
  | BeginFetchAction
  | SetAction
  | SetDistributionCenterAction
  | SetCustomerAction
  | ResetCustomerModelAction
  | GetCustomerAddressAction
  | EditCustomerAddressAction
  | SetUsersAction;
