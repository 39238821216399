import { Button, Col, Form, Icon, Modal, Row, Upload } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import { fileSizeValidator } from 'commons/validators';
import React, { Component } from 'react';
import { BankDeposit } from 'types/type';

interface CounterProps {
  isFetching: boolean;
  isVisible: boolean;
  bankDeposit: BankDeposit;
  onCancel: Function;
  onCreate: Function;
  form: WrappedFormUtils;
}

class ClarificationForm extends Component<CounterProps> {
  public state = {
    tempFile: false,
  };

  private normFile = (e: Storage): File[] => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  private handleBeforeUpload = (): boolean => {
    this.setState({ tempFile: true });
    return true;
  };

  public handleRemove = async (): Promise<void> => {
    await this.props.form.setFieldsValue({ image: undefined });
    this.setState({ tempFile: false });
  };

  public render = (): JSX.Element => {
    const { isVisible, onCancel, onCreate, form } = this.props;
    return (
      <Modal
        width={430}
        visible={isVisible}
        title={'Añadir aclaración'}
        okText="Guardar"
        cancelText="Cancelar"
        onCancel={onCancel()}
        onOk={onCreate()}
        centered
      >
        <Row>
          <Col span={12}>
            <Form.Item label="Adjuntar archivo">
              {form.getFieldDecorator('image', {
                valuePropName: 'fileList',
                getValueFromEvent: this.normFile,
                rules: [
                  {
                    required: true,
                    message: 'Seleccione un archivo',
                  },
                  {
                    validator: fileSizeValidator(20),
                  },
                ],
                initialValue: undefined,
              })(
                <Upload
                  name="image"
                  action="/"
                  accept="*"
                  listType="picture-card"
                  beforeUpload={this.handleBeforeUpload}
                  onRemove={this.handleRemove}
                  multiple={false}
                  showUploadList={{ showPreviewIcon: false }}
                >
                  {this.state.tempFile === false ? (
                    <div>
                      <Button>
                        <Icon type={'upload'} /> Seleccionar
                      </Button>
                    </div>
                  ) : null}
                </Upload>,
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Comentario">
              {form.getFieldDecorator(`comment`, {
                initialValue: '',
                rules: [
                  {
                    required: true,
                    message: 'Introduzca un comentario',
                  },
                  {
                    max: 500,
                    message: 'Máximo 500 caracteres',
                  },
                ],
              })(
                <TextArea
                  rows={4}
                  placeholder="Por favor escriba su aclaración"
                />,
              )}
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    );
  };
}

export default ClarificationForm;
