import { Company } from 'types/type';

/*
 * Action to add a company to the staging area
 */
export const SAVE_COMPANY = 'SAVE_COMPANY';
export const SET_COMPANY = 'SET_COMPANY';
export const SET_DISTRIBUTION_CENTER = 'SET_DISTRIBUTION_CENTER';
export const SET = 'SET';

/*
 * Begin a fetch operation action
 */
export const BEGIN_FETCH = 'BEGIN_FETCH';

/*
 * A fetch operation action have been completed
 */
export const FETCH_COMPLETED = 'FETCH_COMPLETED';

interface BeginFetchAction {
  type: typeof BEGIN_FETCH;
}

interface SaveCompanyAction {
  type: typeof SAVE_COMPANY;
  companytype: Company;
}
interface SetAction {
  type: typeof SET;
  company: Company;
}
interface SetCompanyAction {
  type: typeof SET_COMPANY;
  companyList: {};
}
interface SetDistributionCenterAction {
  type: typeof SET_DISTRIBUTION_CENTER;
  distributionCenter: {};
}

interface EndFetchAction {
  type: typeof FETCH_COMPLETED;
}

export type CompanyAction =
  | EndFetchAction
  | BeginFetchAction
  | SetAction
  | SetCompanyAction
  | SetDistributionCenterAction
  | SaveCompanyAction;
