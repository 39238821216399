import { onlyNumberPattern } from 'commons/regularExpressions';
import { ValidationRule } from 'antd/lib/form';
import { MessageCustomerAddress, MessagesCustomer } from 'commons/strings';

export class CustomerRules {
  public static customerAddresCodeRule: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      pattern: onlyNumberPattern,
      message: MessageCustomerAddress.CUTOMER_ADDRESS_CODE_RULE,
    },
  ];
  public static customerAddresTextRule = (
    onRequiredMessage: string,
  ): ValidationRule[] => [
    {
      required: true,
      whitespace: true,
      message: onRequiredMessage,
    },
  ];
  public static customerAddresNumericRule = (
    onRequiredMessage: string,
  ): ValidationRule[] => [
    {
      required: true,
      message: onRequiredMessage,
    },
    {
      pattern: onlyNumberPattern,
      message: MessagesCustomer.INT_ONLY,
    },
  ];

  public static customerAddresSelectRule = (
    onRequiredMessage: string,
  ): ValidationRule[] => [
    {
      required: true,
      message: onRequiredMessage,
    },
  ];
}
