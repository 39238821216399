/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by David Canul <david@ssf.com.mx>, Dec 2019
 *
 *
 */

import { FilterBankDeposit } from 'types/type';

/*
 * Action to add a sale to list component
 */
export const SET_BANK_DEPOSIT = 'SET_BANK_DEPOSIT';
export const SET_APPLY_BANK_DEPOSIT = 'SET_APPLY_BANK_DEPOSIT';
export const SET_TOTAL_BANK_DEPOSIT = 'SET_TOTAL_BANK_DEPOSIT';
export const SET_SAVED_BANK_DEPOSIT = 'SET_SAVED_BANK_DEPOSIT';
export const SET_BANK_DEPOSIT_LIST = 'SET_BANK_DEPOSIT_LIST';
export const SET_DISTRIBUTION_CENTER = 'SET_DISTRIBUTION_CENTER';
export const SET_SETTLEMENT_LIST = 'SET_SETTLEMENT_LIST';
export const SET_SALES_LIST = 'SET_SALES_LIST';
export const RESET_SETTLEMENT_LIST = 'RESET_SETTLEMENT_LIST';
export const SET_SETTLEMENT_MODAL_LIST = 'SET_SETTLEMENT_MODAL_LIST';
export const RESET_SETTLEMENT__MODAL_LIST = 'RESET_SETTLEMENT__MODAL_LIST';
export const RESET_SALES_LIST = 'RESET_SALES_LIST';
export const SET_BANK_DPOSIT_FILTERS = 'SET_SETTLEMENT_FILTERS';
export const SET_CLARIFICATIONS_HISTORY = 'SET_CLARIFICATIONS_HISTORY';

/*
 * Begin a fetch operation action
 */
export const BEGIN_FETCH = 'BEGIN_FETCH';
interface SetActionFilters {
  type: typeof SET_BANK_DPOSIT_FILTERS;
  bankDepositFilters: FilterBankDeposit;
}

/*
 * A fetch operation action have been completed
 */
export const FETCH_COMPLETED = 'FETCH_COMPLETED';

interface BeginFetchAction {
  type: typeof BEGIN_FETCH;
}
interface SetAction {
  type: typeof SET_BANK_DEPOSIT_LIST;
  bankDeposit: {};
}

interface SetBankDepositAction {
  type: typeof SET_BANK_DEPOSIT;
  bankDepositList: {};
}
interface SetBankDepositStatusAction {
  type: typeof SET_APPLY_BANK_DEPOSIT;
  bankDepositStatus: {};
}
interface SetTotalAction {
  type: typeof SET_TOTAL_BANK_DEPOSIT;
  depositValues: {};
}

interface EndFetchAction {
  type: typeof FETCH_COMPLETED;
}
interface SetDistributionCenterAction {
  type: typeof SET_DISTRIBUTION_CENTER;
  distributionCenter: {};
}
interface SetSettlementList {
  type: typeof SET_SETTLEMENT_LIST;
  settlementList: {};
}
interface SetSalesList {
  type: typeof SET_SALES_LIST;
  salesList: {};
}

interface ResetSettlementList {
  type: typeof RESET_SETTLEMENT_LIST;
}
interface ResetSettlementModalList {
  type: typeof RESET_SETTLEMENT__MODAL_LIST;
}
interface SetSettlementModalList {
  type: typeof SET_SETTLEMENT_MODAL_LIST;
  settlementModalList: {};
}
interface ResetSalesList {
  type: typeof RESET_SALES_LIST;
}
interface SetBankDepositSaved {
  type: typeof SET_SAVED_BANK_DEPOSIT;
  bankDeposit: {};
}

interface SetClarificationsHistory {
  type: typeof SET_CLARIFICATIONS_HISTORY;
  clarifications: {};
}

export type BankDepositAction =
  | SetActionFilters
  | SetSettlementModalList
  | ResetSettlementModalList
  | SetSalesList
  | SetSettlementList
  | ResetSettlementList
  | ResetSalesList
  | SetDistributionCenterAction
  | SetTotalAction
  | SetBankDepositStatusAction
  | SetBankDepositSaved
  | EndFetchAction
  | BeginFetchAction
  | SetAction
  | SetBankDepositAction
  | SetClarificationsHistory;
