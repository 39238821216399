import {
  CollectionRoute,
  InvoiceForPortfolio,
  Portfolio,
  RoutePortfolio,
} from 'types/type';

/*
 * Begin a fetch operation action
 */
export const BEGIN_FETCH = 'BEGIN_FETCH';
/*
 * A fetch operation action have been completed
 */
export const FETCH_COMPLETED = 'FETCH_COMPLETED';

export const SET_ROUTES_PAGE = 'SET_ROUTES_PAGE';
export const RESET_ROUTES_PAGE = 'RESET_ROUTES_PAGE';
export const SET_ROUTE_DETAILS = 'SET_ROUTE_DETAILS';
export const RESET_ROUTE_DETAILS = 'RESET_ROUTE_DETAILS';
export const SET_PORTFOLIOS_TO_ADD = 'SET_PORTFOLIOS_TO_ADD';
export const RESET_PORTFOLIOS_TO_ADD = 'RESET_PORTFOLIOS_TO_ADD';
export const SET_CUSTOMERS_TO_MOVE = 'SET_CUSTOMERS_TO_MOVE';
export const RESET_CUSTOMERS_TO_MOVE = 'RESET_CUSTOMERS_TO_MOVE';
export const SET_OPENED_ROUTES = 'SET_OPENED_ROUTES';
export const RESET_OPENED_ROUTES = 'RESET_OPENED_ROUTES';

interface SetRoutesPage {
  type: typeof SET_ROUTES_PAGE;
  routesPage: {};
}
interface ResetRoutesPage {
  type: typeof RESET_ROUTES_PAGE;
}

interface BeginFetchAction {
  type: typeof BEGIN_FETCH;
}

interface EndFetchAction {
  type: typeof FETCH_COMPLETED;
}

interface SetRouteDetails {
  type: typeof SET_ROUTE_DETAILS;
  routeDetails: RoutePortfolio[];
}

interface ResetRouteDetails {
  type: typeof RESET_ROUTE_DETAILS;
}

interface SetPortfoliosToAdd {
  type: typeof SET_PORTFOLIOS_TO_ADD;
  portfolios: Portfolio[];
}

interface ResetPortfoliosToAdd {
  type: typeof RESET_PORTFOLIOS_TO_ADD;
}

interface SetCustomersToMove {
  type: typeof SET_CUSTOMERS_TO_MOVE;
  customersInfo: InvoiceForPortfolio[];
}

interface ResetCustomersToMove {
  type: typeof RESET_CUSTOMERS_TO_MOVE;
}

interface SetOpenedRoutes {
  type: typeof SET_OPENED_ROUTES;
  openedRoutes: CollectionRoute[];
}

interface ResetOpenedRoutes {
  type: typeof RESET_OPENED_ROUTES;
}

export type RouteAction =
  | EndFetchAction
  | BeginFetchAction
  | SetRoutesPage
  | ResetRoutesPage
  | SetRouteDetails
  | ResetRouteDetails
  | SetPortfoliosToAdd
  | ResetPortfoliosToAdd
  | SetCustomersToMove
  | ResetCustomersToMove
  | SetOpenedRoutes
  | ResetOpenedRoutes;
