/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by David Canul <david@ssf.com.mx>, Jun 2019
 *
 * This component lists all the customers crawled from g4s
 */
import React, { Component, FormEvent } from 'react';
import { connect } from 'react-redux';
import Form, { FormComponentProps, ValidationRule } from 'antd/lib/form';
import {
  Customer,
  DistributionCenter,
  CustomerModel,
  CustomerAddress,
  FiscalInformation,
  User,
  G4sState,
  G4sMunicipality,
  ZipCode,
} from 'types/type';
import { RouteComponentProps } from 'react-router';
import { RootState } from 'store/configureStore';
import {
  Button,
  Row,
  Card,
  Input,
  Select,
  Col,
  InputNumber,
  DatePicker,
  Radio,
  Checkbox,
  Icon,
  AutoComplete,
} from 'antd';
import './../../style.css';
import {
  editCustomer,
  getCustomer,
  resetCustomer,
  getCustomerG4SByCode,
  cleanCustomer,
  getDistributionCenter,
  sendEmailWithDataAccess,
  getUsers,
  getZipCode,
  saveUdr,
} from '../../redux/actions';
import { InvoiceRules as r } from '../../../invoices/components/FiscalData/rulesFiscalData';
import {
  usoCFDI,
  formaPago,
  regimenFiscal,
} from 'commons/components/regimenFiscal';
import { ButtonProps } from 'antd/lib/button';
import { Link } from 'react-router-dom';
import Text from 'antd/lib/typography/Text';
import { Popups } from 'commons/components/popups/popups';
import moment from 'moment';
import RadioGroup from 'antd/lib/radio/group';
import TextArea from 'antd/lib/input/TextArea';
import { MessagesCustomer, LabelsCustomer } from 'commons/strings';
import CustomerAdresssManager from './customerAddressManager';
import { isAllowed } from 'security/index';
import { Permissions as P } from 'commons/permissions';
import { hasValue } from 'commons/functions';
import FiscalInformationManager from './fiscalInformationManager';
import GenericSelect from 'commons/components/genericComponents/genericSelect';
import G4sServices from 'services/g4sServices';
import { getUdrFieldsNames } from 'routes/customers/functions';
import GoogleMapReact, { ClickEventValue } from 'google-map-react';
import Marker from 'routes/sales/components/Marker';

const g4sServices: G4sServices = new G4sServices();
const buttonProps: ButtonProps = {
  type: 'primary',
  htmlType: 'submit',
};

/**
 * this interface init the actions from de action class when excecut a event async
 */
interface DispatchProps {
  editCustomer: (customer: CustomerModel) => void;
  getCustomer: (customer: string) => void;
  resetCustomer: () => void;
  getCustomerG4SByCode: (customer: string) => void;
  cleanCustomer: () => void;
  getDistributionCenter: () => void;
  sendEmailWithDataAccess: (id: string) => void;
  getUsers: () => void;
  getZipCode: (zipCode: string) => void;
  saveUdr: (udr: CustomerAddress) => void;
}
/**
 * this const build a style depending value send the size in the screen interact.
 * @param value is a value size in the lg xl and xxl proportion.
 */
const sizeScreen = (value: number): {} => ({
  xs: { span: 23, offset: 1 },
  sm: { span: 23, offset: 1 },
  md: { span: 23, offset: 1 },
  lg: { span: value, offset: 1 },
  xl: { span: value, offset: 1 },
  xxl: { span: value, offset: 1 },
});
/**
 * This interface init the param when send a id in the link
 */
interface HomeRouterProps {
  id: string;
}
/**
 * This interface render a propertyes used in the action class to consume in the form elments
 * and init the isftching validator.
 */
interface StateProps {
  customerModel: CustomerModel;
  isFetching: boolean;
  distributionCenter: DistributionCenter[];
  users: User[];
  zipCode: ZipCode;
}
/**
 * This type init the props requiere in to component props.
 */
type Props = FormComponentProps &
  StateProps &
  DispatchProps &
  RouteComponentProps<HomeRouterProps>;

const CUSTOMER_TYPE = {
  CREDIT: 'CREDIT',
  CASH: 'CASH',
  SELF_CONSUMPTION: 'SELF_CONSUMPTION',
  TRASLATE: 'TRASLATE',
  RETURN: 'RETURN',
  PREPAID: 'PREPAID',
};
interface States {
  isCredit: boolean;
  codesAreDuplicated: boolean;
  errors: [];
  errorsFiscalInformation: [];
  states: G4sState[];
  municipalities: G4sMunicipality[];
  indexUdrToSave: number;
  location: { latitude: number; longitude: number };
}
class CustomerFormComponent extends Component<Props> {
  public state: States = {
    isCredit: false,
    codesAreDuplicated: false,
    errors: [],
    errorsFiscalInformation: [],
    states: [],
    municipalities: [],
    indexUdrToSave: 0,
    location: { latitude: 21.881478, longitude: -102.29456 },
  };

  /**
   *This method clean all items form on the new customer area
   *
   */
  public componentWillUnmount(): void {
    this.props.form.resetFields();
    this.props.cleanCustomer();
  }
  /**
   * This method loads data from g4s
   */
  private fetchG4sData = (): void => {
    Promise.all([
      g4sServices.getG4sStates(), //0
      g4sServices.getG4sMunicipalities(1), //1
    ]).then((results): void => {
      this.setState({
        states: results[0].data,
        municipalities: results[1].data,
      });
    });
  };

  private getZipCode = (zipCode: string): void => {
    const asyncAction = async (): Promise<void> => {
      await this.props.getZipCode(zipCode);
      if (this.props.zipCode.estado === '') {
        Popups.notifyError('No hay estados con ese código postal');
        return;
      }

      const stateByZipCode = this.state.states.filter(
        (g4sState: G4sState): boolean =>
          g4sState.name === this.props.zipCode.estado,
      );

      const municipalities = await g4sServices.getG4sMunicipalities(
        stateByZipCode[0] ? stateByZipCode[0].id : 1,
      );
      await this.setState({ municipalities: municipalities.data });
      const municipaltieByZipCode = municipalities.data.filter(
        (muni: G4sMunicipality): boolean =>
          muni.name === this.props.zipCode.municipio,
      );
      await this.props.form.setFieldsValue({
        'customer.municipality': municipaltieByZipCode[0]
          ? municipaltieByZipCode[0].name
          : '',
        'customer.state': stateByZipCode[0] ? stateByZipCode[0].name : '',
      });
    };
    asyncAction();
  };

  private loadCustomerInfo = async (id: string): Promise<void> => {
    await this.props.getCustomer(id);
    const { latitude, longitude } = this.props.customerModel.customer;
    if (latitude && longitude)
      this.setState({ location: { latitude, longitude } });
  };

  /**
   *This method get the customer editable on the backend when the id has equals
   *the param is send in the url from de customer list area and validate if the id is difrent 0
   */
  public componentDidMount(): void {
    this.props.form.resetFields();
    const { id } = this.props.match.params;
    this.props.getDistributionCenter();
    this.props.getUsers();
    if (id !== '0') {
      this.loadCustomerInfo(id);
    } else {
      this.props.cleanCustomer();
    }
    this.fetchG4sData();
  }

  public setIndexUdrToSave = (index: number): void => {
    this.props.form.validateFields(
      //Fields name to validate
      getUdrFieldsNames(index),
      async (errors, values: CustomerModel): Promise<void> => {
        values.customerAddress[
          index
        ].customer = this.props.customerModel.customer.id;
        if (!errors) {
          //When user only has permission to edit UDR, save the UDR individually
          this.props.saveUdr(values.customerAddress[index]);
          this.setState({
            errors: [],
          });
        } else {
          this.setState({
            errors: errors.customerAddress
              ? errors.customerAddress
              : new Array(0),
          });
        }
      },
    );
  };

  /**
   * This method send a request to save a customer
   * when id is difrent 0 edit a customer and when is equals create a new customer
   */
  private handleOnSubmit = (e: FormEvent): void => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll(
      async (err, values: CustomerModel): Promise<void> => {
        let codesAreDuplicated = false;
        if (values.customerAddress !== undefined) {
          //Find duplicated UDR codes in the customer address list
          values.customerAddress.forEach(
            (item: CustomerAddress, index: number): void => {
              const duplicatedUdrCodes = values.customerAddress.filter(
                (itemFilter, indexFilter): boolean =>
                  item.code === itemFilter.code && index !== indexFilter,
              );
              if (duplicatedUdrCodes.length > 0) codesAreDuplicated = true;
              else codesAreDuplicated = false;
            },
          );
        }
        this.setState({ codesAreDuplicated });
        const { id } = this.props.match.params;
        if (!err && !codesAreDuplicated) {
          values.customer.distributionCenter =
            values.customer.assignedDistributionCenters[0];
          try {
            const isAutomatic =
              this.props.match.params.id === '0'
                ? false
                : values.customer.automatic;
            await this.validateData(
              {
                customer: { ...values.customer, automatic: isAutomatic },
                customerAddress: values.customerAddress,
                fiscalInformation: values.fiscalInformation,
              },
              id,
            );
            window.scroll(0, 0);
            this.props.history.push('/customer');
          } catch (error) {
            Popups.notifyException(error);
          }
        } else {
          console.log('ERROR :', err);
          this.setState({
            errors: err.customerAddress ? err.customerAddress : new Array(0),
            errorsFiscalInformation: err.fiscalInformation
              ? err.fiscalInformation
              : new Array(0),
          });
        }
      },
    );
  };
  /**
   * this method validates if the "id" is not "0" to later use one function or another
   */
  private validateData = async (
    values: CustomerModel,
    id: string,
  ): Promise<void> => {
    const defaultBusinessName: FiscalInformation = {
      rfc: values.customer.rfc,
      businessName: values.customer.businessName,
      formaPago: values.customer.formaPago,
      usoCfdi: values.customer.usoCfdi,
      fiscalRegime: values.customer.fiscalRegime,
      customerCodeComPro: values.customer.customerCodeComPro,
      zipCode: values.customer.zipCode,
      street: values.customer.street,
      externalNumber: values.customer.externalNumber,
      colony: values.customer.colony,
      state: values.customer.state,
      city: values.customer.city,
    };
    let fiscalInformation: FiscalInformation[] = [];
    //delete the item with the information of defaultBusinessName
    let filteredFiscalInformation: FiscalInformation[] = [];
    if (values.fiscalInformation !== undefined) {
      filteredFiscalInformation = values.fiscalInformation.filter(
        (item: FiscalInformation): boolean =>
          item.rfc !== defaultBusinessName.rfc,
      );
    }
    //push it into the empty array filteredFiscalInformation
    fiscalInformation = filteredFiscalInformation;
    fiscalInformation.push(defaultBusinessName);

    const { location } = this.state;

    if (id !== '0') {
      const customer: Customer = {
        ...values.customer,
        id,
        statusCredit: values.customer.statusCredit === true ? 'Incobrable' : '',
      };
      customer.latitude = location.latitude;
      customer.longitude = location.longitude;
      const customerAddress: CustomerAddress[] = values.customerAddress;
      await this.props.editCustomer({
        customer,
        customerAddress,
        fiscalInformation,
      });
    } else {
      const customer: Customer = {
        ...values.customer,
        statusCredit: values.customer.statusCredit === true ? 'Incobrable' : '',
        loginDate: moment(),
      };
      customer.latitude = location.latitude;
      customer.longitude = location.longitude;
      const customerAddress: CustomerAddress[] = values.customerAddress;
      await this.props.editCustomer({
        customer,
        customerAddress,
        fiscalInformation,
      });
    }
    this.props.form.resetFields();
    await this.props.resetCustomer();
  };
  /**
   * This merthod set the value obtained from checkBox that
   * verifies if a customer has a bad debt.
   */
  private setStatusCreditValue = (customer: Customer): boolean | string => {
    return customer ? customer.statusCredit === 'Incobrable' : '';
  };
  /**
   * this method get a customer from g4s in the focus over in the code input
   */
  private getCustomerG4s = (code: React.FocusEvent<HTMLInputElement>): void => {
    if (code.target) {
      if (code.target.value.toString() !== '') {
        this.props.getCustomerG4SByCode(code.target.value.toString());
      }
    }
  };

  private renderCustomerInputCode = (
    invoiceItem: string | number,
    label: string,
    name: string,
    rule: ValidationRule[],
  ): JSX.Element => {
    return (
      <Form.Item hasFeedback label={label}>
        {this.props.form.getFieldDecorator(name, {
          initialValue: invoiceItem,
          rules: rule,
        })(
          <Input
            size="large"
            placeholder={label}
            disabled={
              (!isAllowed([P.CAN_EDIT_CODE]) &&
                this.props.match.params.id !== '0') ||
              !isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS])
            }
            onKeyPress={(e): void => {
              e.key === 'Enter' && e.preventDefault();
            }}
            onBlur={
              name === 'customer.code' ? this.getCustomerG4s : (): void => {}
            }
            readOnly={!isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS])}
          />,
        )}
      </Form.Item>
    );
  };
  /**
   *this method render a custom form item to type Input
   *
   */
  private renderCustomerFormItems = (
    invoiceItem: string | number,
    label: string,
    name: string,
    rule: ValidationRule[],
  ): JSX.Element => {
    return (
      <Form.Item hasFeedback label={label}>
        {this.props.form.getFieldDecorator(name, {
          initialValue: invoiceItem,
          rules: rule,
        })(
          <Input
            size="large"
            placeholder={label}
            disabled={
              name === 'customer.code' && this.props.match.params.id !== '0'
            }
            onBlur={
              name === 'customer.code' ? this.getCustomerG4s : (): void => {}
            }
            readOnly={!isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS])}
          />,
        )}
      </Form.Item>
    );
  };
  /**
   *this method render a custom form item to type Input
   *
   */

  private renderCustomerFormItemsTextArea = (
    invoiceItem: string | number,
    label: string,
    name: string,
    rule: ValidationRule[],
  ): JSX.Element => {
    return (
      <Form.Item hasFeedback label={label}>
        {this.props.form.getFieldDecorator(name, {
          initialValue: invoiceItem,
          rules: rule,
        })(
          <TextArea
            rows={4}
            placeholder={label}
            readOnly={!isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS])}
          />,
        )}
      </Form.Item>
    );
  };
  /**
   *this method render a custom form item to type InputNumber
   *
   */

  private renderCustomerFormItemsNumber = (
    invoiceItem: string | number,
    label: string,
    name: string,
    rule: ValidationRule[],
  ): JSX.Element => {
    return (
      <Form.Item hasFeedback label={label}>
        {this.props.form.getFieldDecorator(name, {
          initialValue: invoiceItem,
          rules: rule,
        })(
          <InputNumber
            size="large"
            placeholder={label}
            style={{ width: '100%' }}
            readOnly={!isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS])}
          />,
        )}
      </Form.Item>
    );
  };

  private onChangeState = async (value: string): Promise<void> => {
    const selectedState = this.state.states.find(
      (g4sState: G4sState): boolean => value === g4sState.name,
    );
    if (selectedState !== undefined) {
      const municipalities = await g4sServices.getG4sMunicipalities(
        selectedState.id,
      );
      this.setState({ municipalities: municipalities.data });
    }
  };

  /**
   *This method render a customer class in the Layout content.
   *
   */
  public render = (): JSX.Element => {
    const {
      form: {
        getFieldDecorator,
        getFieldsValue,
        setFieldsValue,
        getFieldValue,
      },
      customerModel,
      isFetching,
    } = this.props;
    const { customer } = this.props.customerModel;
    const { location } = this.state;
    return (
      <Row>
        <Card bordered={false} className={'customer-card'}>
          <Row>
            <h1>
              <Text strong>
                {isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS])
                  ? 'Llena los campos a continuación para agregar a un cliente'
                  : 'A continuación se muestra la información del cliente.'}
              </Text>
            </h1>
          </Row>
          <Row>
            <Text disabled>
              {isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS])
                ? 'Los campos marcados con * son obligadorios'
                : 'No se cuenta con permiso para editar'}
            </Text>
          </Row>
          <Row>
            <Text>
              {this.props.match.params.id === '0'
                ? ''
                : 'Último Acceso: ' +
                  (customer.loginDate
                    ? moment(customer.loginDate)
                        .format('DD/MM/YYYY HH:mm')
                        .toString()
                    : moment()
                        .format('DD/MM/YYYY HH:mm')
                        .toString())}
            </Text>
          </Row>
        </Card>
        <Form onSubmit={this.handleOnSubmit}>
          <Card className={'customer-card'} title={'Cliente'}>
            <Col {...sizeScreen(5)}>
              {this.renderCustomerInputCode(
                (customer || {}).code !== 0 ? customer.code : '',
                LabelsCustomer.CUSTOMER_CODE,
                'customer.code',
                r.codeRule,
              )}
            </Col>
            <Col {...sizeScreen(11)}>
              {this.renderCustomerFormItems(
                customer ? customer.comercialName : '',
                LabelsCustomer.COMERCIAL_NAME,
                'customer.comercialName',
                r.comercialNameRule,
              )}
            </Col>
            {// If it is credit customer & customer is not aparcero, show discount field
            CUSTOMER_TYPE.CREDIT.includes(getFieldValue('customer.credit')) &&
              ('NA'.includes(getFieldValue('customer.aparceroType')) ||
                'undefined'.includes(
                  getFieldValue('customer.aparceroType'),
                )) && (
                <Col {...sizeScreen(5)}>
                  <Form.Item label={LabelsCustomer.DISCOUNT}>
                    {getFieldDecorator(`customer.discount`, {
                      initialValue: customer ? customer.discount : '',
                      rules: [
                        {
                          required: true,
                          message: MessagesCustomer.DISCOUNT_REQUIRED,
                        },
                      ],
                    })(
                      <InputNumber
                        size="large"
                        placeholder="Ingrese porcentaje"
                        style={{ width: '100%' }}
                        min={0}
                        max={isAllowed([P.EDIT_CUSTOMER_DISCOUNT]) ? 100 : 6}
                        readOnly={!isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS])}
                      />,
                    )}
                  </Form.Item>
                </Col>
              )}
            <Col {...sizeScreen(11)}>
              <Form.Item label={'Centros de distribucion asignados'}>
                {this.props.form.getFieldDecorator(
                  'customer.assignedDistributionCenters',
                  {
                    initialValue:
                      //First set the assgined distribution centers
                      customer && customer.assignedDistributionCenters
                        ? customer.assignedDistributionCenters
                        : customer && customer.distributionCenter
                        ? //If not present, then set the distribution center id
                          [customer.distributionCenter]
                        : //Else set empty
                          [],
                    rules: [
                      {
                        required: true,
                        message: 'Selecciona almenos un centro de distribución',
                      },
                    ],
                  },
                )(
                  <Select
                    size="large"
                    placeholder="Seleccionar uno o más"
                    mode="multiple"
                    allowClear
                    onChange={(value: string[]): void => {
                      let distrCentersIds: string[] = [];
                      value.forEach((id: string): void => {
                        distrCentersIds.push(id);
                      });
                    }}
                    disabled={!isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS])}
                  >
                    {this.props.distributionCenter.map(
                      (item, index): React.ReactNode => (
                        <Select.Option key={item.id} value={item.id}>
                          <div id={'dist_' + index.toString()}>
                            {' '}
                            {item.name}
                          </div>
                        </Select.Option>
                      ),
                    )}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            {!(
              CUSTOMER_TYPE.TRASLATE +
              CUSTOMER_TYPE.SELF_CONSUMPTION +
              CUSTOMER_TYPE.TRASLATE +
              CUSTOMER_TYPE.RETURN
            ).includes(getFieldValue('customer.credit')) ? (
              <Col {...sizeScreen(3)}>
                <Form.Item label={LabelsCustomer.BIRTHDAY}>
                  {getFieldDecorator('customer.birthday', {
                    initialValue: customer
                      ? moment(customer.birthday)
                      : moment(),
                    rules: r.contactBirthdayRule,
                  })(
                    <DatePicker
                      format="YYYY/MM/DD"
                      disabled={!isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS])}
                      allowClear={isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS])}
                    />,
                  )}
                </Form.Item>
              </Col>
            ) : (
              getFieldDecorator('customer.birthday', {
                initialValue: customer ? moment(customer.birthday) : moment(),
              })
            )}
            <Col {...sizeScreen(7)}>
              <Form.Item hasFeedback label={LabelsCustomer.ASSIGNED_USER}>
                {getFieldDecorator('customer.assignedUserId', {
                  initialValue: customer ? customer.assignedUserId : '',
                  rules: [],
                })(
                  <Select
                    showSearch
                    size="default"
                    disabled={!isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS])}
                  >
                    {this.props.users.map(
                      (item: User): React.ReactNode => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name +
                            ' ' +
                            item.lastName1 +
                            ' - ' +
                            item.email}
                        </Select.Option>
                      ),
                    )}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col {...sizeScreen(12)}>
              <Form.Item label={LabelsCustomer.CUSTOMER_TYPE}>
                {getFieldDecorator('customer.credit', {
                  initialValue: customer ? customer.credit : '',
                  rules: [
                    {
                      required: true,
                      message: 'Selecciona una opcion',
                    },
                  ],
                })(
                  <RadioGroup
                    disabled={!isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS])}
                  >
                    <Radio value={'CREDIT'}>Cŕedito</Radio>
                    <Radio value={'CASH'}>Contado</Radio>
                    <Radio value={'SELF_CONSUMPTION'}>Consumo propio</Radio>
                    <Radio value={'TRASLATE'}>Traspaso</Radio>
                    <Radio value={'PREPAID'}>Prepago</Radio>
                    <Radio
                      value={'RETURN'}
                      disabled={!isAllowed([P.CAN_SET_CUSTOMER_TYPE_RETURN])}
                    >
                      Retorno
                    </Radio>
                  </RadioGroup>,
                )}
              </Form.Item>
            </Col>
            {CUSTOMER_TYPE.CREDIT.includes(
              getFieldValue('customer.credit'),
            ) && (
              <Col {...sizeScreen(4)}>
                <Form.Item label={LabelsCustomer.CUSTOMER_APARCERO}>
                  {getFieldDecorator('customer.aparceroType', {
                    initialValue: customer.aparceroType
                      ? customer.aparceroType
                      : 'NA',
                    rules: r.usoCfdiRule,
                  })(
                    <Select
                      size={'large'}
                      disabled={
                        !isAllowed([
                          P.CAN_ADD_AND_EDIT_CUSTOMERS,
                          P.CAN_ASSIGN_APARCEROS,
                        ])
                      }
                    >
                      <Select.Option value={'NA'}>{'No aplica'}</Select.Option>
                      <Select.Option value={'KILO'}>{'Kilos'}</Select.Option>
                      <Select.Option value={'LITRO'}>{'Litros'}</Select.Option>
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            )}
            {(getFieldValue('customer.credit') === CUSTOMER_TYPE.CREDIT ||
              getFieldValue('customer.credit') === CUSTOMER_TYPE.CASH) && (
              <Col {...sizeScreen(5)}>
                <Form.Item label="Cuenta contable">
                  {getFieldDecorator('customer.accountingAccount', {
                    initialValue: customer.accountingAccount
                      ? customer.accountingAccount
                      : '',
                    rules: [
                      {
                        max: 255,
                        whitespace: true,
                        message: 'No se permiten mas de 255 caracteres',
                      },
                    ],
                  })(
                    <Input
                      size="large"
                      placeholder="Cuenta contable"
                      readOnly={!isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS])}
                    />,
                  )}
                </Form.Item>
              </Col>
            )}
            <Col {...sizeScreen(3)}>
              <Form.Item label={LabelsCustomer.DISABLED_CUSTOMER}>
                {getFieldDecorator('customer.isDisabled', {
                  valuePropName: 'checked',
                  initialValue: customer.isDisabled,
                })(
                  <Checkbox
                    className={'disable_customer_checkbox'}
                    disabled={!isAllowed([P.IS_DISABLED])}
                  />,
                )}
              </Form.Item>
            </Col>
          </Card>
          {!(
            CUSTOMER_TYPE.TRASLATE +
            CUSTOMER_TYPE.SELF_CONSUMPTION +
            CUSTOMER_TYPE.TRASLATE +
            CUSTOMER_TYPE.RETURN
          ).includes(getFieldValue('customer.credit')) && (
            <Card className={'customer-card'} title={'Información Fiscal'}>
              <Row>
                <Col {...sizeScreen(11)}>
                  {this.renderCustomerFormItems(
                    customer ? customer.rfc : '',
                    LabelsCustomer.RFC,
                    'customer.rfc',
                    r.rfcRule,
                  )}
                </Col>
                <Col {...sizeScreen(11)}>
                  {this.renderCustomerFormItems(
                    customer ? customer.businessName : '',
                    LabelsCustomer.BUSINESS_NAME,
                    'customer.businessName',
                    r.nameRule,
                  )}
                </Col>
                <Col {...sizeScreen(11)}>
                  <Form.Item label={LabelsCustomer.PAYMENY_FORM}>
                    {getFieldDecorator('customer.formaPago', {
                      initialValue: customer ? customer.formaPago : '',
                      rules: r.formaPagoRule,
                    })(
                      <Select
                        showSearch
                        size="large"
                        disabled={!isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS])}
                      >
                        {formaPago.map(
                          (item, index): React.ReactNode => (
                            <Select.Option key={item.key} value={item.key}>
                              <div id={'formaPago_' + index.toString()}>
                                {item.name}
                              </div>
                            </Select.Option>
                          ),
                        )}
                      </Select>,
                    )}
                  </Form.Item>
                </Col>
                <Col {...sizeScreen(11)}>
                  <Form.Item label={LabelsCustomer.USE_CFDI}>
                    {getFieldDecorator('customer.usoCfdi', {
                      initialValue: customer.usoCfdi ? customer.usoCfdi : 'G03',
                      rules: r.usoCfdiRule,
                    })(
                      <Select
                        showSearch
                        size="large"
                        disabled={!isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS])}
                      >
                        {usoCFDI.map(
                          (item, index): React.ReactNode => (
                            <Select.Option key={item.key} value={item.key}>
                              <div id={'usoCFDI_' + index.toString()}>
                                {item.name}
                              </div>
                            </Select.Option>
                          ),
                        )}
                      </Select>,
                    )}
                  </Form.Item>
                </Col>
                <Col {...sizeScreen(11)}>
                  <Form.Item label={LabelsCustomer.FISCAL_REG}>
                    {getFieldDecorator('customer.fiscalRegime', {
                      initialValue: customer.fiscalRegime
                        ? customer.fiscalRegime
                        : '601',
                      rules: r.fiscalRegimeRule,
                    })(
                      <Select
                        showSearch
                        size="large"
                        disabled={!isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS])}
                      >
                        {regimenFiscal.map(
                          (item, index): React.ReactNode => (
                            <Select.Option key={item.key} value={item.key}>
                              <div id={'fiscalRegime_' + index.toString()}>
                                {item.name}
                              </div>
                            </Select.Option>
                          ),
                        )}
                      </Select>,
                    )}
                  </Form.Item>
                </Col>
                <Col {...sizeScreen(4)}>
                  {this.renderCustomerFormItems(
                    customer ? customer.customerCodeComPro : '',
                    LabelsCustomer.COMERTIAL_CODE,
                    'customer.customerCodeComPro',
                    r.comertialRule,
                  )}
                </Col>
                <Col {...sizeScreen(4)}>
                  <Form.Item hasFeedback label={LabelsCustomer.EMAIL_INVOICE}>
                    {this.props.form.getFieldDecorator(
                      'customer.emailInvoice',
                      {
                        initialValue: customer ? customer.emailInvoice : '',
                        rules: [
                          {
                            required: false,
                            message: 'Ingrese uno o varios correos por favor',
                            pattern: /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},?)+$/,
                          },
                        ],
                      },
                    )(
                      <Input
                        size="large"
                        placeholder={LabelsCustomer.EMAIL_INVOICE}
                        max="250"
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col {...sizeScreen(7)}>
                  <Form.Item label={LabelsCustomer.INVOICE_AUTOMATIC}>
                    {getFieldDecorator('customer.automatic', {
                      initialValue:
                        customer &&
                        customer.automatic === true &&
                        this.props.match.params.id !== '0'
                          ? customer.automatic
                          : false,
                      rules: [
                        {
                          required: true,
                          message: 'Selecciona una opcion',
                        },
                      ],
                    })(
                      <RadioGroup>
                        <Radio value={true}>Si</Radio>
                        <Radio value={false}>No</Radio>
                      </RadioGroup>,
                    )}
                  </Form.Item>
                </Col>
                <Col {...sizeScreen(7)}>
                  <Form.Item label={LabelsCustomer.INVOICE_GROUP_BY_SALE}>
                    {getFieldDecorator('customer.groupSales', {
                      initialValue: customer ? customer.groupSales : '',
                      rules: [
                        {
                          required: true,
                          message: 'Selecciona una opcion',
                        },
                      ],
                    })(
                      <RadioGroup
                        disabled={!isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS])}
                      >
                        <Radio value={true}>Si</Radio>
                        <Radio value={false}>No</Radio>
                      </RadioGroup>,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <FiscalInformationManager
                  customerModel={customerModel}
                  sizeScreen={sizeScreen}
                  getFieldDecorator={getFieldDecorator}
                  setFieldsValue={setFieldsValue}
                  getFieldsValue={getFieldsValue}
                  errors={this.state.errorsFiscalInformation}
                  isReadOnly={!isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS])}
                />
              </Row>
            </Card>
          )}
          {getFieldValue('customer.credit') !==
            CUSTOMER_TYPE.SELF_CONSUMPTION &&
            getFieldValue('customer.credit') !== CUSTOMER_TYPE.RETURN && (
              <Card className={'customer-card'} title={'Domicilio'}>
                <Row>
                  <Col {...sizeScreen(5)}>
                    <Form.Item hasFeedback label={LabelsCustomer.ZIP_CODE}>
                      {this.props.form.getFieldDecorator('customer.zipCode', {
                        initialValue: customer ? customer.zipCode : '',
                        rules: r.zipCodeRule,
                      })(
                        <Input
                          size="large"
                          placeholder={LabelsCustomer.ZIP_CODE}
                          readOnly={!isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS])}
                          onChange={(e): void => {
                            if (e.target.value.length > 4)
                              this.getZipCode(e.target.value);
                          }}
                        />,
                      )}
                    </Form.Item>
                  </Col>
                  <Col {...sizeScreen(11)}>
                    {this.renderCustomerFormItems(
                      customer ? customer.street : '',
                      LabelsCustomer.STREET,
                      'customer.street',
                      r.streetRule,
                    )}
                  </Col>
                  <Col {...sizeScreen(5)}>
                    {this.renderCustomerFormItems(
                      (customer || {}).externalNumber !== '0'
                        ? customer.externalNumber
                        : '',
                      LabelsCustomer.EXTERNAL_NUMBER,
                      'customer.externalNumber',
                      r.externalNumberRule,
                    )}
                  </Col>
                  <Col {...sizeScreen(5)}>
                    <Form.Item
                      hasFeedback
                      label={LabelsCustomer.INTERNAL_NUMBER}
                    >
                      {getFieldDecorator('customer.internalNumber', {
                        rules: r.internalNumberRule,
                        initialValue: customer ? customer.internalNumber : '',
                      })(
                        <Input
                          size="large"
                          placeholder="Número interior"
                          readOnly={!isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS])}
                        />,
                      )}
                    </Form.Item>
                  </Col>
                  <Col {...sizeScreen(11)}>
                    <Form.Item hasFeedback label={LabelsCustomer.COLONY}>
                      {this.props.form.getFieldDecorator('customer.colony', {
                        initialValue: customer ? customer.colony : '',
                        rules: r.colonyRule,
                      })(
                        <AutoComplete
                          dataSource={this.props.zipCode.colonias}
                          style={{ width: '100%' }}
                          placeholder="Colonia"
                          disabled={!isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS])}
                        />,
                      )}
                    </Form.Item>
                  </Col>
                  <Col {...sizeScreen(5)}>
                    {getFieldDecorator(`customer.state`, {
                      initialValue: customer ? customer.state : '',
                      rules: r.stateRule,
                    })}
                    <GenericSelect
                      options={this.state.states}
                      valueVarName={'name'}
                      labelVarName={'name'}
                      action={this.onChangeState}
                      isForm={true}
                      initialValue={customer.state}
                      name={`customer.state`}
                      label={LabelsCustomer.STATE}
                      rule={r.stateRule}
                      getFieldDecorator={getFieldDecorator}
                      isDisabled={!isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS])}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col {...sizeScreen(5)}>
                    {getFieldDecorator(`customer.municipality`, {
                      initialValue: customer ? customer.municipality : '',
                      rules: r.municipalityRule,
                    })}
                    <GenericSelect
                      options={this.state.municipalities}
                      valueVarName={'name'}
                      labelVarName={'name'}
                      action={(value: string): void => console.log(value)}
                      isForm={true}
                      initialValue={customer.municipality}
                      name={`customer.municipality`}
                      label={LabelsCustomer.MUNICIPALITY}
                      rule={r.municipalityRule}
                      getFieldDecorator={getFieldDecorator}
                      isDisabled={!isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS])}
                    />
                  </Col>
                  <Col {...sizeScreen(11)}>
                    {this.renderCustomerFormItems(
                      customer ? customer.city : '',
                      LabelsCustomer.CITY,
                      'customer.city',
                      r.cityRule,
                    )}
                  </Col>
                  <Col {...sizeScreen(5)}>
                    <Form.Item hasFeedback label={LabelsCustomer.PHONE_NUMBER}>
                      {getFieldDecorator('customer.phoneNumber', {
                        rules: r.customerPhoneRule,
                        initialValue: customer
                          ? customer.phoneNumber
                          : undefined,
                      })(
                        <Input
                          maxLength={10}
                          placeholder={LabelsCustomer.PHONE_NUMBER}
                          readOnly={!isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS])}
                          size="large"
                        />,
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col {...sizeScreen(23)}>
                    {this.renderCustomerFormItemsTextArea(
                      customer ? customer.observations : '',
                      LabelsCustomer.OBSERVATIONS,
                      'customer.observations',
                      r.observationsRule,
                    )}
                  </Col>
                </Row>
              </Card>
            )}
          {getFieldValue('customer.credit') === CUSTOMER_TYPE.CREDIT && (
            <Card className={'customer-card'} title={'Crédito'}>
              <Row>
                <Col {...sizeScreen(5)}>
                  {this.renderCustomerFormItemsNumber(
                    (customer || {}).creditDays !== 0
                      ? customer.creditDays
                      : '',
                    LabelsCustomer.CREDIT_DAYS,
                    'customer.creditDays',
                    r.numberRule,
                  )}
                </Col>
                <Col {...sizeScreen(5)}>
                  {this.renderCustomerFormItemsNumber(
                    (customer || {}).creditLimit !== 0
                      ? customer.creditLimit
                      : '',
                    LabelsCustomer.CREDIUT_LIMIT,
                    'customer.creditLimit',
                    r.numberDecimalRule,
                  )}
                </Col>
                <Col {...sizeScreen(3)}>
                  <Form.Item label={LabelsCustomer.BAD_CUSTOMER}>
                    {getFieldDecorator('customer.statusCredit', {
                      valuePropName: 'checked',
                      initialValue: this.setStatusCreditValue(customer),
                    })(
                      <Checkbox
                        className={'bad-customer-checkbox'}
                        disabled={!isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS])}
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col {...sizeScreen(7)}>
                  <Form.Item label={LabelsCustomer.CIE_KEY}>
                    {getFieldDecorator('customer.cieKey', {
                      initialValue: customer.cieKey ? customer.cieKey : '',
                      rules: [
                        {
                          max: 50,
                          whitespace: true,
                          message: MessagesCustomer.FIFTY_CHARACTERS,
                        },
                      ],
                    })(
                      <Input
                        size="large"
                        placeholder={LabelsCustomer.CIE_KEY}
                        disabled={!isAllowed([P.CAN_EDIT_CIE_KEY])}
                        readOnly={!isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS])}
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Ubicación de cobranza">
                    <div style={{ height: '27rem', width: '100%' }}>
                      <GoogleMapReact
                        bootstrapURLKeys={{
                          key: 'AIzaSyCsBQRj84EsjEJDwCWiIf0ZoRQCdqFqwh8',
                        }}
                        defaultCenter={{
                          lat: 21.881478,
                          lng: -102.29456,
                        }}
                        defaultZoom={13}
                        onClick={(value: ClickEventValue): void => {
                          this.setState({
                            location: {
                              latitude: value.lat,
                              longitude: value.lng,
                            },
                          });
                        }}
                      >
                        <Marker
                          lat={location.latitude}
                          lng={location.longitude}
                          name={'Ubicación del cliente'}
                          color="#113c70"
                        />
                      </GoogleMapReact>
                    </div>
                  </Form.Item>
                </Col>
                <Col {...sizeScreen(22)}>
                  <Form.Item hasFeedback label={LabelsCustomer.LOCATION}>
                    {this.props.form.getFieldDecorator('customer.location', {
                      initialValue: customer ? customer.location : '',
                      rules: [
                        {
                          max: 499,
                          whitespace: true,
                          message: 'No se permiten mas de 499 caracteres',
                        },
                      ],
                    })(
                      <Input.TextArea placeholder={LabelsCustomer.LOCATION} />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          )}
          {
            <Card className={'customer-card'} title={'Sucursales'}>
              <CustomerAdresssManager
                customerModel={customerModel}
                sizeScreen={sizeScreen}
                form={this.props.form}
                validator={this.state.codesAreDuplicated}
                errors={this.state.errors}
                canEditCustomer={isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS])}
                canEditUdr={isAllowed([P.CAN_EDIT_UDR])}
                setIndexToSave={this.setIndexUdrToSave}
                isFetching={this.props.isFetching}
              />
            </Card>
          }
          {(getFieldValue('customer.credit') === CUSTOMER_TYPE.CREDIT ||
            getFieldValue('customer.credit') === CUSTOMER_TYPE.CASH ||
            getFieldValue('customer.credit') === CUSTOMER_TYPE.PREPAID) && (
            <Card className={'customer-card'} title={'Datos de acceso'}>
              <Col {...sizeScreen(7)}>
                {this.renderCustomerFormItems(
                  customer ? customer.email : '',
                  LabelsCustomer.EMAIL,
                  'customer.email',
                  getFieldValue('customer.credit') === CUSTOMER_TYPE.CREDIT
                    ? r.emailRule
                    : r.emailRuleNotRequired,
                )}
              </Col>
              {getFieldValue('customer.credit') === CUSTOMER_TYPE.CREDIT && (
                <Col {...sizeScreen(7)}>
                  {this.renderCustomerFormItems(
                    customer ? customer.password : '',
                    LabelsCustomer.PASSWORD,
                    'customer.password',
                    r.passwordRule,
                  )}
                </Col>
              )}
              {getFieldValue('customer.credit') === CUSTOMER_TYPE.CREDIT &&
                hasValue(customer.id) &&
                hasValue(customer.email) &&
                hasValue(customer.password) &&
                isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS]) && (
                  <Col {...sizeScreen(7)}>
                    <div
                      style={{
                        color: '#113c71',
                        fontWeight: 'bold',
                        lineHeight: '39.9999px',
                        verticalAlign: 'middle',
                      }}
                    >
                      {'Enviar datos de acceso: '}
                    </div>
                    <Button
                      id="send_data_access"
                      className="button-customer-serch-style"
                      size="large"
                      onClick={(): void =>
                        this.props.sendEmailWithDataAccess(customer.id)
                      }
                    >
                      <Icon type="mail" />
                      {' Enviar al correo'}
                    </Button>
                  </Col>
                )}
            </Card>
          )}
          <Card className={'customer-card'}>
            {//Fit the cancel button to corner when save button is not visible
            isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS]) ? (
              <Col span={11} />
            ) : (
              <Col span={17} />
            )}
            <Col span={6}>
              <Link to="/customer">
                <Button id="cancel_button" type="default" block size="large">
                  Cancelar
                </Button>
              </Link>
            </Col>
            {isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS]) && (
              <Col span={6} offset={1}>
                <Button
                  {...buttonProps}
                  id="customer_button"
                  disabled={isFetching}
                  className="button-customer-serch-style"
                  block
                  size="large"
                >
                  {isFetching ? 'Loading...' : 'Guardar cliente'}
                </Button>
              </Col>
            )}
          </Card>
        </Form>
      </Row>
    );
  };
}
const mapStateToProps = (states: RootState): StateProps => {
  return {
    customerModel: states.customerState.customer,
    isFetching: states.customerState.isFetching,
    distributionCenter:
      states.customerState.distributionCenter.distributionCenter,
    users: Object.values(states.customerState.users),
    zipCode: states.customerState.zipCode,
  };
};

const mapDispatchToProps = {
  editCustomer,
  getCustomer,
  getZipCode,
  resetCustomer,
  getCustomerG4SByCode,
  cleanCustomer,
  getDistributionCenter,
  sendEmailWithDataAccess,
  getUsers,
  saveUdr,
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create()(CustomerFormComponent));
