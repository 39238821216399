import {
  GATEWAY_API_URL,
  WS_G4S_URL,
  CORE_SERVICE_KEY,
} from '../../boot/config';
import QueryString from 'querystring';
import moment, { Moment } from 'moment';

const createUrl = (path: string): string =>
  `${GATEWAY_API_URL}/${CORE_SERVICE_KEY}/${path}`;

const createwsUrl = (path: string): string =>
  `${WS_G4S_URL}/integrationAPI/${path}`;

const createParams = (params: object): string => {
  const fillParams = {};
  Object.entries(params).forEach((paramsArray: [string, string]): void => {
    if (
      paramsArray[0] &&
      paramsArray[1] !== undefined &&
      paramsArray[1] !== null &&
      paramsArray[1] !== ''
    )
      Object.defineProperty(fillParams, paramsArray[0], {
        enumerable: true,
        writable: false,
        configurable: false,
        value: paramsArray[1],
      });
  });
  return '?' + QueryString.stringify(fillParams);
};

/* Builds a moment date with hours, minutes, seconds & milliseconds */
export const constructMoment = (
  hour: number,
  minute: number,
  second: number,
  millisecond: number,
): Moment => {
  return moment().set({
    hour: hour,
    minute: minute,
    second: second,
    millisecond: millisecond,
  });
};

/* Builds a date at 00:00 hours */
export const getInitialTime = (): Moment => {
  return constructMoment(0, 0, 0, 1);
};

/* Builds a date at 23:59 hours */
export const getEndTime = (): Moment => {
  return constructMoment(23, 59, 59, 999);
};

export const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const createCoreServiceUrl = (path: string): string => createUrl(path);
export const createWsServiceUrl = (path: string): string => createwsUrl(path);
export const createParamsService = (params: object): string =>
  createParams(params);
