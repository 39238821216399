export const getPortfolioStatusText = (status: string): string => {
  switch (status) {
    case 'ABIERTA':
      return 'Abierta';
    case 'ASIGNADA':
      return 'Asignada';
    default:
      return 'Finalizada';
  }
};
