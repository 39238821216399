import {
  Sale,
  ModelSaleToUpdate,
  Price,
  PaymentCondition,
  CustomerAndUDR,
} from 'types/type';
import React, { Component } from 'react';
import { Modal, Select, Row, Col, Spin, Input, InputNumber } from 'antd';
import { initCustomerAndUDR } from 'commons/initTypes';
import { Popups } from 'commons/components/popups/popups';
import { Permissions as P } from 'commons/permissions';
import { getToken, Token, isAllowed } from 'security';
import JwtDecode from 'jwt-decode';
import { getPaymentConditions } from './paymentConditions';

const { confirm } = Modal;

interface CounterProps {
  customerEdit: string;
  visible: boolean;
  onCancel: Function;
  onCreate: (modelSaleToUpdate: ModelSaleToUpdate) => void;
  saleToApply: Sale;
  isFetching: boolean;
  fetchCustomer: (filter: string) => void;
  customersList: CustomerAndUDR[];
  onSave: (modelSaleToUpdate: ModelSaleToUpdate) => void;
  pricesList: Price[];
}

interface States {
  operator: string;
  assistant: string;
  filter: string;
  customerSelected: CustomerAndUDR;
  movementType: string;
  paymentWay: string;
  unitPrice: number;
  total: number;
}

const token =
  getToken() != null ? JwtDecode<Token>(getToken() as string) : null;

class EditSalesForm extends Component<CounterProps> {
  public state: States = {
    operator: '',
    assistant: '',
    filter: '',
    customerSelected:
      this.props.customersList.find(
        (cust: CustomerAndUDR): boolean =>
          cust.customer.code.toString() === this.props.saleToApply.customerCode,
      ) || initCustomerAndUDR,
    movementType: this.props.saleToApply.paymentConditionType,
    paymentWay: this.props.saleToApply.paymentWay,
    unitPrice: this.props.saleToApply.unitPrice,
    total: this.props.saleToApply.total,
  };

  public componentDidMount(): void {
    
  }

  public validatePaymnetCondition = (): number => {
    console.log('VALIDADOR2--->', this.props.saleToApply.totalVolume);
    if (isAllowed([P.CAN_EDIT_PAYMENT_CONDITIONS])) {
      return 0;
    }
    if (
      isAllowed([P.CAN_EDIT_PAYMENT_CONDITION_3LT]) &&
      this.props.saleToApply.totalVolume < 3
    ) {
      return 1;
    }
    return 2;
  };

  public okevent = (): void => {
    if (
      this.state.unitPrice !== 0 ||
      (this.state.movementType !== 'CASH' &&
        this.state.movementType !== 'CREDIT'&&
        this.state.movementType !== 'PREPAID')
    ) {
      const save = this.props.onSave;
      const validate = this.validatePaymnetCondition();
      const customerId =
        this.state.filter === ''
          ? this.props.saleToApply.customerId
          : this.state.filter.substring(0, this.state.filter.indexOf(','));
      const udrCode =
        this.state.filter === ''
          ? this.props.saleToApply.reseptionUnit
          : this.state.filter.substring(
              this.state.filter.indexOf(',') + 1,
              this.state.filter.length,
            );
      const formaPago =
        this.state.movementType !== ''
          ? this.state.movementType
          : this.props.saleToApply.paymentConditionType;
      const paymentWay = this.state.paymentWay;
      /*If payment condition is different from cash and credit, then set 0 as unit price*/
      const unitPrice =
        this.state.movementType !== 'CASH' &&
        this.state.movementType !== 'CREDIT'&&
        this.state.movementType !== 'PREPAID'
          ? 0
          : this.state.unitPrice;
      const saleId = this.props.saleToApply.idG4s;
      const modal: ModelSaleToUpdate = {
        saleId,
        customerId,
        udrCode,
        formaPago,
        unitPrice,
        idUserUdate: token ? token.id : '',
        paymentWay,
      };
      if (
        this.props.saleToApply.total / this.props.saleToApply.totalVolume ===
        this.state.unitPrice
      ) {
        confirm({
          content: (
            <div>
              {
                'El precio unitario es la misma a la anterior esta seguro que desea guardarlo así?'
              }
            </div>
          ),
          onOk(): void {
            if (validate < 2 || formaPago !== 'FAILED') {
              save(modal);
            } else {
              Popups.notifyError(
                'No tienes permiso para establecer ese precio unitario',
              );
            }
          },
          onCancel(): void {},
        });
      } else {
        if (validate < 2 || formaPago !== 'FAILED') {
          save(modal);
        } else {
          Popups.notifyError(
            'No tienes permiso para establecer ese precio unitario',
          );
        }
      }
    } else {
      Popups.notifyError('No puedes Modificar la venta con precio CERO');
    }
  };

  public search = (value: string): void => {
    this.setState({ filter: value.toLowerCase() });
    if (value.length > 2) {
      this.props.fetchCustomer(value.toLowerCase());
    }
  };

  public select = (value: string): void => {
    this.setState({
      filter: value,
      customerSelected:
        this.props.customersList.find(
          (d: CustomerAndUDR): boolean => d.customer.id + d.udr === value,
        ) || initCustomerAndUDR,
    });
  };

  public onChangitemType = (value: string): void => {
    const { movementType } = this.state;
    if (value !== 'CASH' && value !== 'CREDIT' && value !== 'PREPAID') {
      this.setState({ unitPrice: 0, total: 0 });
    } else {
      this.setState({
        unitPrice: this.props.saleToApply.unitPrice,
        total: this.props.saleToApply.total,
      });
    }
    if (movementType !== value) {
      this.setState({ paymentWay: 'EFECTIVO', movementType: value });
    }
  };

  public onChangitemTypeway = (value: string): void => {
    this.setState({ paymentWay: value });
  };

  public render = (): JSX.Element => {
    const { visible, onCancel, saleToApply, isFetching } = this.props;
    return (
      <Modal
        visible={visible}
        title={'Editar Ticket N°: ' + saleToApply.salesFolio}
        okText="Editar"
        cancelText="Cancelar"
        onCancel={onCancel()}
        onOk={this.okevent}
        okButtonProps={{ disabled: isFetching }}
        cancelButtonProps={{ disabled: isFetching }}
      >
        <Row>
          <Row style={{ height: '54px', backgroundColor: '#F1F7FF' }}>
            <Col span={23} offset={1}>
              Seleccione un cliente para asignarle esta venta y asignele un
              precio unitario nuevo:.
            </Col>
          </Row>
          <Row>
            <Col span={23} offset={1}>
              Cliente:
            </Col>
            <Col span={23} offset={1}>
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Selecciona un Cliente"
                optionFilterProp="children"
                showArrow={false}
                onSearch={(value: string): void => this.search(value)}
                value={
                  this.state.filter !== ''
                    ? this.state.filter
                    : 'Nombre: ' +
                      saleToApply.customerName +
                      ' Cod: ' +
                      saleToApply.customerCode +
                      ' Udr: ' +
                      saleToApply.reseptionUnit
                }
                onSelect={this.select}
                loading={isFetching}
              >
                {(this.props.customersList || [initCustomerAndUDR]).map(
                  (item: CustomerAndUDR): React.ReactNode => (
                    <Select.Option
                      key={item.customer.id}
                      value={item.customer.id + ',' + item.udr}
                    >
                      {' '}
                      {'Nom: ' +
                        item.customer.comercialName +
                        ' Cod: ' +
                        item.customer.code +
                        ' UDR: ' +
                        item.udr}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Col>
            <Col span={23} offset={1}>
              <p />
              {'RFC: '}{' '}
              <Input
                placeholder="RFC:"
                value={
                  this.props.customerEdit === 'NA'
                    ? this.state.customerSelected.customer.rfc
                    : saleToApply.customerRfc
                }
                disabled
              />
            </Col>
            <Col span={23} offset={1}>
              <p />
              Condición de Pago:
              <Select
                showSearch
                style={{ width: '100%' }}
                defaultValue={saleToApply.paymentConditionType}
                onSelect={this.onChangitemType}
              >
                {//Get the payment conditions according to the customer, sale and the permission
                getPaymentConditions(
                  this.state.customerSelected.customer.credit,
                  saleToApply.paymentConditionType,
                  isAllowed([
                    P.CAN_ASSIGN_TRANSFER_OR_SELF_CONSUMPTION_TO_SALE,
                  ]),
                ).map(
                  (condition: PaymentCondition): JSX.Element => (
                    <Select.Option value={condition.type} key={condition.type}>
                      {condition.name}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Col>
            {this.state.movementType === 'CASH' && (
              <Col span={23} offset={1}>
                <p />
                Forma de Pago:
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  defaultValue={this.state.paymentWay}
                  onSelect={this.onChangitemTypeway}
                >
                  <Select.Option value="EFECTIVO">Efectivo</Select.Option>
                  <Select.Option value="CREDITO">
                    Tarjeta de crédito
                  </Select.Option>
                  <Select.Option value="DEBITO">
                    Tarjeta de Débito
                  </Select.Option>
                  <Select.Option value="CHEQUE">Cheque</Select.Option>
                  <Select.Option value="TRANSFERENCIA">
                    Transferencia
                  </Select.Option>
                </Select>
              </Col>
            )}
            {this.state.movementType !== 'CASH' &&
            this.state.movementType !== 'CREDIT'&&
            this.state.movementType !== 'PREPAID' ? (
              ''
            ) : (
              <Col span={23} offset={1}>
                <p />
                Precio unitario:
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  defaultValue={this.props.saleToApply.unitPrice}
                  onSelect={(value: number): void =>
                    this.setState({
                      unitPrice: value,
                      total: value * saleToApply.totalVolume,
                    })
                  }
                  disabled={isFetching}
                >
                  {this.props.pricesList.map(
                    (item: Price, index: number): React.ReactNode => (
                      <Select.Option key={item.id} value={item.priceValue}>
                        <div id={'price_' + index.toString()}>
                          {'Zona ' + item.zone + ': $' + item.priceValue}
                        </div>
                      </Select.Option>
                    ),
                  )}
                </Select>
              </Col>
            )}
            <Col span={23} offset={1}>
              <p />
              Cantidad Litros:
              <InputNumber
                size="large"
                disabled
                placeholder={'Litros'}
                style={{ width: '100%' }}
                step={0.11}
                value={saleToApply.totalVolume}
              />
            </Col>
            <Col span={23} offset={1}>
              <p />
              Total:
              <InputNumber
                size="large"
                disabled
                placeholder={'Total'}
                style={{ width: '100%' }}
                step={0.11}
                value={Number(this.state.total.toFixed(2))}
              />
            </Col>
          </Row>
          <Spin spinning={isFetching}></Spin>
        </Row>
      </Modal>
    );
  };
}
export default EditSalesForm;
