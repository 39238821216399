import React from 'react';
import { Row, Col, Card } from 'antd';
import { toNumber } from 'commons/numbersFormat';
import Text from 'antd/lib/typography/Text';
import { TotalsSaleIssued } from 'types/type';

const getIndicators = (totalsSaleIssued: TotalsSaleIssued): JSX.Element => {
  const titles = ['Facturado', 'No facturado', 'Total'];
  let totals: string[] = [];
  //If all totals are zero set lines, else set the values with 2 decimals
  if (
    totalsSaleIssued.totalInvoice === 0 &&
    totalsSaleIssued.totalWithoutInvoice === 0 &&
    totalsSaleIssued.finalTotal === 0
  ) {
    totals = ['-.--', '-.--', '-.--'];
  } else {
    totals = [
      toNumber(totalsSaleIssued.totalInvoice).toString(),
      toNumber(totalsSaleIssued.totalWithoutInvoice).toString(),
      toNumber(totalsSaleIssued.finalTotal).toString(),
    ];
  }

  return (
    <Row>
      {//Show a card for each title item with title and the total respectively
      titles.map(
        (title, index: number): JSX.Element => {
          return (
            <Col
              xs={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              md={{ span: 11, offset: 1 }}
              lg={{ span: 11, offset: 1 }}
              xl={{ span: 8, offset: 0 }}
              xxl={{ span: 8, offset: 0 }}
              key={index}
              style={{ marginTop: '20px' }}
            >
              <Card className="card-statement">
                <Text>{title}</Text>
                <Text>
                  <h3>{'$ ' + totals[index]}</h3>
                </Text>
              </Card>
            </Col>
          );
        },
      )}
    </Row>
  );
};

export default getIndicators;
