import { Moment } from 'moment';

/**
 * End date should never be smaller than start date.
 * If it happends returns true, else false
 */
export const isDateWrong = (
  startValueDate: Moment | undefined,
  endValueDate: Moment | undefined,
): boolean => {
  if (!endValueDate || !startValueDate) {
    return false;
  }
  return endValueDate.valueOf() <= startValueDate.valueOf();
};

/**
 * this const build a style depending value send the size in the screen interact.
 * @param value is a value size in the lg xl and xxl proportion.
 */
export const sizeScreen = (value: number): {} => ({
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 24 },
  lg: { span: value },
  xl: { span: value },
  xxl: { span: value },
});

/**
 * Compare two locations and get the distance in meters between them
 * @param latitude1 Latitude of location one
 * @param longitude1 Longitude of location one
 * @param latitude2 Latitude of location two
 * @param longitude2 Longitude of location two
 * @returns The distance in meters between the two locations
 */
export const getDistanceBetweenLocations = (
  latitude1: number,
  longitude1: number,
  latitude2: number,
  longitude2: number,
): number => {
  const R = 6371e3; // metres
  const fi1 = (latitude1 * Math.PI) / 180; // φ, λ in radians
  const fi2 = (latitude2 * Math.PI) / 180;
  const deltaFi = ((latitude2 - latitude1) * Math.PI) / 180;
  const deltaLambda = ((longitude2 - longitude1) * Math.PI) / 180;

  const a =
    Math.sin(deltaFi / 2) * Math.sin(deltaFi / 2) +
    Math.cos(fi1) *
      Math.cos(fi2) *
      Math.sin(deltaLambda / 2) *
      Math.sin(deltaLambda / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const d = R * c; // in metres
  return d;
};
