/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by David Canul <david@ssf.com.mx>, Jun 2019
 *
 * This component lists all the customers crawled from g4s
 */
import React, { Component, ReactNode } from 'react';
import {
  Row,
  List,
  Pagination,
  Col,
  Icon,
  Table,
  Button,
  Card,
  Select,
  Badge,
  Divider,
  Modal,
  Input,
  InputNumber,
  Tooltip,
  Checkbox,
  Popconfirm,
} from 'antd';
import JwtDecode from 'jwt-decode';
import {
  Customer,
  DistributionCenter,
  CustomerAddress,
  CustomerTotals,
  CustomerFilters,
} from 'types/type';
import { connect } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { CustomerList } from './redux/reducers';
import {
  page,
  saveCustomerBalance,
  deleteCustomerById,
  deleteCustomerBranches,
  downloadCustomersReport,
} from './redux/actions';
import Text from 'antd/lib/typography/Text';
import Search from 'antd/lib/input/Search';
import { Link } from 'react-router-dom';
import noCustomers from 'assets/noCustomers.svg';
import { Strings } from 'commons/strings';
import { toNumber } from 'commons/numbersFormat';
import { ColumnProps } from 'antd/lib/table';
import { isAllowed, Token, getToken } from 'security/index';
import { Permissions as P } from 'commons/permissions';
import { initDistributionCenter } from 'commons/initTypes';
import { Popups } from 'commons/components/popups/popups';
import { DATE_FORMAT, getEndTime, getInitialTime } from 'commons/services';
import moment from 'moment';
import { getDateStatus } from 'routes/users/functions';
const { Column } = Table;

interface DispatchProps {
  page: (filter: CustomerFilters) => void;
  saveCustomerBalance: (
    customerCode: string,
    amount: number,
    userId: string,
    distId: string,
  ) => void;
  deleteCustomerById: (id: string) => void;
  deleteCustomerBranches: (idCustomer: string, idUdr: string) => void;
  downloadCustomersReport: (filters: CustomerFilters) => void;
}
interface StateProps {
  customerPage: CustomerList;
  distributionCenter: DistributionCenter[];
  customerDebt: CustomerTotals;
  customerFilters: CustomerFilters;
  isFetching: boolean;
}

type Props = StateProps & DispatchProps;

class CustomersListComponent extends Component<Props> {
  public state = {
    pagePosition: 1,
    invoiceStatus: 'ALL',
    unitType: 'L',
    startValueDate: moment(),
    endValueDate: moment(),
    endOpen: false,
    pagination: 0,
    filter: '',
    statusCredit: '',
    statusCustomer: '',
    distributionCenter: '',
    visible: false,
    customerCode: '',
    amount: 0,
    pageSize: 10,
  };

  //Get distribution centers from token to set them to filters when user has no permission
  private tokenDistrCenter = JwtDecode<Token>(getToken() as string)
    .distributionCenterId;
  private tokenAssignedDistrCenters: string[] = JwtDecode<Token>(
    getToken() as string,
  ).assingedDistrCenters;

  /**
   * This function execute when the component is mounting and get the first page from
   * customers
   */
  public componentDidMount(): void {
    window.scroll(0, 0);
    this.setState({
      startValueDate: getInitialTime(),
      endValueDate: getEndTime(),
    });
    this.props.page(this.props.customerFilters);
  }
  public deleteCustomerAndUpdatePage = (customerId: string): void => {
    const asyncAction = async (): Promise<void> => {
      await this.props.deleteCustomerById(customerId);
      await this.props.page(this.props.customerFilters);
    };

    asyncAction();
  };
  public deleteCustomerAddressAndUpdatePage = (
    idCustomer: string,
    idUdr: string,
  ): void => {
    const asyncAction = async (): Promise<void> => {
      await this.props.deleteCustomerBranches(idCustomer, idUdr);
      await this.props.page(this.props.customerFilters);
    };
    asyncAction();
  };

  /**
   * This function change page and replaces current position in the pagination
   */
  public handlePaginationChangeNew = (current: number): void => {
    this.setState({ pagination: current });
    this.props.page({
      ...this.props.customerFilters,
      page: current,
    });
  };

  public handlePaginationChange = (
    currentPage: number,
    pageSize?: number,
  ): void => {
    this.setState({ valor: currentPage, pageSize });
    this.props.page({
      ...this.props.customerFilters,
      page: currentPage,
      pageSize: pageSize ? pageSize : 25,
    });
  };

  public handleChangeCustomerStatus = (customerStatus: string): void => {
    this.props.page({
      ...this.props.customerFilters,
      page: 1,
      statusCustomer: customerStatus,
    });
    this.setState({ statusCustomer: customerStatus });
  };
  public handleChangeCreditStatus = (creditStatus: string): void => {
    this.props.page({
      ...this.props.customerFilters,
      page: 1,
      statusCredit: creditStatus,
    });
    this.setState({ statusCredit: creditStatus });
  };
  public handleChangeDistributionCenter = (
    distributionCenter: string,
  ): void => {
    this.props.page({
      ...this.props.customerFilters,
      page: 1,
      distributionCenter,
    });
    this.setState({ distributionCenter });
  };
  /**
   * This function find a customer by name when the value in not null;
   */
  public handleFindCustomer = (customerSearchString: string): void => {
    if (customerSearchString === Strings.EMPTY_STRING || customerSearchString) {
      this.props.page({
        ...this.props.customerFilters,
        page: 1,
        filter: customerSearchString,
      });
      this.setState({ filter: customerSearchString });
    }
  };
  public handleCancel = (): void => {
    this.setState({ visible: false });
  };
  public handleCreate = (): void => {
    if (this.state.customerCode !== '' && this.state.amount !== 0) {
      this.props.saveCustomerBalance(
        this.state.customerCode,
        this.state.amount,
        JwtDecode<Token>(getToken() as string).id,
        JwtDecode<Token>(getToken() as string).distributionCenterId !==
          undefined
          ? JwtDecode<Token>(getToken() as string).distributionCenterId
          : '00000000-0000-0000-0000-000000000000',
      );
      this.props.page(this.props.customerFilters);
      this.setState({ customerCode: '', amount: 0, visible: false });
    } else {
      Popups.notifyError('Faltan datos a capturar');
    }
  };

  public sortDebt = (pagination: any, filters: any, sorter: any): void => {
    filters = this.props.customerFilters;
    filters.sorter = sorter.order === 'descend' ? 'DESC' : 'ASC';

    this.props.page(filters);
  };

  public onChangeinput = (value: string): void => {
    const reg = /^[0-9-]+$/;
    if (reg.test(value) || value === '' || value === '-') {
      this.setState({ customerCode: value });
    }
  };

  public onChangeinputNumber = (value: number | undefined): void => {
    const newValue =
      value !== undefined && value !== null ? value.toString() : (0).toString();
    const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
    if (reg.test(newValue)) {
      this.setState({ amount: parseFloat(newValue !== '' ? newValue : '0') });
    }
  };
  private renderDetails = (item: Customer): JSX.Element => {
    const distributionCenter: DistributionCenter =
      this.props.distributionCenter.find(
        (d: DistributionCenter): boolean => d.id === item.distributionCenter,
      ) || initDistributionCenter;

    //variable to set the time left for the sale to expire
    let time = '';
    if (this.props.customerFilters.withSoonDueDate) {
      const endDate = moment(item.saleSoonDueDate).add(24, 'hours');
      const leftHours = moment(endDate).diff(moment());
      time = moment(leftHours).format('H[ hora(s)] m[ minuto(s)]');
    }
    return (
      <Row>
        <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
          <Text strong>{'Razón Social: '}</Text>
          <Text>{item.comercialName}</Text>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Código: '}</Text>
          <Text strong style={{ color: '#1890ff' }}>
            {item.code}
          </Text>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Estado de cliente: '}</Text>
          <Text type={item.statusCustomer === 'Activo' ? 'warning' : 'danger'}>
            {item.statusCustomer}
          </Text>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Estado de crédito: '}</Text>
          <Badge
            status={
              item.statusCredit === 'Al Corriente'
                ? 'success'
                : item.statusCredit === 'Vencido'
                ? 'warning'
                : item.statusCredit === 'Contado'
                ? 'processing'
                : 'error'
            }
          />
          {item.statusCredit}
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Deuda: '}</Text>
          <Text>{'$' + toNumber(item.debt || 0)}</Text>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Crédito disponible: '}</Text>
          <Text>{'$' + toNumber(item.creditLimit - item.debt || 0)}</Text>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Límite de crédito: '}</Text>
          <Text>{'$' + toNumber(item.creditLimit || 0)}</Text>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Centro de distribución: '}</Text>
          <Text>{distributionCenter.name}</Text>
        </Col>
        {this.props.customerFilters.withSoonDueDate && (
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <Text strong>{'Tiempo restante: '}</Text>
            <Text>{time}</Text>
          </Col>
        )}
      </Row>
    );
  };

  public renderActions = (item: Customer): JSX.Element => {
    return (
      <Row>
        {
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Link
                key="1"
                to={{
                  pathname: `/customer/newCustomer/${item.id}`,
                }}
                style={{ color: 'black' }}
              >
                {//If user has permission, show edit button, else show details button
                isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS]) ? (
                  <Tooltip title="Editar">
                    <Icon type="edit" theme="twoTone" />
                  </Tooltip>
                ) : (
                  <Tooltip title="Ver detalles">
                    <Icon type="eye" theme="twoTone" />
                  </Tooltip>
                )}
              </Link>
              <Divider />
              {isAllowed([P.CAN_VIEW_STATEMENT]) && (
                <Link
                  key="2"
                  to={{
                    pathname: `/statements/${item.code}`,
                  }}
                  style={{ color: 'black' }}
                >
                  <Tooltip title="Ver Edo. cuenta">
                    <Icon type="dollar" theme="twoTone" />
                  </Tooltip>
                </Link>
              )}
            </Col>
          </Col>
        }
      </Row>
    );
  };

  /**
   * Function to get the customers when a checkbox from filters is checked
   */
  private onChangeCheckBox = (value: boolean, property: string): void => {
    const isChecked = value;
    switch (property) {
      case 'withSoonDueDate':
        this.props.page({
          ...this.props.customerFilters,
          page: 1,
          withSoonDueDate: isChecked,
        });
        break;
      case 'withOnlyAssignedCustomers':
        this.props.page({
          ...this.props.customerFilters,
          page: 1,
          withOnlyAssignedCustomers: isChecked,
        });
        break;
      case 'withInvoicePendingPay':
        this.props.page({
          ...this.props.customerFilters,
          page: 1,
          withInvoicePendingPay: isChecked,
        });
        break;
      default:
        this.props.page({
          ...this.props.customerFilters,
          page: 1,
          withoutAutoInvoicing: isChecked,
        });
        break;
    }
  };

  public renderSearchBar = (): React.ReactNode => {
    const { isFetching } = this.props;
    const assignedDistrCenters =
      this.tokenAssignedDistrCenters.length > 0
        ? this.tokenAssignedDistrCenters
        : [this.tokenDistrCenter];
    return (
      <Row style={{ backgroundColor: '#F1F7FF', padding: '15px' }}>
        <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
          <Row>
            <Text strong style={{ color: '#113C71' }}>
              Buscar por cliente o código G4S
            </Text>
          </Row>
          <Row>
            <Search
              size="large"
              placeholder={
                this.props.customerFilters.filter !== ''
                  ? this.props.customerFilters.filter
                  : 'Buscar cliente o Código'
              }
              onSearch={(value): void => this.handleFindCustomer(value)}
              enterButton
              disabled={isFetching}
            />
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={3} xl={3} xxl={3} offset={1}>
          <Row>
            <Text strong style={{ color: '#113C71' }}>
              Centro de Distribución
            </Text>
          </Row>
          <Row>
            <Select
              defaultValue={this.props.customerFilters.distributionCenter}
              size="large"
              style={{ width: '100%' }}
              disabled={isFetching}
              onChange={(value: string): void =>
                this.handleChangeDistributionCenter(value)
              }
            >
              <Select.Option value="">Todos</Select.Option>
              {//When user has permission, show all options
              isAllowed([P.CAN_VIEW_ALL_DISTRIBUTION_CENTERS]) &&
                this.props.distributionCenter &&
                this.props.distributionCenter.map(
                  (
                    distCenter: DistributionCenter,
                    index: number,
                  ): JSX.Element => (
                    <Select.Option value={distCenter.id} key={index}>
                      {distCenter.name}
                    </Select.Option>
                  ),
                )}
              {//When user HAS NO permission, show the options with the assigned distr centers information
              !isAllowed([P.CAN_VIEW_ALL_DISTRIBUTION_CENTERS]) &&
                this.props.distributionCenter &&
                assignedDistrCenters.map(
                  (id: string, index: number): JSX.Element => {
                    const distrCenter = this.props.distributionCenter.find(
                      (center: DistributionCenter): boolean => center.id === id,
                    );
                    return distrCenter ? (
                      <Select.Option value={distrCenter.id} key={index}>
                        {distrCenter.name}
                      </Select.Option>
                    ) : (
                      <div key={index} />
                    );
                  },
                )}
            </Select>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={3} xl={3} xxl={3} offset={1}>
          <Row>
            <Text strong style={{ color: '#113C71' }}>
              Estatus del cliente
            </Text>
          </Row>
          <Row>
            <Select
              defaultValue={this.props.customerFilters.statusCustomer}
              size="large"
              style={{ width: '100%' }}
              onChange={(value: string): void =>
                this.handleChangeCustomerStatus(value)
              }
              disabled={isFetching}
            >
              <Select.Option value="">Todos</Select.Option>
              <Select.Option value="Activo">Activo</Select.Option>
              <Select.Option value="Inactivo">Inactivo</Select.Option>
            </Select>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={3} xl={3} xxl={3} offset={1}>
          <Row>
            <Text strong style={{ color: '#113C71' }}>
              Estatus del Crédito
            </Text>
          </Row>
          <Row>
            <Select
              defaultValue={this.props.customerFilters.statusCredit}
              size="large"
              style={{ width: '100%' }}
              onChange={(value: string): void =>
                this.handleChangeCreditStatus(value)
              }
              disabled={isFetching}
            >
              <Select.Option value="">Todos</Select.Option>

              <Select.Option value="Contado">
                {' '}
                <div>
                  <Badge status="processing" /> Contado
                </div>
              </Select.Option>
              <Select.Option value="Credito">
                {' '}
                <div>
                  <Badge color="pink" /> Crédito
                </div>
              </Select.Option>
              <Select.Option value="Al Corriente">
                {' '}
                <div>
                  <Badge status="success" /> Al Corriente
                </div>
              </Select.Option>
              <Select.Option value="Vencido">
                {' '}
                <div>
                  <Badge status="warning" /> Vencido
                </div>
              </Select.Option>
              <Select.Option value="Incobrable">
                {' '}
                <div>
                  <Badge status="error" /> Incobrable
                </div>
              </Select.Option>
            </Select>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={5} xl={5} xxl={5} offset={1}>
          <Row>
            <Checkbox
              onChange={(event): void => {
                this.onChangeCheckBox(event.target.checked, 'withSoonDueDate');
              }}
              style={{ color: '#113C71' }}
              defaultChecked={this.props.customerFilters.withSoonDueDate}
              disabled={isFetching}
            >
              Mostrar clientes con facturas próximas a vencer
            </Checkbox>
          </Row>
          <Row>
            <Checkbox
              onChange={(event): void => {
                this.onChangeCheckBox(
                  event.target.checked,
                  'withAutoInvoicing',
                );
              }}
              style={{ color: '#113C71', marginTop: '10px' }}
              defaultChecked={this.props.customerFilters.withoutAutoInvoicing}
              disabled={isFetching}
            >
              Ver sólo clientes sin autofacturación
            </Checkbox>
          </Row>
          <Row>
            <Checkbox
              onChange={(event): void => {
                this.onChangeCheckBox(
                  event.target.checked,
                  'withOnlyAssignedCustomers',
                );
              }}
              style={{ color: '#113C71', marginTop: '10px' }}
              defaultChecked={
                this.props.customerFilters.withOnlyAssignedCustomers
              }
              disabled={isFetching}
            >
              Ver sólo mis clientes asignados
            </Checkbox>
          </Row>
          <Row>
            <Checkbox
              onChange={(event): void => {
                this.onChangeCheckBox(
                  event.target.checked,
                  'withInvoicePendingPay',
                );
              }}
              style={{ color: '#113C71', marginTop: '10px' }}
              defaultChecked={this.props.customerFilters.withInvoicePendingPay}
              disabled={isFetching}
            >
              Ver clientes con facturas pendientes de pago
            </Checkbox>
          </Row>
        </Col>
      </Row>
    );
  };
  public renderEmpty = (): React.ReactNode => {
    return (
      <Card className="customer-main-card">
        <Card
          bordered={false}
          className="login-card"
          cover={
            <div className="center-items-card">
              <Row>
                <img src={noCustomers} alt="No customer" />
              </Row>
              <Row>
                <Text>Sin clientes</Text>
              </Row>
              <Row>
                {isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS]) && (
                  <Link
                    key="1"
                    to={{
                      pathname: '/customer/newCustomer/0',
                    }}
                  >
                    <Button type="primary">{'+ Agregar Cliente'}</Button>
                  </Link>
                )}
              </Row>
            </div>
          }
        />
      </Card>
    );
  };

  public addressListContent = (
    idCustomer: string,
  ): ColumnProps<CustomerAddress>[] => {
    return [
      {
        title: 'Codigo de sucursal',
        dataIndex: 'code',
        key: 'code',
        width: '250px',
      },

      {
        title: 'Descripción',
        dataIndex: 'description',
        key: 'description',
        width: '250px',
      },

      {
        title: 'Acciones',
        render: (record: Customer): ReactNode => (
          <div>
            {isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS]) && (
              <Link
                key="1"
                to={{
                  pathname: `/customerAddress/addContact/${record.id}`,
                }}
                style={{ color: 'black' }}
              >
                <Tooltip title="Agregar/Editar contacto">
                  <Icon type="edit" theme="twoTone" />
                </Tooltip>
              </Link>
            )}
            <Divider type="vertical" />
            {isAllowed([P.CAN_DELETE_CUSTOMER]) && (
              <Tooltip title="Eliminar UDR">
                <Popconfirm
                  title="¿Seguro que quiere borrar la Udr?"
                  onConfirm={(): void =>
                    this.deleteCustomerAddressAndUpdatePage(
                      idCustomer,
                      record.id,
                    )
                  }
                  okText="Si"
                  cancelText="No"
                >
                  <Icon
                    style={{ color: 'black' }}
                    type="delete"
                    theme="twoTone"
                  />
                </Popconfirm>
              </Tooltip>
            )}
          </div>
        ),
      },
    ];
  };

  public render = (): React.ReactNode => {
    return (
      <div>
        {this.renderSearchBar()}
        {this.props.customerPage.content !== undefined &&
        (this.props.customerPage.content.length || []) < 1 ? (
          <Row>{this.renderEmpty()}</Row>
        ) : (
          <Row>
            <Row style={{ backgroundColor: '#F1F7FF' }}>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={{ span: 7, offset: 16 }}
                xxl={{ span: 7, offset: 16 }}
              >
                <p />
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  {isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS]) && (
                    <Link
                      key="1"
                      to={{
                        pathname: '/customer/newCustomer/0',
                      }}
                    >
                      <Button type="primary">{'+ Agregar Cliente'}</Button>
                    </Link>
                  )}
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  {isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS]) && (
                    <Tooltip
                      title={`Descargar en Excel registros con todos los clientes listados`}
                      placement="topRight"
                    >
                      <Button
                        type="primary"
                        shape="round"
                        size={'default'}
                        onClick={(): void => {
                          const downloadReport = async (): Promise<void> => {
                            await this.props.downloadCustomersReport(
                              this.props.customerFilters,
                            );
                          };
                          downloadReport();
                        }}
                        disabled={this.props.isFetching}
                      >
                        <Icon type={'download'} />
                        {'Reporte Clientes'}
                      </Button>
                    </Tooltip>
                  )}
                </Col>
                <Col xs={12} sm={12} md={12} lg={12}>
                  {'\u00A0\u00A0\u00A0\u00A0'}
                  {isAllowed([P.CAN_INIT_DEBT]) && (
                    <Link
                      key="1"
                      to={{}}
                      onClick={(): void => this.setState({ visible: true })}
                    >
                      <Button type="primary">
                        Inicializar Saldos
                        <Icon type="dollar" />
                      </Button>
                    </Link>
                  )}
                </Col>
              </Col>
            </Row>
            <Row>
              <p />
            </Row>
            <Row>
              <Col
                xs={{ span: 23, offset: 1 }}
                sm={{ span: 23, offset: 1 }}
                md={{ span: 11, offset: 1 }}
                lg={{ span: 11, offset: 1 }}
                xl={{ span: 6, offset: 0 }}
                xxl={{ span: 6, offset: 0 }}
              >
                <Card className="card-statement">
                  <Text>{'Deuda'}</Text>
                  <Text>
                    <h3>
                      {'$ ' + toNumber(this.props.customerDebt.totalDebt, true)}
                    </h3>
                  </Text>
                </Card>
              </Col>
              <Col
                xs={{ span: 23, offset: 1 }}
                sm={{ span: 23, offset: 1 }}
                md={{ span: 11, offset: 1 }}
                lg={{ span: 11, offset: 1 }}
                xl={{ span: 6, offset: 0 }}
                xxl={{ span: 6, offset: 0 }}
              >
                <Card className="card-statement">
                  <Text>{'Límite de Crédito'}</Text>
                  <Text>
                    <h3>
                      {'$ ' +
                        toNumber(
                          this.props.customerDebt.totalCreditLimit,
                          true,
                        )}
                    </h3>
                  </Text>
                </Card>
              </Col>
            </Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={0} xxl={0}>
              <Card bordered={false}>
                <List
                  dataSource={this.props.customerPage.content}
                  renderItem={(item: Customer, index: number): JSX.Element => {
                    let rowStyle = '';
                    if (!item.automatic) rowStyle = 'grey-table-row';
                    return (
                      <List.Item>
                        <List.Item.Meta
                          key={index}
                          description={
                            <Row
                              className={rowStyle}
                              style={{ padding: '10px', margin: '-5px' }}
                            >
                              <Col span={2}>{this.renderActions(item)}</Col>
                            </Row>
                          }
                        />
                      </List.Item>
                    );
                  }}
                />
              </Card>
            </Col>
            <p />

            <Col xs={0} sm={0} md={0} lg={0} xl={24} xxl={24}>
              <Card bordered={false}>
                <Table
                  className={'custom-table1'}
                  pagination={false}
                  dataSource={this.props.customerPage.content}
                  onChange={this.sortDebt}
                  expandedRowRender={(record): JSX.Element => (
                    <Table
                      className={'custom-table'}
                      dataSource={record.customerAddresses}
                      pagination={false}
                      columns={this.addressListContent(record.id)}
                    />
                  )}
                  rowClassName={(record): string =>
                    !record.automatic ? 'grey-table-row' : ''
                  }
                  rowKey={(record): string => record.id}
                >
                  <Column
                    title="Cliente"
                    dataIndex="businessName"
                    render={(text: string, record: Customer): string => {
                      return record.comercialName;
                    }}
                  />
                  <Column title="Código G4S" dataIndex="code" />
                  <Column
                    title="Estatus cliente"
                    dataIndex="statusCustomer"
                    render={(text: string): JSX.Element => {
                      return (
                        <Text
                          strong
                          type={text === 'Activo' ? 'warning' : 'danger'}
                        >
                          {text}
                        </Text>
                      );
                    }}
                  />
                  <Column
                    title="Estatus crédito"
                    dataIndex="statusCredit"
                    render={(text: string): JSX.Element => {
                      return (
                        <div>
                          <Badge
                            status={
                              text === 'Al Corriente'
                                ? 'success'
                                : text === 'Vencido'
                                ? 'warning'
                                : text === 'Contado'
                                ? 'processing'
                                : 'error'
                            }
                          />
                          {text}
                        </div>
                      );
                    }}
                  />
                  <Column
                    title="Descuento"
                    dataIndex="discount"
                    render={(text: number): React.ReactNode => {
                      return toNumber(text || 0) + '%';
                    }}
                  />
                  <Column
                    title="Deuda"
                    dataIndex="debt"
                    key="debt"
                    render={(
                      text: number,
                      record: Customer,
                    ): React.ReactNode => {
                      return '$' + toNumber(record.debt || 0);
                    }}
                    sorter={(a: any, b: any) => a.debt - b.debt}
                    defaultSortOrder={'descend'}
                  />
                  <Column
                    title="Crédito disponible"
                    dataIndex="balance"
                    render={(
                      text: number,
                      record: Customer,
                    ): React.ReactNode => {
                      return (
                        '$' + toNumber(record.creditLimit - record.debt || 0)
                      );
                    }}
                  />
                  <Column
                    title="Límite de credito"
                    dataIndex="creditLimit"
                    render={(text: number): React.ReactNode => {
                      return '$' + toNumber(text || 0);
                    }}
                  />
                  <Column
                    title="Centro de Distribución"
                    dataIndex="distributionCenter"
                    render={(text: string): JSX.Element => {
                      const x: DistributionCenter =
                        this.props.distributionCenter.find(
                          (d: DistributionCenter): boolean => d.id === text,
                        ) || initDistributionCenter;
                      return <div>{x.name}</div>;
                    }}
                  />
                  <Column
                    title="Fecha última carga"
                    dataIndex="lastPurchaseDate"
                    render={(date: string): JSX.Element => {
                      return date !== undefined && date !== null ? (
                        <Tooltip title={moment(date).format(DATE_FORMAT)}>
                          <Icon type={'calendar'} />
                          {' ' + getDateStatus(moment(date))}
                        </Tooltip>
                      ) : (
                        <div>
                          <Icon type={'calendar'} />
                          {' Desconocida'}
                        </div>
                      );
                    }}
                  />
                  {this.props.customerFilters.withSoonDueDate && (
                    <Column
                      title="Tiempo restante"
                      dataIndex="saleSoonDueDate"
                      render={(text: string): React.ReactNode => {
                        let time = '';
                        if (text !== null) {
                          /*add 24 hours to sale date and 
                          get the difference between end date and current*/
                          const endDate = moment(text).add(24, 'hours');
                          const leftHours = moment(endDate).diff(moment());
                          time = moment(leftHours).format(
                            'H[ hora(s)] m[ minuto(s)]',
                          );
                        }
                        return time;
                      }}
                    />
                  )}
                  <Column
                    title="Acciones"
                    dataIndex="id"
                    render={(text: string, record: Customer): JSX.Element => {
                      return (
                        <div>
                          <Link
                            key="1"
                            to={{
                              pathname: `/customer/newCustomer/${text}`,
                            }}
                            style={{ color: 'black' }}
                          >
                            {//If user has permission, show edit button, else show details button
                            isAllowed([P.CAN_ADD_AND_EDIT_CUSTOMERS]) ||
                            isAllowed([P.CAN_EDIT_UDR]) ? (
                              <Tooltip title="Editar">
                                <Icon type="edit" theme="twoTone" />
                              </Tooltip>
                            ) : (
                              <Tooltip title="Ver detalles">
                                <Icon type="eye" theme="twoTone" />
                              </Tooltip>
                            )}
                          </Link>
                          <Divider type="vertical" />
                          {isAllowed([P.CAN_VIEW_STATEMENT]) && (
                            <Link
                              key="2"
                              to={{
                                pathname: `/statements/${record.code}`,
                              }}
                              style={{ color: 'black' }}
                            >
                              <Tooltip title="Ver Edo. cuenta">
                                <Icon type="dollar" theme="twoTone" />
                              </Tooltip>
                            </Link>
                          )}
                          <Divider type="vertical" />
                          {isAllowed([P.CAN_DELETE_CUSTOMER]) && (
                            <Tooltip title="Eliminar cliente">
                              <Popconfirm
                                title="¿Seguro que quiere borrar al cliente?"
                                onConfirm={(): void =>
                                  this.deleteCustomerAndUpdatePage(record.id)
                                }
                                okText="Si"
                                cancelText="No"
                              >
                                <Icon
                                  style={{ color: 'black' }}
                                  type="delete"
                                  theme="twoTone"
                                />
                              </Popconfirm>
                            </Tooltip>
                          )}
                        </div>
                      );
                    }}
                  />
                  <Column width={1} />
                </Table>
              </Card>
            </Col>
            <Pagination
              pageSizeOptions={['25', '50', '75', '100']}
              showSizeChanger
              current={(this.props.customerPage.number || 0) + 1}
              total={this.props.customerPage.totalElements}
              onShowSizeChange={this.handlePaginationChange}
              onChange={this.handlePaginationChangeNew}
              defaultPageSize={25}
              disabled={this.props.isFetching}
            />
          </Row>
        )}
        <Modal
          visible={this.state.visible}
          title={'Inicializar Saldos'}
          okText="Iniciar"
          cancelText="Cancelar"
          onCancel={(): void => this.handleCancel()}
          onOk={(): void => this.handleCreate()}
        >
          <Input
            placeholder="Código de Cliente"
            value={this.state.customerCode}
            onChange={({ target: { value } }): void =>
              this.onChangeinput(value)
            }
            style={{ width: 200 }}
          />
          {'\u00A0\u00A0\u00A0\u00A0'}
          <InputNumber
            placeholder="Monto"
            value={this.state.amount}
            onChange={(value: number | undefined): void =>
              this.onChangeinputNumber(value)
            }
          />
        </Modal>
      </div>
    );
  };
}

const mapStateToProps = (states: RootState): StateProps => {
  return {
    customerPage: states.customerState.customerList,
    distributionCenter:
      states.customerState.distributionCenter.distributionCenter,
    customerDebt: states.customerState.customerDebt,
    customerFilters: states.customerState.customerFilters,
    isFetching: states.customerState.isFetching,
  };
};

const mapDispatchToProps = {
  page,
  saveCustomerBalance,
  deleteCustomerById,
  deleteCustomerBranches,
  downloadCustomersReport,
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(CustomersListComponent);
