import React from 'react';
import { addIncognitMode } from '../../security/';
import { Modal } from 'antd';

class IncognitMode extends React.PureComponent {
  public state = {
    redirect: false,
    modal1Visible: false,
    modal2Visible: false,
  };

  public setModal2Visible(modal2Visible: boolean): void {
    this.setState({ modal2Visible });
  }

  public render = (): React.ReactNode => {
    addIncognitMode();
    window.location.reload();
    return (
      <Modal
        title="Vertically centered modal dialog"
        centered
        visible={this.state.modal2Visible}
        onOk={(): void => this.setModal2Visible(false)}
        onCancel={(): void => this.setModal2Visible(false)}
      >
        <p>some contents...</p>
        <p>some contents...</p>
        <p>some contents...</p>
      </Modal>
    );
  };
}

export default IncognitMode;
