/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by David Canul <david@ssf.com.mx>, Jun 2019
 *
 *
 */

import { combineReducers, Reducer } from 'redux';
import { Statement, Payment, Acount, Sale } from 'types/type';
import { RootState } from 'store/configureStore';
import {
  StatementAction,
  BEGIN_FETCH,
  FETCH_COMPLETED,
  SET_STATEMENTS,
  SET_STATEMENTS_STATUS,
  SET_STATEMENT,
  SET_SEARCH_STATEMENTS,
  SET_STATEMENT_SALES,
  RESET_STATEMENT_SALES,
  SET_SINGLE_STATEMENT_SALE,
  RESET_SINGLE_STATEMENT_SALE,
} from './types';
import { initPayment, initAcountStatus, initSale } from 'commons/initTypes';

export interface StatementI {
  statement: Statement;
}

export interface StatementList {
  content?: Statement[];
  number?: number;
  statementElements?: number;
  totalElements?: number;
  totalPages?: number;
}
/*
export interface Statement {
  statement: {};
}*/

export interface AcountStatus {
  acountStatus: AcountStatus;
}

export interface StatementState {
  readonly statementList: StatementList;
  readonly statementSearchList: Statement[];
  readonly acountStatus: Acount;
  readonly isFetching: boolean;
  readonly payment: Payment;
  readonly Acount: Acount;
  readonly statementSales: Sale[];
  readonly singleStatementSale: Sale;
}

const statementList: Reducer<StatementList> = (
  state: StatementList = { content: [] },
  action,
): StatementList => {
  if (action.type === SET_STATEMENTS) {
    return { ...state, ...action.statementList };
  }
  return state;
};

const statementSearchList: Reducer<Statement[]> = (
  state: Statement[] = [],
  action,
): Statement[] => {
  if (action.type === SET_SEARCH_STATEMENTS) {
    return { ...action.statementSearchList };
  }
  return state;
};

const acountStatus: Reducer<Acount> = (
  state: Acount = initAcountStatus,
  action,
): Acount => {
  if (action.type === SET_STATEMENTS_STATUS) {
    return { ...state, ...action.acountStatus };
  }
  return state;
};

const statementSave: Reducer<Payment> = (
  state: Payment = initPayment,
  action,
): Payment => {
  if (action.type === SET_STATEMENT) {
    return { ...state, ...action };
  }
  return state;
};

const isFetching: Reducer<boolean> = (
  state = false,
  action: StatementAction,
): boolean => {
  switch (action.type) {
    case BEGIN_FETCH:
      return true;
    case FETCH_COMPLETED:
      return false;
    default:
      return state;
  }
};

const statementSales: Reducer<Sale[]> = (
  state: Sale[] = [],
  action,
): Sale[] => {
  switch (action.type) {
    case SET_STATEMENT_SALES:
      return { ...state, ...action.statementSales };
    case RESET_STATEMENT_SALES:
      return [];
    default:
      return state;
  }
};

const singleStatementSale: Reducer<Sale> = (
  state: Sale = initSale,
  action,
): Sale => {
  switch (action.type) {
    case SET_SINGLE_STATEMENT_SALE:
      return { ...state, ...action.singleStatementSale };
    case RESET_SINGLE_STATEMENT_SALE:
      return initSale;
    default:
      return state;
  }
};

export const getIsFetching = (store: RootState): boolean =>
  store.statementState.isFetching;

export default combineReducers<StatementState>({
  statementSearchList: statementSearchList,
  statementList: statementList,
  statementSave: statementSave,
  acountStatus: acountStatus,
  isFetching,
  statementSales,
  singleStatementSale,
});
