/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Hiram Padilla <hiram@ssf.com.mx>, February 2021
 *
 * This file contains the redux reducers for Cylinder Settlements & Sales
 */
import { initCylinderFilters, initCylinderSale } from 'commons/initTypes';
import { combineReducers, Reducer } from 'redux';
import { RootState } from 'store/configureStore';
import {
  CylinderEquipment,
  CylinderFilters,
  CylinderSale,
  CylinderSettlement,
  DistributionCenter,
  Price,
} from 'types/type';
import {
  BEGIN_FETCH,
  CylinderSettlementAction,
  FETCH_COMPLETED,
  RESET_CYLINDER_SALES,
  SET_CYLINDER_EQUIPMENTS,
  SET_CYLINDER_FILTERS,
  SET_CYLINDER_SALES,
  SET_CYLINDER_SETTLEMENTS,
  SET_DISTRIBUTION_CENTERS,
  SET_PRICES,
} from './types';

export interface CylinderSettlementList {
  content?: CylinderSettlement[];
  number?: number;
  totalElements?: number;
}

export interface CylindersState {
  readonly settlements: CylinderSettlementList;
  readonly sales: CylinderSale[];
  readonly isFetching: boolean;
  readonly distributionCenters: DistributionCenter[];
  readonly equipments: CylinderEquipment[];
  readonly prices: Price[];
  readonly cylinderFilters: CylinderFilters;
}

const isFetching: Reducer<boolean> = (
  state = false,
  action: CylinderSettlementAction,
): boolean => {
  switch (action.type) {
    case BEGIN_FETCH:
      return true;
    case FETCH_COMPLETED:
      return false;
    default:
      return state;
  }
};

const settlements: Reducer<CylinderSettlementList> = (
  state: CylinderSettlementList = { content: [] },
  action,
): CylinderSettlementList => {
  if (action.type === SET_CYLINDER_SETTLEMENTS) {
    return { ...state, ...action.settlements };
  }
  return state;
};

const sales: Reducer<CylinderSale[]> = (
  state: CylinderSale[] = [],
  action,
): CylinderSale[] => {
  switch (action.type) {
    case SET_CYLINDER_SALES:
      return { ...state, ...action.sales };
    case RESET_CYLINDER_SALES:
      return [initCylinderSale];
    default:
      return state;
  }
};

const distributionCenters: Reducer<DistributionCenter[]> = (
  state: DistributionCenter[] = [],
  action,
): DistributionCenter[] => {
  if (action.type === SET_DISTRIBUTION_CENTERS) {
    return { ...state, ...action.distributionCenters };
  }
  return state;
};

const equipments: Reducer<CylinderEquipment[]> = (
  state: CylinderEquipment[] = [],
  action,
): CylinderEquipment[] => {
  if (action.type === SET_CYLINDER_EQUIPMENTS) {
    return { ...state, ...action.equipments };
  }
  return state;
};

const prices: Reducer<Price[]> = (state: Price[] = [], action): Price[] => {
  if (action.type === SET_PRICES) {
    return { ...state, ...action.prices };
  }
  return state;
};

const cylinderFilters: Reducer<CylinderFilters> = (
  state: CylinderFilters = initCylinderFilters,
  action,
): CylinderFilters => {
  if (action.type === SET_CYLINDER_FILTERS) {
    return { ...state, ...action.cylinderFilters };
  }
  return state;
};

export const getIsFetching = (store: RootState): boolean =>
  store.cylindersState.isFetching;

export default combineReducers<CylindersState>({
  isFetching,
  settlements,
  sales,
  distributionCenters,
  equipments,
  prices,
  cylinderFilters,
});
