/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Ricardo Sansores <ricardo@ssf.mx>, May 2019
 *
 * This file define all the Actions and Action Creators that
 * can be sent to a reducer for Invoices management.
 */

import SaleService from 'services/sale';
import InvoiceService from 'services/invoice';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Invoice, InvoicingPortalFiscalInfo, Sale } from 'types/type';
import { parseApiError } from 'commons/error';
import { notification } from 'antd';
import {
  InvoiceAction,
  ADD_SALE_TO_STAGING_AREA,
  BEGIN_FETCH,
  FETCH_COMPLETED,
  CLEAN_STAGING_AREA,
  REMOVE_SALE_FROM_STAGING_AREA,
  SET_FISCAL_DATA,
} from 'store/invoice/types';
import { getIsFetching } from './reducers';
import { RootState } from 'store/configureStore';

/**
 * The services that are required by Invoice Component.
 * Sale is used to adquire the Sales that must be issued
 * and invoices to persist and stamp the invoice.
 */
const saleService = new SaleService();
const invoiceService = new InvoiceService();

/**
 * Just an alias because this type is quite long that stands
 * for Invoice Thunk Action and Invoice DispatchAction
 */
type ITA = ThunkAction<Promise<void>, RootState, {}, InvoiceAction>;
type IDA = ThunkDispatch<{}, {}, InvoiceAction>;
/**
 * This action creator is not public because it should be used
 * automatically by other action creators that need fetch data from
 * backend.
 */
const startFetch = (): InvoiceAction => {
  return { type: BEGIN_FETCH };
};

/**
 * This action creator is not public beca1use it should be used
 * automatically by other action creators that need fetch data from
 * backend.
 */
const endFetch = (): InvoiceAction => {
  return { type: FETCH_COMPLETED };
};

/**
 * This action creator takes the IUS of a ticket and the total
 * try to fetch the sale from the backend and dispatch the correct
 * action depending on the backend response.
 */
export const addSaleToStagingArea = (
  ius: string,
  total: number,
  clean: boolean,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  //Prevent double submits and race conditions. Only one fetch at a time allowed per user.
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  // FIXME This try and catch should be deleted. Do not control
  // flow with exceptions
  try {
    if (clean) {
      dispatch({ type: CLEAN_STAGING_AREA, sale: null });
    }
    const response = await saleService.getbyiUSCodeandTotal(ius, total);
    dispatch(startFetch());
    dispatch({ type: ADD_SALE_TO_STAGING_AREA, sale: response });
    dispatch(endFetch());
  } catch (error) {
    dispatch(endFetch());
    throw error;
  }
};

/**
 * This action remove takes the sale of a ticket and remove it from the list in StagingArea
 */
export const removeSaleFromStagingArea = (sale: Sale): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  //Prevent double submits and race conditions. Only one fetch at a time allowed per user.
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }

  try {
    dispatch(startFetch());
    dispatch({ type: REMOVE_SALE_FROM_STAGING_AREA, sale });
    dispatch(endFetch());
  } catch (error) {
    dispatch(endFetch());
    throw error;
  }
};

/**
 * This action save in the state the Fiscal Data typed
 */
export const setFiscalData = (
  fiscalInfo: InvoicingPortalFiscalInfo,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  //Prevent double submits and race conditions. Only one fetch at a time allowed per user.
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }

  try {
    dispatch(startFetch());
    dispatch({ type: SET_FISCAL_DATA, fiscalInformation: fiscalInfo });
    dispatch(endFetch());
  } catch (error) {
    dispatch(endFetch());
  }
};

/**
 * This action constructor takes an Invoice and
 * Saves it in the database thru the backend service.
 * Then calls the correct reducer to update state.
 */
export const saveInvoce = (
  invoice: Invoice,
  fiscalInformation: InvoicingPortalFiscalInfo,
): ITA => async (dispatch: IDA): Promise<void> => {
  try {
    dispatch(startFetch());
    await invoiceService.saveInvoice(invoice, fiscalInformation);
    dispatch(endFetch());
  } catch (error) {
    dispatch(endFetch());
    notification.error(parseApiError(error));
    throw error;
  }
};

/**
 * Maybe there's a way to avoid writing manually this,
 * but the intention is to make explicit what are the action
 * creators that are being exported so later on can be added to
 * the dispatch props in the React Component
 */
export interface InvoiceActionConstructors {
  addSaleToStagingArea: (
    iUSCode: string,
    total: number,
    clean: boolean,
  ) => void;
  removeSaleFromStagingArea: (sale: Sale) => void;
  setFiscalData: (fiscalInfo: InvoicingPortalFiscalInfo) => void;
  saveInvoce: (
    invoice: Invoice,
    fiscalInformation: InvoicingPortalFiscalInfo,
  ) => void;
  getFiscalInformation: (rfc: string) => void;
}

export const getFiscalInformation = (rfc: string): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  //Prevent double submits and race conditions. Only one fetch at a time allowed per user.
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }

  try {
    const response: InvoicingPortalFiscalInfo = await invoiceService.getFiscalInformationByRfc(
      rfc,
    );
    if (response.id === null) {
      return;
    }
    dispatch(startFetch());
    dispatch({ type: SET_FISCAL_DATA, fiscalInformation: response });
    dispatch(endFetch());
  } catch (error) {
    dispatch(endFetch());
  }
};
