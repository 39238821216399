/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by David Canul <david@ssf.com.mx>, May 2019
 *
 *
 */
import {
  Sale,
  DistributionCenter,
  Equipment,
  SaleFilters,
  TotalsSaleIssued,
} from 'types/type';

/*
 * Action to add a sale to list component
 */
export const SET_SALE = 'SET_SALE';
export const SET_TOTAL = 'SET_TOTAL';
export const SET_TOTAL_DASHBOARD = 'SET_TOTAL_DASHBOARD';
export const SET_SALE_LIST = 'SET_SALE_LIST';
export const GET_DISTRIBUTION_CENTER = 'GET_DISTRIBUTION_CENTER';
export const GET_EQUIPMENTS = 'GET_EQUIPMENTS';
export const RESET_EQUIPMENTS = 'RESET_EQUIPMENTS';
export const SET_TOTALS_INDICATORS = 'SET_TOTALS_INDICATORS';

/*
 * Begin a fetch operation actionSET_TOTAL_DASHBOARD
 */
export const BEGIN_FETCH = 'BEGIN_FETCH';

/*
 * A fetch operation action have been completed
 */
export const FETCH_COMPLETED = 'FETCH_COMPLETED';

/**
 * Action to set the actual filters selected
 */
export const SET_SALE_FILTERS = 'SET_SALE_FILTERS';

interface BeginFetchAction {
  type: typeof BEGIN_FETCH;
}
interface SetAction {
  type: typeof SET_SALE_LIST;
  sale: Sale;
}
interface SetSaleAction {
  type: typeof SET_SALE;
  saleList: {};
}
interface SetTotalAction {
  type: typeof SET_TOTAL;
  dashboardValues: DistributionCenter[];
}

interface SetTotalsAction {
  type: typeof SET_TOTAL_DASHBOARD;
  dashboardValue: {};
}
interface GetDistributionCenter {
  type: typeof GET_DISTRIBUTION_CENTER;
  distributionCenter: DistributionCenter[];
}
interface GetEquipments {
  type: typeof GET_EQUIPMENTS;
  equipments: Equipment[];
}
interface ResetEquipments {
  type: typeof RESET_EQUIPMENTS;
}
interface EndFetchAction {
  type: typeof FETCH_COMPLETED;
}

interface SetFiltersAction {
  type: typeof SET_SALE_FILTERS;
  saleFilters: SaleFilters;
}

interface SetTotalsIndicatorsAction {
  type: typeof SET_TOTALS_INDICATORS;
  totalsSaleIssued: TotalsSaleIssued;
}

export type SaleAction =
  | SetTotalsAction
  | SetTotalAction
  | EndFetchAction
  | BeginFetchAction
  | SetAction
  | SetSaleAction
  | GetDistributionCenter
  | GetEquipments
  | ResetEquipments
  | SetFiltersAction
  | SetTotalsIndicatorsAction;
