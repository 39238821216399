import {
  rfcformat,
  spacesformat,
  emailPattern,
  onlyNumberPattern,
  onlyNumberDecimalPattern,
  numberAndMinusPattern,
} from 'commons/regularExpressions';
import { ValidationRule } from 'antd/lib/form';
import { MessagesCustomer } from 'commons/strings';

export class InvoiceRules {
  public static nameRule: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      message: MessagesCustomer.ENTERPRICE__NAME_REQUIERED,
    },
    
    {
      max: 100,
      whitespace: true,
      message: MessagesCustomer.HUNDRED_CHARACTERS,
    },
  ];
  public static comertialRule: ValidationRule[] = [
    {
      max: 100,
      whitespace: true,
      message: MessagesCustomer.HUNDRED_CHARACTERS,
    },
  ];
  public static rfcRule: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      message: MessagesCustomer.RFC_REQUIERED,
    },
    {
      pattern: rfcformat,
      message: MessagesCustomer.RFC_VALID,
    },
    {
      max: 20,
      whitespace: true,
      message: MessagesCustomer.TWENTY_CHARACTERS,
    },
  ];

  public static streetRule: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      message: MessagesCustomer.STREET_REQUIERED,
    },
    {
      max: 100,
      whitespace: true,
      message: MessagesCustomer.HUNDRED_CHARACTERS,
    },
  ];
  public static observationsRule: ValidationRule[] = [
    {
      max: 500,
      whitespace: true,
      message: MessagesCustomer.FIVE_HUNDRED_CHARACTERS,
    },
  ];

  public static cieRule: ValidationRule[] = [
    {
      max: 50,
      whitespace: true,
      message: MessagesCustomer.FIFTY_CHARACTERS,
    },
  ];

  public static externalNumberRule: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      message: MessagesCustomer.EXTERNAL_NUMBER_REQUIRED,
    },
    {
      max: 100,
      whitespace: true,
      message: MessagesCustomer.HUNDRED_CHARACTERS,
    },
  ];

  public static internalNumberRule: ValidationRule[] = [
    {
      max: 100,
      whitespace: true,
      message: MessagesCustomer.HUNDRED_CHARACTERS,
    },
  ];

  public static fiscalRegimeRule: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      message: MessagesCustomer.FISCA_REGIMEN_REQUIERED,
    },
    {
      max: 100,
      whitespace: true,
      message: MessagesCustomer.HUNDRED_CHARACTERS,
    },
  ];

  public static zipCodeRule: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      message: MessagesCustomer.ZIP_CODE_REQUIRED,
    },
    {
      max: 5,
      whitespace: true,
      message: MessagesCustomer.FIVE_CHARACTERS,
    },
    {
      pattern: spacesformat,
      message: MessagesCustomer.ZIP_CODE_VALID,
    },
  ];

  public static colonyRule: ValidationRule[] = [
    {
      required: true,
      message: MessagesCustomer.COLONY_REQUIRED,
    },
    {
      max: 100,
      whitespace: true,
      message: MessagesCustomer.HUNDRED_CHARACTERS,
    },
  ];
  public static codeRule: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      pattern: numberAndMinusPattern,
      message: MessagesCustomer.CUSTOMER_CODE_REQUIRED,
    },
  ];
  public static numberRule: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      pattern: onlyNumberPattern,
      message: MessagesCustomer.INT_ONLY,
    },
  ];
  public static creditDaysRule: ValidationRule[] = [
    {
      pattern: onlyNumberPattern,
      message: MessagesCustomer.INT_ONLY,
    },
    {
      required: true,
      whitespace: true,
      message: MessagesCustomer.CREDIT_DAYS_RULE,
    },
  ];

  public static numberRulePercent: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      pattern: onlyNumberPattern,
      message: MessagesCustomer.INT_ONLY,
    },
  ];
  public static discountRulePercent: ValidationRule[] = [
    {
      pattern: onlyNumberPattern,
      message: MessagesCustomer.INT_ONLY,
    },
    {
      required: true,
      whitespace: true,
      message: MessagesCustomer.DISCOUNT_REQUIRED,
    },
  ];
  public static numberDecimalRule: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      pattern: onlyNumberDecimalPattern,
      message: MessagesCustomer.NUMBERS_ONLY,
    },
  ];
  public static comercialNameRule: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      message: MessagesCustomer.COMERCIAL_NAME_REQUIERED,
    },
  ];
  public static cityRule: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      message: MessagesCustomer.CITY_REQUIRED,
    },
  ];

  public static municipalityRule: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      message: MessagesCustomer.MUNICIPALITY_REQUIRED,
    },
    {
      max: 100,
      whitespace: true,
      message: MessagesCustomer.HUNDRED_CHARACTERS,
    },
  ];

  public static stateRule: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      message: MessagesCustomer.STATE_REQUIERED,
    },
    {
      max: 100,
      whitespace: true,
      message: MessagesCustomer.HUNDRED_CHARACTERS,
    },
  ];

  public static passwordRule: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      message: MessagesCustomer.PASSWORD_REQUIRED,
    },
    {
      max: 100,
      whitespace: true,
      message: MessagesCustomer.HUNDRED_CHARACTERS,
    },
  ];

  public static usoCfdiRule: ValidationRule[] = [
    {
      required: true,
      message: MessagesCustomer.CFDI_REQUIRED,
    },
  ];  
  public static regimeRule: ValidationRule[] = [
    {
      required: true,
      message: MessagesCustomer.CFDI_REQUIRED,
    },
  ];
  public static bankRule: ValidationRule[] = [
    {
      required: true,
      message: MessagesCustomer.BANK_REQUIRED,
    },
  ];
  public static formaPagoRule: ValidationRule[] = [
    {
      required: true,
      message: MessagesCustomer.PAYMET_FORM_REQUIRED,
    },
  ];

  public static emailRule: ValidationRule[] = [
    {
      required: true,
      message: MessagesCustomer.EMAIL_REQUIRED,
    },
    {
      pattern: emailPattern,
      message: MessagesCustomer.EMAIL_VALID,
    },
  ];
  public static emailRuleNotRequired: ValidationRule[] = [
    {
      pattern: emailPattern,
      message: MessagesCustomer.EMAIL_VALID,
    },
  ];
  public static contactNameRule: ValidationRule[] = [
    {
      required: true,
      message: MessagesCustomer.CONTACT_NAME_REQUIRED,
    },
  ];
  public static contactBirthdayRule: ValidationRule[] = [
    {
      required: true,
      message: MessagesCustomer.DATE_REQUIRED,
    },
  ];
  public static contactMarketStallRule: ValidationRule[] = [
    {
      required: true,
      message: MessagesCustomer.CONTACT_MARKET_STALL_REQUIRED,
    },
  ];
  public static contactPhonesRule = (
    allPhoneIsNull: boolean,
  ): ValidationRule[] => {
    return [
      {
        required: allPhoneIsNull,
        message: ' ',
      },
      {
        pattern: onlyNumberPattern,
        message: MessagesCustomer.INT_ONLY,
      },
      {
        max: 10,
        min: 10,
        message: MessagesCustomer.CONTACT_PHONE_MIN,
      },
    ];
  };
  public static customerPhoneRule: ValidationRule[] = [
    {
      required: true,
      message: MessagesCustomer.PHONE_NUMBER_RQUIRED,
    },
    {
      pattern: onlyNumberPattern,
      message: MessagesCustomer.INT_ONLY,
    },
    {
      max: 10,
      min: 10,
      message: MessagesCustomer.CONTACT_PHONE_MIN,
    },
  ];
}
