import React, { Component, ChangeEvent } from 'react';
import Form, { ValidationRule } from 'antd/lib/form';
import { Contact, AddressContactModel } from 'types/type';
import { Button, Row, Input, Col, DatePicker, Checkbox, Divider } from 'antd';
import Text from 'antd/lib/typography/Text';
import { InvoiceRules as r } from '../../../invoices/components/FiscalData/rulesFiscalData';
import { LabelsCustomer } from '../../../../commons/strings';
import moment from 'moment';
import { initContact } from '../../../../commons/initTypes/index';
import { isEmpty } from 'commons/functions';

interface Props {
  customerAddressModel: AddressContactModel;
  sizeScreen: Function;
  getFieldDecorator: Function;
  getFieldsValue: Function;
  setFieldsValue: Function;
  validateFields: Function;
  getFieldValue: Function;
}

class ContactManager extends Component<Props> {
  public state = {
    contact: [],
    customerAddress: this.props.customerAddressModel.customerAddressId,
    error: false,
  };

  private validator = async (): Promise<void> => {};
  public componentDidMount = async (): Promise<void> => {
    await this.validator();
  };
  private ContactImputText = (
    value: string,
    name: string,
    index: number,
    label: string,
    rule: ValidationRule[],
  ): JSX.Element => {
    return (
      <Form.Item hasFeedback label={label}>
        {this.props.getFieldDecorator(name, {
          initialValue: value ? value : '',
          rules: rule,
        })(
          <Input
            onSelect={(): void => this.updateAction(index)}
            size="large"
            placeholder={label}
          />,
        )}
      </Form.Item>
    );
  };
  private contactImputNumber = (
    value: string | number | undefined,
    name: string,
    index: number,
    label: string,
    rule: ValidationRule[],
  ): JSX.Element => {
    return (
      <Form.Item hasFeedback label={label}>
        {this.props.getFieldDecorator(name, {
          initialValue: value,
          rules: rule,
        })(
          <Input
            onChange={this.phoneChangeAction}
            maxLength={10}
            placeholder={label}
            style={{ width: '100%' }}
          />,
        )}
      </Form.Item>
    );
  };

  private fieldsName = (index: number): string[] => {
    return [
      `contact[${index}].id`,
      `contact[${index}].customerAddressId`,
      `contact[${index}].name`,
      `contact[${index}].email`,
      `contact[${index}].houseNumber`,
      `contact[${index}].oficeNumber`,
      `contact[${index}].movilNumber`,
      `contact[${index}].birthday`,
      `contact[${index}].marketstall`,
      `contact[${index}].resiveEmails`,
    ];
  };
  private deleteAction = (index: number): void => {
    let propscontact: Contact[] = this.props.customerAddressModel.contact;
    let statecontact: Contact[] = this.state.contact;
    let newcontact: Contact[];
    let flitredContact: Contact[];

    if (this.state.contact.length === 0) {
      newcontact = propscontact;
    } else {
      newcontact = statecontact;
    }
    flitredContact = newcontact.filter(
      (value: Contact, findex: number): boolean => findex !== index,
    );
    if (newcontact.length > 1) {
      this.setState({ contact: flitredContact });
      this.props.setFieldsValue({ contact: flitredContact });
    }
  };

  private addAction = (): void => {
    let newcontact: Contact[] = this.props.customerAddressModel.contact;
    if (this.state.contact.length === 0) {
      newcontact.push(initContact);
      this.setState({ contact: [...this.state.contact, ...newcontact] });
      this.props.setFieldsValue({ contact: this.state.contact });
    } else {
      this.setState({ contact: [...this.state.contact, ...[initContact]] });
      this.props.setFieldsValue({ contact: this.state.contact });
    }
  };

  private updateAction = (indextoUpodate: number): void => {
    let propscontact: Contact[] = this.props.customerAddressModel.contact;
    let statecontact: Contact[] = this.state.contact;
    let newcontact: Contact[];

    if (this.state.contact.length === 0) {
      newcontact = propscontact;
    } else {
      newcontact = statecontact;
    }
    newcontact[indextoUpodate] = this.props.getFieldsValue(
      this.fieldsName(indextoUpodate),
    ).contact[indextoUpodate];

    this.setState({ contact: [...newcontact] });
  };

  private phoneChangeAction = (event: ChangeEvent<HTMLInputElement>): void => {
    const value: string = event.currentTarget.value;
    const index = Number(event.currentTarget.id.split('[')[1].split(']')[0]);
    const element: string = event.currentTarget.id;
    let contact: Contact[] =
      this.state.contact.length === 0
        ? this.props.customerAddressModel.contact
        : this.state.contact;

    this.props.setFieldsValue({ [element]: value });
    contact[index] = this.props.getFieldsValue(this.fieldsName(index)).contact[
      index
    ];
    this.setState({ contact: [...contact] });
    this.props.validateFields([element], { force: true });
  };

  private isAllPhonesNull = (index: number): boolean => {
    if (this.state.contact.length !== 0) {
      const validator: Contact = this.props.getFieldValue(`contact[${index}]`);
      return (
        isEmpty(validator.houseNumber) &&
        isEmpty(validator.movilNumber) &&
        isEmpty(validator.oficeNumber)
      );
    }
    return false;
  };

  public render = (): React.ReactNode => {
    const { getFieldDecorator, sizeScreen } = this.props;
    const { contact } = this.state;
    const firstContact = [initContact];
    return (
      <Row>
        {(contact.length !== 0
          ? contact
          : this.props.customerAddressModel.contact.length !== 0
          ? this.props.customerAddressModel.contact
          : firstContact
        ).map(
          (value: Contact, index: number): React.ReactNode => (
            <Row key={index}>
              <Divider />
              {getFieldDecorator(`contact[${index}].id`, {
                initialValue: value.id,
              })}
              {getFieldDecorator(`contact[${index}].customerAddressId`, {
                initialValue: value.customerAddressId,
              })}
              <Row>
                <Col {...sizeScreen(7)}>
                  {this.ContactImputText(
                    value.name,
                    `contact[${index}].name`,
                    index,
                    LabelsCustomer.CONTACT_NAME,
                    r.contactNameRule,
                  )}
                </Col>
                <Col {...sizeScreen(7)}>
                  {this.ContactImputText(
                    value.email,
                    `contact[${index}].email`,
                    index,
                    LabelsCustomer.CONTACT_EMAIL,
                    r.emailRule,
                  )}
                </Col>
                <Col {...sizeScreen(4)}>
                  <Form.Item label={LabelsCustomer.BIRTHDAY}>
                    {getFieldDecorator(`contact[${index}].birthday`, {
                      initialValue: value ? moment(value.birthday) : null,
                      rules: r.contactBirthdayRule,
                      id: index.toString(),
                    })(
                      <DatePicker
                        defaultValue={moment(value.birthday)}
                        value={this.props.getFieldValue(
                          `contact[${index}].birthday`,
                        )}
                        onChange={(date: moment.Moment | null): void => {
                          this.props.setFieldsValue({
                            [`contact[${index}].birthday`]: date,
                          });
                        }}
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Col offset={1}>
                <Text
                  disabled
                  strong
                  className={
                    this.isAllPhonesNull(index)
                      ? 'text-validation-contact-phones'
                      : ' '
                  }
                >
                  {' Llene almennos uno de los teléfonos '}
                </Text>
              </Col>
              <Row>
                <Col {...sizeScreen(4)}>
                  {this.contactImputNumber(
                    value.houseNumber,
                    `contact[${index}].houseNumber`,
                    index,
                    LabelsCustomer.CONTACT_HOUSE_PHONE,
                    r.contactPhonesRule(this.isAllPhonesNull(index)),
                  )}
                </Col>
                <Col {...sizeScreen(4)}>
                  {this.contactImputNumber(
                    value.movilNumber,
                    `contact[${index}].movilNumber`,
                    index,
                    LabelsCustomer.CONTACT_MOVIL_PHONE,
                    r.contactPhonesRule(this.isAllPhonesNull(index)),
                  )}
                </Col>
                <Col {...sizeScreen(4)}>
                  {this.contactImputNumber(
                    value.oficeNumber,
                    `contact[${index}].oficeNumber`,
                    index,
                    LabelsCustomer.CONTACT_OFICE_PHONE,
                    r.contactPhonesRule(this.isAllPhonesNull(index)),
                  )}
                </Col>
              </Row>

              <Col {...sizeScreen(7)}>
                {this.ContactImputText(
                  value.marketstall,
                  `contact[${index}].marketstall`,
                  index,
                  LabelsCustomer.CONTACT_MARKET_STALL,
                  r.contactMarketStallRule,
                )}
              </Col>
              <Col {...sizeScreen(2)}>
                <Form.Item label={LabelsCustomer.CONTACT_RESIVE_MAILS}>
                  {getFieldDecorator(`contact[${index}].resiveEmails`, {
                    valuePropName: 'checked',
                    initialValue: value.resiveEmails,
                  })(<Checkbox />)}
                </Form.Item>
              </Col>
              <Col {...sizeScreen(23)}>
                {index ===
                  (contact.length !== 0
                    ? contact
                    : this.props.customerAddressModel.contact
                  ).length -
                    1 && (
                  <Button
                    onClick={(): void => this.addAction()}
                    className="add-contact-button"
                  >
                    &#x271a;
                  </Button>
                )}
                {index <=
                  (contact.length !== 0
                    ? contact
                    : this.props.customerAddressModel.contact
                  ).length -
                    1 && (
                  <Button
                    onClick={(): void => this.deleteAction(index)}
                    className="delete-contact-button"
                  >
                    &#x2716;
                  </Button>
                )}
              </Col>
            </Row>
          ),
        )}
      </Row>
    );
  };
}
export default ContactManager;
