import { Divider, Modal, Select } from 'antd';
import React, { Component } from 'react';
import { CollectionRoute, InvoiceForPortfolio } from 'types/type';

interface Props {
  isVisible: boolean;
  originRoute: CollectionRoute;
  customers: InvoiceForPortfolio[];
  routes: CollectionRoute[];
  onCancel: Function;
  onSave: Function;
}

class MoveCustomerModal extends Component<Props> {
  public state = { selectedCustomers: [], destinationRoute: '' };

  public render = (): JSX.Element => {
    const { isVisible, originRoute, onCancel, onSave } = this.props;
    const { customers, routes } = this.props;
    return (
      <Modal
        centered
        visible={isVisible}
        title={`Mover clientes de la ruta ${originRoute.folio.id}`}
        onOk={(): void => {
          const { selectedCustomers, destinationRoute } = this.state;
          let customersInfo: InvoiceForPortfolio[] = [];
          // From each selected customer, get the invoices information & added to an array
          selectedCustomers.forEach((code: string): void => {
            const invoices = customers.filter(
              (inv): boolean => inv.customerCode === code,
            );
            customersInfo = customersInfo.concat(invoices);
          });
          onSave(originRoute, destinationRoute, customersInfo);
        }}
        onCancel={(): void => onCancel()}
      >
        Seleccione los clientes que desea mover a otra ruta:
        <Select
          mode="multiple"
          style={{ width: '100%', marginTop: '10px' }}
          placeholder="Seleccionar clientes"
          defaultValue={[]}
          onChange={(values: string[]): void =>
            this.setState({ selectedCustomers: values })
          }
        >
          {customers.map(
            (inv: InvoiceForPortfolio): React.ReactNode => (
              <Select.Option value={inv.customerCode} key={inv.customerCode}>
                {inv.customerName}
              </Select.Option>
            ),
          )}
        </Select>
        <Divider type="horizontal" />
        Seleccione la ruta a la que desea mover los clientes:
        <Select
          style={{ width: '100%', marginTop: '10px' }}
          defaultValue=""
          onChange={(value: string): void =>
            this.setState({ destinationRoute: value })
          }
        >
          {routes.map(
            (route: CollectionRoute): React.ReactNode => (
              <Select.Option
                value={route.id}
                key={route.id}
              >{`Folio: ${route.folio.id} - Cobrador: ${route.debCollectorName} - Clientes: ${route.customersToVisit} - Estado: ${route.status}`}</Select.Option>
            ),
          )}
        </Select>
      </Modal>
    );
  };
}

export default MoveCustomerModal;
