import { AnyAction } from 'redux';
import UserService from '../../../services/user';
import { parseApiError } from '../../../commons/error';
import { notification } from 'antd';
import {
  Pageable,
  User,
  DistributionCenter,
  Permissions,
  Marketstall,
  DebtCollector,
  PortfolioManager,
} from '../../../types/type';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { UserActionTypes, UserActions } from './types';
import CompanyService from 'services/company';
import { initUser } from 'commons/initTypes';
import PermissionService from 'services/permission';
import MarketstallService from 'services/marketstall';

const userService = new UserService();
const companyService = new CompanyService();
const permissionService = new PermissionService();
const marketstallService = new MarketstallService();

type TAPUA = ThunkAction<Promise<void>, {}, {}, UserActions>;
type TAU = ThunkDispatch<{}, {}, UserActions>;
type TAVUA = ThunkAction<void, {}, {}, UserActions>;

//export type Action = UserActions;

export const set = (usersList: {}): UserActions => ({
  type: UserActionTypes.SET,
  payload: usersList,
});

export const usersSave = (usersaved: User): UserActions => ({
  type: UserActionTypes.SAVE,
  payload: usersaved,
});
export const updateUser = (userupdate: {}): UserActions => ({
  type: UserActionTypes.UPDATE,
  payload: userupdate,
});
export const getUser = (user: User): UserActions => ({
  type: UserActionTypes.GET,
  payload: user,
});
export const getDistributionCenter = (
  distributionCenter: DistributionCenter[],
): UserActions => ({
  type: UserActionTypes.GET_DISTRIBUTION_CENTER,
  payload: distributionCenter,
});
export const cleanUser = (user: User): UserActions => ({
  type: UserActionTypes.CLEAN_USER,
  payload: user,
});
export const getPermissions = (permissions: Permissions[]): UserActions => ({
  type: UserActionTypes.GET_PERMISSIONS,
  payload: permissions,
});
export const getMarketstall = (marketstall: Marketstall[]): UserActions => ({
  type: UserActionTypes.GET_MARKETSTALL,
  payload: marketstall,
});
export const getDebtCollector = (
  debtCollector: DebtCollector[],
): UserActions => ({
  type: UserActionTypes.GET_DEBT_COLLECTOR,
  payload: debtCollector,
});
export const getPortfolioManager = (
  portfolioManager: PortfolioManager[],
): UserActions => ({
  type: UserActionTypes.GET_PORTFOLIO_MANAGER,
  payload: portfolioManager,
});

export const fetching = (fetch: boolean): UserActions => ({
  type: UserActionTypes.FETCHING,
  payload: fetch,
});

export const page = (
  filter: string,
  userMarketstall: string,
  distributionCenter: string,
  currentPage: number,
  pageSize: number,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      const responsepage = await userService.usersList(
        filter,
        userMarketstall,
        distributionCenter,
        currentPage,
        pageSize,
      );
      dispatch(set(responsepage));
    } catch (err) {
      notification.error(parseApiError(err));
    }
  };
};

export const saveuser = (
  user: User,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(fetching(true));
    try {
      const responseuser = await userService.save(user);
      dispatch(usersSave(responseuser));
      notification.success({
        description: 'Usuario registrado exitosamente.',
        duration: 3,
        message: 'Éxito',
      });
      const responsepage = await userService.usersList('', '', '', 1, 25);
      dispatch(set(responsepage));
    } catch (err) {
      notification.error(parseApiError(err));
    }
    dispatch(fetching(false));
  };
};

export const activateUserById = (
  active: string,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      const responseuser: Pageable = await userService.activateUserById(active);
      dispatch(updateUser(responseuser));
    } catch (err) {
      notification.error(parseApiError(err));
    }
  };
};

export const desactivateUserById = (
  desactive: string,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      const responseuser = await userService.deactivateUserById(desactive);
      dispatch(updateUser(responseuser));
    } catch (err) {
      notification.error(parseApiError(err));
    }
  };
};

export const trysendpassword = (
  userEmail: string,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (): Promise<void> => {
    try {
      await userService.trysendpassword(userEmail);
      notification.success({
        description: 'Correo Enviado Exitosamente',
        duration: 3,
        message: 'Éxito',
      });
    } catch (err) {
      notification.error(parseApiError(err));
    }
  };
};

export const getUserAction = (userId: string): TAPUA => {
  return async (dispatch: TAU): Promise<void> => {
    try {
      const response: User = await userService.getUser(userId);
      dispatch(getUser(response));
    } catch (error) {
      notification.error(parseApiError(error));
    }
  };
};

export const getDistributionCenterAction = (): TAPUA => {
  return async (dispatch: TAU): Promise<void> => {
    try {
      const response: DistributionCenter[] = await companyService.getDistrubutionCenter();
      dispatch(getDistributionCenter(response));
    } catch (error) {
      notification.error(parseApiError(error));
    }
  };
};

export const cleanUserAction = (): TAVUA => (dispatch: TAU): void => {
  dispatch(cleanUser(initUser));
};
export const getPermissionsAction = (): TAPUA => {
  return async (dispatch: TAU): Promise<void> => {
    try {
      const response: Permissions[] = await permissionService.getAll();
      dispatch(getPermissions(response));
    } catch (error) {
      notification.error(parseApiError(error));
    }
  };
};

export const getMarketstallAction = (): TAPUA => {
  return async (dispatch: TAU): Promise<void> => {
    try {
      dispatch(getMarketstall(await marketstallService.getAll()));
    } catch (error) {
      notification.error(parseApiError(error));
    }
  };
};

export const getDebtCollectorAction = (): TAPUA => {
  return async (dispatch: TAU): Promise<void> => {
    try {
      dispatch(getDebtCollector(await userService.getAllUsersCollectors()));
    } catch (error) {
      notification.error(parseApiError(error));
    }
  };
};
export const getPortfolioManagerAction = (): TAPUA => {
  return async (dispatch: TAU): Promise<void> => {
    try {
      dispatch(
        getPortfolioManager(await userService.getAllUsersPortfolioManager()),
      );
    } catch (error) {
      notification.error(parseApiError(error));
    }
  };
};
