/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by David Canul <david@ssf.com.mx>, Jun 2019
 *
 *
 */

import { combineReducers, Reducer } from 'redux';
import {
  Sale,
  DashboardValues,
  DistributionCenter,
  Equipment,
  SaleFilters,
  TotalsSaleIssued,
} from 'types/type';
import { RootState } from 'store/configureStore';
import {
  SaleAction,
  BEGIN_FETCH,
  FETCH_COMPLETED,
  SET_SALE,
  SET_TOTAL,
  GET_DISTRIBUTION_CENTER,
  GET_EQUIPMENTS,
  RESET_EQUIPMENTS,
  SET_TOTAL_DASHBOARD,
  SET_SALE_FILTERS,
  SET_TOTALS_INDICATORS,
} from './types';
import {
  initDistributionCenter,
  initEquipment,
  intDashboardValue,
  initSaleFilters,
  initTotalsSaleIssued,
} from 'commons/initTypes';

export interface SaleI {
  sale: Sale;
}

export interface SaleList {
  content?: Sale[];
  number?: number;
  totalElements?: number;
}

export interface DistributionCenterI {
  distributionCenters: DistributionCenter[];
}

export interface DistributionCenterTotalsI {
  distributionCentersTotals: DistributionCenter[];
}

export interface DashboardValue {
  dashboardValue: DashboardValues;
}

export interface DashboardValuex {
  dashboardValues: DashboardValues;
}

export interface SaleState {
  readonly saleList: SaleList;
  readonly isFetching: boolean;
  readonly dashboardValue: DistributionCenterTotalsI;
  readonly dashboardValues: DashboardValues;
  readonly distributionCenterInterface: DistributionCenterI;
  readonly equipmentList: Equipment[];
  readonly saleFilters: SaleFilters;
  readonly totalsSaleIssued: TotalsSaleIssued;
}

const saleList: Reducer<SaleList> = (
  state: SaleList = { content: [] },
  action,
): SaleList => {
  if (action.type === SET_SALE) {
    return { ...state, ...action.saleList };
  }
  return state;
};

/**
 * Reducer to keep the sale filters
 */
const saleFilters: Reducer<SaleFilters> = (
  state: SaleFilters = initSaleFilters,
  action,
): SaleFilters => {
  if (action.type === SET_SALE_FILTERS) {
    return { ...state, ...action.saleFilters };
  }
  return state;
};

const dashboardValue: Reducer<DistributionCenterTotalsI> = (
  state: DistributionCenterTotalsI = {
    distributionCentersTotals: [initDistributionCenter],
  },
  action,
): DistributionCenterTotalsI => {
  if (action.type === SET_TOTAL) {
    return { ...state, distributionCentersTotals: action.dashboardValues };
  }
  return state;
};

const dashboardValues: Reducer<DashboardValues> = (
  state: DashboardValues = intDashboardValue,
  action,
): DashboardValues => {
  if (action.type === SET_TOTAL_DASHBOARD) {
    return { ...state, ...action.dashboardValue };
  }
  return state;
};

const distributionCenterInterface: Reducer<DistributionCenterI> = (
  state: DistributionCenterI = {
    distributionCenters: [initDistributionCenter],
  },
  action,
): DistributionCenterI => {
  if (action.type === GET_DISTRIBUTION_CENTER) {
    return { ...state, distributionCenters: action.distributionCenter };
  }
  return state;
};

const equipmentList: Reducer<Equipment[]> = (
  state: Equipment[] = [initEquipment],
  action,
): Equipment[] => {
  switch (action.type) {
    case GET_EQUIPMENTS:
      return { ...state, ...action.equipments };
    case RESET_EQUIPMENTS:
      return [initEquipment];
    default:
      return state;
  }
};

const totalsSaleIssued: Reducer<TotalsSaleIssued> = (
  state: TotalsSaleIssued = initTotalsSaleIssued,
  action,
): TotalsSaleIssued => {
  if (action.type === SET_TOTALS_INDICATORS) {
    return { ...state, ...action.totalsSaleIssued };
  }
  return state;
};

const isFetching: Reducer<boolean> = (
  state = false,
  action: SaleAction,
): boolean => {
  switch (action.type) {
    case BEGIN_FETCH:
      return true;
    case FETCH_COMPLETED:
      return false;
    default:
      return state;
  }
};

export const getIsFetching = (store: RootState): boolean =>
  store.invoiceState.isFetching;

export default combineReducers<SaleState>({
  saleList: saleList,
  saleFilters,
  dashboardValues: dashboardValues,
  dashboardValue: dashboardValue,
  isFetching,
  distributionCenterInterface,
  equipmentList,
  totalsSaleIssued,
});
