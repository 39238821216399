/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by David Canul <david@ssf.com.mx>, Jul 2019
 *
 *
 */
import SettlementService from '../../../services/settlement';
import CustomerService from '../../../services/customers';
import PriceService from '../../../services/prices';
import { parseApiError, parseStringError } from '../../../commons/error';
import { notification } from 'antd';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {
  SettlementAction,
  BEGIN_FETCH,
  FETCH_COMPLETED,
  SET_SETTLEMENT,
  SET_SETTLEMENT_LISTS,
  SETTLEMENTS_SET_DISTRIBUTION_CENTER,
  SET_USERS_NAME,
  SET_SETTLEMENT_EQUIPMENT_LIST,
  SET_SETTLEMENT_TOTALS,
  SET_CUSTOMER_LIST,
  RESET_SETTLEMENT_EQUIPMENT_LIST,
  SET_SALE_AUDIT,
  RESET_SALE_AUDIT,
  SET_SETTLEMENT_FILTERS,
  SET_PRICES,
} from './types';
import { getIsFetching } from './reducers';
import { RootState } from 'store/configureStore';
import { Popups } from 'commons/components/popups/popups';
import {
  SettlementFilters,
  Equipment,
  ModelSaleToUpdate,
  Settlement,
  Price,
  ContainerBuilder,
  ContainerPurchase,
} from '../../../../src/types/type';

const settlementService = new SettlementService();
const customerService = new CustomerService();
const priceService = new PriceService();

const startFetch = (): SettlementAction => {
  return { type: BEGIN_FETCH };
};
type ITA = ThunkAction<Promise<void>, RootState, {}, SettlementAction>;
type IDA = ThunkDispatch<{}, {}, SettlementAction>;
/**
 * This action creator is not public beca1use it should be used
 * automatically by other action creators that need fetch data from
 * backend.
 */
const endFetch = (): SettlementAction => {
  return { type: FETCH_COMPLETED };
};

const editFilters = (filters: SettlementFilters): SettlementAction => {
  return {
    type: SET_SETTLEMENT_FILTERS,
    settlementFilters: filters,
  };
};

export const applySettlement = (
  operator: string,
  assistant: string,
  id: string,
  apply: string,
  comment: string,
  containerValues: ContainerBuilder[],
  containerPurchases: ContainerPurchase[],
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  dispatch(startFetch());
  await settlementService.saveSettlement(
    operator,
    assistant,
    id,
    apply,
    comment,
    containerValues,
    containerPurchases,
  );
  Popups.notifySuccess('Liquidación generada correctamente');
  dispatch(endFetch());
};
export const saveposSettlement = (
  salesId: { id: string; total: number }[],
  id: string,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    await dispatch(startFetch());
    await settlementService.saveposSettlement(salesId, id);

    Popups.notifySuccess('Liquidación posliquidada correctamente');
    await dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};
export const getSettlementbyId = (id: string): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    await dispatch(startFetch());

    await dispatch({
      type: SET_SETTLEMENT,
      settlement: await settlementService.getSettlementbyId(id),
    });

    await dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};
export const getSaleAudit = (id: string): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    await dispatch(startFetch());
    await dispatch({ type: RESET_SALE_AUDIT });
    await dispatch({
      type: SET_SALE_AUDIT,
      salesAuditArray: await settlementService.getSaleAuditbyId(id),
    });

    await dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};
export const getSettlementbyIdfiltred = (
  id: string,
  all: boolean,
  status: string,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    await dispatch(startFetch());

    await dispatch({
      type: SET_SETTLEMENT,
      settlement: await settlementService.getSettlementbyIdfiltred(
        id,
        all,
        status,
      ),
    });

    await dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};
export const getDistributionCenter = (): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    await dispatch(startFetch());

    let userResponse = await settlementService.getNamesFromUSer();
    await dispatch({
      type: SET_USERS_NAME,
      namesUsers: userResponse,
    });
    let responseDistribution = await settlementService.getDistrubutionCenter();
    await dispatch({
      type: SETTLEMENTS_SET_DISTRIBUTION_CENTER,
      distributionCenter: responseDistribution,
    });
    await dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};

export const page = (filters: SettlementFilters): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    await dispatch(startFetch());
    await dispatch(editFilters(filters));
    //let settlementListResponse;
    //settlementListResponse =  await settlementService.SettlementList(filters);
    await dispatch({
      type: SET_SETTLEMENT_LISTS,
      settlementLists: await settlementService.SettlementList(filters),
    });

    let totals;
    totals = await settlementService.SettlementTotals(filters);
    dispatch({ type: SET_SETTLEMENT_TOTALS, settlementTotals: totals });

    await dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};

export const saveSaleToUpdate = (
  modelSalesToUpdate: ModelSaleToUpdate,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    await dispatch(startFetch());
    await settlementService.saveSaleToUpdate(modelSalesToUpdate);
    await dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};
export const getCustomersByFilter = (filter: string): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    await dispatch(startFetch());
    const customer = await customerService.listCustomerByFilter(filter);

    dispatch({ type: SET_CUSTOMER_LIST, customersList: customer });

    await dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};
export const getEquipmentsByDistributionCenter = (
  distriburionCenterId: string,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  dispatch(startFetch());
  try {
    const equipmentList: Equipment[] = await settlementService.settlementEquipmentList(
      distriburionCenterId,
    );
    dispatch({ type: RESET_SETTLEMENT_EQUIPMENT_LIST });
    dispatch({
      type: SET_SETTLEMENT_EQUIPMENT_LIST,
      equipmentList: equipmentList,
    });
  } catch (err) {
    notification.error(parseApiError(err));
  }
  dispatch(endFetch());
};

export const downloadTicket = (
  id: string,
  name: string,
  saleFolio: string,
): ITA => async (dispatch: IDA): Promise<void> => {
  dispatch(startFetch());
  try {
    await settlementService.downloadCut(id, saleFolio, name);
  } catch (error) {
    if (error.responce !== undefined) {
      const decoder: TextDecoder = new TextDecoder('utf-8');
      const decodedString: string = decoder.decode(
        new Uint8Array(error.response.data),
      );
      notification.error(parseStringError(decodedString));
    } else {
      notification.error(parseApiError(error));
    }
  }
  dispatch(endFetch());
};
export const restoreSettlement = (id: string): ITA => async (
  dispatch: IDA,
): Promise<void> => {
  dispatch(startFetch());
  try {
    const settlement: Settlement = await settlementService.restoreSettlement(
      id,
    );
    Popups.notifySuccess('Corte restaurado' + settlement.movementDate);
    dispatch(endFetch());
  } catch (error) {
    if (error.responce !== undefined) {
      const decoder: TextDecoder = new TextDecoder('utf-8');
      const decodedString: string = decoder.decode(
        new Uint8Array(error.response.data),
      );
      notification.error(parseStringError(decodedString));
    } else {
      notification.error(parseApiError(error));
    }
  }
  dispatch(endFetch());
};
export const setZeroAllSettlementAndSales = (id: string): ITA => async (
  dispatch: IDA,
): Promise<void> => {
  dispatch(startFetch());
  try {
    const settlement: Settlement = await settlementService.setZeroAllSettlementAndSales(
      id,
    );
    Popups.notifySuccess('Corte seteado a cero' + settlement.movementDate);
    dispatch(endFetch());
  } catch (error) {
    if (error.responce !== undefined) {
      const decoder: TextDecoder = new TextDecoder('utf-8');
      const decodedString: string = decoder.decode(
        new Uint8Array(error.response.data),
      );
      notification.error(parseStringError(decodedString));
    } else {
      notification.error(parseApiError(error));
    }
  }
  dispatch(endFetch());
};

/** Action to get all the prices data */
export const getPrices = (): ITA => async (dispatch: IDA): Promise<void> => {
  dispatch(startFetch());
  try {
    const response: Price[] = await priceService.getProductType1Prices();
    dispatch({
      type: SET_PRICES,
      prices: response,
    });
    dispatch(endFetch());
  } catch (error) {
    dispatch(endFetch());
    notification.error(parseApiError(error));
  }
};

/** Action to set edn fetching when the exception is managed in the index */
export const setEndFetch = (): ITA => async (dispatch: IDA): Promise<void> => {
  dispatch(endFetch());
};

/** Action to generate an Excel file with the data filtered, and download it */
export const downloadReport = (
  settlementFilters: SettlementFilters,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) return Promise.resolve();

  try {
    dispatch(startFetch());
    await settlementService.generateReport(settlementFilters);
    dispatch(endFetch());
  } catch (error) {
    dispatch(endFetch());
    notification.error(parseApiError(error));
  }
};

/** Action to generate an Excel file of pending settlements with the data filtered, and download it */
export const downloadPendingSettlements = (
  settlementFilters: SettlementFilters,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) return Promise.resolve();

  try {
    dispatch(startFetch());
    await settlementService.getPengingSettlementsReport(settlementFilters);
    dispatch(endFetch());
  } catch (error) {
    dispatch(endFetch());
    notification.error(parseApiError(error));
  }
};
