import axios, { AxiosResponse } from 'axios';
import { createCoreServiceUrl, createParamsService } from 'commons/services';
import Service from 'services/Service';
import {
  CollectionRoute,
  InvoiceForPortfolio,
  Pageable,
  Portfolio,
  RoutePortfolio,
} from 'types/type';

class RoutesService extends Service {
  // eslint-disable-next-line
    public initCollectionRoute = (id: string): Promise<any> =>
    axios({
      method: 'put',
      url: createCoreServiceUrl(`collectionroute/init`),
      data: { id: id },
      // eslint-disable-next-line
    }).then((response: AxiosResponse<any>): any => response.data);
  // eslint-disable-next-line
    public getPageByFilters = (filters: Record<string, any>): Promise<Pageable> =>
    axios({
      method: 'get',
      url:
        createCoreServiceUrl(`collectionroute`) + createParamsService(filters),
    }).then((response: AxiosResponse<Pageable>): Pageable => response.data);

  public getRoutePdf = async (id: string): Promise<void> => {
    const response = await axios({
      method: 'get',
      url: createCoreServiceUrl(`collectionroute/${id}/pdf`),
      responseType: 'arraybuffer',
    });

    const pdfUrl = window.URL.createObjectURL(new Blob([response.data]));
    const pdfLink = document.createElement('a');
    pdfLink.href = pdfUrl;
    pdfLink.setAttribute('download', `Ruta.pdf`);
    document.body.appendChild(pdfLink);
    pdfLink.click();
  };

  public getCollRouteDetails = (
    collRouteId: string,
  ): Promise<RoutePortfolio[]> =>
    axios({
      method: 'get',
      url: createCoreServiceUrl(`collectionroute/${collRouteId}/details`),
    }).then(
      (response: AxiosResponse<RoutePortfolio[]>): RoutePortfolio[] =>
        response.data,
    );

  public downloadEvidence = async (taskitId: string): Promise<void> => {
    const response = await axios({
      url: createCoreServiceUrl(`collectionroute/evidence/${taskitId}`),
      method: 'get',
      responseType: 'arraybuffer',
    });
    const evidenceUrl = window.URL.createObjectURL(new Blob([response.data]));
    const evidenceLink = document.createElement('a');
    evidenceLink.href = evidenceUrl;
    evidenceLink.setAttribute('download', taskitId + '.jpg');
    document.body.appendChild(evidenceLink);
    evidenceLink.click();
  };

  public addPortfolios = (
    routeId: string,
    portfolios: Portfolio[],
  ): Promise<CollectionRoute> =>
    axios({
      method: 'put',
      url: createCoreServiceUrl(`collectionroute/${routeId}/addportfolios`),
      data: portfolios,
    }).then(
      (reponse: AxiosResponse<CollectionRoute>): CollectionRoute =>
        reponse.data,
    );

  public getCustomersToMove = (
    collRouteId: string,
  ): Promise<InvoiceForPortfolio[]> =>
    axios({
      method: 'get',
      url: createCoreServiceUrl(`collectionroute/${collRouteId}/customers`),
    }).then(
      (response: AxiosResponse<InvoiceForPortfolio[]>): InvoiceForPortfolio[] =>
        response.data,
    );

  public getOpenedRoutesDifferentFrom = (
    id: string,
  ): Promise<CollectionRoute[]> =>
    axios({
      method: 'get',
      url:
        createCoreServiceUrl(`collectionroute/${id}/status`) +
        `?status=ABIERTA`,
    }).then(
      (response: AxiosResponse<CollectionRoute[]>): CollectionRoute[] =>
        response.data,
    );

  public moveCustomers = (
    originRouteId: string,
    destinationRouteId: string,
    customersData: InvoiceForPortfolio[],
  ): Promise<CollectionRoute> =>
    axios({
      method: 'put',
      url:
        createCoreServiceUrl(`collectionroute/${originRouteId}/customers`) +
        `?destinationRouteId=${destinationRouteId}`,
      data: customersData,
    }).then(
      (reponse: AxiosResponse<CollectionRoute>): CollectionRoute =>
        reponse.data,
    );
}

export default RoutesService;
