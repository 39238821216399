import { ColumnProps } from 'antd/lib/table';
import { toNumber } from 'commons/numbersFormat';
import { DATE_FORMAT } from 'commons/services';
import moment from 'moment';
import React from 'react';
import { CreNoteInvoice, InvoiceForPortfolio } from 'types/type';

export const creditNoteInvoiceColumns = (): ColumnProps<CreNoteInvoice>[] => [
  //Empty column added to fit the others to screen
  { title: '', dataIndex: '', key: '' },
  {
    title: 'Referencia',
    dataIndex: 'reference',
    key: 'reference',
    width: '30%',
  },
  {
    title: 'Serie/Folio',
    dataIndex: 'invoiceFolio',
    key: 'invoiceFolio',
    render: (folio: string, record: CreNoteInvoice): React.ReactNode => {
      if (folio === null) return 'N/A';
      return record.invoiceSerie + '/' + folio;
    },
    width: '20%',
  },
  {
    title: 'Total',
    dataIndex: 'invoiceTotal',
    key: 'invoiceTotal',
    render: (total: number): React.ReactNode => {
      return '$ ' + toNumber(total);
    },
    width: '40%',
  },
];

export const invoicesInfoColumns = (): ColumnProps<InvoiceForPortfolio>[] => [
  {
    title: 'Cobrador',
    dataIndex: 'debtCollectorName',
    render: (name: string, record: InvoiceForPortfolio): React.ReactNode => {
      let firstName = '';
      let lastName1 = '';
      let lastName2 = '';
      if (name !== null) firstName = name;
      if (record.collectorLastName1 !== null)
        lastName1 = record.collectorLastName1;
      if (record.collectorLastName2 !== null)
        lastName2 = record.collectorLastName2;
      return `${firstName} ${lastName1} ${lastName2}`;
    },
  },
  {
    title: 'Fecha de la factura',
    dataIndex: 'invoiceDate',
    render: (invoiceDate: string): React.ReactNode =>
      moment(invoiceDate).format(DATE_FORMAT),
  },
  {
    title: 'Cliente',
    dataIndex: 'customerName',
  },
  {
    title: 'Serie/Folio',
    dataIndex: 'invoiceFolio',
    render: (folio: string, record: InvoiceForPortfolio): React.ReactNode => {
      if (folio === null) return 'N/A';
      return record.invoiceSerie + '/' + folio;
    },
  },
  {
    title: 'Importe total factura',
    dataIndex: 'invoiceTotal',
    render: (total: number): React.ReactNode => '$ ' + toNumber(total),
  },
  {
    title: 'Monto a cobrar',
    dataIndex: 'amountToPay',
    render: (total: number): React.ReactNode => '$ ' + toNumber(total),
  },
  {
    title: 'Saldo',
    dataIndex: 'debt',
    render: (totalDebt: number): React.ReactNode => '$ ' + toNumber(totalDebt),
  },
  {
    title: 'Folio taskit',
    dataIndex: 'taskitFolio',
  },
];
