import { combineReducers } from 'redux';
import { Reducer } from 'redux';
import { RootState } from 'store/configureStore';
import {
  CollectionRoute,
  InvoiceForPortfolio,
  Portfolio,
  RoutePortfolio,
  RoutesPage,
} from 'types/type';
import {
  BEGIN_FETCH,
  FETCH_COMPLETED,
  SET_ROUTES_PAGE,
  RESET_ROUTES_PAGE,
  RouteAction,
  SET_ROUTE_DETAILS,
  RESET_ROUTE_DETAILS,
  SET_PORTFOLIOS_TO_ADD,
  RESET_PORTFOLIOS_TO_ADD,
  SET_CUSTOMERS_TO_MOVE,
  RESET_CUSTOMERS_TO_MOVE,
  SET_OPENED_ROUTES,
  RESET_OPENED_ROUTES,
} from './types';

export interface RoutesState {
  readonly routesPage: RoutesPage;
  readonly collRouteDetails: RoutePortfolio[];
  readonly portfoliosToAdd: Portfolio[];
  readonly customersToMove: InvoiceForPortfolio[];
  readonly openedRoutes: CollectionRoute[];
  readonly isFetching: boolean;
}
const routesPage: Reducer<RoutesPage> = (
  state: RoutesPage = { content: [] },
  action: RouteAction,
): RoutesPage => {
  switch (action.type) {
    case SET_ROUTES_PAGE:
      return { ...state, ...action.routesPage };
    case RESET_ROUTES_PAGE:
      return { content: [], number: 0, totalElements: 0 };
    default:
      return state;
  }
};

const collRouteDetails: Reducer<RoutePortfolio[]> = (
  state: RoutePortfolio[] = [],
  action: RouteAction,
): RoutePortfolio[] => {
  switch (action.type) {
    case SET_ROUTE_DETAILS:
      return { ...state, ...action.routeDetails };
    case RESET_ROUTE_DETAILS:
      return [];
    default:
      return state;
  }
};

const portfoliosToAdd: Reducer<Portfolio[]> = (
  state: Portfolio[] = [],
  action: RouteAction,
): Portfolio[] => {
  switch (action.type) {
    case SET_PORTFOLIOS_TO_ADD:
      return { ...state, ...action.portfolios };
    case RESET_PORTFOLIOS_TO_ADD:
      return [];
    default:
      return state;
  }
};

const customersToMove: Reducer<InvoiceForPortfolio[]> = (
  state: InvoiceForPortfolio[] = [],
  action: RouteAction,
): InvoiceForPortfolio[] => {
  switch (action.type) {
    case SET_CUSTOMERS_TO_MOVE:
      return { ...state, ...action.customersInfo };
    case RESET_CUSTOMERS_TO_MOVE:
      return [];
    default:
      return state;
  }
};

const openedRoutes: Reducer<CollectionRoute[]> = (
  state: CollectionRoute[] = [],
  action: RouteAction,
): CollectionRoute[] => {
  switch (action.type) {
    case SET_OPENED_ROUTES:
      return { ...state, ...action.openedRoutes };
    case RESET_OPENED_ROUTES:
      return [];
    default:
      return state;
  }
};

const isFetching: Reducer<boolean> = (
  state: boolean = false,
  action: RouteAction,
): boolean => {
  switch (action.type) {
    case BEGIN_FETCH:
      return true;
    case FETCH_COMPLETED:
      return false;
    default:
      return state;
  }
};

export const isRoutesFetching = (store: RootState): boolean =>
  store.routesState.isFetching;

export default combineReducers<RoutesState>({
  routesPage,
  collRouteDetails,
  portfoliosToAdd,
  customersToMove,
  openedRoutes,
  isFetching,
});
