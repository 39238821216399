/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Hiram Padilla <hiram@ssf.com.mx>, August 2020
 *
 * This file contains the redux reducers for inventory component
 */

import {
  Container,
  Equipment,
  ContainerFilters,
  Equivalence,
  LiterTotals,
} from 'types/type';
import {
  SET_INVENTORY,
  InventoryAction,
  BEGIN_FETCH,
  FETCH_COMPLETED,
  SET_EQUIPMENTS,
  SET_CONTAINER_FILTERS,
  SET_EQUIVALENCES,
  RESET_EQUIVALENCES,
  SET_TOTALS_INDICATORS,
} from './types';
import { Reducer, combineReducers } from 'redux';
import { RootState } from 'store/configureStore';
import {
  initContainerFilters,
  initEquivalence,
  initLiterTotals,
} from 'commons/initTypes';

export interface InventoryList {
  content?: Container[];
  number?: number;
  totalElements?: number;
}

export interface InventoryState {
  readonly inventory: InventoryList;
  readonly isFetching: boolean;
  readonly equipments: Equipment[];
  readonly containerFilters: ContainerFilters;
  readonly equivalences: Equivalence[];
  readonly totalIndicators: LiterTotals;
}

const isFetching: Reducer<boolean> = (
  state = false,
  action: InventoryAction,
): boolean => {
  switch (action.type) {
    case BEGIN_FETCH:
      return true;
    case FETCH_COMPLETED:
      return false;
    default:
      return state;
  }
};

const inventory: Reducer<InventoryList> = (
  state: InventoryList = { content: [] },
  action,
): InventoryList => {
  if (action.type === SET_INVENTORY) {
    return { ...state, ...action.inventory };
  }
  return state;
};

const equipments: Reducer<Equipment[]> = (
  state: Equipment[] = [],
  action,
): Equipment[] => {
  if (action.type === SET_EQUIPMENTS) {
    return { ...state, ...action.equipments };
  }
  return state;
};

const containerFilters: Reducer<ContainerFilters> = (
  state: ContainerFilters = initContainerFilters,
  action,
): ContainerFilters => {
  if (action.type === SET_CONTAINER_FILTERS) {
    return { ...state, ...action.containerFilters };
  }
  return state;
};

const equivalences: Reducer<Equivalence[]> = (
  state: Equivalence[] = [],
  action,
): Equivalence[] => {
  switch (action.type) {
    case SET_EQUIVALENCES:
      return { ...state, ...action.equivalences };
    case RESET_EQUIVALENCES:
      return [initEquivalence];
    default:
      return state;
  }
};

const totalIndicators: Reducer<LiterTotals> = (
  state: LiterTotals = initLiterTotals,
  action,
): LiterTotals => {
  if (action.type === SET_TOTALS_INDICATORS) {
    return { ...state, ...action.totalIndicators };
  }
  return state;
};

export const getIsFetching = (store: RootState): boolean =>
  store.inventoryState.isFetching;

export default combineReducers<InventoryState>({
  isFetching,
  inventory,
  equipments,
  containerFilters,
  equivalences,
  totalIndicators,
});
