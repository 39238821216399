import React, { Component } from 'react';
import { RootState } from 'store/configureStore';
import { connect } from 'react-redux';
import {
  CollectionRoute,
  InvoiceForPortfolio,
  Portfolio,
  RoutePortfolio,
  RoutesFilters,
  RoutesPage,
} from 'types/type';
import {
  Button,
  Col,
  DatePicker,
  Icon,
  InputNumber,
  Pagination,
  Row,
  Select,
  Table,
  Tooltip,
  Popconfirm,
} from 'antd';
import {
  DATE_FORMAT,
  getEndTime,
  getInitialTime,
} from '../../commons/services';
import Text from 'antd/lib/typography/Text';
import { initCollectionRoute, initroutesFilters } from 'commons/initTypes';
import { Link } from 'react-router-dom';
import { isAllowed } from 'security';
import { Permissions as P } from 'commons/permissions';
import {
  getPage,
  getPdf,
  startCollectionRoute,
  closeRoute,
  loadCollRouteDetails,
  loadPortfoliosToAdd,
  addPortfoliosToRoute,
  loadCustomersToMove,
  loadOpenedRoutes,
  moveCustomersToRoute,
} from './redux/actions';
import { isDateWrong } from 'routes/functions';
import moment, { Moment } from 'moment';
import { toNumber } from 'commons/numbersFormat';
import { getPortfolioStatusText, portfoliosInfoColumns } from './functions';
import AddPortfolioModal from './components/addPortfolioModal';
import './styles.css';
import { Popups } from 'commons/components/popups/popups';
import MoveCustomerModal from './components/moveCustomerModal';

let filters: RoutesFilters = initroutesFilters;
interface DispatchProps {
  getPage: (filters: RoutesFilters) => void;
  getPdf: (id: string) => void;
  startCollectionRoute: (id: string) => void;
  closeRoute: (id: string) => void;
  loadCollRouteDetails: (id: string) => void;
  loadPortfoliosToAdd: (initDate: Moment, endDate: Moment) => void;
  addPortfoliosToRoute: (id: string, portfolios: Portfolio[]) => void;
  loadCustomersToMove: (routeId: string) => void;
  loadOpenedRoutes: (id: string) => void;
  moveCustomersToRoute: (
    originRoute: CollectionRoute,
    destinationRoute: CollectionRoute,
    customers: InvoiceForPortfolio[],
  ) => void;
}
interface StateProps {
  routesPage: RoutesPage;
  isFetching: boolean;
  collRouteDetails: RoutePortfolio[];
  portfoliosToAdd: Portfolio[];
  customersToMove: InvoiceForPortfolio[];
  openedRoutes: CollectionRoute[];
}

type Props = StateProps & DispatchProps;
class RoutesComponent extends Component<Props> {
  public state = {
    stateFilters: initroutesFilters,
    expandedRowKeys: [''],
    isAddModalVisible: false,
    selectedCollRoute: initCollectionRoute,
    isMoveCVisible: false,
    routeToMoveCustomers: initCollectionRoute,
  };

  private loadInfo = async (filters: RoutesFilters): Promise<void> => {
    await this.props.getPage(filters);
  };
  public componentDidMount(): void {
    this.loadInfo(filters);
  }
  private fetchPageInfo = async (
    filtersApplied: RoutesFilters,
  ): Promise<void> => {
    await this.props.getPage(filtersApplied);
  };

  private fetchCollRouteDetails = async (id: string): Promise<void> =>
    await this.props.loadCollRouteDetails(id);

  private fetchPortfoliosInfoToAdd = (record: CollectionRoute): void => {
    const loadPortfolios = async (): Promise<void> => {
      await this.props.loadPortfoliosToAdd(getInitialTime(), getEndTime());
      this.setState({
        isAddModalVisible: true,
        selectedCollRoute: record,
      });
    };
    loadPortfolios();
  };
  private onCancelAddPortfolios = (): void =>
    this.setState({
      isAddModalVisible: false,
      selectedCollRoute: initCollectionRoute,
    });

  private onSaveAddPortfolios = (id: string, portfolios: Portfolio[]): void => {
    if (portfolios.length < 1) {
      Popups.notifyError('No se ha seleccionado ninguna cartera');
      return;
    }
    const addPortfolios = async (): Promise<void> => {
      await this.props.addPortfoliosToRoute(id, portfolios);
      this.setState({
        isAddModalVisible: false,
        selectedCollRoute: initCollectionRoute,
      });
      await this.props.getPage(filters);
    };
    addPortfolios();
  };

  private onCancelMoveCustomer = (): void =>
    this.setState({
      isMoveCVisible: false,
      routeToMoveCustomers: initCollectionRoute,
    });

  private onSaveMoveCustomer = (
    originRoute: CollectionRoute,
    destinationId: string,
    customers: InvoiceForPortfolio[],
  ): void => {
    if (customers.length < 1) {
      Popups.notifyError('No se ha seleccionado ningún cliente');
      return;
    }
    if (destinationId === '') {
      Popups.notifyError('No se ha seleccionado la ruta');
      return;
    }

    // Get destination route info
    let destinationRoute =
      this.props.openedRoutes.find(
        (route): boolean => route.id === destinationId,
      ) || initCollectionRoute;

    const moveCustomer = async (): Promise<void> => {
      await this.props.moveCustomersToRoute(
        originRoute,
        destinationRoute,
        customers,
      );
      this.setState({
        isMoveCVisible: false,
        routeToMoveCustomers: initCollectionRoute,
      });
      await this.props.getPage(filters);
    };
    moveCustomer();
  };

  private onClickMoveC = (record: CollectionRoute): void => {
    const loadData = async (): Promise<void> => {
      await this.props.loadCustomersToMove(record.id);
      await this.props.loadOpenedRoutes(record.id);
      this.setState({ isMoveCVisible: true, routeToMoveCustomers: record });
    };
    loadData();
  };

  private handlePaginationChange = async (
    currentPage: number,
    pageSize?: number,
  ): Promise<void> => {
    filters.pageNumber = currentPage;
    filters.pageSize = pageSize || 25;
    await this.props.getPage(filters);
  };
  private searchButtonComponent = (): JSX.Element => {
    const { isFetching } = this.props;
    return (
      <Col
        xs={24}
        sm={12}
        md={3}
        lg={3}
        xl={2}
        xxl={2}
        className="separated-filters"
      >
        <Button
          type="primary"
          size={'default'}
          onClick={(): void => {
            this.fetchPageInfo(filters);
          }}
          disabled={isFetching}
          style={{ marginTop: '20px', marginRight: '10px' }}
        >
          <Icon type="search" />
          {'Buscar'}
        </Button>
      </Col>
    );
  };
  private renderGeneralFilters = (): JSX.Element => {
    const { isFetching } = this.props;
    return (
      <Row className={'content-backgroud'}>
        <Col
          xs={24}
          sm={24}
          md={6}
          lg={5}
          xl={8}
          xxl={6}
          className="separated-filters"
        >
          <div>
            <Text className="font-color-Labeln">Filtrar por fecha</Text>
          </div>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <DatePicker
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
              disabled={isFetching}
              disabledDate={(date: moment.Moment | undefined): boolean =>
                isDateWrong(date, moment(filters.endDate))
              }
              format={DATE_FORMAT}
              placeholder="Desde"
              onOk={(selectedTime: moment.Moment): void => {
                filters.initDate = selectedTime.toISOString();
              }}
              defaultValue={moment(filters.initDate)}
              style={{ width: '100%', marginBottom: '10px' }}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <DatePicker
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
              disabled={isFetching}
              disabledDate={(date: moment.Moment | undefined): boolean =>
                isDateWrong(moment(filters.initDate), date)
              }
              format={DATE_FORMAT}
              placeholder="Hasta"
              onOk={(selectedTime: moment.Moment): void => {
                filters.endDate = selectedTime.toISOString();
              }}
              defaultValue={moment(filters.endDate)}
              style={{ width: '100%', marginBottom: '10px' }}
            />
          </Col>
        </Col>
        <Col
          xs={12}
          sm={6}
          md={3}
          lg={3}
          xl={2}
          xxl={2}
          className="separated-filters"
        >
          <div>
            <Text className="font-color-Labeln">Folio</Text>
          </div>
          {
            <InputNumber
              placeholder="Folio"
              disabled={isFetching}
              onChange={(value: number | undefined): void => {
                filters.folio = value;
              }}
              style={{ width: '100%', marginBottom: '10px' }}
              min={0}
              step={1}
              precision={0}
              defaultValue={filters.folio}
            />
          }
        </Col>
        <Col
          xs={24}
          sm={12}
          md={7}
          lg={6}
          xl={5}
          xxl={4}
          className="separated-filters"
        >
          <div>
            <Text className="font-color-Labeln">Cobrador</Text>
          </div>
          {
            <Select
              style={{ width: '100%' }}
              className={'sale-filters-size'}
              defaultValue={''}
              disabled={isFetching}
            >
              <Select.Option value="">Todos</Select.Option>
            </Select>
          }
        </Col>
        <Col
          xs={12}
          sm={6}
          md={3}
          lg={3}
          xl={2}
          xxl={2}
          className="separated-filters"
        >
          <div>
            <Text className="font-color-Labeln">Estatus</Text>
          </div>
          {
            <Select
              style={{ width: '100%', paddingBottom: '10px' }}
              onChange={(value: string): void => {
                filters.status = value;
              }}
              defaultValue={filters.status}
              disabled={isFetching}
            >
              <Select.Option value="">Todas</Select.Option>
              <Select.Option value="ABIERTA">Abierta</Select.Option>
              <Select.Option value="ASIGNADA">Asignada</Select.Option>
              <Select.Option value="FINALIZADA">Finalizada</Select.Option>
            </Select>
          }
        </Col>
        {this.searchButtonComponent()}
        {//Show button of 'Mi cartera' whem user is portfolio manager
        !isAllowed([P.IS_PORTFOLIO_MANAGER]) ? (
          <div />
        ) : (
          <Col
            xs={8}
            sm={6}
            md={4}
            lg={4}
            xl={4}
            xxl={2}
            className="separated-filters"
          >
            <Link
              key="1"
              to={{
                pathname: '/portfolios',
              }}
              className="separated-filters"
            >
              <Button
                type="primary"
                id="Mi_cartera"
                style={{ marginTop: '20px' }}
                disabled={isFetching}
              >
                Mi cartera
              </Button>
            </Link>
          </Col>
        )}
        <Col
          xs={8}
          sm={6}
          md={4}
          lg={4}
          xl={4}
          xxl={2}
          className="separated-filters"
        >
          <Link
            key="1"
            to={{
              pathname: '/CreateRoute',
            }}
          >
            <Button
              type="primary"
              id="Generar_rutas"
              style={{ marginTop: '20px' }}
              disabled={isFetching}
            >
              Generar rutas
            </Button>
          </Link>
        </Col>
      </Row>
    );
  };

  private initAction = (record: CollectionRoute): void => {
    const asyncAction = async (): Promise<void> => {
      await this.props.startCollectionRoute(record.id);
      await this.props.getPdf(record.id);
      await this.props.getPage(filters);
    };
    asyncAction();
  };
  public render = (): React.ReactNode => {
    const { isFetching, routesPage, collRouteDetails } = this.props;

    return (
      <div>
        {this.renderGeneralFilters()}
        {this.state.isAddModalVisible && (
          <Col>
            <AddPortfolioModal
              isVisible={this.state.isAddModalVisible}
              collRoute={this.state.selectedCollRoute}
              portfolios={this.props.portfoliosToAdd}
              onCancel={this.onCancelAddPortfolios}
              onSave={this.onSaveAddPortfolios}
            />
          </Col>
        )}
        {this.state.isMoveCVisible && (
          <MoveCustomerModal
            isVisible={this.state.isMoveCVisible}
            originRoute={this.state.routeToMoveCustomers}
            customers={this.props.customersToMove}
            routes={this.props.openedRoutes}
            onCancel={this.onCancelMoveCustomer}
            onSave={this.onSaveMoveCustomer}
          />
        )}
        <Col
          xs={0}
          sm={0}
          md={0}
          lg={0}
          xl={24}
          xxl={24}
          style={{ backgroundColor: 'white' }}
        >
          <Table
            pagination={false}
            rowKey={(record: CollectionRoute): string => record.id}
            dataSource={this.props.routesPage.content || []}
            loading={isFetching}
            expandedRowKeys={this.state.expandedRowKeys}
            onExpand={(expand: boolean, record: CollectionRoute): void => {
              if (expand) {
                this.fetchCollRouteDetails(record.id);
                this.setState({ expandedRowKeys: [record.id] });
              } else this.setState({ expandedRowKeys: [''] });
            }}
            expandedRowRender={(): JSX.Element => (
              <Table
                className={'custom-table'}
                dataSource={collRouteDetails}
                pagination={false}
                rowKey={(record: RoutePortfolio): string => record.chargeId}
                columns={portfoliosInfoColumns()}
                size="small"
              />
            )}
          >
            <Table.Column title="Folio" dataIndex="folio.id" />
            <Table.Column title="Cobrador" dataIndex="debCollectorName" />
            <Table.Column
              title="Fecha"
              dataIndex="updatedAt"
              render={(createdAt: string): React.ReactNode =>
                moment(createdAt).format(DATE_FORMAT)
              }
            />
            <Table.Column
              title="Clientes a visitar"
              dataIndex="customersToVisit"
            />
            <Table.Column
              title="Km a recorrer aprox."
              dataIndex="kilometers"
              render={(kilometers: number): React.ReactNode => {
                return toNumber(kilometers) + ' Km';
              }}
            />
            <Table.Column
              title="Total a cobrar"
              dataIndex="totalAmount"
              render={(total: number): React.ReactNode => {
                return '$ ' + toNumber(total);
              }}
            />
            <Table.Column
              title="Estatus"
              dataIndex="status"
              render={(
                status: string,
                record: CollectionRoute,
              ): React.ReactNode =>
                getPortfolioStatusText(status) + ' ' + record.percentage + '%'
              }
            />
            <Table.Column
              title="Acciones"
              dataIndex="Accions"
              key="Accions"
              render={(
                text: string,
                record: CollectionRoute,
              ): React.ReactNode => {
                return (
                  <Row>
                    {record.status === 'ABIERTA' && (
                      <div>
                        <Button
                          className="ant-button"
                          type="link"
                          onClick={(): void => this.initAction(record)}
                        >
                          Iniciar
                        </Button>
                        <Button
                          className="ant-button"
                          type="link"
                          onClick={(): void => this.onClickMoveC(record)}
                        >
                          Mover clientes
                        </Button>
                      </div>
                    )}
                    {record.status === 'ASIGNADA' && (
                      <Popconfirm
                        title="¿Realmente desea cerrar la ruta?"
                        onConfirm={(): void => this.props.closeRoute(record.id)}
                        okText="Si"
                        cancelText="No"
                      >
                        <Button className="ant-button" type="link">
                          Cerrar
                        </Button>
                      </Popconfirm>
                    )}
                    {record.status !== 'FINALIZADA' && (
                      <Tooltip title="Agregar carteras">
                        <Button
                          className="ant-button"
                          type="link"
                          onClick={(): void =>
                            this.fetchPortfoliosInfoToAdd(record)
                          }
                        >
                          <Icon type="plus" />
                        </Button>
                      </Tooltip>
                    )}
                    <Tooltip title="Comprobante">
                      <Icon
                        type="file"
                        theme="twoTone"
                        style={{ fontSize: '23px' }}
                        onClick={(): void => this.props.getPdf(record.id)}
                      />
                    </Tooltip>
                  </Row>
                );
              }}
            />
          </Table>
        </Col>

        <Pagination
          showSizeChanger
          pageSizeOptions={['25', '50', '75', '100']}
          defaultPageSize={25}
          disabled={isFetching}
          current={(routesPage.number || 0) + 1}
          total={routesPage.totalElements}
          onShowSizeChange={this.handlePaginationChange}
          onChange={this.handlePaginationChange}
        />
      </div>
    );
  };
}

const mapStateToProps = (states: RootState): StateProps => {
  return {
    routesPage: states.routesState.routesPage,
    isFetching: states.routesState.isFetching,
    collRouteDetails: Object.values(states.routesState.collRouteDetails),
    portfoliosToAdd: Object.values(states.routesState.portfoliosToAdd),
    customersToMove: Object.values(states.routesState.customersToMove),
    openedRoutes: Object.values(states.routesState.openedRoutes),
  };
};

const mapDispatchToProps: DispatchProps = {
  getPage,
  getPdf,
  startCollectionRoute,
  closeRoute,
  loadCollRouteDetails,
  loadPortfoliosToAdd,
  addPortfoliosToRoute,
  loadCustomersToMove,
  loadOpenedRoutes,
  moveCustomersToRoute,
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(RoutesComponent);
