import { Button, Divider, Icon, Modal, Row } from 'antd';
import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Clarification } from 'types/type';

interface CounterProps {
  isFetching: boolean;
  isVisible: boolean;
  clarifications: Clarification[];
  onCancel: Function;
}

class ClarificationHistory extends Component<CounterProps> {
  public render = (): JSX.Element => {
    const { isVisible, onCancel, clarifications } = this.props;
    return (
      <Modal
        width={600}
        visible={isVisible}
        title={'Historial de aclaraciones'}
        cancelText="Cancelar"
        onOk={onCancel()}
        onCancel={onCancel()}
        centered
      >
        {clarifications.length <= 0 ? (
          <div>
            <Icon type="exclamation-circle" />
            {' No se encontraron aclaraciones'}
          </div>
        ) : (
          <Row>
            <div className="clarifications-scroll-container">
              <InfiniteScroll
                initialLoad={false}
                pageStart={0}
                loadMore={(): void => {}}
                hasMore={false}
                useWindow={false}
              >
                {clarifications.map(
                  (
                    clarification: Clarification,
                    index: number,
                  ): React.ReactNode => (
                    <div key={index}>
                      <table>
                        <tbody>
                          <tr>
                            <td className="history-item-first-column">
                              Fecha:
                            </td>
                            <td>{clarification.date}</td>
                            <td className="history-item-next-columns">
                              Usuario:
                            </td>
                            <td>{clarification.userEmail}</td>
                          </tr>
                          <tr>
                            <td className="history-item-first-column">
                              Archivo:
                            </td>
                            <td>
                              {
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={clarification.imageUrl}
                                >
                                  <Button
                                    type="primary"
                                    shape="round"
                                    size={'small'}
                                  >
                                    <Icon type={'download'} />
                                    {' Descargar'}
                                  </Button>
                                </a>
                              }
                            </td>
                            <td className="history-item-next-columns">
                              Descripción:
                            </td>
                            <td>{clarification.comment}</td>
                          </tr>
                        </tbody>
                      </table>
                      <Divider />
                    </div>
                  ),
                )}
              </InfiniteScroll>
            </div>
          </Row>
        )}
      </Modal>
    );
  };
}

export default ClarificationHistory;
