import React, { Component } from 'react';
import { Moment } from 'moment';
import { connect } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { totalCalculators } from '../sales/redux/actions';
import './style.css';
import { DashboardValues, DistributionCenter, Equipment } from 'types/type';
import { RouteComponentProps } from 'react-router';
import { Row, Col, Select, Radio, InputNumber } from 'antd';
import DistributionCenterSaleBarChart from './widgets/distributionCenterSaleBarChart';
import EquipmentSaleBarChart from './widgets/equipmentSaleBarChart';
import PaymentConditionPieChart from './widgets/paymentConditionPieChart';
import PendingBalancesDistributionCenterTable from './widgets/pendingBalancesDistributionCenterTable';
import SalesDistributionCenterLineChart from './widgets/salesDistributionCenterLineChart';
import MinIndicators from './widgets/mainIndicators';
import SalesHeatMap from './widgets/salesHeatMap';
import { DatePicker } from 'antd';
import axios, { AxiosResponse } from 'axios';
import { createCoreServiceUrl } from 'commons/services';
import { isAllowed, getToken, Token } from 'security';
import JwtDecode from 'jwt-decode';
import { initDistributionCenter } from 'commons/initTypes';
import { Permissions as P } from 'commons/permissions';
import { sizeScreen } from 'routes/functions';
import Text from 'antd/lib/typography/Text';
const { RangePicker } = DatePicker;
// eslint-disable-next-line @typescript-eslint/no-var-requires
let moment = require('moment-timezone');

interface DispatchProps {
  totalCalculators: () => void;
}

interface StateProps {
  total: DashboardValues;
}

type Props = StateProps & DispatchProps & RouteComponentProps;

interface DashboardState {
  initialDate: Moment;
  endDate: Moment;
  distributionCenters: DistributionCenter[];
  isRangePickerVisible: boolean;
  radioSelected: string;
  kilogramsValue: number;
  equipment: Equipment[];
  paymentMethods: string[];
}

class Dashboard extends Component<Props, DashboardState> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      initialDate: moment().set({ hour: 0, minute: 0, second: 0 }),
      endDate: moment().set({ hour: 23, minute: 59, second: 59 }),
      distributionCenters: [],
      isRangePickerVisible: false,
      radioSelected: 'amount',
      kilogramsValue: 0.56,
      equipment: [],
      paymentMethods: [],
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private updateDateRange = (current: any[]): void => {
    if (current.length < 2) return;
    const initialDate = current[0].set({ hour: 0, minute: 0, second: 0 });
    const endDate = current[1].set({ hour: 23, minute: 59, second: 59 });
    this.setState({
      initialDate,
      endDate,
    });
  };

  public componentDidMount(): void {
    let distributionCenterId = '';
    if (!isAllowed([P.CAN_VIEW_ALL_DISTRIBUTION_CENTERS])) {
      distributionCenterId = JwtDecode<Token>(getToken() as string)
        .distributionCenterId;
    }
    this.getEquipoment(distributionCenterId);
    this.getDistributionCenters();
    if (this.state.radioSelected === 'amount') {
      this.setState({ paymentMethods: ['Contado', 'Credito'] });
    } else {
      this.setState({
        paymentMethods: ['Transferencia', 'Auto consumo', 'Contado', 'Credito'],
      });
    }
  }

  private getEquipoment = (
    distributionCenterId: string,
  ): Promise<Equipment[]> =>
    axios({
      method: 'post',
      url: createCoreServiceUrl(`settlement/equipment`),
      data: { distributionCenterId },
    }).then((res: AxiosResponse<Equipment[]>): Equipment[] => {
      this.setState({ equipment: res.data });
      return res.data;
    });

  private getDistributionCenters = (): void => {
    axios
      .get(createCoreServiceUrl('company/distribuciosncenters'))
      .then((response: AxiosResponse<DistributionCenter[]>): void => {
        const userDistCenter = JwtDecode<Token>(getToken() as string)
          .distributionCenterId;
        const distributionCenters = isAllowed([
          P.CAN_VIEW_ALL_DISTRIBUTION_CENTERS,
        ])
          ? response.data
          : [
              // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
              response.data.find(dc => dc.id === userDistCenter) ||
                initDistributionCenter,
            ];
        this.setState({ distributionCenters });
      });
  };

  private manageSelectDates = (value: string): void => {
    this.setState({ isRangePickerVisible: false });
    switch (value) {
      case '1day':
        this.setState({
          initialDate: moment().set({ hour: 0, minute: 0, second: 0 }),
          endDate: moment().set({ hour: 23, minute: 59, second: 59 }),
        });
        break;
      case '1week':
        this.setState({
          initialDate: moment()
            .set({ hour: 23, minute: 59, second: 59 })
            .subtract(7, 'days'),
          endDate: moment().set({ hour: 23, minute: 59, second: 59 }),
        });
        break;
      case '2weeks':
        this.setState({
          initialDate: moment()
            .set({ hour: 23, minute: 59, second: 59 })
            .subtract(2, 'weeks'),
          endDate: moment().set({ hour: 23, minute: 59, second: 59 }),
        });
        break;
      case '1month':
        this.setState({
          initialDate: moment()
            .set({ hour: 23, minute: 59, second: 59 })
            .subtract(1, 'months'),
          endDate: moment().set({ hour: 23, minute: 59, second: 59 }),
        });
        break;
      case '3months':
        this.setState({
          initialDate: moment()
            .set({ hour: 23, minute: 59, second: 59 })
            .subtract(3, 'months'),
          endDate: moment().set({ hour: 23, minute: 59, second: 59 }),
        });
        break;
      case '6months':
        this.setState({
          initialDate: moment()
            .set({ hour: 23, minute: 59, second: 59 })
            .subtract(6, 'months'),
          endDate: moment().set({ hour: 23, minute: 59, second: 59 }),
        });
        break;
      case '1year':
        this.setState({
          initialDate: moment()
            .set({ hour: 23, minute: 59, second: 59 })
            .subtract(1, 'years'),
          endDate: moment().set({ hour: 23, minute: 59, second: 59 }),
        });
        break;
      case 'other':
        this.setState({ isRangePickerVisible: true });
        break;
      default:
        break;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onRadioChange = (e: any): void => {
    if (e.target.value === 'amount') {
      this.setState({ paymentMethods: ['Contado', 'Credito'] });
    } else {
      this.setState({
        paymentMethods: ['Transferencia', 'Auto consumo', 'Contado', 'Credito'],
      });
    }
    console.log('Mostrando valores desde el estado index: ');
    console.log(this.state.paymentMethods);
    this.setState({ radioSelected: e.target.value, kilogramsValue: 0.56 });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onChangeKilograms = (value: any): void => {
    this.setState({ kilogramsValue: value });
  };

  public render = (): React.ReactNode => {
    return (
      <div>
        <Row gutter={10}>
          <Col {...sizeScreen(4)}>
            <Text strong>Seleccionar rango de fecha:</Text>
            <Select
              defaultValue="1day"
              style={{ width: '100%' }}
              onChange={this.manageSelectDates}
            >
              <Select.Option value="1day">Hoy</Select.Option>
              <Select.Option value="1week">Última semana</Select.Option>
              <Select.Option value="2weeks">Últimas 2 semanas</Select.Option>
              <Select.Option value="1month">Último mes</Select.Option>
              <Select.Option value="3months">Últimos 3 meses</Select.Option>
              <Select.Option value="6months">Últimos 6 meses</Select.Option>
              <Select.Option value="1year">Último año</Select.Option>
              <Select.Option value="other">Otro</Select.Option>
            </Select>
          </Col>
          {this.state.isRangePickerVisible && (
            <Col {...sizeScreen(4)}>
              <Text strong>Desde - Hasta:</Text>
              <RangePicker onCalendarChange={this.updateDateRange} />
            </Col>
          )}
          <Col {...sizeScreen(8)}>
            <Text strong>Seleccionar valor a mostrar: </Text>
            <Radio.Group
              onChange={this.onRadioChange}
              value={this.state.radioSelected}
            >
              <Radio value="amount">Monto $</Radio>
              <Radio value="volume">Litros</Radio>
              <Radio value="weight">Kilogramos</Radio>
            </Radio.Group>
          </Col>
          {this.state.radioSelected === 'weight' && (
            <Col {...sizeScreen(5)}>
              <Text strong>Factor de conversión, Kg/Litro: </Text>
              <InputNumber
                min={0}
                step={0.1}
                defaultValue={0.56}
                onChange={this.onChangeKilograms}
              />
            </Col>
          )}
        </Row>
        <Row style={{ marginTop: 10 }}>
          <MinIndicators
            initialDate={this.state.initialDate.toISOString()}
            endDate={this.state.endDate.toISOString()}
            distributionCenters={this.state.distributionCenters}
            radioSelected={this.state.radioSelected}
            kilogramsValue={this.state.kilogramsValue}
            equipment={this.state.equipment}
            paymentMethods={this.state.paymentMethods}
          />
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col {...sizeScreen(12)}>
            <Row style={{ height: 400 }}>
              <DistributionCenterSaleBarChart
                initialDate={this.state.initialDate.toISOString()}
                endDate={this.state.endDate.toISOString()}
                distributionCenters={this.state.distributionCenters}
                radioSelected={this.state.radioSelected}
                kilogramsValue={this.state.kilogramsValue}
                equipment={this.state.equipment}
                paymentMethods={this.state.paymentMethods}
              />
            </Row>
          </Col>
          <Col {...sizeScreen(12)}>
            <Row>
              <div style={{ height: 400 }}>
                <PaymentConditionPieChart
                  initialDate={this.state.initialDate.toISOString()}
                  endDate={this.state.endDate.toISOString()}
                  distributionCenters={this.state.distributionCenters}
                  radioSelected={this.state.radioSelected}
                  kilogramsValue={this.state.kilogramsValue}
                  equipment={this.state.equipment}
                  paymentMethods={this.state.paymentMethods}
                />
              </div>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col {...sizeScreen(12)}>
            <PendingBalancesDistributionCenterTable
              initialDate={this.state.initialDate.toISOString()}
              endDate={this.state.endDate.toISOString()}
              distributionCenters={this.state.distributionCenters}
              radioSelected={this.state.radioSelected}
              kilogramsValue={this.state.kilogramsValue}
              equipment={this.state.equipment}
              paymentMethods={this.state.paymentMethods}
            />
          </Col>
          <Col {...sizeScreen(12)}>
            <div style={{ height: 300 }}>
              <SalesDistributionCenterLineChart
                initialDate={this.state.initialDate.toISOString()}
                endDate={this.state.endDate.toISOString()}
                distributionCenters={this.state.distributionCenters}
                radioSelected={this.state.radioSelected}
                kilogramsValue={this.state.kilogramsValue}
                equipment={this.state.equipment}
                paymentMethods={this.state.paymentMethods}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col {...sizeScreen(12)}>
            <div style={{ height: 400 }}>
              <SalesHeatMap
                initialDate={this.state.initialDate.toISOString()}
                endDate={this.state.endDate.toISOString()}
                distributionCenters={this.state.distributionCenters}
                radioSelected={this.state.radioSelected}
                kilogramsValue={this.state.kilogramsValue}
                equipment={this.state.equipment}
                paymentMethods={this.state.paymentMethods}
              />
            </div>
          </Col>
          <Col {...sizeScreen(12)}>
            <Row>
              <div style={{ height: 400 }}>
                <EquipmentSaleBarChart
                  initialDate={this.state.initialDate.toISOString()}
                  endDate={this.state.endDate.toISOString()}
                  distributionCenters={this.state.distributionCenters}
                  radioSelected={this.state.radioSelected}
                  kilogramsValue={this.state.kilogramsValue}
                  equipment={this.state.equipment}
                  paymentMethods={this.state.paymentMethods}
                />
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };
}

const mapStateToProps = (states: RootState): StateProps => {
  return {
    total: states.saleState.dashboardValues,
  };
};

const mapDispatchToProps = {
  totalCalculators,
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard);
