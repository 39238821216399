import Service from 'services/Service';
import axios, { AxiosResponse } from 'axios';
import { Equipment, GovernmentPermission, Pageable } from 'types/type';
import { createCoreServiceUrl, createParamsService } from 'commons/services';

class EquipmentsService extends Service {
  public getAllEquipments = (
    params: Record<string, any>, // eslint-disable-line
  ): Promise<Pageable> =>
    axios
      .get(createCoreServiceUrl('equipments') + createParamsService(params))
      .then((response: AxiosResponse<Pageable>): Pageable => response.data);

  public updateEquipment = (equipment: Equipment): Promise<Equipment> =>
    axios
      .put(
        createCoreServiceUrl(`equipments/${equipment.id}/govPermission`),
        equipment,
      )
      .then((response: AxiosResponse<Equipment>): Equipment => response.data);

  public getGovenrmentPermissionsList = (): Promise<GovernmentPermission[]> =>
    axios
      .get(createCoreServiceUrl('governmentPermissions'))
      .then(
        (
          response: AxiosResponse<GovernmentPermission[]>,
        ): GovernmentPermission[] => response.data,
      );
}

export default EquipmentsService;
