/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by David Canul <david@ssf.com.mx>, Jun 2019
 *
 *
 */
import SaleService from '../../../services/sale';
import { parseApiError, parseStringError } from '../../../commons/error';
import { notification } from 'antd';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {
  SaleAction,
  BEGIN_FETCH,
  FETCH_COMPLETED,
  SET_SALE,
  SET_TOTAL,
  GET_DISTRIBUTION_CENTER,
  GET_EQUIPMENTS,
  RESET_EQUIPMENTS,
  SET_TOTAL_DASHBOARD,
  SET_SALE_FILTERS,
  SET_TOTALS_INDICATORS,
} from './types';
import { getIsFetching } from './reducers';
import { RootState } from 'store/configureStore';
import {
  SaleFilters,
  Pageable,
  DistributionCenter,
  Equipment,
  TotalsSaleIssued,
} from 'types/type';
import CompanyService from 'services/company';
import SettlementService from 'services/settlement';
import StatementService from 'services/statement';
import { Moment } from 'moment';

const saleService = new SaleService();
const companyService = new CompanyService();
const settlementService = new SettlementService();
const statementService = new StatementService();

/** Quantity of registers allowed */
const rowsAllowed = 10000;

const startFetch = (): SaleAction => {
  return { type: BEGIN_FETCH };
};
type ITA = ThunkAction<Promise<void>, RootState, {}, SaleAction>;
type IDA = ThunkDispatch<{}, {}, SaleAction>;
/**
 * This action creator is not public beca1use it should be used
 * automatically by other action creators that need fetch data from
 * backend.
 */
const endFetch = (): SaleAction => {
  return { type: FETCH_COMPLETED };
};

/**
 *This action give sales from db by filters(initDate,endDte,Sale status and Page)
 */

export const downloadFile = (
  id: string,
  name: string,
  saleFolio: string,
): ITA => async (): Promise<void> => {
  saleService.downloadFile(id, name, saleFolio);
};

export const totalCalculator = (
  initDate: Moment,
  endDate: Moment,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }

  try {
    dispatch(startFetch());
    const response = await saleService.totalCalculator(initDate, endDate);
    dispatch({ type: SET_TOTAL, dashboardValues: response });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};

export const totalCalculators = (): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }

  try {
    dispatch(startFetch());
    const response = await saleService.totalCalculators();
    dispatch({ type: SET_TOTAL_DASHBOARD, dashboardValue: response });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};

export const getDistributionCenterAction = (): ITA => {
  return async (dispatch: IDA): Promise<void> => {
    try {
      const response: DistributionCenter[] = await companyService.getDistrubutionCenter();
      dispatch({ type: GET_DISTRIBUTION_CENTER, distributionCenter: response });
    } catch (error) {
      notification.error(parseApiError(error));
    }
  };
};

export const getEquipmentsByDistributionCenter = (
  distriburionCenterId: string,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  dispatch(startFetch());
  try {
    const equipmentList: Equipment[] = await settlementService.settlementEquipmentList(
      distriburionCenterId,
    );
    dispatch({ type: RESET_EQUIPMENTS });
    dispatch({
      type: GET_EQUIPMENTS,
      equipments: equipmentList,
    });
  } catch (err) {
    notification.error(parseApiError(err));
  }
  dispatch(endFetch());
};

export const getSales = (saleFilters: SaleFilters): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) return Promise.resolve();
  dispatch(startFetch());
  try {
    const response: Pageable = await saleService.saleListGrouped(saleFilters);
    dispatch({ type: SET_SALE, saleList: response });

    //Verify the quantity of sales is the allowed to get the totals
    if (response.totalElements <= rowsAllowed && response.totalElements > 0) {
      const totalsIndicators: TotalsSaleIssued = await saleService.getTotalsSaleIssued(
        saleFilters,
      );
      dispatch({
        type: SET_TOTALS_INDICATORS,
        totalsSaleIssued: totalsIndicators,
      });
    } else {
      const totalsIndicators: TotalsSaleIssued = {
        totalInvoice: 0,
        totalWithoutInvoice: 0,
        finalTotal: 0,
      };
      dispatch({
        type: SET_TOTALS_INDICATORS,
        totalsSaleIssued: totalsIndicators,
      });
    }
  } catch (error) {
    notification.error(parseApiError(error));
  }
  dispatch(endFetch());
};

export const downloadTicket = (
  id: string,
  name: string,
  saleFolio: string,
): ITA => async (dispatch: IDA): Promise<void> => {
  console.log('llamando descargar ticket');
  dispatch(startFetch());
  try {
    await saleService.downloadTicket(id, saleFolio, name);
  } catch (error) {
    if (error.responce !== undefined) {
      const decoder: TextDecoder = new TextDecoder('utf-8');
      const decodedString: string = decoder.decode(
        new Uint8Array(error.response.data),
      );
      notification.error(parseStringError(decodedString));
    } else {
      notification.error(parseApiError(error));
    }
  }
  dispatch(endFetch());
};

export const updateInvoice = (
  saleFilters: SaleFilters,
  saleId: string,
  invoiceRequired: boolean,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) return Promise.resolve();
  dispatch(startFetch());
  try {
    await saleService.updateInvoice(saleId, invoiceRequired);

    const response: Pageable = await saleService.saleListGrouped(saleFilters);
    dispatch({ type: SET_SALE, saleList: response });
  } catch (error) {
    notification.error(parseApiError(error));
  }
  dispatch(endFetch());
};

export const setSaleFilters = (saleFilters: SaleFilters): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) return Promise.resolve();
  dispatch(startFetch());
  try {
    dispatch({ type: SET_SALE_FILTERS, saleFilters });
  } catch (error) {
    notification.error(parseApiError(error));
  }
  dispatch(endFetch());
};

export const downloadReport = (saleFilters: SaleFilters): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) return Promise.resolve();
  dispatch(startFetch());
  try {
    await saleService.getReport(saleFilters);
  } catch (error) {
    notification.error(parseApiError(error));
  }
  dispatch(endFetch());
};

export const downloadXmlZip = (saleFilters: SaleFilters): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }

  try {
    dispatch(startFetch());
    await saleService.generateXmlZip(saleFilters);
    dispatch(endFetch());
  } catch (e) {
    dispatch(endFetch());
    if (e.response !== undefined) {
      //Decode message error from backend and show it in a notification
      const decoder: TextDecoder = new TextDecoder('utf-8');
      const decodedString: string = decoder.decode(
        new Uint8Array(e.response.data),
      );
      notification.error(parseStringError(decodedString));
    } else {
      notification.error(parseApiError(e));
    }
  }
};

export const cancelSaleInvoice = (saleId: string): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) return Promise.resolve();

  try {
    dispatch(startFetch());
    await statementService.statementCancelation(saleId);
    dispatch(endFetch());
  } catch (error) {
    notification.error(parseApiError(error));
    dispatch(endFetch());
  }
};

export const downloadCancellationsReport = (
  filters: SaleFilters,
): ITA => async (dispatch: IDA, getState: Function): Promise<void> => {
  if (getIsFetching(getState())) return Promise.resolve();

  dispatch(startFetch());
  try {
    await saleService.generateCancellationsReport(filters);
  } catch (error) {
    notification.error(parseApiError(error));
  }
  dispatch(endFetch());
};
