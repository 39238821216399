/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Ricardo Sansores <ricardo@ssf.mx>, May 2019
 *
 *
 */

import { combineReducers, Reducer } from 'redux';
import { Company, DistributionCenter } from 'types/type';
import { initcompany, initDistributionCenter } from 'commons/initTypes';
import { RootState } from 'store/configureStore';
import {
  CompanyAction,
  BEGIN_FETCH,
  FETCH_COMPLETED,
  SET_DISTRIBUTION_CENTER,
  SET_COMPANY,
} from './types';

export interface CompanyI {
  company: Company;
}
export interface CompanyListI {
  content?: Company[];
  number?: number;
  totalElements?: number;
}
export interface DistributionCenterI {
  distributionCenter: DistributionCenter[];
}

export interface CompanyState {
  readonly company: Company;
  readonly companyList: CompanyListI;
  readonly distributionCenter: DistributionCenterI;
  readonly isFetching: boolean;
}

const company: Reducer<Company> = (
  state: Company = initcompany,
  action,
): Company => {
  if (action.type === 'SET') {
    return { ...state, ...action.company };
  }
  return state;
};
const companyList: Reducer<CompanyListI> = (
  state: CompanyListI = { content: [] },
  action,
): CompanyListI => {
  if (action.type === SET_COMPANY) {
    return { ...state, ...action.companyList };
  }
  return state;
};

const distributionCenter: Reducer<DistributionCenterI> = (
  state: DistributionCenterI = { distributionCenter: [initDistributionCenter] },
  action,
): DistributionCenterI => {
  if (action.type === SET_DISTRIBUTION_CENTER) {
    return { ...state, distributionCenter: action.distributionCenter };
  }
  return state;
};
const isFetching: Reducer<boolean> = (
  state = false,
  action: CompanyAction,
): boolean => {
  switch (action.type) {
    case BEGIN_FETCH:
      return true;
    case FETCH_COMPLETED:
      return false;
    default:
      return state;
  }
};
export const getIsFetching = (store: RootState): boolean =>
  store.invoiceState.isFetching;

export default combineReducers<CompanyState>({
  company,
  companyList,
  distributionCenter,
  isFetching,
});
