import axios, { AxiosResponse } from 'axios';
import { createCoreServiceUrl, createParamsService } from 'commons/services';
import Service from 'services/Service';
import {
  InvoiceForPortfolio,
  Pageable,
  CollectionRouteModel,
  Portfolio,
  PortfolioDTO,
  CollectionRoute,
} from 'types/type';
import moment from 'moment';

class PortfoliosService extends Service {
  // eslint-disable-next-line
  public getPageByFilters = (filters: Record<string, any>): Promise<Pageable> =>
    axios({
      method: 'get',
      url: createCoreServiceUrl(`portfolios`) + createParamsService(filters),
    }).then((response: AxiosResponse<Pageable>): Pageable => response.data);

  public getPortfolioList = (
    date: moment.Moment,
    user: string,
  ): Promise<Portfolio[]> =>
    axios({
      method: 'get',
      url: createCoreServiceUrl(`portfolios/`) + date.toString() + '/' + user,
    }).then(
      (response: AxiosResponse<Portfolio[]>): Portfolio[] => response.data,
    );

  // eslint-disable-next-line
  public getPortfolioInvoicesPage = (filters: Record<string, any>): Promise<Pageable> =>
    axios({
      method: 'get',
      url:
        createCoreServiceUrl(`portfolios/invoices`) +
        createParamsService(filters),
    }).then((response: AxiosResponse<Pageable>): Pageable => response.data);

  public save = (portfolioDto: PortfolioDTO): Promise<Portfolio> =>
    axios({
      method: 'post',
      url: createCoreServiceUrl(`portfolios`),
      data: portfolioDto,
    }).then((response: AxiosResponse<Portfolio>): Portfolio => response.data);

  public saveCollectionRoute = (
    routeDto: CollectionRouteModel,
  ): Promise<CollectionRoute> =>
    axios({
      method: 'post',
      url: createCoreServiceUrl(`collectionroute`),
      data: routeDto,
    }).then(
      (response: AxiosResponse<CollectionRoute>): CollectionRoute =>
        response.data,
    );
  public closeRoute = (routeId: string): Promise<boolean> =>
    axios({
      method: 'delete',
      url: createCoreServiceUrl(`collectionroute/closeroute/${routeId}`),
    }).then((response: AxiosResponse<boolean>): boolean => response.data);

  public closePortfolio = (porfolioId: string): Promise<boolean> =>
    axios({
      method: 'delete',
      url: createCoreServiceUrl(`portfolios/closeportfolio/${porfolioId}`),
    }).then((response: AxiosResponse<boolean>): boolean => response.data);

  public getInvoicesByPortfolioId = (
    portfolioId: string,
  ): Promise<InvoiceForPortfolio[]> =>
    axios({
      method: 'get',
      url: createCoreServiceUrl(`portfolios/${portfolioId}/invoices`),
    }).then(
      (response: AxiosResponse<InvoiceForPortfolio[]>): InvoiceForPortfolio[] =>
        response.data,
    );

  public update = (
    id: string,
    portfolioDto: PortfolioDTO,
  ): Promise<Portfolio> =>
    axios({
      method: 'put',
      url: createCoreServiceUrl(`portfolios/${id}`),
      data: portfolioDto,
    }).then((response: AxiosResponse<Portfolio>): Portfolio => response.data);

  public getOneOpenPortfolio = (endDate: string): Promise<Portfolio> =>
    axios({
      method: 'get',
      url:
        createCoreServiceUrl(`portfolios/status`) +
        '?status=ABIERTA' +
        `&endDate=${moment(endDate)} `,
    }).then((response: AxiosResponse<Portfolio>): Portfolio => response.data);

  public getPortfoliosToAdd = (
    initDate: string,
    endDate: string,
  ): Promise<Portfolio[]> =>
    axios({
      method: 'get',
      url:
        createCoreServiceUrl(`portfolios/assignedmanagers`) +
        createParamsService({ initDate, endDate }),
    }).then(
      (response: AxiosResponse<Portfolio[]>): Portfolio[] => response.data,
    );
}

export default PortfoliosService;
