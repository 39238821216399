export const iusRule = [
  {
    required: true,
    max: 24,
    min: 24,
    message: 'Ingresa un ius válido',
  },
  {
    pattern: /^([A-Fa-f0-9]{4})+-([A-Fa-f0-9]{4})+-([A-Fa-f0-9]{4})+-([A-Fa-f0-9]{4})+-([A-Fa-f0-9]{4})$/,
    message: 'Ingresa un ius válido',
  },
];

export const totalRule = [
  {
    required: true,
    message: 'Ingresa un monto',
  },
];
