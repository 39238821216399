import { Col, List, Row } from 'antd';
import React from 'react';
import { Portfolio } from 'types/type';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import { DATE_FORMAT } from 'commons/services';
import { toNumber } from 'commons/numbersFormat';

/** Returns the portfolios displayed as a list */
export const responsiveListPortfolios = (
  porfolios: Portfolio[],
): JSX.Element => {
  return (
    <List
      dataSource={porfolios}
      renderItem={(item: Portfolio): JSX.Element => {
        return (
          <List.Item key={item.id}>
            <List.Item.Meta
              description={
                <Row
                  style={{
                    background: '#FFFFFF',
                    padding: '10px',
                    margin: '-7px',
                  }}
                >
                  <Col xs={24} sm={12} lg={8} xl={8} xxl={8}>
                    <Text strong>{'Folio: '}</Text>
                    <Text>{item.folio}</Text>
                  </Col>
                  <Col xs={24} sm={12} lg={8} xl={8} xxl={8}>
                    <Text strong>{'Fecha: '}</Text>
                    <Text>{moment(item.createdAt).format(DATE_FORMAT)}</Text>
                  </Col>
                  <Col xs={24} sm={12} lg={8} xl={8} xxl={8}>
                    <Text strong>{'Clientes a visitar: '}</Text>
                    <Text>{item.customersToVisit}</Text>
                  </Col>
                  <Col xs={24} sm={12} lg={8} xl={8} xxl={8}>
                    <Text strong>{'Km a recorrer aprox.: '}</Text>
                    <Text>{toNumber(item.kilometers) + ' Km'}</Text>
                  </Col>
                  <Col xs={24} sm={12} lg={8} xl={8} xxl={8}>
                    <Text strong>{'Total a cobrar: '}</Text>
                    <Text>{'$ ' + toNumber(item.totalAmount)}</Text>
                  </Col>
                  <Col xs={24} sm={12} lg={8} xl={8} xxl={8}>
                    <Text strong>{'Estatus: '}</Text>
                    <Text>{item.status}</Text>
                  </Col>
                </Row>
              }
            ></List.Item.Meta>
          </List.Item>
        );
      }}
    />
  );
};
