/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Hiram Padilla <hiram@ssf.com.mx>, July 2020
 *
 * This component lists all the equipments
 */
import React, { Component } from 'react';
import { Row, Col, List, Table, Select, Pagination } from 'antd';
import { connect } from 'react-redux';
import {
  Equipment,
  DistributionCenter,
  GovernmentPermission,
  EquipmentFilters,
  Company,
} from 'types/type';
import Text from 'antd/lib/typography/Text';
import { RootState } from 'store/configureStore';
import {
  getAll,
  getDistributionCenters,
  getGovernmentPermissions,
  getAllCompany,
  save,
} from './redux/actions';
import { initDistributionCenter } from 'commons/initTypes';
import './styles.css';
import { Redirect } from 'react-router';
import { EquipmentList } from './redux/reducers';

//Properties from redux actions
interface DispatchProps {
  getAll: (equipmentFilters: EquipmentFilters) => void;
  getDistributionCenters: () => void;
  getGovernmentPermissions: () => void;
  getAllCompany: () => void;
  save: (equipment: Equipment) => void;
}

interface StateProps {
  equipments: EquipmentList;
  distributionCenters: DistributionCenter[];
  governmentPermissions: GovernmentPermission[];
  equipmentFilters: EquipmentFilters;
  isFetching: boolean;
  companyList: Company[]
}
//type to merge the props
type Props = DispatchProps & StateProps;

class Equipments extends Component<Props> {
  public state = {
    equipments: [],
  };

  private fetchData = (): void => {
    const asyncAction = async (): Promise<void> => {
      await this.props.getAllCompany();
      await this.props.getAll(this.props.equipmentFilters);
      await this.props.getDistributionCenters();
      await this.props.getGovernmentPermissions();
    }
    asyncAction();
  };

  public componentDidMount(): void {
    this.fetchData();
  }
  /**
   * This function render a responsive list of Equipments data, which is shown when the screen has a specific size
   */
  private renderResponsiveList = (): JSX.Element => {
    const distributionCenters = this.props.distributionCenters;
    return (
      <List
        dataSource={this.props.equipments.content}
        renderItem={(item: Equipment, index: number): JSX.Element => {
          //Find the distributionCenter by id. If it is not found, set an initialized
          const distributionC =
            distributionCenters.find(
              (d: DistributionCenter): boolean =>
                d.id === item.distributionCenterId,
            ) || initDistributionCenter;
          let rowStyle = 'white-row-list';
          if (item.governmentPermissionId === null) rowStyle = 'red-row-list';
          return (
            <List.Item>
              <List.Item.Meta
                key={index}
                description={
                  <Row className={rowStyle}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Text strong>{'Tag: '}</Text>
                      <Text>{item.tag}</Text>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Text strong>{'Número de equipo: '}</Text>
                      <Text>{item.number}</Text>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Text strong>{'Centro de distribución: '}</Text>
                      <Text>{distributionC.name}</Text>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Text strong>{'Permiso CRE: '}</Text>
                      <Select
                        value={item.governmentPermissionId}
                        size="default"
                        style={{ width: '60%' }}
                        onChange={(value: string): void => {
                          item.governmentPermissionId = value;
                          this.props.save(item);
                          //Set state to update what screen is showing in the list and table
                          this.setState({
                            equipments: this.props.equipments.content,
                          });
                        }}
                        disabled={this.props.isFetching}
                      >
                        {this.props.governmentPermissions.map(
                          (govPermission, govIndex): React.ReactNode => (
                            <Select.Option
                              key={govPermission.id}
                              value={govPermission.id}
                            >
                              <div id={'govP_' + govIndex.toString()}>
                                {govPermission.name}
                              </div>
                            </Select.Option>
                          ),
                        )}
                      </Select>
                    </Col>
                  </Row>
                }
              />
            </List.Item>
          );
        }}
      />
    );
  };

  /**
   * These following 2 functions change page and replaces current position in the pagination
   */
  public handlePaginationChangeNew = (current: number): void => {
    this.props.getAll({
      ...this.props.equipmentFilters,
      currentPage: current - 1,
    });
    //Set state to update what screen is showing
    this.setState({ currentPage: current });
  };

  public handlePaginationChange = (
    currentPage: number,
    pageSize?: number,
  ): void => {
    this.props.getAll({
      currentPage: currentPage - 1,
      pageSize: pageSize ? pageSize : 25,
    });
    //Set state to update what screen is showing
    this.setState({ currentPage, pageSize });
  };

  public render = (): React.ReactNode => {
    const {
      equipments,
      distributionCenters,
      governmentPermissions,
      isFetching,
    } = this.props;
    if (!localStorage.getItem('token')) return <Redirect to="/login" />;
    return (
      <div className={'content-background'}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
            {this.renderResponsiveList()}
          </Col>
          <Col
            xs={0}
            sm={0}
            md={0}
            lg={24}
            xl={24}
            xxl={24}
            style={{ backgroundColor: 'white', padding: '40px 20px' }}
          >
            <Table
              dataSource={equipments.content}
              pagination={false}
              loading={isFetching}
              rowClassName={(record): string =>
                record.governmentPermissionId === null ? 'red-row-table' : ''
              }
              rowKey={(record): string => record.id}
            >
              <Table.Column title="Tag" dataIndex="tag" />
              <Table.Column title="Número de equipo" dataIndex="number" />
              <Table.Column
                title="Centro de distribución"
                dataIndex="distributionCenterId"
                render={(text: string): JSX.Element => {
                  //Find the distributionCenter by id. If it is not found, set an initialized
                  const distributionC =
                    distributionCenters.find(
                      (d: DistributionCenter): boolean => d.id === text,
                    ) || initDistributionCenter;
                  return <div>{distributionC.name}</div>;
                }}
              />
              <Table.Column
                title="Permiso CRE"
                dataIndex="governmentPermissionId"
                render={(text: string, record: Equipment): JSX.Element => {
                  return (
                    <Select
                      value={text !== null ? text : ''}
                      size="large"
                      style={{ width: '100%' }}
                      onChange={(value: string): void => {
                        record.governmentPermissionId = value;
                        this.props.save(record);
                        //Set state to update what screen is showing in the list and table
                        this.setState({
                          equipments: equipments.content,
                        });
                      }}
                      disabled={isFetching}
                    >
                      <Select.Option key={record.id} value={''}>
                        {'No asignado'}
                      </Select.Option>
                      {governmentPermissions.map(
                        (item, index): React.ReactNode => (
                          <Select.Option key={item.id} value={item.id}>
                            <div id={'govP_' + index.toString()}>
                              {item.name}
                            </div>
                          </Select.Option>
                        ),
                      )}
                    </Select>
                  );
                }}
              />
              <Table.Column
                title="Empresa fiscal"
                dataIndex="fiscalCompanyId"
                render={(text: string, record: Equipment): JSX.Element => {
                  return (
                    <Select
                      value={text !== null ? text : ''}
                      size="large"
                      style={{ width: '100%' }}
                      onChange={(value: string): void => {
                        record.fiscalCompanyId = value;
                        this.props.save(record);
                        this.setState({
                          equipments: equipments.content,
                        });
                      }}
                      disabled={isFetching}
                    >
                      <Select.Option key={record.id} value={''}>
                        {'No asignado'}
                      </Select.Option>
                      {this.props.companyList.map(
                        (item: Company, index: number): React.ReactNode => (
                          <Select.Option key={item.id!= null? item.id:''} value={item.id!= null? item.id:''}>
                            <div id={'comp_' + index.toString()}>
                              {item.name}
                            </div>
                          </Select.Option>
                        ),
                      )}
                    </Select>
                  );
                }}
              />
            </Table>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <Pagination
              showSizeChanger
              pageSizeOptions={['25', '50', '75', '100']}
              defaultPageSize={25}
              disabled={this.props.isFetching}
              current={(this.props.equipments.number || 0) + 1}
              total={this.props.equipments.totalElements}
              onShowSizeChange={this.handlePaginationChange}
              onChange={this.handlePaginationChangeNew}
            />
          </Col>
        </Row>
      </div>
    );
  };
}
//redux reducers data recieved
const mapStateToProps = (states: RootState): StateProps => {
  return {
    equipments: states.equipmentState.equipments,
    distributionCenters: Object.values(
      states.equipmentState.distributionCenters,
    ),
    governmentPermissions: Object.values(
      states.equipmentState.governmentPermissions,
    ),
    equipmentFilters: states.equipmentState.equipmentFilters,
    isFetching: states.equipmentState.isFetching,
    companyList: Object.values(states.equipmentState.companyList,),
  };
};
//redux actions which are dispatch from here
const mapDispatchProps: DispatchProps = {
  getAll,
  getDistributionCenters,
  getGovernmentPermissions,
  getAllCompany,
  save,
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchProps,
)(Equipments);
