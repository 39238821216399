import moment from 'moment';
import {
  constructMoment,
  getEndTime,
  getInitialTime,
} from '../../commons/services';
import { Permissions as P } from 'commons/permissions';
import { Token, getIncognitMode } from 'security/index';
import JwtDecode from 'jwt-decode';

import {
  Contact,
  Customer,
  CustomerAddress,
  AddressContactModel,
  User,
  Permissions,
  Marketstall,
  SettlementsTotals,
  CustomerTotals,
  Sale,
  Equipment,
  Settlement,
  SaleFilters,
  SalesAuditArray,
  G4sState,
  G4sMunicipality,
  G4sPrice,
  G4sProduct,
  Statement,
  SettlementDepositModal,
  BankDeposit,
  SettlementFilters,
  PaymentFilters,
  CustomerFilters,
  FilterBankDeposit,
  FiscalInformation,
  Payment,
  Acount,
  GovernmentPermission,
  Price,
  EquipmentFilters,
  Company,
  TotalsSaleIssued,
  Container,
  ContainerFilters,
  Equivalence,
  SaleEquipment,
  LiterTotals,
  CustomerAndUDR,
  ContainersModel,
  ContainerPurchase,
  ZipCode,
  Clarification,
  InvoicingPortalFiscalInfo,
  ModelBankTotals,
  DataToCustomInvoice,
  CylinderSale,
  CylinderSettlement,
  CylinderEquipment,
  CylinderFilters,
  StatementFilters,
  Portfolio,
  PortfolioInvoice,
  PortfolioFilters,
  Routes,
  RoutesFilters,
  DebtCollector,
  PortfolioManager,
  InvoicePortfolioFilters,
  InvoiceForPortfolio,
  PortfolioDTO,
  CollectionRoute,
} from '../../types/type';
import { isAllowed, getToken } from 'security';

export const initcompany: Company = {
  id: '',
  name: '',
  rfc: '',
  street: '',
  externalNumber: '',
  internalNumber: '',
  fiscalRegime: '',
  zipCode: '',
  colony: '',
  city: '',
  municipality: '',
  state: '',
  logoUrl: '',
  comercialName: '',
  password: '',
  certificate: new File([''], ''),
  privateKey: new File([''], ''),
  stampCompany: false,
  ftpHost: '',
  ftpPassword: '',
  ftpUser: '',
  ftpPort: '',
  invoiceFolio: 1,
};
export const initTotalsTypes = {
  totalAmountCarburacionC: [6, 7, 8],
  totalAmountAutotanqueC: [11, 12, 13],
};

export const initDistributionCenter = {
  id: '',
  active: false,
  code: 0,
  name: '',
  centerKey: '',
  priceZoneRegion: 0,
  scheme: 0,
  linked: false,
  realSale: 0,
  totalSale: 0,
  totalSaleExpected: 0,
  saleCheck: 0,
  totalAlternativeSale: 0,
  totalAlternativeSaleExpected: 0,
  totalAlternativeSaleCheck: 0,
};

export const initSale: Sale = {
  id: '',
  createdAt: '',
  updatedAt: '',
  customerId: '',
  customerCode: '',
  idUserCreate: '',
  idUserUpdate: '',
  total: 0,
  totalVolume: 0,
  IUSCode: '',
  distCenterId: '',
  salesFolio: '',
  idG4s: '',
  customerRfc: '',
  paymentConditionType: '',
  customerType: '',
  customerName: '',
  disabled: false,
  invoice: '',
  objetiveSale: 0,
  endDateSave: '',
  paymentWay: '',
  invoiceRequired: false,
  equipmentId: '',
  bankDepositId: '',
  postTotal: 0,
  equipmentAppType: '',
  totalAmountC: 0,
  totalVolumeC: 0,
  forcedGeneralCustomer: false,
  unitPrice: 0,
  invoiceDownloaded: false,
  reseptionUnit: '',
  customerRegistered: false,
  statusCancelation: '',
};

export const initInvoice = {
  id: null,
  name: '',
  rfc: '',
  street: '',
  externalNumber: '',
  internalNumber: '',
  fiscalRegime: '',
  zipCode: '',
  colony: '',
  city: '',
  municipality: '',
  state: '',
  usoCfdi: '',
  formaPago: '',
  email: undefined,
  sales: [],
  idG4s: '',
};

export const initContact: Contact = {
  id: undefined,
  customerAddressId: undefined,
  name: '',
  email: '',
  houseNumber: undefined,
  oficeNumber: undefined,
  movilNumber: undefined,
  birthday: moment(),
  marketstall: '',
  resiveEmails: false,
};

export const initG4sState: G4sState = {
  id: 1,
  name: 'Aguascalientes',
};
export const initG4sMunicipality: G4sMunicipality = {
  id: 0,
  name: '',
};
export const initG4sPrice: G4sPrice = {
  id: '',
  priceValue: 0,
  reference: '',
  taxRate: 0,
  productId: 0,
  regionZone: 0,
};
export const initG4sProduct: G4sProduct = {
  id: 0,
  description: '',
  measureUnit: '',
  factor: 0,
};

export const initCustomerAddress: CustomerAddress = {
  id: undefined,
  customer: undefined,
  code: '',
  description: '',
  contacts: [initContact],
  capacity: 0,
  generalPurpose1: '',
  generalPurpose2: '',
  product: 1,
  commode: false,
  street: '',
  externalNumber: 0,
  internalNumber: 0,
  suburb: '',
  city: '',
  country: '',
  state: '',
  municipality: '',
  zipCode: '',
  copiesToPrint: 0,
  unitType: '',
  price: 62,
};

export const initFiscalInformation: FiscalInformation = {
  rfc: '',
  businessName: '',
  formaPago: '',
  usoCfdi: '',
  fiscalRegime: '',
  customerCodeComPro: '',
  street: '',
  externalNumber: '',
  colony: '',
  city: '',
  state: '',
  zipCode: '',
};

export const initCustomer: Customer = {
  id: '',
  createdAt: '',
  updatedAt: '',
  idUserCreate: '',
  idUserUpdate: '',
  businessName: '',
  rfc: '',
  street: '',
  address: '',
  externalNumber: '',
  internalNumber: '',
  zipCode: '',
  colony: '',
  city: '',
  municipality: '',
  state: '',
  usoCfdi: '',
  fiscalRegime: '',
  formaPago: '',
  email: '',
  code: 0,
  creditDays: 0,
  creditLimit: 0,
  debt: 0,
  balance: 0,
  automatic: false,
  groupSales: true,
  distributionCenter: '',
  password: '',
  loginDate: moment(),
  discount: '',
  birthday: moment(),
  comercialName: '',
  observations: '',
  cieKey: '',
  statusCustomer: '',
  statusCredit: '',
  credit: 'CREDIT',
  customerAddresses: [initCustomerAddress],
  phoneNumber: '',
  fiscalInformationList: [],
  saleSoonDueDate: moment(),
  assignedUserId: '',
  customerCodeComPro: '',
  aparceroType: 'NA',
  assignedDistributionCenters: [],
  accountingAccount: '',
  emailInvoice: '',
  isDisabled: false,
  latitude: 21.881478,
  longitude: -102.29456,
  lastPurchaseDate: moment(),
  location: '',
};

export const initCustomerAndUDR: CustomerAndUDR = {
  customer: initCustomer,
  udr: '',
};

export const initPayment: Payment = {
  id: '',
  createdAt: '',
  updatedAt: '',
  idUserCreate: '',
  idUserUpdate: '',
  amount: 0,
  balance: 0,
  paymentForm: '',
  bank: '',
  reference: '',
  voucher: new Blob(),
  paymentDate: moment(),
  customerCode: '',
  apply: 'PENDIENTE',
  paymentVoucherLink: '',
  statementId: '',
  cieKey: '',
  invoiceDownloaded: false,
  distributionCenter: '',
  customerFromPayment: initCustomer,
  taskitFolio: '',
  latitude: 21.881478,
  longitude: -102.29456,
};
export const initStatement: Statement = {
  id: '',
  createdAt: '',
  updatedAt: '',
  idUserCreate: '',
  idUserUpdate: '',
  statusStatment: '',
  sale: false,
  amount: 0,
  balance: 0,
  movementDate: moment(),
  reference: '',
  //movementId: '',
  customerCode: '',
  creditDays: moment(),
  statementRelation: [],
  acountNumber: '',
  invoiceDownloaded: false,
  paymentCondition: '',
  paymentWay: '',
  invoiceStatus: null,
  cancellationUser: '',
  cancellationDate: moment(),
  linkedInvoices: '',
};

export const initAcountStatus: Acount = {
  balance: 0,
  balanceLost: 0,
  creditAvailable: 0,
  creditLimit: 0,
  customerComercialName: '',
  customerBusinessName: '',
  fiscalInformationList: [],
  customerCieKey: '',
  customerAddresses: [initCustomerAddress],
};

export const intDashboardValue = {
  total: 0,
  distribtionCenterBalance: [{ name: '', amountBalance: 0, debt: 0 }],
};
export const intBankDepositValue: ModelBankTotals = {
  totalApply: 0,
  totalPending: 0,
  totalPendingDeposit: 0,
  totalIus: 0,
  totalIusPending: 0,
  totalIusPendingDeposit: 0,
  totalAmountDifferences: 0,
};
export const initCustomerModel = {
  customer: initCustomer,
  customerAddress: [initCustomerAddress],
  fiscalInformation: [],
};
export const valuecuts = (): string[] => {
  let seeSettlements: string[] = [];

  if (isAllowed([P.CAN_POS_SETTLE])) {
    seeSettlements.push('Liquidado?');
  }
  if (isAllowed([P.CAN_MANAGE_SETTLEMENTS])) {
    seeSettlements.push('Nuevo');
  }
  if (isAllowed([P.CAN_VIEW_SETTLEMENT_APPLY])) {
    seeSettlements.push('Liquidado');
  }
  return seeSettlements;
};
const token =
  getToken() !== null ? JwtDecode<Token>(getToken() as string) : null;

export const initSettlementFilters: SettlementFilters = {
  distributionCenter: isAllowed([P.CAN_VIEW_ALL_DISTRIBUTION_CENTERS])
    ? ''
    : token
    ? token.distributionCenterId
    : '',
  initDate: constructMoment(0, 0, 0, 1).toISOString(),
  endDate: constructMoment(23, 59, 59, 999).toISOString(),
  serviceType: '',
  equipment: '',
  pagePosition: 1,
  pageSize: 25,
  all: getIncognitMode() === '1',
  allCuts: valuecuts(),
};

export const initContainersModel: ContainersModel = {
  containers: [],
  containerCustomerCode: '',
  purchases: [],
  containerType: '',
};

export const initSettlement: Settlement = {
  idNoG4s: '',
  id: '',
  createdAt: '',
  updatedAt: '',
  idUserCreate: '',
  idUserUpdate: '',
  amountCredit: 0,
  amountCash: 0,
  postAmountCash: 0,
  total: 0,
  postTotal: 0,
  unit: '',
  serviceType: '',
  reference: '',
  folio: '',
  movementDate: moment(),
  distributionCenterId: '',
  status: '',
  operator: '',
  assistant: '',
  bankDepositId: '',
  finalvolumetotalizer: 0,
  objective: 0,
  settlementContainer: initContainersModel,
  childContainerValues: [],
  containerPurchases: [],
};
export const initSettlementDepositModal: SettlementDepositModal = {
  settlement: [],
  totalDebt: 0,
};
export const initAddressContactModel: AddressContactModel = {
  code: '',
  customerAddressId: '',
  contact: [initContact],
};
export const initPermission: Permissions = {
  name: '',
  description: '',
  id: '',
  permissionName: '',
  priority: 0,
  group: '',
};
export const initUser: User = {
  createdAt: '',
  email: '',
  id: '',
  idUserCreate: null,
  idUserUpdate: null,
  isActive: false,
  name: '',
  lastName1: '',
  lastName2: '',
  phone: '',
  marketstall: '',
  admissionDate: new Date(),
  birthday: new Date(),
  numberOpportunity: 0,
  token: '',
  updatedAt: '',
  distributionCenterId: '',
  userPermission: [],
  lastActiveDate: new Date(),
  assignedDistributionCenters: [],
  debtCollector: [],
  portfolioManager: [],
  taskitId: '',
};

export const initDebtCollector: DebtCollector = {
  id: '',
  name: '',
};
export const initPortfolioManager: PortfolioManager = {
  id: '',
  name: '',
};
export const initMarketstall: Marketstall = {
  id: '',
  name: '',
};
export const initSettlementList = {
  content: [initSettlement],
  number: 1,
  totalElements: 10,
};
export const initSettlmentsTotals: SettlementsTotals = {
  id: '',
  total: 0,
  amountCash: 0,
  amountCredit: 0,
  postAmountCash: 0,
  postTotal: 0,
};
export const initCustomerDebt: CustomerTotals = {
  totalDebt: 0,
  totalCreditLimit: 0,
};
export const initEquipment: Equipment = {
  id: '',
  idG4s: '',
  tag: '',
  number: 0,
  distributionCenterId: '',
  governmentPermissionId: '',
  fiscalCompanyId: '',
};
export const initBankDeposit: BankDeposit = {
  id: '',
  createdAt: '',
  updatedAt: '',
  idUserCreate: '',
  idUserUpdate: '',
  status: '',
  depositDate: moment(),
  distributionCenterId: '',
  amount: 0,
  amountsp: 0,
  bank: '',
  acountRecipt: '',
  folioDeposit: '',
  refuceDescription: '',
  urlTicket: '',
  voucher: new Blob(),
  depositType: '',
  debt: 0,
  balance: 0,
  balancesp: 0,
  discounted: false,
  comments: '',
  paymentMethod: '',
  fileExt: '',
  amountDifferences: 0,
};
export const initSaleFilters: SaleFilters = {
  pageNumber: 0,
  pageSize: 25,
  totalGreaterThan: undefined,
  totalLessThan: undefined,
  customerCode: undefined,
  invoiceStatus: '',
  dateGreaterThan: constructMoment(0, 0, 0, 1).toISOString(),
  dateLessThan: constructMoment(23, 59, 59, 999).toISOString(),
  distributionCenter: '',
  equipment: '',
  serviceType: '',
  paymentWay: '',
  paymentCondition: '',
  folio: undefined,
  disabled: [false, false],
  moreFilters: [],
  moreFiltersVisible: false,
  settlementFolio: '',
  invoiceSerie: '',
};

export const initSaleAudit: SalesAuditArray[] = [
  [
    {
      idG4s: '',
      idUserUpdate: '',
      customerCode: '',
      unitPrice: 0,
      paymentWay: '',
      paymentConditionType: '',
      name: '',
    },
    { revisionDate: moment() },
    'MOD',
  ],
];

export const initPaymentFilters: PaymentFilters = {
  pageNumber: 0,
  pageSize: 25,
  dateGreaterThan: moment(getInitialTime()).toISOString(),
  dateLessThan: moment(getEndTime()).toISOString(),
  customerCode: '',
  bank: '',
  StatusPayment: '',
  invoiceSerie: '',
  invoiceFolio: '',
  distributionCenter: '',
  paymentType: '',
  idUserCreate: '',
  companyId: '',
};

export const initStatementFilter: StatementFilters = {
  currentPage: 1,
  pageSize: 25,
  invoiceSerie: '',
  invoiceFolio: '',
  initDate: moment(getInitialTime()).toISOString(),
  endDate: moment(getEndTime()).toISOString(),
};

export const initCustomerFilters: CustomerFilters = {
  filter: '',
  statusCredit: '',
  statusCustomer: '',
  distributionCenter: '',
  withSoonDueDate: false,
  withoutAutoInvoicing: false,
  withOnlyAssignedCustomers: false,
  withInvoicePendingPay: false,
  page: 1,
  pageSize: 25,
  isDisabled: false,
  sorter: 'DESC',
  sortByColumn: 'debt',
};

export const initGovernmentPermission: GovernmentPermission = {
  id: '',
  name: '',
};

const statusBankDepopsitSalePerm = (): string[] => {
  let seeBankDeposit: string[] = [];

  if (
    isAllowed([P.CAN_VIEW_DEPOSIT_IUS]) ||
    isAllowed([P.CAN_APPLY_DEPOSIT_IUS])
  ) {
    seeBankDeposit.push('A validar');
  }
  if (isAllowed([P.CAN_VIEW_DEPOSIT_IUS])) {
    seeBankDeposit.push('Rechazado');
  }
  if (isAllowed([P.CAN_VIEW_APPLICATED_DEPOSIT_UIS])) {
    seeBankDeposit.push('Aplicado');
  }
  return seeBankDeposit;
};
const statusBankDepopsitSettlementPerm = (): string[] => {
  let seeBankDeposit: string[] = [];

  if (
    isAllowed([P.CAN_APPLY_BANK_DEPOSITS]) ||
    isAllowed([P.CAN_VIEW_BANK_DEPOSITS])
  ) {
    seeBankDeposit.push('A validar');
    seeBankDeposit.push('Aplicado');
  }
  if (isAllowed([P.CAN_VIEW_BANK_DEPOSITS])) {
    seeBankDeposit.push('Rechazado');
  }
  return seeBankDeposit;
};

export const initBankDepositFilter: FilterBankDeposit = {
  initDate: constructMoment(0, 0, 0, 1),
  endDate: constructMoment(23, 59, 59, 999),
  initAmount: 0,
  endAmount: 10000000,
  folioDeposit: '',
  acountRecipt: '',
  distributionCenterId: isAllowed([P.CAN_VIEW_ALL_DISTRIBUTION_CENTERS])
    ? ''
    : token
    ? token.distributionCenterId
    : '',
  pageSize: 25,
  pagePosition: 1,
  bank: '',
  statusSale: statusBankDepopsitSalePerm(),
  statusSettlement: statusBankDepopsitSettlementPerm(),
};

export const initEquipmentFilters: EquipmentFilters = {
  currentPage: 0,
  pageSize: 25,
};

export const initPrice: Price = {
  id: '',
  priceValue: 0,
  zone: 0,
  productType: 0,
  insertionDate: moment(),
};

export const initTotalsSaleIssued: TotalsSaleIssued = {
  totalInvoice: 0,
  totalWithoutInvoice: 0,
  finalTotal: 0,
};

export const initSaleEquipment: SaleEquipment = {
  id: '',
  tag: '',
};

export const initContainer: Container = {
  id: '',
  name: '',
  type: '',
  capacity: 0,
  tolerance: 0,
  customerCode: '',
  saleEquipments: [initSaleEquipment],
  parent: true,
  parentId: '',
  currentTheoreticalInventoryLevel: 0,
  currentTheoreticalVolume: 0,
  childContainers: [],
};

export const initContainerFilters: ContainerFilters = {
  code: '',
  containerType: '',
  currentPage: 0,
  pageSize: 25,
};

export const initEquivalence: Equivalence = {
  id: '',
  containerId: '',
  percentage: 0,
  volume: 0,
};

export const initLiterTotals: LiterTotals = {
  totalGlobal: 0,
  totalStore: 0,
  totalTankTruck: 0,
  totalCarburization: 0,
  totalCylinder: 0,
};

export const initContainerPurchase: ContainerPurchase = {
  volume: 0,
  date: moment(),
};
export const initZipcode: ZipCode = {
  codigo_postal: '',
  colonias: [],
  estado: '',
  municipio: '',
};

export const initclarification: Clarification = {
  id: '',
  imageUrl: '',
  comment: '',
  date: moment(),
  userEmail: '',
  bankDepositId: '',
  image: new Blob(),
  fileType: '',
  fileExt: '',
};

export const initInvoicingPortalFiscalInfo: InvoicingPortalFiscalInfo = {
  id: '',
  businessName: '',
  rfc: '',
  cfdiUse: '',
  fiscalRegime: '',
  paymentWay: '',
  street: '',
  externalNumber: '',
  internalNumber: '',
  zipCode: '',
  colony: '',
  city: '',
  municipality: '',
  state: '',
};

export const initDataToCustomInvoice: DataToCustomInvoice = {
  fiscalInformation: initFiscalInformation,
  observations: '',
  statementSales: [],
};

export const initCylinderSale: CylinderSale = {
  id: '',
  createdAt: moment(),
  movementDate: moment(),
  cylinderCapacity: 0,
  numberOfCylinders: 0,
  unitPrice: 0,
  total: 0,
  routeTag: '',
  routeEquipmentId: '',
  cylinderSettlementId: '',
  distributionCenterId: '',
  invoiceId: '',
  customerCode: '',
};

export const initCylinderSettlement: CylinderSettlement = {
  id: '',
  createdAt: moment(),
  movementDate: moment(),
  routeEquipmentId: '',
  routeTag: '',
  total: 0,
  totalVolume: 0,
  distributionCenterId: '',
  bankDepositId: '',
};

export const initCylinderEquipment: CylinderEquipment = {
  id: '',
  tag: '',
  distributionCenterId: '',
};

export const initCylinderFilters: CylinderFilters = {
  initDate: constructMoment(0, 0, 0, 1).toISOString(),
  endDate: constructMoment(23, 59, 59, 999).toISOString(),
  distributionCenterId: '',
  routeEquipmentId: '',
  currentPage: 1,
  pageSize: 25,
};

export const initPortfolio: Portfolio = {
  id: '',
  createdAt: moment(),
  updatedAt: moment(),
  idUserCreate: '',
  idUserUpdate: '',
  folio: 0,
  customersToVisit: 0,
  kilometers: 0,
  totalAmount: 0,
  status: '',
};

export const initPortfolioWithManagerempty: Portfolio = {
  id: '',
  createdAt: moment(),
  updatedAt: moment(),
  idUserCreate: '',
  idUserUpdate: '',
  folio: 0,
  customersToVisit: 0,
  kilometers: 0,
  totalAmount: 0,
  status: '',
  allporfolioManagers: [],
};

export const initPortfolioInvoice: PortfolioInvoice = {
  id: '',
  portfolioId: '',
  invoiceId: '',
  status: '',
  observations: '',
};

export const initPortfolioFilters: PortfolioFilters = {
  currentPage: 1,
  pageSize: 25,
  initDate: getInitialTime().toISOString(),
  endDate: getEndTime().toISOString(),
  folio: undefined,
  status: '',
};
export const initroutesFilters: RoutesFilters = {
  pageNumber: 1,
  pageSize: 25,
  initDate: getInitialTime().toISOString(),
  endDate: getEndTime().toISOString(),
  folio: undefined,
  cobrador: undefined,
  status: '',
};

export const initRoutes: Routes = {
  Folio: '',
  DateRoute: '',
  DebtCollector: '',
  Customers: 0,
  Kilometros: '',
  Total: 0,
  Status: '',
};

export const initRoutesFilters: RoutesFilters = {
  pageNumber: 1,
  pageSize: 25,
  initDate: getInitialTime().toISOString(),
  endDate: getEndTime().toISOString(),
  folio: undefined,
  cobrador: undefined,
  status: '',
};

export const initInvoicePortfolioFilters: InvoicePortfolioFilters = {
  currentPage: 1,
  pageSize: 25,
  initDate: getInitialTime().toISOString(),
  endDate: getEndTime().toISOString(),
  serie: '',
  folio: undefined,
  customerName: '',
};

export const initInvoiceForPortfolio: InvoiceForPortfolio = {
  invoiceId: '',
  invoiceDate: moment(),
  customerName: '',
  invoiceSerie: '',
  invoiceFolio: '',
  invoiceTotal: 0,
  amountToPay: 0,
  debt: 0,
  chargeId: '',
  withPendingPayment: false,
  observations: '',
  customerCode: '',
  taskitFolio: '',
  totalDebt: 0,
  debtCollectorName: '',
  collectorLastName1: '',
  collectorLastName2: '',
};

export const initPortfolioDTO: PortfolioDTO = {
  invoices: [],
  creationDate: moment(),
};

export const initCollectionRoute: CollectionRoute = {
  id: '',
  createdAt: moment(),
  updatedAt: moment(),
  idUserCreate: '',
  idUserUpdate: '',
  folio: { id: 0 },
  customersToVisit: 0,
  kilometers: 0,
  totalAmount: 0,
  status: '',
  percentage: 0,
  debCollectorName: '',
};
