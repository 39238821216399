import { AnyAction } from 'redux';
import CompanyService from '../../../services/company';
import { parseApiError } from '../../../commons/error';
import { notification } from 'antd';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Company, Files as filesType } from '../../../types/type';
import { initcompany } from '../../../commons/initTypes';
import {
  CompanyAction,
  BEGIN_FETCH,
  FETCH_COMPLETED,
  SET,
  SET_COMPANY,
  SET_DISTRIBUTION_CENTER,
} from './types';
import { getIsFetching } from './reducers';
import { RootState } from 'store/configureStore';
import { Popups } from 'commons/components/popups/popups';

const companyService = new CompanyService();

const startFetch = (): CompanyAction => {
  return { type: BEGIN_FETCH };
};
type ITA = ThunkAction<Promise<void>, RootState, {}, CompanyAction>;
type IDA = ThunkDispatch<{}, {}, CompanyAction>;
/**
 * This action creator is not public beca1use it should be used
 * automatically by other action creators that need fetch data from
 * backend.
 */
const endFetch = (): CompanyAction => {
  return { type: FETCH_COMPLETED };
};

export const page = (page: number): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    const responsepage = await companyService.companyList(page);
    dispatch({ type: SET_COMPANY, companyList: responsepage });
    const responseDistribution = await companyService.getDistrubutionCenter();
    dispatch({
      type: SET_DISTRIBUTION_CENTER,
      distributionCenter: responseDistribution,
    });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};

export const getCompany = (companyId: string): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    const response: Company = await companyService.getCompany(companyId);
    dispatch({ type: SET, company: response });
    const responseDistribution = await companyService.getDistrubutionCenter();
    dispatch({
      type: SET_DISTRIBUTION_CENTER,
      distributionCenter: responseDistribution,
    });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};
export const cleanCompany = (): ThunkAction<
  Promise<void>,
  {},
  {},
  AnyAction
> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch({ type: SET, company: initcompany });
    } catch (err) {}
  };
};

export const editCompany = (company: Company, files: filesType): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    const response: Company = await companyService.save(company, files);
    dispatch({ type: SET, company: response });
    const responsepage = await companyService.companyList(1);
    dispatch({ type: SET_COMPANY, companyList: responsepage });
    Popups.notifySuccess('guardado exitosamente');
    dispatch(endFetch());
  } catch (error) {
    dispatch(endFetch());
    throw error;
  }
};
export const getDistributionCenter = (): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    const responseDistribution = await companyService.getDistrubutionCenter();
    dispatch({
      type: SET_DISTRIBUTION_CENTER,
      distributionCenter: responseDistribution,
    });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};
