import React, { Component, FormEvent } from 'react';
import { RootState } from 'store/configureStore';
import Form, { FormComponentProps } from 'antd/lib/form';
import { connect } from 'react-redux';
import {
  Row,
  List,
  Table,
  DatePicker,
  Steps,
  Button,
  Card,
  Select,
  Col,
} from 'antd';
import { Invoice, Portfolio, CollectionRouteModel } from 'types/type';
import moment from 'moment';
import {
  getPortfoliosByDate,
  saveCollectionRoute,
} from '../../portfolios/redux/actions';
import { creditNoteInvoiceColumns } from 'routes/portfolios/components/rowExpandedTable';
import { getToken, Token } from 'security';
import JwtDecode from 'jwt-decode';
import { toNumber } from 'commons/numbersFormat';
import { initPortfolioWithManagerempty, initUser } from 'commons/initTypes';
const id: string =
  getToken() !== null ? JwtDecode<Token>(getToken() as string).id : '';

interface DispatchProps {
  getPortfoliosByDate: (filterDate: moment.Moment, user: string) => void;
  saveCollectionRoute: (porfolios: CollectionRouteModel) => void;
}
interface StateProps {
  portfoliosList: Portfolio[];
  isFetching: boolean;
}
interface State {
  selectedDate: moment.Moment;
}

const { Step } = Steps;
type Props = State & StateProps & DispatchProps & FormComponentProps;
class RoutesComponent extends Component<Props> {
  public state: State = {
    selectedDate: moment(),
  };

  public range(start: number, end: number): number[] {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  public componentDidMount(): void {
    this.props.getPortfoliosByDate(moment(), id);
  }

  public disabledDate(current: moment.Moment | undefined): boolean {
    return (current && current < moment().startOf('day')) || false;
  }

  public disabledDateTime(): {
    disabledHours: () => number[];
    disabledMinutes: () => number[];
    disabledSeconds: () => number[];
  } {
    return {
      disabledHours: (): number[] => [0, 24],
      disabledMinutes: (): number[] => [30, 60],
      disabledSeconds: (): number[] => [55, 56],
    };
  }
  public selectDateEvent = (selectedTime: moment.Moment | null): void => {
    this.setState({ selectedDate: selectedTime });
    this.props.getPortfoliosByDate(selectedTime || moment(), id);
  };

  private handleOnSubmit = (e: FormEvent): void => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll(
      async (err, values: CollectionRouteModel): Promise<void> => {
        values.porfolios = this.props.portfoliosList;
        values.portfolioManager = id;
        if (!err && values.porfolios[0] !== undefined) {
          console.log(values);
          const asyncAction = async (): Promise<void> => {
            try {
              await this.props.saveCollectionRoute(values);
              await this.props.getPortfoliosByDate(
                this.state.selectedDate || moment(),
                id,
              );
            } catch {}
          };
          asyncAction();
        }
      },
    );
  };

  public render = (): React.ReactNode => {
    const {
      form: { getFieldDecorator },
      isFetching,
      portfoliosList,
    } = this.props;
    return (
      <Row>
        <Form
          layout="vertical"
          className="user-form"
          onSubmit={this.handleOnSubmit}
        >
          <Steps
            direction="vertical"
            current={portfoliosList[0] === undefined ? 0 : 2}
          >
            <Step
              title="Seleccione la fecha"
              description={
                <Row className="filterDate">
                  <Form.Item hasFeedback label={'Fecha:'}>
                    {getFieldDecorator('date', {
                      initialValue: moment(),
                      rules: [
                        {
                          required: true,
                          message: 'Selecciona La fecha',
                        },
                      ],
                    })(
                      <DatePicker
                        onChange={(
                          selectedTime: moment.Moment | null,
                        ): void => {
                          this.selectDateEvent(selectedTime);
                        }}
                        disabledDate={this.disabledDate}
                        disabledTime={this.disabledDateTime}
                      />,
                    )}
                  </Form.Item>
                </Row>
              }
            />
            <Step
              title="Verifique los datos"
              description={
                <Row>
                  <Row>
                    <List
                      size={'large'}
                      bordered
                      dataSource={portfoliosList}
                      renderItem={(item: Portfolio): React.ReactNode => (
                        <Row>
                          <List.Item title={item.portfolioManager}>
                            <Card
                              title={
                                'Encargado de cartera: ' + item.portfolioManager
                              }
                              style={{ width: '100%' }}
                            >
                              <Table
                                rowKey={(record): string => record.id || ''}
                                defaultExpandedRowKeys={
                                  item.invoices
                                    ? item.invoices.map(
                                        (item: Invoice): string =>
                                          item.id || '',
                                      )
                                    : []
                                }
                                pagination={false}
                                dataSource={item.invoices}
                                expandedRowRender={(
                                  record: Invoice,
                                ): JSX.Element => (
                                  <Table
                                    className={'custom-table'}
                                    loading={isFetching}
                                    dataSource={record.creNoteInvoiceList}
                                    pagination={false}
                                    columns={creditNoteInvoiceColumns()}
                                  />
                                )}
                              >
                                <Table.Column
                                  title={'Fecha factura'}
                                  dataIndex={'invoiceDate'}
                                />
                                <Table.Column
                                  title={'Cliente'}
                                  dataIndex={'customerName'}
                                />
                                <Table.Column
                                  title={'Serie/folio'}
                                  dataIndex={'invoiceFolio'}
                                />
                                <Table.Column
                                  title={'Importe total factura'}
                                  dataIndex={'invoiceTotal'}
                                  render={(total: number): React.ReactNode => {
                                    return '$ ' + toNumber(total);
                                  }}
                                />
                                <Table.Column
                                  title={'Monto total a pagar'}
                                  dataIndex={'amountToPay'}
                                  render={(total: number): React.ReactNode => {
                                    return '$ ' + toNumber(total);
                                  }}
                                />
                                <Table.Column
                                  title={'saldo'}
                                  dataIndex={'debt'}
                                  render={(total: number): React.ReactNode => {
                                    return '$ ' + toNumber(total);
                                  }}
                                />
                                <Table.Column
                                  title={'observaciones'}
                                  dataIndex={'observations'}
                                />
                              </Table>
                            </Card>
                          </List.Item>
                        </Row>
                      )}
                    />
                  </Row>
                </Row>
              }
            />
            <Step
              title="Genere la ruta"
              description={
                <Col>
                  {portfoliosList[0] !== undefined && (
                    <Form.Item label={'Cobradores'}>
                      {getFieldDecorator('allporfolioManagers', {
                        initialValue: portfoliosList[0]
                          ? (portfoliosList[0].allporfolioManagers || []).map(
                              (item, index): React.ReactNode => item.id,
                            ) || []
                          : [],
                        rules: [
                          {
                            required: true,
                            message: 'Selecciona almenos un cobrador',
                          },
                        ],
                      })(
                        <Select
                          size="large"
                          placeholder="Seleccionar uno o más"
                          mode="multiple"
                          allowClear
                          onChange={(value: string[]): void => {
                            let debtCollectorIds: string[] = [];
                            value.forEach((id: string): void => {
                              debtCollectorIds.push(id);
                            });
                          }}
                        >
                          {(
                            (
                              portfoliosList[0] || [
                                initPortfolioWithManagerempty,
                              ]
                            ).allporfolioManagers || [initUser]
                          ).map(
                            (item, index): React.ReactNode => (
                              <Select.Option key={item.id} value={item.id}>
                                <div id={index.toString()}> {item.name}</div>
                              </Select.Option>
                            ),
                          )}
                        </Select>,
                      )}
                    </Form.Item>
                  )}
                  <Button
                    type="primary"
                    className="button-size"
                    size="large"
                    id="Generar_rutas"
                    htmlType="submit"
                    disabled={portfoliosList[0] === undefined}
                  >
                    Generar rutas
                  </Button>
                </Col>
              }
            />
          </Steps>
        </Form>
      </Row>
    );
  };
}

const mapStateToProps = (states: RootState): StateProps => {
  return {
    portfoliosList: states.portfoliosState.portfoliosList,
    isFetching: states.portfoliosState.isFetching,
  };
};

const mapDispatchToProps: DispatchProps = {
  getPortfoliosByDate,
  saveCollectionRoute,
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create()(RoutesComponent));
