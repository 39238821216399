/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by David Canul <david@ssf.com.mx>, Jul 2019
 *
 *
 */
import {
  Settlement,
  DistributionCenter,
  SettlementsTotals,
  Equipment,
  SalesAuditArray,
  SettlementFilters,
  CustomerAndUDR,
} from 'types/type';

/*
 * Action to add a Settlement to list component
 */
export const SET_SETTLEMENT = 'SET_SETTLEMENT';
export const SET_SALE_AUDIT = 'SET_SALE_AUDIT';
export const SET_SETTLEMENT_TOTALS = 'SET_SETTLEMENT_TOTALS';
export const SET_SETTLEMENT_LISTS = 'SET_SETTLEMENT_LISTS';
export const SET_CUSTOMER_LIST = 'SET_CUSTOMER_LIST';
export const SETTLEMENTS_SET_DISTRIBUTION_CENTER =
  'SETTLEMENTS_SET_DISTRIBUTION_CENTER';
export const SET_USERS_NAME = 'SET_USERS_NAME';
export const SET_SETTLEMENT_EQUIPMENT_LIST = 'SET_SETTLEMENT_EQUIPMENT_LIST';
export const RESET_SETTLEMENT_EQUIPMENT_LIST =
  'RESET_SETTLEMENT_EQUIPMENT_LIST';
export const RESET_SALE_AUDIT = 'RESET_SALE_AUDIT';
export const SET_SETTLEMENT_FILTERS = 'SET_SETTLEMENT_FILTERS';
export const SET_PRICES = 'SET_PRICES';

/*
 * Begin a fetch operation action
 */
export const BEGIN_FETCH = 'BEGIN_FETCH';

/*
 * A fetch operation action have been completed
 */
export const FETCH_COMPLETED = 'FETCH_COMPLETED';

interface BeginFetchAction {
  type: typeof BEGIN_FETCH;
}
interface SetAction {
  type: typeof SET_SETTLEMENT;
  settlement: Settlement;
}
interface SetActionFilters {
  type: typeof SET_SETTLEMENT_FILTERS;
  settlementFilters: SettlementFilters;
}
interface SetSaleAuditAction {
  type: typeof SET_SALE_AUDIT;
  salesAuditArray: SalesAuditArray[];
}
interface SetSettlementsTotalsAction {
  type: typeof SET_SETTLEMENT_TOTALS;
  settlementTotals: SettlementsTotals;
}
interface SetSettlementList {
  type: typeof SET_SETTLEMENT_LISTS;
  settlementLists: {};
}

interface SetDistributionCenterAction {
  type: typeof SETTLEMENTS_SET_DISTRIBUTION_CENTER;
  distributionCenter: DistributionCenter[];
}
interface SetSettlementEquipmentList {
  type: typeof SET_SETTLEMENT_EQUIPMENT_LIST;
  equipmentList: Equipment[];
}

interface SetUsersName {
  type: typeof SET_USERS_NAME;
  namesUsers: string[];
}
interface EndFetchAction {
  type: typeof FETCH_COMPLETED;
}
interface CustomerListAction {
  type: typeof SET_CUSTOMER_LIST;
  customersList: CustomerAndUDR[];
}
interface ResetSettlementEquipmentList {
  type: typeof RESET_SETTLEMENT_EQUIPMENT_LIST;
}
interface ResetSalesAudit {
  type: typeof RESET_SALE_AUDIT;
}

interface SetPricesAction {
  type: typeof SET_PRICES;
  prices: {};
}

export type SettlementAction =
  | SetActionFilters
  | SetSettlementsTotalsAction
  | ResetSalesAudit
  | SetSaleAuditAction
  | SetAction
  | SetDistributionCenterAction
  | SetUsersName
  | SetSettlementEquipmentList
  | SetSettlementList
  | BeginFetchAction
  | CustomerListAction
  | EndFetchAction
  | ResetSettlementEquipmentList
  | SetPricesAction;
