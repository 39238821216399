/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by David Canul <david@ssf.com.mx>, Jun 2019
 *
 *
 */
import BankDepositService from '../../../services/bankDeposit';
import { parseApiError, parseStringError } from '../../../commons/error';
import { notification } from 'antd';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {
  BankDepositAction,
  BEGIN_FETCH,
  FETCH_COMPLETED,
  SET_TOTAL_BANK_DEPOSIT,
  SET_APPLY_BANK_DEPOSIT,
  SET_DISTRIBUTION_CENTER,
  SET_SETTLEMENT_LIST,
  SET_SAVED_BANK_DEPOSIT,
  RESET_SETTLEMENT_LIST,
  SET_BANK_DEPOSIT_LIST,
  SET_SALES_LIST,
  RESET_SALES_LIST,
  SET_SETTLEMENT_MODAL_LIST,
  RESET_SETTLEMENT__MODAL_LIST,
  SET_BANK_DPOSIT_FILTERS,
  SET_CLARIFICATIONS_HISTORY,
} from './types';
import { getIsFetching } from './reducers';
import { RootState } from 'store/configureStore';
import { FilterBankDeposit } from 'types/type';
import { Popups } from 'commons/components/popups/popups';
import { ApiVarStorage } from 'commons/services/api';

const bankDepositService = new BankDepositService();

const startFetch = (): BankDepositAction => {
  return { type: BEGIN_FETCH };
};
type ITA = ThunkAction<Promise<void>, RootState, {}, BankDepositAction>;
type IDA = ThunkDispatch<{}, {}, BankDepositAction>;
/**
 * This action creator is not public beca1use it should be used
 * automatically by other action creators that need fetch data from
 * backend.
 */
const endFetch = (): BankDepositAction => {
  return { type: FETCH_COMPLETED };
};

const editFilters = (filters: FilterBankDeposit): BankDepositAction => {
  return {
    type: SET_BANK_DPOSIT_FILTERS,
    bankDepositFilters: filters,
  };
};

/**
 *This action give sales from db by filters(initDate,endDte,Sale status and Page)
 */

export const downloadFile = (
  id: string,
  name: string,
  saleFolio: string,
): ITA => async (): Promise<void> => {
  bankDepositService.downloadFile(id, name, saleFolio);
};

export const totalCalculator = (
  filterBankDeposit: FilterBankDeposit,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    const response = await bankDepositService.totalsbankdeposit(
      filterBankDeposit,
    );
    dispatch({ type: SET_TOTAL_BANK_DEPOSIT, depositValues: response });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};

export const page = (filterBankDeposit: FilterBankDeposit): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    let responsepage;
    await dispatch(editFilters(filterBankDeposit));
    responsepage = await bankDepositService.pagebyfilters(filterBankDeposit);
    dispatch({ type: SET_BANK_DEPOSIT_LIST, bankDeposit: responsepage });
    dispatch(endFetch());
    let responseDistribution = await bankDepositService.getDistrubutionCenter();
    await dispatch({
      type: SET_DISTRIBUTION_CENTER,
      distributionCenter: responseDistribution,
    });
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};
export const apply = (
  id: string,
  status: string,
  description: string,
  amountDifferences: number,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    let responsepage;
    responsepage = await bankDepositService.applyBankDeposit(
      id,
      status,
      description,
      amountDifferences,
    );
    dispatch({ type: SET_APPLY_BANK_DEPOSIT, bankDepositStatus: responsepage });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};
export const resetModal = (): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  dispatch(startFetch());
  try {
    dispatch({ type: RESET_SETTLEMENT__MODAL_LIST });
  } catch (err) {
    notification.error(parseApiError(err));
  }
  dispatch(endFetch());
};
export const getSettlementList = (distId: string): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    let responsepage;
    dispatch({ type: RESET_SETTLEMENT__MODAL_LIST });
    responsepage = await bankDepositService.setSettlementList(distId);
    dispatch({
      type: SET_SETTLEMENT_MODAL_LIST,
      settlementModalList: responsepage,
    });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};

export const getSettlementListByBankDeposit = (
  id: string,
  depositType: string,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    dispatch({ type: RESET_SETTLEMENT_LIST });
    dispatch({ type: RESET_SALES_LIST });
    if (depositType === 'Corte') {
      let responsepage;
      responsepage = await bankDepositService.getSettlementListByBankDeposit(
        id,
      );
      dispatch({ type: SET_SETTLEMENT_LIST, settlementList: responsepage });
    } else {
      let responsepage;
      responsepage = await bankDepositService.getSalelistbybankdeposit(id);
      dispatch({ type: SET_SALES_LIST, salesList: responsepage });
    }
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};
export const downloadTicket = (
  id: string,
  name: string,
  saleFolio: string,
): ITA => async (dispatch: IDA): Promise<void> => {
  dispatch(startFetch());
  try {
    await bankDepositService.downloadFile(id, name, saleFolio);
  } catch (error) {
    if (error.responce !== undefined) {
      const decoder: TextDecoder = new TextDecoder('utf-8');
      const decodedString: string = decoder.decode(
        new Uint8Array(error.response.data),
      );
      notification.error(parseStringError(decodedString));
    } else {
      notification.error(parseApiError(error));
    }
  }
  dispatch(endFetch());
};
export const save = (payment: FormData): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    let responseStatement;

    responseStatement = await bankDepositService.saveBankDeposit(payment);
    ApiVarStorage.set('bankDepositId', responseStatement.id);
    console.log('Response: ', responseStatement);
    
    dispatch({ type: SET_SAVED_BANK_DEPOSIT, bankDeposit: responseStatement });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};

export const downloadDeposit = (id: string, folio: string): ITA => async (
  dispatch: IDA,
): Promise<void> => {
  dispatch(startFetch());
  try {
    await bankDepositService.getBankDepositPDF(id, folio);
  } catch (error) {
    if (error.response !== undefined) {
      const decoder: TextDecoder = new TextDecoder('utf-8');
      const decodedString: string = decoder.decode(
        new Uint8Array(error.response.data),
      );
      notification.error(parseStringError(decodedString));
    } else {
      notification.error(parseApiError(error));
    }
  }
  dispatch(endFetch());
};

export const getClarificationsHistory = (depositId: string): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    const response = await bankDepositService.getClarificationsHistory(
      depositId,
    );
    dispatch({
      type: SET_CLARIFICATIONS_HISTORY,
      clarifications: response,
    });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};

export const saveClarification = (clarification: FormData): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    await bankDepositService.saveClarification(clarification);
    Popups.notifySuccess('Aclaración guardada');
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};
