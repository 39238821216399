/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Ricardo Sansores <ricardo@ssf.mx>, May 2019
 *
 */

import JwtDecode from 'jwt-decode';
import axios, { AxiosRequestConfig } from 'axios';
import { TokenResponse } from '../types/type';
import { createCoreServiceUrl } from '../commons/services';

export const TOKEN_KEY = 'token';
export const INCOGNIT_MODE = 'incognitMode';
export const REFRESH_TOKEN_KEY = 'refresh-token';

export interface Token {
  id: string;
  name: string;
  email: string;
  customerCode: string;
  distributionCenterId: string;
  rfc: string;
  assingedDistrCenters: string[];
  permissions: string[];
  iat: string;
}

export const getToken = (): string | null | undefined =>
  localStorage.getItem(TOKEN_KEY);
export const getIncognitMode = (): string | null | undefined =>
  localStorage.getItem(INCOGNIT_MODE);

/**
 * This function only evaluates if the user is authenticated
 * using the token in the local storage.
 * If the token have expired then this method should invalidate
 * the stored token and return false.
 */
export const isAuthenticated = (): boolean => {
  try {
    if (getToken() != null) {
      try {
      } catch (e) {
        return false;
      }
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

/**
 * This function takes a list of permissions that are required by any
 * given component or screen in the system and verify that the user have
 * all those rights otherwise the function return false.
 */
export const isAllowed = (rights: string[] | undefined): boolean => {
  if (rights === undefined) return true;
  if (!getToken()) return false;
  try {
    const token = JwtDecode<Token>(getToken() as string);
    return rights.every((right): boolean => token.permissions.includes(right));
  } catch (e) {
    return false;
  }
};

export const getCustomerCode = (): string | undefined =>
  JwtDecode<Token>(getToken() as string).customerCode;


export const getRefreshToken = (): string | null =>
  localStorage.getItem(REFRESH_TOKEN_KEY);

export const saveToken = (token: string | undefined): void => {
  localStorage.setItem(TOKEN_KEY, token || '');
};

export const saveTokenResponse = (response: TokenResponse): void => {
  saveToken(response.accessToken);
  localStorage.setItem(REFRESH_TOKEN_KEY, response.refreshToken);
};

export const removeToken = (): void => {
  localStorage.removeItem(TOKEN_KEY);
};
export const addIncognitMode = (): void => {
  localStorage.setItem(INCOGNIT_MODE, '1');
};
export const removeIncognitMode = (): void => {
  localStorage.removeItem(INCOGNIT_MODE);
};

axios.interceptors.request.use(
  (conf): AxiosRequestConfig | Promise<AxiosRequestConfig> => {
    const configuration = conf;
    const token = getToken();
    if (token !== null && token !== undefined && token !== '') {
      configuration.headers.Authorization = `Bearer ${token}`;
    }
    return configuration;
  },
  (error): Promise<never> => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  response => response,
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  error => {
    if (error && error.response && error.response.status === 401) {
      console.log('Requesting new access token');
      return (
        axios
          .post(createCoreServiceUrl('users/token'), {
            refreshToken: getRefreshToken(),
          })
          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
          .then(tokenResp => {
            saveTokenResponse(tokenResp.data);
            axios.defaults.headers.common.Authorization = `Bearer ${tokenResp.data.accessToken}`;
            return axios(error.config);
          })
          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
          .catch((): void => {
            console.log('There was an error renewing the token');
            localStorage.removeItem(TOKEN_KEY);
            localStorage.removeItem(REFRESH_TOKEN_KEY);
            window.location.reload();
          })
      );
    }
    return Promise.reject(error);
  },
);
