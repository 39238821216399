import axios, { AxiosResponse } from 'axios';
import Service from '../Service';
import { createCoreServiceUrl } from '../../commons/services';
import {
  Pageable,
  User,
  Credentials,
  NewPassword,
  TokenResponse,
  PortfolioManager,
  DebtCollector,
} from '../../types/type';

class UserService extends Service {
  public getAll = (): Promise<User> =>
    axios
      .get(createCoreServiceUrl('users'))
      .then((response: AxiosResponse<User>): User => response.data);

  public authenticateUser = (
    credentials: Credentials,
  ): Promise<TokenResponse> =>
    axios
      .post(createCoreServiceUrl('users/token'), credentials)
      .then(
        (response: AxiosResponse<TokenResponse>): TokenResponse =>
          response.data,
      );

  public authenticateCustomer = (credentials: Credentials): Promise<string> =>
    axios
      .post(createCoreServiceUrl('customer/token'), credentials)
      .then((response: AxiosResponse<string>): string => response.data);

  public usersList = (
    filter: string,
    userMarketstall: string,
    distributionCenter: string,
    currentPage: number,
    pageSize: number,
  ): Promise<Pageable> =>
    axios
      .post(createCoreServiceUrl(`users/paginatedUsersByFilter`), {
        filter,
        userMarketstall,
        distributionCenter,
        currentPage,
        pageSize,
      })
      .then((response: AxiosResponse<Pageable>): Pageable => response.data);

  public save = (user: User): Promise<User> =>
    axios
      .post(createCoreServiceUrl('users'), { user: { ...user } })
      .then((response: AxiosResponse<User>): User => response.data);

  public updatePassword = (user: NewPassword): Promise<string> =>
    axios
      .post(createCoreServiceUrl('users/passwordupdate'), user)
      .then((response: AxiosResponse<string>): string => response.data);

  public activateUserById = (userId: string): Promise<Pageable> =>
    axios
      .post(createCoreServiceUrl(`users/${userId}/activate`))
      .then((response: AxiosResponse<Pageable>): Pageable => response.data);

  public deactivateUserById = (userId: string): Promise<User> =>
    axios
      .post(createCoreServiceUrl(`users/${userId}/deactivate`))
      .then((response: AxiosResponse<User>): User => response.data);

  public trysendpassword = (userEmail: string): Promise<User> =>
    axios
      .post(createCoreServiceUrl(`users/trysendpassword/${userEmail}`))
      .then((response: AxiosResponse<User>): User => response.data);

  public getNamesFromUSer = (): Promise<string[]> =>
    axios
      .get(createCoreServiceUrl('user/allNames'))
      .then((response: AxiosResponse<string[]>): string[] => response.data);

  public getUser = (userId: string): Promise<User> =>
    axios
      .get(createCoreServiceUrl(`users/getUser/${userId}`))
      .then((response: AxiosResponse<User>): User => response.data);

  public getAllUsersCollectors = (): Promise<DebtCollector[]> =>
    axios
      .get(createCoreServiceUrl('user/getUser/debtCollectors'))
      .then(
        (response: AxiosResponse<DebtCollector[]>): DebtCollector[] =>
          response.data,
      );

  public getAllUsersPortfolioManager = (): Promise<PortfolioManager[]> =>
    axios
      .get(createCoreServiceUrl('user/getUser/portfolioManagers'))
      .then(
        (response: AxiosResponse<PortfolioManager[]>): PortfolioManager[] =>
          response.data,
      );
}

export default UserService;
