import { Col, Modal, Row } from 'antd';
import GoogleMapReact from 'google-map-react';
import React, { Component } from 'react';
import Marker from 'routes/sales/components/Marker';
import { Customer, Payment } from 'types/type';

interface Props {
  isVisible: boolean;
  customer: Customer;
  payment: Payment;
  onCancel: Function;
}

class LocationModal extends Component<Props> {
  public render = (): JSX.Element => {
    const { isVisible, customer, onCancel, payment } = this.props;
    const { latitude, longitude, businessName } = customer;
    return (
      <Modal
        width={450}
        visible={isVisible}
        title={'Ubicación del cobro'}
        okText={'Cerrar'}
        cancelText=""
        onCancel={onCancel()}
        cancelButtonProps={{ disabled: true, hidden: true }}
        onOk={onCancel()}
      >
        <Row>
          <Col span={24}>
            <div style={{ height: '27rem', width: '100%' }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: 'AIzaSyCsBQRj84EsjEJDwCWiIf0ZoRQCdqFqwh8',
                }}
                defaultCenter={{
                  lat: latitude !== null ? latitude : 21.881478,
                  lng: longitude !== null ? longitude : -102.29456,
                }}
                defaultZoom={15}
              >
                <Marker
                  lat={latitude ? latitude : 21.881478}
                  lng={longitude ? longitude : -102.29456}
                  name={'Ubicación de cliente ' + businessName}
                  color="#113c70"
                />
                <Marker
                  lat={payment.latitude ? payment.latitude : 21.881478}
                  lng={payment.longitude ? payment.longitude : -102.29456}
                  name="Ubicación de cobranza"
                  color="#C52121"
                />
              </GoogleMapReact>
            </div>
          </Col>
        </Row>
      </Modal>
    );
  };
}

export default LocationModal;
