import React, { Component, FormEvent } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Card, Form, Input, Button, Icon, Row, Col, notification } from 'antd';
import { enterpriceLogo as logo } from 'boot/config';
import { emailPattern } from './../../commons/regularExpressions';
import { FormComponentProps } from 'antd/lib/form/Form';
import './style.css';
import { login } from './redux/actions';
import { AccessToken } from './redux/reducers';
import { RootState } from '../../store/configureStore';
import Text from 'antd/lib/typography/Text';
import { parseApiError } from 'commons/error';

interface DispatchProps {
  login: (username: string, password: string) => void;
}
interface StateProps {
  accessToken: AccessToken;
}
type Props = FormComponentProps & StateProps & DispatchProps;

class Login extends Component<Props> {
  private handleOnSubmit = (e: FormEvent): void => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(
      async (err, values): Promise<void> => {
        if (!err) {
          try {
            await this.props.login(values.username, values.password);
            window.location.reload();
          } catch (err) {
            notification.error(parseApiError(err));
          }
        }
      },
    );
  };
  public render = (): JSX.Element => {
    const { getFieldDecorator } = this.props.form;
    if (localStorage.getItem('token')) return <Redirect to="/" />;
    return (
      <body>
        <Form onSubmit={this.handleOnSubmit} className="login-form">
          <Card
            className="login-card"
            cover={
              <Row>
                <Row
                  style={{ backgroundColor: '#113c71', textAlign: 'center' }}
                >
                  <h1 style={{ color: '#fa9869', fontStyle: 'italic' }}>
                    BIENVENIDO
                  </h1>
                  <p />
                </Row>
                <Row style={{ backgroundColor: '#f1f7ff' }}>
                  <p />
                  <img className="header-logo" src={logo} alt="company_logo" />

                  <p />
                </Row>
                <Row style={{ textAlign: 'center' }}>
                  <p />
                  <h2>Iniciar Sesión</h2>

                  <p />
                </Row>
                <Row>
                  <Col span={2}>
                    <div>&nbsp;</div>
                  </Col>
                  <Col span={20}>
                    <Form.Item hasFeedback>
                      {getFieldDecorator('username', {
                        rules: [
                          { required: true, message: 'Ingresa un email' },
                          {
                            pattern: emailPattern,
                            message: 'Ingresa un email valido',
                          },
                        ],
                      })(
                        <Input
                          prefix={<Icon type="mail" />}
                          placeholder="Email"
                        />,
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <div>&nbsp;</div>
                  </Col>
                </Row>
                <Row>
                  <Col span={2}>
                    <div>&nbsp;</div>
                  </Col>
                  <Col span={20}>
                    <Form.Item hasFeedback>
                      {getFieldDecorator('password', {
                        rules: [
                          { required: true, message: 'Ingresa una contraseña' },
                        ],
                      })(
                        <Input
                          prefix={<Icon type="lock" />}
                          type="password"
                          placeholder="Contraseña"
                        />,
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <div>&nbsp;</div>
                  </Col>
                </Row>
                <Row>
                  <Col span={2}>
                    <div>&nbsp;</div>
                  </Col>
                  <Col span={20} />
                  <a
                    className="forgot-password-link float-right"
                    href="/forgotPassword"
                  >
                    <Text>{'¿Olvidaste tu contraseña?'}</Text>
                  </a>
                  <Col span={20} />
                  <Col span={2}>
                    <div>&nbsp;</div>
                  </Col>
                </Row>
                <Form.Item />
              </Row>
            }
          >
            <Button
              block
              className="button-invoice-serch-style"
              type="primary"
              htmlType="submit"
              id="login_button"
            >
              Iniciar sesión
            </Button>
          </Card>
        </Form>
      </body>
    );
  };
}

const mapStateToProps = (states: RootState): StateProps => {
  return {
    accessToken: states.loginState.accessToken,
  };
};

const mapDispatchToProps = {
  login,
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create()(Login));
