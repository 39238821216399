import axios, { AxiosResponse } from 'axios';
import Service from '../Service';
import { createCoreServiceUrl } from '../../commons/services';
import { Invoice, InvoicingPortalFiscalInfo } from '../../types/type';

class InvoiceService extends Service {
  public saveInvoice = (
    invoice: Invoice,
    fiscalInformation: InvoicingPortalFiscalInfo,
  ): Promise<Invoice> =>
    axios
      .post(createCoreServiceUrl('invoice'), {
        invoice: { ...invoice },
        sales: invoice.sales,
        fiscalInformation,
      })
      .then((response: AxiosResponse<Invoice>): Invoice => response.data);

  public getFiscalInformationByRfc = (
    rfc: string,
  ): Promise<InvoicingPortalFiscalInfo> =>
    axios
      .get(createCoreServiceUrl(`invoice/${rfc}/fiscalInformation`))
      .then(
        (
          response: AxiosResponse<InvoicingPortalFiscalInfo>,
        ): InvoicingPortalFiscalInfo => response.data,
      );
}
export default InvoiceService;
