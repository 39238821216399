// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasValue = (v: any): boolean => {
  if (v) return true;
  return false;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isEmpty = (v: any): boolean => {
  if (v) return false;
  return true;
};
