import axios, { AxiosResponse } from 'axios';
import Service from '../Service';
import {
  createCoreServiceUrl,
  createParamsService,
} from '../../commons/services';
import { Pageable } from '../../types/type';
import moment from 'moment';

class PaymentService extends Service {
  /**
   * Service to get the payments by filters
   */
  public paymentListGrouped = async (
    params: Record<string, any>,// eslint-disable-line
  ): Promise<Pageable> => {
    const response: AxiosResponse<Pageable> = await axios.get(
      createCoreServiceUrl('payments') + createParamsService(params),
    );
    return response.data;
  };

  public changeBank = async (
    params: Record<string, any>,// eslint-disable-line
  ): Promise<Pageable> => {
    const response: AxiosResponse<Pageable> = await axios.post(
      createCoreServiceUrl('payments/changebank') + createParamsService(params),
    );
    return response.data;
  };

  public applyPayment = (
    paymentId: string,
    idUserApply: string,
  ): Promise<void> =>
    axios
      .post(createCoreServiceUrl(`payment/apply/${paymentId}/${idUserApply}`))
      .then((response: AxiosResponse<void>): void => response.data);

  public refusePayment = (paymentId: string): Promise<void> =>
    axios
      .post(createCoreServiceUrl(`payment/refuse/${paymentId}`))
      .then((response: AxiosResponse<void>): void => response.data);

  public getPaymentsReport = async (
    params: Record<string, any>,
  ): Promise<void> => {
    const response = await axios({
      url:
        createCoreServiceUrl('payments/excelReport') +
        createParamsService(params),
      method: 'GET',
      responseType: 'arraybuffer',
    });

    //Get the current values of date to set them in the file name
    const year = moment().year();
    const month = moment().month() + 1;
    const day = moment().date();

    const ticketUrl = window.URL.createObjectURL(new Blob([response.data]));
    const ticketLink = document.createElement('a');
    ticketLink.href = ticketUrl;
    ticketLink.setAttribute(
      'download',
      `Reporte_pagos_${year}_${month}_${day}.xlsx`,
    );
    document.body.appendChild(ticketLink);
    ticketLink.click();
  };

  public getDetailedPaymentsReport = async (
    params: Record<string, any>,// eslint-disable-line
  ): Promise<void> => {
    const response = await axios({
      url:
        createCoreServiceUrl('payments/detailedReportInExcel') +
        createParamsService(params),
      method: 'GET',
      responseType: 'arraybuffer',
    });

    //Get the current values of date to set them in the file name
    const year = moment().year();
    const month = moment().month() + 1;
    const day = moment().date();

    const ticketUrl = window.URL.createObjectURL(new Blob([response.data]));
    const ticketLink = document.createElement('a');
    ticketLink.href = ticketUrl;
    ticketLink.setAttribute(
      'download',
      `Reporte_pagos_detallados_${year}_${month}_${day}.xlsx`,
    );
    document.body.appendChild(ticketLink);
    ticketLink.click();
  };

  public getZipFile = async (filters: Record<string, any>// eslint-disable-line
  ): Promise<void> => {
    const response = await axios({
      method: 'get',
      url: createCoreServiceUrl(`payments/zip`) + createParamsService(filters),
      responseType: 'arraybuffer',
    });

    const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
    const fileLink = document.createElement('a');
    fileLink.href = fileUrl;
    fileLink.setAttribute('download', `Archivos_pagos.zip`);
    document.body.appendChild(fileLink);
    fileLink.click();
  };
}

export default PaymentService;
