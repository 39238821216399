/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Hiram Padilla <hiram@ssf.com.mx>, July 2021
 *
 */

import { notification } from 'antd';
import { parseApiError } from 'commons/error';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import PortfoliosService from 'services/portfolios';
import StatementService from 'services/statement';
import { RootState } from 'store/configureStore';
import moment from 'moment';
import {
  Pageable,
  Portfolio,
  PortfolioFilters,
  InvoicePortfolioFilters,
  CreNoteInvoice,
  PortfoliosFormPage,
  PortfolioDTO,
  InvoiceForPortfolio,
  CollectionRouteModel,
} from 'types/type';
import { isPortfoliosFetching } from './reducers';
import {
  BEGIN_FETCH,
  FETCH_COMPLETED,
  PortfolioAction,
  RESET_CRE_NOTES_LIST,
  RESET_INVOICES_TO_EDIT,
  RESET_OPENED_PORTFOLIO,
  RESET_PORTFOLIO_DETAILS,
  SET_CRE_NOTES_LIST,
  SET_INVOICES_PAGE,
  SET_INVOICES_TO_EDIT,
  SET_OPENED_PORTFOLIO,
  SET_PORTFOLIOS_LIST,
  SET_PORTFOLIOS_PAGE,
  SET_PORTFOLIO_DETAILS,
  SET_SELECTED_INVOICES,
} from './types';
import { Popups } from 'commons/components/popups/popups';

const portfolioService = new PortfoliosService();
const statementService = new StatementService();

type ITA = ThunkAction<Promise<void>, RootState, {}, PortfolioAction>;
type IDA = ThunkDispatch<{}, {}, PortfolioAction>;

export const getPage = (filters: PortfolioFilters): ITA => async (
  dispatch: IDA,
  getState: Function,
): Promise<void> => {
  if (isPortfoliosFetching(getState())) return Promise.resolve();

  dispatch({ type: BEGIN_FETCH });
  try {
    const page: Pageable = await portfolioService.getPageByFilters(filters);
    dispatch({ type: SET_PORTFOLIOS_PAGE, portfoliosPage: page });
  } catch (error) {
    notification.error(parseApiError(error));
  }
  dispatch({ type: FETCH_COMPLETED });
};

export const save = (portfolio: PortfolioDTO): ITA => async (
  dispatch: IDA,
  getState: Function,
): Promise<void> => {
  if (isPortfoliosFetching(getState())) return Promise.resolve();

  dispatch({ type: BEGIN_FETCH });
  try {
    await portfolioService.save(portfolio);
    Popups.notifySuccess('Cartera guardada');
  } catch (error) {
    notification.error(parseApiError(error));
  }
  dispatch({ type: FETCH_COMPLETED });
};

export const update = (id: string, portfolio: PortfolioDTO): ITA => async (
  dispatch: IDA,
  getState: Function,
): Promise<void> => {
  if (isPortfoliosFetching(getState())) return Promise.resolve();

  dispatch({ type: BEGIN_FETCH });
  try {
    await portfolioService.update(id, portfolio);
    Popups.notifySuccess('Cartera actualizada');
  } catch (error) {
    notification.error(parseApiError(error));
  }
  dispatch({ type: FETCH_COMPLETED });
};

export const getInvoicesPage = (
  filters: InvoicePortfolioFilters,
): ITA => async (dispatch: IDA, getState: Function): Promise<void> => {
  if (isPortfoliosFetching(getState())) return Promise.resolve();

  dispatch({ type: BEGIN_FETCH });
  try {
    const page: Pageable = await portfolioService.getPortfolioInvoicesPage(
      filters,
    );
    dispatch({ type: SET_INVOICES_PAGE, invoicesPage: page });
  } catch (error) {
    notification.error(parseApiError(error));
  }
  dispatch({ type: FETCH_COMPLETED });
};

export const getStatementCreNotes = (id: string): ITA => async (
  dispatch: IDA,
  getState: Function,
): Promise<void> => {
  if (isPortfoliosFetching(getState())) return Promise.resolve();

  dispatch({ type: BEGIN_FETCH });
  try {
    dispatch({ type: RESET_CRE_NOTES_LIST });
    const creNotes: CreNoteInvoice[] = await statementService.getCreditNotesOfStatement(
      id,
    );
    dispatch({ type: SET_CRE_NOTES_LIST, creNotes });
  } catch (error) {
    notification.error(parseApiError(error));
  }
  dispatch({ type: FETCH_COMPLETED });
};

export const setOrderedInvoicesPage = (
  newPage: PortfoliosFormPage,
): ITA => async (dispatch: IDA, getState: Function): Promise<void> => {
  if (isPortfoliosFetching(getState())) return Promise.resolve();

  dispatch({ type: BEGIN_FETCH });
  try {
    dispatch({ type: SET_INVOICES_PAGE, invoicesPage: newPage });
  } catch (error) {
    notification.error(parseApiError(error));
  }
  dispatch({ type: FETCH_COMPLETED });
};

export const setSelectedInvoicesFirst = (
  selectedRows: InvoiceForPortfolio[],
): ITA => async (dispatch: IDA, getState: Function): Promise<void> => {
  if (isPortfoliosFetching(getState())) return Promise.resolve();

  dispatch({ type: BEGIN_FETCH });
  try {
    dispatch({ type: SET_SELECTED_INVOICES, selectedInvoices: selectedRows });
  } catch (error) {
    notification.error(parseApiError(error));
  }
  dispatch({ type: FETCH_COMPLETED });
};

export const getPortfoliosByDate = (
  date: moment.Moment,
  user: string,
): ITA => async (dispatch: IDA, getState: Function): Promise<void> => {
  if (isPortfoliosFetching(getState())) return Promise.resolve();

  dispatch({ type: BEGIN_FETCH });
  try {
    const list: Portfolio[] = await portfolioService.getPortfolioList(
      date,
      user,
    );
    dispatch({ type: SET_PORTFOLIOS_LIST, portfolioList: list });
  } catch (error) {
    notification.error(parseApiError(error));
  }
  dispatch({ type: FETCH_COMPLETED });
};

export const getByPortfolioId = (id: string): ITA => async (
  dispatch: IDA,
  getState: Function,
): Promise<void> => {
  if (isPortfoliosFetching(getState())) return Promise.resolve();

  dispatch({ type: BEGIN_FETCH });
  try {
    dispatch({ type: RESET_INVOICES_TO_EDIT });
    const invoices: InvoiceForPortfolio[] = await portfolioService.getInvoicesByPortfolioId(
      id,
    );

    dispatch({ type: SET_INVOICES_TO_EDIT, invoicesToEdit: invoices });
  } catch (error) {
    notification.error(parseApiError(error));
  }
  dispatch({ type: FETCH_COMPLETED });
};

export const searchOpenedPortfolio = (endDate: string): ITA => async (
  dispatch: IDA,
  getState: Function,
): Promise<void> => {
  if (isPortfoliosFetching(getState())) return Promise.resolve();

  dispatch({ type: BEGIN_FETCH });
  try {
    dispatch({ type: RESET_OPENED_PORTFOLIO });
    const openedPortfolio: Portfolio = await portfolioService.getOneOpenPortfolio(
      endDate,
    );
    dispatch({ type: SET_OPENED_PORTFOLIO, openedPortfolio });
  } catch (error) {
    notification.error(parseApiError(error));
  }
  dispatch({ type: FETCH_COMPLETED });
};
export const saveCollectionRoute = (route: CollectionRouteModel): ITA => async (
  dispatch: IDA,
  getState: Function,
): Promise<void> => {
  if (isPortfoliosFetching(getState())) return Promise.resolve();

  dispatch({ type: BEGIN_FETCH });
  try {
    await portfolioService.saveCollectionRoute(route);
    Popups.notifySuccess('Ruta guardada');
  } catch (error) {
    notification.error(parseApiError(error));
  }
  dispatch({ type: FETCH_COMPLETED });
};

export const closePortfolio = (porfolioId: string): ITA => async (
  dispatch: IDA,
  getState: Function,
): Promise<void> => {
  if (isPortfoliosFetching(getState())) return Promise.resolve();

  dispatch({ type: BEGIN_FETCH });
  try {
    await portfolioService.closePortfolio(porfolioId);
    Popups.notifySuccess('Ruta Cerrada');
  } catch (error) {
    notification.error(parseApiError(error));
  }
  dispatch({ type: FETCH_COMPLETED });
};

export const loadPortfolioDetails = (portfolioId: string): ITA => async (
  dispatch: IDA,
  getState: Function,
): Promise<void> => {
  if (isPortfoliosFetching(getState())) return Promise.resolve();

  dispatch({ type: BEGIN_FETCH });
  dispatch({ type: RESET_PORTFOLIO_DETAILS });
  try {
    const response = await portfolioService.getInvoicesByPortfolioId(
      portfolioId,
    );
    dispatch({ type: SET_PORTFOLIO_DETAILS, invoicesInfo: response });
  } catch (error) {
    notification.error(parseApiError(error));
  }
  dispatch({ type: FETCH_COMPLETED });
};
