import axios, { AxiosResponse } from 'axios';
import Service from '../Service';
import { createCoreServiceUrl } from '../../commons/services';
import {
  Customer,
  Pageable,
  CustomerModel,
  AddressContactModel,
  Contact,
  CustomerTotals,
  CustomerAddress,
  CustomerFilters,
  CustomerAndUDR,
} from '../../types/type';

class CustomerService extends Service {
  public customerList = (customerFilters: CustomerFilters): Promise<Pageable> =>
    axios
      .post(
        createCoreServiceUrl(`customer/paginatedCustomersByFilter`),
        customerFilters,
      )
      .then((response: AxiosResponse<Pageable>): Pageable => response.data);
  public customerdebts = (
    customerFilters: CustomerFilters,
  ): Promise<CustomerTotals> =>
    axios
      .post(createCoreServiceUrl(`customer/balances`), customerFilters)
      .then(
        (response: AxiosResponse<CustomerTotals>): CustomerTotals =>
          response.data,
      );

  public getCustomer = (customerId: string): Promise<CustomerModel> => {
    return axios
      .get(createCoreServiceUrl(`customer/getCustomer/${customerId}`))
      .then(
        (response: AxiosResponse<CustomerModel>): CustomerModel =>
          response.data,
      );
  };

  public getCustomerG4SByCode = (
    customerCode: string,
  ): Promise<CustomerModel> => {
    return axios
      .get(createCoreServiceUrl(`customer/getCustomer/code/${customerCode}`))
      .then(
        (response: AxiosResponse<CustomerModel>): CustomerModel =>
          response.data,
      );
  };

  public save = (customer: CustomerModel): Promise<CustomerModel> => {
    console.log(customer);
    /* if(1){
      console.log(JSON.stringify(customer, null, '  '));
      alert('interupted');
      return new Promise(res => res(customer));
    } */
    return axios
      .post(createCoreServiceUrl('customer/save'), {
        ...customer,
      })
      .then(
        (response: AxiosResponse<CustomerModel>): CustomerModel =>
          response.data,
      );
  };

  public getCustomerAddress = (id: string): Promise<AddressContactModel> =>
    axios
      .get(createCoreServiceUrl(`customer/getCustomerAddress/${id}`))
      .then(
        (response: AxiosResponse<AddressContactModel>): AddressContactModel =>
          response.data,
      );

  public editCustomerAddress = (
    id: string,
    contact: Contact[],
  ): Promise<AddressContactModel> =>
    axios
      .post(createCoreServiceUrl(`customer/editCustomerAddress`), {
        id,
        contact,
      })
      .then(
        (response: AxiosResponse<AddressContactModel>): AddressContactModel =>
          response.data,
      );

  public saveCustomerBalance = (
    customerCode: string,
    amount: number,
    userId: string,
    distId: string,
  ): Promise<AddressContactModel> =>
    axios
      .get(
        createCoreServiceUrl(
          `statement/cleanStatements/${customerCode}/${amount}/${userId}/${distId}`,
        ),
      )
      .then(
        (response: AxiosResponse<AddressContactModel>): AddressContactModel =>
          response.data,
      );
  public listCustomerByFilter = (filter: string): Promise<CustomerAndUDR[]> => {
    return axios
      .get(createCoreServiceUrl(`customer/filter/${filter}`))
      .then(
        (response: AxiosResponse<CustomerAndUDR[]>): CustomerAndUDR[] =>
          response.data,
      );
  };

  public getCustomerAddressOfG4s = async (
    code: string,
  ): Promise<AxiosResponse<CustomerAddress>> =>
    await axios.get(createCoreServiceUrl('customer/udr/' + code));

  public sendEmailDataAccess = (id: string): Promise<Customer> =>
    axios
      .post(createCoreServiceUrl(`customer/${id}/sendDataAccess`))
      .then((response: AxiosResponse<Customer>): Customer => response.data);

  public deleteCustomerById = (customerId: string): Promise<Customer> =>
    axios
      .delete(createCoreServiceUrl(`customer/${customerId}`))
      .then((response: AxiosResponse<Customer>): Customer => response.data);

  public deleteCustomerBranches = (
    customerId: string,
    udrid: string,
  ): Promise<Customer> =>
    axios
      .delete(createCoreServiceUrl(`customer/${customerId}/branches/${udrid}`))
      .then((response: AxiosResponse<Customer>): Customer => response.data);

  public saveUdr = (udr: CustomerAddress): Promise<CustomerAddress> => {
    return axios
      .put(createCoreServiceUrl(`customer/saveUdr`), udr)
      .then(
        (response: AxiosResponse<CustomerAddress>): CustomerAddress =>
          response.data,
      );
  };
  public generateCustomersReport = async (
    filters: Record<string, any>, // eslint-disable-line
  ): Promise<void> => {
    const response = await axios({
      method: 'post',
      url: createCoreServiceUrl(`customers/customersReport`),
      data: filters,
      responseType: 'arraybuffer',
    });

    const reportUrl = window.URL.createObjectURL(new Blob([response.data]));
    const reportLink = document.createElement('a');
    reportLink.href = reportUrl;
    reportLink.setAttribute('download', `clientes.xlsx`);
    document.body.appendChild(reportLink);
    reportLink.click();
  };
}

export default CustomerService;
