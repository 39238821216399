import React from 'react';
import { Row, Col, Card } from 'antd';
import Text from 'antd/lib/typography/Text';
import { LiterTotals } from 'types/type';
import { toNumber } from 'commons/numbersFormat';

const getIndicators = (totals: LiterTotals): JSX.Element => {
  const titles = [
    'Total global',
    'Autotanque',
    'Carburación',
    'Almacén',
    'Cilindro',
  ];
  let values: number[] = [
    totals.totalGlobal,
    totals.totalTankTruck,
    totals.totalCarburization,
    totals.totalStore,
    totals.totalCylinder,
  ];

  return (
    <Row className="content-backgroud">
      {//Show a card for each title item with title and the total respectively
      titles.map(
        (title, index: number): JSX.Element => {
          return (
            <Col
              xs={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              md={{ span: 6, offset: 0 }}
              lg={{ span: 6, offset: 0 }}
              xl={{ span: 6, offset: 0 }}
              xxl={{ span: 6, offset: 0 }}
              key={index}
              style={{ marginTop: '20px' }}
            >
              <Card className="card-statement">
                <Text>{title}</Text>
                <Text>
                  <h3>
                    {values[index] === 0 ? '-.--' : toNumber(values[index])}
                    {' Lts'}
                  </h3>
                </Text>
              </Card>
            </Col>
          );
        },
      )}
    </Row>
  );
};

export default getIndicators;
