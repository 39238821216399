/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Hiram Padilla <hiram@ssf.com.mx>, July 2021
 *
 * This component shows a table with the information of cylinder settlements & sales
 */

import {
  Button,
  Col,
  DatePicker,
  Icon,
  Input,
  InputNumber,
  Pagination,
  Row,
  Select,
  Table,
  Tooltip,
} from 'antd';
import {
  initInvoiceForPortfolio,
  initInvoicePortfolioFilters,
  initPortfolioDTO,
} from 'commons/initTypes';
import JwtDecode from 'jwt-decode';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { getToken, isAllowed, Token } from 'security';
import {
  InvoicePortfolioFilters,
  CreNoteInvoice,
  InvoiceForPortfolio,
  PortfoliosFormPage,
  PortfolioDTO,
  Portfolio,
} from 'types/type';
import {
  getInvoicesPage,
  save,
  update,
  getStatementCreNotes,
  setOrderedInvoicesPage,
  getByPortfolioId,
  setSelectedInvoicesFirst,
  searchOpenedPortfolio,
} from '../redux/actions';
import Text from 'antd/lib/typography/Text';
import { isDateWrong } from 'routes/functions';
import moment from 'moment';
import { DATE_FORMAT } from 'commons/services';
import { toNumber } from 'commons/numbersFormat';
import { RootState } from 'store/configureStore';
import { connect } from 'react-redux';
import { creditNoteInvoiceColumns } from './rowExpandedTable';
import { Permissions as P } from 'commons/permissions';
import ObservationsModal from './observationsModal';
import Form, { FormComponentProps } from 'antd/lib/form';
import { Link } from 'react-router-dom';

const userName: string =
  getToken() !== null ? JwtDecode<Token>(getToken() as string).name : '';

interface RouterProps {
  id: string;
}

interface StateProps {
  invoicesPage: PortfoliosFormPage;
  creNotes: CreNoteInvoice[];
  invoicesToEdit: InvoiceForPortfolio[];
  openedPortfolio: Portfolio;
  isFetching: boolean;
}

interface DispatchProps {
  getInvoicesPage: (filters: InvoicePortfolioFilters) => void;
  save: (portfolio: PortfolioDTO) => void;
  update: (id: string, portfolio: PortfolioDTO) => void;
  getStatementCreNotes: (id: string) => void;
  setOrderedInvoicesPage: (newPage: PortfoliosFormPage) => void;
  getByPortfolioId: (id: string) => void;
  setSelectedInvoicesFirst: (selectedRows: InvoiceForPortfolio[]) => void;
  searchOpenedPortfolio: (endDate: string) => void;
}

/* Varaibles used in this component life cycle */
let filters: InvoicePortfolioFilters = initInvoicePortfolioFilters;
let selectedRows: InvoiceForPortfolio[] = [];
let invoiceToUpdate: InvoiceForPortfolio = initInvoiceForPortfolio;
let portfolioDto: PortfolioDTO = initPortfolioDTO;

type Props = FormComponentProps &
  StateProps &
  DispatchProps &
  RouteComponentProps<RouterProps>;

class PortfolioFormComponent extends Component<Props> {
  public state = {
    expandedRowKeys: [''],
    selectedRowKeys: [''],
    isObservationsVisible: false,
  };

  private loadInfo = async (
    filters: InvoicePortfolioFilters,
  ): Promise<void> => {
    await this.props.getInvoicesPage(filters);
    await this.props.searchOpenedPortfolio(
      this.props.form.getFieldValue('creationDate'),
    );
    const { id } = this.props.match.params;
    //If is a portfolio edition, get its information & added to current page
    if (id !== '0') {
      await this.props.getByPortfolioId(id);
      selectedRows = this.props.invoicesToEdit;
      await this.props.setSelectedInvoicesFirst(selectedRows);
      this.setState({
        selectedRowKeys: selectedRows.map((inv): string => inv.invoiceId),
      });
    }
  };

  private fetchInvoiceInfo = async (
    filtersApplied: InvoicePortfolioFilters,
  ): Promise<void> => {
    await this.props.getInvoicesPage(filtersApplied);
    //Get non selected rows from current page content
    const rowKeysSelected = selectedRows.map((inv): string => inv.invoiceId);
    const originalPage = this.props.invoicesPage;
    let nonSelected = this.getElementsDifferentFromList(
      originalPage.content || [],
      rowKeysSelected,
    );
    //Order rows by invoice date descending
    nonSelected = this.orderInvoiceListDescending(nonSelected);
    selectedRows = this.orderInvoiceListDescending(selectedRows);
    //New page initialized
    let newPage: PortfoliosFormPage = {
      content: [],
      number: originalPage.number,
      totalElements: originalPage.totalElements,
    };
    //New page content updated, with selected rows first
    newPage.content = selectedRows.concat(nonSelected);
    await this.props.setOrderedInvoicesPage(newPage);
  };

  private fetchCreNotesInfo = async (id: string): Promise<void> =>
    await this.props.getStatementCreNotes(id);

  private updatePage = async (newPage: PortfoliosFormPage): Promise<void> =>
    await this.props.setOrderedInvoicesPage(newPage);

  private savePortfolio = async (portfolioDto: PortfolioDTO): Promise<void> =>
    await this.props.save(portfolioDto);

  private updatePortfolio = async (
    id: string,
    portfolioDto: PortfolioDTO,
  ): Promise<void> => await this.props.update(id, portfolioDto);

  /* ------------- Component mount methods ------------- */

  public componentDidMount(): void {
    selectedRows = [];
    invoiceToUpdate = initInvoiceForPortfolio;
    portfolioDto = initPortfolioDTO;

    this.loadInfo(filters);
  }

  /* ------------- Event handlers methods, starting with 'handle' ------------- */

  private handleOnExpand = (
    isExpanded: boolean,
    record: InvoiceForPortfolio,
  ): void => {
    let { expandedRowKeys } = this.state;
    //if a row is expanded, update expanded rows list & update the state
    if (isExpanded) {
      expandedRowKeys = [record.invoiceId];
      this.setState({ expandedRowKeys });
    } //Else, set expanded rows list as empty
    else {
      this.setState({ expandedRowKeys: [''] });
    }
  };

  private isRowDisabled = (record: InvoiceForPortfolio): boolean => {
    //Disable when invoice has pending payments
    if (record.withPendingPayment) return true;
    return false;
  };

  /** Sort a list of InvoiceForPortfolio by invoice date descending */
  private orderInvoiceListDescending = (
    list: InvoiceForPortfolio[],
  ): InvoiceForPortfolio[] =>
    list.sort((a: InvoiceForPortfolio, b: InvoiceForPortfolio): number => {
      if (a.invoiceDate > b.invoiceDate) return -1;
      if (a.invoiceDate < b.invoiceDate) return 1;
      return 0;
    });

  private getElementsDifferentFromList = (
    invoices: InvoiceForPortfolio[],
    list: string[],
  ): InvoiceForPortfolio[] =>
    invoices.filter((inv): boolean => !list.includes(inv.invoiceId));

  private handleSavePortfolio = (): void => {
    const { id } = this.props.match.params;
    this.props.form.validateFields(
      async (err, values): Promise<void> => {
        if (!err) {
          portfolioDto.creationDate = values.creationDate;

          if (id === '0') this.savePortfolio(portfolioDto);
          else this.updatePortfolio(id, portfolioDto);

          selectedRows = [];
          invoiceToUpdate = initInvoiceForPortfolio;
          portfolioDto = initPortfolioDTO;
          this.props.history.push('/portfolios');
        }
      },
    );
  };

  /* -------------Event handlers methods, starting with 'on' -------------*/

  private onSelectRow = (
    record: InvoiceForPortfolio,
    selected: boolean,
  ): void => {
    let { selectedRowKeys } = this.state;
    let originalPage = this.props.invoicesPage;
    // ~~~ If a row is selected, added to list
    if (selected) {
      selectedRowKeys.push(record.invoiceId);
      selectedRows.push(record);
    } // ~~~ If is diselected, remove it from list
    else {
      selectedRowKeys = selectedRowKeys.filter(
        (id: string): boolean => id !== record.invoiceId,
      );
      selectedRows = selectedRows.filter(
        (inv): boolean => inv.invoiceId !== record.invoiceId,
      );
      //If row is diselected & current page content is not undefined, remove diselected from page
      if (originalPage.content)
        originalPage.content = originalPage.content.filter(
          (inv): boolean => inv.invoiceId !== record.invoiceId,
        );
    }
    //Get non selected rows from current page content
    let nonSelected = this.getElementsDifferentFromList(
      originalPage.content || [],
      selectedRowKeys,
    );
    //Order rows by invoice date descending
    nonSelected = this.orderInvoiceListDescending(nonSelected);
    selectedRows = this.orderInvoiceListDescending(selectedRows);
    //New page initialized
    let newPage: PortfoliosFormPage = {
      content: [],
      number: originalPage.number,
      totalElements: originalPage.totalElements,
    };
    //New page content updated, with selected rows first
    newPage.content = selectedRows.concat(nonSelected);
    this.updatePage(newPage);
    this.setState({ selectedRowKeys });
  };

  /** This function change page and replaces current position in the pagination */
  private onChangeCurrentPage = async (currentPage: number): Promise<void> => {
    filters.currentPage = currentPage;
    await this.props.getInvoicesPage(filters);
    //Get non selected rows from current page content
    const rowKeysSelected = selectedRows.map((inv): string => inv.invoiceId);
    const originalPage = this.props.invoicesPage;
    let nonSelected = this.getElementsDifferentFromList(
      originalPage.content || [],
      rowKeysSelected,
    );
    //Order rows by invoice date descending
    nonSelected = this.orderInvoiceListDescending(nonSelected);
    selectedRows = this.orderInvoiceListDescending(selectedRows);
    //New page initialized
    let newPage: PortfoliosFormPage = {
      content: [],
      number: originalPage.number,
      totalElements: originalPage.totalElements,
    };
    //New page content updated, with selected rows first
    newPage.content = selectedRows.concat(nonSelected);
    await this.props.setOrderedInvoicesPage(newPage);
  };

  /** This function change page and replaces current position in the pagination */
  private onChangePageSize = async (
    currentPage: number,
    pageSize: number,
  ): Promise<void> => {
    filters.currentPage = currentPage;
    filters.pageSize = pageSize || 25;
  };

  private onCancelObservations = (): void => {
    this.setState({ isObservationsVisible: false });
  };

  private onSaveObservations = (invoice: InvoiceForPortfolio): void => {
    selectedRows = selectedRows.map(
      (inv): InvoiceForPortfolio => {
        if (inv.invoiceId === invoice.invoiceId)
          inv.observations = invoice.observations;
        return inv;
      },
    );
    this.setState({ isObservationsVisible: false });
  };

  /* ------------- Render methods for render content ------------- */

  /* ------------- Main Render method ------------- */
  public render = (): React.ReactNode => {
    const { isFetching, invoicesPage, creNotes, form } = this.props;
    const { selectedRowKeys, expandedRowKeys } = this.state;
    const { isObservationsVisible } = this.state;

    //When user is not portfolio manager, show nothing, else show entire section
    return !isAllowed([P.IS_PORTFOLIO_MANAGER]) ? (
      <div />
    ) : (
      <Row>
        {/** ------------- Header section (includes the manager name & the filters) ------------- */}
        <Row className="content-backgroud">
          <Col xs={24} sm={12} md={8} lg={8} xl={4} xxl={4}>
            <Text>Encargado de cartera</Text>
            <br />
            <h2>{userName}</h2>
            <br />
            <Link to={{ pathname: '/portfolios' }}>
              <Text strong>
                <Icon type="arrow-left" /> Regresar
              </Text>
            </Link>
          </Col>
          <Col xs={24} sm={12} md={16} lg={16} xl={20} xxl={20}>
            <Col xs={23} sm={23} md={11} lg={15} xl={10} xxl={8} offset={1}>
              <div>
                <Text className="font-color-Labeln">Filtrar por fecha</Text>
              </div>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <DatePicker
                  showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                  disabled={isFetching}
                  disabledDate={(date: moment.Moment | undefined): boolean =>
                    isDateWrong(date, moment(filters.endDate))
                  }
                  format={DATE_FORMAT}
                  placeholder="Desde"
                  onOk={(selectedTime: moment.Moment): void => {
                    filters.initDate = selectedTime.toISOString();
                  }}
                  defaultValue={moment(filters.initDate)}
                  style={{ width: '100%', marginBottom: '10px' }}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <DatePicker
                  showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                  disabled={isFetching}
                  disabledDate={(date: moment.Moment | undefined): boolean =>
                    isDateWrong(moment(filters.initDate), date)
                  }
                  format={DATE_FORMAT}
                  placeholder="Hasta"
                  onOk={(selectedTime: moment.Moment): void => {
                    filters.endDate = selectedTime.toISOString();
                  }}
                  defaultValue={moment(filters.endDate)}
                  style={{ width: '100%', marginBottom: '10px' }}
                />
              </Col>
            </Col>
            <Col xs={23} sm={11} md={7} lg={5} xl={4} xxl={3} offset={1}>
              <Text className="font-color-Labeln">Serie</Text>
              <Select
                style={{ width: '100%', paddingBottom: '10px' }}
                defaultValue={filters.serie}
                onChange={(value: string): void => {
                  filters.serie = value;
                }}
                disabled={this.props.isFetching}
              >
                <Select.Option value="">Todas</Select.Option>
                <Select.Option value="CRE">CRE</Select.Option>
                <Select.Option value="CON">CON</Select.Option>
                <Select.Option value="C">C</Select.Option>
                <Select.Option value="CR">CR</Select.Option>
                <Select.Option value="RCO">RCO</Select.Option>
                <Select.Option value="RCR">RCR</Select.Option>
              </Select>
            </Col>
            <Col xs={23} sm={11} md={7} lg={5} xl={4} xxl={3} offset={1}>
              <Text className="font-color-Labeln">Folio</Text>
              <InputNumber
                placeholder="Folio"
                disabled={isFetching}
                onChange={(value: number | undefined): void => {
                  filters.folio = value;
                }}
                style={{ width: '100%', marginBottom: '10px' }}
                min={0}
                step={1}
                precision={0}
                defaultValue={filters.folio}
              />
            </Col>
            <Col xs={23} sm={23} md={11} lg={15} xl={8} xxl={6} offset={1}>
              <Text className="font-color-Labeln">
                Razón social/Nombre comercial
              </Text>
              <Input
                placeholder="Buscar"
                defaultValue={filters.customerName}
                disabled={isFetching}
                onChange={(event): void => {
                  filters.customerName = event.currentTarget.value;
                }}
                style={{ width: '100%', marginBottom: '10px' }}
              />
            </Col>
            <Col xs={23} sm={23} md={13} lg={11} xl={4} xxl={5} offset={1}>
              <Button
                type="primary"
                size={'default'}
                onClick={(): void => {
                  filters.currentPage = 1;
                  this.fetchInvoiceInfo(filters);
                }}
                disabled={isFetching}
                style={{ marginTop: '20px', marginRight: '10px' }}
              >
                <Icon type="search" />
                {'Buscar'}
              </Button>
            </Col>
          </Col>
        </Row>
        <Row>
          {/** Section to show modal */
          isObservationsVisible && (
            <ObservationsModal
              isVisible={isObservationsVisible}
              onCancel={(): Function => this.onCancelObservations}
              onCreate={(invoice: InvoiceForPortfolio): void =>
                this.onSaveObservations(invoice)
              }
              isFetching={isFetching}
              selectedRow={invoiceToUpdate}
            />
          )}
          {/** ------------- Body section (includes the table & the responsive list) ------------- */}
          <Col style={{ background: 'white' }}>
            <Table
              dataSource={invoicesPage.content}
              rowKey={(record: InvoiceForPortfolio): string => record.invoiceId}
              pagination={false}
              loading={isFetching}
              expandedRowKeys={expandedRowKeys}
              expandIconAsCell={false}
              expandIconColumnIndex={-1}
              expandedRowRender={(): JSX.Element => (
                <Table
                  className={'custom-table'}
                  dataSource={creNotes}
                  pagination={false}
                  rowKey={(record: CreNoteInvoice, index: number): string =>
                    record.chargeId + '_' + index
                  }
                  columns={creditNoteInvoiceColumns()}
                  size="small"
                />
              )}
              rowSelection={{
                selectedRowKeys,
                onSelect: this.onSelectRow,
                getCheckboxProps: (record: InvoiceForPortfolio): {} => ({
                  disabled: this.isRowDisabled(record),
                  name: record.invoiceId,
                }),
              }}
            >
              <Table.Column
                title="Fecha de la factura"
                dataIndex="invoiceDate"
                render={(invoiceDate: string): React.ReactNode =>
                  moment(invoiceDate).format(DATE_FORMAT)
                }
              />
              <Table.Column title="Cliente" dataIndex="customerName" />
              <Table.Column
                title="Serie/folio"
                dataIndex="invoiceFolio"
                render={(
                  invoiceFolio: string,
                  record: InvoiceForPortfolio,
                ): React.ReactNode => {
                  return record.invoiceSerie + '/' + invoiceFolio;
                }}
              />
              <Table.Column
                title="Importe total factura"
                dataIndex="invoiceTotal"
                render={(total: number): React.ReactNode => {
                  return '$ ' + toNumber(total);
                }}
              />
              <Table.Column
                title="Monto total a pagar"
                dataIndex="amountToPay"
                render={(amount: number): React.ReactNode => {
                  return '$ ' + toNumber(amount);
                }}
              />
              <Table.Column
                title="Saldo"
                dataIndex="debt"
                render={(debt: number): React.ReactNode => {
                  return '$ ' + toNumber(debt);
                }}
              />
              <Table.Column
                title="Acciones"
                dataIndex="invoiceId"
                render={(
                  invoiceId: string,
                  record: InvoiceForPortfolio,
                ): React.ReactNode => {
                  const isRowExpanded = expandedRowKeys[0] === record.invoiceId;
                  //Show a message when is disabled because of record has a pending payment
                  let buttonLabel = '';
                  if (record.withPendingPayment)
                    buttonLabel = 'Pago pendiente a validar';
                  else {
                    buttonLabel = isRowExpanded
                      ? 'Ocultar det.'
                      : 'Ver detalles';
                  }
                  return (
                    <div>
                      <Button
                        type="link"
                        className="ant-button"
                        onClick={(): void => {
                          if (
                            creNotes.length < 1 ||
                            (creNotes.length > 0 &&
                              creNotes[0].chargeId !== record.chargeId)
                          )
                            this.fetchCreNotesInfo(record.chargeId);
                          this.handleOnExpand(!isRowExpanded, record);
                        }}
                        disabled={record.withPendingPayment || isFetching}
                      >
                        {buttonLabel}
                      </Button>
                      {selectedRowKeys.includes(invoiceId) ? (
                        <Button
                          type="link"
                          className="ant-button"
                          onClick={(): void => {
                            invoiceToUpdate = record;
                            this.setState({ isObservationsVisible: true });
                          }}
                        >
                          Observaciones
                        </Button>
                      ) : (
                        <div />
                      )}
                    </div>
                  );
                }}
              />
            </Table>
          </Col>
          <Col span={24}>
            <Pagination
              showSizeChanger
              pageSizeOptions={['25', '50', '75', '100']}
              defaultPageSize={25}
              disabled={isFetching}
              current={(invoicesPage.number || 0) + 1}
              total={invoicesPage.totalElements}
              onShowSizeChange={this.onChangePageSize}
              onChange={this.onChangeCurrentPage}
            />
          </Col>
        </Row>
        <Row type="flex" justify="end">
          <Col
            xs={6}
            sm={2}
            md={2}
            lg={2}
            xl={1}
            xxl={1}
            className="separated-filters"
            style={{ paddingTop: '10px' }}
          >
            <Text className="font-color-Labeln">Fecha: </Text>
          </Col>
          <Col
            xs={18}
            sm={9}
            md={7}
            lg={6}
            xl={4}
            xxl={3}
            className="separated-filters"
          >
            <Form>
              <Form.Item>
                {form.getFieldDecorator('creationDate', {
                  initialValue: moment(),
                  rules: [
                    {
                      required: true,
                      message: 'Seleccione una fecha',
                    },
                  ],
                })(
                  <DatePicker
                    showTime={{ defaultValue: moment() }}
                    disabled={isFetching}
                    disabledDate={(date: moment.Moment | undefined): boolean =>
                      isDateWrong(moment().add(-1, 'd'), date)
                    }
                    format={DATE_FORMAT}
                    placeholder="Seleccionar"
                    style={{ width: '100%', marginBottom: '10px' }}
                    onOk={(selectedTime: moment.Moment): void => {
                      this.props.searchOpenedPortfolio(selectedTime.toString());
                    }}
                  />,
                )}
              </Form.Item>
            </Form>
          </Col>
          <Col
            xs={13}
            sm={8}
            md={6}
            lg={5}
            xl={4}
            xxl={3}
            className="separated-filters"
          >
            <Tooltip
              title={
                //Show a tooltip when there is an open portfolio & is a new portfolio register
                this.props.openedPortfolio.id !== '' &&
                this.props.match.params.id === '0'
                  ? `Tiene una cartera abierta; día: ${moment(
                      this.props.openedPortfolio.createdAt,
                    ).format(DATE_FORMAT)}, folio: ${
                      this.props.openedPortfolio.folio
                    }`
                  : ''
              }
            >
              <Button
                type="primary"
                onClick={(): void => {
                  portfolioDto.invoices = selectedRows;
                  this.handleSavePortfolio();
                }}
                size="default"
                style={{ marginBottom: '10px', marginRight: '20px' }}
                disabled={
                  isFetching ||
                  selectedRows.length < 1 ||
                  //Disable when there is an open portfolio & is a new portfolio register
                  (this.props.openedPortfolio.id !== '' &&
                    this.props.match.params.id === '0')
                }
              >
                <Icon type="save" />
                {this.props.match.params.id === '0'
                  ? ' Generar mi cartera'
                  : ' Guardar cambios'}
              </Button>
            </Tooltip>
          </Col>
        </Row>
      </Row>
    );
  };
}

const mapStateToProps = (states: RootState): StateProps => {
  return {
    invoicesPage: states.portfoliosState.invoicesPage,
    creNotes: Object.values(states.portfoliosState.creNotes),
    invoicesToEdit: Object.values(states.portfoliosState.invoicesToEdit),
    openedPortfolio: states.portfoliosState.openedPortfolio,
    isFetching: states.portfoliosState.isFetching,
  };
};

const mapDispatchToProps = {
  getInvoicesPage,
  save,
  update,
  getStatementCreNotes,
  setOrderedInvoicesPage,
  getByPortfolioId,
  setSelectedInvoicesFirst,
  searchOpenedPortfolio,
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create()(PortfolioFormComponent));
