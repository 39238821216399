import React, { Component } from 'react';
import { Row, Col, Button, Icon, Layout } from 'antd';
import { Link } from 'react-router-dom';
import { enterpriceLogo as centralGas } from 'boot/config';
import { Redirect } from 'react-router';
import Login from './login';
class CustomerInvoice extends Component {
  private renderHeader = (): JSX.Element => {
    return (
      <Layout.Header
        className={'layout-invoice-header'}
        style={{ minHeight: '10vh' }}
      >
        <Col xs={0} sm={0} md={2} lg={3} xl={4} xxl={4}>
          <img src={centralGas} className="logo-invoice" alt="Logo" />
        </Col>
        <Col
          xs={0}
          sm={0}
          md={3}
          lg={5}
          xl={7}
          xxl={9}
          className="align-center-col"
        />
        <Login />
      </Layout.Header>
    );
  };
  public render = (): React.ReactNode => {
    if (localStorage.getItem('token')) return <Redirect to="/" />;
    return (
      <Row>
        <Layout>
          {this.renderHeader()}
          <Layout.Content>
            <Row>
              <Col span={24}>
                <div>&nbsp;</div>
              </Col>
              <Col span={24}>
                <div>&nbsp;</div>
              </Col>
              <Col span={24}>
                <div>&nbsp;</div>
              </Col>
              <Col span={4} />
              <Col
                style={{ margin: '0 auto' }}
                xs={24}
                sm={24}
                md={24}
                lg={8}
                xl={8}
                xxl={8}
              >
                <Col
                  style={{
                    border: '.5rem solid #ffeae0',
                    backgroundColor: '#fff',
                  }}
                >
                  <Col style={{ border: '2.5rem solid #fff' }}>
                    <h1>
                      {' '}
                      <b style={{ color: '#db522f', fontSize: '2rem' }}>
                        BIENVENIDO
                      </b>
                    </h1>
                    <p>
                      <h3>
                        Aqui puedes generar tus facturas sin necesidad de
                        registrarte, solo necesitas tu ticket y tus datos
                        fiscales
                      </h3>
                    </p>
                    <p>
                      <Link to="/factura">
                        <Button type="primary" block size="large">
                          Facturar ticket
                        </Button>
                      </Link>
                    </p>
                  </Col>
                </Col>
              </Col>
              <Col span={1}>
                <div>&nbsp;</div>
              </Col>
              <Col
                style={{ margin: '0 auto' }}
                xs={24}
                sm={24}
                md={24}
                lg={8}
                xl={8}
                xxl={8}
              >
                <Col
                  style={{
                    border: '.5rem solid #e2edf8',
                    backgroundColor: '#fff',
                  }}
                >
                  <Col style={{ border: '2.5rem solid #fff' }}>
                    <p>
                      <h3>
                        <Icon
                          type="question-circle"
                          style={{ color: '#a7c2e4' }}
                        />
                        {
                          ' Si tienes dudas o necesitas ayuda para generar tu factura ponte en contacto con nosotros y con gusto te atenderemos.'
                        }
                      </h3>
                    </p>
                    <p>
                      <h3>
                        <Icon type="phone" style={{ color: '#db522f' }} /> (449)
                        912 22 22
                      </h3>
                    </p>
                    <p>
                      <h3>
                        <Icon type="mail" style={{ color: '#db522f' }} />
                        {' Atencionalcliente@centraldegas.net'}
                      </h3>
                    </p>
                    <p>
                      <h3>
                        <Icon
                          type="clock-circle"
                          style={{ color: '#a7c2e4' }}
                        />
                        {' Horario de Atención'}
                      </h3>
                    </p>
                    <p>
                      <b style={{ color: '#db522f' }}>Lunes Viernes:</b>07:00h a
                      19:00h
                    </p>
                    <p>
                      <b style={{ color: '#db522f' }}>Sabado:</b>07:00h a 17:00h
                    </p>
                    <p>
                      <b style={{ color: '#db522f' }}>Domingo:</b>08:00h a
                      14:00h
                    </p>
                    <p />
                  </Col>
                </Col>
              </Col>

              <Col span={2} />
            </Row>
          </Layout.Content>
        </Layout>
      </Row>
    );
  };
}

export default CustomerInvoice;
