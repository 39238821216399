/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by David Canul <david@ssf.com.mx>, Jun 2019
 *
 *
 */

import { combineReducers, Reducer } from 'redux';
import {
  Payment,
  PaymentFilters,
  DistributionCenter,
  Company,
} from 'types/type';
import { RootState } from 'store/configureStore';
import {
  PaymentAction,
  BEGIN_FETCH,
  FETCH_COMPLETED,
  SET_PAYMENT,
  SET_PAYMENT_FILTERS,
  SET_DISTRIBUTION_CENTER,
  SET_PAYMENT_COMPANY_LIST,
} from './types';
import { initPaymentFilters } from 'commons/initTypes';

export interface PaymentI {
  payment: Payment;
}
export interface PaymentList {
  content?: Payment[];
  number?: number;
  totalElements?: number;
}
export interface Total {
  total: number;
}

export interface PaymentState {
  readonly distributionCenter: DistributionCenterI;
  readonly paymentList: PaymentList;
  readonly isFetching: boolean;
  readonly total: Total;
  readonly paymentFilters: PaymentFilters;
  readonly companyList: Company[];
}

export interface DistributionCenterI {
  distributionCenter: DistributionCenter[];
}

const paymentList: Reducer<PaymentList> = (
  state: PaymentList = { content: [] },
  action,
): PaymentList => {
  if (action.type === SET_PAYMENT) {
    return { ...state, ...action.paymentList };
  }
  return state;
};

const distributionCenter: Reducer<DistributionCenterI> = (
  state: DistributionCenterI = { distributionCenter: [] },
  action,
): DistributionCenterI => {
  if (action.type === SET_DISTRIBUTION_CENTER) {
    return { ...state, distributionCenter: action.distributionCenter };
  }
  return state;
};

const paymentFilters: Reducer<PaymentFilters> = (
  state: PaymentFilters = initPaymentFilters,
  action,
): PaymentFilters => {
  if (action.type === SET_PAYMENT_FILTERS) {
    return { ...state, ...action.paymentFilters };
  }
  return state;
};

const companyList: Reducer<Company[]> = (
  state: Company[] = [],
  action,
): Company[] => {
  if (action.type === SET_PAYMENT_COMPANY_LIST) {
    return { ...state, ...action.companyId };
  }
  return state;
};

const isFetching: Reducer<boolean> = (
  state = false,
  action: PaymentAction,
): boolean => {
  switch (action.type) {
    case BEGIN_FETCH:
      return true;
    case FETCH_COMPLETED:
      return false;
    default:
      return state;
  }
};
export const getIsFetching = (store: RootState): boolean =>
  store.invoiceState.isFetching;

export default combineReducers<PaymentState>({
  paymentList: paymentList,
  paymentFilters,
  isFetching,
  distributionCenter,
  companyList,
});
