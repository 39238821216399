/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Ricardo Sansores <ricardo@ssf.mx>, May 2019
 *
 * Define the popups that can be launched from anyware in the app
 * and the messages are stadarized thru this class.
 */

import { notification } from 'antd';
import { Strings as i8n } from 'commons/strings';
import { parseApiError } from 'commons/error';
import { ExceptionMessage } from 'types/type';

const MESSAGE_DURATION = 5;

export class Popups {
  /**
   * Creates a Success Popopup in the application with
   * a given Message.
   */
  public static notifySuccess = (message: string): void => {
    notification.success({
      description: message,
      duration: MESSAGE_DURATION,
      message: i8n.SUCCESS,
    });
  };

  /**
   * Creates an error popup in the application with a given
   * exception.
   */
  public static notifyException = (message: unknown | ExceptionMessage): void => {
    notification.error(parseApiError(message));
  };

  /**
   * Just like notify Exception, this creates an error message
   * in a popup
   */
  public static notifyError = (message: string): void => {
    notification.error({
      description: message,
      duration: MESSAGE_DURATION,
      message: i8n.ERROR,
    });
  };

  /**
   * Is a common patter to try something and if success send a message if fail
   * throw a message with the error in a popup. This funcion abstracs this pattern
   * avoiding try catch.
   */

  public static executeOrNotifyError = (
    f: () => Promise<void>,
    message?: string,
  ): void => {
    try {
      f();
      if (message !== undefined) {
        Popups.notifySuccess(message);
      }
    } catch (err) {
      Popups.notifyException(err);
    }
  };
}
