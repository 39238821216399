/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Hiram Padilla <hiram@ssf.com.mx>, August 2020
 *
 * This component shows a table with the data of containers
 */
import React, { Component, ReactNode } from 'react';
import { InventoryList } from './redux/reducers';
import {
  Row,
  Form,
  Col,
  Table,
  Pagination,
  Select,
  Button,
  Tag,
  Popconfirm,
  Icon,
  Tooltip,
  Divider,
  notification,
} from 'antd';
import { connect } from 'react-redux';
import { RootState } from 'store/configureStore';
import {
  getPage,
  save,
  getEquipments,
  deleteContainer,
  saveEquivalences,
  getEquivalences,
  resetEquivalences,
  getTotalIndicators,
  setEndFetch,
} from './redux/actions';
import { responsiveList } from './components/responsiveList';
import getIndicators from './components/indicators';
import Text from 'antd/lib/typography/Text';
import CollectionForm from './components/form';
import CollectionSubcontainerForm from './components/formSubcontainer';
import { FormComponentProps } from 'antd/lib/form';
import { Popups } from 'commons/components/popups/popups';
import {
  Container,
  Equipment,
  ChildContainer,
  ContainerFilters,
  Equivalence,
  SaleEquipment,
  LiterTotals,
} from 'types/type';
import { ColumnProps } from 'antd/lib/table';
import { isAllowed, Token, getToken } from 'security';
import JwtDecode from 'jwt-decode';
import { Permissions as P } from 'commons/permissions';
import Search from 'antd/lib/input/Search';
import { toNumber } from 'commons/numbersFormat';
import CollectionEquivalencesForm from './components/formEquivalences';
import { parseApiError } from 'commons/error';
import { Globals } from 'commons/globals';
import { ApiRoute, callApiGetFile } from 'commons/services/api';

interface StateProps {
  inventory: InventoryList;
  isFetching: boolean;
  equipments: Equipment[];
  containerFilters: ContainerFilters;
  equivalences: Equivalence[];
  totalIndicators: LiterTotals;
}
interface DispatchProps {
  getPage: (containerFilters: ContainerFilters) => void;
  save: (container: Container) => void;
  getEquipments: (distributionCenterId: string) => void;
  deleteContainer: (id: string) => void;
  saveEquivalences: (equivalences: Equivalence[]) => void;
  getEquivalences: (id: string) => void;
  resetEquivalences: () => void;
  getTotalIndicators: (containerFilters: ContainerFilters) => void;
  setEndFetch: () => void;
}

type Props = StateProps & DispatchProps & FormComponentProps;

class InventoryComponent extends Component<Props> {
  public state = {
    inventory: [],
    isVisible: false,
    isSubcontainerModalVisible: false,
    isEquivalenceModalVisible: false,
    currentPage: 0,
    pageSize: 25,
    currentContainer: undefined,
    currentSubcontainer: undefined,
    currentContainerId: '',
    expandedRows: [],
    equipmentsToSave: [],
  };

  private fetchData = (): void => {
    //Asyc function to use await in multiple async functions
    const getData = async (): Promise<void> => {
      await this.props.getPage(this.props.containerFilters);
      await this.props.getTotalIndicators(this.props.containerFilters);
      let distributionCenter = '';
      if (!isAllowed([P.CAN_VIEW_ALL_DISTRIBUTION_CENTERS])) {
        distributionCenter = JwtDecode<Token>(getToken() as string)
          .distributionCenterId;
      }
      await this.props.getEquipments(distributionCenter);
    };
    getData();
  };

  public componentDidMount(): void {
    this.fetchData();
  }

  /** These following 2 functions change page and replaces current position in the pagination */
  private handlePaginationChangeNew = (currentPage: number): void => {
    this.props.getPage({ ...this.props.containerFilters, currentPage });
    //Set state to update what screen is showing
    this.setState({ currentPage: currentPage });
  };

  private handlePaginationChange = (
    currentPage: number,
    pageSize?: number,
  ): void => {
    this.props.getPage({
      ...this.props.containerFilters,
      currentPage,
      pageSize: pageSize !== undefined ? pageSize : this.state.pageSize,
    });
    //Set state to update what screen is showing
    this.setState({ currentPage, pageSize });
  };

  private handleCancelContainer = (): void => {
    this.setState({ isVisible: false });
  };

  private setEquipments = (equipments: SaleEquipment[]): void => {
    this.setState({ equipmentsToSave: equipments });
  };
  private handleCreateContainer = (): void => {
    //Validate form data and save it, then update the table
    const form = this.props.form;
    form.validateFields(
      async (err, values): Promise<void> => {
        if (!err) {
          try {
            //Using await for more than one async functions
            await this.props.save({
              ...values,
              saleEquipments: this.state.equipmentsToSave,
            });
            form.resetFields();
            await this.props.getPage(this.props.containerFilters);
            await this.props.getTotalIndicators(this.props.containerFilters);
            this.setState({
              isVisible: false,
            });
          } catch (e) {
            await this.props.setEndFetch();
            Popups.notifyException(e);
          }
        }
      },
    );
  };

  private handleCancelSubcontainer = (): void => {
    this.setState({ isSubcontainerModalVisible: false });
  };

  private handleCreateSubcontainer = (): void => {
    //Validate form data and save it, then update the table
    const form = this.props.form;
    form.validateFields(
      async (err, values): Promise<void> => {
        if (!err) {
          try {
            //Using await for more than one async functions
            await this.props.save(values);
            form.resetFields();
            await this.props.getPage(this.props.containerFilters);
            this.setState({
              isSubcontainerModalVisible: false,
            });
          } catch (err) {
            await this.props.setEndFetch();
            notification.error(parseApiError(err));
          }
        }
      },
    );
  };

  private handleCancelEquivalences = (): void => {
    this.props.resetEquivalences();
    this.setState({
      isEquivalenceModalVisible: false,
    });
  };

  private handleCreateEquivalences = (): void => {
    const form = this.props.form;
    form.validateFields(
      async (err, values): Promise<void> => {
        //Validate form data and save it, then update the table
        if (!err) {
          try {
            const { equivalences } = values;
            //Using await for more than one async functions
            await this.props.saveEquivalences(equivalences);
            await this.props.resetEquivalences();
            form.resetFields();
            await this.props.getPage(this.props.containerFilters);
            this.setState({
              isEquivalenceModalVisible: false,
            });
          } catch (e) {
            Popups.notifyException(e);
          }
        }
      },
    );
  };

  private onChangeFilters = (containerFilters: ContainerFilters): void => {
    //Asyc function to use await in multiple async functions
    const reloadData = async (): Promise<void> => {
      await this.props.getPage(containerFilters);
      await this.props.getTotalIndicators(this.props.containerFilters);
    };
    reloadData();
  };

  /** Filters and button to add containers */
  public renderHeader = (): JSX.Element => {
    const { isFetching } = this.props;
    return (
      <Row style={{ marginBottom: '20px' }}>
        <Col span={18}>
          <Col span={6} style={{ padding: '20px 10px' }}>
            <Text strong>{'Buscar por código de cliente: '}</Text>
            <Search
              size="default"
              placeholder="Código"
              disabled={isFetching}
              onSearch={(value): void =>
                this.onChangeFilters({
                  ...this.props.containerFilters,
                  code: value,
                  currentPage: 0,
                })
              }
            />
          </Col>
          <Col span={6} style={{ padding: '20px 10px' }}>
            <Text strong>{'Tipo de contenedor: '}</Text>
            <Select
              size="default"
              style={{ width: '100%' }}
              disabled={isFetching}
              onChange={(value: string): void =>
                this.onChangeFilters({
                  ...this.props.containerFilters,
                  containerType: value,
                  currentPage: 0,
                })
              }
              defaultValue=""
            >
              <Select.Option value="">Todos</Select.Option>
              <Select.Option value="Autotanque">Autotanque</Select.Option>
              <Select.Option value="Carburacion">Carburación</Select.Option>
              <Select.Option value="Cilindro">Cilindro</Select.Option>
              <Select.Option value="Almacen">Almacén</Select.Option>
            </Select>
          </Col>
        </Col>
        <Col span={6} style={{ textAlign: 'right', padding: '20px 10px' }}>
          <Button
            type="primary"
            onClick={(): void => {
              this.setState({
                isVisible: !this.state.isVisible,
                currentContainer: undefined,
              });
            }}
          >
            Agregar contenedor
          </Button>
              { isAllowed([ P.DOWNLOAD_DEI_REPORT ]) && (<Button
                type="primary"
                shape="round"
                size={'default'}
                onClick={async () => {
                  let yesterday = Globals.Utils.Date.dateToStringMinusDays(new Date(), 1);
                  let dateStart = await Globals.Utils.Prompt.dateString(`Ingrese fecha inicial.`, { initial: yesterday });
                  if(!dateStart) return;
                  let dateEnd = await Globals.Utils.Prompt.dateString(`Ingrese fecha final.`, { initial: Globals.Utils.Date.dateToString() });
                  if(!dateEnd) return;
                  console.log(dateStart, dateEnd);
                  //let distCenterId = distributionCenter as DbOldValues._DistCenterId || undefined;
                  let dateFileName = dateStart !== dateEnd ? `${dateStart}_${dateEnd}`: `${dateStart}`;
                  callApiGetFile(ApiRoute.GetDeiReport, { dateRange: [ dateStart, dateEnd ] }).then(result => {
                    let fileName = `RegistroInventario_${dateFileName}.csv`;//${distCenterId ? `_${DbOldDetails.distCenterId[distCenterId].name}` : ''}
                    if(result) Globals.Utils.File.downloadBlob(result, fileName);
                    else alert(`Error obteniendo archivo.`);
                  });
                  //this.props.downloadReport(this.props.settlementFilters);
                }}
                disabled={
                  this.props.isFetching 
                }
              >
                <Icon type={'download'} />
                {'Reporte inventario QR'}
              </Button>)}
        </Col>
      </Row>
    );
  };

  /** Modal to add or edit containers */
  public renderContainerModal = (): JSX.Element => {
    const { isFetching, form } = this.props;
    const { setFieldsValue } = form;
    return (
      <Row>
        <CollectionForm
          isVisible={this.state.isVisible}
          onCancel={(): Function => this.handleCancelContainer}
          onCreate={(): Function => this.handleCreateContainer}
          onSearch={(): void => {}}
          form={this.props.form}
          isFetching={isFetching}
          setFieldsValue={setFieldsValue}
          equipments={this.props.equipments}
          container={this.state.currentContainer}
          setEquipments={(equipment: SaleEquipment[]): void =>
            this.setEquipments(equipment)
          }
        />
      </Row>
    );
  };

  /** Modal to add or edit sub containers */
  public renderSubcontainerModal = (): JSX.Element => {
    return (
      <Row>
        <CollectionSubcontainerForm
          isSubcontainerModalVisible={this.state.isSubcontainerModalVisible}
          onCancel={(): Function => this.handleCancelSubcontainer}
          onCreate={(): Function => this.handleCreateSubcontainer}
          form={this.props.form}
          equipments={this.props.equipments}
          currentContainerId={this.state.currentContainerId}
          currentSubcontainer={this.state.currentSubcontainer}
        />
      </Row>
    );
  };

  /** Modal to add or edit cubic capacity */
  public renderEquivalencesModal = (): JSX.Element => {
    return (
      <Row>
        <CollectionEquivalencesForm
          isEquivalenceVisible={this.state.isEquivalenceModalVisible}
          onCancel={(): Function => this.handleCancelEquivalences}
          onCreate={(): Function => this.handleCreateEquivalences}
          form={this.props.form}
          currentContainer={this.state.currentContainer}
          currentEquivalences={this.props.equivalences}
        />
      </Row>
    );
  };

  //Columns for the subtable in each row of containers
  public subcontainerTableColumns = (
    idContainer: string,
  ): ColumnProps<ChildContainer>[] => {
    return [
      {
        title: 'Nombre de subcontenedor',
        dataIndex: 'name',
      },
      {
        title: 'Capacidad',
        dataIndex: 'capacity',
        render: (capacity: number): ReactNode => toNumber(capacity, true),
      },
      {
        title: 'Acciones',
        render: (record: ChildContainer): ReactNode => (
          <Col>
            <Tooltip title="Editar">
              <Icon
                type="edit"
                theme="twoTone"
                onClick={(): void => {
                  this.setState({
                    isSubcontainerModalVisible: true,
                    currentSubcontainer: record,
                    currentContainerId: idContainer,
                  });
                }}
              />
            </Tooltip>
            <Divider type="vertical" />
            <Tooltip title="Eliminar subcontenedor">
              <Popconfirm
                title="¿Estás seguro que desea eliminar el sub contenedor?"
                okText="Sí,eliminar"
                cancelText="No"
                onConfirm={async (): Promise<void> => {
                  await this.props.deleteContainer(record.id);
                  await this.props.getPage(this.props.containerFilters);
                  await this.props.getTotalIndicators(
                    this.props.containerFilters,
                  );
                  this.setState({
                    currentSubcontainer: undefined,
                  });
                }}
                icon={
                  <Icon type="question-circle-o" style={{ color: 'red' }} />
                }
                style={{ width: '40px' }}
              >
                <Icon
                  style={{ color: 'black' }}
                  type="delete"
                  theme="twoTone"
                />
              </Popconfirm>
            </Tooltip>
            <Button
              type="link"
              block
              onClick={(): void => {
                this.props.getEquivalences(record.id);
                this.setState({
                  isEquivalenceModalVisible: true,
                  currentContainer: record,
                });
              }}
              style={{ width: '110px' }}
            >
              {'Ver cubicaje'}
            </Button>
          </Col>
        ),
      },
    ];
  };

  public render = (): JSX.Element => {
    const { inventory, isFetching } = this.props;
    return (
      <div className={'content-background'}>
        <Row>
          {getIndicators(this.props.totalIndicators)}
          {this.renderHeader()}
          {this.state.isVisible && this.renderContainerModal()}
          {this.state.isSubcontainerModalVisible &&
            this.renderSubcontainerModal()}
          {this.state.isEquivalenceModalVisible &&
            this.renderEquivalencesModal()}
          <Col xs={24} sm={24} md={24} lg={24} xl={0} xxl={0}>
            {responsiveList(inventory)}
          </Col>
          <Col
            xs={0}
            sm={0}
            md={0}
            lg={0}
            xl={24}
            xxl={24}
            style={{ backgroundColor: 'white', padding: '40px 20px' }}
          >
            <Table
              onExpand={(expand: boolean, record: Container): void =>
                //Property used to close the other rows when the selected is expanded
                expand
                  ? this.setState({
                      expandedRows: [record.id],
                    })
                  : this.setState({
                      expandedRows: [],
                    })
              }
              expandedRowKeys={this.state.expandedRows}
              dataSource={inventory.content}
              pagination={false}
              loading={isFetching}
              rowKey={(record): string => record.id}
              expandedRowRender={(record): JSX.Element => (
                <Table
                  className={'custom-table'}
                  dataSource={record.childContainers}
                  pagination={false}
                  columns={this.subcontainerTableColumns(record.id)}
                />
              )}
            >
              <Table.Column title="Nombre" dataIndex="name" />
              <Table.Column title="Tipo" dataIndex="type" />
              <Table.Column
                title="Capacidad (L)"
                dataIndex="capacity"
                render={(capacity: number): React.ReactNode =>
                  toNumber(capacity, true)
                }
              />
              <Table.Column
                title="Cubicaje"
                dataIndex="currentTheoreticalVolume"
                render={(text: number, record: Container): React.ReactNode => {
                  return record.childContainers.length === 0 ? (
                    <Button
                      type="link"
                      block
                      onClick={(): void => {
                        this.props.getEquivalences(record.id);
                        this.setState({
                          isEquivalenceModalVisible: true,
                          currentContainer: record,
                        });
                      }}
                    >
                      {'Ver cubicaje'}
                    </Button>
                  ) : (
                    'Ver en subcontenedores'
                  );
                }}
              />
              <Table.Column
                title="Gaspares"
                dataIndex="saleEquipments"
                render={(equipments: SaleEquipment[]): React.ReactNode => {
                  return (
                    <Col>
                      {equipments.map(
                        (item): React.ReactNode => {
                          return (
                            <Tag color="blue" key={item.id}>
                              {item.tag}
                            </Tag>
                          );
                        },
                      )}
                    </Col>
                  );
                }}
              />
              <Table.Column
                title="Código de cliente / UDR"
                dataIndex="customerCode"
              />
              <Table.Column
                title="% / Volumen de tolerancia"
                dataIndex="tolerance"
                render={(tolerance: number): React.ReactNode =>
                  toNumber(tolerance, true)
                }
              />
              <Table.Column
                title="Inventario actual %"
                dataIndex="currentTheoreticalInventoryLevel"
                render={(currentLevel: number): React.ReactNode => (
                  <Col>
                    {currentLevel ? currentLevel : 0}
                    {' %'}
                  </Col>
                )}
              />
              <Table.Column
                title="Volumen actual (L)"
                dataIndex="currentTheoreticalVolume"
                render={(currentVolume: number): React.ReactNode => (
                  <Col>
                    {currentVolume ? currentVolume : 0}
                    {' Lts'}
                  </Col>
                )}
              />
              <Table.Column
                title="Acciones"
                dataIndex="name"
                width="160px"
                render={(text: string, record: Container): React.ReactNode => {
                  return (
                    <Col>
                      <Tooltip title="Editar">
                        <Icon
                          type="edit"
                          theme="twoTone"
                          onClick={(): void => {
                            let equipments = record.saleEquipments.map(
                              (eq: SaleEquipment): string => {
                                return eq.id;
                              },
                            );
                            let equipmentsToSave = record.saleEquipments.map(
                              (eq: SaleEquipment): SaleEquipment => {
                                return { id: eq.id, tag: eq.tag };
                              },
                            );
                            this.setState({
                              isVisible: true,
                              currentContainer: { ...record, equipments },
                              equipmentsToSave,
                            });
                          }}
                        />
                      </Tooltip>
                      <Divider type="vertical" />
                      <Tooltip title="Eliminar contenedor">
                        <Popconfirm
                          title={
                            <div>
                              {'¿Está seguro que desea eliminar el contenedor?'}
                              <br />
                              {
                                'Se borrarán todos los subcontendores pertenecientes al mismo.'
                              }
                            </div>
                          }
                          okText="Sí,eliminar"
                          cancelText="No"
                          placement="topRight"
                          onConfirm={async (): Promise<void> => {
                            await this.props.deleteContainer(record.id);
                            await this.props.getPage(
                              this.props.containerFilters,
                            );
                            await this.props.getTotalIndicators(
                              this.props.containerFilters,
                            );
                            this.setState({
                              currentContainer: undefined,
                            });
                          }}
                          icon={
                            <Icon
                              type="question-circle-o"
                              style={{ color: 'red' }}
                            />
                          }
                        >
                          <Icon
                            style={{ color: 'black' }}
                            type="delete"
                            theme="twoTone"
                          />
                        </Popconfirm>
                      </Tooltip>
                      <Divider type="vertical" />
                      <Tooltip title="Agregar subcontenedor">
                        <Icon
                          type="plus-circle"
                          theme="twoTone"
                          onClick={(): void => {
                            this.setState({
                              isSubcontainerModalVisible: !this.state
                                .isSubcontainerModalVisible,
                              currentContainerId: record.id,
                              currentSubcontainer: undefined,
                            });
                          }}
                        />
                      </Tooltip>
                    </Col>
                  );
                }}
              />
            </Table>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <Pagination
              showSizeChanger
              pageSizeOptions={['25', '50', '75', '100']}
              defaultPageSize={25}
              disabled={isFetching}
              current={(this.props.inventory.number || 0) + 1}
              total={this.props.inventory.totalElements}
              onShowSizeChange={this.handlePaginationChange}
              onChange={this.handlePaginationChangeNew}
            />
          </Col>
        </Row>
      </div>
    );
  };
}

const mapStateToProps = (states: RootState): StateProps => {
  return {
    inventory: states.inventoryState.inventory,
    isFetching: states.inventoryState.isFetching,
    equipments: Object.values(states.inventoryState.equipments),
    containerFilters: states.inventoryState.containerFilters,
    equivalences: Object.values(states.inventoryState.equivalences),
    totalIndicators: states.inventoryState.totalIndicators,
  };
};

const mapDispatchToProps: DispatchProps = {
  getPage,
  save,
  getEquipments,
  deleteContainer,
  saveEquivalences,
  getEquivalences,
  resetEquivalences,
  getTotalIndicators,
  setEndFetch,
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create()(InventoryComponent));
