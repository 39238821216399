/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Ricardo Sansores <ricardo@ssf.mx>, May 2019
 *
 * All the strings used in the frontend come from this file
 * we are not using a library for this because this project
 * won't be internacionalizated in the short term so this is
 * a non elegant but at least separated solution.
 */

export class Strings {
  public static ERROR = '👎 Error';
  public static SUCCESS = '👍 Éxito';
  public static INVOICE_CREATED =
    'La factura 🗎 ha sido procesada. Pronto recibirá sus documentos por correo electrónico. ✉';
  public static TICKET_ALREADY_ADDED = 'El ticket 🗎 ya ha sido agregado';
  public static NO_TICKET_IN_STAGING_AREA = 'Falta capturar notas de venta';
  public static TICKET_STEP = 'Ticket';
  public static FISCAL_DATA_STEP = 'Datos fiscales';
  public static BUILD_INVOICE_STEP = 'Generar factura';
  public static IUS_ADDED_IN_STAGING_AREA = 'IUS agregados';
  public static STAMP_INVOICE_TICKET = 'Factura ticket';
  public static EMPTY_STRING = '';
}
export class MessagesCustomer {
  public static ENTERPRICE__NAME_REQUIERED: string =
    'Ingresa un nombre de empresa';
  public static HUNDRED_CHARACTERS: string =
    'No se permiten mas de 100 caracteres';
  public static TWENTY_CHARACTERS: string =
    'No se permiten mas de 20 caracteres';
  public static FIVE_HUNDRED_CHARACTERS: string =
    'No se permiten mas de 500 caracteres';
  public static FIVE_CHARACTERS: string = 'No se permiten mas de 5 caracteres';
  public static FIFTY_CHARACTERS: string =
    'No se permiten más de 50 caracteres';
  public static RFC_REQUIERED: string = 'Ingresa un RFC';
  public static RFC_VALID: string = 'Ingresa un rfc válido';
  public static STREET_REQUIERED = 'Ingresa una calle';
  public static EXTERNAL_NUMBER_REQUIRED: string = 'Ingresa un número exterior';
  public static FISCA_REGIMEN_REQUIERED: string = 'Ingresa un régimen fiscal';
  public static ZIP_CODE_REQUIRED: string = 'Ingresa un código postal';
  public static ZIP_CODE_VALID = 'Ingresa un código postal Válido';
  public static COLONY_REQUIRED = 'Ingresa una colonia';
  public static CUSTOMER_CODE_REQUIRED = 'Ingresa un código de Cliente';
  public static INT_ONLY = 'Solo se aceptan números enteros';
  public static CREDIT_DAYS_RULE = 'Ingresa los dias de credito';
  public static DISCOUNT_REQUIRED = 'Ingresa un descuento puede ser 0';
  public static NUMBERS_ONLY = 'Ingresa una cantidad válida';
  public static COMERCIAL_NAME_REQUIERED = 'Ingresa un nombre comercial';
  public static CITY_REQUIRED = 'Ingresa una ciudad';
  public static MUNICIPALITY_REQUIRED = 'Ingresa un municipio';
  public static STATE_REQUIERED = 'Ingresa un estado';
  public static PASSWORD_REQUIRED = 'Ingresa una contraseña';
  public static CFDI_REQUIRED = 'Selecciona un uso de CFDI';
  public static FISCAL_REG_REQUIRED = 'Selecciona un Regimen fiscal';
  public static BANK_REQUIRED = 'Selecciona un banco';
  public static PAYMET_FORM_REQUIRED = 'Selecciona forma de pago';
  public static EMAIL_REQUIRED = 'Ingresa un correo electrónico';
  public static EMAIL_VALID = 'Ingresa un correo electrónico valido';
  public static DISTRIBUTION_CENTER_VALID =
    'Selecciona un centro de distribución';
  public static DATE_REQUIRED = 'Selecciona una fecha';
  public static CONTACT_NAME_REQUIRED = 'El nombre del contacto es requerido';
  public static CONTACT_BIRTHDAY_REQUIRED =
    'La fecha de nacimiento del contacto es requerida';
  public static CONTACT_PHONE_REQUIRED =
    'Se requiere almenos un numero de teléfono';
  public static CONTACT_MARKET_STALL_REQUIRED =
    'Se requiere ingresar el puesto de trabajo';
  public static CONTACT_PHONE_MIN = 'El teléfono debe tener 10 digitos';
  public static PHONE_NUMBER_RQUIRED: string = 'Se requiere telefono';
}

export class LabelsCustomer {
  public static CUSTOMER_CODE: string = 'Código G4s';
  public static COMERCIAL_NAME: string = 'Nombre comercial';
  public static DISCOUNT: string = 'Descuento %';
  public static DISTRIBUTION_CENTER: string = 'Centro de distribución G4S';
  public static BIRTHDAY: string = 'Cumpleaños';
  public static ASSIGNED_USER: string = 'Usuario asignado a este cliente';
  public static CUSTOMER_TYPE: string = 'Tipo de cliente';
  public static CUSTOMER_APARCERO: string = 'Cliente aparcero';
  public static RFC: string = 'RFC';
  public static BUSINESS_NAME: string = 'Razón social';
  public static COMERTIAL_CODE: string = 'Código comercial Pro';
  public static EXTERNAL_NUMBER: string = 'Número exterior';
  public static INTERNAL_NUMBER: string = 'Número interior';
  public static ZIP_CODE: string = 'Código postal';
  public static STATE: string = 'Estado';
  public static CITY: string = 'Ciudad';
  public static COLONY: string = 'Colonia';
  public static MUNICIPALITY: string = 'Municipio';
  public static STREET: string = 'Calle';
  public static OBSERVATIONS: string = 'Observaciones';
  public static CREDIT_DAYS: string = 'Días de crédito';
  public static CREDIUT_LIMIT: string = 'Límite de crédito';
  public static EMAIL: string = 'Correo electrónico';
  public static PASSWORD: string = 'Contraseña';
  public static PAYMENY_FORM: string = 'Forma de pago';
  public static USE_CFDI: string = 'Uso de CFDI';
  public static FISCAL_REG: string = 'Regimen fiscal';
  public static BAD_CUSTOMER: string = 'Incobrable';
  public static CIE_KEY: string = 'Clave CIE';
  public static INVOICE_AUTOMATIC: string = '¿Deseas autofacturar?';
  public static INVOICE_GROUP_BY_SALE: string =
    '¿Deseas agrupar Ventas en una Factura?';
  public static CONTACT_NAME: string = 'Nombre de contacto';
  public static CONTACT_EMAIL: string = 'Correo electrónico';
  public static CONTACT_HOUSE_PHONE: string = 'Teléfono particular';
  public static CONTACT_MOVIL_PHONE: string = 'Teléfono celular';
  public static CONTACT_OFICE_PHONE: string = 'Teléfono oficio';
  public static CONTACT_MARKET_STALL: string = 'Puesto de trabajo';
  public static CONTACT_RESIVE_MAILS: string = 'Recive correos';
  public static PHONE_NUMBER: string = 'Teléfono';
  public static EMAIL_INVOICE: string = 'Email';
  public static LOCATION: string = 'Dirección';
  public static DISABLED_CUSTOMER: string = 'Cliente deshabilitado';
}
export class LabelsCostomerAddress {
  public static CUTOMER_ADDRESS_CODE: string = 'Clave UDR';
  public static CUTOMER_ADDRESS_DESCRIPTION: string =
    'Descripción de la Sucursal';
  public static CUTOMER_ADDRESS_PRICE: string = 'Precio de venta';
  public static CUTOMER_ADDRESS_CAPACITY: string = ' Capacidad de la unidad';
  public static CUTOMER_ADDRESS_GENERAL_PURPOSE: string = ' Propósito general';
  public static CUTOMER_ADDRESS_COUNTRY: string = ' País';
  public static CUTOMER_ADDRESS_STREET: string = ' Calle';
  public static CUTOMER_ADDRESS_SUBURB: string = ' Colonia';
  public static CUTOMER_ADDRESS_CITY: string = ' Ciudad';
  public static CUTOMER_ADDRESS_STATE: string = ' Estado';
  public static CUTOMER_ADDRESS_PRODUCT: string = ' Producto';
  public static CUTOMER_ADDRESS_MUNICIPALITY: string = '  Municipio';
  public static CUTOMER_ADDRESS_INTERNAL_NUMBER: string = ' Número interior';
  public static CUTOMER_ADDRESS_EXTERNAL_NUMBER: string = ' Número exterior';
  public static CUTOMER_ADDRESS_ZIP_CODE: string = ' Código postal';
  public static CUTOMER_ADDRESS_COPIES_TO_PRINT: string = ' Copias a imprimir';
  public static CUTOMER_ADDRESS_COMMODE: string = ' Comodato';
}
export class MessageCustomerAddress {
  public static CUTOMER_ADDRESS_CODE_RULE: string =
    'Ingresa una clave de UDR valida';
  public static CUTOMER_ADDRESS_DESCRIPTION: string =
    'Se requiere la descripción de la sucursal';
  public static CUTOMER_ADDRESS_PRICE: string =
    'Se requiere el precio de venta';
  public static CUTOMER_ADDRESS_CAPACITY: string =
    'Se requiere el capacidad de la unidad';
  public static CUTOMER_ADDRESS_STREET: string =
    'Se requiere la Calle de la UDR';
  public static CUTOMER_ADDRESS_COUNTRY: string =
    'Se requiere el país de la UDR';
  public static CUTOMER_ADDRESS_STATE: string = 'Selecciona un estado';
  public static CUTOMER_ADDRESS_CITY: string = 'Se requiere una ciudad';
  public static CUTOMER_ADDRESS_MUNICIPALITY: string =
    'Selecciona un municipio';
  public static CUTOMER_ADDRESS_SUBURB: string = 'se requiere una colonia';
  public static CUTOMER_ADDRESS_PRODUCT: string =
    'Se requiere el tipo de producto';
  public static CUTOMER_ADDRESS_ZIP_CODE: string = ' Se requiere código postal';
  public static CUTOMER_ADDRESS_INTERNAL_NUMBER: string =
    ' Se requiere número interior';
  public static CUTOMER_ADDRESS_EXTERNAL_NUMBER: string =
    ' Se requiere número exterior';
}

export class MessagesUser {
  public static USER_NAME_RULE: string = 'Ingrese el nombre';
  public static USER_FIRST_NAME_RULE: string = 'Ingrese el apellido paterno';
  public static USER_LAST_NAME_RULE: string = 'Ingrese el apellido materno';
  public static USER_PHONE_RULE: string = 'Ingrese un numero telefonico';
  public static USER_ONLY_NUMBER_RULE: string = 'Solo se aceptan numeros';
  public static USER_PHONE_MIN_RULE: string =
    'El telefono debe tener 10 digitos';
  public static USER_MARKETSTALL_RULE: string =
    'Seleccione un puesto de trabajo';
  public static USER_DISTRIBUTIONCENTER_RULE: string =
    'Seleccione un centro de distribucion';
  public static USER_BIRTHDAY_RULE: string = 'Ingrese la fecha de nacimiento';
  public static USER_ADMISSION_DATE_RULE: string =
    'Se requiere la fecha de ingreso';
  public static USER_EMAIL_RULE: string =
    'Ingrese un correo electrónico valido';
}

export class LabelsUser {
  public static USER_NAME: string = 'Nombre';
  public static USER_FIRST_NAME: string = 'Apellido Paterno';
  public static USER_LAST_NAME: string = 'Apellido Materno';
  public static USER_PHONE: string = 'Teléfono';
  public static USER_MARKETSTALL: string = 'Puesto de Trabajo';
  public static USER_DISTRIBUTIONCENTER: string = 'Centro de Distribución';
  public static USER_BIRTHDAY: string = 'Fecha de Nacimiento';
  public static USER_ADMISSION_DATE: string = 'Fecha de Ingreso';
  public static USER_EMAIL: string = 'Correo Electrónico';
  public static USER_PASSWORD: string = 'Contraseña';
}
