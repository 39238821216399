import axios, { AxiosResponse } from 'axios';
import Service from '../Service';
import {
  createCoreServiceUrl,
  createParamsService,
} from '../../commons/services';
import {
  Settlement,
  Pageable,
  SettlementFilters,
  SettlementsTotals,
  Sale,
  ModelSaleToUpdate,
  Equipment,
  SalesAuditArray,
  ContainerBuilder,
  ContainerPurchase,
} from '../../types/type';
import moment from 'moment';

class SettlementService extends Service {
  public SettlementList = (filters: SettlementFilters): Promise<Pageable> => {
    return axios({
      method: 'post',
      url: createCoreServiceUrl(`settlement/pageable`),
      data: filters,
    }).then((response: AxiosResponse<Pageable>): Pageable => response.data);
  };

  public SettlementTotals = (
    filters: SettlementFilters,
  ): Promise<SettlementsTotals> => {
    return axios({
      method: 'post',
      url: createCoreServiceUrl(`settlement/totals`),
      data: filters,
    }).then(
      (response: AxiosResponse<SettlementsTotals>): SettlementsTotals =>
        response.data,
    );
  };

  public saveSaleToUpdate = (filters: ModelSaleToUpdate): Promise<Sale> => {
    return axios({
      method: 'post',
      url: createCoreServiceUrl(`sale/editsale`),
      data: filters,
    }).then((response: AxiosResponse<Sale>): Sale => response.data);
  };

  public saveSettlement = (
    operator: string,
    assistant: string,
    id: string,
    apply: string,
    comment: string,
    containerValues: ContainerBuilder[],
    containerPurchases: ContainerPurchase[],
  ): Promise<Settlement> => {
    return axios({
      method: 'post',
      url: createCoreServiceUrl(`settlement/apply`),
      data: {
        operator,
        assistant,
        id,
        apply,
        comment,
        containerValues,
        containerPurchases,
      },
    }).then((response: AxiosResponse<Settlement>): Settlement => response.data);
  };

  public saveposSettlement = (
    salesId: { id: string; total: number }[],
    id: string,
  ): Promise<Settlement> => {
    return axios({
      method: 'post',
      url: createCoreServiceUrl(`settlement/posSettlement`),
      data: { salesId, id },
    }).then((response: AxiosResponse<Settlement>): Settlement => response.data);
  };

  public settlementEquipmentList = (
    distributionCenterId: string = '',
  ): Promise<Equipment[]> =>
    axios({
      method: 'post',
      url: createCoreServiceUrl(`settlement/equipment`),
      data: { distributionCenterId },
    }).then(
      (response: AxiosResponse<Equipment[]>): Equipment[] => response.data,
    );

  public getSettlementbyId = (id: string): Promise<Settlement> =>
    axios
      .get(createCoreServiceUrl(`settlement/settlementById/${id}`))
      .then((response: AxiosResponse<Settlement>): Settlement => response.data);

  public getUser = async (userId: string): Promise<string> =>
    await axios
      .get(createCoreServiceUrl(`user/name/${userId}`))
      .then((response: AxiosResponse<string>): string => response.data);

  public getSaleAuditbyId = (id: string): Promise<SalesAuditArray[]> =>
    axios
      .get(createCoreServiceUrl(`sale/${id}/history`))
      .then((response: AxiosResponse<SalesAuditArray[]>): SalesAuditArray[] => {
        return response.data.map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (item: any): SalesAuditArray => {
            const itemUpdated = Object.assign({}, item);
            itemUpdated[2] =
              item[2] === 'ADD' ? 'Primer registro' : 'Modificación';
            this.getUser(item[0].idUserUpdate).then((valor: string): void => {
              item[0].name = valor;
            });
            return itemUpdated;
          },
        );
      });

  public getDistrubutionCenter = (): Promise<[]> =>
    axios
      .get(createCoreServiceUrl('company/distribuciosncenters'))
      .then((response: AxiosResponse<[]>): [] => {
        return response.data;
      });

  public getSettlementbyIdfiltred = (
    id: string,
    all: boolean,
    status: string,
  ): Promise<Settlement> =>
    axios
      .get(
        createCoreServiceUrl(
          `settlement/settlementById/${id}/${all}/${status}`,
        ),
      )
      .then((response: AxiosResponse<Settlement>): Settlement => response.data);

  public getNamesFromUSer = (): Promise<string[]> =>
    axios
      .get(createCoreServiceUrl('user/allNames'))
      .then((response: AxiosResponse<string[]>): string[] => response.data);

  public downloadCut = async (
    id: string,
    cutFOlio: string,
    name: string,
  ): Promise<void> => {
    const response = await axios({
      url: createCoreServiceUrl(`settlement/${id}/ticket`),
      method: 'GET',
      responseType: 'arraybuffer',
    });
    const ticketUrl = window.URL.createObjectURL(new Blob([response.data]));
    const ticketLink = document.createElement('a');
    ticketLink.href = ticketUrl;
    ticketLink.setAttribute('download', cutFOlio + '-' + name + '.pdf');
    document.body.appendChild(ticketLink);
    ticketLink.click();
  };
  public restoreSettlement = async (
    settlementId: string,
  ): Promise<Settlement> =>
    await axios
      .put(createCoreServiceUrl(`settlement/${settlementId}/restoresettlement`))
      .then((response: AxiosResponse<Settlement>): Settlement => response.data);

  public generateReport = async (
    settlementFilters: Record<string, any>, // eslint-disable-line
  ): Promise<void> => {
    const response = await axios({
      url:
        createCoreServiceUrl(`settlement/excelReport`) +
        createParamsService(settlementFilters),
      method: 'GET',
      responseType: 'arraybuffer',
    });

    //Get the current values of date to set them in the file name
    const year = moment().year();
    const month = moment().month() + 1;
    const day = moment().date();

    const excelUrl = window.URL.createObjectURL(new Blob([response.data]));
    const excelLink = document.createElement('a');
    excelLink.href = excelUrl;
    excelLink.setAttribute(
      'download',
      `Reporte_cortes_${year}_${month}_${day}.xlsx`,
    );
    document.body.appendChild(excelLink);
    excelLink.click();
  };
  public setZeroAllSettlementAndSales = async (
    settlementId: string,
  ): Promise<Settlement> =>
    await axios
      .put(
        createCoreServiceUrl(
          `settlement/${settlementId}/setzeroallsettlementandsales`,
        ),
      )
      .then((response: AxiosResponse<Settlement>): Settlement => response.data);

  public getPengingSettlementsReport = async (
    settlementFilters: Record<string, any>, // eslint-disable-line
  ): Promise<void> => {
    const response = await axios({
      url:
        createCoreServiceUrl(`settlement/pendingSettlementsReport`) +
        createParamsService(settlementFilters),
      method: 'GET',
      responseType: 'arraybuffer',
    });

    //Get the current values of date to set them in the file name
    const year = moment().year();
    const month = moment().month() + 1;
    const day = moment().date();

    const excelUrl = window.URL.createObjectURL(new Blob([response.data]));
    const excelLink = document.createElement('a');
    excelLink.href = excelUrl;
    excelLink.setAttribute(
      'download',
      `Cortes_pendientes_${year}_${month}_${day}.xlsx`,
    );
    document.body.appendChild(excelLink);
    excelLink.click();
  };
}

export default SettlementService;
