import { User, Permissions } from 'types/type';
import moment, { Moment } from 'moment';

/**
 * Function to validate which is the most permission a user has.
 * The priority 4 is the lowest critical permission.
 * The priority 1 is the highest critical permission.
 */
export const getMaximumUserPermission = (user: User): number => {
  let priority = 4;
  user.userPermission.forEach((item: Permissions): void => {
    if (item.priority < priority) {
      priority = item.priority;
    }
  });
  return priority;
};

/**Function to calculate the time since the user logged in */
export const getDateStatus = (date: Moment): string => {
  let actualDate = moment();
  //[0]seconds, [1]minutes, [2]hours, [3]days, [4]weeks, [5]months, [6]years
  let time = [0, 0, 0, 0, 0, 0, 0];
  time[0] = actualDate.diff(date, 'seconds');
  time[1] = actualDate.diff(date, 'minutes');
  time[2] = actualDate.diff(date, 'hours');
  time[3] = actualDate.diff(date, 'days');
  time[4] = actualDate.diff(date, 'weeks');
  time[5] = actualDate.diff(date, 'months');
  time[6] = actualDate.diff(date, 'years');

  let message = 'Desconocida';

  //If one of the values are equals to 1, show the message in single way
  if (time[1] === 1) message = `Hace 1 minuto`;
  if (time[2] === 1) message = `Hace 1 hora`;
  if (time[3] === 1) message = `Hace 1 día`;
  if (time[4] === 1) message = `Hace 1 semana`;
  if (time[5] === 1) message = `Hace 1 mes`;
  if (time[6] === 1) message = `Hace 1 año`;

  //Conditions to show the message in plural way
  if (time[0] >= 0 && time[0] < 60) message = `Hace un momento`;
  if (time[1] > 1 && time[1] < 60) message = `Hace ${time[1]} minutos`;
  if (time[2] > 1 && time[2] < 24) message = `Hace ${time[2]} horas`;
  if (time[3] > 1 && time[3] < 7) message = `Hace ${time[3]} días`;
  if (time[4] > 1 && time[4] < 4) message = `Hace ${time[4]} semanas`;
  if (time[5] > 1 && time[5] < 12) message = `Hace ${time[5]} meses`;
  if (time[6] > 1) message = `Hace ${time[6]} años`;

  return message;
};
