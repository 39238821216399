/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Hiram Padilla <hiram@ssf.com.mx>, July 2021
 *
 * This component shows a table with the information of portfolios
 */
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Icon,
  InputNumber,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Table,
  Tooltip,
} from 'antd';
import { initPortfolioFilters } from 'commons/initTypes';
import { DATE_FORMAT } from 'commons/services';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isDateWrong } from 'routes/functions';
import { RootState } from 'store/configureStore';
import {
  InvoiceForPortfolio,
  Portfolio,
  PortfolioFilters,
  PortfoliosPage,
} from 'types/type';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import {
  getPage,
  searchOpenedPortfolio,
  loadPortfolioDetails,
  closePortfolio,
} from './redux/actions';
import { toNumber } from 'commons/numbersFormat';
import JwtDecode from 'jwt-decode';
import { getToken, isAllowed, Token } from 'security';
import { getPortfolioStatusText } from './functions';
import { Link } from 'react-router-dom';
import { Permissions as P } from 'commons/permissions';
import { responsiveListPortfolios } from './components/responsiveList';
import { invoicesInfoColumns } from './components/rowExpandedTable';

const userName: string =
  getToken() !== null ? JwtDecode<Token>(getToken() as string).name : '';

interface StateProps {
  portfoliosPage: PortfoliosPage;
  openedPortfolio: Portfolio;
  portfolioDetails: InvoiceForPortfolio[];
  isFetching: boolean;
}

interface DispatchProps {
  getPage: (filters: PortfolioFilters) => void;
  searchOpenedPortfolio: (endDate: string) => void;
  loadPortfolioDetails: (id: string) => void;
  closePortfolio: (id: string) => void;
}

let filters: PortfolioFilters = initPortfolioFilters;

type Props = StateProps & DispatchProps;
class PortfoliosComponent extends Component<Props> {
  public state = { expandedRowKeys: [''] };

  private loadInfo = async (filters: PortfolioFilters): Promise<void> => {
    await this.props.getPage(filters);
    await this.props.searchOpenedPortfolio(
      filters.endDate
        ? filters.endDate
        : moment().format('DD MM YYYY hh:mm:ss'),
    );
  };
  private closeAction = (record: Portfolio): void => {
    const asyncAction = async (): Promise<void> => {
      await this.props.closePortfolio(record.id);
      await this.props.getPage(filters);
    };
    asyncAction();
  };
  private fetchPageInfo = async (
    filtersApplied: PortfolioFilters,
  ): Promise<void> => {
    await this.props.getPage(filtersApplied);
    await this.props.searchOpenedPortfolio(
      filters.endDate
        ? filters.endDate
        : moment().format('DD MM YYYY hh:mm:ss'),
    );
  };

  private fetchPortfolioDetails = async (id: string): Promise<void> =>
    await this.props.loadPortfolioDetails(id);

  /* ------------- Component mount methods ------------- */
  public componentDidMount(): void {
    this.loadInfo(filters);
  }

  /* ------------- Event handlers methods, starting with 'handle' ------------- */

  /** This function change page and replaces current position in the pagination */
  private handlePaginationChange = async (
    currentPage: number,
    pageSize?: number,
  ): Promise<void> => {
    filters.currentPage = currentPage;
    filters.pageSize = pageSize || 25;
    await this.props.getPage(filters);
  };

  /* -------------Event handlers methods, starting with 'on' -------------*/

  /* ------------- Render methods for render content ------------- */

  /* ------------- Main Render method ------------- */
  public render = (): React.ReactNode => {
    const { isFetching, portfoliosPage, portfolioDetails } = this.props;

    //When user is not portfolio manager, show nothing, else show entire section
    return !isAllowed([P.IS_PORTFOLIO_MANAGER]) ? (
      <div />
    ) : (
      <Row>
        {/** ------------- Header section (includes the manager name & the filters) ------------- */}
        <Row className="content-backgroud">
          <Col xs={24} sm={12} md={8} lg={8} xl={4} xxl={4}>
            <Text>Encargado de cartera</Text>
            <br />
            <h2>{userName}</h2>
          </Col>
          <Col xs={24} sm={12} md={16} lg={16} xl={20} xxl={20}>
            <Col xs={23} sm={23} md={11} lg={15} xl={10} xxl={8} offset={1}>
              <div>
                <Text className="font-color-Labeln">Filtrar por fecha</Text>
              </div>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <DatePicker
                  showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                  disabled={isFetching}
                  disabledDate={(date: moment.Moment | undefined): boolean =>
                    isDateWrong(date, moment(filters.endDate))
                  }
                  format={DATE_FORMAT}
                  placeholder="Desde"
                  onOk={(selectedTime: moment.Moment): void => {
                    filters.initDate = selectedTime.toISOString();
                  }}
                  defaultValue={moment(filters.initDate)}
                  style={{ width: '100%', marginBottom: '10px' }}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <DatePicker
                  showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                  disabled={isFetching}
                  disabledDate={(date: moment.Moment | undefined): boolean =>
                    isDateWrong(moment(filters.initDate), date)
                  }
                  format={DATE_FORMAT}
                  placeholder="Hasta"
                  onOk={(selectedTime: moment.Moment): void => {
                    filters.endDate = selectedTime.toISOString();
                  }}
                  defaultValue={moment(filters.endDate)}
                  style={{ width: '100%', marginBottom: '10px' }}
                />
              </Col>
            </Col>
            <Col xs={23} sm={11} md={7} lg={5} xl={4} xxl={3} offset={1}>
              <Text
                className="font-color-Labeln"
                style={{ paddingRight: '25%' }}
              >
                Folio
              </Text>
              <InputNumber
                placeholder="Folio"
                disabled={isFetching}
                onChange={(value: number | undefined): void => {
                  filters.folio = value;
                }}
                style={{ width: '100%', marginBottom: '10px' }}
                min={0}
                step={1}
                precision={0}
                defaultValue={filters.folio}
              />
            </Col>
            <Col xs={23} sm={11} md={7} lg={5} xl={4} xxl={3} offset={1}>
              <Text className="font-color-Labeln">Estatus</Text>
              <Select
                style={{ width: '100%', paddingBottom: '10px' }}
                onChange={(value: string): void => {
                  filters.status = value;
                }}
                defaultValue={filters.status}
              >
                <Select.Option value="">Todas</Select.Option>
                <Select.Option value="ABIERTA">Abierta</Select.Option>
                <Select.Option value="ASIGNADA">Asignada</Select.Option>
                <Select.Option value="FINALIZADA">Finalizada</Select.Option>
              </Select>
            </Col>
            <Col xs={23} sm={23} md={13} lg={11} xl={4} xxl={5} offset={1}>
              <Button
                type="primary"
                size={'default'}
                onClick={(): void => {
                  this.fetchPageInfo(filters);
                }}
                disabled={isFetching}
                style={{ marginTop: '20px', marginRight: '10px' }}
              >
                <Icon type="search" />
                {'Buscar'}
              </Button>
              <Tooltip
                title={
                  //Show a tooltip when is a open portfolio
                  this.props.openedPortfolio.id === ''
                    ? ''
                    : `Tiene una cartera abierta del día ${moment(
                        this.props.openedPortfolio.createdAt,
                      ).format(DATE_FORMAT)}`
                }
              >
                <Link to={{ pathname: '/generatePortfolio/0' }}>
                  <Button
                    type="primary"
                    size={'default'}
                    disabled={isFetching}
                    style={{ marginTop: '20px' }}
                  >
                    <Icon type="file-add" />
                    {'Generar cartera'}
                  </Button>
                </Link>
              </Tooltip>
            </Col>
          </Col>
        </Row>
        <Row>
          {/** ------------- Body section (includes the table & the responsive list) ------------- */}
          <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
            {responsiveListPortfolios(portfoliosPage.content || [])}
          </Col>
          <Col
            xs={0}
            sm={0}
            md={0}
            lg={24}
            xl={24}
            xxl={24}
            style={{ background: 'white' }}
          >
            <Table
              dataSource={portfoliosPage.content}
              rowKey={(record: Portfolio): string => record.id}
              pagination={false}
              loading={isFetching}
              expandedRowKeys={this.state.expandedRowKeys}
              onExpand={(expand: boolean, record: Portfolio): void => {
                if (expand) {
                  this.fetchPortfolioDetails(record.id);
                  this.setState({ expandedRowKeys: [record.id] });
                } else this.setState({ expandedRowKeys: [''] });
              }}
              expandedRowRender={(): JSX.Element => (
                <Table
                  className={'custom-table'}
                  dataSource={portfolioDetails}
                  pagination={false}
                  rowKey={(record: InvoiceForPortfolio): string =>
                    record.invoiceId
                  }
                  columns={invoicesInfoColumns()}
                  size="small"
                />
              )}
            >
              <Table.Column title="Folio" dataIndex="folio" />
              <Table.Column
                title="Fecha"
                dataIndex="createdAt"
                render={(createdAt: string): React.ReactNode =>
                  moment(createdAt).format(DATE_FORMAT)
                }
              />
              <Table.Column title="Cartera de" dataIndex="portfolioManager" />
              <Table.Column
                title="Clientes a visitar"
                dataIndex="customersToVisit"
              />
              <Table.Column
                title="Km a recorrer aprox."
                dataIndex="kilometers"
                render={(kilometers: number): React.ReactNode => {
                  return toNumber(kilometers) + ' Km';
                }}
              />
              <Table.Column
                title="Total a cobrar"
                dataIndex="totalAmount"
                render={(total: number): React.ReactNode => {
                  return '$ ' + toNumber(total);
                }}
              />
              <Table.Column
                title="Estatus"
                dataIndex="status"
                render={(status: string): React.ReactNode =>
                  getPortfolioStatusText(status)
                }
              />
              <Table.Column
                title="Acciones"
                dataIndex="id"
                render={(id: string, record: Portfolio): React.ReactNode => {
                  let editOption = <div />;
                  if (record.status === 'ABIERTA')
                    editOption = (
                      <Col>
                        <Link
                          key="1"
                          to={{
                            pathname: `/generatePortfolio/${id}`,
                          }}
                        >
                          Editar
                        </Link>
                        <Divider type="vertical" style={{ height: '2.8rem' }} />
                        {isAllowed([P.CLOSE_PORTFOLIO]) && (
                          <Popconfirm
                            title="¿Realmente desea cerrar la ruta?"
                            onConfirm={(): void => this.closeAction(record)}
                            okText="Si"
                            cancelText="No"
                          >
                            <Link key="2" to={{}}>
                              Cerrar
                            </Link>
                          </Popconfirm>
                        )}
                      </Col>
                    );
                  return <div>{editOption}</div>;
                }}
              />
            </Table>
          </Col>
          <Col span={24}>
            <Pagination
              showSizeChanger
              pageSizeOptions={['25', '50', '75', '100']}
              defaultPageSize={25}
              disabled={isFetching}
              current={(portfoliosPage.number || 0) + 1}
              total={portfoliosPage.totalElements}
              onShowSizeChange={this.handlePaginationChange}
              onChange={this.handlePaginationChange}
            />
          </Col>
        </Row>
      </Row>
    );
  };
}

const mapStateToProps = (states: RootState): StateProps => {
  return {
    portfoliosPage: states.portfoliosState.portfoliosPage,
    openedPortfolio: states.portfoliosState.openedPortfolio,
    portfolioDetails: Object.values(states.portfoliosState.portfolioDetails),
    isFetching: states.portfoliosState.isFetching,
  };
};

const mapDispatchToProps: DispatchProps = {
  getPage,
  closePortfolio,
  searchOpenedPortfolio,
  loadPortfolioDetails,
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(PortfoliosComponent);
