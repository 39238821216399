/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { ResponsivePie, PieDatum } from '@nivo/pie';
import { Row, Col, Select } from 'antd';
import BaseWidget from './baseWidget';
import axios, { AxiosResponse } from 'axios';
import { createCoreServiceUrl } from '../../../commons/services';
import { SettlementSummaryModel, BaseWidgetProps } from './types';
import { toNumber } from 'commons/numbersFormat';
import { hasValue } from 'commons/functions';

class PaymentConditionPieChart extends BaseWidget {
  public fetchData(): void {
    const { initialDate, endDate } = this.props;
    const url = `financialInformation/settlements?dateGreaterThan=${initialDate}&dateLessThan=${endDate}`;
    axios
      .get(createCoreServiceUrl(url))
      .then((response: AxiosResponse<SettlementSummaryModel[]>): void => {
        this.setState({ settlements: response.data });
      });
  }

  public componentDidUpdate = (prevProps: BaseWidgetProps): void => {
    const { initialDate, endDate, distributionCenters } = this.props;
    if (
      prevProps.initialDate === initialDate &&
      prevProps.endDate === endDate &&
      prevProps.distributionCenters === distributionCenters
    )
      return;
    this.fetchData();
  };

  public componentDidMount(): void {
    this.fetchData();
  }

  private calculateData(settlements: SettlementSummaryModel[]): PieDatum[] {
    let pieSettlements;
    const distributionCenter = this.state.selectedDistributionC;
    if (distributionCenter === '') {
      pieSettlements = settlements;
    } else {
      pieSettlements = settlements.filter(
        (item: SettlementSummaryModel): boolean => {
          return item.distributionCenterId === distributionCenter;
        },
      );
    }
    let kilogramsValue = 0.56;
    if (hasValue(this.props.kilogramsValue)) {
      kilogramsValue = this.props.kilogramsValue;
    }
    const contadoTotal = {
      id: 'Contado',
      label: 'Contado',
      value: pieSettlements.reduce((acc, s) => {
        switch (this.props.radioSelected) {
          case 'volume':
            return s.totalVolumeCash + acc;
          case 'weight':
            return s.totalVolumeCash * kilogramsValue + acc;
          default:
            return s.amountCash + acc;
        }
      }, 0),
    };
    const creditTotal = {
      id: 'Crédito',
      label: 'Crédito',
      value: pieSettlements.reduce((acc, s) => {
        switch (this.props.radioSelected) {
          case 'volume':
            return s.totalVolumeCredit + acc;
          case 'weight':
            return s.totalVolumeCredit * kilogramsValue + acc;
          default:
            return s.amountCredit + acc;
        }
      }, 0),
    };
    const totalUsed = {
      id: 'Autoconsumos',
      label: 'Autoconsumos',
      value: pieSettlements.reduce((acc, s) => {
        switch (this.props.radioSelected) {
          case 'volume':
            return s.totalVolumeUsed + acc;
          case 'weight':
            return s.totalVolumeUsed * kilogramsValue + acc;
          default:
            return acc;
        }
      }, 0),
    };
    const totalTranser = {
      id: 'Traspasos',
      label: 'Traspasos',
      value: pieSettlements.reduce((acc, s) => {
        switch (this.props.radioSelected) {
          case 'volume':
            return s.totalVolumeTransfer + acc;
          case 'weight':
            return s.totalVolumeTransfer * kilogramsValue + acc;
          default:
            return acc;
        }
      }, 0),
    };
    const totalFailed = {
      id: 'Fallas',
      label: 'Fallas',
      value: pieSettlements.reduce((acc, s) => {
        switch (this.props.radioSelected) {
          case 'volume':
            return s.totalVolumeFailed + acc;
          case 'weight':
            return s.totalVolumeFailed * kilogramsValue + acc;
          default:
            return acc;
        }
      }, 0),
    };
    return [contadoTotal, creditTotal, totalUsed, totalTranser, totalFailed];
  }

  public widgetRender = (): React.ReactNode => {
    const data = this.calculateData(this.state.settlements);

    return (
      <div>
        <p
          style={{
            fontFamily: 'arial',
            fontSize: '20px',
            textAlign: 'center',
            height: 15,
          }}
        >
          Venta Por Condición de Pago
        </p>
        <Row style={{ justifyContent: 'center', display: 'flex' }}>
          <Col span={8}>
            <label className="text-filters">
              <b>{'Centro de distribución:'}</b>
            </label>
            <Select
              defaultValue=""
              style={{ width: '100%' }}
              onChange={(value: string): void => {
                this.setState({ selectedDistributionC: value });
              }}
            >
              <Select.Option value="">Todos</Select.Option>
              {this.props.distributionCenters &&
                this.props.distributionCenters.map(
                  (distCenter, index): JSX.Element => (
                    <Select.Option value={distCenter.id} key={index}>
                      {distCenter.name}
                    </Select.Option>
                  ),
                )}
            </Select>
          </Col>
        </Row>
        <Row style={{ height: 320 }}>
          <ResponsivePie
            data={data}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            colors={{ scheme: 'category10' }}
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
            radialLabelsSkipAngle={10}
            radialLabelsTextXOffset={6}
            radialLabelsTextColor="#333333"
            radialLabelsLinkOffset={0}
            radialLabelsLinkDiagonalLength={16}
            radialLabelsLinkHorizontalLength={24}
            radialLabelsLinkStrokeWidth={1}
            radialLabelsLinkColor={{ from: 'color' }}
            slicesLabelsSkipAngle={10}
            slicesLabelsTextColor="#333333"
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            sliceLabel={d => {
              const { radioSelected } = this.props;
              switch (radioSelected) {
                case 'volume':
                  return `${toNumber(+d.value)} Lts`;
                case 'weight':
                  return `${toNumber(+d.value)} Kgs`;
                default:
                  return `$${toNumber(+d.value)}`;
              }
            }}
            legends={[
              {
                anchor: 'bottom',
                direction: 'row',
                translateY: 56,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: '#999',
                symbolSize: 18,
                symbolShape: 'circle',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemTextColor: '#000',
                    },
                  },
                ],
              },
            ]}
          />
        </Row>
      </div>
    );
  };
}

export default PaymentConditionPieChart;
