/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Hiram Padilla <hiram@ssf.com.mx>, July 2020
 *
 * This file contains the redux actions for equipments component
 */

import {
  EquipmentAction,
  BEGIN_FETCH,
  FETCH_COMPLETED,
  SET_EQUIPMENTS,
  SET_GOVERNMENT_PERMISSIONS,
  SET_DISTRIBUTION_CENTERS,
  SET_EQUIPMENT_FILTERS,
  SET_COMPANY_LIST,
} from './types';
import { RootState } from 'store/configureStore';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { notification } from 'antd';
import { parseApiError } from 'commons/error';
import { getIsFetching } from './reducers';
import EquipmentsService from '../../../services/equipments';
import {
  DistributionCenter,
  Equipment,
  GovernmentPermission,
  EquipmentFilters,
  Pageable,
} from 'types/type';
import CompanyService from '../../../services/company';
import { Popups } from 'commons/components/popups/popups';


const equipmentsService = new EquipmentsService();
const companyService = new CompanyService();
type ITA = ThunkAction<Promise<void>, RootState, {}, EquipmentAction>;
type IDA = ThunkDispatch<{}, {}, EquipmentAction>;

const startFetch = (): EquipmentAction => {
  return { type: BEGIN_FETCH };
};

const endFetch = (): EquipmentAction => {
  return { type: FETCH_COMPLETED };
};

/** Action to get all the equipments data */
export const getAll = (equipmentFilters: EquipmentFilters): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }

  try {
    dispatch(startFetch());
    const response: Pageable = await equipmentsService.getAllEquipments(
      equipmentFilters,
    );
    dispatch({ type: SET_EQUIPMENTS, equipments: response });
    dispatch({
      type: SET_EQUIPMENT_FILTERS,
      equipmentFilters: equipmentFilters,
    });
    dispatch(endFetch());
  } catch (error) {
    dispatch(endFetch());
    notification.error(parseApiError(error));
  }
};

/** Action to get all the distribution centers data */
export const getDistributionCenters = (): ITA => async (
  dispatch: IDA,
): Promise<void> => {
  try {
    const response: DistributionCenter[] = await companyService.getDistrubutionCenter();
    dispatch({ type: SET_DISTRIBUTION_CENTERS, distributionCenters: response });
  } catch (error) {
    notification.error(parseApiError(error));
  }
};

/** Action to get all the governmet permissions data */
export const getGovernmentPermissions = (): ITA => async (
  dispatch: IDA,
): Promise<void> => {
  try {
    const response: GovernmentPermission[] = await equipmentsService.getGovenrmentPermissionsList();
    dispatch({
      type: SET_GOVERNMENT_PERMISSIONS,
      governmentPermissions: response,
    });
  } catch (error) {
    notification.error(parseApiError(error));
  }
};

/** Action to update a government permission from an equipment */
export const save = (equipmemt: Equipment): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }

  try {
    dispatch(startFetch());
    await equipmentsService.updateEquipment(equipmemt);
    dispatch(endFetch());
    Popups.notifySuccess('El equipo ha sido actualizado');
  } catch (error) {
    dispatch(endFetch());
    notification.error(parseApiError(error));
  }
};

export const getAllCompany = (): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    const responsepage = await companyService.getAll();
    dispatch({ type: SET_COMPANY_LIST, companyList: responsepage });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};
