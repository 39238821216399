import { Table, Row, Col } from 'antd';
import React, { Component } from 'react';
import {
  SettlementDepositModal,
  Settlement,
  TotalsSettlement,
} from 'types/type';
import { toNumber } from 'commons/numbersFormat';
import moment from 'moment';
import './../stylesBankDeposit.css';
import { CheckboxProps } from 'antd/lib/checkbox';
import { DATE_FORMAT } from 'commons/services';

interface CounterProps {
  settlementSearchList: SettlementDepositModal;
  isFetching: boolean;
  getTotalCash: TotalsSettlement;
  validateCheck(settlement: Settlement): boolean;
  validatorChecked(settlement: Settlement): boolean;
  selected(settlement: Settlement, selected: boolean): void;
}

class DepositTableToApply extends Component<CounterProps> {
  public state = {
    imageUrl: undefined,
    loading: false,
    tempFile: false,
  };

  public footerTable = (): JSX.Element => {
    const totals = this.props.getTotalCash;
    return (
      <Row>
        <Col span={18} className="blue-text">
          {'TOTAL'}
        </Col>
        <Col span={6} className="blue-text" style={{ textAlign: 'right' }}>
          {'$ ' + toNumber(totals.totalCashCP)}
        </Col>
      </Row>
    );
  };

  public render = (): JSX.Element => {
    return (
      <Row>
        <span>
          <Table
            footer={(): JSX.Element => this.footerTable()}
            rowSelection={{
              onSelect: this.props.selected,
              getCheckboxProps: (record: Settlement): CheckboxProps => {
                return {
                  checked: this.props.validatorChecked(record),
                  disabled: this.props.validateCheck(record),
                };
              },
            }}
            className={'table-deposit-settlements ant-table-thead '}
            loading={this.props.isFetching}
            scroll={{ x: 1100, y: 440 }}
            pagination={false}
            dataSource={
              this.props.settlementSearchList.settlement
                ? this.props.settlementSearchList.settlement
                : []
            }
          >
            <Table.Column
              fixed="left"
              width={150}
              title="Fecha y hora"
              dataIndex="movementDate"
              key="movementDate"
              render={(text: string, record: Settlement): React.ReactNode => {
                return moment(record.movementDate).format(DATE_FORMAT);
              }}
              align="center"
            />
            <Table.Column
              title="Folio"
              dataIndex="folio"
              key="folio"
              width={65}
              align="center"
            />
            <Table.Column
              title="Tipo"
              dataIndex="equipment.arrangeType"
              key="equipment.arrangeType"
              width={65}
              align="center"
            />
            <Table.Column
              title="Tipo"
              width={150}
              dataIndex="serviceType"
              key="serviceType"
              align="center"
            />
            <Table.Column
              title="Unidad"
              width={100}
              dataIndex="unit"
              key="unit"
              align="center"
            />
            <Table.Column
              title="Efectivo"
              width={100}
              dataIndex="postAmountCash"
              key="postAmountCash"
              align="center"
            />
            <Table.Column
              title="Tarjeta crédito"
              width={140}
              dataIndex="totalCredit"
              key="totalCredit"
              align="center"
            />
            <Table.Column
              title="Tarjeta débito"
              width={140}
              dataIndex="totalDebit"
              key="totalDebit"
              align="center"
            />
            <Table.Column
              title="Cheque"
              width={100}
              dataIndex="totalCheque"
              key="totalCheque"
              align="center"
            />
            <Table.Column
              title="Transferencia"
              dataIndex="totalTransfer"
              key="totalTransfer"
              align="center"
            />
          </Table>
        </span>
      </Row>
    );
  };
}
export default DepositTableToApply;
