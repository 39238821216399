import JwtDecode from "jwt-decode";
import { getToken, Token } from "security";

import { DbOldTypes, DbOldValues } from "database";
import { AppTypes } from "commons/globals";
import { Sale as FrontendSale } from "types/type";

export enum ApiRoute {
    GetBucketFile = 'getBucketFile',

    VerifyAllowedOperations = 'verifyAllowedOperations',

    EditSale = 'editSale',
    GetSaleEditSnapshots = 'getSaleEditSnapshots',

    AddExternalInvoice = 'addExternalInvoice',
    ClearCfdiFolioRetryOfSale = 'clearCfdiFolioRetryOfSale',

    AddDepositDifferences = 'addDepositDifferences',
    GetDepositDifferences = 'getDepositDifferences',
    EditDepositDifferenceStatus = 'editDepositDifferenceStatus',

    AddDepositDifferenceComment = 'addDepositDifferenceComment',
    EditDepositDifferenceComment = 'editDepositDifferenceComment',
    DeleteDepositDifferenceComment = 'deleteDepositDifferenceComment',
    GetDepositDifferenceComments = 'getDepositDifferenceComments',

    GetUserAuditLevel = 'getUserAuditLevel',
    GetAuditConfig = 'getAuditConfig',
    SetAuditConfig = 'setAuditConfig',
    GetAuditBillingInfo = 'getAuditBillingInfo',
    GroupForAuditBilling = 'groupForAuditBilling',
    SetAuditBillingStampInfo = 'setAuditBillingStampInfo',
    GetDailyAuditStatuss = 'getDailyAuditStatuss',
    EditDailyAuditStatusReviewTransition = 'editDailyAuditStatusReviewTransition',
    GetDasCutsAndSales = 'getDasCutsAndSales',
    GetDasSaleOriginalFolios = 'getDasSaleOriginalFolios',

    GetDasSaleCsv = 'getDasSaleCsv',
    GetDasCutCsv = 'getDasCutCsv',

    GetCers = 'getCers',
    SetCers = 'setCers',
    GetVirtualCylinderSales = 'getVirtualCylinderSales',
    AddVirtualCylinderSale = 'addVirtualCylinderSale',
    DeleteVirtualCylinderSale = 'deleteVirtualCylinderSale',

    GetDeiReport = 'getDeiReport',
    GetDailyEquipmentInventorys = 'getDailyEquipmentInventorys',
    EditDailyEquipmentInventory = 'editDailyEquipmentInventory',
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace ApiTypes {
    export type ParamFile = { id: string, file: File };

    export type EditSaleData = Pick<NonNullable<DbOldTypes.Sale>, 'id' | 'customer_code' | 'payment_condition_type' | 'payment_way' | 'unit_price'> & { rfc: string };

    export type NewDepositDifference = { bankDepositId: string, amount: number, paymentWay: number, otherPaymentWay: string, fileId: string };
    export type NewDepositDifferenceComment = { content: string, fileId?: string };
    export type ExtendedDepositDifference = { createdByUserFullName: string } & DbOldTypes.DepositDifference;
    export type ExtendedDepositDifferenceComment = { createdByUserFullName: string } & DbOldTypes.DepositDifferenceComment;
    export type ExtendedDailyEquipmentInventory = { equipmentName: string, equipmentNumber: number };// & DbOldTypes.DailyEquipmentInventory;
    export type DailyEquipmentInventoryCarga = { time: string, volumeLPercentageBefore: number, volumeLPercentageAfter: number };
    export type DasCut = Pick<DbOldTypes.Settlement, 'id' | 'folio' | 'audit_status_key' | 'audit_snapshot' | 'audit_role_type_key'>;
    export type DasSale = Pick<DbOldTypes.Sale, 'id' | 'sales_folio' | 'cut_id' | 'audit_status_key' | 'audit_snapshot' | 'audit_role'>;
    export type PaymentConditionFilter = 'CASH' | 'CREDIT' | 'TRASLATE' | 'RETURN' | 'PREPAID' | 'SELF_CONSUMPTION';
    export type CylinderVirtualSale = Pick<DbOldTypes.Sale, 'id' | 'end_date' | 'sales_folio' | 'total' | 'total_amount_c' | 'total_volume' | 'total_volume_c' | 'dist_center_id' | 'cylinder_snapshot'> & { cerId: string, cerTag: string, cutFolio: string | null };

    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace AuditBilling {
        export type Cut = AppTypes.NonNullablePick<DbOldTypes.Settlement, 'id' | 'folio' | 'equipment_idg4s' | 'unit' | 'equipment_number' | 'daily_audit_status_id'> & Pick<DbOldTypes.Settlement, 'audit_invoice_record_id'> & Pick<DbOldTypes.Equipment, 'type'> & { sales: Sale[] };
        export type Sale = AppTypes.NonNullablePick<DbOldTypes.Sale, 'id' | 'cut_id' | 'total_amount_c' | 'total_volume_c'> & Pick<DbOldTypes.Sale, 'audit_invoice_record_id'>;
        export type Customer = AppTypes.NonNullablePick<DbOldTypes.Customer, 'id' | 'code' | 'rfc' | 'business_name' | 'comercial_name'>;
        //export type Equipment = Pick<DbOldTypes.Equipment, 'id_g4s' | 'distribution_center_id' | 'tag' | 'number'>;

        export type InfoResponse = { cpCuts: Cut[], spCuts: Cut[], customers: Customer[], stampPendingFrontendSales: FrontendSale[] };
    }

    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace CylinderService {
        export type CerSale = {
            cerId: string,
            date: string,
            customerCode: string,
            discount: number,
            paymentCondition: DbOldValues._SalePaymentCondition,
            paymentWay: DbOldValues._SalePaymentWay,
            units: { kg: number, price: number, quantity: number }[],
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace StatementService {
        export type ExternalInvoiceData = { [K in 'rfc' | 'serie' | 'folio' | 'timbreUuid']: string } & { stampDate: Date, govPerms: string[] };
    }
}

type ApiResponse = { success: boolean, msg: string };
type ApiFailedResponse = { success: false, msg: string };

type ApiRouteParam<AR extends ApiRoute> = (
    AR extends ApiRoute.GetBucketFile ? { bucketPath: string } :

    AR extends ApiRoute.VerifyAllowedOperations ? { operation: 'registerDeposit' | 'login' } :

    AR extends ApiRoute.EditSale ? ApiTypes.EditSaleData :
    AR extends ApiRoute.GetSaleEditSnapshots ?  { saleId: DbOldTypes.Sale['id'] } :

    AR extends ApiRoute.AddExternalInvoice ?  { statementId: string, data: ApiTypes.StatementService.ExternalInvoiceData } :
    AR extends ApiRoute.ClearCfdiFolioRetryOfSale ? { statementOrSaleId: string } :

    AR extends ApiRoute.AddDepositDifferences ? { depositDifferences: ApiTypes.NewDepositDifference[] } :
    AR extends ApiRoute.GetDepositDifferences ? { filters?: { statusKeys?: DbOldValues._DepositDifferenceStatus[], bankDepositId?: string, distCenterId?: DbOldValues._DistCenterId } } :
    AR extends ApiRoute.EditDepositDifferenceStatus ? { depositDifferenceId: string, statusKey: DbOldValues._DepositDifferenceStatus } :

    AR extends ApiRoute.AddDepositDifferenceComment ? { depositDifferenceId: string } & ApiTypes.NewDepositDifferenceComment :
    AR extends ApiRoute.EditDepositDifferenceComment ? { depositDifferenceCommentId: string, keepFile: boolean } & ApiTypes.NewDepositDifferenceComment :
    AR extends ApiRoute.DeleteDepositDifferenceComment ? { depositDifferenceCommentId: string } :
    AR extends ApiRoute.GetDepositDifferenceComments ? { filters?: { depositDifferenceId?: string, includeDeleted?: boolean } } :

    AR extends ApiRoute.GetUserAuditLevel ? {  } :
    AR extends ApiRoute.GetAuditConfig ? { idDatetimeMs?: string } :
    AR extends ApiRoute.SetAuditConfig ? { users: DbOldTypes.AuditConfig['users'], review: DbOldTypes.AuditConfig['review'] } :
    AR extends ApiRoute.GetAuditBillingInfo ? { mode: 'sp' | 'cp' } :
    AR extends ApiRoute.GroupForAuditBilling ? { mode: 'sp' | 'cp', saleIds: string[], customerCode: string, cutId: string, paymentWay: DbOldValues._SalePaymentWay } :
    AR extends ApiRoute.SetAuditBillingStampInfo ? { saleId: string/* , invoiceId: string */ } :
    AR extends ApiRoute.GetDailyAuditStatuss ? { filters?: { date?: string } } :
    AR extends ApiRoute.EditDailyAuditStatusReviewTransition ? { id: DbOldTypes.DailyAuditStatus['id'], statusKey: DbOldValues._DasReviewTransitionStatus, transition: DbOldTypes.DailyAuditStatusReviewTransition } :
    AR extends ApiRoute.GetDasCutsAndSales ? { dasId: DbOldTypes.DailyAuditStatus['id'] } :
    AR extends ApiRoute.GetDasSaleOriginalFolios ? { dasId: DbOldTypes.DailyAuditStatus['id'] } | { dasDateRange: [ string, string ] } :

    AR extends ApiRoute.GetDasSaleCsv ? ( { dasId: DbOldTypes.DailyAuditStatus['id'] } | { dateRange: [ string, string ] } ) & { distCenterId?: DbOldValues._DistCenterId, customerCode?: string, paymentCondition?: ApiTypes.PaymentConditionFilter } :
    AR extends ApiRoute.GetDasCutCsv ? { mode: 'FULL' | 'FINAL_ONLY_AMOUNT' } & ( { dasId: DbOldTypes.DailyAuditStatus['id'] } | { dateRange: [ string, string ], distCenterId?: DbOldValues._DistCenterId } ) :

    AR extends ApiRoute.GetCers ? {} :
    AR extends ApiRoute.SetCers ? { cers: Pick<DbOldTypes.CylinderEquipmentRoute, 'id' | 'tag' | 'distribution_center_id' | 'government_permission_id'>[] } :
    AR extends ApiRoute.GetVirtualCylinderSales ? { dateRange: [ string, string ], distCenterId?: DbOldValues._DistCenterId, cerId?: string } :
    AR extends ApiRoute.AddVirtualCylinderSale ? { cerSale: ApiTypes.CylinderService.CerSale } :
    AR extends ApiRoute.DeleteVirtualCylinderSale ? { saleId: DbOldTypes.Sale['id'] } :

    AR extends ApiRoute.GetDeiReport ? { dateRange: [ string, string ], distCenterId?: DbOldValues._DistCenterId } :
    AR extends ApiRoute.GetDailyEquipmentInventorys ? { filters?: { equipmentIdG4s?: string, date?: string } } :
    AR extends ApiRoute.EditDailyEquipmentInventory ? { date: string, equipmentIdG4s: string, volumeLPercentageInitial: number, volumeLPercentageFinal: number, cargas: ApiTypes.DailyEquipmentInventoryCarga[] } :

    never
);
type ApiRouteResponse<AR extends ApiRoute> = ApiFailedResponse | ApiResponse & (
    AR extends ApiRoute.GetBucketFile ? null :

    AR extends ApiRoute.VerifyAllowedOperations ? {} :

    AR extends ApiRoute.EditSale ? {} :
    AR extends ApiRoute.GetSaleEditSnapshots ? { saleEditSnapshots: DbOldTypes.Sale['edit_snapshots'] } :

    AR extends ApiRoute.AddExternalInvoice ?  {} :
    AR extends ApiRoute.ClearCfdiFolioRetryOfSale ?  {} :
    
    AR extends ApiRoute.AddDepositDifferences ? {} :
    AR extends ApiRoute.GetDepositDifferences ? { depositDifferences: ApiTypes.ExtendedDepositDifference[] } :
    AR extends ApiRoute.EditDepositDifferenceStatus ? {} :
    AR extends ApiRoute.EditDepositDifferenceStatus ? {} :

    AR extends ApiRoute.AddDepositDifferenceComment ? {} :
    AR extends ApiRoute.EditDepositDifferenceComment ? {} :
    AR extends ApiRoute.DeleteDepositDifferenceComment ? {} :
    AR extends ApiRoute.GetDepositDifferenceComments ? { depositDifferenceComments: ApiTypes.ExtendedDepositDifferenceComment[] } :

    AR extends ApiRoute.GetUserAuditLevel ? { levelKey: DbOldValues._UserAuditLevel, allowedAuditInvoice: boolean } :
    AR extends ApiRoute.GetAuditConfig ? { auditConfig: DbOldTypes.AuditConfig } :
    AR extends ApiRoute.SetAuditConfig ? {  } :
    AR extends ApiRoute.GetAuditBillingInfo ? ApiTypes.AuditBilling.InfoResponse :
    AR extends ApiRoute.GroupForAuditBilling ? { frontendSale: FrontendSale } :
    AR extends ApiRoute.SetAuditBillingStampInfo ? {} :
    AR extends ApiRoute.GetDailyAuditStatuss ? { dailyAuditStatuss: DbOldTypes.DailyAuditStatus[], airAmountByDas: { [das in string]?: number } } :
    AR extends ApiRoute.EditDailyAuditStatusReviewTransition ? {} :
    AR extends ApiRoute.GetDasCutsAndSales ? { cuts: ApiTypes.DasCut[], sales: ApiTypes.DasSale[] } :
    AR extends ApiRoute.GetDasSaleOriginalFolios ? { originalFolioBySaleId: { [id in string]?: DbOldTypes.Sale['sales_folio'] } } :
    
    AR extends ApiRoute.GetDasSaleCsv ? null :
    AR extends ApiRoute.GetDasCutCsv ? null :

    AR extends ApiRoute.GetCers ? { cers: DbOldTypes.CylinderEquipmentRoute[], govPerms: DbOldTypes.GovernmentPermissions[] } :
    AR extends ApiRoute.SetCers ? {} :
    AR extends ApiRoute.GetVirtualCylinderSales ? { sales: ApiTypes.CylinderVirtualSale[], cers: DbOldTypes.CylinderEquipmentRoute[] } :
    AR extends ApiRoute.AddVirtualCylinderSale ? {} :
    AR extends ApiRoute.DeleteVirtualCylinderSale ? {} :

    AR extends ApiRoute.GetDeiReport ? null :
    AR extends ApiRoute.GetDailyEquipmentInventorys ? { dailyEquipmentInventorys: ApiTypes.ExtendedDailyEquipmentInventory[] } :
    AR extends ApiRoute.EditDailyEquipmentInventory ? {} :

    never
);

const DEV_MODE: boolean = false;
const API_HOST = DEV_MODE ? `http://localhost:3701` : `https://erp.centraldegas.info`;

export async function callApi<AR extends ApiRoute>(route: AR, param: ApiRouteParam<AR>): Promise<ApiRouteResponse<AR> | null> {
    let fullUrl = `${API_HOST}/erp/${route}`;
    let headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    let body = `json=${encodeURIComponent(JSON.stringify(param))}&token=${getToken()}`;
    console.log(`Calling api.\nFull url: '${fullUrl}'.`);
    try{
        let res = await fetch(fullUrl, { headers, method: 'POST', body });
        let apiRouteResponse: ApiRouteResponse<AR> = await res.json();
        return apiRouteResponse;
    }catch(error){
        console.log(`Error calling api.\nBody: '${body}'.`);
        console.log(error);
        return null;
    }
}
export async function callApiWithFiles<AR extends ApiRoute>(route: AR, param: ApiRouteParam<AR>, files: ApiTypes.ParamFile[]): Promise<ApiRouteResponse<AR> | null> {
    let fullUrl = `${API_HOST}/erp/${route}`;
    let headers = { token: getToken() || '' };
    let body = new FormData();
    body.append('json', JSON.stringify(param));
    body.append('token', headers.token);
    for(let file of files) body.append(files.length > 1 ? 'files' : 'file', file.file, file.id);
    console.log(`Calling api with ${files.length} files.\nFull url: '${fullUrl}'.`);
    try{
        let res = await fetch(fullUrl, { headers, method: 'POST', body });
        let apiRouteResponse: ApiRouteResponse<AR> = await res.json();
        return apiRouteResponse;
    }catch(error){
        console.log(`Error calling api with files.\nBody: '${body}'.`);
        console.log(error);
        return null;
    }
}

type ApiRouteGetFile = ApiRoute.GetBucketFile | ApiRoute.GetDasSaleCsv | ApiRoute.GetDasCutCsv | ApiRoute.GetDeiReport
export async function callApiGetFile<AR extends ApiRouteGetFile>(route: AR, param: ApiRouteParam<AR>): Promise<null | Blob>{
    let fullUrl = `${API_HOST}/erp/${route}`;
    let headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    let body = `json=${encodeURIComponent(JSON.stringify(param))}&token=${getToken()}`;
    console.log(`Calling api to get file.\nFull url: '${fullUrl}'.`);
    try{
        let res = await fetch(fullUrl, { headers, method: 'POST', body });
        let blob = await res.blob();
        return blob;
    }catch(error){
        console.log(`Error calling api to get file.\nBody: '${body}'.`);
        console.log(error);
        return null;
    }
}

export function getCurrentTokenInfo(){
    let token = getToken();
    return token ? JwtDecode<Token>(token) : null;
}


type ApiVarName = 'bankDepositId' | 'depositDifferences';
type ApiVarValue<AVN extends ApiVarName> = (
    AVN extends 'bankDepositId' ? string :
    AVN extends 'depositDifferences' ? DbOldTypes.DepositDifference[] :
    never
);
export class ApiVarStorage {

    private static storage: { [K in ApiVarName]: ApiVarValue<K> | null } = {
        bankDepositId: null,
        depositDifferences: null,
    };

    static set<AVN extends ApiVarName>(varName: AVN, value: ApiVarValue<AVN>){
        console.log(`Saved '${varName}': '${JSON.stringify(value)}'.`);
        this.storage[varName] = value as unknown as null;
    }
    static get<AVN extends ApiVarName>(varName: AVN): ApiVarValue<AVN> | null {
        let value = this.storage[varName] as ApiVarValue<AVN>;
        console.log(`Got '${varName}': '${JSON.stringify(value)}'.`);
        return value;
    }
    static consume<AVN extends ApiVarName>(varName: AVN): ApiVarValue<AVN> | null {
        let value = this.storage[varName] as ApiVarValue<AVN>;
        console.log(`Consumed '${varName}': '${JSON.stringify(value)}'.`);
        this.storage[varName] = null;
        return value;
    }

}