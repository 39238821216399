/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Hiram Padilla <hiram@ssf.com.mx>, July 2021
 *
 */

import { initPortfolio } from 'commons/initTypes';
import { combineReducers } from 'redux';
import { Reducer } from 'redux';
import { RootState } from 'store/configureStore';
import {
  CreNoteInvoice,
  InvoiceForPortfolio,
  Portfolio,
  PortfoliosFormPage,
  PortfoliosPage,
} from 'types/type';
import {
  BEGIN_FETCH,
  FETCH_COMPLETED,
  PortfolioAction,
  RESET_CRE_NOTES_LIST,
  RESET_INVOICES_PAGE,
  RESET_INVOICES_TO_EDIT,
  RESET_PORTFOLIOS_LIST,
  RESET_PORTFOLIOS_PAGE,
  SET_CRE_NOTES_LIST,
  SET_INVOICES_PAGE,
  SET_INVOICES_TO_EDIT,
  SET_OPENED_PORTFOLIO,
  RESET_OPENED_PORTFOLIO,
  SET_PORTFOLIOS_LIST,
  SET_PORTFOLIOS_PAGE,
  SET_SELECTED_INVOICES,
  SET_PORTFOLIO_DETAILS,
  RESET_PORTFOLIO_DETAILS,
} from './types';

export interface PortfoliosState {
  readonly portfoliosPage: PortfoliosPage;
  readonly invoicesPage: PortfoliosFormPage;
  readonly creNotes: CreNoteInvoice[];
  readonly portfoliosList: Portfolio[];
  readonly invoicesToEdit: InvoiceForPortfolio[];
  readonly openedPortfolio: Portfolio;
  readonly portfolioDetails: InvoiceForPortfolio[];
  readonly isFetching: boolean;
}

const portfoliosList: Reducer<Portfolio[]> = (
  state: Portfolio[] = [],
  action: PortfolioAction,
): Portfolio[] => {
  switch (action.type) {
    case SET_PORTFOLIOS_LIST:
      console.log(action.portfolioList);
      return action.portfolioList;
    case RESET_PORTFOLIOS_LIST:
      return [];
    default:
      return state;
  }
};

const portfoliosPage: Reducer<PortfoliosPage> = (
  state: PortfoliosPage = { content: [] },
  action: PortfolioAction,
): PortfoliosPage => {
  switch (action.type) {
    case SET_PORTFOLIOS_PAGE:
      return { ...state, ...action.portfoliosPage };
    case RESET_PORTFOLIOS_PAGE:
      return { content: [], number: 0, totalElements: 0 };
    default:
      return state;
  }
};

const invoicesPage: Reducer<PortfoliosFormPage> = (
  state: PortfoliosFormPage = { content: [] },
  action: PortfolioAction,
): PortfoliosFormPage => {
  switch (action.type) {
    case SET_INVOICES_PAGE:
      return { ...state, ...action.invoicesPage };
    case SET_SELECTED_INVOICES:
      state.content = action.selectedInvoices.concat(state.content || []);
      return state;
    case RESET_INVOICES_PAGE:
      return { content: [], number: 0, totalElements: 0 };
    default:
      return state;
  }
};

const creNotes: Reducer<CreNoteInvoice[]> = (
  state: CreNoteInvoice[] = [],
  action: PortfolioAction,
): CreNoteInvoice[] => {
  switch (action.type) {
    case SET_CRE_NOTES_LIST:
      return { ...state, ...action.creNotes };
    case RESET_CRE_NOTES_LIST:
      return [];
    default:
      return state;
  }
};

const invoicesToEdit: Reducer<InvoiceForPortfolio[]> = (
  state: InvoiceForPortfolio[] = [],
  action: PortfolioAction,
): InvoiceForPortfolio[] => {
  switch (action.type) {
    case SET_INVOICES_TO_EDIT:
      return { ...state, ...action.invoicesToEdit };
    case RESET_INVOICES_TO_EDIT:
      return [];
    default:
      return state;
  }
};

const openedPortfolio: Reducer<Portfolio> = (
  state: Portfolio = initPortfolio,
  action: PortfolioAction,
): Portfolio => {
  switch (action.type) {
    case SET_OPENED_PORTFOLIO:
      return { ...state, ...action.openedPortfolio };
    case RESET_OPENED_PORTFOLIO:
      return initPortfolio;
    default:
      return state;
  }
};

const portfolioDetails: Reducer<InvoiceForPortfolio[]> = (
  state: InvoiceForPortfolio[] = [],
  action: PortfolioAction,
): InvoiceForPortfolio[] => {
  switch (action.type) {
    case SET_PORTFOLIO_DETAILS:
      return { ...state, ...action.invoicesInfo };
    case RESET_PORTFOLIO_DETAILS:
      return [];
    default:
      return state;
  }
};

const isFetching: Reducer<boolean> = (
  state: boolean = false,
  action: PortfolioAction,
): boolean => {
  switch (action.type) {
    case BEGIN_FETCH:
      return true;
    case FETCH_COMPLETED:
      return false;
    default:
      return state;
  }
};

export const isPortfoliosFetching = (store: RootState): boolean =>
  store.portfoliosState.isFetching;

export default combineReducers<PortfoliosState>({
  portfoliosPage,
  invoicesPage,
  creNotes,
  portfoliosList,
  invoicesToEdit,
  openedPortfolio,
  portfolioDetails,
  isFetching,
});
