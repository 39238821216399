/* eslint-disable */
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import configureStore from './store/configureStore';
import './App.css';
import Routes from './routes';
import { IDLE_TIMEOUT_IN_MINUTES } from './boot/config';
import { getCustomerCode } from './security';

const notAuthorizedRoutes = ['login', 'factura', 'clientes'];

const isNotAnAuthorizedRoute = () =>
  notAuthorizedRoutes.some(r => window.location.pathname.indexOf(r) >= 0);

class App extends Component {
  private idleTimer: any;
  private onIdle: (e: any) => void;

  constructor(props: any) {
    super(props);
    this.idleTimer = null;
    this.onIdle = this._onIdle.bind(this);
  }

  public render(): JSX.Element {
    return (
      <div>
        {<IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          element={document}
          onIdle={this.onIdle}
          debounce={250}
          timeout={1000 * 60 * IDLE_TIMEOUT_IN_MINUTES}
        />}
        <Provider store={configureStore as any}>
          <Routes />
        </Provider>
      </div>
    );
  }

  _onIdle(e: any): void {
    console.log('user is idle', e);
    console.log('last active', this.idleTimer.getLastActiveTime());

    if (isNotAnAuthorizedRoute()) {
      console.log('Is not an authorized Route');
      return;
    }

    if (getCustomerCode()) {
      localStorage.removeItem('token');
      localStorage.removeItem('refresh-token');
      window.location.href = '/clientes';
      return;
    }
    
    localStorage.removeItem('token');
    localStorage.removeItem('refresh-token');
    window.location.href = '/login';
  }
}

export default App;
