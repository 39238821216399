/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by David Canul <david@ssf.com.mx>, Jun 2019
 *
 * This component lists all the sales crawled from g4s
 */
import React, { Component, useCallback, useEffect, useState } from 'react';
import JwtDecode from 'jwt-decode';
import {
  Row,
  List,
  Pagination,
  Col,
  Icon,
  DatePicker,
  Table,
  Button,
  Badge,
  Select,
  Checkbox,
  Input,
  Popover,
  Tooltip,
  Popconfirm,
  Spin,
  Modal,
  InputNumber,
} from 'antd';
import {
  Sale,
  SaleFilters,
  DistributionCenter,
  Equipment,
  OptionalFilters,
  TotalsSaleIssued,
} from 'types/type';
import { connect } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { SaleList } from './redux/reducers';
import {
  getEquipmentsByDistributionCenter,
  getDistributionCenterAction,
  getSales,
  downloadFile,
  downloadTicket,
  setSaleFilters,
  downloadReport,
  downloadXmlZip,
  updateInvoice,
  cancelSaleInvoice,
  downloadCancellationsReport,
} from './redux/actions';
import moment, { Moment } from 'moment';
import Text from 'antd/lib/typography/Text';
import { Permissions as P } from 'commons/permissions';
import {
  isAllowed,
  Token,
  getToken,
  getIncognitMode,
  getCustomerCode,
} from 'security/index';
import { Redirect } from 'react-router';
import { toNumber } from 'commons/numbersFormat';
import { Permissions as permissions } from '../../commons/permissions';
import { initSaleFilters, initSale } from 'commons/initTypes';
import {
  constructMoment,
  DATE_FORMAT,
  getEndTime,
  getInitialTime,
} from '../../commons/services';
import './style.css';
import { isDateWrong } from 'routes/functions';
import SaleDetailModal from './components/saleDetailModal';
import { paymentWayEs } from 'commons/components/PaymentEs';
import getIndicators from './components/indicators';
import { ApiRoute, ApiTypes, callApi, callApiGetFile } from 'commons/services/api';
import { Globals } from 'commons/globals';
import { DbOldDetails, DbOldTypes, DbOldValues } from 'database';

interface DispatchProps {
  getSales: (saleFilters: SaleFilters) => void;
  downloadFile: (id: string, name: string, saleFolio: string) => void;
  downloadTicket: (id: string, saleFolio: string, name: string) => void;
  getDistributionCenterAction: () => void;
  getEquipmentsByDistributionCenter: (distributionCenterId: string) => void;
  setSaleFilters: (saleFilters: SaleFilters) => void;
  downloadReport: (saleFilters: SaleFilters) => void;
  downloadXmlZip: (saleFilters: SaleFilters) => void;
  updateInvoice: (
    saleFilters: SaleFilters,
    saleId: string,
    invoiceRequired: boolean,
  ) => void;
  cancelSaleInvoice: (saleId: string) => void;
  downloadCancellationsReport: (saleFilters: SaleFilters) => void;
}
interface StateProps {
  salePage: SaleList;
  saleFilters: SaleFilters;
  isFetching: boolean;
  distributionCenter: DistributionCenter[];
  equipments: Equipment[];
  totalsSaleIssued: TotalsSaleIssued;
}
interface State {
  pagePosition: number;
  invoiceStatus: string;
  unitType: string;
  startValueDate: Moment;
  endValueDate: Moment;
  endOpen: boolean;
  all: boolean;
  showDetails: boolean;
  saleDetail: Sale;
  stateFilters: SaleFilters;

  originalFolioBySaleId: { [id in string]?: string };
}

type Props = State & StateProps & DispatchProps;

let optionalFilters: (OptionalFilters | undefined)[] = [
  isAllowed([P.CAN_SEE_FOLIOS])
    ? { label: 'Folio', value: 'folio', disabled: false, checked: false }
    : undefined,
  { label: 'Equipo', value: 'equipment', disabled: false, checked: false },
  {
    label: 'Condición de pago',
    value: 'paymentCondition',
    disabled: false,
    checked: false,
  },
  {
    label: 'Método de pago',
    value: 'paymentWay',
    disabled: false,
    checked: false,
  },
  { label: 'Monto', value: 'amount', disabled: false, checked: false },
  {
    label: 'Estado de facturación',
    value: 'invoiceState',
    disabled: false,
    checked: false,
  },
  {
    label: 'Folio de corte',
    value: 'settlementFolio',
    disabled: false,
    checked: false,
  },
  {
    label: 'Requiere factura',
    value: 'invoiceRequired',
    disabled: false,
    checked: false,
  },
  {
    label: 'Serie/folio factura',
    value: 'invoiceSerieFolio',
    disabled: false,
    checked: false,
  },
];
class SalesListComponent extends Component<Props> {
  public state: State = {
    pagePosition: 1,
    invoiceStatus: 'ALL',
    unitType: 'L',
    startValueDate: moment(),
    endValueDate: moment(),
    endOpen: false,
    all: false,
    showDetails: false,
    saleDetail: initSale,
    stateFilters: initSaleFilters,

    originalFolioBySaleId: {},
  };

  //Get distribution centers from token to set them to filters when user has no permission
  private tokenDistrCenter = JwtDecode<Token>(getToken() as string)
    .distributionCenterId;
  private tokenAssignedDistrCenters: string[] = JwtDecode<Token>(
    getToken() as string,
  ).assingedDistrCenters;

  private fetchData = async (): Promise<void> => {
    await this.props.getDistributionCenterAction();
    const disabled: [boolean, boolean] =
      getIncognitMode() === '1' ? [true, false] : [false, false];

    //variable to initialize the sales showed by the customerCode
    let temporalSaleFilter = this.props.saleFilters;
    if (getCustomerCode()) {
      temporalSaleFilter = Object.assign({}, this.props.saleFilters, {
        customerCode: getCustomerCode(),
      });
    }

    temporalSaleFilter = temporalSaleFilter = Object.assign(
      {},
      temporalSaleFilter,
      {
        disabled,
      },
    );

    this.onGetDIstCenters(temporalSaleFilter);
    await this.props.getSales(temporalSaleFilter);
    await this.props.setSaleFilters(temporalSaleFilter);

    this.setState({
      moreFiltersVisible: this.props.saleFilters.moreFiltersVisible,
      startValueDate: getInitialTime(),
      endValueDate: getEndTime(),
      all: getIncognitMode() === '1',
      stateFilters: temporalSaleFilter,
    });
  };

  /**
   * This function execute when the component is mounting and get the first page from
   * sales
   */
  public componentDidMount(): void {
    this.fetchData();
  }

  private isFilterSelected = (
    checkFilterValue: string,
    checkFiltersValues: OptionalFilters[],
  ): boolean => {
    const value = !!checkFiltersValues.find(
      (filter: OptionalFilters): boolean => checkFilterValue === filter.value,
    );
    return value;
  };

  /*
  This function set the checked state of the optionalFilters checkboxes to actually value 
  and set the filter components state to visible of invisible 
  */
  public onManageFilters = (
    selectedFilter: OptionalFilters,
    saleFilters: SaleFilters,
    checkStatus: boolean,
  ): void => {
    //First, update the list of Inputs
    optionalFilters = optionalFilters.map(
      (
        optionalFilter: OptionalFilters | undefined,
      ): OptionalFilters | undefined => {
        if (optionalFilter && optionalFilter.value === selectedFilter.value)
          optionalFilter.checked = checkStatus;
        return optionalFilter;
      },
    );

    //If one filter is added, hide the list of checkbox
    if (checkStatus) {
      saleFilters.moreFilters && saleFilters.moreFilters.push(selectedFilter);
      this.props.setSaleFilters(saleFilters);
    } else {
      //If one filter is deleted, update the list of Inputs and reset its value
      saleFilters.moreFilters = saleFilters.moreFilters.filter(
        (opFilter: OptionalFilters | undefined): boolean =>
          selectedFilter !== opFilter,
      );
      this.updateFilters(saleFilters, optionalFilters);
    }
    if (selectedFilter.value === 'equipment') {
      this.onGetDIstCenters(saleFilters);
    }
  };

  /*
  This function use the optionalFilters checked state for reset the filters that were removed
  */
  public updateFilters = (
    saleFilters: SaleFilters,
    optionalFilters: (OptionalFilters | undefined)[],
  ): void => {
    const deletedFilters: (
      | OptionalFilters
      | undefined
    )[] = optionalFilters.filter((filter: OptionalFilters | undefined):
      | boolean
      | undefined => {
      return filter && filter.checked === false;
    });
    deletedFilters.forEach(
      (deletedFilter: OptionalFilters | undefined): void => {
        if (!deletedFilter) return;
        switch (deletedFilter.value) {
          case 'folio':
            saleFilters.folio = undefined;
            break;
          case 'equipment':
            saleFilters.equipment = '';
            break;
          case 'paymentCondition':
            saleFilters.paymentCondition = undefined;
            break;
          case 'paymentWay':
            saleFilters.paymentWay = '';
            break;
          case 'amount':
            saleFilters.totalGreaterThan = undefined;
            saleFilters.totalLessThan = undefined;
            break;
          case 'invoiceState':
            saleFilters.invoiceStatus = '';
            break;
          case 'distributionCenter':
            saleFilters.distributionCenter = '';
            break;
          case 'serviceType':
            saleFilters.serviceType = '';
            break;
          case 'dateGreaterThan':
            saleFilters.dateGreaterThan = '';
            break;
          case 'dateLessThan':
            saleFilters.dateLessThan = '';
            break;
          case 'settlementFolio':
            saleFilters.settlementFolio = '';
            break;
          case 'invoiceRequired':
            saleFilters.invoiceRequired = undefined;
            break;
          case 'invoiceSerieFolio':
            saleFilters.invoiceSerie = '';
            saleFilters.invoiceFolio = undefined;
            break;
          default:
            break;
        }
      },
    );
    this.onFiltersChange(saleFilters, true);
  };

  /*
  This function apply the actually saleFilters values and get the sales
  */
  public onFiltersChange = (
    saleFilters: SaleFilters,
    paginationChange?: boolean,
  ): void => {
    console.log('sf', saleFilters);
    const asyncAction = async (): Promise<void> => {
      if (!paginationChange) {
        saleFilters.pageNumber = 0;
      }

      await this.props.getSales(saleFilters);
      await this.props.setSaleFilters(saleFilters);
    };
    asyncAction();

    let { dateGreaterThan = Globals.Utils.Date.dateToString() as string, dateLessThan = dateGreaterThan as string } = saleFilters;
    callApi(ApiRoute.GetDasSaleOriginalFolios, { dasDateRange: [ dateGreaterThan.split('T')[0], dateLessThan.split('T')[0] ] }).then(result => {
      console.log(result);
      if(!result || !result.success || !('originalFolioBySaleId' in result)){
        return;
      }
      this.setState({ originalFolioBySaleId: { ...result.originalFolioBySaleId } });
      //this.state.originalFolioBySaleId = { ...result.originalFolioBySaleId };
    });
  };

  public onGetDIstCenters = (saleFilters: SaleFilters): void => {
    const asyncAction = async (): Promise<void> => {
      await this.props.getEquipmentsByDistributionCenter(
        saleFilters.distributionCenter ? saleFilters.distributionCenter : '',
      );
      this.setState({ stateFilters: saleFilters });
    };
    asyncAction();
  };
  private searchButtonComponent = (saleFilters: SaleFilters): JSX.Element => {
    return (
      <Col
        xs={24}
        sm={12}
        md={3}
        lg={3}
        xl={2}
        xxl={2}
        className="separated-filters"
      >
        <Button
          type="primary"
          size={'default'}
          onClick={(): void => {
            this.onFiltersChange(saleFilters);
          }}
          disabled={this.props.isFetching}
          style={{ marginTop: '20px' }}
        >
          <Icon type="search" />
          {'Buscar'}
        </Button>
      </Col>
    );
  };

  private onConfirmInvoiceCancellation = (saleId: string): void => {
    const cancelAndLoadPage = async (): Promise<void> => {
      await this.props.cancelSaleInvoice(saleId);
      await this.props.getSales(this.props.saleFilters);
    };

    cancelAndLoadPage();
  };

  private renderGeneralFilters = (saleFilters: SaleFilters): JSX.Element => {
    const { endValueDate, endOpen } = this.state;
    return (
      <Col
        xs={24}
        sm={24}
        md={6}
        lg={5}
        xl={8}
        xxl={6}
        className="separated-filters"
      >
        <div>
          <Text className={'filters-text-label'}>Filtrar por fecha</Text>
        </div>
        <Col xs={24} sm={12} md={24} lg={24} xl={12} xxl={12}>
          <DatePicker
            style={{ width: '100%' }}
            disabled={saleFilters.moreFiltersVisible || this.props.isFetching}
            disabledDate={(date: moment.Moment | undefined): boolean =>
              isDateWrong(date, moment(saleFilters.dateLessThan))
            }
            format={DATE_FORMAT}
            defaultValue={moment(getInitialTime())}
            placeholder="Desde"
            value={
              saleFilters.dateGreaterThan
                ? moment(saleFilters.dateGreaterThan)
                : undefined
            }
            onChange={(startDate: Moment | null): void => {
              saleFilters.dateGreaterThan = startDate
                ? startDate.toISOString()
                : undefined;
              //when clic on cancel date, it is undefined, so set the today's date
              if (saleFilters.dateGreaterThan === undefined) {
                saleFilters.dateGreaterThan = getInitialTime().toISOString();
              }
              this.setState({ stateFilters: saleFilters });
            }}
            onOpenChange={(open: boolean): void => {
              if (!open) this.setState({ endOpen: true });
            }}
          />
        </Col>
        <Col xs={24} sm={12} md={24} lg={24} xl={12} xxl={12}>
          <DatePicker
            style={{ width: '100%' }}
            disabled={saleFilters.moreFiltersVisible || this.props.isFetching}
            disabledDate={(date: moment.Moment | undefined): boolean =>
              isDateWrong(
                saleFilters && moment(saleFilters.dateGreaterThan),
                date,
              )
            }
            format={DATE_FORMAT}
            defaultValue={endValueDate}
            value={
              saleFilters.dateLessThan
                ? moment(saleFilters.dateLessThan)
                : undefined
            }
            placeholder="Hasta"
            onChange={(endDate: Moment | null): void => {
              saleFilters.dateLessThan = endDate
                ? endDate.toISOString()
                : undefined;
              //when clic on cancel date, it is undefined, so set the today's date
              if (saleFilters.dateLessThan === undefined) {
                saleFilters.dateLessThan = getEndTime().toISOString();
              }
              this.setState({ stateFilters: saleFilters });
            }}
            open={endOpen}
            onOpenChange={(status: boolean): void =>
              this.setState({ endOpen: status })
            }
          />
        </Col>
      </Col>
    );
  };

  /**
   * This function render elements from the filters
   */
  private renderFilters = (saleFilters: SaleFilters): JSX.Element => {
    const rowsAllowed = 30000;
    const zipRowsAllowed = 15000;
    const { salePage } = this.props;
    const assignedDistrCenters =
      this.tokenAssignedDistrCenters &&
      this.tokenAssignedDistrCenters.length > 0
        ? this.tokenAssignedDistrCenters
        : [this.tokenDistrCenter];
    if (getCustomerCode()) {
      return (
        <Row>
          {this.renderGeneralFilters(saleFilters)}
          {this.searchButtonComponent(saleFilters)}
        </Row>
      );
    }
    const moreFiltersContent: JSX.Element = (
      <div>
        {optionalFilters.map(
          (
            opFilters: OptionalFilters | undefined,
            index: number,
          ): JSX.Element | undefined =>
            opFilters && (
              <div key={index}>
                <Checkbox
                  value={opFilters}
                  key={'moreFilers.Option' + index}
                  onChange={(event): void => {
                    const selectedFilter = event.target.value;
                    const checked = event.target.checked;
                    this.onManageFilters(selectedFilter, saleFilters, checked);
                  }}
                  checked={opFilters.checked}
                  disabled={opFilters.disabled}
                >
                  {opFilters.label}
                </Checkbox>
              </div>
            ),
        )}
      </div>
    );
    return (
      <div>
        <Row>
          {this.renderGeneralFilters(saleFilters)}
          <Col
            xs={24}
            sm={12}
            md={7}
            lg={6}
            xl={5}
            xxl={4}
            className="separated-filters"
          >
            <div>
              <Text className={'filters-text-label'}>
                Centro de distribución
              </Text>
            </div>
            {
              <Select
                style={{ width: '100%' }}
                className={'sale-filters-size'}
                disabled={
                  saleFilters.moreFiltersVisible || this.props.isFetching
                }
                defaultValue={''}
                value={saleFilters.distributionCenter}
                onChange={(event: string): void => {
                  saleFilters.distributionCenter = event;
                  this.onGetDIstCenters(saleFilters);
                }}
              >
                <Select.Option value="">Todos</Select.Option>
                {//When user has permission, show all options
                isAllowed([P.CAN_VIEW_ALL_DISTRIBUTION_CENTERS]) &&
                  this.props.distributionCenter &&
                  this.props.distributionCenter.map(
                    (
                      distCenter: DistributionCenter,
                      index: number,
                    ): JSX.Element => (
                      <Select.Option value={distCenter.id} key={index}>
                        {distCenter.name}
                      </Select.Option>
                    ),
                  )}
                {//When user HAS NO permission, show the options with the assigned distr centers information
                !isAllowed([P.CAN_VIEW_ALL_DISTRIBUTION_CENTERS]) &&
                  this.props.distributionCenter &&
                  assignedDistrCenters.map(
                    (id: string, index: number): JSX.Element => {
                      const distrCenter = this.props.distributionCenter.find(
                        (center: DistributionCenter): boolean =>
                          center.id === id,
                      );
                      return distrCenter ? (
                        <Select.Option value={distrCenter.id} key={index}>
                          {distrCenter.name}
                        </Select.Option>
                      ) : (
                        <div key={index} />
                      );
                    },
                  )}
              </Select>
            }
          </Col>

          <Col
            xs={24}
            sm={12}
            md={4}
            lg={4}
            xl={3}
            xxl={2}
            className="separated-filters"
          >
            <div>
              <Text className={'filters-text-label'}>Cliente</Text>
            </div>
            <Input
              placeholder="Código cliente"
              defaultValue={''}
              disabled={saleFilters.moreFiltersVisible || this.props.isFetching}
              value={saleFilters.customerCode}
              onChange={(event): void => {
                saleFilters.customerCode = event.currentTarget.value;
                this.setState({ stateFilters: saleFilters });
              }}
            />
          </Col>

          <Col
            xs={24}
            sm={12}
            md={4}
            lg={4}
            xl={3}
            xxl={2}
            className="separated-filters"
          >
            <div>
              <Text className={'filters-text-label'}>Tipo de servicio</Text>
            </div>
            <Select
              style={{ width: '100%' }}
              className={'sale-filters-size'}
              defaultValue={''}
              disabled={saleFilters.moreFiltersVisible || this.props.isFetching}
              value={saleFilters.serviceType}
              onChange={(event: string): void => {
                saleFilters.serviceType = event;
                this.setState({ stateFilters: saleFilters });
              }}
            >
              <Select.Option value="">Todos</Select.Option>
              <Select.Option value="AUTOTANQUE">Autotanque</Select.Option>
              <Select.Option value="CARBURACION">Carburacion</Select.Option>
              <Select.Option value="CILINDRO">Cilindro</Select.Option>
            </Select>
          </Col>
          {this.searchButtonComponent(saleFilters)}

          <Col
            xs={24}
            sm={24}
            md={18}
            lg={14}
            xl={12}
            xxl={8}
            className="separated-filters extra-buttons"
          >
            <Popover
              placement="bottomRight"
              title={'Más filtros'}
              trigger="click"
              visible={saleFilters.moreFiltersVisible}
              onVisibleChange={(): void => {
                saleFilters.moreFiltersVisible = !saleFilters.moreFiltersVisible;
                this.setState({ stateFilters: saleFilters });
                this.props.setSaleFilters(saleFilters);
              }}
              content={moreFiltersContent}
            >
              <Button type="link">{'+Más filtros'}</Button>
            </Popover>

            <Button
              type={'ghost'}
              disabled={saleFilters.moreFiltersVisible}
              onClick={(): void => {
                //Save the current selected checkbox list in a constant, because the InitSaleFilters has the previous selected
                const currentMoreFilters = saleFilters.moreFilters;
                saleFilters = initSaleFilters;
                saleFilters.moreFilters = currentMoreFilters;
                saleFilters.dateGreaterThan = constructMoment(
                  0,
                  0,
                  0,
                  1,
                ).toISOString();
                saleFilters.dateLessThan = constructMoment(
                  23,
                  59,
                  59,
                  999,
                ).toISOString();
                this.updateFilters(saleFilters, optionalFilters);
              }}
            >
              Limpiar
            </Button>
            <Tooltip
              title={`Descargar como Excel.\nMáximo 10000 registros.`}
              placement="topRight"
            >
              <Button
                type="primary"
                shape="round"
                size={'default'}
                onClick={(): void => {
                  this.props.downloadReport(saleFilters);
                }}
                disabled={
                  this.props.isFetching ||
                  (salePage.totalElements !== undefined &&
                    salePage.totalElements > rowsAllowed) ||
                  (salePage.totalElements !== undefined &&
                    salePage.totalElements <= 0)
                }
              >
                <Icon type={'download'} />
                {' Excel'}
              </Button>
              <Button
                type="primary"
                shape="round"
                size={'default'}
                onClick={(): void => {
                  //this.props.downloadReport(saleFilters);
                  let { dateGreaterThan: initDate, dateLessThan: endDate, distributionCenter, customerCode, paymentCondition, serviceType } = this.props.saleFilters;
                  console.log(initDate, endDate, distributionCenter, customerCode, serviceType);
                  console.log(this.props.saleFilters);
                  let dateStart = Globals.Utils.Date.dateToString({ date: initDate ? new Date(initDate) : new Date() });
                  let dateEnd = Globals.Utils.Date.dateToString({ date: endDate ? new Date(endDate) : new Date() });
                  let distCenterId = distributionCenter as DbOldValues._DistCenterId || undefined;
                  let dateFileName = dateStart !== dateEnd ? `${dateStart}_${dateEnd}`: `${dateStart}`;
                  console.log(dateStart, dateEnd);
                  callApiGetFile(ApiRoute.GetDasSaleCsv, { dateRange: [ dateStart, dateEnd ], distCenterId, customerCode, paymentCondition: paymentCondition as any }).then(result => {
                    let fileName = `Ventas_ ${dateFileName}${distCenterId ? `_${DbOldDetails.distCenterId[distCenterId].name}` : ''}.csv`;
                    if(result) Globals.Utils.File.downloadBlob(result, fileName);
                    else alert(`Error obteniendo archivo.`);
                  });
                }}
                disabled={
                  this.props.isFetching /* ||
                  (salePage.totalElements !== undefined &&
                    salePage.totalElements > rowsAllowed) ||
                  (salePage.totalElements !== undefined &&
                    salePage.totalElements <= 0) */
                }
              >
                <Icon type={'download'} />
                {' CSV Nuevo'}
              </Button>
            </Tooltip>
            <Tooltip
              title={`Descargar ZIP con XML y PDF de ventas. 
              Máximo 15,000 registros`}
              placement="topRight"
            >
              <Button
                type="primary"
                shape="round"
                size={'default'}
                onClick={(): void => {
                  this.props.downloadXmlZip(saleFilters);
                }}
                disabled={
                  this.props.isFetching ||
                  (salePage.totalElements !== undefined &&
                    salePage.totalElements > zipRowsAllowed) ||
                  (salePage.totalElements !== undefined &&
                    salePage.totalElements <= 0)
                }
              >
                <Icon type={'download'} />
                {' ZIP'}
              </Button>
            </Tooltip>
            <Tooltip
              title={`Descargar en Excel registros con factura cancelada`}
              placement="topRight"
            >
              <Button
                type="primary"
                shape="round"
                size={'default'}
                onClick={(): void => {
                  const downloadReport = async (): Promise<void> => {
                    await this.props.downloadCancellationsReport(
                      this.state.stateFilters,
                    );
                  };
                  downloadReport();
                }}
                disabled={this.props.isFetching}
              >
                <Icon type={'download'} />
                {' Cancelados'}
              </Button>
            </Tooltip>
          </Col>
        </Row>

        <Row>
          {isAllowed([P.CAN_SEE_FOLIOS]) &&
            this.isFilterSelected('folio', saleFilters.moreFilters) && (
              <Col
                xs={24}
                sm={12}
                md={4}
                lg={3}
                xl={2}
                xxl={2}
                className="separated-filters"
              >
                <div>
                  <Text className={'filters-text-label'}>Buscar Folio</Text>
                </div>
                <Input
                  placeholder="Folio"
                  value={saleFilters.folio}
                  disabled={
                    saleFilters.moreFiltersVisible || this.props.isFetching
                  }
                  onChange={(event): void => {
                    saleFilters.folio = event.currentTarget.value;
                    this.setState({ stateFilters: saleFilters });
                  }}
                />
              </Col>
            )}
          {this.isFilterSelected('amount', saleFilters.moreFilters) && (
            <Col
              xs={24}
              sm={12}
              md={8}
              lg={7}
              xl={5}
              xxl={4}
              className="separated-filters"
            >
              <div>
                <Text
                  className={'filters-text-label'}
                  style={{ paddingLeft: '30%' }}
                >
                  Precio total
                </Text>
              </div>
              <div>
                <Text strong className={'filter-price-range-divisa'}>
                  $
                </Text>
                <Input
                  className={'filter-price-range-input'}
                  disabled={
                    saleFilters.moreFiltersVisible || this.props.isFetching
                  }
                  placeholder="Desde"
                  value={saleFilters.totalGreaterThan}
                  onChange={(event): void => {
                    const numberValue = parseInt(event.currentTarget.value);
                    saleFilters.totalGreaterThan = !Number.isNaN(numberValue)
                      ? numberValue
                      : 0;
                    this.setState({ stateFilters: saleFilters });
                  }}
                />
                <Text strong className={'filter-price-range-divisa'}>
                  $
                </Text>
                <Input
                  className={'filter-price-range-input'}
                  style={{
                    borderTopRightRadius: '5px',
                    borderBottomRightRadius: '5px',
                  }}
                  disabled={
                    saleFilters.moreFiltersVisible || this.props.isFetching
                  }
                  placeholder="Hasta"
                  value={saleFilters.totalLessThan}
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>,
                  ): void => {
                    const numberValue = parseInt(event.currentTarget.value);
                    saleFilters.totalLessThan = !Number.isNaN(numberValue)
                      ? numberValue
                      : 0;
                    this.setState({ stateFilters: saleFilters });
                  }}
                />
              </div>
            </Col>
          )}
          {this.isFilterSelected('equipment', saleFilters.moreFilters) && (
            <Col
              xs={24}
              sm={12}
              md={6}
              lg={4}
              xl={3}
              xxl={3}
              className="separated-filters"
            >
              <div>
                <Text className={'filters-text-label'}>Equipos</Text>
              </div>
              <Select
                style={{ width: '100%' }}
                className={'sale-filters-size'}
                disabled={
                  saleFilters.moreFiltersVisible || this.props.isFetching
                }
                defaultValue={''}
                value={saleFilters.equipment}
                onChange={(event: string): void => {
                  saleFilters.equipment = event;
                  this.setState({ stateFilters: saleFilters });
                }}
              >
                <Select.Option value={''}>Todos</Select.Option>
                {this.props.equipments.map(
                  (equipment: Equipment, index: number): JSX.Element => (
                    <Select.Option value={equipment.idG4s} key={index}>
                      {equipment.tag}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Col>
          )}
          {this.isFilterSelected('paymentWay', saleFilters.moreFilters) && (
            <Col
              xs={24}
              sm={12}
              md={5}
              lg={4}
              xl={3}
              xxl={2}
              className="separated-filters"
            >
              <div>
                <Text className={'filters-text-label'}>Método de pago</Text>
              </div>
              <Select
                showSearch
                disabled={
                  saleFilters.moreFiltersVisible || this.props.isFetching
                }
                style={{ width: '100%' }}
                defaultValue={''}
                value={saleFilters.paymentWay}
                onChange={(event: string): void => {
                  saleFilters.paymentWay = event;
                  this.setState({ stateFilters: saleFilters });
                }}
              >
                <Select.Option value="">Todos</Select.Option>
                <Select.Option value="Efectivo">Efectivo</Select.Option>
                <Select.Option value="Tarjeta de crédito">
                  Tarjeta de crédito
                </Select.Option>
                <Select.Option value="Tarjeta de débito">
                  Tarjeta de débito
                </Select.Option>
                <Select.Option value="Cheque">Cheque</Select.Option>
                <Select.Option value="Transferencia">
                  Transferencia
                </Select.Option>
              </Select>
            </Col>
          )}

          {this.isFilterSelected(
            'paymentCondition',
            saleFilters.moreFilters,
          ) && (
            <Col
              xs={24}
              sm={12}
              md={5}
              lg={4}
              xl={3}
              xxl={2}
              className="separated-filters"
            >
              <div>
                <Text className={'filters-text-label'}>Condición de pago</Text>
              </div>
              <Select
                showSearch
                disabled={
                  saleFilters.moreFiltersVisible || this.props.isFetching
                }
                style={{ width: '100%' }}
                defaultValue={''}
                value={saleFilters.paymentCondition}
                onChange={(event: string): void => {
                  saleFilters.paymentCondition = event;
                  this.setState({ stateFilters: saleFilters });
                }}
              >
                <Select.Option value="">Todos</Select.Option>
                <Select.Option value="CASH">Contado</Select.Option>
                <Select.Option value="CREDIT">Crédito</Select.Option>
                <Select.Option value="TRASLATE">Traslado</Select.Option>
                <Select.Option value="RETURN">Retorno</Select.Option>
                <Select.Option value="PREPAID">Prepago</Select.Option>
                <Select.Option value="SELF_CONSUMPTION">
                  Consumo propio
                </Select.Option>
                <Select.Option value="FAILED">Fallido</Select.Option>
              </Select>
            </Col>
          )}

          {this.isFilterSelected('invoiceState', saleFilters.moreFilters) && (
            <Col
              xs={24}
              sm={12}
              md={5}
              lg={4}
              xl={3}
              xxl={2}
              className="separated-filters"
            >
              <div>
                <Text className={'filters-text-label'}>Estado facturación</Text>
              </div>
              <Select
                style={{ width: '100%' }}
                disabled={
                  saleFilters.moreFiltersVisible || this.props.isFetching
                }
                className={'sale-filters-size'}
                defaultValue={''}
                value={saleFilters.invoiceStatus}
                onChange={(event: string): void => {
                  saleFilters.invoiceStatus = event;
                  this.setState({ stateFilters: saleFilters });
                }}
              >
                <Select.Option value="">Todos</Select.Option>
                <Select.Option value="facturadas">Facturadas</Select.Option>
                <Select.Option value="no facturadas">
                  No facturadas
                </Select.Option>
                <Select.Option value="canceladas">Canceladas</Select.Option>
              </Select>
            </Col>
          )}
          {this.isFilterSelected(
            'settlementFolio',
            saleFilters.moreFilters,
          ) && (
            <Col
              xs={24}
              sm={12}
              md={6}
              lg={5}
              xl={4}
              xxl={3}
              className="separated-filters"
            >
              <div>
                <Text className={'filters-text-label'}>
                  Buscar por folio de corte
                </Text>
              </div>
              <Input
                placeholder="Folio de corte"
                value={saleFilters.settlementFolio}
                disabled={
                  saleFilters.moreFiltersVisible || this.props.isFetching
                }
                onChange={(event): void => {
                  saleFilters.settlementFolio = event.currentTarget.value;
                  this.setState({ stateFilters: saleFilters });
                }}
              />
            </Col>
          )}
          {this.isFilterSelected(
            'invoiceSerieFolio',
            saleFilters.moreFilters,
          ) && (
            <Col
              xs={24}
              sm={12}
              md={8}
              lg={7}
              xl={5}
              xxl={4}
              className="separated-filters"
            >
              <div>
                <Text
                  className={'filters-text-label'}
                  style={{ paddingLeft: '30%' }}
                >
                  Factura serie/folio
                </Text>
              </div>
              <div>
                <Select
                  style={{ width: '50%' }}
                  disabled={
                    saleFilters.moreFiltersVisible || this.props.isFetching
                  }
                  className={'sale-filters-size'}
                  defaultValue={''}
                  value={saleFilters.invoiceSerie}
                  onChange={(event: string): void => {
                    saleFilters.invoiceSerie = event;
                    this.setState({ stateFilters: saleFilters });
                  }}
                >
                  <Select.Option value="">Todos</Select.Option>
                  <Select.Option value="CRE">CRE</Select.Option>
                  <Select.Option value="CON">CON</Select.Option>
                  <Select.Option value="C">C</Select.Option>
                  <Select.Option value="CR">CR</Select.Option>
                  <Select.Option value="BI">BI</Select.Option>
                  <Select.Option value="RCO">RCO</Select.Option>
                  <Select.Option value="RCR">RCR</Select.Option>
                </Select>
                <Input
                  className={'filter-price-range-input'}
                  style={{
                    borderTopRightRadius: '5px',
                    borderBottomRightRadius: '5px',
                  }}
                  disabled={
                    saleFilters.moreFiltersVisible || this.props.isFetching
                  }
                  placeholder="Folio"
                  value={saleFilters.invoiceFolio}
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>,
                  ): void => {
                    saleFilters.invoiceFolio = event.currentTarget.value;
                    this.setState({ stateFilters: saleFilters });
                  }}
                />
              </div>
            </Col>
          )}
          {this.isFilterSelected(
            'invoiceRequired',
            saleFilters.moreFilters,
          ) && (
            <Col
              xs={24}
              sm={12}
              md={6}
              lg={5}
              xl={4}
              xxl={3}
              className="separated-filters"
            >
              <div>
                <Text className={'filters-text-label'}>Factura requerida</Text>
              </div>
              <Checkbox
                value={saleFilters.invoiceRequired}
                disabled={
                  saleFilters.moreFiltersVisible || this.props.isFetching
                }
                onChange={(event): void => {
                  saleFilters.invoiceRequired = event.target.checked;
                  this.setState({ stateFilters: saleFilters });
                }}
              />
            </Col>
          )}
        </Row>
      </div>
    );
  };

  private renderDetails = (item: Sale): JSX.Element => {
    const badge =
      isAllowed([P.SEE_INDICATOR_POSLIQUIDATE_SALE]) &&
      item.totalAmountC !== null ? (
        <Badge status="default" />
      ) : (
        <div />
      );
    return (
      <Row>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Fecha: '}</Text>
          <Text>{moment(item.endDateSave).format('YYYY-MM-DD HH:mm')}</Text>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          {badge}
          <Text strong>{'Ticket: '}</Text>
          <Text>{item.salesFolio}</Text>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Equipo: '}</Text>
          <Text>
            {badge} {item.tag}
          </Text>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Cliente: '}</Text>
          <Text>{item.customerName}</Text>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'RFC: '}</Text>
          <Text>{item.customerRfc}</Text>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Forma pago: '}</Text>
          <Text>{paymentWayEs(item.paymentWay)}</Text>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Requiere factura: '}</Text>
          <Text>{item.invoiceRequired === true ? 'Si' : 'No'}</Text>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Cantidad: '}</Text>
          <Text>
            {this.state.unitType === 'L'
              ? item.totalVolume
              : Number(item.totalVolume) * 2}{' '}
            {this.state.unitType}
          </Text>
        </Col>
      </Row>
    );
  };
  public showModal = (saleDetail: Sale): void => {
    const startAsync = async (): Promise<void> => {
      try {
        await this.setState({ saleDetail });
        await this.setState({ showDetails: true });
      } catch {}
    };
    startAsync();
  };

  public hideModal = (): void => {
    this.setState({ showDetails: false });
  };

  public renderActions = (item: Sale): JSX.Element => {
    return (
      <Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <h1>
            <Text strong>{' $' + item.total}</Text>
          </h1>
        </Col>
        {item.invoice !== null ? (
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              {this.renderDownloadFiles(item)}
            </Col>
          </Col>
        ) : (
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              {isAllowed([P.CAN_PRINT_SALE_TICKET]) &&
                item.paymentConditionType !== 'TRASLATE' &&
                item.paymentConditionType !== 'SELF-CONSUMPTION' && (
                  <Button
                    type="link"
                    className="ticket-button ant-button"
                    disabled={this.props.isFetching}
                    onClick={(): void =>
                      this.props.downloadTicket(
                        item.id,
                        item.salesFolio,
                        item.customerName,
                      )
                    }
                  >
                    <Text>Ticket</Text>
                  </Button>
                )}
            </Col>
          </Col>
        )}
      </Row>
    );
  };
  public renderDownloadFiles = (record: Sale): JSX.Element => {
    const tokenRfc: string =
      getToken() != null ? JwtDecode<Token>(getToken() as string).rfc : '';
    if (tokenRfc !== '' && record.forcedGeneralCustomer) {
      return <div />;
    }
    return record.invoiceError === null ? (
      <div>
        <Button
          type="link"
          className="ant-button"
          key={record.id + '4'}
          onClick={(): void =>
            this.props.downloadFile(
              record.invoice,
              'file.pdf',
              record.salesFolio,
            )
          }
        >
          <Icon type="download" />
          PDF
        </Button>
        <Button
          type="link"
          className="ant-button"
          key={record.id + '3'}
          onClick={(): void =>
            this.props.downloadFile(
              record.invoice,
              'file.xml',
              record.salesFolio,
            )
          }
        >
          <Icon type="download" />
          XML
        </Button>
      </div>
    ) : (
      <Tooltip title={record.invoiceError}>
        <Icon type="warning" style={{ fontSize: '26px', color: 'red' }} />
      </Tooltip>
    );
  };

  public render = (): React.ReactNode => {
    const { downloadTicket, isFetching } = this.props;
    const { stateFilters } = this.state;

    if (!localStorage.getItem('token')) return <Redirect to="/login" />;
    return (
      <div className={'content-backgroud'}>
        {this.renderFilters(stateFilters)}
        {getIndicators(this.props.totalsSaleIssued)}
        <Row>
          <Row>
            <p />
          </Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={0} xxl={0}>
            <List
              dataSource={this.props.salePage.content}
              renderItem={(item: Sale, index: number): JSX.Element => (
                <List.Item>
                  <List.Item.Meta
                    key={index}
                    description={
                      <Row
                        style={{
                          background: '#FFFFFF',
                          padding: '10px',
                          margin: '-7px',
                        }}
                      >
                        <Col span={20}>{this.renderDetails(item)}</Col>
                        <Col span={4}>{this.renderActions(item)}</Col>
                      </Row>
                    }
                  />
                </List.Item>
              )}
            />
          </Col>

          <Col
            xs={0}
            sm={0}
            md={0}
            lg={0}
            xl={24}
            xxl={24}
            style={{ backgroundColor: 'white' }}
          >
            <Table
              dataSource={this.props.salePage.content}
              rowKey={ row => row.id }
              pagination={false}
              loading={isFetching}
            >
              <Table.Column
                title="Fecha"
                dataIndex="endDate"
                key="endDate"
                render={(text: string, record: Sale): React.ReactNode => {
                  return moment(record.endDateSave).format(
                    'YYYY-MM-DD HH:mm:ss',
                  );
                }}
              />
              <Table.Column
                title="Ticket"
                dataIndex={
                  isAllowed([permissions.CAN_SEE_FOLIOS])
                    ? 'salesFolio'
                    : 'IUSCode'
                }
                key={isAllowed(['CAN_SEE_FOLIOS']) ? 'salesFolio' : 'IUSCode'}
                render={(text: string, record: Sale): React.ReactNode => {
                  const badge =
                    isAllowed([P.SEE_INDICATOR_POSLIQUIDATE_SALE]) &&
                    record.totalAmountC !== null ? (
                      <Badge status="default" />
                    ) : (
                      <div />
                    );
                  return (
                    <div>
                      {record.disabled && getIncognitMode() === '1' ? (
                        <Badge color={'cyan'} />
                      ) : (
                        <div />
                      )}{' '}
                      {badge} {text}
                    </div>
                  );
                }}
              />
              { isAllowed([permissions.VIEW_SALE_G4S_FOLIO]) && (
                <Table.Column
                  title="Folio G4S"
                  key={ `folioG4S` }
                  render={ (_, sale: Sale) => this.state.originalFolioBySaleId[sale.id] || sale.salesFolio }
                />
              ) }
              <Table.Column title="Equipo" dataIndex="tag" key="tag" />
              <Table.Column
                title="Cliente"
                dataIndex="customerName"
                key="customerName"
              />
              <Table.Column
                title="RFC"
                dataIndex="customerRfc"
                key="customerRfc"
              />
              <Table.Column
                title="Forma de Pago"
                dataIndex="paymentWay"
                key="paymentWay"
                render={(text: string): React.ReactNode => {
                  return paymentWayEs(text);
                }}
              />
              <Table.Column
                title="Factura Serie/Folio"
                dataIndex="invoiceSerie"
                key="invoiceSerie"
                render={(text: string, record: Sale): React.ReactNode => {
                  return text !== null
                    ? record.invoiceSerie + '/' + record.invoiceFolio
                    : 'N/A';
                }}
              />
              <Table.Column
                title="Requiere factura"
                dataIndex="invoiceRequired"
                key="invoiceRequired"
                render={(text: string, record: Sale): React.ReactNode => {
                  return (
                    <div className={'requiere-factura'}>
                      {record.invoiceRequired === true ? 'Si' : 'No'}
                    </div>
                  );
                }}
              />
              <Table.Column
                title={'Cantidad(' + this.state.unitType + ')'}
                dataIndex="totalVolume"
                key="totalVolume"
                render={(text: string, record: Sale): React.ReactNode => {
                  return getIncognitMode() === '1'
                    ? toNumber(record.totalVolume || 0) +
                        ((record.totalVolumeC || 0) !== 0
                          ? ' - ' + toNumber(record.totalVolumeC || 0)
                          : '')
                    : (record.totalVolumeC || 0) !== 0
                    ? toNumber(record.totalVolumeC || 0)
                    : toNumber(record.totalVolume || 0);
                }}
              />

              <Table.Column
                title="Monto"
                dataIndex="total"
                key="total"
                render={(text: string, record: Sale): React.ReactNode => {
                  return getIncognitMode() === '1'
                    ? '$' +
                        toNumber(record.total || 0) +
                        ((record.totalAmountC || 0) !== 0
                          ? ' - ' + toNumber(record.totalAmountC || 0)
                          : '')
                    : (record.totalAmountC || 0) !== 0
                    ? '$' + toNumber(record.totalAmountC || 0)
                    : '$' + toNumber(record.total || 0);
                }}
              />
              <Table.Column
                title="Acciones"
                dataIndex="invoice"
                key="invoice"
                render={(text: string, record: Sale): React.ReactNode => {
                  const saleFilters = this.props.saleFilters;
                  const invoiceSerie = record.invoiceSerie
                    ? record.invoiceSerie
                    : '';
                  return (
                    <Row>
                      { isAllowed([P.CAN_EDIT_SALES]) && (
                          <EditSaleModal editingSale={ record } onSave={ async () => {
                            this.onFiltersChange(saleFilters);
                          } } />
                      ) }
                      {text !== null && record.invoiceDownloaded ? (
                        <div>
                          {this.renderDownloadFiles(record)}
                          {isAllowed([P.CAN_PRINT_SALE_TICKET]) &&
                            record.paymentConditionType !== 'TRASLATE' &&
                            record.paymentConditionType !==
                              'SELF-CONSUMPTION' && (
                              <Button
                                type="link"
                                className="ant-button"
                                disabled={isFetching}
                                onClick={(): void =>
                                  downloadTicket(
                                    record.id,
                                    isAllowed([P.CAN_SEE_FOLIOS])
                                      ? record.salesFolio
                                      : record.IUSCode,
                                    record.customerName
                                      ? record.customerName
                                      : 'PÚBLICO GENERAL',
                                  )
                                }
                              >
                                Ticket
                              </Button>
                            )}
                          <Button
                            type="link"
                            className="ant-button"
                            onClick={(): void => this.showModal(record)}
                          >
                            Ver detalles
                          </Button>
                        </div>
                      ) : (
                        <div>
                          {' '}
                          {isAllowed([P.CAN_PRINT_SALE_TICKET]) &&
                            record.paymentConditionType !== 'TRASLATE' &&
                            record.paymentConditionType !==
                              'SELF-CONSUMPTION' && (
                              <Button
                                type="link"
                                className="ticket-button ant-button"
                                disabled={isFetching}
                                onClick={(): void =>
                                  downloadTicket(
                                    record.id,
                                    isAllowed([P.CAN_SEE_FOLIOS])
                                      ? record.salesFolio
                                      : record.IUSCode,
                                    record.customerName
                                      ? record.customerName
                                      : 'PÚBLICO GENERAL',
                                  )
                                }
                              >
                                Ticket
                              </Button>
                            )}
                          <Button
                            type="link"
                            className="ant-button"
                            onClick={(): void => this.showModal(record)}
                          >
                            Ver detalles
                          </Button>
                        </div>
                      )}
                      {isAllowed([P.CAN_CHANGUE_INVOICE_REQUIRED]) &&
                      record.paymentConditionType === 'CASH' &&
                      record.invoiceRequired === false &&
                      ((record.invoiceDownloaded &&
                        record.statusCancelation !== null &&
                        invoiceSerie.includes('CANCELADO')) ||
                        !record.invoiceDownloaded) ? (
                        <Button
                          type="link"
                          className="ant-button"
                          onClick={(): void => {
                            this.props.updateInvoice(
                              saleFilters,
                              record.idG4s,
                              true,
                            );
                          }}
                        >
                          Requiere factura
                        </Button>
                      ) : (
                        ''
                      )}
                      {//Cancel invoice when user has permission & sale is CASH & customer is not registered
                      isAllowed([P.CANCEL_INVOICE_UNREGISTERED_CUSTOMER]) &&
                      record.paymentConditionType === 'CASH' &&
                      !record.customerRegistered &&
                      //And invoice is downloaded & status cancellation is null
                      record.invoiceDownloaded &&
                      record.statusCancelation === null &&
                      //And when invoice is not canceled or invoice has error
                      (!invoiceSerie.includes('CANCELADO') ||
                        record.invoiceError !== null) ? (
                        <Popconfirm
                          title={
                            '¿Realmente quiere cancelar esta factura ' +
                            record.invoiceSerie +
                            '/' +
                            record.invoiceFolio +
                            '?'
                          }
                          placement="topRight"
                          onConfirm={(): void =>
                            //Make request and update screen in async way
                            this.onConfirmInvoiceCancellation(record.idG4s)
                          }
                          okText="Sí"
                          cancelText="No"
                        >
                          <Button type="link" className="ant-button">
                            Cancelar factura
                          </Button>
                        </Popconfirm>
                      ) : (
                        ''
                      )}
                    </Row>
                  );
                }}
              />
            </Table>
            {this.state.showDetails && (
              <SaleDetailModal
                onCreate={(): Function => this.hideModal}
                sale={this.state.saleDetail}
                visible={this.state.showDetails}
                onCancel={(): Function => this.hideModal}
              />
            )}
          </Col>
          <Pagination
            showSizeChanger
            onShowSizeChange={(current: number, event: number): void => {
              stateFilters.pageSize = event;
              this.onFiltersChange(stateFilters);
            }}
            current={(this.props.salePage.number || 0) + 1}
            total={this.props.salePage.totalElements}
            onChange={(event: number): void => {
              stateFilters.pageNumber = event - 1;
              this.onFiltersChange(stateFilters, true);
            }}
            defaultPageSize={25}
            pageSizeOptions={['25', '50', '75', '100']}
            disabled={this.props.isFetching}
          />
        </Row>
      </div>
    );
  };
}

const mapStateToProps = (states: RootState): StateProps => {
  return {
    salePage: states.saleState.saleList,
    saleFilters: states.saleState.saleFilters,
    isFetching: states.saleState.isFetching,
    distributionCenter:
      states.saleState.distributionCenterInterface.distributionCenters,
    equipments: Object.values(states.saleState.equipmentList),
    totalsSaleIssued: states.saleState.totalsSaleIssued,
  };
};

const mapDispatchToProps: DispatchProps = {
  getSales,
  downloadFile,
  downloadTicket,
  getDistributionCenterAction,
  getEquipmentsByDistributionCenter,
  setSaleFilters,
  downloadReport,
  downloadXmlZip,
  updateInvoice,
  cancelSaleInvoice,
  downloadCancellationsReport,
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(SalesListComponent);


type EditSaleModalProps = { editingSale: Sale, onSave: () => Promise<void> };
const EditSaleModal = (props: EditSaleModalProps) => {
  const { CASH, CREDIT, PREPAID } = DbOldValues.SalePaymentCondition;

  const traslatePaymentWay = (text: string) => {
    switch(text){
      case'EFECTIVO': return DbOldValues.SalePaymentWay.EFECTIVO;
      case'CREDITO': return DbOldValues.SalePaymentWay.CREDITO;
      case'DEBITO': return DbOldValues.SalePaymentWay.DEBITO;
      case'CHEQUE': return DbOldValues.SalePaymentWay.CHEQUE;
      case'POR_DEFINIR': return DbOldValues.SalePaymentWay.POR_DEFINIR;
      default: return DbOldValues.SalePaymentWay.TRANSFERENCIA;
    }
  };

  let [ visible, setVisible ] = useState(false);
  let [ isLoading, setIsLoading ] = useState(false);

  let [ customerCode, setCustomerCode ] = useState('');
  let [ rfc, setRfc ] = useState('');
  let [ paymentCondition, setPaymentCondition ] = useState(props.editingSale.paymentConditionType as DbOldValues._SalePaymentCondition);
  let [ paymentWay, setPaymentWay ] = useState(traslatePaymentWay(props.editingSale.paymentWay));
  let [ unitPrice, setUnitPrice ] = useState(props.editingSale.unitPrice);

  let [ editSnapshots, setEditSnapshots ] = useState(null as DbOldTypes.Sale['edit_snapshots']);


  let loadEditHistory = useCallback(async () => {
    console.log(`Getting sale edit snapshots.`);
    setIsLoading(true);
    let result = await callApi(ApiRoute.GetSaleEditSnapshots, { saleId: props.editingSale.id });
    console.log(result);
    if(!result || !result.success){
      return;
    }
    setEditSnapshots(result.saleEditSnapshots);
    setIsLoading(false);
  }, [ props.editingSale.id ]);

  let editSale = useCallback(async (body: ApiTypes.EditSaleData) => {
    let result = await callApi(ApiRoute.EditSale, body);
    console.log(result);
    if(!result || !result.success){
      alert((result && result.msg) || `Error editando venta.`);
      return;
    }
    alert('Se guardaron los cambios.');
    setVisible(false);
    await props.onSave();
  }, [ props ]);

  const paymentConditionOptions: { name: string, value: DbOldValues._SalePaymentCondition }[] = [
    { name: 'Contado', value: DbOldValues.SalePaymentCondition.CASH },
    { name: 'Crédito', value: DbOldValues.SalePaymentCondition.CREDIT },
    { name: 'Traslado', value: DbOldValues.SalePaymentCondition.TRASLATE },
    { name: 'Consumo propio', value: DbOldValues.SalePaymentCondition.SELF_CONSUMPTION },
    { name: 'Retorno', value: DbOldValues.SalePaymentCondition.RETURN },
    { name: 'Prepago', value: DbOldValues.SalePaymentCondition.PREPAID },
    { name: 'Fallido', value: DbOldValues.SalePaymentCondition.FAILED },
  ];
  const paymentWayOptions: { name: string, value: DbOldValues._SalePaymentWay }[] = Globals.Utils.Object.entries(DbOldDetails.salePaymentWay).map(entry => ({
    name: entry.value.name,
    value: +entry.key,
  }));
  
  useEffect(() => {
    if(visible){
      setCustomerCode(props.editingSale.customerCode);
      setRfc(props.editingSale.customerRfc);
      setPaymentCondition(props.editingSale.paymentConditionType as DbOldValues._SalePaymentCondition);
      setPaymentWay(traslatePaymentWay(props.editingSale.paymentWay));
      setUnitPrice(props.editingSale.unitPrice);
      loadEditHistory();
    }
  }, [ visible, props ]);

  let modalContent: JSX.Element;
  if(isLoading) modalContent = (
    <div style={{ marginTop: 20, textAlign: 'center' }} >
      <Spin size="large" />
    </div>
  );
  else modalContent = (
    <div style={ { height: '88vh', overflowY: 'auto' } } >
      <Row>
        <Col span={ 22 } offset={ 1 } >
          <div>
            <label> Código cliente: </label>
            <Input id="customerCode" value={ customerCode } onChange={ event => setCustomerCode(event.target.value) } style={{ width: '100%' }} />
          </div>
          <div>
            <label> { `Rfc: ( Dejar vacío para usar el default del cliente )` } </label>
            <Input id="rfc" value={ rfc } onChange={ event => setRfc(event.target.value) } style={{ width: '100%' }} />
          </div>
          <div>
            <label> Condición de pago: </label>
            <Select id="paymentCondition" value={ paymentCondition } onChange={ value => setPaymentCondition(value) } style={{ width: '100%' }} >
              { paymentConditionOptions.map(pcOption => (
                <Select.Option key={ `pc_${pcOption.value}` } value={ pcOption.value } > { pcOption.name } </Select.Option>
              )) }
            </Select>
          </div>
          { paymentCondition === CASH && (
            <div>
              <label> Forma de pago: </label>
              <Select id="paymentWay" value={ paymentWay } onChange={ value => setPaymentWay(value) } style={{ width: '100%' }} >
                { paymentWayOptions.map(pwOption => (
                  <Select.Option key={ `pw_${pwOption.value}` } value={ pwOption.value } > { pwOption.name } </Select.Option>
                )) }
              </Select>
            </div>
          ) }
          { [ CASH, CREDIT, PREPAID ].includes(paymentCondition) && (
            <div>
              <div>
                <label> Precio unitario: </label>
                <InputNumber id="unitPrice" value={ unitPrice } onChange={ value => setUnitPrice(+(value || 0)) } style={{ width: '100%' }} />
              </div>
              <div>
                <label> Total: { Globals.Utils.Number.formatCurrency(props.editingSale.totalVolume*unitPrice) } </label>
              </div>
            </div>
          ) }
          <div style={ { textAlign: 'right' } } >
            <Button type="primary" onClick={ () => {
              editSale({
                id: props.editingSale.id,
                customer_code: customerCode,
                rfc,
                payment_condition_type: paymentCondition,
                payment_way: paymentCondition === CASH ? paymentWay : DbOldValues.SalePaymentWay.POR_DEFINIR,
                unit_price: [ CASH, CREDIT, PREPAID ].includes(paymentCondition) ? unitPrice : 0,
              });
            } } >
              Guardar
            </Button>
          </div>
        </Col>
        {/* <Col span={ 12 } >
          <pre>{ JSON.stringify([ { customerCode, paymentCondition, paymentWay, unitPrice }, props.editingSale ], null, '\t') }</pre>
        </Col> */}
      </Row>
      { editSnapshots && (
        <Row>
          <h1> Historial de ediciones </h1>
          <Table
            dataSource={ editSnapshots }
            rowKey={ row => row.datetime }
            size="small"
            columns={ [
              { title: 'Fecha', key: 'datetime', render: (_, row) => row.datetime },
              { title: 'Usuario', key: 'userEmail', render: (_, row) => row.userEmail },
              { title: 'Cliente', key: 'customer_code', render: (_, row) => row.customer_code },
              { title: 'Rfc', key: 'customer_rfc', render: (_, row) => row.customer_rfc },
              { title: 'Condición', key: 'payment_condition_type', render: (_, row) => DbOldDetails.getSalePaymentCondition(row.payment_condition_type).name },
              { title: 'Forma', key: 'payment_way', render: (_, row) => DbOldDetails.getSalePaymentWay(row.payment_way).name },
              { title: 'Precio U', key: 'unit_price', align: 'right', render: (_, row) => row.unit_price },
              { title: 'Total', key: 'total', align: 'right', render: (_, row) => row.total },
            ] }
          />
        </Row>
      ) }
    </div>
  );


  return (
    <div>
      { <Button type="link" className="ant-button" style={ {} } onClick={ () => setVisible(true) } >
        Editar
      </Button> }
      <Modal
        width={ '52vw' }
        style={ {  } }
        title="Editar venta"
        centered
        visible={ visible }
        footer={ null }
        onCancel={ () => setVisible(false) }
      >
        { modalContent }
      </Modal>
    </div>
  );
};
