import React, { Component, useEffect, useState } from 'react';
import { Modal, Col, Row, Divider, Button, Spin, InputNumber } from 'antd';
import {
  BankDeposit,
  TotalsSettlement,
  Settlement,
  DistributionCenter,
} from 'types/type';
import DepositTableToApply from './depositTableToApply';
import { initSettlement, initDistributionCenter } from 'commons/initTypes';
import Text from 'antd/lib/typography/Text';
import { toNumber } from 'commons/numbersFormat';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';
import Form, { WrappedFormUtils } from 'antd/lib/form/Form';
import { DbOldDetails, DbOldTypes } from 'database';
import { ApiRoute, ApiVarStorage, callApi } from 'commons/services/api';
import { Globals } from 'commons/globals';

interface CounterProps {
  visible: boolean;
  onCancel: Function;
  onCreate: Function;
  onCangeText(desc: string): void;
  bankDepositToValidate: BankDeposit;
  title: string;
  validar: boolean;
  settlementList: Settlement[];
  distributionCenter: DistributionCenter[];
  isFetching: boolean;
  form: WrappedFormUtils;
  onChangeDifferences(amount: number | undefined): void;
}
class ValidateDepositModel extends Component<CounterProps> {
  public state = {
    imageUrl: undefined,
    loading: false,
    tempFile: false,
    showTable: false,
    stagingArea: [initSettlement],
  };

  public footerTable = (): JSX.Element => {
    return (
      <Row>
        <Row>
          <p />
          <div className="base-for-table-short">
            <Col span={12} className="blue-cell-table-title">
              <Text className="white-text-table">{'Depositado'}</Text>
            </Col>
            <Col span={12} className="white-cell-table-title">
              <Text className="blue-text-table">
                {'$ ' + toNumber(this.props.bankDepositToValidate.amount)}
              </Text>
            </Col>
            {/* <Col span={12} className="grey-cell-table-body">
              <Text className="grey-text">{'Nuevo saldo pendiente'}</Text>
            </Col>
            <Col span={12} className="cell-table-body">
              {this.props.isFetching && <Spin />}
              {!this.props.isFetching && (
                <Text className="grey-text">
                  {'$ ' +
                    toNumber(
                      Number(
                        Math.round(
                          Number(this.props.bankDepositToValidate.debt),
                        ).toFixed(2),
                      ),
                    )}
                </Text>
              )}
            </Col> */}
          </div>
          <p />
        </Row>
        {this.props.validar && (
          <Row>
            <Col span={ 0 } >
              <Form.Item label="Capturar diferencias">
                {this.props.form.getFieldDecorator('amountDifferences ', {
                  initialValue: 0,
                  rules: [],
                })(
                  <InputNumber
                    style={{ width: '100%' }}
                    formatter={(value): string => `$${value}`}
                    parser={(value): string =>
                      value ? value.replace('$', '') : ''
                    }
                    onChange={(value): void =>
                      this.props.onChangeDifferences(value)
                    }
                    precision={2}
                  />,
                )}
              </Form.Item>
            </Col>
            <Divider type="horizontal" style={{ marginTop: 12, marginBottom: 0 }} />
            <DifferenceSection bankDepositId={ this.props.bankDepositToValidate.id } modalProps={ this.props } />
          </Row>
        )}
      </Row>
    );
  };
  public getTotalCash = (): TotalsSettlement => {
    const settlement = this.props.settlementList
      ? this.props.settlementList
      : [];
    let total = 0;
    let totalCash = 0;
    let totalCashCP = 0;
    let totalCashSP = 0;
    let totalCredit = 0;
    let totalDebit = 0;
    let totalCheque = 0;
    let totalTransfer = 0;
    settlement.forEach((s: Settlement): void => {
      if(s.equipment!== undefined && s.equipment.arrangeType!==undefined && (s.equipment.arrangeType ==='CP' || s.equipment.arrangeType==='NA')){
        total += s.postTotal;
        totalCash += s.totalCash || 0;
        totalCashCP += s.totalCash || 0;
        totalCredit += s.totalCredit || 0;
        totalDebit += s.totalDebit || 0;
        totalCheque += s.totalCheque || 0;
        totalTransfer += s.totalTransfer || 0;
      }else{
      totalCashSP += s.totalCash || 0;
      }
    });
    return {
      total,
      totalCash,
      totalCashCP,
      totalCashSP,
      totalCredit,
      totalDebit,
      totalCheque,
      totalTransfer,
    };
  };

  public selectedItemOnTable = (
    record: Settlement,
    selected: boolean,
  ): void => {
    this.setState({ stagingArea: this.props.settlementList });
  };

  public render = (): JSX.Element => {
    return (
      <Modal
        width={896}
        visible={this.props.visible}
        title={this.props.title}
        okText={this.props.validar ? 'Abonar' : 'Rechazar'}
        cancelText="Cancelar"
        onCancel={this.props.onCancel()}
        onOk={this.props.onCreate()}
        okButtonProps={{ disabled: this.props.isFetching }}
      >
        <Row>
          <Row className="header-bar">
            <Col span={15} offset={1}>
              <h1>
                <Text strong>
                  {
                    (
                      this.props.distributionCenter.find(
                        (d: DistributionCenter): boolean =>
                          d.id ===
                          this.props.bankDepositToValidate.distributionCenterId,
                      ) || initDistributionCenter
                    ).name
                  }
                </Text>
              </h1>
            </Col>
            <Col span={2} offset={1}>
              <Button
                type="primary"
                ghost
                onClick={(): void =>
                  this.setState({
                    showTable: !this.state.showTable,
                  })
                }
              >
                {this.state.showTable
                  ? '<- Regresar'
                  : 'Ver cortes a depositar'}
              </Button>
            </Col>
          </Row>
          <p />
          {this.state.showTable === true && (
            <DepositTableToApply
              selected={this.selectedItemOnTable}
              validatorChecked={(): boolean => false}
              validateCheck={(): boolean => false}
              getTotalCash={this.getTotalCash()}
              isFetching={this.props.isFetching}
              settlementSearchList={{
                settlement:
                  this.props.settlementList !== undefined
                    ? this.props.settlementList
                    : [initSettlement],
                totalDebt: this.props.bankDepositToValidate.debt,
              }}
            />
          )}
          {this.state.showTable === false && (
            <Row>
              <Col span={11}>
                <p />
                <Col span={11} offset={1}>
                  <p />
                  <Text strong style={{ color: '#113C71' }}>
                    {'Monto'}
                  </Text>
                  <br />
                  <Text>
                    {Math.round(
                      this.props.bankDepositToValidate.amount,
                    ).toFixed(2)}
                  </Text>
                </Col>
                <Col span={11} offset={1}>
                  <p />
                  <Text strong style={{ color: '#113C71' }}>
                    {'Número de cuenta'}
                  </Text>
                  <br />
                  <Text>{this.props.bankDepositToValidate.acountRecipt}</Text>
                </Col>
                <Col span={11} offset={1}>
                  <p />
                  <Text strong style={{ color: '#113C71' }}>
                    {'Forma de pago'}
                  </Text>
                  <br />
                  <Text>{this.props.bankDepositToValidate.paymentMethod}</Text>
                </Col>
                <Col span={11} offset={1}>
                  <p />
                  <Text strong style={{ color: '#113C71' }}>
                    {'Banco'}
                  </Text>
                  <br />
                  <Text>{this.props.bankDepositToValidate.bank}</Text>
                </Col>
                <Col span={11} offset={1}>
                  <p />
                  <Text strong style={{ color: '#113C71' }}>
                    {'Folio de pago'}
                  </Text>
                  <br />
                  <Text>{this.props.bankDepositToValidate.folioDeposit}</Text>
                </Col>

                <Col span={11} offset={1}>
                  <p />
                  <Text strong style={{ color: '#113C71' }}>
                    {'Fecha de pago'}
                  </Text>
                  <br />
                  <Text>
                    {moment(
                      this.props.bankDepositToValidate.depositDate,
                    ).format('YYYY/MM/DD')}
                  </Text>
                </Col>
                <Col span={23} offset={1}>
                  <p />
                  <Text strong style={{ color: '#113C71' }}>
                    {'Comentarios'}
                  </Text>
                  <br />
                  <Text>{this.props.bankDepositToValidate.comments}</Text>
                </Col>
                <Col span={23} offset={1}>
                  {!this.props.validar && (
                    <Col>
                      <Text strong style={{ color: '#113C71' }}>
                        {'Motivo de rechazo'}
                      </Text>
                      <br />
                      <TextArea
                        rows={4}
                        maxLength={490}
                        onChange={(e): void =>
                          this.props.onCangeText(e.target.value)
                        }
                      />
                    </Col>
                  )}
                </Col>
              </Col>
              <Col span={1}>
                <Divider type={'vertical'} style={{ height: '350px' }} />
              </Col>
              <Col span={11}>{this.footerTable()}</Col>
            </Row>
          )}
        </Row>
      </Modal>
    );
  };
}
export default ValidateDepositModel;


/* let formatCurrency = (value: number, { unvalidReturn = 'Monto inválido' } = {}): string => {
  if(isNaN(value)) return unvalidReturn;
  return `$ ${(+value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
}; */

type DifferenceSectionProps = { bankDepositId: string, modalProps: CounterProps };
const DifferenceSection = (props: DifferenceSectionProps) => {
  let { formatCurrency } = Globals.Utils.Number;

  let [ isLoading, setIsLoading ] = useState(true);
  let [ isError, setIsError ] = useState(false);
  let [ depositDifferences, setDepositDifferences ] = useState([] as DbOldTypes.DepositDifference[]);

  useEffect(() => {
    console.log(`Getting deposit differences for bank deposit id: '${props.bankDepositId}'`);
    callApi(ApiRoute.GetDepositDifferences, { filters: { bankDepositId: props.bankDepositId } }).then(result => {
      if(!result || !result.success){
        setIsError(true);
        return;
      }
      setDepositDifferences(result.depositDifferences);
      ApiVarStorage.set('depositDifferences', result.depositDifferences);
      setTimeout(() => { setIsLoading(false) }, 600);
    });
  }, [ props.bankDepositId ]);

  if(isError) return (
    <div>
      <h1 style={{ marginBottom: 0 }} > <small> Diferencias </small> </h1>
      <div style={{ marginTop: 20, textAlign: 'center' }} >
        <span> Error obteniendo las diferencias. </span>
      </div>
    </div>
  );

  if(isLoading) return (
    <div>
      <h1 style={{ marginBottom: 0 }} > <small> Diferencias </small> </h1>
      <div style={{ marginTop: 20, textAlign: 'center' }} >
        <Spin size="large" />
      </div>
    </div>
  );

  return (
    <Row>
      <h1 style={{ marginBottom: 0 }} > <small> Diferencias </small> </h1>
      <table style={{ width: '100%' }} >
        <thead style={{ borderBottom: '2px solid black' }} >
          <tr>
            <th style={ {} } >
              <Text strong > Método de pago </Text>
              {/* <div className="ant-row ant-form-item" style={{ marginBottom: 0, paddingBottom: 0 }} >
                <label htmlFor="paymentWay" className="" > Método de pago: </label>
              </div> */}
            </th>
            <th style={ {} } >
              <Text strong > Monto </Text>
            </th>
            <th style={ { textAlign: 'center' } } >
              <Text strong > Estatus </Text>
            </th>
          </tr>
        </thead>
        <tbody>
          { depositDifferences.map((depositDifference, index) => {
            let statusDetail = DbOldDetails.depositDifferenceStatus[depositDifference.status_key];
            return (
              <tr key={ `row_${index}` } >
                <td style={ {} } > { depositDifference.other_payment_way || DbOldDetails.salePaymentWay[depositDifference.payment_way].name } </td>
                <td style={ { textAlign: 'right', paddingRight: 36 } } > { formatCurrency(depositDifference.amount) } </td>
                <td style={ { textAlign: 'center' } } >
                  <span className={ `ant-tag ant-tag-${statusDetail.tagColor}` } style={ { marginRight: 0 } } > { statusDetail.name } </span>
                  {/* <Tag color={ statusDetail.tagColor } >
                    { statusDetail.name }
                  </Tag> */}
                </td>
              </tr>
            );
          }) }
        </tbody>
      </table>
    </Row>
  );
};