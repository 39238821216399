/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Ricardo Sansores <ricardo@ssf.mx>, May 2019
 *
 * This file includes all the possible actions that can be
 * dispached to an invoice reducer.
 */

import { Sale, InvoicingPortalFiscalInfo } from 'types/type';

/*
 * Action to add a sale to the staging area
 */
export const ADD_SALE_TO_STAGING_AREA = 'ADD_SALE_TO_STAGING_AREA';

/*
 * Action to remove a sale in the staging area
 */
export const REMOVE_SALE_FROM_STAGING_AREA = 'REMOVE_SALE_FROM_STAGING_AREA';

/*
 * Action clean the staging area
 */
export const CLEAN_STAGING_AREA = 'CLEAN_STAGING_AREA';
/*
 * Begin a fetch operation action
 */
export const BEGIN_FETCH = 'BEGIN_FETCH';

/*
 * A fetch operation action have been completed
 */
export const FETCH_COMPLETED = 'FETCH_COMPLETED';

export const FETCH_IUS = 'FETCH_IUS';

//Action to save the Fiscal Data setted in the form
export const SET_FISCAL_DATA = 'SET_FISCAL_DATA';

/**
 * This action adds a Sale in the Staging area of invoices
 * The Staging area is a place where you can put one or many
 * sales just before issuing the invoice. To add a sale to the
 * Stagging area you provide a *IUS* (unique sale identifier)
 * and if the sale is found then is added to this stagin area
 * in the state.
 */
interface AddSaleToStagingAreaAction {
  type: typeof ADD_SALE_TO_STAGING_AREA;
  sale: Sale;
}
interface CleanStagingAreaAction {
  type: typeof CLEAN_STAGING_AREA;
  sale: Sale | null;
}
interface RemoveSaleFromStagingAreaAction {
  type: typeof REMOVE_SALE_FROM_STAGING_AREA;
  sale: Sale;
}

interface SetFiscalDataAction {
  type: typeof SET_FISCAL_DATA;
  fiscalInformation: InvoicingPortalFiscalInfo;
}

/**
 * This action is used just before requesting something to
 * the backend and sets in the state the isFetchingProperty
 * to true. This is useful to add loading indicators.
 */
interface BeginFetchAction {
  type: typeof BEGIN_FETCH;
}

/**
 * Just like [[BeginFetchAction]] EndFetchAction is used to
 * indicate that a call to the backend or a slow action have been
 * completed. After dispatching this action the isFetching state
 * property should change to false. *Don't forget to call this after*
 * *BeginFetch or the loading indicator could stay forever*
 * *be careful specially when the action fails with an exception*
 */
interface EndFetchAction {
  type: typeof FETCH_COMPLETED;
}
export interface AddIusAction {
  type: typeof FETCH_IUS;
  ius: string;
}

export type InvoiceAction =
  | EndFetchAction
  | BeginFetchAction
  | AddSaleToStagingAreaAction
  | CleanStagingAreaAction
  | RemoveSaleFromStagingAreaAction
  | SetFiscalDataAction;

export type IusAction = AddIusAction;
