/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Hiram Padilla <hiram@ssf.com.mx>, February 2021
 *
 * This component shows a table with the information of cylinder settlements & sales
 */
import {
  Button,
  Col,
  DatePicker,
  Form,
  Icon,
  Row,
  Select,
  Table,
  Tooltip,
} from 'antd';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/configureStore';
import {
  CylinderEquipment,
  CylinderFilters,
  CylinderSale,
  CylinderSettlement,
  DistributionCenter,
  Price,
} from 'types/type';
import { CylinderSettlementList } from './redux/reducers';
import {
  getSettlementsPage,
  save,
  getDistributionCenters,
  getCylinderSales,
  getCylinderEquipments,
  getPrices,
  saveSales,
} from './redux/actions';
import CylindersFormComponent from './components/formModal';
import { FormComponentProps } from 'antd/lib/form';
import { responsiveList } from './components/responsiveList';
import moment, { Moment } from 'moment';
import { Popups } from 'commons/components/popups/popups';
import Text from 'antd/lib/typography/Text';
import { isDateWrong } from 'routes/functions';
import { DATE_FORMAT, getEndTime, getInitialTime } from 'commons/services';
import { getToken, isAllowed, Token } from 'security';
import { Permissions as P } from '../../commons/permissions';
import JwtDecode from 'jwt-decode';
import { ApiRoute, ApiTypes, callApi } from 'commons/services/api';
import { Globals } from 'commons/globals';
import { DbOldDetails, DbOldTypes, DbOldValues } from 'database';

interface StateProps {
  settlements: CylinderSettlementList;
  sales: CylinderSale[];
  isFetching: boolean;
  distributionCenters: DistributionCenter[];
  equipments: CylinderEquipment[];
  prices: Price[];
  cylinderFilters: CylinderFilters;
}

interface DispatchProps {
  getSettlementsPage: (filters: CylinderFilters) => void;
  save: (model: CylinderModel) => void;
  getDistributionCenters: () => void;
  getCylinderSales: (settlementId: string) => void;
  getCylinderEquipments: () => void;
  getPrices: () => void;
  saveSales: (sales: CylinderSale[], settlementId: string) => void;
}

export interface CylinderModel {
  cylinderSettlement: CylinderSettlement;
  cylinderSales: CylinderSale[];
}

type Props = StateProps & DispatchProps & FormComponentProps;
class CylindersComponent extends Component<Props> {
  public state = {
    isFormModalVisible: false,
    expandedRows: [],
    currentPage: 1,
    pageSize: 25,
    currentSettlement: undefined,
    //
    cers: [] as DbOldTypes.CylinderEquipmentRoute[],
    sales: [] as ApiTypes.CylinderVirtualSale[],
  };

  //Get distribution centers from token to set them to filters when user has no permission
  private tokenDistrCenter = JwtDecode<Token>(getToken() as string)
    .distributionCenterId;
  private tokenAssignedDistrCenters: string[] = JwtDecode<Token>(
    getToken() as string,
  ).assingedDistrCenters;

  public componentDidMount(): void {
    const fetchData = async (): Promise<void> => {
      await this.fetchVirtualSales();
      //await this.props.getSettlementsPage(this.props.cylinderFilters);
      await this.props.getDistributionCenters();
      await this.props.getCylinderEquipments();
      await this.props.getPrices();
    };
    fetchData();
  }

  private async fetchVirtualSales(){
    console.log(this.props.cylinderFilters);
    let { initDate, endDate, distributionCenterId, routeEquipmentId } = this.props.cylinderFilters;
    const dateRange = [ initDate, endDate ].map(dateIso => {
      return Globals.Utils.Date.dateToString({ date: dateIso ? new Date(dateIso) : undefined });
    }) as [ string, string ];
    let result = await callApi(ApiRoute.GetVirtualCylinderSales, { dateRange, distCenterId: distributionCenterId as DbOldValues._DistCenterId, cerId: routeEquipmentId });
    console.log(result);
    if(result && result.success && 'sales' in result){
      this.setState({ cers: result.cers, sales: result.sales });
    }else{
      Popups.notifyError((result && result.msg) || `Èrror obteniendo datos.`);
    }
  }

  private onCurrentPageChange = (currentPage: number): void => {
    /* this.props.getSettlementsPage({
      ...this.props.cylinderFilters,
      currentPage,
    }); */
  };

  private onPageSizeChange = (currentPage: number, pageSize?: number): void => {
    /* this.props.getSettlementsPage({
      ...this.props.cylinderFilters,
      currentPage,
      pageSize: pageSize || 25,
    }); */
  };

  private closeFormModal = (): void => {
    this.setState({ isFormModalVisible: false });
  };

  private saveCylinderSettlement = (): void => {
    //Validate data and save it, then update the table
    this.props.form.validateFields(
      async (errors, values: CylinderModel): Promise<void> => {
        if (!errors) {
          //Validate cylinders quantity is not zero
          const quantity = values.cylinderSales.reduce(
            (accumulator: number, sale: CylinderSale): number => {
              return accumulator + Number(sale.numberOfCylinders);
            },
            0,
          );
          if (quantity <= 0) {
            Popups.notifyError('Introduzca almenos una cantidad');
            return;
          }
          //Save only sales when adding sales to a settlement
          const { currentSettlement } = this.state;
          if (currentSettlement !== undefined) {
            const sales = values.cylinderSales.map(
              (sale: CylinderSale): CylinderSale => {
                sale.createdAt = values.cylinderSettlement.movementDate;
                sale.movementDate = values.cylinderSettlement.movementDate;
                return sale;
              },
            );
            const settlementId = values.cylinderSettlement.id;
            await this.props.saveSales(sales, settlementId);
            if (this.state.expandedRows.length > 0)
              await this.props.getCylinderSales(this.state.expandedRows[0]);
            await this.props.getSettlementsPage(this.props.cylinderFilters);
            this.setState({ isFormModalVisible: false });
            return;
          }
          //Using await for more than one async functions
          await this.props.save(values);
          this.props.form.resetFields();
          await this.props.getSettlementsPage(this.props.cylinderFilters);
          this.setState({ isFormModalVisible: false });
        }
      },
    );
  };

  /* This function renders a form in a Modal to register a cylinder settlement & sales */
  private formModal = (): ReactNode => {
    return (
      <Row>
        <CylindersFormComponent
          isVisible={this.state.isFormModalVisible}
          onCancel={(): Function => this.closeFormModal}
          onCreate={ async () => {
            /* this.saveCylinderSettlement */
            this.setState({ isFormModalVisible: false });
            this.fetchVirtualSales();
          } }
          isFetching={this.props.isFetching}
          form={this.props.form}
          distributionCenters={this.props.distributionCenters}
          equipments={this.props.equipments}
          cers={ this.state.cers }
          prices={this.props.prices}
          currentSettlement={this.state.currentSettlement}
        />
      </Row>
    );
  };

  private onFiltersChange = (filters: CylinderFilters): void => {
    this.fetchVirtualSales();
    //this.props.getSettlementsPage(filters);
  };

  /* This function renders filters for cylinders */
  private filters = (cylinderFilters: CylinderFilters): ReactNode => {
    const { isFetching } = this.props;
    //Get assigned distribution centers
    const assignedDistrCenters =
      this.tokenAssignedDistrCenters &&
      this.tokenAssignedDistrCenters.length > 0
        ? this.tokenAssignedDistrCenters
        : [this.tokenDistrCenter];
    return (
      <div>
        <Col xs={24} sm={24} md={6} lg={5} xl={8} xxl={6} className="filters">
          <div>
            <Text strong className={'filters-text-label'}>
              {'Filtrar por fecha'}
            </Text>
          </div>
          <Col xs={24} sm={12} md={24} lg={24} xl={12} xxl={12}>
            <DatePicker
              style={{ width: '100%' }}
              disabled={isFetching}
              disabledDate={(date: moment.Moment | undefined): boolean =>
                isDateWrong(date, moment(cylinderFilters.endDate))
              }
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
              format={DATE_FORMAT}
              defaultValue={moment(getInitialTime())}
              placeholder="Desde"
              value={
                cylinderFilters.initDate
                  ? moment(cylinderFilters.initDate)
                  : getInitialTime()
              }
              onChange={(startDate: Moment | null): void => {
                cylinderFilters.initDate = startDate
                  ? startDate.toISOString()
                  : getInitialTime().toISOString();
                this.onFiltersChange(cylinderFilters);
              }}
            />
          </Col>
          <Col xs={24} sm={12} md={24} lg={24} xl={12} xxl={12}>
            <DatePicker
              style={{ width: '100%' }}
              disabledDate={(date: moment.Moment | undefined): boolean =>
                isDateWrong(moment(cylinderFilters.initDate), date)
              }
              format={DATE_FORMAT}
              value={
                cylinderFilters.endDate
                  ? moment(cylinderFilters.endDate)
                  : getEndTime()
              }
              placeholder="Hasta"
              onChange={(endDate: Moment | null): void => {
                cylinderFilters.endDate = endDate
                  ? endDate.toISOString()
                  : getEndTime().toISOString();
                this.onFiltersChange(cylinderFilters);
              }}
              disabled={isFetching}
            />
          </Col>
        </Col>
        <Col xs={24} sm={12} md={7} lg={6} xl={5} xxl={4} className="filters">
          <div>
            <Text strong className="filters-text-label">
              {'Centro de distribución'}
            </Text>
          </div>
          {
            <Select
              style={{ width: '100%' }}
              disabled={isFetching}
              defaultValue={''}
              value={cylinderFilters.distributionCenterId}
              onChange={(event: string): void => {
                cylinderFilters.distributionCenterId = event;
                this.onFiltersChange(cylinderFilters);
              }}
            >
              <Select.Option value="">{'Todos'}</Select.Option>
              {//When user has permission, show all options
              isAllowed([P.CAN_VIEW_ALL_DISTRIBUTION_CENTERS]) &&
                this.props.distributionCenters &&
                this.props.distributionCenters.map(
                  (center: DistributionCenter): JSX.Element => (
                    <Select.Option value={center.id} key={center.id}>
                      {center.name}
                    </Select.Option>
                  ),
                )}
              {//When user HAS NO permission, show the options with the assigned distr centers information
              !isAllowed([P.CAN_VIEW_ALL_DISTRIBUTION_CENTERS]) &&
                this.props.distributionCenters &&
                assignedDistrCenters.map(
                  (id: string, index: number): JSX.Element => {
                    const distrCenter = this.props.distributionCenters.find(
                      (center: DistributionCenter): boolean => center.id === id,
                    );
                    return distrCenter ? (
                      <Select.Option value={distrCenter.id} key={index}>
                        {distrCenter.name}
                      </Select.Option>
                    ) : (
                      <div key={index} />
                    );
                  },
                )}
            </Select>
          }
        </Col>
        <Col xs={24} sm={12} md={6} lg={4} xl={3} xxl={3} className="filters">
          <div>
            <Text strong className="filters-text-label">
              {'Ruta'}
            </Text>
          </div>
          <Select
            style={{ width: '100%' }}
            disabled={isFetching}
            defaultValue={''}
            value={cylinderFilters.routeEquipmentId}
            onChange={(event: string): void => {
              cylinderFilters.routeEquipmentId = event;
              this.onFiltersChange(cylinderFilters);
            }}
          >
            <Select.Option value="">{'Todas'}</Select.Option>
            {//When user has permission, show all options
            /* isAllowed([P.CAN_VIEW_ALL_DISTRIBUTION_CENTERS]) &&  */this.state.cers.map(cer => (
              <Select.Option key={ cer.id } value={ cer.id } > { cer.tag } </Select.Option>
            ))
              /* this.props.equipments.map(
                (equipment: CylinderEquipment): JSX.Element => (
                  <Select.Option value={equipment.id} key={equipment.id}>
                    {equipment.tag}
                  </Select.Option>
                ),
              ) */}
            {//When user HAS NO permission, show the options of the assigned distr centers
            /* !isAllowed([P.CAN_VIEW_ALL_DISTRIBUTION_CENTERS]) &&
              this.props.distributionCenters && this.state.cers.filter(cer => {
                return assignedDistrCenters.includes(cer.id);
              }).map(cer => (
                <Select.Option key={ cer.id } value={ cer.id } > { cer.tag } </Select.Option>
              )) */
              /* assignedDistrCenters.map(
                (id: string, index: number): JSX.Element => {
                  const equipment = this.props.equipments.find(
                    (equip: CylinderEquipment): boolean =>
                      equip.distributionCenterId === id,
                  );
                  return equipment ? (
                    <Select.Option value={equipment.id} key={index}>
                      {equipment.tag}
                    </Select.Option>
                  ) : (
                    <div key={index} />
                  );
                },
              ) */}
          </Select>
        </Col>
        <Col
          xs={12}
          sm={8}
          md={5}
          lg={5}
          xl={4}
          xxl={4}
          style={{ float: 'right' }}
          className="filters"
        >
          <Button
            type="primary"
            onClick={(): void =>
              this.setState({
                isFormModalVisible: true,
                currentSettlement: undefined,
              })
            }
            disabled={isFetching}
          >
            {'Registrar venta'}
          </Button>
        </Col>
      </div>
    );
  };

  public render = (): React.ReactNode => {
    const { settlements, isFetching, distributionCenters } = this.props;
    const { cylinderFilters } = this.props;
    return (
      <div className="content-background">
        <Row>
          <Row style={{ paddingBottom: '10px' }}>
            {this.filters(cylinderFilters)}
          </Row>
          {this.state.isFormModalVisible && this.formModal()}
          <Row>
            <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
              {responsiveList(settlements, distributionCenters)}
            </Col>
            <Col
              xs={0}
              sm={0}
              md={0}
              lg={24}
              xl={24}
              xxl={24}
              style={{ backgroundColor: 'white', padding: '40px 20px' }}
            >
              <Table
                /* onExpand={(
                  expand: boolean,
                  record: CylinderSettlement,
                ): void => {
                  //When a settlement row is expanded, search the sales of it
                  if (expand) {
                    this.props.getCylinderSales(record.id);
                    this.setState({
                      expandedRows: [record.id],
                    });
                  } else
                    this.setState({
                      expandedRows: [],
                    });
                }}
                expandedRowKeys={this.state.expandedRows} */
                dataSource={ this.state.sales /* settlements.content */ }
                pagination={ { pageSize: 25 } }
                loading={isFetching}
                rowKey={(record): string => record.id}
                /* expandedRowRender={(): React.ReactNode => (
                  <Table
                    className={'custom-table'}
                    dataSource={this.props.sales}
                    pagination={false}
                    columns={salesTableColumns()}
                  />
                )} */
              >
                <Table.Column
                  title="Fecha"
                  dataIndex="end_date"
                  render={ (_, rec: ApiTypes.CylinderVirtualSale) => Globals.Utils.Date.dateToString({ date: new Date(rec.end_date) }) }
                  /* render={(movementDate: Moment): React.ReactNode => {
                    return moment(movementDate).format('YYYY-MM-DD HH:mm:ss');
                  }} */
                />
                <Table.Column
                  title="Folio"
                  dataIndex="sales_folio"
                  render={ (_, rec: ApiTypes.CylinderVirtualSale) => rec.sales_folio }
                  /* render={(movementDate: Moment): React.ReactNode => {
                    return moment(movementDate).format('YYYY-MM-DD HH:mm:ss');
                  }} */
                />
                <Table.Column
                  title="Corte"
                  dataIndex="cutFolio"
                  render={ (_, rec: ApiTypes.CylinderVirtualSale) => rec.cutFolio || 'Pendiente' }
                  /* render={(movementDate: Moment): React.ReactNode => {
                    return moment(movementDate).format('YYYY-MM-DD HH:mm:ss');
                  }} */
                />
                <Table.Column title="Ruta" dataIndex="cerTag" />
                <Table.Column
                  title="Volumen litros"
                  dataIndex="volume"
                  render={ (_, rec: ApiTypes.CylinderVirtualSale) => `${rec.total_volume_c || rec.total_volume}` }
                  /* render={(totalVolume: number): React.ReactNode => {
                    return toNumber(totalVolume);
                  }} */
                />
                <Table.Column
                  title="Total"
                  dataIndex="total"
                  render={ (_, rec: ApiTypes.CylinderVirtualSale) => `$${rec.total_amount_c || rec.total}` }
                  /* render={(total: number): React.ReactNode => {
                    return '$' + toNumber(total);
                  }} */
                />
                <Table.Column
                  title="Centro de distribución"
                  dataIndex="distributionCenterId"
                  render={ (_, rec: ApiTypes.CylinderVirtualSale) => DbOldDetails.getDistCenterId(rec.dist_center_id).name }
                  /* render={(distributionCenterId: string, rec: ApiTypes.CylinderVirtualSale): React.ReactNode => {
                    //Find distribution center in the list or set an initialized if not found
                    const distrCenter: DistributionCenter =
                      this.props.distributionCenters.find(
                        (center: DistributionCenter): boolean =>
                          center.id === distributionCenterId,
                      ) || initDistributionCenter;
                    return distrCenter.name;
                  }} */
                />
                <Table.Column
                  title="Acciones"
                  dataIndex="id"
                  render={(
                    id: string,
                    record: ApiTypes.CylinderVirtualSale,
                  ): ReactNode => (
                    <>
                    <Tooltip title="Detalles">
                      <Icon
                        type="eye"
                        onClick={ async () => {
                          if(!record.cylinder_snapshot) return alert("No hay detalles.");
                          let { units, density, discount } = record.cylinder_snapshot;
                          let unitDetails = units.map(unit => `    ${unit.kg}kg: ${unit.quantity} a $${unit.price}`);
                          alert(`Densidad: ${density}\nDescuento: $${discount}\nUnidades:\n${unitDetails.join('\n')}`);
                        }
                          /* this.setState({
                            isFormModalVisible: true,
                            currentSettlement: record,
                          }) */
                        }
                        theme="twoTone"
                        style={{ fontSize: '22px' }}
                      />
                    </Tooltip>
                    <Tooltip title="Eliminar venta">
                      <Icon
                        type="delete"
                        onClick={ async () => {
                          let confirmed = Globals.Utils.Prompt.confirm(`¿Eliminar venta ${record.sales_folio}?`);
                          if(!confirmed) return;
                          let result = await callApi(ApiRoute.DeleteVirtualCylinderSale, { saleId: record.id });
                          console.log(result);
                          if(!result || !result.success) Popups.notifyError((result && result.msg) || 'Error eliminando venta.');
                          await this.fetchVirtualSales();
                        }
                          /* this.setState({
                            isFormModalVisible: true,
                            currentSettlement: record,
                          }) */
                        }
                        theme="twoTone"
                        style={{ fontSize: '22px' }}
                      />
                    </Tooltip>
                    </>
                  )}
                />
              </Table>
            </Col>
            {/* <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Pagination
                showSizeChanger
                pageSizeOptions={['25', '50', '75', '100']}
                defaultPageSize={25}
                disabled={isFetching}
                current={(this.props.settlements.number || 0) + 1}
                total={this.props.settlements.totalElements}
                onShowSizeChange={this.onPageSizeChange}
                onChange={this.onCurrentPageChange}
              />
            </Col> */}
          </Row>
        </Row>
      </div>
    );
  };
}

const mapStateToProps = (states: RootState): StateProps => {
  return {
    settlements: states.cylindersState.settlements,
    sales: Object.values(states.cylindersState.sales),
    isFetching: states.cylindersState.isFetching,
    distributionCenters: Object.values(
      states.cylindersState.distributionCenters,
    ),
    equipments: Object.values(states.cylindersState.equipments),
    prices: Object.values(states.cylindersState.prices),
    cylinderFilters: states.cylindersState.cylinderFilters,
  };
};

const mapDispatchToProps: DispatchProps = {
  getSettlementsPage,
  save,
  getDistributionCenters,
  getCylinderSales,
  getCylinderEquipments,
  getPrices,
  saveSales,
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create()(CylindersComponent));
