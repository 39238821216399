/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by David Canul <david@ssf.com.mx>, jul 2019
 *
 *
 */
import { Statement, CustomerAndUDR } from 'types/type';

/*
 * Action to add a Statement to list component
 */
export const SET_STATEMENTS = 'SET_STATEMENTS';
export const SET_SEARCH_STATEMENTS = 'SET_SEARCH_STATEMENTS';
export const SET_STATEMENTS_STATUS = 'SET_STATEMENTS_STATUS';
export const SET_STATEMENT = 'SET_STATEMENT';
export const SET_STATEMENTS_LIST = 'SET_STATEMENTS_LIST';
export const SET_STATEMENT_SALES = 'SET_STATEMENT_SALES';
export const RESET_STATEMENT_SALES = 'RESET_STATEMENT_SALES';
export const SET_CUSTOMER_LIST = 'SET_CUSTOMER_LIST';
export const SET_SINGLE_STATEMENT_SALE = 'SET_SINGLE_STATEMENT_SALE';
export const RESET_SINGLE_STATEMENT_SALE = 'RESET_SINGLE_STATEMENT_SALE';

/*
 * Begin a fetch operation action
 */
export const BEGIN_FETCH = 'BEGIN_FETCH';

/*
 * A fetch operation action have been completed
 */
export const FETCH_COMPLETED = 'FETCH_COMPLETED';

interface BeginFetchAction {
  type: typeof BEGIN_FETCH;
}
interface SetAction {
  type: typeof SET_STATEMENTS_LIST;
  statements: Statement;
}
interface SetStatementsAction {
  type: typeof SET_STATEMENTS;
  statementList: {};
}
interface SetStatementsSearchAction {
  type: typeof SET_SEARCH_STATEMENTS;
  statementSearchList: {};
}
interface SetStatementStatusAction {
  type: typeof SET_STATEMENTS_STATUS;
  acountStatus: {};
}
interface SetStatementAction {
  type: typeof SET_STATEMENT;
  payment: {};
}

interface EndFetchAction {
  type: typeof FETCH_COMPLETED;
}

interface SetStatementSalesAction {
  type: typeof SET_STATEMENT_SALES;
  statementSales: {};
}

interface ResetStatementSales {
  type: typeof RESET_STATEMENT_SALES;
}
interface CustomerListAction {
  type: typeof SET_CUSTOMER_LIST;
  customersList: CustomerAndUDR[];
}

interface SetSingleStatementSale {
  type: typeof SET_SINGLE_STATEMENT_SALE;
  singleStatementSale: {};
}

interface ResetSingleStatementSale {
  type: typeof RESET_SINGLE_STATEMENT_SALE;
}

export type StatementAction =
  | SetStatementStatusAction
  | SetStatementsAction
  | EndFetchAction
  | BeginFetchAction
  | SetAction
  | SetStatementsSearchAction
  | SetStatementAction
  | SetStatementSalesAction
  | ResetStatementSales
  | CustomerListAction
  | SetSingleStatementSale
  | ResetSingleStatementSale;
