/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by David Canul <david@ssf.com.mx>, Jun 2019
 *
 *
 */

import { combineReducers, Reducer } from 'redux';
import {
  Sale,
  DashboardValues,
  ModelBankTotals,
  BankDeposit,
  DistributionCenter,
  Settlement,
  SettlementDepositModal,
  FilterBankDeposit,
  Clarification,
} from 'types/type';
import { RootState } from 'store/configureStore';
import {
  BankDepositAction,
  BEGIN_FETCH,
  FETCH_COMPLETED,
  SET_BANK_DEPOSIT_LIST,
  SET_TOTAL_BANK_DEPOSIT,
  SET_BANK_DEPOSIT,
  SET_DISTRIBUTION_CENTER,
  SET_SETTLEMENT_LIST,
  SET_SALES_LIST,
  RESET_SALES_LIST,
  RESET_SETTLEMENT_LIST,
  RESET_SETTLEMENT__MODAL_LIST,
  SET_SETTLEMENT_MODAL_LIST,
  SET_BANK_DPOSIT_FILTERS,
  SET_CLARIFICATIONS_HISTORY,
} from './types';
import {
  intBankDepositValue,
  initBankDeposit,
  initSettlement,
  initSale,
  initSettlementDepositModal,
  initBankDepositFilter,
} from 'commons/initTypes';

export interface BankDepositI {
  sale: BankDeposit;
}
export interface BankDepositList {
  content?: BankDeposit[];
  number?: number;
  totalElements?: number;
}
export interface DistributionCenterI {
  distributionCenter: DistributionCenter[];
}
export interface DashboardValue {
  dashboardValue: DashboardValues;
}

export interface BankDepositState {
  readonly settlementList: Settlement[];
  readonly settlementModalList: SettlementDepositModal;
  readonly salesList: Sale[];
  readonly bankDepositList: BankDepositList;
  readonly isFetching: boolean;
  readonly dashboardValue: ModelBankTotals;
  readonly bankDepositToApply: BankDeposit;
  readonly distributionCenter: DistributionCenterI;
  readonly bankDepositSave: BankDeposit;
  readonly bankDepositFilters: FilterBankDeposit;
  readonly clarifications: Clarification[];
}

const bankDepositList: Reducer<BankDepositList> = (
  state: BankDepositList = { content: [] },
  action,
): BankDepositList => {
  if (action.type === SET_BANK_DEPOSIT_LIST) {
    return { ...state, ...action.bankDeposit };
  }
  return state;
};

const dashboardValue: Reducer<ModelBankTotals> = (
  state: ModelBankTotals = intBankDepositValue,
  action,
): ModelBankTotals => {
  if (action.type === SET_TOTAL_BANK_DEPOSIT) {
    return { ...state, ...action.depositValues };
  }
  return state;
};
const bankDepositToApply: Reducer<BankDeposit> = (
  state: BankDeposit = initBankDeposit,
  action,
): BankDeposit => {
  if (action.type === SET_BANK_DEPOSIT) {
    return { ...state, ...action.settlement };
  }
  return state;
};

const isFetching: Reducer<boolean> = (
  state = false,
  action: BankDepositAction,
): boolean => {
  switch (action.type) {
    case BEGIN_FETCH:
      return true;
    case FETCH_COMPLETED:
      return false;
    default:
      return state;
  }
};
const distributionCenter: Reducer<DistributionCenterI> = (
  state: DistributionCenterI = { distributionCenter: [] },
  action,
): DistributionCenterI => {
  if (action.type === SET_DISTRIBUTION_CENTER) {
    return { ...state, distributionCenter: action.distributionCenter };
  }
  return state;
};
const settlementList: Reducer<Settlement[]> = (
  state: Settlement[] = [initSettlement],
  action,
): Settlement[] => {
  switch (action.type) {
    case SET_SETTLEMENT_LIST:
      return { ...state, ...action.settlementList };
    case RESET_SETTLEMENT_LIST:
      return [initSettlement];
    default:
      return state;
  }
};
const settlementModalList: Reducer<SettlementDepositModal> = (
  state: SettlementDepositModal = initSettlementDepositModal,
  action,
): SettlementDepositModal => {
  switch (action.type) {
    case SET_SETTLEMENT_MODAL_LIST:
      return { ...state, ...action.settlementModalList };
    case RESET_SETTLEMENT__MODAL_LIST:
      return initSettlementDepositModal;
    default:
      return state;
  }
};
const salesList: Reducer<Sale[]> = (
  state: Sale[] = [initSale],
  action,
): Sale[] => {
  switch (action.type) {
    case SET_SALES_LIST:
      return { ...state, ...action.salesList };
    case RESET_SALES_LIST:
      return [initSale];
    default:
      return state;
  }
};

const bankDepositSave: Reducer<BankDeposit> = (
  state: BankDeposit = initBankDeposit,
  action,
): BankDeposit => {
  if (action.type === SET_BANK_DEPOSIT) {
    return { ...state, ...action };
  }
  return state;
};

export const bankDepositFilters: Reducer<FilterBankDeposit> = (
  state: FilterBankDeposit = { ...initBankDepositFilter },
  action,
): FilterBankDeposit => {
  if (action.type === SET_BANK_DPOSIT_FILTERS) {
    return { ...state, ...action.bankDepositFilters };
  }
  return state;
};

export const clarifications: Reducer<Clarification[]> = (
  state: Clarification[] = [],
  action,
): Clarification[] => {
  if (action.type === SET_CLARIFICATIONS_HISTORY)
    return { ...state, ...action.clarifications };
  return state;
};

export const getIsFetching = (store: RootState): boolean =>
  store.invoiceState.isFetching;

export default combineReducers<BankDepositState>({
  bankDepositFilters,
  distributionCenter,
  bankDepositList: bankDepositList,
  dashboardValue: dashboardValue,
  bankDepositToApply: bankDepositToApply,
  bankDepositSave,
  settlementList,
  settlementModalList,
  salesList,
  clarifications,
  isFetching,
});
