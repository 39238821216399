const distributionsCenters = [
  {
    id: '94e058cc-f074-4471-9fc6-6afbf5da535d',
    name: 'Central de Gas del Bajío',
  },
  {
    id: '37a91121-f679-47ef-81cd-fe3310ffb12f',
    name: 'Estaciones AGS.',
  },
  {
    id: '43ba830d-f619-4ee0-bc8e-99fd226e7ec1',
    name: 'Pabellón',
  },
  {
    id: 'f6e58e7d-dc71-45a5-a555-5e227cff93e3',
    name: 'Guayana',
  },
  {
    id: '0ecb5291-c4ce-4a2d-bd29-1f8658b8686a',
    name: 'Carretera Ags-Loreto km 1.9',
  },
  {
    id: '1879d5d0-37b4-4e65-9333-c51c1e73ae5e',
    name: 'Central de Gas',
  },
  {
    id: '36250eef-f188-4082-8b02-cd964597b9d9',
    name: 'Carretera Guayana 1.3 Nombre largo',
  },
  {
    id: '83d6222d-89ac-46a6-b10b-9653ec6b0ac7',
    name: 'Planta Pabellón Obed Pueblo Caballo',
  },
];

export const getDistCenterName = (id: string): string => {
  const distCenter = distributionsCenters.find(
    (dc: { id: string; name: string }): boolean => {
      return dc.id === id;
    },
  );
  if (distCenter) return distCenter.name;
  return id;
};
