/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by David Canul <david@ssf.com.mx>, Jun 2019
 *
 * This component lists all the payments
 */
import React, { Component } from 'react';
import JwtDecode from 'jwt-decode';
import {
  Row,
  List,
  Pagination,
  Col,
  Icon,
  Table,
  Divider,
  Button,
  Popconfirm,
  Input,
  DatePicker,
  Select,
  Checkbox,
  Tooltip,
} from 'antd';
import {
  Payment,
  PaymentFilters,
  DistributionCenter,
  Company,
} from 'types/type';
import { connect } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { PaymentList } from './redux/reducers';
import {
  getPayments,
  handleBankChange,
  applyPayment,
  refusePayment,
  downloadPaymentsReport,
  downloadDetailedPaymentsReport,
  page,
  downloadTaskitEvidence,
  downloadZip,
  changeFilters,
} from './redux/actions';
import moment, { Moment } from 'moment';
import Text from 'antd/lib/typography/Text';
import { Token, getToken, getCustomerCode } from 'security/index';
import { toNumber } from 'commons/numbersFormat';
import { isAllowed } from 'security';
import { Permissions as permissions } from '../../commons/permissions';
import { getDistanceBetweenLocations, isDateWrong } from 'routes/functions';
import { banks } from 'commons/bankCatalog';
import './style.css';
import { DATE_FORMAT, getEndTime, getInitialTime } from 'commons/services';
import {
  initCustomer,
  initDistributionCenter,
  initPayment,
} from 'commons/initTypes';
import { formaPago } from 'commons/components/regimenFiscal';
import LocationModal from './components/locationModal';

interface DispatchProps {
  getPayments: (PaymentFilters: PaymentFilters) => void;
  handleBankChange: (paymentId: string, bank: string) => void;
  applyPayment: (
    id: string,
    idUserApply: string,
    filters: PaymentFilters,
  ) => void;
  page: (filter: PaymentFilters) => void;
  refusePayment: (paymentId: string, paymentFilters: PaymentFilters) => void;
  downloadPaymentsReport: (paymentFilters: PaymentFilters) => void;
  downloadDetailedPaymentsReport: (paymentFilters: PaymentFilters) => void;
  downloadTaskitEvidence: (evidenceId: string) => void;
  downloadZip: (filters: PaymentFilters) => void;
  changeFilters: (paymentFilters: PaymentFilters) => void;
}
interface StateProps {
  paymentPage: PaymentList;
  paymentFilters: PaymentFilters;
  isFetching: boolean;
  distributionCenter: DistributionCenter[];
  companyList: Company[];
}

type Props = StateProps & DispatchProps;

const rowsAllowed = 10000;

const sizeScreen = (value: number): {} => ({
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: value },
  lg: { span: value },
  xl: { span: value },
  xxl: { span: value },
});

class PaymentsListComponent extends Component<Props> {
  public state = {
    invoiceStatus: 'ALL',
    unitType: 'L',
    startValueDate: moment(),
    endValueDate: moment(),
    endOpen: false,
    distributionCenter: '',
    customerCode: '',
    amount: 0,
    isModalVisible: false,
    selectedCustomer: initCustomer,
    selectedPayment: initPayment,
  };

  //Get distribution centers from token to set them to filters when user has no permission
  private tokenDistrCenter = JwtDecode<Token>(getToken() as string)
    .distributionCenterId;
  private tokenAssignedDistrCenters: string[] = JwtDecode<Token>(
    getToken() as string,
  ).assingedDistrCenters;

  //Get user from token
  private tokenIdUser = JwtDecode<Token>(getToken() as string).id;

  public getByUserId = (e: any): void => {
    if (e.target.checked === true) {
      const idUserCreate = this.tokenIdUser;
      this.SendUserId(idUserCreate);
      this.props.paymentFilters.idUserCreate = idUserCreate;
    } else if (e.target.checked === false) {
      const idUserCreate = '';
      this.SendUserId(idUserCreate);
      this.props.paymentFilters.idUserCreate = idUserCreate;
    }
  };

  public SendUserId = (idUserCreate: string): void => {
    this.props.page({
      ...this.props.paymentFilters,
      idUserCreate,
    });
    this.setState({ idUserCreate });
  };

  /**
   * This function execute when the component is mounting and get the first page from
   * payments
   */
  public componentDidMount(): void {
    let tempPaymentFilters = this.props.paymentFilters;
    if (getCustomerCode()) {
      tempPaymentFilters = Object.assign({}, this.props.paymentFilters, {
        customerCode: getCustomerCode(),
      });
    }

    //Initialize paymentFilters
    this.props.getPayments(tempPaymentFilters);
    this.setState({
      startValueDate: getInitialTime(),
      endValueDate: getEndTime(),
    });
  }

  private handlePaginationChange = async (
    currentPage: number,
    pageSize?: number,
  ): Promise<void> => {
    let { paymentFilters } = this.props;
    paymentFilters.pageNumber = currentPage - 1;
    paymentFilters.pageSize = pageSize || 25;
    console.log('hola2');
    await this.onChangeFilters(paymentFilters);
    await this.props.getPayments(this.props.paymentFilters);
  };

  /**
   * This function render the elements for the filters
   */
  private renderFilters = (paymentFilters: PaymentFilters): JSX.Element => {
    const assignedDistrCenters =
      this.tokenAssignedDistrCenters.length > 0
        ? this.tokenAssignedDistrCenters
        : [this.tokenDistrCenter];
    return (
      <Col>
        <Col xs={24} sm={24} md={6} lg={5} xl={8} xxl={6} className={'filters'}>
          <div>
            <Text className={'filters-text-label'}>Filtrar por fecha</Text>
          </div>
          <Col xs={24} sm={12} md={24} lg={24} xl={12} xxl={12}>
            <DatePicker
              style={{ width: '100%' }}
              disabledDate={(date: moment.Moment | undefined): boolean =>
                isDateWrong(date, moment(paymentFilters.dateLessThan))
              }
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
              format={DATE_FORMAT}
              defaultValue={moment(paymentFilters.dateGreaterThan)}
              placeholder="Desde"
              onOk={(startDate: Moment): void => {
                paymentFilters.dateGreaterThan = startDate
                  ? startDate.toISOString()
                  : getInitialTime().toISOString();
                this.onChangeFilters(paymentFilters);
              }}
              disabled={this.props.isFetching}
            />
          </Col>
          <Col xs={24} sm={12} md={24} lg={24} xl={12} xxl={12}>
            <DatePicker
              style={{ width: '100%' }}
              disabledDate={(date: moment.Moment | undefined): boolean =>
                isDateWrong(moment(paymentFilters.dateGreaterThan), date)
              }
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
              format={DATE_FORMAT}
              defaultValue={moment(paymentFilters.dateLessThan)}
              placeholder="Hasta"
              onOk={(endDate: Moment): void => {
                paymentFilters.dateLessThan = endDate
                  ? endDate.toISOString()
                  : getInitialTime().toISOString();
                this.onChangeFilters(paymentFilters);
              }}
              disabled={this.props.isFetching}
            />
          </Col>
        </Col>

        <Col className={'filters'} {...sizeScreen(5)}>
          <Text className={'filters-text-label'}>Estado de pago</Text>
          <Select
            style={{ width: '100%' }}
            className={'sale-filters-size'}
            defaultValue={''}
            value={this.props.paymentFilters.StatusPayment}
            onChange={(event: string): void => {
              paymentFilters.StatusPayment = event;
              this.onChangeFilters(paymentFilters);
            }}
            disabled={this.props.isFetching}
          >
            <Select.Option value={''}>Todos</Select.Option>
            <Select.Option value={'Aplicada'}>Aplicada</Select.Option>
            <Select.Option value={'Pendiente'}>Pago a verificar</Select.Option>
            <Select.Option value={'Rechazada'}>Rechazado</Select.Option>
          </Select>
        </Col>
        <Col className={'filters'} {...sizeScreen(5)}>
          <Text className={'filters-text-label'}>Tipo</Text>
          <Select
            style={{ width: '100%' }}
            className={'sale-filters-size'}
            defaultValue={''}
            value={this.props.paymentFilters.paymentType}
            onChange={(event: string): void => {
              paymentFilters.paymentType = event;
              this.onChangeFilters(paymentFilters);
            }}
            disabled={this.props.isFetching}
          >
            <Select.Option value={''}>Todos</Select.Option>
            <Select.Option value={'Nota'}>Nota de crédito</Select.Option>
            <Select.Option value={'Pago'}>Pago</Select.Option>
          </Select>
        </Col>

        <Col className={'filters'} {...sizeScreen(5)}>
          <Text className={'filters-text-label'}>Banco</Text>
          <Select
            style={{ width: '100%' }}
            className={'sale-filters-size'}
            defaultValue={''}
            value={this.props.paymentFilters.bank}
            onChange={(event: string): void => {
              paymentFilters.bank = event;
              this.onChangeFilters(paymentFilters);
            }}
            disabled={this.props.isFetching}
          >
            <Select.Option value="">Todos</Select.Option>
            {banks.map(
              (item, index: number): React.ReactNode => (
                <Select.Option key={index} value={item.name}>
                  {item.name}
                </Select.Option>
              ),
            )}
          </Select>
        </Col>
        <Col className={'filters'} {...sizeScreen(5)}>
          <Row>
            <Text className={'filters-text-label'}>Centro de Distribución</Text>
          </Row>
          <Row>
            <Select
              defaultValue={this.props.paymentFilters.distributionCenter}
              style={{ width: '100%' }}
              className={'sale-filters-size'}
              disabled={this.props.isFetching}
              onChange={(value: string): void => {
                paymentFilters.distributionCenter = value;
                this.onChangeFilters(paymentFilters);
              }}
            >
              <Select.Option value="">Todos</Select.Option>
              {//When user has permission, show all options
              isAllowed([permissions.CAN_VIEW_ALL_DISTRIBUTION_CENTERS]) &&
                this.props.distributionCenter &&
                this.props.distributionCenter.map(
                  (
                    distCenter: DistributionCenter,
                    index: number,
                  ): JSX.Element => (
                    <Select.Option value={distCenter.id} key={index}>
                      {distCenter.name}
                    </Select.Option>
                  ),
                )}
              {//When user HAS NO permission, show the options with the assigned distr centers information
              !isAllowed([permissions.CAN_VIEW_ALL_DISTRIBUTION_CENTERS]) &&
                this.props.distributionCenter &&
                assignedDistrCenters.map(
                  (id: string, index: number): JSX.Element => {
                    const distrCenter = this.props.distributionCenter.find(
                      (center: DistributionCenter): boolean => center.id === id,
                    );
                    return distrCenter ? (
                      <Select.Option value={distrCenter.id} key={index}>
                        {distrCenter.name}
                      </Select.Option>
                    ) : (
                      <div key={index} />
                    );
                  },
                )}
            </Select>
          </Row>
        </Col>
        <Col className={'filters'} {...sizeScreen(5)}>
          <Text className={'filters-text-label'}>Compañía</Text>
          <Select
            disabled={this.props.isFetching}
            defaultValue={this.props.paymentFilters.companyId}
            value={paymentFilters.companyId}
            style={{ width: '100%' }}
            onChange={(value: string): void => {
              paymentFilters.companyId = value;
              this.onChangeFilters(paymentFilters);
            }}
          >
            <Select.Option key="Todos" value="">
              Todos
            </Select.Option>
            {this.props.companyList.map(
              (company: Company, index: number): JSX.Element => (
                <Select.Option value={company.id} key={index}>
                  {company.name}
                </Select.Option>
              ),
            )}
          </Select>
        </Col>
        <Col className={'filters'} {...sizeScreen(5)}>
          <Text className={'filters-text-label'} style={{ paddingLeft: '30%' }}>
            Factura serie/folio
          </Text>
          <Select
            style={{ width: '50%' }}
            className={'sale-filters-size'}
            defaultValue={''}
            value={this.props.paymentFilters.invoiceSerie}
            onChange={(event: string): void => {
              paymentFilters.invoiceSerie = event;
              this.onChangeFilters(paymentFilters);
            }}
            disabled={this.props.isFetching}
          >
            <Select.Option value="">Todos</Select.Option>
            <Select.Option value="PAG">PAG</Select.Option>
            <Select.Option value="NC">NC</Select.Option>
            <Select.Option value="N">N</Select.Option>
            <Select.Option value="P">P</Select.Option>
            <Select.Option value="SN">SN</Select.Option>
            <Select.Option value="SP">SP</Select.Option>
            <Select.Option value="RNC">RNC</Select.Option>
            <Select.Option value="NCI">NCI</Select.Option>
            <Select.Option value="REP">REP</Select.Option>
          </Select>
          <Input
            className={'filter-price-range-input'}
            style={{
              borderTopRightRadius: '5px',
              borderBottomRightRadius: '5px',
            }}
            disabled={this.props.isFetching}
            placeholder="Folio"
            defaultValue={paymentFilters.invoiceFolio}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              paymentFilters.invoiceFolio = event.currentTarget.value;
            }}
            onPressEnter={(): void => this.onChangeFilters(paymentFilters)}
          />
        </Col>
      </Col>
    );
  };

  private onCancelLocationModal = (): void => {
    this.setState({
      isModalVisible: false,
      selectedCustomer: initCustomer,
      selectedPayment: initPayment,
    });
  };

  private onChangeFilters = (filters: PaymentFilters): void => {
    const updateFilters = async (): Promise<void> =>
      await this.props.changeFilters(filters);
    updateFilters();
  };

  /*
  This function apply the actually paymentFilters values and get the Payments
  */
  public onClickSearch = (): void => {
    const loadPaymentsByFilters = async (): Promise<void> => {
      let { paymentFilters } = this.props;
      paymentFilters.pageNumber = 0;
      await this.props.getPayments(paymentFilters);
    };
    loadPaymentsByFilters();
  };

  public onChangeBank = (paymentId: string, bank: string): void => {
    const asyncAction = async (): Promise<void> => {
      await this.props.handleBankChange(paymentId, bank + ', Pago por taskit');
      await this.props.getPayments(this.props.paymentFilters);
    };
    asyncAction();
  };

  private renderDetails = (item: Payment): JSX.Element => {
    const distributionCenter: DistributionCenter =
      this.props.distributionCenter.find(
        (d: DistributionCenter): boolean => d.id === item.distributionCenter,
      ) || initDistributionCenter;

    return (
      <Row>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Estado de pago: '}</Text>
          {//If it is credit note and it has balance, set as "Pendiente por timbrar"
          item.bank === 'Nota de Crédito' && item.balance > 0 ? (
            <Text type="warning" strong>
              Pendiente por timbrar
            </Text>
          ) : //When it is applied, set as "Aplicado"
          item.apply === 'APLICADA' ? (
            <Text strong>Aplicado </Text>
          ) : //When it is a pending payment, set as "Pago a verificar"
          item.apply === 'PENDIENTE' ? (
            <Text type="warning" strong>
              Pago a verificar
            </Text>
          ) : (
            //Else, set as "Rechazado"
            <Text type="danger" strong>
              Rechazado
            </Text>
          )}
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Fecha: '}</Text>
          <Text>{moment(item.paymentDate).format(DATE_FORMAT)}</Text>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Cliente: '}</Text>
          <Text>{item.customerCode}</Text>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Banco: '}</Text>
          <Text>{item.bank}</Text>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Referencia: '}</Text>
          <Text>{item.reference}</Text>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Clave CIE: '}</Text>
          <Text>{item.cieKey}</Text>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Text strong>{'Centro de distribución: '}</Text>
          <Text>{distributionCenter.name}</Text>
        </Col>
      </Row>
    );
  };

  public renderActions = (item: Payment): JSX.Element => {
    return (
      <Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <h1>
            <Text strong>{' $' + item.amount}</Text>
          </h1>
        </Col>
        {item.bank !== 'Nota de Crédito' && item.balance === item.amount ? (
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              {JwtDecode<Token>(getToken() as string).customerCode === '' &&
              item.apply === 'PENDIENTE' ? (
                <Col>
                  <Tooltip
                    title={
                      item.paymentVoucherLink === null
                        ? 'Comprobante pendiente por subir'
                        : ''
                    }
                  >
                    <Button
                      type="link"
                      key={item.id + '3'}
                      className="ant-button"
                      onClick={(): void =>
                        this.props.applyPayment(
                          item.id,
                          JwtDecode<Token>(getToken() as string).id,
                          this.props.paymentFilters,
                        )
                      }
                      disabled={
                        this.props.isFetching ||
                        item.paymentVoucherLink === null ||
                        item.bank.includes('Pago por taskit')
                      }
                    >
                      <Text>Aplicar</Text>
                    </Button>
                  </Tooltip>
                  <Tooltip
                    title={
                      item.paymentVoucherLink === null
                        ? 'Comprobante pendiente por subir'
                        : ''
                    }
                  >
                    <Popconfirm
                      title="¿Seguro que deseas rechazar el pago?"
                      onConfirm={(): void =>
                        this.props.refusePayment(
                          item.id,
                          this.props.paymentFilters,
                        )
                      }
                      okText="si"
                      cancelText="No"
                    >
                      <Button
                        type="link"
                        key={item.id + '3'}
                        className="ant-button"
                        disabled={
                          this.props.isFetching ||
                          (item.paymentVoucherLink === null &&
                            !isAllowed([
                              permissions.CAN_REFUSE_PAYMENTS_WITHOUT_TICKET,
                            ]))
                        }
                      >
                        <Text>Rechazar</Text>
                      </Button>
                    </Popconfirm>
                  </Tooltip>
                </Col>
              ) : null}
            </Col>
          </Col>
        ) : (
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Icon type="check-circle" />
            </Col>
          </Col>
        )}
      </Row>
    );
  };

  public render = (): React.ReactNode => {
    let { paymentFilters } = this.props;
    const { paymentPage, isFetching } = this.props;
    const filters = this.renderFilters(paymentFilters);
    let searchCodeElement;
    if (getCustomerCode()) {
      searchCodeElement = '';
    } else {
      searchCodeElement = (
        <Col className={'filters'} {...sizeScreen(5)}>
          <div>
            <Text className={'filters-text-label'}>Cliente</Text>
          </div>
          <Input.Search
            placeholder="Nombre comercial, Razón social o Código de cliente "
            defaultValue={''}
            value={paymentFilters.customerCode}
            onChange={(event): void => {
              paymentFilters.customerCode = event.currentTarget.value;
              this.onChangeFilters(paymentFilters);
            }}
            disabled={isFetching}
          />
        </Col>
      );
    }
    return (
      <div className={'content-backgroud'}>
        <Row>
          {filters}
          {searchCodeElement}
          <Col
            xs={12}
            sm={5}
            md={4}
            lg={3}
            xl={2}
            xxl={2}
            style={{ paddingTop: '28px' }}
            className="filters"
          >
            <Checkbox onChange={this.getByUserId}>
              Mis pagos realizados
            </Checkbox>
          </Col>
          <Col
            xs={10}
            sm={5}
            md={4}
            lg={3}
            xl={3}
            xxl={3}
            style={{ paddingTop: '28px' }}
            className="filters"
          >
            <Button
              type="primary"
              size="default"
              onClick={(): void => {
                this.onClickSearch();
              }}
              disabled={isFetching}
            >
              <Icon type="search" />
              Buscar
            </Button>
          </Col>
          <Col
            xs={10}
            sm={5}
            md={4}
            lg={3}
            xl={2}
            xxl={2}
            style={{ paddingTop: '28px' }}
            className="filters"
          >
            <Tooltip
              title={`Descargar como Excel. 
              Máximo 10,000 registros`}
              placement="topRight"
            >
              <Button
                type="primary"
                shape="round"
                size={'default'}
                onClick={(): void => {
                  this.props.downloadPaymentsReport(paymentFilters);
                }}
                disabled={
                  isFetching ||
                  (paymentPage.totalElements !== undefined &&
                    paymentPage.totalElements > rowsAllowed) ||
                  (paymentPage.totalElements !== undefined &&
                    paymentPage.totalElements <= 0)
                }
              >
                <Icon type={'download'} />
                {' Excel'}
              </Button>
            </Tooltip>
          </Col>
          <Col
            xs={16}
            sm={8}
            md={6}
            lg={5}
            xl={4}
            xxl={3}
            style={{ paddingTop: '28px' }}
            className="filters"
          >
            <Tooltip
              title={`Descargar como Excel. 
              Máximo 10,000 registros`}
              placement="topRight"
            >
              <Button
                type="primary"
                shape="round"
                size={'default'}
                onClick={(): void => {
                  this.props.downloadDetailedPaymentsReport(paymentFilters);
                }}
                disabled={
                  isFetching ||
                  (paymentPage.totalElements !== undefined &&
                    paymentPage.totalElements > rowsAllowed) ||
                  (paymentPage.totalElements !== undefined &&
                    paymentPage.totalElements <= 0)
                }
              >
                <Icon type={'download'} />
                {' Excel Detallado'}
              </Button>
            </Tooltip>
          </Col>
          <Col
            xs={8}
            sm={4}
            md={3}
            lg={3}
            xl={2}
            xxl={2}
            style={{ paddingTop: '28px' }}
            className="filters"
          >
            <Tooltip title="Descargar xml y pdf en un ZIP. Máximo 10,000 registros">
              <Button
                type="primary"
                shape="round"
                onClick={(): void => this.props.downloadZip(paymentFilters)}
                disabled={
                  isFetching ||
                  (paymentPage.totalElements !== undefined &&
                    paymentPage.totalElements > rowsAllowed) ||
                  (paymentPage.totalElements !== undefined &&
                    paymentPage.totalElements <= 0)
                }
              >
                <Icon type="download" /> ZIP
              </Button>
            </Tooltip>
          </Col>
        </Row>
        <Row>
          {this.state.isModalVisible && (
            <LocationModal
              isVisible={this.state.isModalVisible}
              customer={this.state.selectedCustomer}
              payment={this.state.selectedPayment}
              onCancel={(): Function => this.onCancelLocationModal}
            />
          )}
          <Row>
            <p />
          </Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={0} xxl={0}>
            <List
              dataSource={paymentPage.content}
              renderItem={(item: Payment, index: number): JSX.Element => (
                <List.Item>
                  <List.Item.Meta
                    key={index}
                    description={
                      <Row
                        style={{
                          background: '#FFFFFF',
                          padding: '10px',
                          margin: '-7px',
                        }}
                      >
                        <Col span={20}>{this.renderDetails(item)}</Col>
                        <Col span={4}>{this.renderActions(item)}</Col>
                      </Row>
                    }
                  />
                </List.Item>
              )}
            />
          </Col>

          <Col
            xs={0}
            sm={0}
            md={0}
            lg={0}
            xl={24}
            xxl={24}
            style={{ backgroundColor: 'white' }}
          >
            <Table
              dataSource={paymentPage.content}
              pagination={false}
              loading={isFetching}
              rowKey={(record): string => record.id}
            >
              <Table.Column
                title="Estatus de pago"
                dataIndex="apply"
                render={(text: string, record: Payment): React.ReactNode => {
                  //If it is credit note and it has balance, set as "Pendiente por timbrar"
                  return record.bank === 'Nota de Crédito' &&
                    record.balance > 0 ? (
                    <Text type="warning" strong>
                      Pendiente por timbrar
                    </Text>
                  ) : //When it is applied, set as "Aplicado"
                  record.apply === 'APLICADA' ? (
                    <Text strong>Aplicado </Text>
                  ) : //When it is a pending payment, set as "Pago a verificar"
                  record.apply === 'PENDIENTE' ? (
                    <Text type="warning" strong>
                      Pago a verificar
                    </Text>
                  ) : (
                    //Else, set as "Rechazado"
                    <Text type="danger" strong>
                      Rechazado
                    </Text>
                  );
                }}
              />
              <Table.Column
                title="Fecha de pago"
                dataIndex="paymentDate"
                render={(text: string, record: Payment): React.ReactNode => {
                  return moment(record.paymentDate).format(DATE_FORMAT);
                }}
              />
              <Table.Column title="Código G4S" dataIndex="customerCode" />
              <Table.Column
                title="Nombre"
                dataIndex="name"
                render={(text: string, record: Payment): JSX.Element => {
                  return <div>{record.customerFromPayment.businessName}</div>;
                }}
              />
              <Table.Column
                title="Monto del pago"
                dataIndex="amount"
                render={(amount: number, record: Payment): React.ReactNode => {
                  const customer = record.customerFromPayment;
                  // Show a button when is a taskit payment, location values are not null & distance between customer and payment are more than 50 meters
                  return record.bank === 'Pago por taskit' &&
                    record.latitude !== null &&
                    record.longitude !== null &&
                    getDistanceBetweenLocations(
                      customer.latitude,
                      customer.longitude,
                      record.latitude,
                      record.longitude,
                    ) > 50 ? (
                    <Button
                      onClick={(): void =>
                        this.setState({
                          isModalVisible: true,
                          selectedCustomer: record.customerFromPayment,
                          selectedPayment: record,
                        })
                      }
                      style={{ color: '#C52121' }}
                    >
                      {'$' + toNumber(amount)}
                    </Button>
                  ) : (
                    // Else show only the payment amount
                    '$' + toNumber(amount)
                  );
                }}
              />
              <Table.Column
                title="Banco"
                dataIndex="bank"
                render={(text: string, record: Payment): React.ReactNode => {
                  if (text === 'Pago por taskit') {
                    return (
                      <Select
                        style={{ width: '100%' }}
                        className={'sale-filters-size'}
                        defaultValue={''}
                        value={text}
                        onChange={(event: string): void => {
                          this.onChangeBank(record.id, event);
                        }}
                        disabled={this.props.isFetching}
                      >
                        {banks.map(
                          (item, index: number): React.ReactNode => (
                            <Select.Option key={index} value={item.name}>
                              {item.name}
                            </Select.Option>
                          ),
                        )}
                      </Select>
                    );
                  }
                  return text;
                }}
              />
              <Table.Column
                title="Número de transferencia"
                dataIndex="reference"
              />
              <Table.Column
                title="Método de Pago"
                dataIndex="paymentForm"
                render={(text: string, record: Payment): React.ReactNode => {
                  return (
                    formaPago.find(
                      (item, index): boolean => item.key === record.paymentForm,
                    ) || { key: '00', name: record.paymentForm }
                  ).name;
                }}
              />

              <Table.Column title="Clave CIE" dataIndex="cieKey" />
              <Table.Column
                title="Acciones"
                dataIndex="isapply"
                render={(text: string, record: Payment): React.ReactNode => {
                  return record.bank !== 'Nota de Crédito' &&
                    record.balance === record.amount &&
                    isAllowed([permissions.CAN_APPLY_PAYMENTS]) ? (
                    <span>
                      {JwtDecode<Token>(getToken() as string).customerCode ===
                        '' && record.apply !== 'RECHAZADA' ? (
                        <Col>
                          <Tooltip
                            title={
                              record.paymentVoucherLink === null
                                ? 'Comprobante pendiente por subir'
                                : ''
                            }
                          >
                            <Button
                              type="link"
                              key={record.id + '3'}
                              className="ant-button"
                              onClick={(): void =>
                                this.props.applyPayment(
                                  record.id,
                                  JwtDecode<Token>(getToken() as string).id,
                                  this.props.paymentFilters,
                                )
                              }
                              disabled={
                                isFetching || record.paymentVoucherLink === null ||
                                record.bank.includes('Pago por taskit')
                              }
                            >
                              <Text>Aplicar</Text>
                            </Button>
                          </Tooltip>
                          <Divider type="vertical" />
                          <Tooltip
                            title={
                              record.paymentVoucherLink === null
                                ? 'Comprobante pendiente por subir'
                                : ''
                            }
                          >
                            <Popconfirm
                              title="¿Realmente desea rechazarlo?"
                              onConfirm={(): void =>
                                this.props.refusePayment(
                                  record.id,
                                  this.props.paymentFilters,
                                )
                              }
                              okText="Sí"
                              cancelText="No"
                            >
                              <Button
                                type="link"
                                key={record.id + '3'}
                                className="ant-button"
                                disabled={
                                  this.props.isFetching ||
                                  (record.paymentVoucherLink === null &&
                                    !isAllowed([
                                      permissions.CAN_REFUSE_PAYMENTS_WITHOUT_TICKET,
                                    ]))
                                }
                              >
                                <Text>Rechazar</Text>
                              </Button>
                              <Divider type="vertical" />
                            </Popconfirm>
                          </Tooltip>
                          {record.bank !== 'Pago por taskit' ? (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={record.paymentVoucherLink}
                            >
                              <Tooltip title="Descargar comprobante">
                                <Button
                                  type="primary"
                                  shape="round"
                                  size={'small'}
                                  disabled={isFetching}
                                >
                                  <Icon type={'download'} />
                                </Button>
                              </Tooltip>
                            </a>
                          ) : (
                            <Tooltip title="Descargar comprobante">
                              <Button
                                type="primary"
                                shape="round"
                                size={'small'}
                                disabled={isFetching}
                                onClick={(): void =>
                                  this.props.downloadTaskitEvidence(
                                    record.paymentVoucherLink,
                                  )
                                }
                              >
                                <Icon type={'download'} />
                              </Button>
                            </Tooltip>
                          )}
                        </Col>
                      ) : null}
                    </span>
                  ) : //Condition in Actions Column, to show the download Icon if the paymentVoucherLink is not Empty
                  record.paymentVoucherLink === null ? (
                    <Col>
                      <span>
                        <Icon type="check-circle" />
                      </span>
                    </Col>
                  ) : (
                    <Row id={record.id}>
                      <Col span={8}>
                        <Icon type="check-circle" />
                      </Col>
                      <Col span={8}>
                        {record.bank !== 'Pago por taskit' ? (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={record.paymentVoucherLink}
                          >
                            <Tooltip title="Descargar comprobante">
                              <Button
                                type="primary"
                                shape="round"
                                size={'small'}
                                disabled={isFetching}
                              >
                                <Icon type={'download'} />
                              </Button>
                            </Tooltip>
                          </a>
                        ) : (
                          <Tooltip title="Descargar comprobante">
                            <Button
                              type="primary"
                              shape="round"
                              size={'small'}
                              disabled={isFetching}
                              onClick={(): void =>
                                this.props.downloadTaskitEvidence(
                                  record.paymentVoucherLink,
                                )
                              }
                            >
                              <Icon type={'download'} />
                            </Button>
                          </Tooltip>
                        )}
                      </Col>
                      <Col span={8}>
                        {record.invoiceError !== null ? (
                          //If the invoice had some error, show it in a tooltip of a warning icon
                          <Col span={5}>
                            <Tooltip title={record.invoiceError}>
                              <Icon
                                type="warning"
                                style={{ fontSize: '26px', color: 'red' }}
                              />
                            </Tooltip>
                          </Col>
                        ) : (
                          //Else, show the buttons when has invoice id and invoice is downloaded
                          record.invoiceId != null &&
                          record.invoiceDownloaded && (
                            <div>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={record.invoicePdfLink}
                              >
                                <Tooltip title="Descargar PDF">
                                  <Text disabled={isFetching}>PDF</Text>
                                </Tooltip>
                              </a>
                              <Divider type="vertical" />
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={record.invoiceXmlLink}
                              >
                                <Tooltip title="Descargar XML">
                                  <Text>XML</Text>
                                </Tooltip>
                              </a>
                            </div>
                          )
                        )}
                      </Col>
                    </Row>
                  );
                }}
              />
            </Table>
          </Col>
          <Pagination
            current={(this.props.paymentPage.number || 0) + 1}
            total={this.props.paymentPage.totalElements}
            onChange={this.handlePaginationChange}
            showSizeChanger
            onShowSizeChange={this.handlePaginationChange}
            defaultPageSize={25}
            pageSizeOptions={['25', '50', '75', '100']}
          />
        </Row>
      </div>
    );
  };
}

const mapStateToProps = (states: RootState): StateProps => {
  return {
    paymentPage: states.paymentState.paymentList,
    paymentFilters: states.paymentState.paymentFilters,
    isFetching: states.paymentState.isFetching,
    distributionCenter:
      states.paymentState.distributionCenter.distributionCenter,
    companyList: Object.values(states.paymentState.companyList),
  };
};

const mapDispatchToProps = {
  page,
  getPayments,
  handleBankChange,
  applyPayment,
  refusePayment,
  downloadPaymentsReport,
  downloadDetailedPaymentsReport,
  downloadTaskitEvidence,
  downloadZip,
  changeFilters,
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentsListComponent);
