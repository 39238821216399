import React, { Component } from 'react';
import Form, { ValidationRule } from 'antd/lib/form';
import {
  CustomerAddress,
  CustomerModel,
  G4sState,
  G4sMunicipality,
  G4sPrice,
  G4sProduct,
  Customer,
} from 'types/type';
import {
  Button,
  Row,
  Input,
  Col,
  Radio,
  Collapse,
  Popover,
  InputNumber,
  Select,
} from 'antd';
import { CustomerRules as r } from '../customerRules/customerRules';
import { LabelsCostomerAddress, MessageCustomerAddress } from 'commons/strings';
import { initCustomerAddress } from 'commons/initTypes';
import G4sServices from '../../../../services/g4sServices/index';
import GenericSelect from 'commons/components/genericComponents/genericSelect';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import dump from 'assets/dump.svg';
import CustomerService from 'services/customers';
import { isNullOrUndefined } from 'util';
import { Popups } from 'commons/components/popups/popups';
import { CollapseProps } from 'antd/lib/collapse';
import { getUdrFieldsNames } from 'routes/customers/functions';
const g4sServices: G4sServices = new G4sServices();
const customerService: CustomerService = new CustomerService();
interface Country {
  id: number;
  text: string;
}
const country: Country[] = [{ id: 1, text: 'México' }];

interface State {
  customer: Customer;
  customerAddress: CustomerAddress[];
  error: boolean;
  states: G4sState[];
  municipalities: G4sMunicipality[];
  prices: G4sPrice[];
  products: G4sProduct[];
  filtredPrices: G4sPrice[];
  visiblePopover: boolean[];
  errors: [];
}
interface Props {
  customerModel: CustomerModel;
  sizeScreen: Function;
  form: WrappedFormUtils;
  validator: boolean;
  errors: [];
  canEditCustomer: boolean;
  canEditUdr: boolean;
  setIndexToSave: Function;
  isFetching: boolean;
}

class CustomerAdresssManager extends Component<Props, State> {
  public state: State = {
    customer: this.props.customerModel.customer,
    customerAddress: [],
    error: false,
    states: [],
    municipalities: [],
    prices: [],
    products: [],
    filtredPrices: [],
    visiblePopover: [false],
    errors: [],
  };

  private fetchData = (): void => {
    Promise.all([
      g4sServices.getG4sPrices(), //0
      g4sServices.getG4sProducts(), //1
      g4sServices.getG4sStates(), //2
      g4sServices.getG4sMunicipalities(1), //3
    ]).then((results): void => {
      this.setState({
        prices: results[0].data,
        products: results[1].data,
        states: results[2].data,
        municipalities: results[3].data,
        filtredPrices: results[0].data.filter(
          (f: G4sPrice): boolean => f.productId === 1,
        ),
      });
    });
  };
  public componentDidMount(): void {
    //what data?
    this.fetchData();
  }

  private CustomerAddressImputTextUdr = (
    id: string | undefined,
    value: string,
    name: string,
    index: number,
    label: string,
    rule: ValidationRule[] | undefined,
    maxLength?: number,
  ): JSX.Element => {
    return (
      <Form.Item
        hasFeedback
        label={label}
        validateStatus={this.props.validator ? 'error' : undefined}
        help={
          this.props.validator
            ? 'No se premite usar el mismo codigo de UDR'
            : undefined
        }
      >
        {this.props.form.getFieldDecorator(name, {
          initialValue: value ? value : '',
          rules: rule,
        })(
          <InputNumber
            max={2147483647}
            style={{ minWidth: '10rem' }}
            maxLength={maxLength ? maxLength : 255}
            //onSelect={(): void => this.updateAction(value, index)}
            onKeyPress={(e): void => {
              e.key === 'Enter' && e.preventDefault();
            }}
            onBlur={(event: React.FocusEvent<HTMLInputElement>): void => {
              this.autoSetCustomerAddress(event.currentTarget.value, index);
            }}
            size="large"
            placeholder={label}
            disabled={!(this.props.canEditCustomer || this.props.canEditUdr)}
          />,
        )}
      </Form.Item>
    );
  };

  private CustomerAddressImputText = (
    value: string | number,
    name: string,
    index: number,
    label: string,
    rule?: ValidationRule[],
    maxLength?: number,
  ): JSX.Element => {
    return (
      <Form.Item hasFeedback label={label}>
        {this.props.form.getFieldDecorator(name, {
          initialValue: value ? value : '',
          rules: rule,
        })(
          <Input
            maxLength={maxLength ? maxLength : 255}
            onBlur={(
              event: React.SyntheticEvent<HTMLInputElement, Event>,
            ): void => this.updateAction(event.currentTarget.value, index)}
            size="large"
            placeholder={label}
            disabled={!(this.props.canEditCustomer || this.props.canEditUdr)}
          />,
        )}
      </Form.Item>
    );
  };

  private generateFieldDecorator = (
    value: string | number | boolean,
    name: string,
    index: number,
    label: string,
    rule?: ValidationRule[],
    render?: React.ReactElement,
  ): JSX.Element => (
    <Form.Item label={label}>
      {this.props.form.getFieldDecorator(name, {
        initialValue: value,
        rules: rule,
      })(render)}
    </Form.Item>
  );

  //what delete in the form?
  private deleteAction = (index: number): void => {
    let propsCustomerAddress: CustomerAddress[] = this.props.customerModel
      .customerAddress;
    let stateCustomerAddress: CustomerAddress[] = this.state.customerAddress;
    let newCustomerAddress: CustomerAddress[];
    let flitredCustomerAddress: CustomerAddress[];

    if (this.state.customerAddress.length === 0) {
      newCustomerAddress = propsCustomerAddress;
    } else {
      newCustomerAddress = stateCustomerAddress;
    }
    flitredCustomerAddress = newCustomerAddress.filter(
      (value: CustomerAddress, findex: number): boolean => findex !== index,
    );
    if (newCustomerAddress.length > 1) {
      this.props.form.setFieldsValue({
        customerAddress: flitredCustomerAddress,
      });
      this.setState({ customerAddress: flitredCustomerAddress });
    }
  };

  private onSelectValue = (value: string | number, index: number): void => {
    this.updateAction(value, index);
    this.updateAction(value, index);
  };

  private onChangeProduct = (value: string | number, index: number): void => {
    let filtredResult: G4sPrice[] = this.state.prices.filter(
      (price: G4sPrice): boolean => price.productId === value,
    );
    this.setState({ filtredPrices: filtredResult }, (): void => {
      this.updateAction(value, index);
      this.updateAction(value, index);
    });
  };

  private onChangeRegionState = async (
    value: string,
    index: number,
  ): Promise<void> => {
    const selectedState = this.state.states.find(
      (f: G4sState): boolean => value === f.name,
    );
    if (selectedState !== undefined) {
      this.updateAction(selectedState.name, index);
      const municipalities = await g4sServices.getG4sMunicipalities(
        selectedState.id,
      );
      this.updateAction(selectedState.name, index);
      this.setState({ municipalities: municipalities.data });
    }
  };

  //what add in the form? add udr or add what?
  private addAction = (): void => {
    let newCustomerAddress: CustomerAddress[] = this.props.customerModel
      .customerAddress;
    if (this.state.customerAddress.length === 0) {
      newCustomerAddress.push(initCustomerAddress);
      this.setState({
        customerAddress: newCustomerAddress,
      });
      this.props.form.setFieldsValue({
        customerAddress: newCustomerAddress,
      });
    } else {
      let customerAddresResult = this.state.customerAddress;
      customerAddresResult.push(initCustomerAddress);
      this.setState({
        customerAddress: customerAddresResult,
      });
      this.props.form.setFieldsValue({
        customerAddress: customerAddresResult,
      });
    }
  };

  //same case, the description of the functions is ambiguous
  private updateAction = (
    valueToUpdate: string | number | boolean,
    indextoUpodate: number,
  ): void => {
    let propsCustomerAddress: CustomerAddress[] = this.props.customerModel
      .customerAddress;
    let stateCustomerAddress: CustomerAddress[] = this.state.customerAddress;
    let newCustomerAddress: CustomerAddress[];

    if (this.state.customerAddress.length === 0) {
      newCustomerAddress = propsCustomerAddress.map(
        (address): CustomerAddress => address,
      );
    } else {
      newCustomerAddress = stateCustomerAddress;
    }
    newCustomerAddress[indextoUpodate] = this.props.form.getFieldsValue(
      getUdrFieldsNames(indextoUpodate),
    ).customerAddress[indextoUpodate];
    this.setState({ customerAddress: [...newCustomerAddress] });
  };

  //auto set in how case? is a auto event or any action? and the functions is so long and have multiples loggers
  private autoSetCustomerAddress = async (
    code: string,
    indexSelected: number,
  ): Promise<void> => {
    const { customerAddress } = this.state;
    const { customerModel } = this.props;

    let customerAddressToEdit: CustomerAddress[] =
      customerAddress.length > 0
        ? customerAddress
        : customerModel.customerAddress;
    const g4sCustomerAddress: CustomerAddress = (
      await customerService.getCustomerAddressOfG4s(code)
    ).data;
    if (
      customerAddressToEdit.filter(
        (f: CustomerAddress, index: number): boolean =>
          f.code === code && index === indexSelected,
      ).length > 0
    ) {
      this.setState({
        customerAddress: customerAddressToEdit.map(
          (f: CustomerAddress, index: number): CustomerAddress => {
            if (f.code === code && index === indexSelected)
              f = { ...g4sCustomerAddress, id: f.id };
            return f;
          },
        ),
      });

      this.props.form.setFieldsValue({
        customerAddress: customerAddressToEdit.map(
          (f: CustomerAddress, index: number): CustomerAddress => {
            if (f.code === code && index === indexSelected)
              f = { ...g4sCustomerAddress, id: f.id };
            return f;
          },
        ),
      });
    } else if (
      customerAddressToEdit.filter(
        (f: CustomerAddress): boolean => f.code === code,
      ).length > 0
    ) {
      Popups.notifyError('Esta udr ya existe');
    } else {
      customerAddressToEdit[indexSelected] = {
        ...g4sCustomerAddress,
        id:
          customerAddressToEdit[indexSelected] !== undefined
            ? customerAddressToEdit[indexSelected].id
            : undefined,
      };
      this.setState({ customerAddress: customerAddressToEdit });
      this.props.form.setFieldsValue({
        customerAddress: customerAddressToEdit,
      });
    }
  };
  public activeKeys = (): CollapseProps => {
    return {
      activeKey: this.props.errors.map((item: [], index: number): string => {
        return index.toString();
      }),
    };
  };
  public activeKeysSubItems = (): CollapseProps => {
    return {
      activeKey: ['0', '1', '2'],
    };
  };
  public render = (): React.ReactNode => {
    const { form, sizeScreen, customerModel, errors } = this.props;
    const isEditable = this.props.canEditCustomer || this.props.canEditUdr;
    const { customerAddress } = this.state;
    const customerAddressList =
      customerAddress.length !== 0
        ? customerAddress
        : customerModel.customerAddress.length !== 0
        ? customerModel.customerAddress
        : [initCustomerAddress];
    let visiblePopover: boolean[] =
      customerAddressList.length > 1
        ? customerAddressList.map((): false => false)
        : this.state.visiblePopover;

    return (
      <Row>
        <Collapse
          accordion={!(errors.length > 0)}
          bordered
          {...(errors.length > 0 ? this.activeKeys() : undefined)}
        >
          {customerAddressList.map(
            (value: CustomerAddress, index: number): React.ReactNode => (
              <Collapse.Panel
                style={{
                  paddingBottom: '8px',
                  backgroundColor: errors[index] ? '#fff4f4' : '',
                  borderColor: errors[index] ? '#fdb0b2' : '',
                }}
                key={index}
                header={
                  value.code !== ''
                    ? `${value.code}  ${value.street}  #${
                        value.externalNumber
                      } ${
                        errors[index] ? 'Faltan propiedades por definir' : ''
                      } `
                    : 'Sin Codigo de UDR'
                }
                extra={
                  <span>
                    {index === customerAddressList.length - 1 && isEditable && (
                      <Button
                        type="primary"
                        onClick={(event): void => {
                          event.stopPropagation();
                          visiblePopover.push(false);
                          this.setState({ visiblePopover: visiblePopover });
                          this.addAction();
                        }}
                      >
                        &#x271a;
                      </Button>
                    )}
                    {1 === customerAddressList.length
                      ? index > 1
                      : isNullOrUndefined(value.id) &&
                        isEditable && (
                          <Popover
                            key={index}
                            content={
                              <div key={index}>
                                <div style={{ marginBottom: '10px' }}>
                                  Se eliminará toda la información
                                  <br />
                                  que contenga esta UDR
                                </div>
                                <Button
                                  key={index}
                                  className={'popover-buttons'}
                                  onClick={(event): void => {
                                    event.stopPropagation();
                                    visiblePopover = visiblePopover.filter(
                                      (f: boolean): boolean => f !== true,
                                    );
                                    this.setState({
                                      visiblePopover: visiblePopover,
                                    });
                                  }}
                                >
                                  Cancelar
                                </Button>

                                <Button
                                  key={index}
                                  type={'primary'}
                                  className={'popover-buttons'}
                                  onClick={(event): void => {
                                    event.stopPropagation();
                                    visiblePopover = visiblePopover.filter(
                                      (f: boolean): boolean => f !== true,
                                    );
                                    this.setState({
                                      visiblePopover: visiblePopover,
                                    });
                                    this.deleteAction(index);
                                  }}
                                >
                                  Aceptar
                                </Button>
                              </div>
                            }
                            title={`Eliminar UDR ${value.code}`}
                            trigger="click"
                            visible={this.state.visiblePopover[index]}
                            onVisibleChange={(): void => {
                              visiblePopover = visiblePopover.map(
                                (f: boolean, indexF: number): boolean => {
                                  return indexF === index;
                                },
                              );
                              this.setState({ visiblePopover: visiblePopover });
                            }}
                          >
                            <Button
                              className={'delete-customer-address-button'}
                              type="link"
                            >
                              <img key={index} src={dump} alt="" />
                            </Button>
                          </Popover>
                        )}
                  </span>
                }
              >
                <Row>
                  {form.getFieldDecorator(`customerAddress[${index}].id`, {
                    initialValue: value.id,
                  })}
                  {form.getFieldDecorator(
                    `customerAddress[${index}].customer`,
                    {
                      initialValue: value.customer,
                    },
                  )}
                  <Collapse
                    {...(errors.length > 0
                      ? this.activeKeysSubItems()
                      : undefined)}
                  >
                    <Collapse.Panel key={0} header="General">
                      <Col {...sizeScreen(4)}>
                        {this.CustomerAddressImputTextUdr(
                          value.id,
                          value.code,
                          `customerAddress[${index}].code`,
                          index,
                          LabelsCostomerAddress.CUTOMER_ADDRESS_CODE,
                          !isEditable ? undefined : r.customerAddresCodeRule,
                        )}
                      </Col>
                      <Col {...sizeScreen(7)}>
                        {this.CustomerAddressImputText(
                          value.description,
                          `customerAddress[${index}].description`,
                          index,
                          LabelsCostomerAddress.CUTOMER_ADDRESS_DESCRIPTION,
                          !isEditable
                            ? undefined
                            : r.customerAddresTextRule(
                                MessageCustomerAddress.CUTOMER_ADDRESS_DESCRIPTION,
                              ),
                        )}
                      </Col>
                      <Col {...sizeScreen(5)}>
                        {this.CustomerAddressImputText(
                          value.generalPurpose1,
                          `customerAddress[${index}].generalPurpose1`,
                          index,
                          LabelsCostomerAddress.CUTOMER_ADDRESS_GENERAL_PURPOSE +
                            1,
                          undefined,
                          20,
                        )}
                      </Col>
                      <Col {...sizeScreen(5)}>
                        {this.CustomerAddressImputText(
                          value.generalPurpose2,
                          `customerAddress[${index}].generalPurpose2`,
                          index,
                          LabelsCostomerAddress.CUTOMER_ADDRESS_GENERAL_PURPOSE +
                            2,
                          undefined,
                          20,
                        )}
                      </Col>
                      <Col {...sizeScreen(3)}>
                        {this.generateFieldDecorator(
                          value.commode,
                          `customerAddress[${index}].commode`,
                          index,
                          LabelsCostomerAddress.CUTOMER_ADDRESS_COMMODE,
                          undefined,
                          <Radio.Group
                            onChange={(event): void =>
                              this.updateAction(event.target.value, index)
                            }
                            disabled={!isEditable}
                          >
                            <Radio value={true}>Si</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>,
                        )}
                      </Col>
                    </Collapse.Panel>
                    <Collapse.Panel key={1} header="Domicilio">
                      <Col {...sizeScreen(5)}>
                        {form.getFieldDecorator(
                          `customerAddress[${index}].country`,
                          { initialValue: value.country },
                        )}
                        <GenericSelect
                          options={country}
                          labelVarName={'text'}
                          valueVarName={'text'}
                          action={this.onSelectValue}
                          isDisabled={true}
                          defaultValues={'México'}
                          index={index}
                          isForm={true}
                          initialValue={'México'}
                          name={`customerAddress[${index}].country`}
                          label={LabelsCostomerAddress.CUTOMER_ADDRESS_COUNTRY}
                          rule={r.customerAddresSelectRule(
                            MessageCustomerAddress.CUTOMER_ADDRESS_COUNTRY,
                          )}
                          getFieldDecorator={form.getFieldDecorator}
                        />
                      </Col>
                      <Col {...sizeScreen(5)}>
                        {form.getFieldDecorator(
                          `customerAddress[${index}].state`,
                          {
                            initialValue: value.state,
                            rules: !isEditable
                              ? undefined
                              : [
                                  {
                                    required: true,
                                    message:
                                      MessageCustomerAddress.CUTOMER_ADDRESS_STATE,
                                  },
                                ],
                          },
                        )}
                        <GenericSelect
                          options={this.state.states}
                          valueVarName={'name'}
                          labelVarName={'name'}
                          action={this.onChangeRegionState}
                          index={index}
                          isForm={true}
                          initialValue={value.state}
                          name={`customerAddress[${index}].state`}
                          label={LabelsCostomerAddress.CUTOMER_ADDRESS_STATE}
                          rule={
                            !isEditable
                              ? undefined
                              : r.customerAddresSelectRule(
                                  MessageCustomerAddress.CUTOMER_ADDRESS_STATE,
                                )
                          }
                          getFieldDecorator={form.getFieldDecorator}
                          isDisabled={!isEditable}
                        />
                      </Col>
                      <Col {...sizeScreen(5)}>
                        {form.getFieldDecorator(
                          `customerAddress[${index}].municipality`,
                          {
                            initialValue: value.municipality,
                            rules: !isEditable
                              ? undefined
                              : [
                                  {
                                    required: true,
                                    message:
                                      MessageCustomerAddress.CUTOMER_ADDRESS_MUNICIPALITY,
                                  },
                                ],
                          },
                        )}
                        <GenericSelect
                          options={this.state.municipalities}
                          valueVarName={'name'}
                          labelVarName={'name'}
                          action={this.onSelectValue}
                          index={index}
                          isForm={true}
                          initialValue={value.municipality}
                          name={`customerAddress[${index}].municipality`}
                          label={
                            LabelsCostomerAddress.CUTOMER_ADDRESS_MUNICIPALITY
                          }
                          rule={
                            !isEditable
                              ? undefined
                              : r.customerAddresSelectRule(
                                  MessageCustomerAddress.CUTOMER_ADDRESS_MUNICIPALITY,
                                )
                          }
                          getFieldDecorator={form.getFieldDecorator}
                          isDisabled={!isEditable}
                        />
                      </Col>
                      <Col {...sizeScreen(5)}>
                        {this.CustomerAddressImputText(
                          value.city,
                          `customerAddress[${index}].city`,
                          index,
                          LabelsCostomerAddress.CUTOMER_ADDRESS_CITY,
                          !isEditable
                            ? undefined
                            : r.customerAddresSelectRule(
                                MessageCustomerAddress.CUTOMER_ADDRESS_CITY,
                              ),
                        )}
                      </Col>
                      <Col {...sizeScreen(5)}>
                        {this.CustomerAddressImputText(
                          value.suburb,
                          `customerAddress[${index}].suburb`,
                          index,
                          LabelsCostomerAddress.CUTOMER_ADDRESS_SUBURB,
                          !isEditable
                            ? undefined
                            : r.customerAddresSelectRule(
                                MessageCustomerAddress.CUTOMER_ADDRESS_SUBURB,
                              ),
                        )}
                      </Col>
                      <Col {...sizeScreen(5)}>
                        {this.CustomerAddressImputText(
                          value.street,
                          `customerAddress[${index}].street`,
                          index,
                          LabelsCostomerAddress.CUTOMER_ADDRESS_STREET,
                          !isEditable
                            ? undefined
                            : r.customerAddresSelectRule(
                                MessageCustomerAddress.CUTOMER_ADDRESS_STREET,
                              ),
                        )}
                      </Col>
                      <Col {...sizeScreen(7)}>
                        {this.CustomerAddressImputText(
                          value.externalNumber,
                          `customerAddress[${index}].externalNumber`,
                          index,
                          LabelsCostomerAddress.CUTOMER_ADDRESS_EXTERNAL_NUMBER,
                          !isEditable
                            ? undefined
                            : r.customerAddresTextRule(
                                MessageCustomerAddress.CUTOMER_ADDRESS_EXTERNAL_NUMBER,
                              ),
                        )}
                      </Col>
                      <Col {...sizeScreen(7)}>
                        {this.CustomerAddressImputText(
                          value.internalNumber,
                          `customerAddress[${index}].internalNumber`,
                          index,
                          LabelsCostomerAddress.CUTOMER_ADDRESS_INTERNAL_NUMBER,
                        )}
                      </Col>
                      <Col {...sizeScreen(7)}>
                        {this.CustomerAddressImputText(
                          value.zipCode,
                          `customerAddress[${index}].zipCode`,
                          index,
                          LabelsCostomerAddress.CUTOMER_ADDRESS_ZIP_CODE,
                          !isEditable
                            ? undefined
                            : r.customerAddresTextRule(
                                MessageCustomerAddress.CUTOMER_ADDRESS_ZIP_CODE,
                              ),
                        )}
                      </Col>
                    </Collapse.Panel>
                    <Collapse.Panel key={2} header="Venta">
                      <Col {...sizeScreen(5)}>
                        {this.CustomerAddressImputText(
                          value.capacity === 0 ? '0' : value.capacity,
                          `customerAddress[${index}].capacity`,
                          index,
                          LabelsCostomerAddress.CUTOMER_ADDRESS_CAPACITY,
                          !isEditable
                            ? undefined
                            : r.customerAddresNumericRule(
                                MessageCustomerAddress.CUTOMER_ADDRESS_CAPACITY,
                              ),
                          5,
                        )}
                      </Col>
                      <Col {...sizeScreen(5)}>
                        {form.getFieldDecorator(
                          `customerAddress[${index}].product`,
                          { initialValue: value.product },
                        )}
                        <GenericSelect
                          options={this.state.products}
                          labelVarName={'description'}
                          valueVarName={'id'}
                          action={this.onChangeProduct}
                          index={index}
                          isForm={true}
                          initialValue={value.product}
                          name={`customerAddress[${index}].product`}
                          label={LabelsCostomerAddress.CUTOMER_ADDRESS_PRODUCT}
                          defaultValues={1}
                          rule={
                            !isEditable
                              ? undefined
                              : r.customerAddresSelectRule(
                                  MessageCustomerAddress.CUTOMER_ADDRESS_PRODUCT,
                                )
                          }
                          getFieldDecorator={form.getFieldDecorator}
                          isDisabled={!isEditable}
                        />
                      </Col>
                      <Col {...sizeScreen(11)}>
                        <Form.Item hasFeedback label="Precio de venta:">
                          {form.getFieldDecorator(
                            `customerAddress[${index}].price`,
                            {
                              initialValue: value.price,
                              rules: [
                                {
                                  required: true,
                                  message: 'Seleccione una opción',
                                },
                              ],
                            },
                          )(
                            <Select
                              size="large"
                              placeholder="Seleccionar"
                              onChange={(): void =>
                                this.updateAction('Not used field', index)
                              }
                              disabled={!isEditable}
                            >
                              {this.state.filtredPrices.map(
                                (item: G4sPrice): React.ReactNode => (
                                  <Select.Option
                                    key={item.id}
                                    value={item.regionZone}
                                  >
                                    {'Zona ' +
                                      item.regionZone +
                                      ' - Ref: ' +
                                      item.reference}
                                  </Select.Option>
                                ),
                              )}
                            </Select>,
                          )}
                        </Form.Item>
                      </Col>
                    </Collapse.Panel>
                  </Collapse>
                  {this.props.canEditUdr && !this.props.canEditCustomer && (
                    <Col span={4} style={{ float: 'right' }}>
                      <Button
                        id={'udr_button_' + index}
                        className="btn-save-udr"
                        onClick={(): void => this.props.setIndexToSave(index)}
                        block
                        size="default"
                        disabled={this.props.isFetching}
                      >
                        {'Guardar UDR'}
                      </Button>
                    </Col>
                  )}
                </Row>
              </Collapse.Panel>
            ),
          )}
        </Collapse>
      </Row>
    );
  };
}
export default CustomerAdresssManager;
