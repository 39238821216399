import { ExceptionMessage } from '../types/type';

const getErrorDescription = (
  data: { message: string },
  status: number,
): string => {
  if (data.message !== null) {
    return data.message !== 'GENERAL'
      ? `${status} ${' -'} ${data.message}`
      : `${status} ${'-'} Error al conectarse al servidor`;
  }
  return `${status} ${data}`;
};

export const parseApiError = (error: unknown | ExceptionMessage): ExceptionMessage => {
  try {
    const parsedError = JSON.parse(JSON.stringify(error));
    const {
      response: { data, status },
    } = parsedError;
    return {
      status,
      message: 'Error',
      description: getErrorDescription(data, status),
      duration: 0,
    };
  } catch (ex) {
    return {
      status: 500,
      message: 'Error',
      description: 'Error de conexión!',
      duration: 0,
    };
  }
};
export const parseStringError = (error: string): ExceptionMessage => {
  try {
    const parsedError = JSON.parse(error);
    const data: ExceptionMessage = parsedError;
    return {
      status: data.status,
      message: 'Error',
      description: getErrorDescription(data, data.status),
      duration: 0,
    };
  } catch (ex) {
    return {
      status: 500,
      message: 'Error',
      description: 'Error de conexión!',
      duration: 0,
    };
  }
};
