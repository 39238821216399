import { Pageable, Container, LiterTotals } from 'types/type';
import Service from 'services/Service';
import { createCoreServiceUrl, createParamsService } from 'commons/services';
import axios, { AxiosResponse } from 'axios';

class ContainersService extends Service {
  public getAll = async (
    containerFilters: Record<string, any>, // eslint-disable-line
  ): Promise<Pageable> => {
    return axios({
      method: 'get',
      url:
        createCoreServiceUrl(`containers`) +
        createParamsService(containerFilters),
    }).then((response: AxiosResponse<Pageable>): Pageable => response.data);
  };

  public save = async (container: Container): Promise<Container> => {
    return axios({
      method: 'post',
      url: createCoreServiceUrl(`containers`),
      data: container,
    }).then((response: AxiosResponse<Container>): Container => response.data);
  };

  public deleteContainer = async (id: string): Promise<Container> => {
    return axios({
      method: 'delete',
      url: createCoreServiceUrl(`containers/${id}`),
    }).then((response: AxiosResponse<Container>): Container => response.data);
  };

  public getTotalIndicators = async (
    containerFilters: Record<string, any>, // eslint-disable-line
  ): Promise<LiterTotals> => {
    return axios({
      method: 'get',
      url:
        createCoreServiceUrl(`containers/totalIndicators`) +
        createParamsService(containerFilters),
    }).then(
      (response: AxiosResponse<LiterTotals>): LiterTotals => response.data,
    );
  };
}

export default ContainersService;
