/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Hiram Padilla <hiram@ssf.com.mx>, July 2020
 *
 * This file contains the redux reducers for equipments component
 */

import {
  Equipment,
  DistributionCenter,
  GovernmentPermission,
  EquipmentFilters,
  Company,
} from 'types/type';
import { Reducer, combineReducers } from 'redux';
import {
  SET_EQUIPMENTS,
  EquipmentAction,
  BEGIN_FETCH,
  FETCH_COMPLETED,
  SET_DISTRIBUTION_CENTERS,
  SET_GOVERNMENT_PERMISSIONS,
  SET_EQUIPMENT_FILTERS,
  SET_COMPANY_LIST,
} from './types';
import {
  initDistributionCenter,
  initGovernmentPermission,
  initEquipmentFilters,
  initcompany,
} from 'commons/initTypes';
import { RootState } from 'store/configureStore';

//Interface to be used in the reducer Equipments
export interface EquipmentList {
  content?: Equipment[];
  number?: number;
  totalElements?: number;
}

export interface EquipmentState {
  readonly equipments: EquipmentList;
  readonly distributionCenters: DistributionCenter[];
  readonly governmentPermissions: GovernmentPermission[];
  readonly equipmentFilters: EquipmentFilters;
  readonly companyList: Company[];
  readonly isFetching: boolean;
}

const isFetching: Reducer<boolean> = (
  state = false,
  action: EquipmentAction,
): boolean => {
  switch (action.type) {
    case BEGIN_FETCH:
      return true;
    case FETCH_COMPLETED:
      return false;
    default:
      return state;
  }
};

const equipments: Reducer<EquipmentList> = (
  state: EquipmentList = { content: [] },
  action,
): EquipmentList => {
  if (action.type === SET_EQUIPMENTS) {
    return { ...state, ...action.equipments };
  }
  return state;
};
const companyList: Reducer<Company[]> = (
  state: Company[] =  [initcompany] ,
  action,
): Company[] => {
  if (action.type === SET_COMPANY_LIST) {
    return { ...state, ...action.companyList };
  }
  return state;
};

const distributionCenters: Reducer<DistributionCenter[]> = (
  state: DistributionCenter[] = [initDistributionCenter],
  action,
): DistributionCenter[] => {
  if (action.type === SET_DISTRIBUTION_CENTERS) {
    return { ...state, ...action.distributionCenters };
  }
  return state;
};

const governmentPermissions: Reducer<GovernmentPermission[]> = (
  state: GovernmentPermission[] = [initGovernmentPermission],
  action,
): GovernmentPermission[] => {
  if (action.type === SET_GOVERNMENT_PERMISSIONS) {
    return { ...state, ...action.governmentPermissions };
  }
  return state;
};

const equipmentFilters: Reducer<EquipmentFilters> = (
  state: EquipmentFilters = initEquipmentFilters,
  action,
): EquipmentFilters => {
  if (action.type === SET_EQUIPMENT_FILTERS) {
    return { ...state, ...action.equipmentFilters };
  }
  return state;
};

export const getIsFetching = (store: RootState): boolean =>
  store.equipmentState.isFetching;

export default combineReducers<EquipmentState>({
  isFetching,
  equipments,
  distributionCenters,
  governmentPermissions,
  equipmentFilters,
  companyList,
});
