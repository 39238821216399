import {
  Modal,
  Form,
  Input,
  Select,
  Upload,
  Icon,
  Col,
  Row,
  DatePicker,
  Spin,
  Button,
  Divider,
  Collapse,
  InputNumber,
  Tabs,
} from 'antd';
import React, { Component, useState } from 'react';
import noData from 'assets/no data.svg';
import { FormComponentProps } from 'antd/lib/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { InvoiceRules as r } from '../../invoices/components/FiscalData/rulesFiscalData';
import { formaPago } from 'commons/components/regimenFiscal';
import { banks } from 'commons/bankCatalog';
import { onlyNumberDecimalPattern } from '../../../commons/regularExpressions';
import Text from 'antd/lib/typography/Text';
import { MessagesCustomer } from 'commons/strings';
import {
  Settlement,
  TotalsSettlement,
  DistributionCenter,
  SettlementDepositModal,
} from 'types/type';
import { toNumber } from 'commons/numbersFormat';
import { Permissions as P } from 'commons/permissions';
import { isAllowed, Token, getToken } from 'security';
import { StatementStagingItem } from 'routes/statement/types';
import DepositTableToApply from './depositTableToApply';
import { initDistributionCenter } from 'commons/initTypes';
import JwtDecode from 'jwt-decode';
import './../stylesBankDeposit.css';
import { fileSizeValidator } from 'commons/validators';
import { ApiTypes } from 'commons/services/api';
import { DbOldDetails } from 'database';
import { Globals } from 'commons/globals';

interface CounterProps {
  visible: boolean;
  settlementSearchList: SettlementDepositModal;
  onCancel: Function;
  onCreate(settlement: Settlement[]): void;
  onSearch: Function;
  handleChange: Function;
  form: WrappedFormUtils;
  stagingArea: StatementStagingItem[];
  customerName: string;
  isFetching: boolean;
  handleChangeDistributionCenter: Function;
  distributionCenter: DistributionCenter[];
}
interface State {
  imageUrl: string | undefined;
  loading: boolean;
  tempFile: boolean;
  showTable: boolean;
  selected: boolean;
  stagingArea: Settlement[];
}
class CreateFormStatement extends Component<CounterProps & FormComponentProps> {
  public state: State = {
    imageUrl: undefined,
    loading: false,
    tempFile: false,
    showTable: false,
    selected: false,
    stagingArea: [],
  };
  public changeDistributionCenter = (id: string): void => {
    this.setState({ selected: true });
    this.props.handleChangeDistributionCenter(id);
  };
  private uploadButton = (): JSX.Element => {
    return (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">{'subir'}</div>
      </div>
    );
  };
  private normFile = (e: Storage): File[] => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  private handleBeforeUpload = (file: File): boolean => {
    const newFile = Object.assign({}, file, { originFileObj: file });

    console.log(
      'TIPO:',
      newFile.originFileObj.name.substring(
        newFile.originFileObj.name.lastIndexOf('.') + 1,
      ),
    );
    this.setState({ tempFile: true });
    return true;
  };

  public showDayText = (days: number): string => {
    return days > 1 ? 'Días ' : 'Día ';
  };

  public handleRemove = async (): Promise<void> => {
    await this.props.form.setFieldsValue({ voucher: undefined });
    this.setState({ tempFile: false });
  };

  public footerTable = (): JSX.Element => {
    const totals = this.getTotalStagingArea();
    return (
      <Row>
        <Row>
          <div className="base-for-table">
            <Col span={12} className="blue-cell-table-title">
              <Text className="white-text-table">{'Saldo total'}</Text>
            </Col>
            <Col span={12} className="white-cell-table-title">
              <Text className="blue-text-table">
                {'$ ' +
                  toNumber(
                    Number(
                      Math.round(
                        this.getTotalStagingArea().totalCashCP +
                          this.props.settlementSearchList.totalDebt,
                      ).toFixed(2),
                    ),
                  )}
              </Text>
            </Col>
            <Col span={12} className="grey-cell-table-body">
              <Text className="grey-text">{'Saldo actual'}</Text>
            </Col>
            <Col span={12} className="cell-table-body">
              <Text className="grey-text">
                {'$ ' +
                  toNumber(
                    Number(
                      Math.round(this.getTotalStagingArea().totalCashCP).toFixed(
                        2,
                      ),
                    ),
                  )}
              </Text>
            </Col>
            <Col span={12} className="grey-cell-table-body">
              <Text className="grey-text">{'Saldo pendiente'}</Text>
            </Col>
            <Col span={12} className="cell-table-body">
              <Text className="grey-text">
                {'$ ' +
                  toNumber(
                    Number(
                      Math.round(
                        this.props.settlementSearchList.totalDebt,
                      ).toFixed(2),
                    ),
                  )}
              </Text>
            </Col>
          </div>
          <p />
          <div className="collapse-container">
            <Col span={12} className="blue-cell-table-title">
              <Text className="white-text-table">{'Su depósito'}</Text>
            </Col>
            <Col span={12} className="white-cell-table-title">
              <Text className="blue-text-table">
                {'$ ' +
                  toNumber(
                    Number(
                      Math.round(
                        this.props.form.getFieldValue('balance'),
                      ).toFixed(2),
                    ),
                  )}
              </Text>
            </Col>
            <Col span={12} className="grey-cell-table-body">
              <Text className="grey-text">{'Nuevo saldo pendiente'}</Text>
            </Col>
            <Col span={12} className="cell-table-body">
              <Text className="grey-text">
                {'$ ' +
                  toNumber(
                    Number(
                      Math.round(
                        this.getTotalStagingArea().totalCashCP +
                          this.props.settlementSearchList.totalDebt -
                          Number(this.props.form.getFieldValue('balance')),
                      ).toFixed(2),
                    ),
                  )}
              </Text>
            </Col>
          </div>
          <p />
        </Row>
        <Collapse>
          <Collapse.Panel
            className="collapse-title"
            header={`${'Total contado: $'} ${toNumber(totals.total)}`}
            key="1"
          >
            <Row className="row-collapse-content">
              <Col span={14} offset={0} className="col-collapse-content">
                {'Efectivo (Saldo actual)'}
              </Col>
              <Col span={8} offset={0} className="money-col-collapse-content">
                {'$ ' + toNumber(totals.totalCashCP)}
              </Col>
            </Row>
            <Row className="row-collapse-content">
              <Col span={14} offset={0} className="col-collapse-content">
                {'Tarjeta de crédito'}
              </Col>
              <Col span={8} offset={0} className="money-col-collapse-content">
                {'$ ' + toNumber(totals.totalCredit)}
              </Col>
            </Row>

            <Row className="row-collapse-content">
              <Col span={14} offset={0} className="col-collapse-content">
                {'Tarjeta de débito'}
              </Col>
              <Col span={8} offset={0} className="money-col-collapse-content">
                {'$ ' + toNumber(totals.totalDebit)}
              </Col>
            </Row>

            <Row className="row-collapse-content">
              <Col span={14} offset={0} className="col-collapse-content">
                {'Cheque'}
              </Col>
              <Col span={8} offset={0} className="money-col-collapse-content">
                {'$ ' + toNumber(totals.totalCheque)}
              </Col>
            </Row>

            <Row className="row-collapse-content">
              <Col span={14} offset={0} className="col-collapse-content">
                {'Transferencia'}
              </Col>
              <Col span={8} offset={0} className="money-col-collapse-content">
                {'$ ' + toNumber(totals.totalTransfer)}
              </Col>
            </Row>
          </Collapse.Panel>
        </Collapse>
      </Row>
    );
  };
  public getTotalCash = (): TotalsSettlement => {
    const settlement = this.props.settlementSearchList.settlement
      ? this.props.settlementSearchList.settlement
      : [];
    let total = 0;
    let totalCashCP = 0;
    let totalCashSP = 0;
    let totalCash = 0;
    let totalCredit = 0;
    let totalDebit = 0;
    let totalCheque = 0;
    let totalTransfer = 0;
    settlement.forEach((s: Settlement): void => {
      if(s.equipment!== undefined && s.equipment.arrangeType!==undefined && (s.equipment.arrangeType ==='CP' || s.equipment.arrangeType==='NA')){
        total += s.postAmountCash|| 0;
        totalCash += s.totalCash || 0;
      totalCashCP += s.totalCash || 0;
      totalCredit += s.totalCredit || 0;
      totalDebit += s.totalDebit || 0;
      totalCheque += s.totalCheque || 0;
      totalTransfer += s.totalTransfer || 0;
      }else{
      totalCashSP += s.totalCash || 0;
      }
      
    });
    return {
      total,
      totalCash,
      totalCashCP,
      totalCashSP,
      totalCredit,
      totalDebit,
      totalCheque,
      totalTransfer,
    };
  };

  public getTotalStagingArea = (): /* TotalsSettlement */ DifferenceSectionTotals => {
    const settlement =
      this.state.stagingArea.length > 0 ? this.state.stagingArea : [];
    let total = 0;
    let totalCash = 0;
    let totalCashCP = 0;
    let totalCashSP = 0;
    let totalCredit = 0;
    let totalDebit = 0;
    let totalCheque = 0;
    let totalTransfer = 0;
    let totalAudit = 0;
    settlement.forEach((s: Settlement): void => {
      if(s.equipment!== undefined && s.equipment.arrangeType!==undefined && (s.equipment.arrangeType ==='CP' || s.equipment.arrangeType==='NA')){
        total += s.postAmountCash;
        totalCash += s.totalCash || 0;
        totalCashCP += s.totalCash || 0;
        totalCredit += s.totalCredit || 0;
        totalDebit += s.totalDebit || 0;
        totalCheque += s.totalCheque || 0;
        totalTransfer += s.totalTransfer || 0;
        totalAudit += (s.amountCash || 0) - (s.postAmountCash || 0);
      }else{
      totalCashSP += s.totalCash || 0;
      }
    });
    return {
      total,
      totalCash,
      totalCashCP,
      totalCashSP,
      totalCredit,
      totalDebit,
      totalCheque,
      totalTransfer,
      totalAudit,
    };
  };

  public validatorCheckedItemsOnTable = (record: Settlement): boolean => {
    this.state.stagingArea.filter(
      (s: Settlement): boolean => s.id === record.id,
    );

    if (
      this.state.stagingArea.filter(
        (s: Settlement): boolean => s.id === record.id,
      ).length > 0
    ) {
      return true;
    }
    return false;
  };

  public selectedItemOnTable = (
    record: Settlement,
    selected: boolean,
  ): void => {
    if (selected === false) {
      const stagingArea = this.state.stagingArea;
      const newStagingArea = stagingArea.filter(
        (item: Settlement): boolean => item.id !== record.id,
      );
      this.setState({ stagingArea: newStagingArea });
    } else {
      const stagingArea: Settlement[] = this.state.stagingArea;
      stagingArea.push({
        ...record,
      });
      this.setState({ stagingArea: stagingArea });
    }
  };
  public render = (): JSX.Element => {
    const { visible, onCancel, onCreate, form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Modal
        width={ /* 896 */ 1300 }
        visible={visible}
        title={'Registrar depósito'}
        okText="Abonar"
        cancelText="Cancelar"
        onCancel={onCancel()}
        onOk={() => onCreate(this.state.stagingArea)}
        okButtonProps={{ disabled: this.state.stagingArea.length <= 0 }}
      >
        <Row>
          <Row>
            <Row className="header-bar">
              {isAllowed([P.CAN_VIEW_ALL_DISTRIBUTION_CENTERS]) && (
                <div>
                  <Col span={23} offset={1}>
                    <Text strong style={{ color: '#113C71' }}>
                      {'Selecciona un centro de distribución '}
                    </Text>
                  </Col>
                  <Col span={9} offset={1}>
                    <Select
                      defaultValue=""
                      size="large"
                      style={{ width: '100%' }}
                      onChange={(value: string): void => {
                        this.changeDistributionCenter(value);
                      }}
                    >
                      {this.props.distributionCenter.map(
                        (item, index): React.ReactNode => (
                          <Select.Option key={item.id} value={item.id}>
                            <div id={'dist_' + index.toString()}>
                              {''}
                              {item.name}
                            </div>
                          </Select.Option>
                        ),
                      )}
                    </Select>
                  </Col>
                </div>
              )}
              {isAllowed([P.CAN_VIEW_ALL_DISTRIBUTION_CENTERS]) === false && (
                <Col span={8} offset={1}>
                  <h1>
                    <Text strong>
                      {
                        (
                          this.props.distributionCenter.find(
                            (d: DistributionCenter): boolean =>
                              d.id ===
                              JwtDecode<Token>(getToken() as string)
                                .distributionCenterId,
                          ) || initDistributionCenter
                        ).name
                      }
                    </Text>
                  </h1>
                </Col>
              )}
              <p />
              <Col span={24}>
                <Spin size="large" spinning={this.props.isFetching} />
              </Col>
            </Row>
          </Row>
          {this.props.settlementSearchList.settlement.length <= 0 &&
            this.state.selected === true && (
              <div className="info-message">
                <p />
                &nbsp;
                <p />
                &nbsp;
                <p />
                &nbsp;
                <p />
                <img src={noData} alt="No data" />
                <br />
                Sin saldo pendiente a depositar
              </div>
            )}
          <Tabs defaultActiveKey="2">
            <Tabs.TabPane key="2" tab="Cortes">
              {this.props.settlementSearchList.settlement.length > 0 && (
                <DepositTableToApply
                  selected={this.selectedItemOnTable}
                  validatorChecked={this.validatorCheckedItemsOnTable}
                  validateCheck={(): boolean => false}
                  getTotalCash={this.getTotalCash()}
                  isFetching={this.props.isFetching}
                  settlementSearchList={this.props.settlementSearchList}
                />
              )}
            </Tabs.TabPane>
            <Tabs.TabPane key="1" tab="Depósito">
              {/* this.props.settlementSearchList.settlement.length > 0 && (
                <Row>
                  <Text disabled>
                    Los campos marcados con * son obligatorios
                  </Text>
                </Row>
              ) */}
              {this.props.settlementSearchList.settlement.length > 0 && (
                <Row>
                  <Col span={ /* 11 */ 7 } className="left-column">
                    <Row>
                      <Text disabled>
                        Los campos marcados con * son obligatorios
                      </Text>
                    </Row>
                    <Form layout="vertical">
                      <p />
                      <Col span={11} offset={1}>
                        <Form.Item label="Monto" id="balance">
                          {getFieldDecorator('balance', {
                            initialValue: Number(
                              Math.round(
                                (this.getTotalStagingArea().totalCashCP +
                                  this.props.settlementSearchList.totalDebt) *
                                  100,
                              ) / 100,
                            ),
                            rules: [
                              {
                                pattern: onlyNumberDecimalPattern,
                                required: true,
                                message: 'Introduce saldo!',
                              },
                            ],
                          })(
                            <InputNumber
                              style={{ width: '100%' }}
                              onChange={ (value) => {
                                this.props.form.setFieldsValue({ amountsNotMatch: value ? +(value - this.getTotalStagingArea().totalCashCP).toFixed(2) : 0 });
                              } }
                            />,
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={11} offset={1}>
                        <Form.Item label="Monto SP" id="balancesp">
                          {getFieldDecorator('balancesp', {
                            initialValue: Number(
                              Math.round(
                                (this.getTotalStagingArea().totalCashSP) *
                                  100,
                              ) / 100,
                            ),
                            rules: [
                              {
                                pattern: onlyNumberDecimalPattern,
                                required: true,
                                message: 'Introduce saldo!',
                              },
                            ],
                          })(
                            <InputNumber
                              disabled = {true}
                              style={{ width: '100%' }}
                              onChange={ (value) => {
                                this.props.form.setFieldsValue({ balancesp: value ? +(value - this.getTotalStagingArea().totalCashSP).toFixed(2) : 0 });
                              } }
                            />,
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={0}>
                        <Form.Item id="amout">
                          {getFieldDecorator('amount', {
                            initialValue:
                              Math.round(this.getTotalCash().totalCash * 100) /
                              100,
                            rules: [
                              {
                                pattern: onlyNumberDecimalPattern,
                                required: true,
                                message: 'Introduce monto!',
                              },
                            ],
                          })(<div></div>)}
                        </Form.Item>
                      </Col>
                      <Col span={0}>
                        <Form.Item id="debt">
                          {getFieldDecorator('debt', {
                            initialValue:
                              Math.round(
                                this.props.settlementSearchList.totalDebt * 100,
                              ) / 100,
                            rules: [
                              {
                                required: true,
                                message: 'Introduce a pagar!',
                              },
                            ],
                          })(<div></div>)}
                        </Form.Item>
                      </Col>

                      <Col span={ 0 } >
                        <Form.Item id="amountsNotMatch" >
                          { getFieldDecorator('amountsNotMatch')(<div></div>) }
                        </Form.Item>
                      </Col>
                      <Col span={ 0 } >
                        <Form.Item id="depositDifferences" >
                          { getFieldDecorator('depositDifferences')(<div></div>) }
                        </Form.Item>
                      </Col>

                      <Col span={11} offset={1}>
                        <Form.Item label="Número de cuenta" id="acountRecipt">
                          {getFieldDecorator('acountRecipt', {
                            rules: [
                              {
                                required: true,
                                message: 'Introduce cuenta',
                              },
                            ],
                          })(<Input />)}
                        </Form.Item>
                      </Col>

                      <Col span={11} offset={1}>
                        <Form.Item hasFeedback label="Forma de pago">
                          {getFieldDecorator('paymentForm', {
                            rules: r.formaPagoRule,
                          })(
                            <Select showSearch size="large">
                              {formaPago.map(
                                (item, index): React.ReactNode => (
                                  <Select.Option
                                    key={item.name}
                                    value={item.name}
                                  >
                                    <div id={'paymentForm_' + index.toString()}>
                                      {item.name}
                                    </div>
                                  </Select.Option>
                                ),
                              )}
                            </Select>,
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={11} offset={1}>
                        <Form.Item hasFeedback label="Banco">
                          {getFieldDecorator('bank', {
                            rules: r.bankRule,
                          })(
                            <Select showSearch size="large">
                              {banks.map(
                                (item, index: number): React.ReactNode => (
                                  <Select.Option
                                    key={item.name}
                                    value={item.name}
                                  >
                                    <div id={'banco_' + index.toString()}>
                                      {item.name}
                                    </div>
                                  </Select.Option>
                                ),
                              )}
                            </Select>,
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={11} offset={1}>
                        <Form.Item label="Folio de pago" id="folioDeposit">
                          {getFieldDecorator('folioDeposit', {
                            rules: [
                              {
                                required: true,
                                message: 'Introduce folio de pago',
                              },
                            ],
                          })(<Input />)}
                        </Form.Item>
                      </Col>

                      <Col span={11} offset={1}>
                        <Form.Item hasFeedback label="Fecha de pago">
                          {getFieldDecorator('depositDate', {
                            rules: [
                              {
                                required: true,
                                message: MessagesCustomer.DATE_REQUIRED,
                              },
                            ],
                          })(<DatePicker format="YYYY/MM/DD" />)}
                        </Form.Item>
                      </Col>
                    </Form>

                    {this.props.settlementSearchList.settlement.length > 0 && (
                      <Col span={11} offset={1}>
                        <Form.Item label="Adjuntar comprobante de pago">
                          {getFieldDecorator('voucher', {
                            valuePropName: 'fileList',
                            getValueFromEvent: this.normFile,
                            rules: [
                              {
                                required: true,
                                message: 'Imagen requerida',
                              },
                              {
                                validator: fileSizeValidator(20),
                              },
                            ],
                            initialValue: undefined,
                          })(
                            <Upload
                              name="voucher"
                              action="/"
                              accept="*"
                              listType="text"
                              beforeUpload={this.handleBeforeUpload}
                              onRemove={this.handleRemove}
                              multiple={false}
                              showUploadList={{ showPreviewIcon: false }}
                            >
                              {this.state.tempFile === false ? (
                                <div>
                                  <Button>
                                    <Icon type={'upload'} /> Seleccionar
                                  </Button>
                                </div>
                              ) : null}
                            </Upload>,
                          )}
                        </Form.Item>
                      </Col>
                    )}
                  </Col>
                  <Col span={1} style={{ textAlign: 'center' }} >
                    <Divider type={'vertical'} style={{ height: /* '450px' */ 416 }} />
                  </Col>
                  {/* <Col span={11}>{this.footerTable()}</Col> */}
                  <Col span={ 16 }>
                    <DifferenceSection totals={ this.getTotalStagingArea() } form={ this.props.form } />
                  </Col>
                  {this.props.settlementSearchList.settlement.length > 0 && (
                    <Form layout="vertical">
                      <Col span={ 24 }>
                        <Form.Item label="Comentarios" id="comments">
                          {getFieldDecorator('comments', {
                            rules: [
                              {
                                required: true,
                                max: 500,
                                message:
                                  'Introduce comentarios máximo 500 caracteres',
                              },
                            ],
                          })(<Input.TextArea />)}
                        </Form.Item>
                      </Col>
                    </Form>
                  )}
                </Row>
              )}
            </Tabs.TabPane>
          </Tabs>
        </Row>
      </Modal>
    );
  };
}
export default CreateFormStatement;


/* let formatCurrency = (value: number, { unvalidReturn = 'Monto inválido' } = {}): string => {
  if(isNaN(value)) return unvalidReturn;
  return `$ ${(+value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
} */

type DifferenceSectionTotals = TotalsSettlement & { totalAudit: number };
type DifferenceSectionProps = { totals: DifferenceSectionTotals, form: WrappedFormUtils };
const DifferenceSection = (props: DifferenceSectionProps) => {
  let { formatCurrency, equalsWithPermissiveLimit } = Globals.Utils.Number;
  let { FileInput } = Globals.Components;

  let totalFormItems: { label: string, value: number }[] = [
    { label: 'Tarjeta crédito', value: props.totals.totalCredit },
    { label: 'Cheque', value: props.totals.totalCheque },
    { label: 'Efectivo', value: props.totals.totalCashCP },
    { label: 'Tarjeta débito', value: props.totals.totalDebit },
    { label: 'Transferencia', value: props.totals.totalTransfer },
    { label: 'Total', value: props.totals.total },
  ];

  let paymentWayOptions = [
    { name: DbOldDetails.salePaymentWay[0].name, value: 0 },
    { name: DbOldDetails.salePaymentWay[1].name, value: 1 },
    { name: DbOldDetails.salePaymentWay[2].name, value: 2 },
    { name: DbOldDetails.salePaymentWay[3].name, value: 3 },
    { name: DbOldDetails.salePaymentWay[4].name, value: 4 },
    { name: 'Otro', value: 5 },
  ];

  let [ amount, setAmount ] = useState(0);
  let [ amountsp, setAmountsp ] = useState(0);
  let [ paymentWay, setPaymentWay ] = useState(0);
  let [ otherPaymentWay, setOtherPaymentWay ] = useState("");

  let [ depositDifferences, setDepositDifferences ] = useState([] as (ApiTypes.NewDepositDifference & {file: File | null})[]);
  //let depositDifferences: NewDepositDifference[] = [];

  let amountsNotMatch = () => !equalsWithPermissiveLimit(props.form.getFieldValue('balance'), props.totals.totalCashCP, .2);
  let amountLeftToDiffer = () => +depositDifferences.reduce((acc, cur) => acc + cur.amount, +props.form.getFieldValue('amountsNotMatch') || 0).toFixed(2);

  return (
    <Row>
      <Row>
        <h1 style={{ marginBottom: 0 }} > <small> Totales </small> </h1>
        { totalFormItems.map((totalFormItem, index) => {
          let formItemId = `totalFormItem_${index}`;
          return (
            <Col key={ formItemId } span={ 8 } >
              <div className="ant-row ant-form-item" style={{ marginBottom: 0, paddingBottom: 0, paddingRight: 32 }} >
                <label htmlFor={ formItemId } className="" > { totalFormItem.label }: </label>
                <span style={{ float: 'right' }} > { formatCurrency(totalFormItem.value) } </span>
              </div>
            </Col>
          );
        }) }
      </Row>
      <Divider type="horizontal" style={{ marginTop: 12, marginBottom: 12 }} />
      <Row>
        <Col span={ 12 } >
          {/* <div className="ant-row ant-form-item" style={{ marginBottom: 0, paddingBottom: 0, paddingRight: 32 }} >
            <label htmlFor="" className="" > Entregar al gerente: </label>
            <span style={{ float: 'right' }} > { formatCurrency(props.totals.totalAudit) } </span>
          </div> */}
        </Col>
        <Col span={ 12 } >
          <div className="ant-row ant-form-item" style={{ marginBottom: 0, paddingBottom: 0, paddingRight: 32 }} >
            <label htmlFor="depositExpected" className="" > Monto a depositar esperado: </label>
            <span style={{ float: 'right', backgroundColor: amountsNotMatch() ? 'lemonchiffon' : '' }} > { formatCurrency(props.totals.totalCashCP) } </span>
          </div>
          <div className="ant-row ant-form-item" style={{ marginBottom: 0, paddingBottom: 0, paddingRight: 32 }} >
            <label htmlFor="depositExpected" className="" > Monto a depositar SP: </label>
            <span style={{ float: 'right', backgroundColor: amountsNotMatch() ? 'lemonchiffon' : '' }} > { formatCurrency(props.totals.totalCashSP) } </span>
          </div>
        </Col>
      </Row>
      <Divider type="horizontal" style={{ marginTop: 12, marginBottom: 0 }} />
      <Row>
        <h1 style={{ marginBottom: 0 }} > <small> Diferencias </small> </h1>
        <table style={{ width: '100%' }} >
          <thead style={{ borderBottom: '2px solid black' }} >
            <tr>
              <th>
                <div className="ant-row ant-form-item" style={{ marginBottom: 0, paddingBottom: 0 }} >
                  <label htmlFor="paymentWay" className="" > Método de pago: </label>
                  <Select id="paymentWay" value={ paymentWay } onChange={ value => setPaymentWay(value) } style={{ width: '90%' }} >
                    { paymentWayOptions.map(paymentWayOption => (
                      <Select.Option key={ `pwo_${paymentWayOption.value}` } value={ paymentWayOption.value } > { paymentWayOption.name } </Select.Option>
                    )) }
                  </Select>
                  { paymentWay === 5 && (
                    <Input id="otherPaymentWay" value={ otherPaymentWay } onChange={ event => setOtherPaymentWay(event.target.value.slice(0, 64)) } style={{ width: '90%' }} />
                  ) }
                </div>
              </th>
              <th>
                <div className="ant-row ant-form-item" style={{ marginBottom: 0, paddingBottom: 0 }} >
                  <label htmlFor="amount" className="" > Monto: </label>
                  <InputNumber id="amount" value={ amount } onChange={ value => setAmount(value || 0) } style={{ width: '90%' }} />
                </div>
              </th>
              <th>
                <div className="ant-row ant-form-item" style={{ marginBottom: 0, paddingBottom: 0 }} >
                  <label htmlFor="amount" className="" > Evidencia </label>
                </div>
              </th>
              <th style={{ textAlign: 'right', verticalAlign: 'bottom' }} >
                <Button style={{ backgroundColor: 'royalblue', color: 'white' }} onClick={ () => {
                  console.log(`Clicked add.`);
                  if(!amount) return alert(`El monto no debe ser cero.`);
                  setDepositDifferences(previous => {
                    let newDepositDifferences = [...previous, { bankDepositId: '', amount, paymentWay, otherPaymentWay, fileId: '', file: null }];
                    props.form.setFieldsValue({ depositDifferences: newDepositDifferences });
                    console.log(newDepositDifferences);
                    return newDepositDifferences;
                  });
                  setAmount(0);
                  setAmountsp(0);
                  setPaymentWay(0);
                  setOtherPaymentWay("");
                } } >
                  <Icon type="plus" />
                </Button>
              </th>
            </tr>
          </thead>
          <tbody>
            { amountsNotMatch() ? (
              <tr style={{ height: 34 }} >
                <td style={{ paddingLeft: 6 }} > { paymentWayOptions[0].name } </td>
                <td style={{ textAlign: 'right', paddingRight: 36 }} >
                  <span style={{ backgroundColor: 'lemonchiffon' }} > { formatCurrency(props.form.getFieldValue('balance') - props.totals.totalCashCP) } </span>
                </td>
                <td></td>
              </tr>
            ) : null }
            { depositDifferences.map((depositDifference, index) => {
              let uniqueKey = `${index}_${Globals.Utils.String.random(12)}`
              return (
                <tr key={ `row_${uniqueKey}` } >
                  <td style={{ paddingLeft: 6 }} > { depositDifference.otherPaymentWay || paymentWayOptions[depositDifference.paymentWay].name } </td>
                  <td style={{ textAlign: 'right', paddingRight: 36 }} > { formatCurrency(depositDifference.amount) } </td>
                  <td>
                    <FileInput id={ uniqueKey } file={ depositDifference.file } onChange={ file => {
                      setDepositDifferences(previous => {
                        let newDepositDifferences = [...previous];
                        newDepositDifferences[index].file = file;
                        newDepositDifferences[index].fileId = file ? `file_${+new Date()}` : '';
                        console.log(newDepositDifferences);
                        return depositDifferences;
                      });
                      setTimeout(() => setDepositDifferences([...depositDifferences]), 400);
                    } } placeholder={ '' } />
                  </td>
                  <td style={{ textAlign: 'right' }} >
                    <Button style={{ backgroundColor: 'crimson', color: 'white' }} onClick={ () => {
                      console.log(`Clicked eliminar.`);
                      setDepositDifferences(previous => {
                        let newDepositDifferences = [...previous];
                        newDepositDifferences.splice(index, 1);
                        props.form.setFieldsValue({ depositDifferences: newDepositDifferences });
                        console.log(newDepositDifferences);
                        return newDepositDifferences;
                      });
                    } } >
                      <Icon type='delete' />
                    </Button>
                  </td>
                </tr>
              );
            }) }
            { ( amountsNotMatch() || depositDifferences.length ) ? (
              <tr style={{ borderTop: '2px solid black' }} >
                <td style={{ paddingLeft: 6 }} > Falta por diferir: </td>
                <td style={{ textAlign: 'right', paddingRight: 36 }} >
                  <span style={{}} > { formatCurrency(amountLeftToDiffer()) } </span>
                </td>
                <td style={{ textAlign: 'right' }} >
                  <Button style={{ backgroundColor: 'navy', color: 'white' }} onClick={ () => {
                    let amount = amountLeftToDiffer();
                    console.log(amount);
                    setAmount(-amountLeftToDiffer().toFixed(2));
                  } } >
                    <Icon type="select" />
                  </Button>
                </td>
              </tr>
            ) : null }
          </tbody>
        </table>
      </Row>
    </Row>
  );
};