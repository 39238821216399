//This interface represents the structure of each elements of the value being passed to the validator for upload components
interface FileValue {
  name: string;
  size: number;
}

//This function can be used to create a validator for the size of a file in an Upload component
export const fileSizeValidator = (
  maxFileSizeInMb: number,
): ((_: {}, value: FileValue[], callback: Function) => void) => (
  _: {},
  value: FileValue[],
  callback: Function,
): void => {
  //If the size in MB is bigger than the max size in bytes allowed it calls the callback function with a text (it means that the validation has not been success)
  if (
    value &&
    value.length > 0 &&
    value[0].size / 1024 / 1024 > maxFileSizeInMb
  ) {
    callback(
      `El tamaño del archivo excede el máximo permitido de ${maxFileSizeInMb} MB`,
    );
    return;
  }
  callback();

  return;
};
