/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { Col, Card, Avatar } from 'antd';
import axios, { AxiosResponse } from 'axios';
import { createCoreServiceUrl } from '../../../commons/services';
import {
  SettlementSummaryModel,
  DistributionCenterIndicator,
  BaseWidgetProps,
} from './types';
import BaseWidget from './baseWidget';
import moneyIcon from 'assets/money.svg';
import { toNumber } from 'commons/numbersFormat';
import { sizeScreen } from 'routes/functions';

const { Meta } = Card;

class MainIndicators extends BaseWidget {
  private fetchData(): void {
    const { initialDate, endDate } = this.props;
    const settlementsUrl = createCoreServiceUrl(
      `financialInformation/settlements?dateGreaterThan=${initialDate}&dateLessThan=${endDate}`,
    );
    const accountsReceivableUrl = createCoreServiceUrl(
      `financialInformation/accountsReceivable`,
    );

    const promises: [
      Promise<AxiosResponse<SettlementSummaryModel[]>>,
      Promise<AxiosResponse<DistributionCenterIndicator[]>>,
    ] = [axios.get(settlementsUrl), axios.get(accountsReceivableUrl)];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Promise.all(promises).then((results: any[]): void => {
      this.setState({
        settlements: results[0].data,
        accountsReceivables: results[1].data,
      });
    });
  }

  public componentDidMount(): void {
    this.fetchData();
  }

  public componentDidUpdate = (prevProps: BaseWidgetProps): void => {
    const { initialDate, endDate } = this.props;
    if (prevProps.initialDate === initialDate && prevProps.endDate === endDate)
      return;
    this.fetchData();
  };

  public widgetRender = (): React.ReactNode => {
    const totalSale = this.state.settlements.reduce(
      (acc, s) => s.total + acc,
      0,
    );
    const accRec = this.state.accountsReceivables.reduce(
      (acc, i) => i.value + acc,
      0,
    );
    const totalCash = this.state.settlements.reduce(
      (acc, s) => s.amountCash + acc,
      0,
    );
    const totalVolume = this.state.settlements.reduce(
      (acc, s) => s.totalVolume + acc,
      0,
    );
    return (
      <div>
        <Col {...sizeScreen(6)}>
          <Card>
            {' '}
            <Meta
              avatar={<Avatar src={moneyIcon} />}
              title={`Litros totales: ${toNumber(totalVolume)} L`}
            />
          </Card>
        </Col>
        <Col {...sizeScreen(6)}>
          <Card>
            {' '}
            <Meta
              avatar={<Avatar src={moneyIcon} />}
              title={`Venta Total: $${toNumber(totalSale)}`}
            />
          </Card>
        </Col>
        <Col {...sizeScreen(6)}>
          <Card>
            {' '}
            <Meta
              avatar={<Avatar src={moneyIcon} />}
              title={`Cuentas por Cobrar: $${toNumber(accRec)}`}
            />
          </Card>
        </Col>
        <Col {...sizeScreen(6)}>
          <Card>
            {' '}
            <Meta
              avatar={<Avatar src={moneyIcon} />}
              title={`Venta Contado: $${toNumber(totalCash)}`}
            />
          </Card>
        </Col>
      </div>
    );
  };
}

export default MainIndicators;
