import React, { Component, FormEvent } from 'react';
import { Card, Form, Input, Button, Icon } from 'antd';
import { emailPattern } from './../../commons/regularExpressions';
import { connect } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { trysendpassword } from './../users/redux/actions';
import {} from './../users/redux/reducers';
import './style.css';
import { FormComponentProps } from 'antd/lib/form';
import { enterpriceLogo as logo } from 'boot/config';
import { Redirect } from 'react-router';

interface DispatchProps {
  trysendpassword: (values: string) => void;
}

type Props = FormComponentProps & DispatchProps;

class ForgotPassword extends Component<Props> {
  public state = {};

  private handleOnSubmit = (e: FormEvent): void => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values): void => {
      if (!err) {
        this.props.trysendpassword(values.email);
      }
    });
  };

  public render = (): JSX.Element => {
    const {
      form: { getFieldDecorator },
    } = this.props;
    if (localStorage.getItem('token')) return <Redirect to="/" />;
    return (
      <Card className="password-card">
        <img className="side-logo" src={logo} alt="company_logo" />
        <h1>¿Olvidaste tu contraseña?</h1>
        <h3>
          Te podemos ayudar a reestablecer tu contraseña. Primero, ingresa tu
          email y te enviaremos un correo para restablecerla.
        </h3>
        <br />

        <Form onSubmit={this.handleOnSubmit} className="login-form">
          <Form.Item>
            {getFieldDecorator('email', {
              rules: [
                { pattern: emailPattern, message: 'Ingresa un email valido' },
              ],
            })(
              <Input
                size="large"
                prefix={<Icon type="user" />}
                placeholder="Email"
              />,
            )}
          </Form.Item>
          <Button className="full-width" type="primary" htmlType="submit">
            Enviar
          </Button>
          <Button className="full-width" id="restart_button">
            <a className="forgot-password-link float-right" href="/login">
              Cancelar
            </a>
          </Button>
        </Form>
      </Card>
    );
  };
}
const mapStateToProps = (): {} => {
  return {};
};

const mapDispatchToProps = {
  trysendpassword,
};

export default connect<{}, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create()(ForgotPassword));
