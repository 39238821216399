import React, { useCallback } from "react";
import { Icon } from "antd";
import { DbOldTypes, DbOldValues } from "database";
import { ApiRoute, callApiGetFile } from "./services/api";
import { Popups } from "./components/popups/popups";

class Utils {
    static Date = {
        timeToString: (time = new Date(), useMs = false): string => {
            let hh = Utils.String.fillLeft(`${time.getHours()}`, '0', 2);
            let mm = Utils.String.fillLeft(`${time.getMinutes()}`, '0', 2);
            let ss = Utils.String.fillLeft(`${time.getSeconds()}`, '0', 2);
            let ms = Utils.String.fillLeft(`${time.getMilliseconds()}`, '0', 3);
            return `${hh}:${mm}:${ss}` + (useMs ? `::${ms}` : '');
        },
        dateToString: ({ date = new Date(), separatorDate = '-' } = {}): string => {
            let YYYY = Utils.String.fillLeft(`${date.getFullYear()}`, '0', 4);
            let MM = Utils.String.fillLeft(`${date.getMonth() + 1}`, '0', 2);
            let DD = Utils.String.fillLeft(`${date.getDate()}`, '0', 2);
            return `${YYYY}-${MM}-${DD}`;
        },

        dateToStringMinusDays: (date: Date, days: number): string => {
            let newDate = new Date(+date);
            newDate.setDate( newDate.getDate() - days );
            return Utils.Date.dateToString({ date: newDate, separatorDate: '-' });
        },
    };
    static Number = {
        formatCurrency: (value: number, { unvalidReturn = 'Monto inválido' } = {}): string => {
            if(isNaN(value)) return unvalidReturn;
            let str = `$ ${(+value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
            if(str === '$ -0.00') return `$ 0.00`;
            return str;
        },
        random: (min: number, max: number) => {
            return Math.floor(Math.random()*((max + 1) - min)) + min;
        },

        equalsWithPermissiveLimit: (valueToCompare: number, expectedValue: number, permissiveLimit: number) => {
            return valueToCompare >= ( expectedValue - permissiveLimit ) && valueToCompare <= ( expectedValue + permissiveLimit );
        }
    };
    static String = {
        random: (length: number, pos = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789') => {
            let ret = '';
            for(let i = 0; i < length; i++) ret += pos[Utils.Number.random(0, pos.length - 1)];
            return ret;
        },
        fillLeft: (str: string, char: string, length: number): string => {
            if(str.length >= length) return str;
            else return Utils.String.fillLeft(`${char}${str}`, char, length);
        },
        fillRight: (str: string, char: string, length: number): string => {
            if(str.length >= length) return str;
            else return Utils.String.fillRight(`${str}${char}`, char, length);
        },
    };
    static File = {
        createTmpUrlFromBlob: (blob: Blob, mime?: DbOldTypes.BucketFile['mime']) => {
            //if(typeKey == DbOldValues.BucketFileType.IMAGE) blob = blob.slice(0, blob.size, 'image');
            //if(typeKey == DbOldValues.BucketFileType.PDF) blob = blob.slice(0, blob.size, 'application/pdf');
            blob = blob.slice(0, blob.size, mime);
            let url = URL.createObjectURL(blob);
            console.log(url);
            return url;
        },
        createTmpUrlFromBucketFile: async (file: DbOldTypes.BucketFile) => {
            try{
                let blob = await callApiGetFile(ApiRoute.GetBucketFile, { bucketPath: file.path });
                if(!blob) throw 'No blob';
                if(file.type_key === DbOldValues.BucketFileType.PDF) blob = blob.slice(0, blob.size, 'application/pdf');
                return URL.createObjectURL(blob);
            }catch(error){
                console.log(error);
            }
        },
        openBucketFileInNewTab: async (file: DbOldTypes.BucketFile) => {
            try{
                let blob = await callApiGetFile(ApiRoute.GetBucketFile, { bucketPath: file.path });
                if(!blob) throw 'No blob';
                let tmpUrl = Utils.File.createTmpUrlFromBlob(blob, file.mime);
                window.open(tmpUrl, '_blank');
            }catch(error){
                console.log(error);
                Popups.notifyError(`No se pudo visualizar el archivo.`);
            }
        },
        downloadBlob: (blob: Blob, filename: string) => {
            let a = document.createElement('a');
            a.download = filename;
            a.href = Utils.File.createTmpUrlFromBlob(blob);
            a.click();
        }
    };
    static Array = {
        sort: <T extends any>(arr: T[], criterias: { direction: 'asc' | 'desc', valueFn: (item: T) => string | number }[], { modifyOriginal = true } = {}): T[] => {
            let arrToSort: T[] = modifyOriginal ? arr : [ ...arr ];
            return arrToSort.sort((a, b) => {
                for(let criteria of criterias){
                    let aValue = criteria.valueFn(a);
                    let bValue = criteria.valueFn(b);
                    if(aValue === bValue) continue;
                    let directionCondition: boolean = criteria.direction === 'asc' ? aValue > bValue : aValue < bValue;
                    return directionCondition ? 1 : -1;
                }
                return 0;
            });
        },
    };
    static Object = {
        keys: <T extends {}>(obj: T): (string & keyof T)[] => {
            return Object.keys(obj) as (string & keyof T)[];
        },
        entries: <T extends {}>(obj: T): ({ index: number, key: (string | number) & keyof T, value: T[keyof T] })[] => {
            return Utils.Object.keys(obj).map((key, index) => ({
                index,
                key,
                value: obj[key],
            }));
        },
    };

    static Prompt = {
        dateString: async (msg: string, { initial = '' } = {}): Promise<null | string> => {
            let date: string | null = '';
            while(date != null && !date) date = prompt(`${msg}\nFormato: YYYY-MM-DD.`, initial);
            return date;
        },
        number: async (msg: string, { initial = '', min = -Infinity, max = Infinity } = {}): Promise<null | number> => {
            let numberStr: string | null = 'NaN';
            while(numberStr != null && isNaN(+numberStr)) numberStr= prompt(`${msg}`, initial);
            return numberStr === null ? null : +numberStr;
        },
        confirm: (msg: string): boolean => {
            return window.confirm(msg);
        }
    };

    static Time = {
        sleep: async (ms: number) => {
            await new Promise(res => setTimeout(res, ms));
        },
    };
};

type FileInputProps = {
    id: string,
    file: File | null,
    onChange: (file: File | null) => void,
    placeholder?: string,
};
class Components {
    static FileInput = (props: FileInputProps) => {
        let inputRef = React.useRef<HTMLInputElement>(null);

        let inputRefClick = useCallback(() => {
            if(!inputRef.current) return;
            inputRef.current.click();
        }, [ inputRef.current ]);

        let deleteFile = useCallback(() => {
            //setFile(null);
            props.onChange(null);
        }, [ props.onChange ]);

        let placeholder: string = props.placeholder === undefined ? '-- Adjuntar archivo --' : props.placeholder;

        return (
            <div key={ `${props.id}` } >
                <div key={ `div_${props.id}` } >
                    <button key={ `button_${props.id}` } style={{ backgroundColor: props.file ? 'red' : 'green', color: 'white' }} onClick={ props.file ? deleteFile : inputRefClick } >
                        <Icon type={ props.file ? "close" : "upload" } />
                    </button>
                    <span key={ `span_${props.id}` } > { props.file ? props.file.name : placeholder } </span>
                </div>
                <input key={ `input_${props.id}` } type="file" ref={ inputRef } style={{ display: 'none' }} onChange={ event => {
                    let [ file = null ] = event.target.files || [];
                    //setFile(file);
                    props.onChange(file);
                } } />
            </div>
        );
    };
}

export class Globals {
    static Utils = Utils;
    static Components = Components;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace AppTypes {
    export type SelectOption<T extends string | number = string> = { value: T, name: string };

    export type NonNullablePick<T, K extends keyof T> = Pick<{[KT in keyof T]: NonNullable<T[KT]>}, K>;
}