/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by David Canul <david@ssf.com.mx>, Jun 2019
 *
 *
 */
import Statementervice from '../../../services/statement';
import { parseApiError } from '../../../commons/error';
import CustomerService from '../../../services/customers';
import { notification } from 'antd';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {
  StatementAction,
  BEGIN_FETCH,
  FETCH_COMPLETED,
  SET_STATEMENTS,
  SET_STATEMENT,
  SET_SEARCH_STATEMENTS,
  SET_STATEMENTS_STATUS,
  SET_STATEMENT_SALES,
  RESET_STATEMENT_SALES,
  SET_CUSTOMER_LIST,
  SET_SINGLE_STATEMENT_SALE,
} from './types';
import { getIsFetching } from './reducers';
import { RootState } from 'store/configureStore';
import {
  DataToCustomInvoice,
  StatementFilters,
  ModelSaleMove,
  Statement,
  ModelSaleMoveAll,
} from 'types/type';
import SaleService from 'services/sale';
import { initSale } from 'commons/initTypes';
import { Popups } from 'commons/components/popups/popups';

const statementervice = new Statementervice();
const saleService = new SaleService();
const customerService = new CustomerService();

const startFetch = (): StatementAction => {
  return { type: BEGIN_FETCH };
};
type ITA = ThunkAction<Promise<void>, RootState, {}, StatementAction>;
type IDA = ThunkDispatch<{}, {}, StatementAction>;
/**
 * This action creator is not public beca1use it should be used
 * automatically by other action creators that need fetch data from
 * backend.
 */
const endFetch = (): StatementAction => {
  return { type: FETCH_COMPLETED };
};

/**
 *This action give statement from db by filters and Page
 */

export const save = (payment: FormData): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    let responseStatement;

    responseStatement = await statementervice.savePayment(payment);
    dispatch({ type: SET_STATEMENT, payment: responseStatement });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    throw err;
  }
};

export const page = (
  customerCode: string,
  filters: StatementFilters,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    let responsepage;
    let responseStatus;
    responsepage = await statementervice.statementList(customerCode, filters);
    responseStatus = await statementervice.statementStatus(customerCode);
    dispatch({ type: SET_STATEMENTS, statementList: responsepage });
    dispatch({ type: SET_STATEMENTS_STATUS, acountStatus: responseStatus });
    responsepage = await statementervice.statementLisSearch(customerCode, 'na');
    dispatch({
      type: SET_SEARCH_STATEMENTS,
      statementSearchList: responsepage,
    });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};

export const search = (customerCode: string, reference: string): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    let responsepage;
    responsepage = await statementervice.statementLisSearch(
      customerCode,
      reference,
    );
    dispatch({
      type: SET_SEARCH_STATEMENTS,
      statementSearchList: responsepage,
    });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};
export const stampSale = (
  saleId: string,
  customData: DataToCustomInvoice,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(endFetch());
    await statementervice.statmpSale(saleId, customData);
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    throw err;
  }
};
export const downloadFile = (
  id: string,
  name: string,
  saleFolio: string,
): ITA => async (): Promise<void> => {
  statementervice.downloadFile(id, name, saleFolio);
};

/** Action to generate an Excel file with the data filtered, and download it */
export const downloadReport = (
  customerCode: string,
  statementFilters: StatementFilters,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) return Promise.resolve();
  try {
    dispatch(startFetch());
    await statementervice.generateReport(customerCode, statementFilters);
    dispatch(endFetch());
  } catch (error) {
    dispatch(endFetch());
    notification.error(parseApiError(error));
  }
};

export const getSalesByStatementId = (statementId: string): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(endFetch());
    const response = await saleService.getByStatementId(statementId);
    dispatch({ type: SET_STATEMENT_SALES, statementSales: response });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    throw err;
  }
};

export const resetStatementSales = (): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(endFetch());
    dispatch({ type: RESET_STATEMENT_SALES });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    throw err;
  }
};
export const getCustomersByFilter = (filter: string): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    await dispatch(startFetch());
    const customer = await customerService.listCustomerByFilter(filter);

    dispatch({ type: SET_CUSTOMER_LIST, customersList: customer });

    await dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};

export const splitStatement = (id: string): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) return Promise.resolve();
  try {
    dispatch(startFetch());
    await statementervice.splitStatementById(id);
    dispatch(endFetch());
  } catch (error) {
    dispatch(endFetch());
    notification.error(parseApiError(error));
  }
};

export const saveSaleMove = (modelSaleMove: ModelSaleMove): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    await dispatch(startFetch());
    await statementervice.saveSaleMove(modelSaleMove);
    await dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};

export const saveSaleMoveAll = (modelSaleMove: ModelSaleMoveAll): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    await dispatch(startFetch());
    await statementervice.saveSaleMoveAll(modelSaleMove);
    await dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
};
export const mergeStatements = (statementIds: string[]): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) return Promise.resolve();

  try {
    dispatch(startFetch());
    await statementervice.mergeStatementList(statementIds);
    dispatch(endFetch());
  } catch (error) {
    dispatch(endFetch());
    notification.error(parseApiError(error));
  }
};

export const editStatement = (statement: Statement): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) return Promise.resolve();

  try {
    dispatch(startFetch());
    await statementervice.updateStatement(statement);
    dispatch(endFetch());
  } catch (error) {
    dispatch(endFetch());
    notification.error(parseApiError(error));
  }
};

export const findSaleByStatement = (statementId: string): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) return Promise.resolve();

  try {
    dispatch(startFetch());
    const salesFound = await saleService.getByStatementId(statementId);
    let sale = initSale;
    if (salesFound.length > 0) sale = salesFound[0];
    dispatch({ type: SET_SINGLE_STATEMENT_SALE, singleStatementSale: sale });
    dispatch(endFetch());
  } catch (error) {
    dispatch(endFetch());
    notification.error(parseApiError(error));
  }
};

export const resetStatementSale = (): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) return Promise.resolve();

  try {
    dispatch(startFetch());
    dispatch({
      type: SET_SINGLE_STATEMENT_SALE,
      singleStatementSale: initSale,
    });
    dispatch(endFetch());
  } catch (error) {
    dispatch(endFetch());
    notification.error(parseApiError(error));
  }
};

export const cancelStatement = (id: string): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) return Promise.resolve();

  try {
    dispatch(startFetch());
    await statementervice.statementCancelation(id);
    dispatch(endFetch());
  } catch (error) {
    dispatch(endFetch());
    notification.error(parseApiError(error));
  }
};
export const forceCanceInvoice = (
  id: string,
  selectedInvoiceId: string,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) return Promise.resolve();

  try {
    dispatch(startFetch);
    await statementervice.forceCanceInvoice(id, selectedInvoiceId);
    dispatch(endFetch());
  } catch (error) {
    dispatch(endFetch);
    notification.error(parseApiError(error));
  }
};

export const downloadZip = (
  code: string,
  filters: StatementFilters,
): ITA => async (dispatch: IDA, getState: Function): Promise<void> => {
  if (getIsFetching(getState())) return Promise.resolve();

  dispatch(startFetch());
  try {
    await statementervice.getZipFile(code, filters);
  } catch (error) {
    if (error.response.status === 404) {
      Popups.notifyError('No se encontraron archivos');
      dispatch(endFetch());
      return;
    }
    notification.error(parseApiError(error));
  }
  dispatch(endFetch());
};
