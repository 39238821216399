import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Button, Col, Pagination, Divider, Card } from 'antd';
import './style.css';
import { page } from './redux/actions';
import { CompanyListI } from './redux/reducers';
import { RootState } from '../../store/configureStore';
import { Link } from 'react-router-dom';
import { Company } from 'types/type';
import Text from 'antd/lib/typography/Text';
import noCompany from 'assets/noCompany.svg';
import { isAllowed } from 'security';
import { Permissions as permissions } from '../../commons/permissions';

interface DispatchProps {
  page: (page: number) => void;
}
interface StateProps {
  companyList: CompanyListI;
}
type Props = StateProps & DispatchProps;

class Users extends Component<Props> {
  public state = {
    data: [],
    valor: 0,
    paginationenable: true,
    visible: false,
    positionItem: 0,
  };

  public componentDidMount(): void {
    this.props.page(1);
    this.setState({ positionItem: 0 });
  }

  public handlePaginationChange = (current: number): void => {
    this.props.page(current);
    this.setState({ valor: current, positionItem: 0 });
  };

  public render = (): JSX.Element => {
    const { content } = this.props.companyList;
    if (content !== undefined) {
      if ((content.length || []) < 1) {
        return (
          <Card className="customer-main-card">
            <Card
              bordered={false}
              className="login-card"
              cover={
                <div className="center-items-card">
                  <Row>
                    <img src={noCompany} alt="No company" />
                  </Row>
                  <Row>
                    <Text>Sin Empresas agregadas</Text>
                  </Row>
                  <Row>
                    <Link
                      key="1"
                      to={{
                        pathname: '/enterprice/form/0'
                      }}
                    >
                      <Button type="primary">
                        {'+ Agregar Empresa fiscal'}
                      </Button>
                    </Link>
                  </Row>
                </div>
              }
            />
          </Card>
        );
      }
    }
    return (
      <Row style={{ backgroundColor: '#fff' }} gutter={1}>
        <p />
        <Row>
          {isAllowed([permissions.CAN_VIEW_COMPANY_DETAILS]) && (
            <Col
              xs={24}
              sm={9}
              md={7}
              lg={5}
              xl={4}
              xxl={3}
              offset={1}
              style={{ marginTop: '10px' }}
            >
              <Link
                key="1"
                to={{
                  pathname: '/enterprice/form/0'
                }}
              >
                <Button
                  type="primary"
                  className="button-new"
                  size="large"
                  icon="plus"
                  id="newCompany_button"
                >
                  {'Agregar empresa fiscal'}
                </Button>
              </Link>
            </Col>
          )}
          {isAllowed([permissions.CAN_MANAGE_EQUIPMENTS]) && (
            <Col
              xs={24}
              sm={9}
              md={7}
              lg={4}
              xl={4}
              xxl={3}
              offset={1}
              style={{ marginTop: '10px' }}
            >
              <Link key="2" to={'/equipments'}>
                <Button
                  type="primary"
                  className="button-new"
                  size="large"
                  icon="setting"
                  id="manage_equipments_button"
                >
                  {'Administrar equipos'}
                </Button>
              </Link>
            </Col>
          )}
        </Row>
        <Divider />
        <Col span={24}>
          <Row gutter={1}>
            {content !== undefined
              ? content.map(
                  (item: Company): JSX.Element => {
                    return (
                      <Col
                        key={item.name}
                        xs={{ span: 22, offset: 1 }}
                        sm={{ span: 22, offset: 1 }}
                        md={{ span: 11, offset: 1 }}
                        lg={{ span: 11, offset: 1 }}
                        xl={{ span: 7, offset: 1 }}
                        xxl={{ span: 7, offset: 1 }}
                      >
                        {(): void =>
                          this.setState({
                            positionItem: this.state.positionItem + 1,
                          })
                        }
                        <Card
                          title={item.name}
                          headStyle={{
                            backgroundColor: '#113c71',
                            color: 'white',
                          }}
                          extra={
                            isAllowed([
                              permissions.CAN_VIEW_COMPANY_DETAILS,
                            ]) && (
                              <Link
                                key="1"
                                to={{
                                  pathname: `/enterprice/form/${item.id}`
                                }}
                                style={{ color: 'black' }}
                              >
                                <Button
                                  type="link"
                                  key={item.id + '2'}
                                  id={`edit_${this.state.positionItem}`}
                                >
                                  Editar
                                </Button>
                              </Link>
                            )
                          }
                        >
                          <Col>
                            <p>
                              <Text strong style={{ color: '#113c71' }}>
                                {'RFC: '}
                              </Text>
                              {item.rfc}
                            </p>
                            <p>{item.state}</p>
                          </Col>
                        </Card>
                      </Col>
                    );
                  },
                )
              : null}
          </Row>
          <p />
        </Col>
        <Pagination
          current={(this.props.companyList.number || 0) + 1}
          total={this.props.companyList.totalElements}
          onChange={this.handlePaginationChange}
        />
      </Row>
    );
  };
}

const mapStateToProps = (states: RootState): StateProps => {
  return {
    companyList: states.companyState.companyList,
  };
};

const mapDispatchToProps = {
  page,
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Users);
