import React from 'react';
import { Redirect } from 'react-router-dom';
import {
  removeToken,
  removeIncognitMode,
  getCustomerCode,
} from '../../security/';

class LogOut extends React.PureComponent {
  public state = {
    redirect: false,
  };
  public clicklogout = (): void => {
    this.setState({
      redirect: true,
    });
  };

  public render = (): React.ReactNode => {
    if (getCustomerCode()) {
      removeToken();
      removeIncognitMode();
      return <Redirect to="/clientes" />;
    }
    removeToken();
    removeIncognitMode();

    return <Redirect to="/login" />;
  };
}

export default LogOut;
