import React, { ReactElement, Component, ReactText } from 'react';
import { Select } from 'antd';
import Form, { ValidationRule } from 'antd/lib/form';
import { GetFieldDecoratorOptions } from 'antd/lib/form/Form';
import { SelectValue } from 'antd/lib/select';

type RecordCustomed = Record<string, ReactText | string | number | object>;

interface ComponentProps<T extends RecordCustomed = {}> {
  options: T[];
  labelVarName: string | string[];
  valueVarName?: string;
  action: Function;
  keyVarName?: string;
  separator?: string;
  mode?: string;
  isDisabled?: boolean;
  defaultValues?: string | string[] | number | number[];
  isOpen?: boolean;
  index?: number;
  isForm?: boolean;
  initialValue?: string | number;
  name?: string;
  label?: string;
  rule?: ValidationRule[];
  getFieldDecorator?: <T extends RecordCustomed = {}>(
    id: keyof T,
    options?: GetFieldDecoratorOptions | undefined,
  ) => (node: React.ReactNode) => React.ReactNode;
}
interface SelectOption {
  label: string;
  value: string | number;
  key: string | number;
}

type Props = ComponentProps;

class GenericSelect extends Component<Props> {
  private genericSelect = <T extends RecordCustomed = {}>(
    options: T[],
    labelVarName: string | string[],
    valueVarName: string = '',
    action: Function,
    keyVarName: string = '',
    separator: string = '',
    mode: string = 'default',
    isDisabled: boolean = false,
    defaultValues: string | number | string[] | number[] = '',
    isOpen: boolean = false,
    index: number = 0,
    isForm: boolean = false,
    initialValue: string | number = '',
    name: string = '',
    label?: string,
    rule?: ValidationRule[],
  ): ReactElement => {
    const defaultVelueVerifield =
      mode === 'default' &&
      typeof defaultValues !== 'string' &&
      typeof defaultValues !== 'number'
        ? defaultValues[0]
        : defaultValues;

    let selectOptions: SelectOption[] = [];
    options.forEach((option: T): void => {
      const objectToArray = Object.entries(option);
      let objectExtracted: SelectOption = { label: '', value: '', key: '' };

      if (typeof labelVarName === 'string') {
        objectToArray.forEach((o): void => {
          if (o[0] === labelVarName) objectExtracted.label = String(o[1]);
          if (o[0] === valueVarName) {
            if (typeof o[1] === 'number' || typeof o[1] === 'string')
              objectExtracted.value = o[1];
          }
          if (o[0] === keyVarName) {
            if (typeof o[1] === 'number' || typeof o[1] === 'string')
              objectExtracted.value = o[1];
          }
        });
      } else {
        const labelVarNameSyze: number = labelVarName.length;
        labelVarName.forEach((labelName: string, index: number): void => {
          objectToArray.forEach((o): void => {
            if (o[0] === labelName)
              objectExtracted.label = objectExtracted.label.concat(
                String(o[1]),
                index < labelVarNameSyze - 1 ? separator : '',
              );
            if (o[0] === valueVarName) {
              if (typeof o[1] === 'number' || typeof o[1] === 'string')
                objectExtracted.value = o[1];
            }
            if (o[0] === keyVarName) {
              if (typeof o[1] === 'number' || typeof o[1] === 'string')
                objectExtracted.value = o[1];
            }
          });
        });
      }
      selectOptions.push(Object.assign({}, objectExtracted));
    });
    const selectForm: ReactElement = this.props.getFieldDecorator ? (
      <Form.Item hasFeedback label={label}>
        {this.props.getFieldDecorator(name, {
          initialValue:
            initialValue !== '' ? initialValue : defaultVelueVerifield,
          rules: rule,
        })(
          <Select
            size={'large'}
            mode={mode}
            key={index}
            disabled={isDisabled}
            defaultOpen={isOpen}
            onChange={(event: SelectValue): void => action(event, index)}
          >
            {selectOptions.map(
              (option, index: number): JSX.Element => (
                <Select.Option
                  key={option.key === '' ? index : option.key}
                  value={mode !== 'tags' ? option.value : String(option.value)}
                >
                  {option.label}
                </Select.Option>
              ),
            )}
          </Select>,
        )}
      </Form.Item>
    ) : (
      <div>GetFieldDecorator is required for form item</div>
    );
    const select: ReactElement = (
      <Select
        size={'large'}
        mode={mode}
        key={index}
        disabled={isDisabled}
        defaultValue={defaultVelueVerifield ? defaultVelueVerifield : undefined}
        defaultOpen={isOpen}
        onChange={(event): void => action(event, index)}
      >
        {selectOptions.map(
          (option, index: number): ReactElement => (
            <Select.Option
              key={option.key === '' ? index : option.key}
              value={option.value}
            >
              {option.label}
            </Select.Option>
          ),
        )}
      </Select>
    );
    return isForm ? selectForm : select;
  };
  public render = (): ReactElement => {
    const {
      options,
      labelVarName,
      valueVarName,
      action,
      keyVarName,
      separator,
      mode,
      isDisabled,
      defaultValues,
      isOpen,
      index,
      isForm,
      initialValue: value,
      name,
      label,
      rule,
    } = this.props;
    return (
      <div>
        {this.genericSelect(
          options,
          labelVarName,
          valueVarName,
          action,
          keyVarName,
          separator,
          mode,
          isDisabled,
          defaultValues,
          isOpen,
          index,
          isForm,
          value,
          name,
          label,
          rule,
        )}
      </div>
    );
  };
}
export default GenericSelect;
