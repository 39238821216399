import Service from 'services/Service';
import { Permissions } from 'types/type';
import axios from 'axios';
import { createCoreServiceUrl } from 'commons/services';

class PermissionService extends Service {
  public getAll = async (): Promise<Permissions[]> =>
    (await axios.get(createCoreServiceUrl(`permissions`))).data;
}
export default PermissionService;
