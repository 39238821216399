/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Hector Oliva <hector@ssf.com.mx>, Sep 2020
 *
 * This widget project all selected equipments
 */

import React from 'react';
import { ResponsiveBar, BarDatum } from '@nivo/bar';
import BaseWidget from './baseWidget';
import axios, { AxiosResponse } from 'axios';
import * as _ from 'lodash';
import { createCoreServiceUrl } from '../../../commons/services';
import { SettlementSummaryModel, BaseWidgetProps } from './types';
import { toNumber } from 'commons/numbersFormat';
import { hasValue } from 'commons/functions';
import { Row, Col, Select } from 'antd';
import { Equipment } from 'types/type';
const { Option } = Select;

interface BarChartItem {
  equipments: string;
  'Aut. Contado': number;
  'Carb. Contado': number;
  'Aut. Crédito': number;
  'Carb. Crédito': number;
}

class EquipmentSaleBarChart extends BaseWidget {
  public fetchData(equipmentList: string[]): void {
    const { initialDate, endDate } = this.props;
    const url = `financialInformation/settlements?dateGreaterThan=${initialDate}&dateLessThan=${endDate}&equipmentList=${equipmentList}`;
    axios
      .get(createCoreServiceUrl(url))
      .then((response: AxiosResponse<SettlementSummaryModel[]>): void => {
        this.setState({ settlements: response.data });
      });
  }

  public componentDidUpdate = (prevProps: BaseWidgetProps): void => {
    const { initialDate, endDate } = this.props;
    if (prevProps.initialDate === initialDate && prevProps.endDate === endDate)
      return;
    this.fetchData(this.state.selectedEquipment);
  };

  public componentDidMount(): void {
    this.fetchData(this.state.selectedEquipment);
  }

  private getEquipmentName = (equipmentFilter: string): string => {
    const equip = this.props.equipment.find(
      (e: Equipment): boolean => e.idG4s === equipmentFilter,
    );
    if (equip) return equip.tag + ' Num: ' + equip.number;
    return equipmentFilter;
  };

  private calculateData(settlements: SettlementSummaryModel[]): BarChartItem[] {
    let barsettlements = settlements.filter(
      (item: SettlementSummaryModel): boolean => {
        return (
          item.equipmentId ===
          this.state.selectedEquipment.find(
            (element: string): boolean => element === item.equipmentId,
          )
        );
      },
    );
    let kilogramsValue = 0.56;
    if (hasValue(this.props.kilogramsValue)) {
      kilogramsValue = this.props.kilogramsValue;
    }
    return _.chain(barsettlements)
      .groupBy('equipmentId')
      .map(
        (value, key): BarChartItem => {
          return {
            equipments: this.getEquipmentName(key),
            'Aut. Contado': value
              .filter(
                (s: SettlementSummaryModel): boolean =>
                  s.serviceType === 'AUTOTANQUE',
              )
              .reduce((acc, s): number => {
                switch (this.props.radioSelected) {
                  case 'volume':
                    return s.totalVolumeCash + acc;
                  case 'weight':
                    return s.totalVolumeCash * kilogramsValue + acc;
                  default:
                    return s.amountCash + acc;
                }
              }, 0),
            'Aut. Crédito': value
              .filter(
                (s: SettlementSummaryModel): boolean =>
                  s.serviceType === 'AUTOTANQUE',
              )
              .reduce((acc, s): number => {
                switch (this.props.radioSelected) {
                  case 'volume':
                    return s.totalVolumeCredit + acc;
                  case 'weight':
                    return s.totalVolumeCredit * kilogramsValue + acc;
                  default:
                    return s.amountCredit + acc;
                }
              }, 0),
            'Carb. Contado': value
              .filter(
                (s: SettlementSummaryModel): boolean =>
                  s.serviceType === 'CARBURACION',
              )
              .reduce((acc, s): number => {
                switch (this.props.radioSelected) {
                  case 'volume':
                    return s.totalVolumeCash + acc;
                  case 'weight':
                    return s.totalVolumeCash * kilogramsValue + acc;
                  default:
                    return s.amountCash + acc;
                }
              }, 0),
            'Carb. Crédito': value
              .filter(
                (s: SettlementSummaryModel): boolean =>
                  s.serviceType === 'CARBURACION',
              )
              .reduce((acc, s): number => {
                switch (this.props.radioSelected) {
                  case 'volume':
                    return s.totalVolumeCredit + acc;
                  case 'weight':
                    return s.totalVolumeCredit * kilogramsValue + acc;
                  default:
                    return s.amountCredit + acc;
                }
              }, 0),
          };
        },
      )
      .value();
  }

  public widgetRender = (): React.ReactNode => {
    const data = this.calculateData(this.state.settlements);
    return (
      <div>
        <p
          style={{
            fontFamily: 'arial',
            fontSize: '20px',
            textAlign: 'center',
            height: 15,
          }}
        >
          Venta por punto de venta
        </p>
        <Row
          style={{ justifyContent: 'center', display: 'flex', height: '50px' }}
        >
          <Col span={8}>
            <label className="text-filters">
              <b>{'Equipos:'}</b>
            </label>
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              size="default"
              maxTagCount={0}
              placeholder="Seleccionar Equipos"
              allowClear={true}
              onChange={(value: []): void => {
                this.fetchData(value);
                this.setState({ selectedEquipment: value });
              }}
            >
              {this.props.equipment &&
                this.props.equipment.map(
                  (equip, index): JSX.Element => (
                    <Option value={equip.idG4s} key={index}>
                      {equip.tag}
                    </Option>
                  ),
                )}
            </Select>
          </Col>
        </Row>
        <Row style={{ height: 300 }}>
          <ResponsiveBar
            data={data}
            keys={[
              'Aut. Contado',
              'Aut. Crédito',
              'Carb. Contado',
              'Carb. Crédito',
            ]}
            indexBy="equipments"
            colors={['#2166ac', '#4393c3', '#d65f4c', '#f4a582']}
            margin={{ top: 10, right: 140, bottom: 100, left: 100 }}
            padding={0.3}
            layout="vertical"
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisTop={null}
            axisRight={null}
            label={(d: BarDatum): string => {
              const { radioSelected } = this.props;
              switch (radioSelected) {
                case 'volume':
                  return `${toNumber(+d.value)} Lts`;
                case 'weight':
                  return `${toNumber(+d.value)} Kgs`;
                default:
                  return `$${toNumber(+d.value)}`;
              }
            }}
            axisBottom={{
              orient: 'bottom',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -45,
              legendOffset: 36,
              legendPosition: 'middle',
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: 'middle',
              legendOffset: -40,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            legends={[
              {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
          />
        </Row>
      </div>
    );
  };
}

export default EquipmentSaleBarChart;
