import Service from 'services/Service';
import axios, { AxiosResponse } from 'axios';
import { createCoreServiceUrl } from '../../commons/services';
import {
  G4sMunicipality,
  G4sState,
  G4sPrice,
  G4sProduct,
  CustomerAddress,
  ZipCode,
} from 'types/type';

class G4sServices extends Service {
  public getG4sStates = async (): Promise<AxiosResponse<G4sState[]>> =>
    await axios.get(createCoreServiceUrl('g4s/states'));

  public getG4sMunicipalities = async (
    state: number,
  ): Promise<AxiosResponse<G4sMunicipality[]>> =>
    await axios.get(createCoreServiceUrl('g4s/municipalities/' + state));

  public getG4sPrices = async (): Promise<AxiosResponse<G4sPrice[]>> =>
    await axios.get(createCoreServiceUrl('g4s/prices'));

  public getG4sProducts = async (): Promise<AxiosResponse<G4sProduct[]>> =>
    await axios.get(createCoreServiceUrl('g4s/products'));

  public getG4sCustomerAddress = async (
    code: string,
  ): Promise<AxiosResponse<CustomerAddress>> =>
    await axios.get(createCoreServiceUrl('g4s/customer/address/' + code));

    public getG4sZipCode = async ( 
      zipCode: string,
    ): Promise<ZipCode> =>
      axios.get(createCoreServiceUrl('g4s/zipcode/' + zipCode))
      .then((response: AxiosResponse<ZipCode>): ZipCode => response.data);;
}
export default G4sServices;
