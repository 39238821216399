import axios, { AxiosResponse } from 'axios';
import Service from '../Service';
import { createCoreServiceUrl } from '../../commons/services';
import { Company, Pageable, Files as filesType } from '../../types/type';

class CompanyService extends Service {
  public getAll = (): Promise<Company[]> =>
    axios
      .get(createCoreServiceUrl('company'))
      .then((response: AxiosResponse<Company[]>): Company[] => response.data);
  public save = (company: Company, files: filesType): Promise<Company> => {
    var bodyFormData = new FormData();
    bodyFormData.append(
      'certificate',
      files.certificate !== null
        ? files.certificate
        : JSON.stringify(files.certificate),
    );
    bodyFormData.append(
      'privateKey',
      files.privateKey !== null
        ? files.privateKey
        : JSON.stringify(files.privateKey),
    );
    bodyFormData.append('company', JSON.stringify(company));
    return axios({
      url: createCoreServiceUrl('company'),
      method: 'post',
      data: bodyFormData,
      headers: {
        'content-Type':
          'Multipart/form-data;boundary=032a1ab685934650abbe059cb45d6ff3',
      },
    }).then((response: AxiosResponse<Company>): Company => response.data);
  };

  public getCompany = (companyId: string): Promise<Company> =>
    axios
      .get(createCoreServiceUrl(`company/getcompany/${companyId}`))
      .then((response: AxiosResponse<Company>): Company => response.data);

  public companyList = (page: number): Promise<Pageable> =>
    axios
      .get(createCoreServiceUrl(`company/page/${page}`))
      .then((response: AxiosResponse<Pageable>): Pageable => response.data);

  public getDistrubutionCenter = (): Promise<[]> =>
    axios
      .get(createCoreServiceUrl('company/distribuciosncenters'))
      .then((response: AxiosResponse<[]>): [] => {
        return response.data;
      });
}

export default CompanyService;
