import { notification } from 'antd';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { RootState } from 'store/configureStore';
import {
  BEGIN_FETCH,
  FETCH_COMPLETED,
  RESET_CUSTOMERS_TO_MOVE,
  RESET_OPENED_ROUTES,
  RESET_PORTFOLIOS_TO_ADD,
  RESET_ROUTE_DETAILS,
  RouteAction,
  SET_CUSTOMERS_TO_MOVE,
  SET_OPENED_ROUTES,
  SET_PORTFOLIOS_TO_ADD,
  SET_ROUTES_PAGE,
  SET_ROUTE_DETAILS,
} from './types';
import { parseApiError } from 'commons/error';
import { isRoutesFetching } from './reducers';
import {
  CollectionRoute,
  InvoiceForPortfolio,
  Pageable,
  Portfolio,
  RoutesFilters,
} from 'types/type';
import { Popups } from 'commons/components/popups/popups';

import RoutesService from 'services/routes';
import PortfoliosService from 'services/portfolios';
import { Moment } from 'moment';

const routesService = new RoutesService();
const portfolioService = new PortfoliosService();
type ITA = ThunkAction<Promise<void>, RootState, {}, RouteAction>;
type IDA = ThunkDispatch<{}, {}, RouteAction>;

export const startCollectionRoute = (id: string): ITA => async (
  dispatch: IDA,
  getState: Function,
): Promise<void> => {
  if (isRoutesFetching(getState())) return Promise.resolve();
  dispatch({ type: BEGIN_FETCH });
  try {
    await routesService.initCollectionRoute(id);
    Popups.notifySuccess('Ruta iniciada');
  } catch (error) {
    notification.error(parseApiError(error));
  }
  dispatch({ type: FETCH_COMPLETED });
};

export const getPage = (filters: RoutesFilters): ITA => async (
  dispatch: IDA,
  getState: Function,
): Promise<void> => {
  if (isRoutesFetching(getState())) return Promise.resolve();

  const page: Pageable = await routesService.getPageByFilters(filters);
  dispatch({ type: SET_ROUTES_PAGE, routesPage: page });
  dispatch({ type: BEGIN_FETCH });
  try {
  } catch (error) {
    notification.error(parseApiError(error));
  }
  dispatch({ type: FETCH_COMPLETED });
};

export const getPdf = (id: string): ITA => async (
  dispatch: IDA,
  getState: Function,
): Promise<void> => {
  if (isRoutesFetching(getState())) return Promise.resolve();

  dispatch({ type: BEGIN_FETCH });
  try {
    await routesService.getRoutePdf(id);
  } catch (error) {
    notification.error(parseApiError(error));
  }
  dispatch({ type: FETCH_COMPLETED });
};
export const closeRoute = (routeId: string): ITA => async (
  dispatch: IDA,
  getState: Function,
): Promise<void> => {
  if (isRoutesFetching(getState())) return Promise.resolve();

  dispatch({ type: BEGIN_FETCH });
  try {
    await portfolioService.closeRoute(routeId);
    Popups.notifySuccess('Ruta Cerrada');
  } catch (error) {
    notification.error(parseApiError(error));
  }
  dispatch({ type: FETCH_COMPLETED });
};

export const loadCollRouteDetails = (collRouteId: string): ITA => async (
  dispatch: IDA,
  getState: Function,
): Promise<void> => {
  if (isRoutesFetching(getState())) return Promise.resolve();

  dispatch({ type: BEGIN_FETCH });
  dispatch({ type: RESET_ROUTE_DETAILS });
  try {
    const response = await routesService.getCollRouteDetails(collRouteId);
    dispatch({ type: SET_ROUTE_DETAILS, routeDetails: response });
  } catch (error) {
    notification.error(parseApiError(error));
  }
  dispatch({ type: FETCH_COMPLETED });
};

export const downloadTaskitEvidence = (taskitId: string): ITA => async (
  dispatch: IDA,
  getState: Function,
): Promise<void> => {
  if (isRoutesFetching(getState())) return Promise.resolve();

  dispatch({ type: BEGIN_FETCH });
  try {
    routesService.downloadEvidence(taskitId);
  } catch (error) {
    notification.error(parseApiError(error));
  }
  dispatch({ type: FETCH_COMPLETED });
};

export const loadPortfoliosToAdd = (
  initDate: Moment,
  endDate: Moment,
): ITA => async (dispatch: IDA, getState: Function): Promise<void> => {
  if (isRoutesFetching(getState())) return Promise.resolve();

  dispatch({ type: BEGIN_FETCH });
  try {
    dispatch({ type: RESET_PORTFOLIOS_TO_ADD });
    const response = await portfolioService.getPortfoliosToAdd(
      initDate.toISOString(),
      endDate.toISOString(),
    );
    dispatch({ type: SET_PORTFOLIOS_TO_ADD, portfolios: response });
  } catch (error) {
    notification.error(parseApiError(error));
  }
  dispatch({ type: FETCH_COMPLETED });
};

export const addPortfoliosToRoute = (
  id: string,
  portfolios: Portfolio[],
): ITA => async (dispatch: IDA, getState: Function): Promise<void> => {
  if (isRoutesFetching(getState())) return Promise.resolve();

  dispatch({ type: BEGIN_FETCH });
  try {
    await routesService.addPortfolios(id, portfolios);
    Popups.notifySuccess('Carteras agregadas a ruta');
  } catch (error) {
    notification.error(parseApiError(error));
  }
  dispatch({ type: FETCH_COMPLETED });
};

export const loadCustomersToMove = (routeId: string): ITA => async (
  dispatch: IDA,
  getState: Function,
): Promise<void> => {
  if (isRoutesFetching(getState())) return Promise.resolve();

  dispatch({ type: BEGIN_FETCH });
  dispatch({ type: RESET_CUSTOMERS_TO_MOVE });
  try {
    const response = await routesService.getCustomersToMove(routeId);
    dispatch({ type: SET_CUSTOMERS_TO_MOVE, customersInfo: response });
  } catch (error) {
    notification.error(parseApiError(error));
  }
  dispatch({ type: FETCH_COMPLETED });
};

export const loadOpenedRoutes = (id: string): ITA => async (
  dispatch: IDA,
  getState: Function,
): Promise<void> => {
  if (isRoutesFetching(getState())) return Promise.resolve();

  dispatch({ type: BEGIN_FETCH });
  dispatch({ type: RESET_OPENED_ROUTES });
  try {
    const response = await routesService.getOpenedRoutesDifferentFrom(id);
    dispatch({ type: SET_OPENED_ROUTES, openedRoutes: response });
  } catch (error) {
    notification.error(parseApiError(error));
  }
  dispatch({ type: FETCH_COMPLETED });
};

export const moveCustomersToRoute = (
  originRoute: CollectionRoute,
  destinationRoute: CollectionRoute,
  customers: InvoiceForPortfolio[],
): ITA => async (dispatch: IDA, getState: Function): Promise<void> => {
  if (isRoutesFetching(getState())) return Promise.resolve();

  dispatch({ type: BEGIN_FETCH });
  try {
    await routesService.moveCustomers(
      originRoute.id,
      destinationRoute.id,
      customers,
    );
    Popups.notifySuccess(
      `Clientes movidos de la ruta ${originRoute.folio.id} a la ${destinationRoute.folio.id}`,
    );
  } catch (error) {
    notification.error(parseApiError(error));
  }
  dispatch({ type: FETCH_COMPLETED });
};
