import { Button, Col, Icon, List, Modal } from 'antd';
import React from 'react';
import { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { CollectionRoute, Portfolio } from 'types/type';
import Text from 'antd/lib/typography/Text';
import { DATE_FORMAT } from 'commons/services';
import moment from 'moment';

interface Props {
  isVisible: boolean;
  collRoute: CollectionRoute;
  portfolios: Portfolio[];
  onCancel: Function;
  onSave: Function;
}

class AddPortfolioModal extends Component<Props> {
  public state = {
    selectedRowKeys: [''],
  };

  private addSelectedRow = (portfolio: Portfolio): void => {
    let { selectedRowKeys } = this.state;
    selectedRowKeys.push(portfolio.id);
    this.setState({ selectedRowKeys });
  };

  private removeSelectedRow = (porfolio: Portfolio): void => {
    const { selectedRowKeys } = this.state;
    const newKeys = selectedRowKeys.filter(
      (id: string): boolean => id !== porfolio.id,
    );
    this.setState({ selectedRowKeys: newKeys });
  };

  public render(): JSX.Element {
    const { collRoute, portfolios, onCancel, onSave, isVisible } = this.props;
    const { selectedRowKeys } = this.state;

    return (
      <Modal
        visible={isVisible}
        centered
        width={700}
        okText="Guardar"
        title={`Asignar carteras a ruta ${collRoute.folio.id}`}
        onOk={(): void => {
          let selectedPortfolios: Portfolio[] = [];
          selectedRowKeys.forEach((id: string): void => {
            const rows = portfolios.filter(
              (pf: Portfolio): boolean => pf.id === id,
            );
            selectedPortfolios = selectedPortfolios.concat(rows);
          });
          onSave(collRoute.id, selectedPortfolios);
        }}
        onCancel={(): void => onCancel()}
      >
        <div className="demo-infinite-container">
          Hacer clic en la palomita para seleccionar la cartera, o en la X del
          renglón para deseleccionarla.
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={(): void => {}}
            hasMore={false}
            useWindow={false}
          >
            <List
              dataSource={portfolios}
              renderItem={(portfolio: Portfolio): JSX.Element => (
                <List.Item
                  key={portfolio.id}
                  className={
                    // Show gray color background when the row is selected
                    selectedRowKeys.includes(portfolio.id)
                      ? 'list-row-grey-backgroud'
                      : 'list-row-white-backgroud'
                  }
                >
                  <List.Item.Meta
                    title={
                      <Text strong>{`Cartera folio: ${portfolio.folio}`}</Text>
                    }
                    description={
                      <Col>
                        <Col span={8}>
                          {portfolio.createdAt == null
                            ? ''
                            : `Fecha: ${moment(portfolio.createdAt).format(
                                DATE_FORMAT,
                              )}`}
                        </Col>
                        <Col span={8}>
                          {`Clientes a visitar: ${portfolio.customersToVisit}`}
                        </Col>
                        <Col span={8}>
                          {`Total a cobrar: ${portfolio.totalAmount}`}
                        </Col>
                        <Col span={8}>{`Estatus: ${portfolio.status}`}</Col>
                      </Col>
                    }
                  />
                  {// Show check button when row is not selected
                  !selectedRowKeys.includes(portfolio.id) && (
                    <Button
                      onClick={(): void => this.addSelectedRow(portfolio)}
                    >
                      <Icon type="check" />
                    </Button>
                  )}
                  {selectedRowKeys.includes(portfolio.id) && (
                    <Button
                      onClick={(): void => this.removeSelectedRow(portfolio)}
                    >
                      <Icon type="close" />
                    </Button>
                  )}
                </List.Item>
              )}
            ></List>
          </InfiniteScroll>
        </div>
      </Modal>
    );
  }
}

export default AddPortfolioModal;
